import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import { IconButton } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@operto/ui';
import ActionDropDownMenu from 'Pages/GuestPortal/Common/ActionDropDownMenu';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import { UNCATEGORIZED_ID } from 'helper/helper';
import React, { useCallback, useState } from 'react';
import { ICategory, useGuestPortal, useGuestPortalStatus } from 'redux/guestportal';
import { useGuidesCategories } from 'redux/guestportal/hooks/useGuidesCategories';
import { useAppSelector } from 'redux/hooks';
import { SortTable } from 'ui-library/Components/table/SortTable';
import { userPermissionSelector } from 'user/state/userSelectors';
import { GuidesActionsType } from '../../types';

const columns = (
  onAction: (action: GuidesActionsType, category?: ICategory) => void,
  hasGuestPortal?: boolean,
): GridColDef<ICategory>[] => [
  {
    field: 'category',
    headerName: 'Category Name',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, ICategory>) => {
      return <Text>{row.category}</Text>;
    },
  },
  {
    field: 'number_of_contents',
    headerName: 'No. Of Pages',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, ICategory>) => {
      return <Text>{row.number_of_contents}</Text>;
    },
  },
  {
    field: 'last_edit_by',
    headerName: 'Last Edited By',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, ICategory>) => {
      return <Text>{row.last_edit_by}</Text>;
    },
  },
  {
    field: 'last_edit_at',
    headerName: 'Last Edited',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, ICategory>) => {
      return <Text>{format(fromUnixTime(row.last_edit_at), 'P')}</Text>;
    },
  },
  {
    field: 'featured',
    headerName: 'Featured',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, ICategory>) => {
      return row.featured && <StarIcon color='primary' />;
    },
  },
  {
    field: 'published',
    headerName: 'Published',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, ICategory>) => {
      return row.published && <CheckCircleIcon color='primary' />;
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    renderCell: ({ row }: GridRenderCellParams<unknown, ICategory>) => {
      return (
        <>
          <IconButton onClick={() => onAction('edit', row)} disabled={!hasGuestPortal} size='large'>
            <EditIcon color={hasGuestPortal ? 'primary' : 'disabled'} />
          </IconButton>
          <ActionDropDownMenu
            onEdit={() => onAction('edit', row)}
            onDelete={() => onAction('delete', row)}
            onPublished={() => onAction('publish', row)}
            onFeatured={() => onAction('feature', row)}
            disabled={!hasGuestPortal}
          />
        </>
      );
    },
  },
];

type GuidesDetailsCategoriesTableProps = {
  onAction: (action: GuidesActionsType, category?: ICategory) => void;
};

export const GuidesDetailsCategoriesTable = ({ onAction }: GuidesDetailsCategoriesTableProps) => {
  const { categoriesList } = useGuestPortal();
  const { isLoading } = useGuestPortalStatus();
  const { sort } = useGuidesCategories();
  const hasGuestPortal = useAppSelector(userPermissionSelector());
  const [filteredList, setFilteredList] = useState<ICategory[]>([]);

  const handleSearch = useCallback(
    (value?: string) => {
      const noUncategorized = categoriesList.filter(
        ({ category_id }) => category_id !== UNCATEGORIZED_ID,
      );
      if (!value?.length) {
        return setFilteredList(noUncategorized);
      }

      const filter = value.toLowerCase();
      setFilteredList(
        noUncategorized.filter(
          item =>
            item.title?.toLowerCase().includes(filter) ||
            item.category?.toLowerCase().includes(filter) ||
            item.last_edit_by?.toLowerCase().includes(filter),
        ),
      );
    },
    [categoriesList],
  );

  const handleSort = (order: ICategory[]) => {
    sort(order.map(o => ({ id: o.category_id, type: 'category' })));
  };

  return (
    <SortTable
      title='Categories'
      loading={isLoading}
      initialState={{ pinnedColumns: { right: ['actions'] } }}
      rows={filteredList}
      rowReordering={false} // diabling to keep old functionality the same
      getRowId={row => row.category_id}
      columns={columns(onAction, hasGuestPortal)}
      onSearch={handleSearch}
      onAdd={() => onAction('add')}
      onSort={handleSort}
    />
  );
};
