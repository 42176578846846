import { CustomizedJwtPayload } from 'auth/auth';
import { useEffect, useState } from 'react';
import { getCustomizedJwtPayload } from 'redux/actions/users';
import { useAppSelector } from 'redux/hooks';
import { MEMBERS_ROLES } from 'redux/members/types';
import { userSelector } from 'user/state/userSelectors';
import { UserRole } from 'user/userTypes';

export const useAuthorizationAccess = () => {
  const user = useAppSelector(userSelector());
  const [userRole, setUserRole] = useState<string | null>(null);

  useEffect(() => {
    if (user && user.role) {
      setUserRole(user.role);
    }
  }, [user]);

  const canAccess = (value: string): boolean => {
    switch (value) {
      case 'communications':
        return canAccessCommunications();
      case 'automate':
        return canAccessAutomate();

      default:
        return false; // Default to allowing access if no specific case
    }
  };

  const canAccessCommunications = (): boolean => {
    const restrictedRoles: UserRole[] = [MEMBERS_ROLES.BASIC_USER];
    return checkRestrictedRoles(restrictedRoles);
  };

  const canAccessAutomate = (): boolean => {
    const restrictedRoles: UserRole[] = [MEMBERS_ROLES.BASIC_USER];
    return checkRestrictedRoles(restrictedRoles);
  };

  const checkRestrictedRoles = (restrictedRoles: UserRole[]): boolean => {
    return checkRoles(restrictedRoles, (userRole, roles) => !roles.includes(userRole));
  };

  const checkRoles = (
    roles: UserRole[],
    checkCondition: (userRole: UserRole, roles: UserRole[]) => boolean,
  ): boolean => {
    if (!user) {
      return false; // If no user, do not allow access
    }

    const role = userRole || getRoleFromToken();

    if (!role) {
      return false; // Handle case where role extraction failed or role is invalid
    }

    return checkCondition(role as UserRole, roles);
  };

  const getRoleFromToken = (): UserRole => {
    const decode: CustomizedJwtPayload = getCustomizedJwtPayload(user.accessToken);
    return decode.role as UserRole;
  };

  return {
    canAccess,
    canAccessCommunications,
    canAccessAutomate,
  };
};
