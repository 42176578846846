import { UserRole } from 'user/userTypes';

export interface IMember {
  is_password_set: boolean;
  units: number[];
  group_ids: number[];
  id: number;
  company_id: number;
  owner_id: number;
  name: string;
  type?: string;
  job_title: string;
  member_type: string;
  staff_type?: string;
  notification_enabled: boolean;
  contact_number: string;
  email: string;
  status: string;
  groups: number[];
  groups_private: number[];
  team_ids?: number[];
  code?: number;
  permissions?: string[];
  textAlert?: boolean;
  emailAlert?: boolean;
  properties: number[];
  notification_settings: Record<string, unknown>;
  is_email_notification: boolean;
  is_sms_notification: boolean;
  site_access_id?: number[];
  blackout_times: Record<string, unknown>;
  profile_picture_url: string | null;
  member_access: MemberAccess;
  role: UserRole;
  is_celsius: boolean;
  teams: number[];
  getstream?: GetStream;
  features?: Features;
  is_migrated_to_cognito: boolean;
  is_dashboard_access?: boolean;
}

export interface Features {
  incident_payment_enabled: boolean;
  mp_guest_enabled: boolean;
  mp_guest_portal_onboarding_enabled: boolean;
  mp_two_way_enabled: boolean;
  mp_campaigns_enabled: boolean;
}

export interface GetStream {
  token: string;
  expiry: number;
  id: string;
}

export enum MemberFilterType {
  ALL_MEMBERS = 'all_members',
  STAFF = 'employee',
  CONTRACTOR = 'contractor',
  INACTIVE = 'inactive',
  EXCLUDE_GROUP = 'exclude_in_group',
  GROUP = 'group_id',
}

export enum MemberFilterTypeIndex {
  ALL_MEMBERS = 0,
  STAFF = 1,
  CONTRACTORS = 2,
  INACTIVE = 3,
}

export interface MemberCounts {
  currentFilter: MemberFilterType;
  isLoading: boolean;
  [MemberFilterType.ALL_MEMBERS]: RecordTrack;
  [MemberFilterType.CONTRACTOR]: RecordTrack;
  [MemberFilterType.INACTIVE]: RecordTrack;
  [MemberFilterType.STAFF]: RecordTrack;
  [MemberFilterType.EXCLUDE_GROUP]: RecordTrack;
  [MemberFilterType.GROUP]: RecordTrack;
}

export interface RecordTrack {
  ids: number[];
  pageNum: number;
  numPerPage: number;
  total_records: number;
}

interface MemberAccess {
  id: number;
  pin_code: string;
  pin_code_task_done: string;
  status: string;
}

export interface MemberDict {
  [Key: number]: IMember;
}

export interface MemberState {
  byId: MemberDict;
  meta: MemberCounts;
}

export enum MemberSiteAccess {
  DASHBOARD = 100,
  PORTAL = 200,
  DEVELOPER = 300,
  SUPPORT = 400,
  CLIENT_SUCCESS = 500,
  DEVOPS = 600,
}
