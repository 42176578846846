import { Delete, Edit } from '@mui/icons-material';
import { Box, CardMedia, Divider, IconButton, Paper, SxProps } from '@mui/material';
import { BaseContainer } from 'Style/Components/BaseComponents';
import * as React from 'react';

export interface ImageThumbnailsProps {
  images: string[];
  onEdit: (url: string) => void;
  onDelete: (url: string) => void;
}
const ImageThumbnails = ({ images, onEdit, onDelete }: ImageThumbnailsProps) => (
  <BaseContainer
    style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '5px',
      justifyContent: 'flex-start',
    }}
  >
    {images?.map((url, index) => (
      <ImageCard images={url} onEdit={onEdit} onDelete={onDelete} key={index} />
    ))}
  </BaseContainer>
);

export interface ImageCardProps {
  images: string;
  onEdit: (url: string) => void;
  onDelete: (url: string) => void;
}
const ImageCard = ({ images, onEdit, onDelete }: ImageCardProps) => (
  <Paper
    sx={{
      width: '100px',
      height: '100px',
      marginTop: '10px',
      marginRight: '10px',
    }}
  >
    <Box sx={{ padding: '5px' }}>
      <CardMedia component='img' width='90' height='64' image={images} />
    </Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
      }}
    >
      <IconButton
        sx={SmallIconButtonSxProps}
        size='small'
        onClick={() => {
          onEdit?.(images);
        }}
      >
        <Edit />
      </IconButton>
      <Divider orientation='vertical' flexItem />
      <IconButton
        sx={SmallIconButtonSxProps}
        size='small'
        onClick={() => {
          onDelete?.(images);
        }}
      >
        <Delete />
      </IconButton>
    </Box>
  </Paper>
);

const SmallIconButtonSxProps: SxProps = {
  '& svg': {
    fontSize: '1.2rem',
  },
};

export default ImageThumbnails;
