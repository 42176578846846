import { IThreshold } from './../thresholdType';
/* eslint-disable no-case-declarations */
import * as api from 'api/propertyAPI';
import * as thresholdApi from 'api/thresholdAPI';
import { OpertoLogger } from 'Logger/logger';
import { AppDispatch } from 'redux/store';
import { IThresholdExtended } from 'threshold/thresholdType';
import { Actions } from 'types/actions';
import { AlertType, IAlert, IMetricType } from 'types/activeAlerts';

const ThresholdName: { [id: number]: string } = {
  [AlertType.ALERT_ENVIRONMENT_ISSUE_TEMPERATURE_HIGH_ID]: 'tempHigh',
  [AlertType.ALERT_ENVIRONMENT_ISSUE_TEMPERATURE_LOW_ID]: 'tempLow',
  [AlertType.ALERT_ENVIRONMENT_ISSUE_HUMIDITY_HIGH_ID]: 'humidityHigh',
  [AlertType.ALERT_ENVIRONMENT_ISSUE_HUMIDITY_LOW_ID]: 'humidityLow',
  [AlertType.ALERT_COMPLIANCE_ISSUE_CO2_MED_ID]: 'co2Med',
  [AlertType.ALERT_COMPLIANCE_ISSUE_CO2_HIGH_ID]: 'co2High',
  [AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_MED_ID]: 'noiseMed',
  [AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_HIGH_ID]: 'noiseHigh',
  [AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_QUIET_ID]: 'noiseQuiet',
  [AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_REG_ID]: 'noiseReg',
};

const getQuietHoursFrom = (alert: IAlert) => {
  if (alert.alert_type_id === AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_QUIET_ID) {
    const threshold = alert?.thresholds?.find(
      (each: IThreshold) => each.metric_type.id === IMetricType.SCHEDULE && each.operator === '>=',
    );
    return threshold;
  }
};
const getQuietHoursTo = (alert: IAlert) => {
  if (alert.alert_type_id === AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_QUIET_ID) {
    const threshold = alert?.thresholds?.find(
      (each: IThreshold) => each.metric_type.id === IMetricType.SCHEDULE && each.operator === '<',
    );
    return threshold;
  }
};

const getQuietThresholdValue = (alert: IAlert) => {
  if (alert.alert_type_id === AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_QUIET_ID) {
    const threshold = alert?.thresholds?.find(
      (each: IThreshold) => each.metric_type.id === IMetricType.NOISE && each.operator === '>=',
    );
    return threshold;
  }
};

const getRegularThresholdValue = (alert: IAlert) => {
  if (alert.alert_type_id === AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_REG_ID) {
    const regThreshold = alert?.thresholds?.find(
      (each: IThreshold) =>
        (each.operator === '>' && each.metric_type.id === IMetricType.NOISE) ||
        (each.operator === '>=' && each.metric_type.id === IMetricType.NOISE),
    );
    return regThreshold;
  }
};

const generateThreshold = (alert: IAlert, pairedAlerts: number[]) => {
  const threshold: IThresholdExtended = { value: 0, id: 0 };

  if (alert.thresholds?.[0]) {
    const alertThreshold = alert.thresholds[0];
    threshold.value = parseInt(alertThreshold.value, 10);
    threshold.id = parseInt(alertThreshold.id, 10);
  }
  switch (alert.alert_type_id) {
    case AlertType.ALERT_COMPLIANCE_ISSUE_CO2_HIGH_ID:
      return {
        ...threshold,
        otherId: pairedAlerts[AlertType.ALERT_COMPLIANCE_ISSUE_CO2_MED_ID],
      };
    case AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_HIGH_ID:
      return {
        ...threshold,
        otherId: pairedAlerts[AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_MED_ID],
      };
    case AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_QUIET_ID:
      const quietFrom = getQuietHoursFrom(alert);
      const quietTo = getQuietHoursTo(alert);
      const quietValue = getQuietThresholdValue(alert);
      return {
        ...threshold,
        toId: quietTo.id,
        to: quietTo?.value,
        id: quietValue.id,
        value: parseInt(quietValue.value, 10),
        fromId: quietFrom.id,
        from: quietFrom && quietFrom.value,
      };
    case AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_REG_ID:
      const regThreshold = getRegularThresholdValue(alert);
      return {
        ...threshold,
        id: regThreshold.id,
        value: parseInt(regThreshold.value, 10),
      };
    case AlertType.ALERT_ENVIRONMENT_ISSUE_TEMPERATURE_HIGH_ID:
    case AlertType.ALERT_ENVIRONMENT_ISSUE_TEMPERATURE_LOW_ID:
    case AlertType.ALERT_ENVIRONMENT_ISSUE_HUMIDITY_LOW_ID:
    case AlertType.ALERT_ENVIRONMENT_ISSUE_HUMIDITY_HIGH_ID:
    case AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_MED_ID:
    case AlertType.ALERT_COMPLIANCE_ISSUE_CO2_MED_ID:
    default:
      return { ...threshold };
  }
};

export const patchThreshold = (thresholdId: number, value: number) => () =>
  thresholdApi.patchThreshold(thresholdId, value).then(() => {
    // TODO: update state, waiting for server to send back data
  });

export const getDeviceThresholdsByPropertyId = (propertyId: string) => (dispatch: AppDispatch) => {
  api
    .getDeviceThresholdsByPropertyId(propertyId)
    .then(data => {
      const pairedAlerts = data.data.data.reduce((accum: IAlert[], alert: IAlert) => {
        if (alert?.thresholds?.[1]) {
          const threshold = alert.thresholds.find((each: IThreshold) => each.operator === '<');
          if (threshold) {
            return {
              ...accum,
              [alert.alert_type_id]: threshold.id,
            };
          }
        }
        return accum;
      }, {});
      const thresholdByDeviceId: Record<number, object> = {};

      const thresholds = data.data.data.reduce(
        (accum: IAlert[], alert: IAlert) => {
          const thresholdName = ThresholdName[alert.alert_type_id];
          const threshold = generateThreshold(alert, pairedAlerts);
          const deviceId = parseInt(alert.devices[0], 10);

          thresholdByDeviceId[deviceId] = {
            ...thresholdByDeviceId[deviceId],
            [thresholdName]: threshold,
          };
          return { ...accum, [thresholdName]: threshold };
        },

        {
          tempHigh: { id: '', value: 0 },
          tempLow: { id: '', value: 0 },
          humidityHigh: { id: '', value: 0 },
          humidityLow: { id: '', value: 0 },
          co2Med: { id: '', value: 0 },
          co2High: { id: '', value: 0 },
          noiseMed: { id: '', value: 0 },
          noiseHigh: { id: '', value: 0 },
          noiseReg: { id: '', value: 0 },
          noiseQuiet: { id: '', value: 0, to: '', from: '' },
        },
      );

      dispatch({
        type: Actions.hydrateThresholds,
        propertySpecificNotificationsHR: { [propertyId]: thresholds },
        thresholdByDeviceId,
      });
    })
    .catch(err => {
      OpertoLogger.Log(err);
    });
};
