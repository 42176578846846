import { Token } from '@stripe/stripe-js';
import axios from 'axios';

const api = process.env.REACT_APP_API_URL;

export const getStripeInfo = () => axios.get(`${api}/v2/companies/stripe`);
export const getStripeToken = () => axios.get(`${api}/v2/companies/stripe-token`);

export const updatePaymentMethod = (paymentMethodId: string) =>
  axios.post(`${api}/v2/companies/stripe-payment-method`, {
    payment_method_id: paymentMethodId,
  });

export const getPaymentMethod = () => axios.get(`${api}/v2/payment-methods`, {});

export const postToken = (token: Token, email: string, companyName: string) =>
  axios.post(`${api}/v2/payment-methods`, {
    stripeToken: token.id,
    email,
    companyName,
  });
