import { createSelector } from '@reduxjs/toolkit';
import { AccessCodeTypes, CodeState, ICode } from 'code/codeType';
import { ApplicationState } from 'redux/reducers';
import matchMembersStartAndComplete from 'redux/selectors/matchMembersStartAndComplete';

const getCodes = (state: ApplicationState) => state.codes;

const memberCodesSelector = (propertyId: number) =>
  createSelector(
    [getCodes, matchMembersStartAndComplete],
    (codes: CodeState, membersCodes: ICode[]) => {
      const managementCodes: ICode[] = Object.values(codes.byId).filter(
        (code: ICode) =>
          code.property_id === Number(propertyId) &&
          (code.access_code_type_id === AccessCodeTypes.Manager ||
            code.access_code_type_id === AccessCodeTypes.Owner),
      );
      return [...managementCodes, ...membersCodes];
    },
  );

export default memberCodesSelector;
