import { Drawer, DrawerProps, SxProps, Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import React from 'react';

export type SideDrawerProps = Omit<DrawerProps, 'anchor' | 'sx'> & {
  anchor?: 'left' | 'right' | undefined;
  sx?: SxProps<Theme> & CSSProperties;
};

export const SideDrawer = ({ sx, PaperProps, ...rest }: SideDrawerProps) => {
  return (
    <Drawer
      anchor='right'
      sx={{ width: sx?.width || '60%' }}
      PaperProps={{
        ...PaperProps,
        sx: { ...PaperProps?.sx, borderRadius: 0, backgroundColor: 'background.default01' },
      }}
      {...rest}
    />
  );
};
