import { shallowEqual } from 'react-redux';
import { GroupsState } from 'redux/groups/types';
import { validateTextLength } from 'ui-library/Helpers/textHelper';

export const validateGroupNameHelper = (
  text?: string,
  data?: GroupsState,
  currentValue?: string,
) => {
  const trimmedText = text?.trim() ?? '';
  if (
    data.groups.some(item => shallowEqual(item.name, trimmedText)) &&
    trimmedText !== currentValue
  ) {
    return 'This name already exists';
  }
  return validateTextLength(trimmedText, 32);
};
