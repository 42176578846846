import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material';
import { Divider, MenuItem, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

interface OfferActionMenuItemsProps {
  onEditClick?: () => void;
  onDeleteClick?: () => void;
}

const OfferActionMenuItems = ({ onEditClick, onDeleteClick }: OfferActionMenuItemsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <MenuItem
        onClick={() => {
          onEditClick();
        }}
        data-testid='offerEditButton'
      >
        <EditOutlined />
        <Typography sx={{ paddingInlineStart: '20px' }}>{t('edit_offers_menu')}</Typography>
      </MenuItem>

      <Divider variant='middle' />

      <MenuItem
        onClick={() => {
          onDeleteClick();
        }}
        data-testid='offerDeleteButton'
      >
        <DeleteOutlineOutlined />
        <Typography sx={{ paddingInlineStart: '20px' }}>{t('delete')}</Typography>
      </MenuItem>
    </>
  );
};

export default OfferActionMenuItems;
