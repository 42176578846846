import { createSelector } from '@reduxjs/toolkit';
import { ThermostatDict } from 'types/thermostats';
import { ApplicationState } from '../reducers';

const getDevices = (state: ApplicationState) => state.thermostat.byPropertyId;

const thermostatsAssociatedToProperty = (propertyID: number) =>
  createSelector([getDevices], (devicesByPropertyId: ThermostatDict) => {
    const settings = devicesByPropertyId[propertyID];
    return settings;
  });

export default thermostatsAssociatedToProperty;
