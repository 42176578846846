// @ts-nocheck not sure what theme is being passed in here
/* eslint-disable  @typescript-eslint/no-explicit-any */

import styled from 'styled-components';

// All components here should extend these so they have the basic

export const Base = styled.div`
  color: ${(props: any) => (props.color ? `${props.color} !important` : props.theme.textColor)};
  height: ${(props: any) => props.height};
  width: ${(props: any) => props.width};
`;

export const BaseContainer = styled(Base)`
  -webkit-transition: all 500ms ease-out;
  -moz-transition: all 500ms ease-out;
  -o-transition: all 500ms ease-out;
  transition: all 500ms ease-out;

  -webkit-transition: height 0.5s linear;
  -moz-transition: height 0.5s linear;
  -ms-transition: height 0.5s linear;
  -o-transition: height 0.5s linear;
  transition: height 0.5s linear;
`;
