import { ICompany } from 'company/companyType';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import AddCard from 'ui-library/Components/card/AdderCard';
import {
  FormTextField,
  FormTextFieldRegexPhone,
  FormTextFieldRegexURL,
} from 'ui-library/Components/input/FormTextField';
import { ActionButton, FormHeader } from 'ui-library/Styled/sidebar';

type CompanyFormData = Pick<
  ICompany,
  'id' | 'name' | 'email' | 'phoneNumber' | 'websiteUrl' | 'address'
>;

export type CompanyFormProps = {
  company: CompanyFormData;
  onSubmit: (data: CompanyFormData) => void;
};

export const CompanyForm = ({ company, onSubmit }: CompanyFormProps) => {
  const { handleSubmit, reset, control } = useForm<CompanyFormData>({ mode: 'all' });

  useEffect(() => {
    if (company) {
      reset(company);
    }
  }, [company, reset]);

  return (
    <AddCard
      title='Business Info'
      footerChildren={
        <ActionButton
          testId='company-save-button'
          variant='contained'
          color='primary'
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </ActionButton>
      }
    >
      <FormHeader align='left'>{`Company CID: ${company.id}`}</FormHeader>
      <FormTextField
        rules={{
          required: {
            value: true,
            message: 'Company name is required',
          },
        }}
        field='name'
        label='Company Name'
        control={control}
        data-testid='company-name-input'
      />
      <FormTextField field='email' type='email' label='Company Email' disabled control={control} />
      <FormTextField
        rules={{
          required: {
            value: true,
            message: 'Phone number is required',
          },
          pattern: {
            value: FormTextFieldRegexPhone,
            message: 'Valid phone number format is required',
          },
        }}
        field='phoneNumber'
        type='tel'
        label='Company Phone'
        control={control}
      />
      <FormTextField
        rules={{
          pattern: {
            value: FormTextFieldRegexURL,
            message: 'Valid web URL is required.',
          },
        }}
        field='websiteUrl'
        type='url'
        label='Company Website'
        control={control}
      />
      <FormTextField field='address' label='Company Address' control={control} />
    </AddCard>
  );
};

export default CompanyForm;
