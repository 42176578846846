import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { HostDetailsCard } from 'Pages/GuestPortal/Global/HostDetails';
import TabActivator from 'Pages/Unit/GuestPortal/TabActivator';
import TabDeactivator from 'Pages/Unit/GuestPortal/TabDeactivator';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import { Host, useGuestPortal } from 'redux/guestportal';
import { useAppSelector } from 'redux/hooks';
import { PageHeader } from 'ui-library/Styled/containers';
import { userPermissionSelector } from 'user/state/userSelectors';

const CustomHost = () => {
  const { propertyId } = useParams<{ propertyId: string }>();
  const { fetchHostData, fetchPropertyHostData, setPropertyHost, host } = useGuestPortal();
  const [isSwitched, setIsSwitched] = React.useState(host?.enabled || false);
  const [convertedHost, setConvertedHost] = React.useState<Host>();
  const permission = useAppSelector(userPermissionSelector());

  // FIXME: we really need to address async issues with the whole guest portal
  const onSave = (host: Host) => {
    setPropertyHost(host);
  };

  const switched = (switchPreviousState: boolean) => {
    setConvertedHost({ ...convertedHost, enabled: !switchPreviousState });
    setIsSwitched(!switchPreviousState);
    onSave({ ...convertedHost, enabled: !switchPreviousState });
  };

  useEffect(() => {
    if (propertyId) {
      fetchPropertyHostData(Number(propertyId));
    } else {
      fetchHostData();
    }
  }, [fetchPropertyHostData, fetchHostData, propertyId]);

  useEffect(() => {
    setIsSwitched(host?.enabled);
    if (host?.enabled) {
      setConvertedHost({
        ...host,
        propertyId,
      });
    } else {
      setConvertedHost({
        name: '',
        phone: '',
        email: '',
        imageURL: '',
        propertyId,
      });
    }
  }, [host, propertyId]);

  if (!host) {
    return null;
  }

  return (
    <>
      <PageHeader>Custom Host</PageHeader>
      {isSwitched ? (
        <>
          <TabDeactivator switchLabel={'Disable Custom Host'} onSwitched={switched} />
          <HostDetailsCard host={convertedHost} onSave={onSave} permission={permission} />
        </>
      ) : (
        <TabActivator
          title={'Need a custom host for this unit?'}
          icon={
            <RecordVoiceOverIcon
              color='primary'
              sx={{
                height: isMobile ? '70px' : '120px',
                width: isMobile ? '70px' : '120px',
                marginX: 'auto',
              }}
            />
          }
          switchLabel={'Enable Custom Host'}
          onSwitched={switched}
        />
      )}
    </>
  );
};

export default CustomHost;
