import { Select, SxProps } from '@mui/material';
import { BaseContainer } from 'Style/Components/BaseComponents';
import styled from 'styled-components';

export const PageContentContainer = styled(BaseContainer)<{ isMobile?: boolean; padding?: string }>`
  padding: ${props => (props.padding ? props.padding : props.isMobile ? '0px' : '24px')};
  margin: ${props => props.isMobile && '2rem'} auto;
`;

export const PageHeader = styled.div`
  padding: 16px 24px;
`;

export const PageHeaderSelect = styled(Select)`
  && {
    &:after,
    &:before {
      border-bottom: none;
    }
  }
`;

export const formFooterStyles: SxProps = {
  height: '56px',
  bgcolor: 'background.default',
  px: 3,
  spacing: 2,
};
