import { MenuItem, Typography } from '@mui/material';
import { IDeviceData } from 'device/deviceType';
import * as React from 'react';
import { TitleContainer, TitleDropdown } from 'ui-library/Styled/smartcard';

export interface TitleDropDownProps {
  icon: React.ReactNode;
  devices: IDeviceData[];
  selectedDeviceId: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  mode?: 'light' | 'dark';
}

const DeviceTitleDropdown = ({
  devices,
  icon,
  selectedDeviceId,
  onChange,
  mode,
}: TitleDropDownProps) => {
  const currentDevice = devices.find(
    (device: IDeviceData) => device.data?.device_id === selectedDeviceId,
  );
  const formattedTitle = `${currentDevice?.vendor}:${currentDevice?.data.name}`;

  const textColor = mode ? (mode === 'dark' ? 'text.primary' : 'white') : 'white';

  return (
    <TitleContainer>
      {icon}
      {devices?.length > 1 ? (
        <TitleDropdown value={selectedDeviceId} onChange={onChange}>
          {devices.map((device: IDeviceData) => (
            <MenuItem
              value={device.data?.device_id as string}
              key={`option${device.data?.device_id}`}
            >{`${device.vendor}:${device.data?.name}`}</MenuItem>
          ))}
        </TitleDropdown>
      ) : devices?.length === 0 ? (
        <Typography sx={{ color: textColor }}>No Devices Found</Typography>
      ) : (
        <Typography sx={{ color: textColor }}>{formattedTitle}</Typography>
      )}
    </TitleContainer>
  );
};

export default DeviceTitleDropdown;
