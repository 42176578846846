import { IIntegration, IntegrationDataPayload } from './integrationsTypes';

export const parseGetIntegrationResponse = (integrations: IntegrationDataPayload[]) =>
  integrations.map(
    (integration: IntegrationDataPayload) =>
      ({
        id: integration.id,
        enabled: integration.enabled,
        apiSystem: integration.api_system,
        label: integration.label,
        name: integration.integration_provider_name,
        providerId: integration.integration_provider_id,
      } as IIntegration),
  );
