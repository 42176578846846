import { SxProps } from '@mui/material';

export const panelSnackbarStyle: SxProps = {
  height: '40px',
  color: 'rgba(0, 0, 0, 0.6)',
  backgroundColor: '#e6f3fa',
  display: 'flex',
  flexDirection: 'row',
  padding: '6px 16px',
  alignItems: 'center',
};
