import Link from '@mui/material/Link';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { toggleSlidebar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SlidebarType } from 'types/ui';

export interface UnitNameCellProps {
  text: string;
  url: string;
}

//TODO: move this to somewhere on the Monorepo, we haven't decided on where global styles go yet at this point
export const sxPropsForUnitName = {
  color: 'rgba(0, 0, 0, 0.87)',
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
};

const LinkCell = ({ text, url }: UnitNameCellProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const renderIcon = (cellText: string) => {
    const handleViewUnit = () => {
      navigate(url);
      dispatch(toggleSlidebar(SlidebarType.CLOSE, ''));
    };

    return (
      <Link onClick={handleViewUnit} sx={sxPropsForUnitName} data-testid='text-cell'>
        {cellText}
      </Link>
    );
  };

  return renderIcon(text);
};

export default LinkCell;
