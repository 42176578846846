import axios from 'axios';

// axios.defaults.headers.common['header1']
const api = process.env.REACT_APP_API_URL;

export const login = (usernameIn: string, passwordIn: string) => {
  const form = new URLSearchParams({
    grant_type: 'password',
    client_id: process.env.REACT_APP_API_CLIENT_API_KEY || '',
    client_secret: process.env.REACT_APP_API_CLIENT_SECRET || '',
    scope: 'internal-client',
    username: usernameIn,
    password: passwordIn,
  });
  return axios.post(`${api}/user/access_token`, form, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
};

export const forgotPassword = (username: string) => {
  const form = new URLSearchParams({
    grant_type: 'password',
    client_id: process.env.REACT_APP_API_CLIENT_API_KEY || '',
    client_secret: process.env.REACT_APP_API_CLIENT_SECRET || '',
    scope: 'internal-client',
    email: username,
  });
  return axios.put(`${api}/user/reset-password-request`, form, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
};

export const completeMemberRegistration = (
  username: string,
  token: string,
  newPassword: string,
  passwordConfirm: string,
) =>
  axios.put(`${api}/user/reset-password`, {
    email: username,
    sid: token,
    password: newPassword,
    password_confirm: passwordConfirm,
  });
