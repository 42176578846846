import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useMedia } from '@operto/ui';
import { useTopBarStyles } from 'Common/TopBar/useTopBarStyles';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

export interface HeaderBreadCrumbsChild {
  name: string;
  href?: string;
  hide?: boolean;
}
export interface HeaderBreadCrumbsProps {
  crumbs: Array<HeaderBreadCrumbsChild>;
  size?: 'small' | 'medium' | 'large';
}

const HeaderBreadCrumbs = ({ crumbs, size }: HeaderBreadCrumbsProps) => {
  const { pathname } = useLocation();
  const pathArray = pathname.split('/');
  const lastHrefPathElement = '/' + pathArray[pathArray.length - 1];
  const { lastBreadCrumbItemStyles, headerItemStyles } = useTopBarStyles();
  const { isMobile } = useMedia();
  let showedBreadCrumbsItems = 0;
  const crumbsComponentArray = crumbs.map(
    (breadCrumbChild: HeaderBreadCrumbsChild, index: number) => {
      if (breadCrumbChild.hide) {
        return;
      }
      showedBreadCrumbsItems++;
      const locatedInLastCrumbHref = breadCrumbChild.href === lastHrefPathElement;
      if (breadCrumbChild.href === undefined) {
        if (showedBreadCrumbsItems === 1) {
          return (
            <Typography key={index} variant='subtitle2' sx={headerItemStyles}>
              {breadCrumbChild.name}
            </Typography>
          );
        }
        return (
          <Typography key={index} sx={lastBreadCrumbItemStyles}>
            {breadCrumbChild.name}
          </Typography>
        );
      }
      return (
        <Link
          sx={locatedInLastCrumbHref ? lastBreadCrumbItemStyles : {}}
          underline='hover'
          color='inherit'
          href={breadCrumbChild.href}
          key={index}
        >
          {breadCrumbChild.name}
        </Link>
      );
    },
  );

  return (
    <Breadcrumbs
      sx={size === 'small' ? { fontSize: 12 } : {}}
      aria-label='breadcrumb'
      separator={<NavigateNextIcon fontSize='small' />}
    >
      {isMobile ? crumbsComponentArray[crumbsComponentArray.length - 1] : crumbsComponentArray}
    </Breadcrumbs>
  );
};

export default HeaderBreadCrumbs;
