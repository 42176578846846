import ArrowBackIosOutlined from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlined from '@mui/icons-material/ArrowForwardIosOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Avatar, Box, Drawer, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { FormContainer } from 'Common/FormContainer';
import { trackEvent, useTrackScreen } from 'lib/analytics';
import { logger } from 'lib/logger';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAddGroupMutation } from 'redux/groups/api-slice';
import { Group } from 'redux/groups/types';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { formFooterStyles } from 'ui-library/Styled/containers';
import { GroupsFormTabGeneral } from './GroupsFormTabGeneral';
import { GroupsFormTabMembers } from './GroupsFormTabMembers';
import { GroupsFormTabUnits } from './GroupsFormTabUnits';

const ADD_GROUPS_PATH = '/groups/add';
export const ADD_GROUP_TEMPLATE: Group = {
  id: 0,
  name: '',
  description: '',
  groupType: 'location', // NOTE: seems to be required but BE never used it
  memberIds: [],
  propertyIds: [],
};
const TAB_ITEMS = {
  GENERAL: 0,
  UNITS: 1,
  MEMBERS: 2,
} as const;

// TODO: extract me to a sharable component
function TabLabel(number: number, label: string, selected: boolean, tabIdx: number) {
  return (
    <Box display='inline-flex' alignItems='top' marginBottom={3}>
      {tabIdx + 1 <= number && (
        <Avatar
          sx={
            selected
              ? {
                  ...defaultAvatarStyle,
                  color: theme => theme.palette.getContrastText(theme.palette.primary.main),
                  backgroundColor: theme => theme.palette.primary.main,
                }
              : defaultAvatarStyle
          }
        >
          {number}
        </Avatar>
      )}
      {tabIdx + 1 > number && <CheckCircleIcon />}

      <Typography
        variant='subtitle-sm-600'
        sx={
          selected
            ? {
                ...defaultTextStyle,
                color: theme => theme.palette.primary.main,
              }
            : defaultTextStyle
        }
      >
        {label}
      </Typography>
    </Box>
  );
}

type GroupsFormProps = {
  onClose: () => void;
};

export const GroupsForm = ({ onClose }: GroupsFormProps) => {
  useTrackScreen({ screen: 'GROUPS FORM' });
  const [addGroup, { isLoading }] = useAddGroupMutation();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState<number>(TAB_ITEMS.GENERAL);
  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    formState: { isDirty, isValid, errors },
  } = useForm<Group>({
    mode: 'all',
    defaultValues: ADD_GROUP_TEMPLATE,
  });
  const enableSubmit =
    selectedTab === TAB_ITEMS.MEMBERS && Object.keys(errors).length === 0 && isValid && isDirty;
  const disableNextButton = !isValid || !!errors.name || selectedTab === TAB_ITEMS.MEMBERS;

  const handleClose = () => {
    onClose();
    reset(ADD_GROUP_TEMPLATE);
    setSelectedTab(TAB_ITEMS.GENERAL);
  };

  const handleSaveChange = async (group: Group) => {
    let success = true;
    try {
      await addGroup(group).unwrap();
      trackEvent({
        event: 'CREATED',
        screen: 'GROUPS FORM',
      });

      handleClose();
    } catch (error) {
      success = false;
      logger.error(`Error adding new group: ${error}`);
    }

    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `${success ? 'Successfully added' : 'Error adding'} new group`,
        variant: success ? SnackbarVariant.SUCCESS : SnackbarVariant.ERROR,
      }),
    );
  };

  const renderHeader = () => {
    return (
      <Tabs
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
        sx={{ backgroundColor: 'background.default', height: '48px', paddingX: 2 }}
      >
        <Tab
          label={TabLabel(1, 'General details', selectedTab === TAB_ITEMS.GENERAL, selectedTab)}
        />
        <Tab
          label={TabLabel(2, 'Add units', selectedTab === TAB_ITEMS.UNITS, selectedTab)}
          disabled={selectedTab < TAB_ITEMS.UNITS}
        />
        <Tab
          label={TabLabel(3, 'Add members', selectedTab === TAB_ITEMS.MEMBERS, selectedTab)}
          disabled={selectedTab < TAB_ITEMS.MEMBERS}
        />
      </Tabs>
    );
  };

  const renderFooter = () => {
    return (
      <Stack direction='row' alignItems='center' sx={formFooterStyles}>
        <IconButton
          color='primary'
          aria-label='previous-button'
          onClick={() => setSelectedTab(selectedTab - 1)}
          disabled={selectedTab === TAB_ITEMS.GENERAL}
        >
          <ArrowBackIosOutlined />
        </IconButton>
        <IconButton
          color='primary'
          aria-label='next-button'
          onClick={() => setSelectedTab(selectedTab + 1)}
          disabled={disableNextButton}
        >
          <ArrowForwardIosOutlined />
        </IconButton>
      </Stack>
    );
  };

  return (
    <Drawer
      open={pathname === ADD_GROUPS_PATH}
      anchor='right'
      onClose={handleClose}
      PaperProps={{
        sx: { width: '720px', backgroundColor: 'background.default01', borderRadius: 0 },
      }}
    >
      <FormContainer
        title='Add new group'
        loading={isLoading}
        dirty={enableSubmit}
        renderHeader={renderHeader}
        renderFooter={renderFooter}
        onClose={handleClose}
        onSubmit={handleSubmit(handleSaveChange)}
      >
        {selectedTab === TAB_ITEMS.GENERAL && <GroupsFormTabGeneral control={control} />}
        {selectedTab === TAB_ITEMS.UNITS && (
          <GroupsFormTabUnits
            propertyIds={watch('propertyIds')}
            onChange={propertyIds => setValue('propertyIds', propertyIds, { shouldDirty: true })}
          />
        )}
        {selectedTab === TAB_ITEMS.MEMBERS && (
          <GroupsFormTabMembers
            memberIds={watch('memberIds')}
            onChange={memberIds => setValue('memberIds', memberIds, { shouldDirty: true })}
          />
        )}
      </FormContainer>
    </Drawer>
  );
};

const defaultAvatarStyle = {
  height: '24px',
  width: '24px',
  fontSize: 'inherit',
};

const defaultTextStyle = {
  textTransform: 'none',
  marginLeft: '8px',
  marginTop: '2px',
};
