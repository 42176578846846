// Devices
// @ts-nocheck - revisit this
export const enum BatteryStatus {
  GOOD = 'good',
  MEDIUM = 'medium',
  LOW = 'low',
  CRITICAL = 'critical',
  EMPTY = 'empty',
  NOT_INSTALLED = 'no-connection',
}

export interface ILock {
  id: number;
  device_name: string;
  name: string;
  battery?: number;
  vendor?: string;
  battery_status?: BatteryStatus;
  is_locked: boolean;
  is_online: boolean;
  device_id: string;
  provider: string;
  provider_method: string;
  device_type_id: number;
  battery_keypad?: number;
  battery_keypad_status?: BatteryStatus;
  data_name?: string;
  data_type_id?: number;
  is_lock_online?: boolean;
  device_external_id?: string;
  property_id: number;
  data: Record<string, unknown>;
}

export interface IIntercom {
  device_name: string;
  id: number;
  device_id: number;
  intercom_config_id: number;
  intercom_number_id: number;
  intercom_button: string;
  auto_buzz: boolean;
  sms_enabled: boolean;
  sms_temp_disabled?: boolean | null;
  voice: boolean;
  number: string;
  external_id: string;
  has_mms: boolean;
  has_sms: boolean;
  has_voice: boolean;
  forward?: string | null;
  device_type_id?: number;
  name?: null;
  property_id: number;
  vendor?: string;
  type?: string;
  data?: Record<string, unknown>;
}

export interface INetatmo {
  name: string;
  type: string;
  status: string;
  device_id: number;
  vendor: string;
  absolute_pressure: string;
  co2: number;
  humidity: number;
  noise: number;
  pressure: string;
  property_id?: number;
  temperature: number;
  data?: Record<string, unknown>;
}

export interface INoiseAware {
  name: string;
  type: string;
  status: string;
  device_id: number;
  vendor: string;
  property_id?: number;
  co2?: number;
  data?: Record<string, unknown>;
}

export interface ITado {
  device_id: number;
  device_type_id: number;
  device_vendor_id: string;
  name: string;
  status: string;
  type: string;
  vendor: string;
  property_id?: number;
  data?: Record<string, unknown>;
}

export interface IEcobee {
  device_id: number;
  device_type_id: number;
  device_vendor_id: string;
  name: string;
  status: string;
  type: string;
  vendor: string;
  property_id?: number;
  data?: Record<string, unknown>;
}

export type IDeviceData = ILock | IIntercom | INetatmo | ITado | IEcobee | INoiseAware;

export type IDevice = {
  property_id?: number;
  device_id?: number;
  type: string;
  vendor: string;
  data: IDeviceData;
};

export type IDeviceDto = {
  id: number;
  company_id: number;
  property_id: number;
  parent_device_id?: number;
  device_vendor_id?: string;
  device_type_id: number;
  name: string;
  settings: {
    settings: string;
  };
  category: string;
  device_name: string;
  device_company: string;
  device_version: string;
  status: string;
  offline_at: string;
  offline_notify_at: string;
  offline_reason: string;
  zone: string;
  integration_id: number;
};

export interface DeviceDict {
  [Key: number]: IDeviceData;
}
export interface PropertyDeviceDict {
  [Key: number]: number[];
}

export interface DeviceState {
  list: number[];
  byId: DeviceDict;
  listByPropertyId: PropertyDeviceDict;
  historical: unknown;
}

// Device Settings
export interface DeviceSettingState {
  byPropertyId: DeviceSettingDict;
}
export interface DeviceSettingDict {
  [Key: number]: DeviceSetting[];
}
export interface DeviceSetting {
  type: string;
  air_conditioning: boolean;
  device_id: number;
  id: number;
  high: number;
  low: number;
  power: boolean;
  thermostat_room_status: string;
}

export interface HistoricalDict {
  [Key: number]: EnviromentalDataPoint[];
}
export interface EnviromentalDataPoint {
  propertyId: string;
  co2: string;
  created_at: string;
  temperature: string;
  noise: string;
  humidity: string;
}

export interface IGraphDataPoint {
  propertyId: string;
  companyId: string;
  created_at: string;
  noise: string;
}

// For historical graphs to see the reservation areas on the graph
export interface IReferenceAreaData {
  name: string;
  x1: number;
  x2: number;
  ref;
}
