import { Box, Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Task } from '@operto/tasks-shared';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import useTranslation from 'hooks/useTranslation';
import MemberProfile from 'member/memberProfileGetter';
import { IMember } from 'member/memberType';
import React from 'react';
import { TableCell } from 'ui-library/Components/table/TableCell';
import { NOT_STARTED_STATUS } from './useTasks';

export default function tasksTableColumns(members?: IMember[], t?: (key: string) => string) {
  const renderStatus = ({ row }: GridRenderCellParams<unknown, Task>) => {
    const dueUtc = zonedTimeToUtc(row.dueDateLocalized, row.unitTimeZone || 'America/Vancouver');
    const nowUtc = new Date();
    const isOverdue = dueUtc < nowUtc;
    const isNotStarted = NOT_STARTED_STATUS.includes(row.status);
    const isInProgress = row.status === 'in progress';
    const isCanceled = row.status === 'canceled';

    return (
      <TableCell
        title={
          <StatusContainer
            status={
              isOverdue
                ? 'overdue'
                : isNotStarted
                ? 'notStarted'
                : isInProgress
                ? 'started'
                : isCanceled
                ? 'canceled'
                : 'completed'
            }
          />
        }
      />
    );
  };

  const renderPriority = ({ row }: GridRenderCellParams<unknown, Task>) => {
    return <TableCell title={<PriorityContainer priority={row.priority} />} />;
  };

  const renderDue = ({ row }: GridRenderCellParams<unknown, Task>) => {
    return (
      <TableCell
        sx={{ width: '100%' }}
        title={<DateContainer localizedDate={row.dueDateLocalized} timezone={row.timezone} />}
      />
    );
  };

  const renderRequested = ({ row }: GridRenderCellParams<unknown, Task>) => {
    return (
      <TableCell
        sx={{ width: '100%' }}
        title={<DateContainer localizedDate={row.createdAt} timezone={row.timezone} />}
      />
    );
  };

  const renderGuest = ({ row }: GridRenderCellParams<unknown, Task>) => {
    return (
      <TableCell
        sx={{ width: '100%' }}
        title={<NameContainer title={row.guest} subtitle={row.unit} />}
      />
    );
  };

  const renderTask = ({ row }: GridRenderCellParams<unknown, Task>) => {
    return (
      <TableCell
        sx={{ width: '100%' }}
        title={<NameContainer title={row.title} subtitle={row.description} />}
      />
    );
  };

  const renderAssignee = ({ row }: GridRenderCellParams<unknown, Task>) => {
    const member = members.find(member => +member?.id === +row.assigneeId);

    return (
      <Stack flexDirection='row' gap='8px' sx={{ width: '100%' }}>
        <TableCell title={<MemberProfile size='medium' memberData={member} />} />

        <TableCell title={member?.name} description={member?.email} />
      </Stack>
    );
  };

  return [
    {
      field: 'status',
      headerName: t('status'),
      sortable: true,
      width: 150,
      resizable: false,
      renderCell: renderStatus,
    },
    {
      field: 'priority',
      headerName: t('priority'),
      sortable: true,
      width: 100,
      resizable: false,
      renderCell: renderPriority,
    },
    {
      field: 'dueDateLocalized',
      headerName: t('due'),
      sortable: true,
      width: 100,
      resizable: false,
      renderCell: renderDue,
    },
    {
      field: 'createdAt',
      headerName: t('requested'),
      sortable: true,
      width: 100,
      resizable: false,
      renderCell: renderRequested,
    },
    {
      field: 'guest',
      headerName: t('guest'),
      flex: 1,
      sortable: true,
      renderCell: renderGuest,
    },
    {
      field: 'task',
      headerName: t('task'),
      flex: 1,
      sortable: true,
      renderCell: renderTask,
    },
    {
      field: 'assignee',
      headerName: t('assignee'),
      flex: 1,
      sortable: true,
      renderCell: renderAssignee,
    },
  ];
}

const StatusContainer = ({ status }: { status: string }) => {
  const { t } = useTranslation();

  const statusColor = {
    notStarted: 'rgba(0, 0, 0, 0.38)',
    canceled: 'rgba(0, 0, 0, 0.38)',
    overdue: 'rgba(211, 47, 47, 1)',
    started: 'rgba(21, 94, 255, 1)',
    completed: 'rgba(76, 175, 80, 1)',
  }[status];

  const statusText = {
    notStarted: t('not_started'),
    canceled: t('canceled'),
    overdue: t('overdue'),
    started: t('started'),
    completed: t('completed'),
  }[status];

  return (
    <Stack direction='row' gap={'8px'}>
      <Box
        sx={{
          backgroundColor: statusColor,
          borderRadius: '50px',
          padding: '10px',
        }}
      ></Box>

      <Typography variant='body-sm-400'>{statusText}</Typography>
    </Stack>
  );
};

const PriorityContainer = ({ priority }: { priority?: string }) => {
  const { t } = useTranslation();

  const priorityBgColor = priority
    ? {
        low: 'rgba(115, 214, 119, 1)',
        medium: 'rgba(255, 207, 67, 1)',
        high: 'rgba(211, 47, 47, 1)',
      }[priority]
    : 'rgba(0, 0, 0, 0.12)';

  const priorityText = priority
    ? {
        low: t('low'),
        medium: t('medium'),
        high: t('high'),
      }[priority]
    : t('none');

  const priorityColor = priority
    ? {
        low: 'black',
        medium: 'black',
        high: 'white',
      }[priority]
    : 'rgba(0, 0, 0, 0.6)';

  return (
    <Box
      sx={{
        backgroundColor: priorityBgColor,
        borderRadius: '4px',
        padding: '0 4px 0 4px',
        color: priorityColor,
      }}
    >
      <Typography variant='caption-sm-700' sx={{ padding: '0 2px 0 2px' }}>
        {priorityText}
      </Typography>
    </Box>
  );
};

const NameContainer = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <Stack direction='column' sx={{ width: '100%' }}>
      <Typography variant='body-sm-400' sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {title}
      </Typography>

      <Typography
        variant='caption-lg-400'
        sx={{ color: 'rgba(0, 0, 0, 0.6)', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {subtitle}
      </Typography>
    </Stack>
  );
};

const DateContainer = ({
  localizedDate = new Date().toISOString(),
  timezone = 'America/Vancouver',
}: {
  localizedDate: string;
  timezone: string;
}) => {
  const utcDate = zonedTimeToUtc(localizedDate, timezone);
  const datePart = format(utcToZonedTime(utcDate, timezone), 'yyyy-MM-dd', { timeZone: timezone });
  const timePart = format(utcToZonedTime(utcDate, timezone), 'hh:mm a zzz', { timeZone: timezone });

  return (
    <Stack direction='column'>
      <Typography variant='body-sm-400'>{datePart}</Typography>

      <Typography variant='caption-lg-400' sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
        {timePart}
      </Typography>
    </Stack>
  );
};
