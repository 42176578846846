import uniqBy from 'lodash/uniqBy';
import { RTKQapi } from 'redux/rtkq-api';
import {
  DEFAULT_PAGINATED_PARAMS,
  PaginatedData,
  PaginatedParams,
  ResponseData,
} from 'redux/rtkq-utils';
import { parseMembers } from './parsers';
import { MemberFilterType, MemberJSON, MembersState } from './types';

export const MEMBER_TAG = 'Member';
export const MEMBER_LIST_ID = 'LIST';

export const PARAM_KEY_STATUS = 'status';
export const PARAM_KEY_STAFF_TYPE = 'staff_type';

export const DEFAULT_GET_PARAMS = {
  ...DEFAULT_PAGINATED_PARAMS,
  [PARAM_KEY_STATUS]: 'active',
} as const;

export const INITIAL_STATE: MembersState = {
  members: [],
  pageNum: 0,
  numPerPage: 100,
  totalMembers: 0,
};

export type GetQueryParams = PaginatedParams & {
  filterType: MemberFilterType;
};

export type UpdateQueryParams = {
  groupId: number;
  memberIds: number[];
};

const membersApiWithTag = RTKQapi.enhanceEndpoints({ addTagTypes: [MEMBER_TAG] });

export const membersApi = membersApiWithTag.injectEndpoints({
  endpoints: builder => ({
    getMembers: builder.query<MembersState, void | GetQueryParams>({
      query: args => {
        let params: Record<string, string | number> = DEFAULT_GET_PARAMS;
        if (args) {
          const { filterType, ...rest } = args;
          params = { ...params, ...rest };
          if (filterType === MemberFilterType.CONTRACTOR || filterType === MemberFilterType.STAFF) {
            params[PARAM_KEY_STAFF_TYPE] = filterType;
          }

          if (filterType === MemberFilterType.INACTIVE) {
            params[PARAM_KEY_STATUS] = MemberFilterType.INACTIVE;
          }
        }

        return {
          url: 'v2/companies/members',
          params,
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const args = { ...queryArgs } as PaginatedParams;

        // exclude pagination from cache key
        delete args['pageNum'];
        delete args['numPerPage'];

        return `${endpointName}(${JSON.stringify(args)})`;
      },
      merge: (state: MembersState, incoming: MembersState) => {
        return {
          ...incoming,
          members:
            incoming.pageNum === 0
              ? incoming.members
              : uniqBy([...state.members, ...incoming.members], 'id'),
        };
      },
      providesTags: (state: MembersState = INITIAL_STATE) => {
        return [
          ...state.members.map(({ id }) => ({ type: MEMBER_TAG, id } as const)),
          { type: MEMBER_TAG, id: MEMBER_LIST_ID },
        ];
      },
      transformResponse: ({
        data: { records, pageNum, numPerPage, total_records },
      }: ResponseData<PaginatedData<MemberJSON>>) => ({
        members: parseMembers(records),
        pageNum,
        numPerPage,
        totalMembers: total_records,
      }),
    }),
  }),
});

export const { useGetMembersQuery, useLazyGetMembersQuery } = membersApi;
