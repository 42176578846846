import { Link, Typography } from '@mui/material';
import { connectStripeAccount } from 'api/paymentAPI';
import { Loading } from 'Common/Loading';
import { companySelector } from 'company/state/companySelectors';
import useTranslation from 'hooks/useTranslation';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

export const StripeRedirectPage = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const navigate = useNavigate();
  const company = useAppSelector(companySelector());
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const connect = useCallback(async () => {
    if (!!code && code.length > 0) {
      const response = await connectStripeAccount(company.id, code);
      if (response.status === 201) {
        setIsConnected(true);
        navigate('/settings/integrations');
      } else {
        console.log('Error connecting');
      }
    }
    setIsLoading(false);
  }, [code, navigate, company.id]);

  useEffect(() => {
    connect();
  }, [connect]);

  if (isLoading) {
    return (
      <div style={style}>
        <Loading />
      </div>
    );
  } else if (isConnected) {
    return (
      <div style={style}>
        <div>
          <Loading />
        </div>
        <div>{t('redirecting')}.</div>
      </div>
    );
  } else {
    return (
      <div style={style}>
        <Typography variant='body1'>
          {t('stripe_redirect_oauth_error', {
            link_integrations_page: (
              <Link href='/settings/integrations'>{t('integrations_page')}</Link>
            ),
          })}
        </Typography>
      </div>
    );
  }
};

const style: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column' as const,
  gap: '8px',
  height: '100vh',
};
