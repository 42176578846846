import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Switch,
  SxProps,
  Typography,
} from '@mui/material';
import { AddCard } from '@operto/ui-library';
import AccessExtensionDropDown from 'Common/DropDown/AccessExtensionDropDown';
import AccessFormatDropDown from 'Common/DropDown/AccessFormatDropDown';
import { ICompany } from 'company/companyType';
import { updateCompany } from 'company/state/companyAction';
import { getCurrentCompany } from 'company/state/companySelectors';
import { SnackBarInfoType, getSnackMessage } from 'data/SnackBarInfo';
import { logger } from 'lib/logger';
import { IMember } from 'member/memberType';
import { getMembers, updateMemberPreferences } from 'member/state/memberActions';
import { getSelf } from 'member/state/memberSelectors';
import React, { useEffect, useState } from 'react';
import { isAdmin, toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';

const PreferencesContainer = () => {
  const enum FormTypes {
    ACCESS_CODE,
    TEST_ACCOUNT,
    TEMPERATURE,
    DEVICE_TYPE,
  }
  const dispatch = useAppDispatch();
  const member = useAppSelector(getSelf()) as IMember;
  const company = useAppSelector(getCurrentCompany());
  const [currentCompany, setCurrentCompany] = useState<ICompany | null>(null);
  const [currentMember, setCurrentMember] = useState<IMember>(member);

  const cVariant = currentMember?.is_celsius ? 'contained' : 'outlined';
  const fVariant = currentMember?.is_celsius ? 'outlined' : 'contained';

  useEffect(() => {
    setCurrentCompany(company);
  }, [company]);

  const handleCelsius = () => {
    setCurrentMember({ ...member, is_celsius: true });
  };

  const handleFahrenheit = () => {
    setCurrentMember({ ...member, is_celsius: false });
  };

  const saveInfo = async (formType: FormTypes) => {
    let successMsg = '';
    let failedMsg = '';

    switch (formType) {
      case FormTypes.TEMPERATURE:
        successMsg = getSnackMessage(SnackBarInfoType.TEMPERATURE_SETTING_SUCCESS, {
          temperature: currentMember?.is_celsius ? 'Celsius' : 'Fahrenheit',
        });
        failedMsg = getSnackMessage(SnackBarInfoType.TEMPERATURE_SETTING_FAIL);
        break;
      case FormTypes.ACCESS_CODE:
        successMsg = getSnackMessage(SnackBarInfoType.ACCESS_CODE_SETTING_SUCCESS, {
          lockType: currentCompany?.accessCodeSetting || 'device',
        });
        failedMsg = getSnackMessage(SnackBarInfoType.ACCESS_CODE_SETTING_FAIL);
        break;
      case FormTypes.TEST_ACCOUNT:
        successMsg = getSnackMessage(SnackBarInfoType.TEST_ACCOUNT_SETTING_SUCCESS, {
          isActive: currentCompany?.testAccountEnabled,
        });
        failedMsg = getSnackMessage(SnackBarInfoType.TEST_ACCOUNT_SETTING_FAIL);
        break;
      default:
        break;
    }

    try {
      if (formType === FormTypes.TEMPERATURE) {
        await dispatch(updateMemberPreferences(currentMember));
      } else {
        await dispatch(updateCompany(currentCompany));
      }
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: successMsg,
          variant: SnackbarVariant.SUCCESS,
        }),
      );
    } catch (error) {
      logger.error(error);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: failedMsg,
          variant: SnackbarVariant.ERROR,
        }),
      );
      if (formType === FormTypes.TEMPERATURE) {
        setCurrentMember(member);
      }
    }
  };

  useEffect(() => {
    dispatch(getMembers());
  }, [dispatch]);

  return (
    <Grid item xs={12} lg={7}>
      {isAdmin() && (
        <Grid container sx={cardHolderGridStyles}>
          <AddCard
            title='Access code'
            onSaveClick={() => {
              saveInfo(FormTypes.ACCESS_CODE);
            }}
            showFooter
          >
            <Grid container alignItems='center'>
              <Grid item xs={12}>
                <Box sx={infoContainerStyles}>
                  <Box>
                    <Typography variant='subtitle1'>Valid access code format</Typography>
                    <Typography variant='body2'>
                      Detemine the correct access code formatting for your guests
                    </Typography>
                  </Box>
                  <AccessFormatDropDown
                    value={currentCompany?.accessCodeFormat}
                    onChange={(value: string) => {
                      setCurrentCompany({ ...currentCompany, accessCodeFormat: value });
                    }}
                  />
                </Box>
                <Divider />
                <Box sx={infoContainerStyles}>
                  <Box>
                    <Typography variant='subtitle1'>Access code extension</Typography>
                    <Typography variant='body2'>
                      If your access code requires a special character, please enter it here.
                    </Typography>
                  </Box>{' '}
                  <AccessExtensionDropDown
                    value={currentCompany?.accessCodeSetting}
                    onChange={(value: string) => {
                      setCurrentCompany({ ...currentCompany, accessCodeSetting: value });
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </AddCard>
        </Grid>
      )}
      {isAdmin() && (
        <Grid container sx={cardHolderGridStyles}>
          <AddCard
            title='Test Account'
            onSaveClick={() => saveInfo(FormTypes.TEST_ACCOUNT)}
            showFooter
          >
            <Grid container alignItems='center'>
              <Grid item xs={12}>
                <Box sx={infoContainerStyles}>
                  <Box>
                    <Typography variant='subtitle1'>
                      Enable or Disable Test Account status
                    </Typography>
                    <Typography variant='body2'>
                      Marks the account as a test account in our system
                    </Typography>
                  </Box>
                  <Switch
                    checked={currentCompany?.testAccountEnabled || false}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCurrentCompany({
                        ...currentCompany,
                        testAccountEnabled: event?.target.checked,
                      });
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </AddCard>
        </Grid>
      )}

      <Grid container sx={cardHolderGridStyles}>
        <AddCard
          title='Toggle Temperature Scale'
          onSaveClick={() => saveInfo(FormTypes.TEMPERATURE)}
          showFooter
        >
          <Grid container alignItems='center'>
            <Grid item xs={12}>
              <Box sx={infoContainerStyles}>
                <Box>
                  <Typography variant='subtitle1'>Temperature Settings</Typography>
                  <Typography variant='body2'>
                    Determines the temperature format in Connect and Guest Portal
                  </Typography>
                </Box>
                <ButtonGroup color='primary' aria-label='outlined primary button group'>
                  <Button sx={toggleButtonStyles} variant={cVariant} onClick={handleCelsius}>
                    Celsius
                  </Button>
                  <Button sx={toggleButtonStyles} variant={fVariant} onClick={handleFahrenheit}>
                    Fahrenheit
                  </Button>
                </ButtonGroup>
              </Box>
            </Grid>
          </Grid>
        </AddCard>
      </Grid>
    </Grid>
  );
};

const infoContainerStyles: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
};

const toggleButtonStyles: SxProps = {
  height: '36px',
  width: '111px',
  fontSize: '14px',
  padding: '0',
};

const cardHolderGridStyles: SxProps = {
  marginBottom: '24px',
};

export default PreferencesContainer;
