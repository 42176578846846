import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { Actions } from '../../../types/actions';
import { RefreshLoader } from '../../../types/ui';

const defaultState = {
  memberCode: '',
  previousMemberCode: '',
  memberId: 0,
  match: false,
  failed: false,
};

const showRefreshLoader: Reducer<boolean> = (state = defaultState.match, action) => {
  switch (action.type) {
    case Actions.toggleRefreshLoaderPending:
      return true;
    case Actions.toggleRefreshLoaderSuccess:
      return false;
    default:
      return state;
  }
};
const previousMemberAccessCode: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.toggleRefreshLoaderPending:
      return action.previousMemberAccessCode;
    default:
      return state;
  }
};

const refreshFailed: Reducer<boolean> = (state = defaultState.failed, action) => {
  switch (action.type) {
    case Actions.toggleRefreshLoaderFailed:
      return true;
    case Actions.toggleRefreshLoaderSuccess:
      return false;
    default:
      return state;
  }
};

const aRefreshLoader: Reducer<RefreshLoader> = combineReducers<RefreshLoader>({
  showRefreshLoader,
  previousMemberAccessCode,
  refreshFailed,
});

export default aRefreshLoader;
