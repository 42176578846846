import SendIcon from '@mui/icons-material/Send';
import {
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import RightSideTopbar from 'Common/Slidebar/components/RightSideTopbar';
import { OpertoLogger } from 'Logger/logger';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppSelector } from 'redux/hooks';
import AddCard from 'ui-library/Components/card/AdderCard';
import {
  FormTextField,
  FormTextFieldRegexEmail,
  FormTextFieldRegexPhone,
} from 'ui-library/Components/input/FormTextField';
import { ActionButton, MainContentContainer } from 'ui-library/Styled/sidebar';

type SendGPLinkFormProps = {
  handleClose?: () => void;
  reservationId: number;
  gpLink: string;
};

type SendGPLinkFormDataProps = {
  messageType: string;
  email: string;
  phone: string;
  message: string;
  subject: string;
};

const MessageType = {
  Email: 'email',
  WhatsApp: 'whatsapp',
  SMS: 'sms',
};

const SendGPLinkForm = ({ handleClose, reservationId, gpLink }: SendGPLinkFormProps) => {
  const { control, handleSubmit, setValue } = useForm();
  const guestInfo = useAppSelector(state => state.guests.byId[reservationId]);
  const [selectedValue, setSelectedValue] = React.useState(MessageType.Email);

  const handleSend = (data: SendGPLinkFormDataProps) => {
    try {
      let newWindow;

      if (data.messageType === MessageType.Email) {
        newWindow = window.open(
          `mailto:${data.email}?subject=${data.subject}&body=${encodeURIComponent(data.message)}`,
          '_blank',
        );
      } else if (data.messageType === MessageType.WhatsApp) {
        const cleanNumber = data.phone.replace(/\D/g, '');
        newWindow = window.open(
          `https://wa.me/${cleanNumber}?text=${encodeURIComponent(data.message)}`,
          '_blank',
        );
      }

      if (!newWindow) throw new Error('Failed to open new window');
    } catch (error) {
      OpertoLogger.Log(error);
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('messageType', event.target.value);
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    setValue('email', guestInfo?.email ?? '');
    setValue('phone', guestInfo?.phone ?? '');
    setValue(
      'message',
      `Thank you for staying with us. Here is our guest portal link. \r\n${gpLink}`,
    );
  }, [gpLink, guestInfo?.email, guestInfo?.phone, setValue]);

  return (
    <MainContentContainer>
      <RightSideTopbar
        title={'Send Guest Portal Link to Guest'}
        handleClose={handleClose}
        buttonComponent={
          <ActionButton
            variant='contained'
            color='primary'
            startIcon={<SendIcon />}
            onClick={handleSubmit(handleSend)}
            testId='send-button'
          >
            Send
          </ActionButton>
        }
      />
      <Container sx={{ pt: 2 }}>
        <AddCard title='Message'>
          <FormControl sx={{ width: '100%' }}>
            <Controller
              name='messageType'
              control={control}
              defaultValue={MessageType.Email}
              render={({ field }) => (
                <RadioGroup
                  aria-labelledby='message-type-group-lebel'
                  name='message-type-group'
                  value={field.value}
                  onChange={handleRadioChange}
                  row
                  data-testid='message-type-group'
                  sx={{ mb: 4 }}
                >
                  <FormControlLabel
                    sx={{ mr: 4 }}
                    value={MessageType.Email}
                    control={<Radio />}
                    label='Email'
                    data-testid='email-button'
                  />
                  <FormControlLabel
                    sx={{ mr: 4 }}
                    value={MessageType.WhatsApp}
                    control={<Radio />}
                    label='WhatsApp'
                    data-testid='whatsapp-button'
                  />
                </RadioGroup>
              )}
            />
            {selectedValue === MessageType.Email && (
              <>
                <InputLabel sx={{ transform: 'none', position: 'relative' }} focused={false}>
                  Email address
                </InputLabel>
                <FormTextField
                  rules={{
                    required: {
                      value: true,
                      message: 'Email address is required',
                    },
                    pattern: {
                      value: FormTextFieldRegexEmail,
                      message: 'Valid email address format is required',
                    },
                  }}
                  field='email'
                  type='email'
                  control={control}
                  data-testid='email-input'
                  sx={{ ml: 0, width: 1 }}
                />
                <InputLabel sx={{ transform: 'none', position: 'relative' }} focused={false}>
                  Subject
                </InputLabel>
                <FormTextField
                  rules={{
                    required: {
                      value: true,
                      message: 'Subject is required',
                    },
                  }}
                  field='subject'
                  type='subject'
                  control={control}
                  data-testid='subject-input'
                  sx={{ ml: 0, width: 1 }}
                />
              </>
            )}
            {(selectedValue === MessageType.SMS || selectedValue === MessageType.WhatsApp) && (
              <>
                <InputLabel sx={{ transform: 'none', position: 'relative' }} focused={false}>
                  Phone number
                </InputLabel>
                <FormTextField
                  rules={{
                    required: {
                      value: true,
                      message: 'Phone number is required',
                    },
                    pattern: {
                      value: FormTextFieldRegexPhone,
                      message: 'Valid phone number format is required',
                    },
                  }}
                  field='phone'
                  type='phone'
                  control={control}
                  data-testid='phone-input'
                />
              </>
            )}

            <InputLabel sx={{ transform: 'none', position: 'relative' }} focused={false}>
              Message
            </InputLabel>
            <FormTextField
              rules={{
                required: {
                  value: true,
                  message: 'Message is required',
                },
              }}
              field='message'
              type='message'
              control={control}
              multiline
              minRows={10}
              data-testid='message-input'
              sx={{ ml: 0, mt: 2, width: 1 }}
            />
          </FormControl>
        </AddCard>
      </Container>
    </MainContentContainer>
  );
};

export default SendGPLinkForm;
