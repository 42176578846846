import DragHandleIcon from '@mui/icons-material/DragHandle';
import { GridRowOrderChangeParams } from '@mui/x-data-grid-pro';
import React, { useCallback, useEffect, useState } from 'react';
import { Table, TableProps } from 'ui-library/Components/table/Table';

export type SortTableProps = TableProps & {
  title?: string | React.ReactNode;
  onAdd?: () => void;
  onSearch?: (searchString?: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSort: (sorted: any[], sourceIndex: number, targetIndex: number) => void;
};

export const SortTable = ({ rows, title, onSearch, onAdd, onSort, ...props }: SortTableProps) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = useCallback(
    (value?: string) => {
      setSearchValue(value);
      onSearch?.(value);
    },
    [onSearch],
  );

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const rowsClone = [...rows];
    const row = rowsClone.splice(params.oldIndex, 1)[0];
    rowsClone.splice(params.targetIndex, 0, row);
    onSort(rowsClone, params.oldIndex, params.targetIndex);
  };

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  return (
    <Table
      hideFooterPagination
      hideFooterSelectedRowCount
      rows={rows}
      rowReordering
      components={{ RowReorderIcon: DragHandleIcon }}
      onRowOrderChange={handleRowOrderChange}
      searchToolbarProps={
        onSearch && { onChange: handleSearch, onAdd, title, value: searchValue ?? '' }
      }
      {...props}
    />
  );
};
