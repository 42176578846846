// @ts-nocheck

import { Grid, Typography } from '@mui/material';
import SelectOffset from 'Common/TextField/SelectOffset';
import { emptyProperty, Property } from 'property/propertyType';
import React from 'react';
import { AddCard } from '@operto/ui-library';
import SimpleReactValidator from 'simple-react-validator';

export interface IOffsetsContainerProps {
  data?: Property;
  validator?: SimpleReactValidator;
  onChange?: (id: string, value: string) => void;
}

const OffsetsContainer = (props: IOffsetsContainerProps) => {
  const { data, validator, onChange } = props;
  const [errors] = React.useState(emptyProperty);
  const valueChanger = (e: React.ChangeEvent<HTMLInputElement>) => {
    const field = e.target.id || e.target.name;
    onChange(field, e.target.value);
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <AddCard title='Reservation Offsets'>
          <Grid container direction='column'>
            <Typography align='left'>Set property reservation offsets</Typography>
            <Grid container direction='row' spacing={4}>
              <Grid item md={6} xs={12}>
                <SelectOffset
                  id='checkin_offset'
                  value={data?.checkin_offset}
                  onChange={valueChanger}
                  label='Checkin Offset'
                  validator={validator.message('checkin_offset', data?.checkin_offset, 'required')}
                  error={errors?.checkin_offset}
                />
                <SelectOffset
                  id='checkout_offset'
                  value={data?.checkout_offset}
                  onChange={valueChanger}
                  label='Checkout Offset'
                  validator={validator.message(
                    'checkout_offset',
                    data?.checkout_offset,
                    'required',
                  )}
                  error={errors?.checkout_offset}
                />
              </Grid>
            </Grid>
          </Grid>
        </AddCard>
      </Grid>

      <Grid item xs={12}>
        <AddCard title='Thermostat Offsets'>
          <Grid container direction='column'>
            <Typography align='left'>Select thermostat reservation offsets</Typography>
            <Grid container direction='row' spacing={4}>
              <Grid item md={6} xs={12}>
                <SelectOffset
                  id='thermostat_checkin_offset'
                  value={data?.thermostat_checkin_offset}
                  onChange={valueChanger}
                  label='Checkin Offset'
                  validator={validator.message(
                    'thermostat_checkin_offset',
                    data?.thermostat_checkin_offset,
                    'required',
                  )}
                  error={errors?.thermostat_checkin_offset}
                />
                <SelectOffset
                  id='thermostat_checkout_offset'
                  value={data?.thermostat_checkout_offset}
                  onChange={valueChanger}
                  label='Checkout Offset'
                  validator={validator.message(
                    'thermostat_checkout_offset',
                    data?.thermostat_checkout_offset,
                    'required',
                  )}
                  error={errors?.thermostat_checkout_offset}
                />
              </Grid>
            </Grid>
          </Grid>
        </AddCard>
      </Grid>
    </Grid>
  );
};

export default OffsetsContainer;
