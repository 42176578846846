import { SxProps } from '@mui/material';
import { theme, useMedia } from '@operto/ui';

export const useTopBarStyles = () => {
  const { isMobile, isDesktop } = useMedia();

  const toolbarStyles: SxProps = {
    backgroundColor: isDesktop ? 'transparent' : 'background.paper',
    borderBottom: isDesktop ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
    padding: isDesktop ? '24px 0px' : '10px 12px',
    marginTop: '10px',
    minHeight: 'auto!important',
    width: '100%',
  };

  const mainHeaderIconStyles: SxProps = {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    width: isMobile ? '32px' : '40px',
    height: isMobile ? '32px' : '40px',
    borderRadius: isMobile ? '4px' : '8px',
    marginRight: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const headerContainerStyles: SxProps = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: isMobile ? 'background.paper' : 'transparent',
  };

  const breadCrumbStyles: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexGrow: 1,
  };

  const unitNameContainerStyles: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  };

  const headerTitleStyles: SxProps = {
    fontSize: isMobile ? 14 : 20,
    fontWeight: isMobile ? 600 : 700,
  };

  const lastBreadCrumbItemStyles: SxProps = {
    fontSize: isMobile ? 16 : 24,
    fontWeight: 700,
    color: 'text.primary',
    paddingTop: 0,
  };

  const headerItemStyles: SxProps = {
    color: 'text.primary',
  };

  return {
    toolbarStyles,
    mainHeaderIconStyles,
    headerContainerStyles,
    breadCrumbStyles,
    unitNameContainerStyles,
    headerTitleStyles,
    lastBreadCrumbItemStyles,
    headerItemStyles,
  };
};
