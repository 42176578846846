import NoiseIcon from '@mui/icons-material/VolumeUp';
import { NOISE_STATUSES, TNoiseStatuses } from 'Common/Tables/Properties/PropertyDataHelpers';
import React from 'react';
import { RowCellTableTooltip } from 'ui-library/Components/table/TableTooltip';

export interface NoiseCellProps {
  noiseLevel: TNoiseStatuses;
}

export type NoiseCellTooltipText = 'Low risk' | 'High risk';

const renderNoiseCellToolTip = (noiseRisk: NoiseCellTooltipText, noiseicon: React.ReactElement) => {
  return <RowCellTableTooltip title={noiseRisk}>{noiseicon}</RowCellTableTooltip>;
};

const NoiseCell = ({ noiseLevel }: NoiseCellProps) => {
  switch (noiseLevel) {
    case NOISE_STATUSES.LOW_RISK:
      return renderNoiseCellToolTip('Low risk', <NoiseIcon color='primary' />);

    case NOISE_STATUSES.MED_RISK:
    case NOISE_STATUSES.HIGH_RISK:
      return renderNoiseCellToolTip('High risk', <NoiseIcon color='error' />);

    default:
      return <NoiseIcon />;
  }
};

export default NoiseCell;
