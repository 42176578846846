// @ts-nocheck
import { GridColDef, GridRenderCellParams, GridSelectionModel } from '@mui/x-data-grid-pro';
import { TableActionbar } from '@operto/ui-library';
import { permissionString } from 'Common/Tables/helpers';
import SearchField from 'Common/TextField/SearchField';
import { isFetchMore } from 'helper/tableHelper';
import MemberProfile from 'member/memberProfileGetter';
import { IMember, RecordTrack } from 'member/memberType';
import { getMembersByCompany } from 'member/state/memberActions';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useAppDispatch } from 'redux/hooks';
import styled from 'styled-components';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { PageContentContainer } from 'ui-library/Styled/containers';
import { ActionDataGrid } from 'ui-library/Styled/tables';

const ROWS_PER_PAGE = [10, 25, 50, 100];

export interface BasicMemberTableProps {
  meta: RecordTrack;
  members: IMember[];
  filterType: string;
  updateUnchecked: (uncheckedList: string[]) => void;
  updateNewChecked?: (newCheckedItem: unknown) => void;
  selectionModel: [];
  updateChecked: (checkedList: string[]) => void;
  searchValue: string;
  handleSearch: (event: React.MouseEvent<HTMLButtonElement>) => void;
  updateNewDeleted: (newItemToDelete: unknown) => void;
}

const BasicMemberTable = ({
  meta,
  members,
  selectionModel,
  updateUnchecked,
  updateChecked,
  filterType,
  searchValue,
  handleSearch,
  updateNewChecked,
  updateNewDeleted,
}: BasicMemberTableProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [pageNum, setPageNum] = React.useState(0);
  const renderAvatar = (params: GridRenderCellParams) => {
    const { row: data } = params;
    return <MemberProfile size='medium' memberData={data} />;
  };

  const renderRole = (params: GridRenderCellParams) => (
    <div>{permissionString(params.row.role)}</div>
  );

  const columnsDef: GridColDef[] = [
    { field: 'id', headerName: 'Avatar', renderCell: renderAvatar, width: 50, sortable: false },
    { field: 'name', headerName: 'Member Name', flex: 1.5, sortable: false },
    { field: 'job_title', headerName: 'Role', flex: 1, sortable: false },
    {
      field: 'role',
      headerName: 'Account Type',
      renderCell: renderRole,
      flex: 1,
      sortable: false,
    },
  ];

  const columnsDefForMobile: GridColDef[] = [
    { field: 'name', headerName: 'Member Name', width: 140 },
    { field: 'job_title', headerName: 'Role', flex: 1, sortable: false },
  ];

  const handleSelect = (data: GridSelectionModel) => {
    if (selectionModel?.length > data?.length) {
      // removing
      const newItemToDelete = selectionModel.filter(item => !data.includes(item));
      updateNewDeleted(newItemToDelete);
    } else if (selectionModel?.length < data?.length) {
      // adding
      const newCheckedItem = data[data.length - 1];
      updateNewChecked(newCheckedItem);
    }

    if (selectionModel?.length !== data?.length) {
      updateChecked(data);
      const uncheckedArr = members?.filter(member => !data.includes(member.id));
      const uncheckedIds = uncheckedArr.map(member => member.id);
      updateUnchecked(uncheckedIds);
    }
  };

  const handleFetchMore = async (page: number) => {
    if (meta.total_records) setPageNum(page);
    if (isFetchMore(meta, pageNum, loading, members, 10)) {
      const newPageNum = meta.pageNum + 1;
      setLoading(true);
      const data = {
        filterType,
        pageNum: newPageNum,
        numPerPage: meta.numPerPage,
        shouldInsert: true,
      };
      dispatch(getMembersByCompany(data)).then(() => {
        setLoading(false);
      });
    }
  };

  return (
    <MainContainer isMobile={isMobile}>
      <TableActionbar
        endSection={<SearchField onChange={handleSearch} value={searchValue} size='small' />}
        fullWidth
      />
      <LoadingContainer loading={loading}>
        <div style={{ height: 500, width: '100%' }}>
          <ActionDataGrid
            disableColumnMenu
            rowsPerPageOptions={ROWS_PER_PAGE}
            actionbar
            rows={members}
            columns={isMobile ? columnsDefForMobile : columnsDef}
            pageSize={10}
            className='material-icons'
            checkboxSelection
            pagination
            onPageChange={handleFetchMore}
            rowCount={meta?.total_records}
            onSelectionModelChange={handleSelect}
            selectionModel={selectionModel}
          />
        </div>
      </LoadingContainer>
    </MainContainer>
  );
};

const MainContainer = styled(PageContentContainer)<{ isMobile: boolean }>`
  padding: 8px 0 0 0;
`;

export default BasicMemberTable;
