import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Tag } from '@operto/tags-shared';
import React from 'react';
import { FilterDataType, SlideDownDataType } from 'types/filter-type';

type CheckboxOptionProps = {
  option: FilterDataType | SlideDownDataType | Tag;
  checked: boolean;
  onChange: (checked: boolean) => void;
  testID?: string;
};

export const CheckboxOption = ({ testID, option, checked, onChange }: CheckboxOptionProps) => {
  return (
    <ListItem
      sx={{ padding: 0, cursor: 'pointer' }}
      data-testid={`${testID}-container`}
      onClick={() => onChange(!checked)}
    >
      <ListItemIcon sx={{ minWidth: '0' }}>
        <Checkbox checked={checked} />
      </ListItemIcon>
      {option && 'icon' in option && option.icon}
      <ListItemText sx={{ padding: '0 0 0 5px' }} primary={option.label} />
    </ListItem>
  );
};
