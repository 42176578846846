import {
  IPropertyAlert,
  IPropertyAlertOverview,
  PROPERTY_ALERT_TYPE,
} from 'propertyAlerts/propertyAlertType';
import React, { Fragment, useEffect, useState } from 'react';
import BatteryIcon from 'ui-library/Components/icon/BatteryIcon';
import HubIcon from 'ui-library/Components/icon/HubIcon';
import LockOfflineIcon from 'ui-library/Components/icon/LockOfflineIcon';
import NoiseIcon from 'ui-library/Components/icon/NoiseIcon';
import OccupancyIcon from 'ui-library/Components/icon/OccupancyIcon';
import ThermostatIcon from 'ui-library/Components/icon/ThermostatIcon';

interface MultipleAlertRendererProps {
  rowData: IPropertyAlertOverview;
}

const MultipleAlertRenderer = ({ rowData }: MultipleAlertRendererProps) => {
  const [icons, setIcons] = useState<JSX.Element[]>([]);

  const getIconForAlertType = (alert: IPropertyAlert, isSummaryRow: boolean) => {
    switch (alert.type) {
      case PROPERTY_ALERT_TYPE.BATTERY_WARNING:
        return alert.data && (alert.data.battery_status || alert.data.battery) ? (
          <BatteryIcon status={alert.data.battery_status} />
        ) : undefined;
      case PROPERTY_ALERT_TYPE.COMPLIANCE_RISK:
        return alert.title.indexOf('occupancy') !== -1 ? <OccupancyIcon /> : <NoiseIcon />;
      case PROPERTY_ALERT_TYPE.ENVIRONMENT_RISK:
        return <ThermostatIcon />;
      case PROPERTY_ALERT_TYPE.LOCK_OFFLINE:
        return <LockOfflineIcon />;
      case PROPERTY_ALERT_TYPE.HUB_OFFLINE:
        return <HubIcon showAsPill={!isSummaryRow} />;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (rowData && rowData.alerts) {
      const iconTypes: string[] = [];
      const uniqueIcons: JSX.Element[] = [];
      rowData.alerts.forEach(alert => {
        if (!iconTypes.includes(alert.type)) {
          const icon = getIconForAlertType(alert, true);
          if (icon) {
            iconTypes.push(alert.type);
            uniqueIcons.push(icon);
          }
        }
      });
      setIcons(uniqueIcons);
    }
  }, [rowData]);

  return (
    <>
      {icons.map((icon, i) => (
        <Fragment key={`icon${i}`}> {icon}</Fragment>
      ))}
      &nbsp;Multiple
    </>
  );
};

export default MultipleAlertRenderer;
