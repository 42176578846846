import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import GPPreviewContainer from 'Pages/GuestPortal/Preview/GPPreviewContainer';
import * as React from 'react';
import styled from 'styled-components';

export interface GlobalGpPreviewProps {
  propertyId?: number;
  onClose?: () => void;
}

export default function GlobalGpPreivew({ propertyId, onClose }: GlobalGpPreviewProps) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <>
      <Dialog maxWidth='lg' open={open} onClose={handleClose}>
        <DialogTitle>Previewing Guest Portal {propertyId ? `Unit ${propertyId}` : ''}</DialogTitle>
        <DialogContent>
          <GPPreviewContainer globalPropertyId={`${propertyId}`} />
        </DialogContent>
        <ActionsRow>
          <OpertoButton variant='contained' backgroundcolor='primary.main' onClick={handleClose}>
            Exit
          </OpertoButton>
        </ActionsRow>
      </Dialog>
    </>
  );
}

const ActionsRow = styled(DialogActions)`
  && {
    display: flex;
    justify-content: space-between;
    padding-left: 25px;
    padding-bottom: 25px;
  }
`;
const OpertoButton = styled(Button)<{ backgroundcolor: string }>`
  && {
    background-color: ${props => props.backgroundcolor};
  }
`;
