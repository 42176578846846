import { Box, List } from '@mui/material';
import { useMedia } from '@operto/ui';
import { OpertoTheme } from 'App/OpertoTheme';
import BackupCodeListItem from 'code/BackupCodeListItem';
import { ICode } from 'code/codeType';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { CodeTypes } from 'types/ui';
import Widget from 'ui-library/Components/widget/Widget';
import BackupCodeFooter from './BackupCodeFooter';

export interface BackupCodeCardProps {
  codes: ICode[];
  propertyId: number;
  isOnUnitDashboard?: boolean;
}

const BackupCodeCard = ({ codes, propertyId, isOnUnitDashboard = false }: BackupCodeCardProps) => {
  const navigate = useNavigate();
  const handleDropdownClick = (type: CodeTypes) => {
    switch (type) {
      case CodeTypes.BACKUP_CODES:
        navigate(`/units/${propertyId}/access/unit-code/backups`);
        return;
      case CodeTypes.GUEST_CODES:
        navigate(`/units/${propertyId}/access/unit-code/guests`);
        return;
      default:
        navigate(`/units/${propertyId}/access/unit-code/members`);
    }
  };

  const { isMobile } = useMedia();

  return (
    <Box sx={{ padding: isMobile ? '0' : 'auto' }}>
      <Widget
        title='Backup Codes'
        footer={<BackupCodeFooter onClick={handleDropdownClick} />}
        isCarouselCard={false}
        isMainActivity={false}
        isOnUnitDashboard={isOnUnitDashboard}
        smartCardType='backup'
        fill={OpertoTheme.guestPortalIconColor}
      >
        <>
          <List data-testid='backup-code-list'>
            {codes?.map(code => (
              <BackupCodeListItem
                key={`code-${code.id}`}
                codeId={code.id}
                data-testid='backup-code-list-item'
              />
            ))}
          </List>
        </>
      </Widget>
    </Box>
  );
};

export default BackupCodeCard;
