// @ts-nocheck revisit this later

import { NotifiableError } from '@bugsnag/js';
import { AxiosResponse } from 'axios';
import { IDevice } from 'device/deviceType';
import { OpertoLogger } from 'Logger/logger';
import { AppDispatch } from 'redux/store';
import { IThreshold } from 'threshold/thresholdType';
import { AlertType, IAlert, IMetricType } from 'types/activeAlerts';
import * as api from '../../api/netatmo';
import { Actions } from '../../types/actions';
import { ApplicationState } from '../reducers';

// alert maps into threshold
const getThresholdName = (alertTypeId: number) => {
  switch (alertTypeId) {
    case AlertType.ALERT_ENVIRONMENT_ISSUE_TEMPERATURE_HIGH_ID:
      return 'tempHigh';
    case AlertType.ALERT_ENVIRONMENT_ISSUE_TEMPERATURE_LOW_ID:
      return 'tempLow';
    case AlertType.ALERT_ENVIRONMENT_ISSUE_HUMIDITY_HIGH_ID:
      return 'humidityHigh';
    case AlertType.ALERT_ENVIRONMENT_ISSUE_HUMIDITY_LOW_ID:
      return 'humidityLow';
    case AlertType.ALERT_COMPLIANCE_ISSUE_CO2_MED_ID:
      return 'co2Med';
    case AlertType.ALERT_COMPLIANCE_ISSUE_CO2_HIGH_ID:
      return 'co2High';
    case AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_MED_ID:
      return 'noiseMed';
    case AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_HIGH_ID:
      return 'noiseHigh';
    case AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_QUIET_ID:
      return 'noiseQuiet';
    case AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_REG_ID:
      return 'noiseReg';
    default:
      return null;
  }
};

const getQuietHoursFrom = (alert: IAlert) => {
  if (alert.alert_type_id === AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_QUIET_ID) {
    const threshold =
      alert &&
      alert.thresholds &&
      alert.thresholds.find(
        (each: IThreshold) =>
          each.metric_type.id === IMetricType.SCHEDULE && each.operator === '>=',
      );
    return threshold;
  }
};
const getQuietHoursTo = (alert: IAlert) => {
  if (alert.alert_type_id === AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_QUIET_ID) {
    const threshold =
      alert &&
      alert.thresholds &&
      alert.thresholds.find(
        (each: IThreshold) => each.metric_type.id === IMetricType.SCHEDULE && each.operator === '<',
      );
    return threshold;
  }
};

const getThreshold = (alert: IAlert) => {
  switch (alert.alert_type_id) {
    case AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_MED_ID:
    case AlertType.ALERT_COMPLIANCE_ISSUE_CO2_MED_ID:
      return (
        alert &&
        alert.thresholds &&
        alert.thresholds.find((each: IThreshold) => each.operator === '>' || each.operator === '>=')
      );
    case AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_REG_ID:
      return (
        alert &&
        alert.thresholds &&
        alert.thresholds.find(
          (each: IThreshold) =>
            (each.operator === '>' && each.metric_type.id === 4) ||
            (each.operator === '>=' && each.metric_type.id === 4),
        )
      );
    case AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_QUIET_ID:
      return (
        alert &&
        alert.thresholds &&
        alert.thresholds.find(
          (each: IThreshold) => each.metric_type.id === 4 && each.operator === '>=',
        )
      );
    case AlertType.ALERT_ENVIRONMENT_ISSUE_TEMPERATURE_HIGH_ID:
    case AlertType.ALERT_ENVIRONMENT_ISSUE_TEMPERATURE_LOW_ID:
    case AlertType.ALERT_ENVIRONMENT_ISSUE_HUMIDITY_LOW_ID:
    case AlertType.ALERT_ENVIRONMENT_ISSUE_HUMIDITY_HIGH_ID:
    case AlertType.ALERT_COMPLIANCE_ISSUE_CO2_HIGH_ID:
    case AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_HIGH_ID:
      return alert.thresholds && alert.thresholds[0];
    default:
      return null;
  }
};
const otherThresholdIdSwitch = (alertId: number) => {
  switch (alertId) {
    case AlertType.ALERT_COMPLIANCE_ISSUE_CO2_HIGH_ID:
      return AlertType.ALERT_COMPLIANCE_ISSUE_CO2_MED_ID;
    case AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_HIGH_ID:
      return AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_MED_ID;
    default:
      return 0;
  }
};

export const getNetatmoThresholds = (propertyId: string) => (dispatch: AppDispatch) => {
  api
    .getThresholds(propertyId)
    .then((data: AxiosResponse) => {
      const alerts = data.data.data.reduce((accum: unknown, alert: IAlert) => {
        if (alert && alert.thresholds && alert.thresholds[1]) {
          const threshold = alert.thresholds.find((each: IThreshold) => each.operator === '<');
          if (threshold) {
            return {
              ...accum,
              [alert.alert_type_id]: threshold.id,
            };
          }
        }
        return accum;
      }, {});
      const thresholds = data.data.data.reduce(
        (accum: unknown, each: IAlert) => {
          const thresholdName = getThresholdName(each.alert_type_id);
          const threshold = getThreshold(each);
          const quietFrom = getQuietHoursFrom(each);
          const quietTo = getQuietHoursTo(each);
          const otherThresholdId =
            threshold &&
            threshold.id &&
            otherThresholdIdSwitch(each.alert_type_id) &&
            alerts[otherThresholdIdSwitch(each.alert_type_id)]
              ? { otherId: alerts[otherThresholdIdSwitch(each.alert_type_id)] }
              : { otherId: null };
          if (thresholdName && thresholdName === 'noiseQuiet') {
            return {
              ...accum,
              [thresholdName]: {
                value: threshold && Number(threshold.value),
                id: threshold && threshold.id,
                toId: quietTo.id,
                to: quietTo && quietTo.value,
                fromId: quietFrom.id,
                from: quietFrom && quietFrom.value,
                ...otherThresholdId,
              },
            };
          }
          if (thresholdName && thresholdName === 'noiseReg') {
            return {
              ...accum,
              [thresholdName]: {
                value: threshold && Number(threshold.value),
                id: threshold && threshold.id,
                ...otherThresholdId,
              },
            };
          }
          if (thresholdName) {
            return {
              ...accum,
              [thresholdName]: {
                value: threshold && threshold.value,
                id: threshold && threshold.id,
                ...otherThresholdId,
              },
            };
          }
          return accum;
        },
        {
          tempHigh: { id: '', value: 0 },
          tempLow: { id: '', value: 0 },
          humidityHigh: { id: '', value: 0 },
          humidityLow: { id: '', value: 0 },
          co2Med: { id: '', value: 0 },
          co2High: { id: '', value: 0 },
          noiseMed: { id: '', value: 0 },
          noiseHigh: { id: '', value: 0 },
          noiseReg: { id: '', value: 0 },
          noiseQuiet: { id: '', value: 0, to: '', from: '' },
        },
      );
      const thresholdByDeviceId: Record<string, unknown> = {};
      if (data.data.data.length > 0) {
        data.data.data.map((each: IAlert) => {
          if (each.devices) {
            if (typeof thresholdByDeviceId[parseInt(each.devices[0])] === 'undefined') {
              thresholdByDeviceId[parseInt(each.devices[0])] = {};
            }
            const thresholdName = getThresholdName(each.alert_type_id);
            const threshold = getThreshold(each);
            const otherThresholdId =
              threshold &&
              threshold.id &&
              otherThresholdIdSwitch(each.alert_type_id) &&
              alerts[otherThresholdIdSwitch(each.alert_type_id)]
                ? {
                    otherId: alerts[otherThresholdIdSwitch(each.alert_type_id)],
                  }
                : { otherId: null };
            if (thresholdName) {
              const alertThreshold = {
                ...thresholdByDeviceId[parseInt(each.devices[0])],
                [thresholdName]: {
                  id: threshold && threshold.id,
                  value: threshold && Number(threshold.value),
                  ...otherThresholdId,
                },
              };
              thresholdByDeviceId[parseInt(each.devices[0])] = alertThreshold;
            }
          }
        });
      }

      dispatch({
        type: Actions.hydrateNetatmoThresholds,
        propertySpecificNotificationsHR: { [propertyId]: thresholds },
        thresholdByDeviceId,
      });
      dispatch({
        type: Actions.hydrateNoiseAwareThresholds,
        thresholdByDeviceId,
        propertySpecificNotificationsHRNoiseAware: { [propertyId]: thresholds },
      });
    })
    .catch((err: NotifiableError) => {
      OpertoLogger.Log(err);
    });
};

export const getNetatmoCurrent = () => (dispatch: AppDispatch) => {
  api
    .getCurrentData()
    .then((data: AxiosResponse) => {
      const newData = data.data.data;
      if (newData.length) {
        const byPropertyId = newData.reduce(
          (accum: unknown, dataPoint: unknown) => ({
            ...accum,
            [dataPoint.property_id]: dataPoint,
          }),
          {},
        );

        dispatch({
          type: Actions.insertNetatmoData,
          byPropertyId,
        });
        const byDeviceId = newData.reduce(
          (accum: unknown, dataPoint: unknown) => ({
            ...accum,
            [dataPoint.device_id]: dataPoint,
          }),
          {},
        );
        dispatch({
          type: Actions.insertMultipleNetatmoDataByDevice,
          byDeviceId,
        });
      }
    })
    .catch((err: NotifiableError) => {
      OpertoLogger.Log(err);
    });
};

export const updateNetatmoThresholdSingle =
  (id: number, value: number) => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const propertyId = getState().ui.propertyDetailId;
    dispatch({
      type: Actions.beginNetatmoThresholdUpdate,
    });
    api
      .patchThreshold(id, Number(value))
      .then(() => {
        dispatch(getNetatmoThresholds(propertyId));
        dispatch({
          type: Actions.netatmoThresholdUpdateSuccess,
        });
        setTimeout(() => {
          dispatch({
            type: Actions.endNetatmoThresholdUpdate,
          });
        }, 5000);
      })
      .catch((err: NotifiableError) => {
        dispatch({
          type: Actions.netatmoThresholdUpdateFailed,
        });
        setTimeout(() => {
          dispatch({
            type: Actions.endNetatmoThresholdUpdate,
          });
        }, 5000);
        OpertoLogger.Log(err);
      });
  };

export const updateNoiseAwareThresholdSingle =
  (id: number, value: number) => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const propertyId = getState().ui.propertyDetailId;
    dispatch({
      type: Actions.beginNoiseAwareThresholdUpdate,
    });
    api
      .patchNoiseAwareThreshold(id, Number(value))
      .then(() => {
        dispatch(getNetatmoThresholds(propertyId));
        dispatch({
          type: Actions.noiseAwareThresholdUpdateSuccess,
        });
        setTimeout(() => {
          dispatch({
            type: Actions.endNoiseAwareThresholdUpdate,
          });
        }, 5000);
      })
      .catch((err: NotifiableError) => {
        dispatch({
          type: Actions.noiseAwareThresholdUpdateFailed,
        });
        setTimeout(() => {
          dispatch({
            type: Actions.endNoiseAwareThresholdUpdate,
          });
        }, 5000);
        OpertoLogger.Log(err);
      });
  };

export const updateNetatmoThresholdDouble =
  (medThresholdId: number, highThresholdId: number, value: number) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const propertyId = getState().ui.propertyDetailId;
    dispatch({
      type: Actions.beginNetatmoThresholdUpdate,
    });
    api
      .patchThreshold(medThresholdId, value)
      .then(() => {
        api
          .patchThreshold(highThresholdId, value)
          .then(() => {
            dispatch(getNetatmoThresholds(propertyId));
            dispatch({
              type: Actions.netatmoThresholdUpdateSuccess,
            });
            setTimeout(() => {
              dispatch({
                type: Actions.endNetatmoThresholdUpdate,
              });
            }, 5000);
          })
          .catch((err: NotifiableError) => {
            dispatch({
              type: Actions.netatmoThresholdUpdateFailed,
            });
            setTimeout(() => {
              dispatch({
                type: Actions.endNetatmoThresholdUpdate,
              });
            }, 5000);
            OpertoLogger.Log(err);
          });
      })
      .catch(() => {
        dispatch({
          type: Actions.netatmoThresholdUpdateFailed,
        });
        setTimeout(() => {
          dispatch({
            type: Actions.endNetatmoThresholdUpdate,
          });
        }, 5000);
      });
  };

export const updateNoiseAwareThresholdDouble =
  (quietThresholdId: number, regThresholdId: number, value: number) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const propertyId = getState().ui.propertyDetailId;
    dispatch({
      type: Actions.beginNoiseAwareThresholdUpdate,
    });
    api
      .patchThreshold(quietThresholdId, value)
      .then(() => {
        api
          .patchThreshold(regThresholdId, value)
          .then(() => {
            dispatch(getNetatmoThresholds(propertyId));
            dispatch({
              type: Actions.noiseAwareThresholdUpdateSuccess,
            });
            setTimeout(() => {
              dispatch({
                type: Actions.endNoiseAwareThresholdUpdate,
              });
            }, 5000);
          })
          .catch(() => {
            dispatch({
              type: Actions.noiseAwareThresholdUpdateFailed,
            });
            setTimeout(() => {
              dispatch({
                type: Actions.endNoiseAwareThresholdUpdate,
              });
            }, 5000);
          });
      })
      .catch((err: NotifiableError) => {
        dispatch({
          type: Actions.noiseAwareThresholdUpdateFailed,
        });
        setTimeout(() => {
          dispatch({
            type: Actions.endNoiseAwareThresholdUpdate,
          });
        }, 5000);
        OpertoLogger.Log(err);
      });
  };

export const getHistoricalDataByDeviceId =
  () => async (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const state: ApplicationState = getState();
    const propertyId = state.ui.propertyDetailId;
    const property = state.properties.byId[propertyId] && state.properties.byId[propertyId];
    const devices = property && property.devices;
    const filtered =
      devices &&
      devices.filter(
        device =>
          device.data_name.includes('Netatmo') ||
          device.data_name.includes('NoiseAware') ||
          device.data_type_id === 3 ||
          device.data_type_id === 25,
      );
    dispatch(getNetatmoThresholds(String(propertyId)));
    if (filtered && filtered.length > 0) {
      const results = await Promise.all(
        filtered.map((each: IDevice) =>
          api.getHistoricalData(String(each.id)).catch(() => ({
            error: true,
          })),
        ),
      );
      results.forEach((each: unknown) => {
        if (each && each.data && each.data.data) {
          const items = each.data.data;
          if (items[0] && items[0].device_id) {
            dispatch({
              type: Actions.insertHistoricalNetatmoDataByDevice,
              deviceId: items[0].device_id,
              data: items,
            });
          }
        }
      });
    }
  };

export const getHistoricalData =
  () => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const state: ApplicationState = getState();
    const propertyId = state.ui.propertyDetailId;
    const property = state.properties.byId[propertyId];
    let deviceId = '';
    const devices = property && property.devices;
    dispatch(getNetatmoThresholds(String(propertyId)));
    if (devices) {
      devices.forEach((each: unknown) => {
        if (
          (each && each.device_name && each.device_name === 'Netatmo') ||
          each.device_name === 'NoiseAware'
        ) {
          deviceId = each.id;
        }
      });
    }
    if (deviceId) {
      api.getHistoricalData(String(deviceId)).then((data: AxiosResponse) => {
        const items = data.data.data;
        dispatch({
          type: Actions.insertHistoricalNetatmoData,
          propertyId,
          data: items,
        });
      });
    }
  };
