import { createSelector } from '@reduxjs/toolkit';
import { IPropertyAlertOverview, PropertyAlertState } from 'propertyAlerts/propertyAlertType';
import { ApplicationState } from 'redux/reducers';

const getPropertyAlerts = (state: ApplicationState) => state.propertyAlerts;

export const propertyAlertsSelector = (filterString?: string) => {
  return createSelector([getPropertyAlerts], (propertyAlerts?: PropertyAlertState) => {
    const alertOverviews: IPropertyAlertOverview[] = [];

    propertyAlerts?.list?.forEach(id => {
      const alertOverview = propertyAlerts.byId[id];

      if (!filterString) {
        alertOverviews.push(alertOverview);
        return;
      }

      if (alertOverview.property_name.toLowerCase().includes(filterString.toLowerCase())) {
        alertOverviews.push(alertOverview);
        return;
      }

      // include alerts that have titles that matches the filter string
      if (
        alertOverview.alerts.some(alert =>
          alert.title.toLowerCase().includes(filterString.toLowerCase()),
        )
      ) {
        alertOverviews.push(alertOverview);
        return;
      }
    });

    return alertOverviews;
  });
};

export const propertyAlertsMetaSelector = () =>
  createSelector([getPropertyAlerts], (propertyAlerts: PropertyAlertState) => propertyAlerts.meta);
