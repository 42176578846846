import { combineReducers, Reducer } from '@reduxjs/toolkit';

import { PaymentInfo, PaymentMethod, StripeInfo } from 'payment/state/paymentType';
import { Actions } from '../../types/actions';

const initialStripeTokenState: StripeInfo = {
  id: null,
  token: null,
  gateway: null,
};

const stripeToken: Reducer<StripeInfo> = (state = initialStripeTokenState, action) => {
  switch (action.type) {
    case Actions.storeStripeToken:
      return action.stripeToken;
    default:
      return state;
  }
};

const initialUnitState: PaymentMethod = {
  card_last_four: '',
  expiry_month: 0,
  expiry_year: 0,
};

const paymentMethod: Reducer<PaymentMethod> = (state = initialUnitState, action) => {
  switch (action.type) {
    case Actions.hydratePaymentMethod:
      return action.paymentMethod;
    default:
      return state;
  }
};

const reducer: Reducer<PaymentInfo> = combineReducers<PaymentInfo>({
  stripeToken,
  paymentMethod,
});

export default reducer;
