import { GridCellParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@operto/ui';
import { PMSDisconnectMessage } from 'Common/ToolTip/TooltipMessages';
import { ICheckInStats, ICheckOutStats } from 'dashboard/dashboardTypes';
import { getReservationsByRoomStatus } from 'dashboard/state/dashboardActions';
import { dashboardStatSelector } from 'dashboard/state/dashboardSelectors';
import { IPaymentInfo } from 'guest/guestType';
import { utcToTimeZone } from 'helper/date';
import { IntegrationsApiSystem } from 'integrations/integrationsTypes';
import { findEnabledIntegrationsApiSystemSelector } from 'integrations/state/integrationsSelectors';
import * as React from 'react';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IReservation } from 'reservation/reservationType';
import SecurityDepositCell from 'ui-library/Components/cell/SecurityDepositCell';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import { accessCodeColumn } from '../Common/AccessCodeTableCell';
import { GuestNameTableCell } from '../Common/GuestNameTableCell';
import LinkCell from '../PropertyAlerts/Components/LinkCell';

export interface ReservationsListTableProps {
  roomStatus?: string;
}

const ReservationsListTable = ({ roomStatus }: ReservationsListTableProps) => {
  const [searchValue, setSearchValue] = React.useState('');

  const checkInOutStats = useAppSelector(
    dashboardStatSelector(
      roomStatus === 'guest-checking-in' ? 'check_in_stats' : 'check_out_stats',
    ),
  ) as ICheckInStats | ICheckOutStats;
  const isPaymentSystemEnabled = useAppSelector(
    findEnabledIntegrationsApiSystemSelector(IntegrationsApiSystem.PAYMENT),
  );
  const dispatch = useAppDispatch();

  const renderTz = (params: GridCellParams) => {
    return (
      <Text.BodySmall>
        {utcToTimeZone(params.row[params.field], params.row.timezone, 'MMM dd hh:mm a')}
      </Text.BodySmall>
    );
  };

  const guestActivityType =
    roomStatus === 'guest-checking-in' ? 'todays-check-in' : 'todays-check-out';

  // not configured on backend for pagination yet
  const handleFetch = useCallback(
    (serverPageNum: number, limit: number, searchValue?: string) => {
      dispatch(getReservationsByRoomStatus(guestActivityType));
      setSearchValue(searchValue || '');
    },
    [dispatch, guestActivityType],
  );

  const renderSecurityDeposit = (params: GridCellParams) => {
    const paymentInfo = params.row?.payment_info as IPaymentInfo;
    return <SecurityDepositCell securityDeposit={paymentInfo} />;
  };

  const renderUnitName = (params: GridRenderCellParams) => {
    return <LinkCell text={params.row.property_name} url={`/units/${params.row.property_id}`} />;
  };

  const renderGuest = (params: GridRenderCellParams) => {
    const warningMessage = params.row?.over_written_duration ? PMSDisconnectMessage : undefined;
    return (
      <GuestNameTableCell
        warningMessage={warningMessage}
        guestName={params.row.guest_name}
        guestId={params.row.reservation_id}
      />
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'property_name',
      headerName: 'Unit Name',
      width: 180,
      align: 'left',
      renderCell: renderUnitName,
    },
    {
      field: 'guest_name',
      headerName: 'Guest Name',
      width: 250,
      sortable: false,
      renderCell: renderGuest,
    },
    {
      field: 'check_in',
      headerName: 'Check-In',
      width: 140,
      sortable: false,
      renderCell: renderTz,
    },
    {
      field: 'check_out',
      headerName: 'Check-Out',
      width: 140,
      sortable: false,
      renderCell: renderTz,
    },
    accessCodeColumn({
      code: 'guest_access_code',
      status: 'code_status',
    }),
  ];

  if (isPaymentSystemEnabled) {
    columns.splice(columns.length, 0, {
      field: 'security_deposit',
      headerName: 'Security Deposit',
      sortable: false,
      width: 180,
      renderCell: renderSecurityDeposit,
    });
  }

  const data = checkInOutStats.reservations?.filter((row: IReservation) =>
    row.guest_name?.toLowerCase().includes(searchValue.toLowerCase()),
  );

  return (
    <PaginatedTable
      enableToolbar
      rows={data}
      rowCount={data.length}
      columns={columns}
      onFetch={handleFetch}
    />
  );
};

export default ReservationsListTable;
