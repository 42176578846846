import { Box, SxProps, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { IPropertyAlertsStats } from 'dashboard/dashboardTypes';
import { dashboardStatSelector } from 'dashboard/state/dashboardSelectors';
import * as React from 'react';
import { toggleSlidebar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ThemeProvider } from 'styled-components';
import { SlidebarType } from 'types/ui';
import Widget from 'ui-library/Components/widget/Widget';
import AlertsFooter from './AlertsFooter';

export interface AlertCardProps {
  isCarouselCard: boolean;
}

const AlertCard = ({ isCarouselCard }: AlertCardProps) => {
  const theme = {
    fill: '#B81365',
  };
  const dispatch = useAppDispatch();

  const handleDetailsClick = () => {
    dispatch(toggleSlidebar(SlidebarType.SHOW_PROPERTY_ALERTS, {}));
  };

  const propertyAlertsStats = useAppSelector(
    dashboardStatSelector('property_alerts_stats'),
  ) as IPropertyAlertsStats;

  const totalAlertCount =
    propertyAlertsStats.total_alerts === 0 ? 'No' : propertyAlertsStats.total_alerts;
  const highAlertCount =
    propertyAlertsStats.total_high_alerts > 99 ? '99+' : propertyAlertsStats.total_high_alerts;

  const alertContainerStyles: SxProps = {
    display: 'flex',
    marginRight: '16px',
    alignItems: 'center',
  };

  const alertIconStyles: SxProps = {
    marginRight: '8px',
    width: '28px',
    height: '28px',
  };

  return (
    <ThemeProvider theme={theme}>
      <Widget
        title='Recent Property Alerts'
        isMainActivity={false}
        isCarouselCard={isCarouselCard}
        footer={<AlertsFooter onClick={handleDetailsClick} />}
        fill='#B81365'
      >
        <Typography variant='h2Light'>{totalAlertCount} unresolved alerts</Typography>

        <Box sx={{ ...alertContainerStyles, marginTop: '16px' }}>
          <Box sx={alertContainerStyles}>
            <Avatar
              sx={{
                ...alertIconStyles,
                backgroundColor: 'error.main',
                fontSize: propertyAlertsStats.total_high_alerts > 99 && '14px',
              }}
            >
              {highAlertCount}
            </Avatar>{' '}
            <Typography variant='h3Light'>High</Typography>
          </Box>

          <Box sx={alertContainerStyles}>
            <Avatar sx={{ ...alertIconStyles, backgroundColor: 'warning.main' }}>
              {propertyAlertsStats.total_low_alerts}
            </Avatar>{' '}
            <Typography variant='h3Light'>Low</Typography>
          </Box>
        </Box>
      </Widget>
    </ThemeProvider>
  );
};

export default AlertCard;
