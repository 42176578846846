import getUnixTime from 'date-fns/getUnixTime';
import uniqBy from 'lodash/uniqBy';
import { CategoriesById, CategoriesState, ICategory, IPage, PagesById, PagesState } from '.';
import { ProviderTypes } from '../../company/companyType';
import {
  ActionButton,
  AssignedPropertyState,
  CustomText,
  FeaturedState,
  GPLink,
  GPProperty,
  GuestPortal,
  Host,
  IFeatured,
  ISection,
  Order,
  PropertyAddress,
  SectionState,
  Settings,
  Theme,
} from './models';

export const parseFeatures = (features: IFeatured[]) => {
  const featured: FeaturedState = { byId: {}, order: [] };

  features.forEach(record => {
    if (record.type === 'section') {
      featured.byId[record.section_id] = record;
      return featured.order.push({ id: record.section_id, type: record.type });
    }

    if (record.type === 'category') {
      featured.byId[record.category_id] = record;
      return featured.order.push({ id: record.category_id, type: record.type });
    }

    if (record.type === 'content') {
      featured.byId[record.content_id] = record;
      return featured.order.push({ id: record.content_id, type: record.type });
    }
  });
  featured.order = uniqBy(featured.order, 'id');

  return featured;
};

export const parsePages = (response: { records: IPage[]; order: Order[] }) => {
  const pages: PagesState = { byId: {}, order: [] };

  // NOTE: records array is actually more accurate than order array coz of the mixing of different types
  response.records.forEach(record => {
    pages.byId[record.content_id] = record;
  });

  // old orders need cleaning. while P2 tickets were being fixed, some orders got type category but with content_id
  // so we need to change the type to content and fix the order
  const allPagesIds = new Set(response.records.map(({ content_id }) => content_id));
  const orderIds = response.order
    .map((orderItem: Order) =>
      allPagesIds.has(orderItem.id) ? ({ id: orderItem.id, type: 'content' } as Order) : null,
    )
    .filter(Boolean);
  pages.order = uniqBy(orderIds, 'id');
  return pages;
};

export const parseCategories = (response: { records: ICategory[]; order: Order[] }) => {
  const categories: CategoriesState = { byId: {}, order: [] };

  // NOTE: records array is actually more accurate than order array coz of the mixing of different types
  response.records.forEach(record => {
    categories.byId[record.category_id] = record;
    categories.order.push({ id: record.category_id, type: 'category' });
  });
  categories.order = uniqBy(categories.order, 'id').filter(({ type }) => type === 'category');

  return categories;
};

export const parseSections = (response: { records: ISection[]; order: Order[] }) => {
  const sections: SectionState = { byId: {}, order: [] };

  // NOTE: sections response.order seems to be accurate. my guess is coz there is no mixing of different types
  sections.order = uniqBy(response.order, 'id');
  sections.order.forEach(o => {
    sections.byId[o.id] = response.records.find(({ section_id }) => section_id === o.id);
  });

  return sections;
};

// FIXME: proper typing...
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parsePageToCategoryResponse = (state: GuestPortal, payload: any) => {
  let pageByIdData: PagesById = state.pages?.byId as PagesById;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload.data?.categories_data?.forEach((c: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    c.contents?.forEach((p: any) => {
      pageByIdData = { ...pageByIdData, [p.content_id]: p };
    });
  });
  const catByIdData: CategoriesById = state.categories?.byId as CategoriesById;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload.data?.categories_data?.forEach((c: any) => {
    Object.assign(catByIdData, { [c.category_id]: c });
  });
  state.pages.byId = pageByIdData;
  state.categories.byId = catByIdData;
  state.categories.order = payload.data.categories_order;
  state.pages.order = payload.data.contents_order;
  return state;
};

export const parseHost = (data: {
  is_host_custom: boolean;
  name: string;
  email: string;
  phone: string;
  avatar: string;
}): Host => ({
  enabled: data.is_host_custom as boolean,
  name: data.name as string,
  email: data.email as string,
  phone: data.phone as string,
  imageURL: data.avatar as string,
});

export const parseCustomText = (data: {
  is_host_message_custom: boolean;
  welcome_message: string;
  goodbye_message: string;
  goodbye_message_title: string;
  welcome_message_title: string;
  message: string;
  qr_code_welcome_title: string;
  qr_code_welcome_message: string;
  qr_code_welcome_buttons: ActionButton[];
  qr_code_checkin_message: string;
  qr_code_checkin_buttons: ActionButton[];
  terms_data: {
    enabled: boolean;
    message: string;
    signature_enabled: boolean;
    is_custom: boolean;
  };
}): CustomText => ({
  isCustom: data.is_host_message_custom,
  welcomeMessage: data.welcome_message,
  welcomeTitle: data.welcome_message_title,
  goodbyeMessage: data.goodbye_message,
  goodbyeTitle: data.goodbye_message_title,
  termsAndCondition: data.message,
  qrCodeWelcomeTitle: data.qr_code_welcome_title,
  qrCodeWelcomeMessage: data.qr_code_welcome_message,
  qrCodeWelcomeButtons: data.qr_code_welcome_buttons,
  qrCodeCheckinMessage: data.qr_code_checkin_message,
  qrCodeCheckinButtons: data.qr_code_checkin_buttons,
  termsData: {
    enabled: data.terms_data?.enabled ?? false,
    message: data.terms_data?.message ?? data.message ?? '',
    signatureEnabled: data.terms_data?.signature_enabled ?? false,
    isCustom: data.terms_data?.is_custom ?? false,
  },
});

export const parseTheme = (data: {
  company_logo: string;
  primary_color: string;
  welcome_images_enabled: boolean;
}): Theme => ({
  primaryColor: data.primary_color || '',
  companyLogo: data.company_logo || '',
  welcomeImagesEnabled: data.welcome_images_enabled,
});

export const parseAddress = (data: {
  is_custom: boolean;
  property_name: string;
  property_address: string;
  property_city: string;
  property_state: string;
  property_country: string;
  dashboard_bg_image: string;
}): PropertyAddress => ({
  isCustom: data.is_custom,
  name: data.property_name,
  city: data.property_city,
  state: data.property_state,
  address: data.property_address,
  country: data.property_country,
  imageURL: data.dashboard_bg_image,
});

export const parseSettings = (data: {
  nearme_enabled: boolean;
  locale: string;
  locale_detection_enabled: boolean;
  incident_payment_enabled: boolean;
  terms_required: boolean;
  terms_enabled: boolean;
  challenge_dates: boolean;
  challenge_lastname: boolean;
  checkin_early_enabled: boolean;
  reservation_display_enabled: boolean;
  checkin_qr_enabled: boolean;
  welcome_required: boolean;
  contact_required: boolean;
  entrance_instructions_enabled: boolean;
  verification_providers: ProviderTypes[];
}): Settings => ({
  nearmeEnabled: data.nearme_enabled,
  locale: data.locale,
  localeDetectionEnabled: data.locale_detection_enabled,
  challengeDate: data.challenge_dates,
  challengeLastName: data.challenge_lastname,
  guestVerification: data.incident_payment_enabled,
  welcomeRequired: data.welcome_required,
  termsRequired: data.terms_required,
  termsEnabled: data.terms_enabled,
  checkinEarlyEnabled: data.checkin_early_enabled,
  reservationDisplayEnabled: data.reservation_display_enabled,
  checkinQrEnabled: data.checkin_qr_enabled,
  contactRequired: data.contact_required,
  entranceInstructionsEnabled: data.entrance_instructions_enabled,
  verificationProviders: data.verification_providers,
});

export const parseProperties = (data: {
  records: [];
  numPerPage: number;
  pageNum: number;
  total_records: number;
}): AssignedPropertyState => {
  if (!Array.isArray(data.records)) {
    return {
      byId: {},
      list: [],
      meta: {
        numPerPage: 0,
        pageNum: 0,
        total_records: 0,
      },
    };
  }

  const propertyById: Record<number, GPProperty> = {};
  const propertyList: number[] = [];

  data.records.forEach((p: Record<string, never>) => {
    propertyList.push(p.property_id as number);
    propertyById[p.property_id as number] = {
      guest_portal_status: p.guest_portal_status,
      propertyId: p.property_id,
      name: p.name,
      location: p.location,
      verified: p.verified,
      checkedIn: p.checked_in,
      guest_portal_initialized: p.guest_portal_initialized,
      guest_verification_enabled: p.guest_verification_enabled,
      smw_enabled: p.smw_enabled,
      entrance_instructions_enabled: p.entrance_instructions_enabled,
    };
  });

  return {
    list: propertyList,
    byId: propertyById,
    meta: {
      numPerPage: data.numPerPage,
      pageNum: data.pageNum,
      total_records: data.total_records,
    },
  };
};

export const categoryToJSON = (category: ICategory) => ({
  category_id: category.category_id,
  category: category.category,
  section_id: category.section_id,
  published: category.published,
  scheduled: category.scheduled || false,
  last_edit_at: category.last_edit_at,
  last_edit_by: category.last_edit_by,
  scheduled_end: category.scheduled_end || getUnixTime(new Date()),
  scheduled_start: category.scheduled_start || getUnixTime(new Date()),
  featured: category.featured,
  category_cover: category.category_cover,
  number_of_contents: category.number_of_contents,
});

export const themeToJSON = (theme: Theme) => ({
  company_logo: theme.companyLogo,
  primary_color: theme.primaryColor,
  welcome_images_enabled: theme.welcomeImagesEnabled,
  dashboard_bg_image: '',
});

export const hostToJSON = (host: Host) => ({
  is_host_custom: host.enabled,
  name: host.name,
  email: host.email,
  phone: host.phone,
  avatar: host.imageURL || '',
});
export const gpLinkToJson = (gpLink: GPLink) => ({
  name: gpLink.name,
  url: gpLink.url,
  check_in: gpLink.check_in,
  check_out: gpLink.check_out,
});

export const customTextToJSON = (text: CustomText) => ({
  welcome_message_title: text.welcomeTitle,
  welcome_message: text.welcomeMessage,
  goodbye_message_title: text.goodbyeTitle,
  goodbye_message: text.goodbyeMessage,
  message: text.termsAndCondition,
  is_host_message_custom: text.isCustom,
  qr_code_welcome_title: text.qrCodeWelcomeTitle,
  qr_code_welcome_message: text.qrCodeWelcomeMessage,
  qr_code_welcome_buttons: text.qrCodeWelcomeButtons,
  qr_code_checkin_message: text.qrCodeCheckinMessage,
  qr_code_checkin_buttons: text.qrCodeCheckinButtons,
  terms_data: {
    is_custom: text.isCustom ?? false,
    enabled: text.termsData.enabled ?? false,
    message: text.termsData.message ?? text.termsAndCondition ?? '',
    signature_enabled: text.termsData.signatureEnabled ?? false,
  },
});

export const propertyAddressToJSON = (property: PropertyAddress) => ({
  is_custom: property.isCustom,
  property_name: property.name,
  property_city: property.city,
  property_state: property.state,
  property_address: property.address,
  property_country: property.country,
  dashboard_bg_image: property.imageURL,
});

export const settingsToJSON = (settings: Settings) => ({
  nearme_enabled: settings.nearmeEnabled,
  locale: settings.locale,
  locale_detection_enabled: settings.localeDetectionEnabled,
  incident_payment_enabled: settings.guestVerification,
  welcome_required: settings.welcomeRequired,
  contact_required: settings.contactRequired,
  terms_required: settings.termsRequired,
  terms_enabled: settings.termsEnabled,
  challenge_dates: settings.challengeDate,
  challenge_lastname: settings.challengeLastName,
  checkin_early_enabled: settings.checkinEarlyEnabled,
  chat_enabled: settings.chatEnabled,
  reservation_display_enabled: settings.reservationDisplayEnabled,
  checkin_qr_enabled: settings.checkinQrEnabled,
  entrance_instructions_enabled: settings.entranceInstructionsEnabled,
});
