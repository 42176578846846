import { Grid, Paper, Stack, SxProps, Typography } from '@mui/material';
import EmptyStateIcon from 'assets/img/icons/empty-state.svg';
import { Loading } from 'Common/Loading';
import { featurePermission } from 'helper/helper';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { INITIAL_STATE, useGetGroupsQuery } from 'redux/groups/api-slice';
import { useAppSelector } from 'redux/hooks';
import { MEMBERS_ROLES } from 'redux/members/types';
import { SearchToolbar } from 'ui-library/Components/table/SearchToolbar';
import TableNoResultsFound from 'ui-library/Components/table/TableNoResultsFound';
import { currentUserSelector } from 'user/state/userSelectors';
import { GroupsForm } from './Forms/GroupsForm';
import { GroupCell } from './GroupCell';

const searchToolbarTitleStyles: SxProps = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginLeft: '-15px',
};

// NOTE: extract me to a shared component if needed
type EmptyResultsProps = {
  title?: string;
};

export const EmptyResults = ({ title }: EmptyResultsProps) => {
  return (
    <Stack alignItems='center'>
      <EmptyStateIcon />
      <Typography variant='body-lg-600'>{title}</Typography>
    </Stack>
  );
};

export const GroupsGrid = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { data = INITIAL_STATE, isLoading } = useGetGroupsQuery();
  const [searchValue, setSearchValue] = useState('');
  const user = useAppSelector(currentUserSelector());
  const addGroupPermission = featurePermission(user?.role, 'groups', 'create');
  const disableCellMenu =
    user?.role === MEMBERS_ROLES.REGIONAL_MANAGER || user?.role === MEMBERS_ROLES.BASIC_USER;
  const filteredList = data.groups.filter(group => {
    if (searchValue === '') {
      return true;
    }

    return group.name.toLowerCase().includes(searchValue.toLowerCase());
  });

  const renderContent = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (data?.groups.length === 0) {
      return <EmptyResults title='No groups yet' />;
    }

    if (filteredList.length === 0) {
      return <TableNoResultsFound />;
    }

    return (
      <Grid container spacing={2} ml={-2}>
        {filteredList.map((group, index) => (
          <Grid item xs={12} md={6} lg={4} xl={4} key={`group-cell-${index}`}>
            <Paper elevation={0} sx={{ '&:hover': { boxShadow: 3 } }}>
              <GroupCell disableMenu={disableCellMenu} group={group} />
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  };

  const handleAddGroup = () => {
    navigate(`${pathname}/add`);
  };

  return (
    <>
      <SearchToolbar
        title={
          <Typography variant='subtitle1' sx={searchToolbarTitleStyles}>
            Control access for team members by grouping units and assigning members.
          </Typography>
        }
        onChange={setSearchValue}
        disableAdd={!addGroupPermission}
        onAdd={handleAddGroup}
      />

      {renderContent()}

      <GroupsForm onClose={() => navigate('/groups')} />
    </>
  );
};
