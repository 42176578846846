import { createSelector } from '@reduxjs/toolkit';
import { AccessCodeTypes, CodeState, ICode } from 'code/codeType';
import differenceInDays from 'date-fns/differenceInDays';
import { utcStringToDate } from 'helper/date';
import { ApplicationState } from 'redux/reducers';

const getCodes = (state: ApplicationState) => state.codes;
interface Result {
  [Key: string]: CodeDict;
}
interface CodeDict {
  [Key: number]: ICode;
}

const groupBy = (items: ICode[]) =>
  items.reduce(
    (result: Result, item: ICode) => ({
      ...result,
      [item.linked_reservation_id]: {
        ...result[item.linked_reservation_id],
        [item.access_code_type_id]: item,
      },
    }),
    {},
  );

export const guestCodesSelector = (propertyId: number) =>
  createSelector([getCodes], (codes: CodeState) => {
    const filteredCodes = codes.list
      .map((each: number) => codes.byId[each])
      .filter((code: ICode) => {
        const daysDiff = differenceInDays(utcStringToDate(code.valid_until), new Date());
        return (
          code.property_id === Number(propertyId) &&
          (code.access_code_type_id === AccessCodeTypes.Guest ||
            code.access_code_type_id === AccessCodeTypes.CheckoutCode) &&
          daysDiff > 0
        );
      })
      .sort((a, b) => new Date(a.valid_from).valueOf() - new Date(b.valid_from).valueOf());
    const groupedCodes: Result = groupBy(filteredCodes);
    const keys: string[] = Object.keys(groupedCodes);
    const reducedCodes = keys
      .map((each: string) => groupedCodes[each])
      .map((each: CodeDict) => ({
        ...each[1],
        exitCode: (each[4] && each[4].access_code) || '',
      }));
    return reducedCodes
      .filter((each: ICode) => each.access_code)
      .sort((a, b) => new Date(a.valid_from).valueOf() - new Date(b.valid_from).valueOf());
  });

export const getCheckinCodeByResId = (reservationId: number | null) =>
  createSelector(
    [getCodes],
    (codes: CodeState) =>
      reservationId &&
      codes.list
        .map((codeId: number) => codes.byId[codeId])
        .find(
          (code: ICode) =>
            code.reservation_id === reservationId &&
            code.access_code_type_id === AccessCodeTypes.Guest,
        ),
  );
export const getCheckinCodeByAccessCodeId = (accessCodeId: number | null) => {
  return createSelector([getCodes], (codes: CodeState) => {
    const code = codes.byId[accessCodeId];
    if (code.access_code_type_id === AccessCodeTypes.Guest) {
      return code;
    }

    return undefined;
  });
};
