import { getStripe, integrateWithStripe, updateStripe } from 'api/integrations';
import { AxiosError } from 'axios';
import { logger } from 'lib/logger';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { Actions } from 'types/actions';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';

export type StripeData = {
  id: number;
  publishableKey: string;
  token: string;
  enabled: boolean;
  webhookSecret: string;
};

type StripeJSON = {
  id: number;
  publishable_key: string;
  token: string;
  enabled: boolean;
  webhook_secret: string;
};

const parseData = (json?: StripeJSON): StripeData => {
  return {
    id: json?.id ?? 0,
    publishableKey: json?.publishable_key ?? '',
    token: json?.token ?? '',
    enabled: json?.enabled ?? false,
    webhookSecret: json?.webhook_secret ?? '',
  };
};

const toJSON = (data: StripeData): Omit<StripeJSON, 'id'> => {
  return {
    publishable_key: data.publishableKey,
    token: data.token,
    enabled: data.enabled ?? false,
    webhook_secret: data.webhookSecret ?? '',
  };
};

type IntegrationState = {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  data?: StripeData;
};

const initialState: IntegrationState = {
  status: 'idle',
};

export const useStripeIntegration = () => {
  const dispatch = useAppDispatch();
  const [integrationState, setIntegrationState] = useState<IntegrationState>(initialState);
  const isLoading = integrationState.status === 'loading';
  const isSuccess = integrationState.status === 'succeeded';

  // internal helper methods
  const displaySnackbar = useCallback(
    ({ variant, message }: { variant: SnackbarVariant; message?: string }) => {
      if (message) {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message,
            variant,
          }),
        );
      }
    },
    [dispatch],
  );

  const doAPICall = useCallback(
    async (apiCall: () => Promise<[StripeData, string]>) => {
      setIntegrationState(prev => ({ ...prev, status: 'loading' }));

      try {
        const [result, message] = await apiCall();
        setIntegrationState({ status: 'succeeded', data: result });
        displaySnackbar({ variant: SnackbarVariant.SUCCESS, message });
        if (result) {
          dispatch({
            type: Actions.upsertIntegrations,
            integration: {
              id: result.id,
              enabled: result.enabled,
              apiSystem: 'payment',
              label: 'stripe',
              name: 'stripe',
            },
          });
        }
      } catch (error) {
        logger.error(error);
        const message = error?.message ?? error;
        setIntegrationState(prev => ({ ...prev, status: 'failed' }));
        displaySnackbar({ variant: SnackbarVariant.ERROR, message });
      }
    },
    [dispatch, displaySnackbar],
  );

  const postData = (newData: StripeData) => {
    doAPICall(async () => {
      const response = await integrateWithStripe(toJSON(newData));
      const { status, message, data } = response.data;
      if (status !== 'success') {
        throw message ?? 'Error saving Stripe data';
      }

      return [parseData(data), message ?? 'Successfully added Stripe integration'];
    });
  };

  const updateData = (newData: StripeData) => {
    doAPICall(async () => {
      if (isEqual(newData, integrationState.data)) {
        return [newData, 'Successfully updated Stripe integration'];
      }

      const response = await updateStripe(toJSON(newData));
      const { status, message, data } = response.data;
      if (status !== 'success') {
        throw message ?? 'Error updating Stripe data';
      }

      return [parseData(data), message ?? 'Successfully updated Stripe integration'];
    });
  };

  // public methods
  const save = (newData: StripeData) => {
    if (newData.id !== undefined) {
      updateData(newData);
    } else {
      postData(newData);
    }
  };

  useEffect(() => {
    // reset status after notifying subscribers
    if (['succeeded', 'failed'].includes(integrationState.status)) {
      setIntegrationState(prev => ({ ...prev, status: 'idle' }));
    }
  }, [integrationState.status]);

  useEffect(() => {
    // fetch data on mount
    doAPICall(async () => {
      try {
        const response = await getStripe();
        const { status, message, data } = response.data;
        if (status !== 'success') {
          throw message ?? 'Error fetching Stripe data';
        }

        return [parseData(data), undefined];
      } catch (e) {
        const error = e as AxiosError;
        if (error.response.status === 404) {
          return [undefined, undefined]; // no stripe integration found
        }

        throw e;
      }
    });
  }, [doAPICall]);

  return {
    data: integrationState.data,
    isLoading,
    isSuccess,
    save,
  };
};
