import { combineReducers, Reducer } from '@reduxjs/toolkit';
import {
  PropertyAlertDict,
  PropertyAlertMeta,
  PropertyAlertState,
} from 'propertyAlerts/propertyAlertType';
import { Actions } from 'types/actions';

export const initialPropertyAlertState: PropertyAlertDict = {};

const byId: Reducer<PropertyAlertDict> = (state = initialPropertyAlertState, action) => {
  switch (action.type) {
    case Actions.hydratePropertyAlerts:
      return action.alertsDict;
    case Actions.insertPropertyAlerts:
      return { ...state, ...action.alertsDict };
    default:
      return state;
  }
};

const list: Reducer<number[]> = (state = [], action) => {
  switch (action.type) {
    case Actions.hydratePropertyAlerts:
      return action.alertsList;
    case Actions.insertPropertyAlerts:
      return Array.from(new Set([...state, ...action.alertsList]));
    default:
      return state;
  }
};

export const initialPropertyAlertMeta: PropertyAlertMeta = {
  pageNum: 0,
  total_records: 0,
  numPerPage: 0,
};

const meta: Reducer<PropertyAlertMeta> = (state = initialPropertyAlertMeta, action) => {
  switch (action.type) {
    case Actions.updatePropertyAlertsCount:
      return action.meta;
    default:
      return state;
  }
};

const reducer: Reducer<PropertyAlertState> = combineReducers<PropertyAlertState>({
  byId,
  list,
  meta,
});

export default reducer;
