import axios, { AxiosResponse } from 'axios';

const api = process.env.REACT_APP_API_URL;

export const getMultiplePropertiesAlerts = (
  pageNum: number,
  numPerPage: number,
  keyword?: string,
): Promise<AxiosResponse> => {
  const params: Record<string, unknown> = {
    pageNum,
    numPerPage,
  };
  if (keyword) {
    params.keyword = keyword;
  }
  return axios.get(`${api}/v2/companies/properties/property-alerts-multiple`, {
    params,
  });
};
