import axios from 'axios';
import { MessengerSetting } from 'company/companyType';

const api = process.env.REACT_APP_API_URL;

export const getCompany = () => axios.get(`${api}/v2/company`);

export const updateCompany = (data: unknown) => axios.put(`${api}/v2/company`, data);

export const getActiveAlerts = async () => {
  const response = await axios.get(`${api}/v2/company/alerts/active`);
  return response.data;
};

export const getMessengerSetting = () => axios.get(`${api}/v2/companies/messenger-settings`);

export const updateMessengerSetting = (settings: MessengerSetting) =>
  axios.put(`${api}/v2/companies/messenger-settings`, settings);

export const setCompanyVerification = (company_id: number, data: unknown) =>
  axios.post(`${api}/v2/companies/${company_id}/verification-provider`, data);
