import { MEMBERS_ROLES } from 'redux/members/types';

export const PERMISSIONS_ACTIONS = {
  CREATE: 'create',
  DELETE: 'delete',
  EDIT: 'edit',
  VIEW: 'view',
} as const;

export const PERMISSIONS = {
  GROUPS: 'groups',
} as const;

export const permissionRoles = [
  {
    role: MEMBERS_ROLES.ACCOUNT_OWNER,
    permissions: {
      groups: ['create_any', 'view_any', 'delete_any', 'edit_any'],
    },
  },
  {
    role: MEMBERS_ROLES.GLOBAL_MANAGER,
    permissions: {
      groups: ['create_any', 'edit_basic-user_regional-manager', 'view_any'],
    },
  },
  {
    role: MEMBERS_ROLES.BASIC_USER,
    permissions: {
      groups: ['view_any'],
    },
  },
  {
    role: MEMBERS_ROLES.REGIONAL_MANAGER,
    permissions: {
      groups: ['view_any'],
    },
  },
];

export type PermissionActionsTypes = (typeof PERMISSIONS_ACTIONS)[keyof typeof PERMISSIONS_ACTIONS];
export type PermissionTypes = (typeof PERMISSIONS)[keyof typeof PERMISSIONS];
