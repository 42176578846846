import useTranslation from 'hooks/useTranslation';
import { ChangeEvent } from 'react';
import renderTextField from '../TabsComponents/TextInput';

/**
 *  Separate component since renderTextField() has a lot of parameters.
 *
 * @param (string) actionButtonText - text label
 * @param (function) handleActionButtonTextUpdate - closure called when text is updated
 * @param (function) handleActionButtonTextClear - closure called when text is cleared
 * @param (string) testId - test id used in TextField
 * @returns (TextField) - React component that renders a text field for the action button text
 */
export const CustomButtonTextField = ({
  actionButtonText,
  handleActionButtonTextUpdate,
  handleActionButtonTextClear,
  testId,
}: {
  actionButtonText: string;
  handleActionButtonTextUpdate: (event: ChangeEvent<HTMLInputElement>) => void;
  handleActionButtonTextClear: () => void;
  testId?: string;
}) => {
  const { t } = useTranslation();

  return renderTextField(
    t('custom_button_text'),
    '',
    actionButtonText,
    handleActionButtonTextUpdate,
    30,
    false,
    1,
    true,
    1,
    4,
    false,
    '',
    testId,
    handleActionButtonTextClear,
  );
};
