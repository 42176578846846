import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Stack, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SettingCard } from '@operto/ui-library';
import ActionSnackbar from 'Common/Snackbar/ActionSnackbar';
import { logger } from 'lib/logger';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { FormTextField } from 'ui-library/Components/input/FormTextField';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { IntegrationsFormHandle, IntegrationsFormProps } from '../IntegrationsSideDrawer';
import { StripeData } from './useStripeIntegration';

type StripeIntegrationFormProps = IntegrationsFormProps & {
  data: StripeData;
  loading?: boolean;
  onSave: (data: StripeData) => void;
  onClose: () => void;
};

const webhookURL = `${process.env.REACT_APP_API_URL}/payments/stripe/events`;

export const StripeIntegrationForm = React.forwardRef<
  IntegrationsFormHandle,
  StripeIntegrationFormProps
>(function StripeIntegrationForm({ data, loading, onSave, onClose }, ref) {
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm<StripeData>({
    mode: 'all',
    defaultValues: data,
  });

  useImperativeHandle(
    ref,
    () => ({
      submit: handleSubmit(onSave),
      close: () => {
        setShowExitConfirmation(isDirty);
        if (!isDirty) {
          onClose?.();
        }
      },
    }),
    [handleSubmit, isDirty, onClose, onSave],
  );

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(webhookURL);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Copied Successfully!',
          variant: SnackbarVariant.SUCCESS,
        }),
      );
    } catch (error) {
      logger.error(error);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Failed to copy webhook URL',
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  return (
    <Stack m={1}>
      <SettingCard title='Integration Settings'>
        <LoadingContainer loading={loading}>
          <ActionSnackbar
            onClose={onClose}
            open={showExitConfirmation}
            setOpen={setShowExitConfirmation}
            severity='error'
            actionButtonText='YES'
            snackbarMessage='You have unsaved changes that will be lost if you exit. Are you sure you want to exit?'
          />
          <FormTextField
            rules={{
              required: {
                value: true,
                message: 'Token is required',
              },
            }}
            field='token'
            label='Token'
            control={control}
            sx={{ mb: 4 }}
          />
          <FormTextField
            rules={{
              required: {
                value: true,
                message: 'Publishable key is required',
              },
            }}
            field='publishableKey'
            label='Publishable Key'
            control={control}
            sx={{ mb: 4 }}
          />
          <FormTextField
            rules={{
              required: {
                value: true,
                message: 'Webhook Secret is required',
              },
            }}
            field='webhookSecret'
            label='Webhook Secret'
            control={control}
            sx={{ mb: 4 }}
          />
          <TextField
            disabled
            variant='outlined'
            label='Webhook URL'
            value={webhookURL}
            InputProps={{
              sx: { backgroundColor: grey[100] },
              endAdornment: (
                <IconButton size='small' color='primary' onClick={handleCopy}>
                  <ContentCopyIcon />
                </IconButton>
              ),
            }}
            sx={{
              mx: 0,
              width: '100%',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          />
        </LoadingContainer>
      </SettingCard>
    </Stack>
  );
});
