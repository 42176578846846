import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { ThermostatState, ThermostatDict } from '../../types/thermostats';

const initialSettingsById: ThermostatDict = {};

const settingsById: Reducer<ThermostatDict> = (state = initialSettingsById, action) => {
  switch (action.type) {
    case 'INSERT_THERMOSTAT_SETTINGS':
      return {
        ...state,
        [action.propertyId]: [...state[action.propertyId], ...action.settings],
      };
    case 'INSERT_THERMOSTAT_SETTINGS_HEATING':
      return {
        ...state,
        [action.propertyId]: [...state[action.propertyId], ...action.settings],
      };
    case 'INSERT_THERMOSTAT_SETTINGS_AIR_CONDITIONING':
      return {
        ...state,
        [action.propertyId]: [...state[action.propertyId], ...action.settings],
      };
    case 'UPDATE_THERMOSTAT_SETTINGS':
      return {
        ...state,
        [action.propertyId]: action.settings,
      };
    case 'UPDATE_THERMOSTAT_SETTINGS_HEATING':
      return {
        ...state,
        [action.propertyId]: action.settings,
      };
    case 'UPDATE_THERMOSTAT_SETTINGS_AIR_CONDITIONING':
      return {
        ...state,
        [action.propertyId]: action.settings,
      };

    case 'CLEAR_THERMOSTAT_SETTING_FOR_PROPERTY':
      return {
        ...state,
        [action.propertyId]: [],
      };
    default:
      return state;
  }
};

const reducer: Reducer<ThermostatState> = combineReducers<ThermostatState>({
  byPropertyId: settingsById,
});

export default reducer;
