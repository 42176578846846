// @ts-nocheck

import * as api from 'api/properties';
import { Actions } from 'types/actions';
import { PropertyDict } from 'types/properties';

import { OpertoLogger } from 'Logger/logger';
import { getCodes } from 'code/state/codeAction';
import * as groupActions from 'location/state/LocationsActions';
import { Property } from 'property/propertyType';
import { ApplicationState } from 'redux/reducers';
import { AppDispatch } from 'redux/store';
import { getSettings } from './dashboardConfiguration';
import { getNetatmoCurrent } from './netatmo';

export const getProperties =
  (fullHydrate?: boolean) => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    api
      .getProperties()
      .then(data => {
        const { properties } = data.data.data;
        const state = getState();
        const companyId = state.company.id;
        dispatch({
          type: Actions.storeCompanyId,
          companyId,
        });
        const propertyIds: number[] = [];
        const propertiesById: PropertyDict = {};
        const mainPropertyIds: number[] = [];
        const mainPropertiesById: PropertyDict = {};
        const commonPropertyIds: number[] = [];
        const commonPropertiesById: PropertyDict = {};
        const additionalPropertyIds: number[] = [];
        const additionalPropertiesById: PropertyDict = {};
        properties.forEach((each: Property) => {
          switch (each.property_type) {
            case 'primary':
              mainPropertyIds.push(each.id);
              mainPropertiesById[each.id] = each;
              break;
            case 'common':
              commonPropertyIds.push(each.id);
              commonPropertiesById[each.id] = each;
              break;
            case 'additional':
              additionalPropertyIds.push(each.id);
              additionalPropertiesById[each.id] = each;
              break;
            default:
          }
          propertyIds.push(each.id);
          propertiesById[each.id] = each;
        });
        dispatch({
          type: Actions.hydrateProperties,
          propertyIds,
          propertiesById,
          mainPropertyIds,
          mainPropertiesById,
          commonPropertyIds,
          commonPropertiesById,
          additionalPropertyIds,
          additionalPropertiesById,
        });
        dispatch(getSettings(companyId));
        if (fullHydrate) {
          dispatch(getMembers());
          dispatch(groupActions.getGroups());
          dispatch(getCodes());
          dispatch(getNetatmoCurrent());
        }
      })
      .catch(err => {
        OpertoLogger.Log(err);
      });
  };

export const getFavoriteProperties = () => (dispatch: AppDispatch) => {
  api
    .getFavoriteProperties()
    .then(data => {
      const propertyIds = data.data.data.property_ids;
      dispatch({
        type: Actions.hydrateFavoriteProperties,
        propertyIds,
      });
    })
    .catch(err => {
      OpertoLogger.Log(err);
    });
};

// TOTALLY NEEDS TO BE OPTIMIZED. ERROR IS WRONG. LOOPING IS WRONG. the thing IS WRONG
export const addGroupsToUnit = (groupIds: number[], unitId: number) => (dispatch: AppDispatch) => {
  // i could hack this together from member side mapping through all memebrs and adding unit to them
  // for now failing api call i guess
  // api.addGroupsToUnit(groupIds, unitId)
  // 	.then((data) => {
  // 		debugger;
  // 	})
  // 	.catch((err) => {
  // 		debugger;
  // 	})

  //  hack api calls
  // TOTALLY NEEDS TO BE OPTIMIZED. ERROR IS WRONG. LOOPING IS WRONG. the thing IS WRONG
  return Promise.all(
    groupIds.map((each: number) => {
      dispatch(groupActions.addUnitsToUnitGroup([parseInt(unitId, 10)], each));
    }),
  ).then(() => {
    dispatch({
      type: Actions.hideAddGroupsToUnit,
    });
  });
};

// TOTALLY NEEDS TO BE OPTIMIZED. ERROR IS WRONG. LOOPING IS WRONG. the thing IS WRONG
export const removeGroupsFromUnit =
  (groupIds: number[], unitId: number) => (dispatch: AppDispatch) => {
    // TOTALLY NEEDS TO BE OPTIMIZED. ERROR IS WRONG. LOOPING IS WRONG. the thing IS WRONG
    return Promise.all(
      groupIds.map((each: number) => {
        dispatch(groupActions.removeUnitFromCurrentPropertyGroup(unitId, each));
      }),
    ).then(() => {
      dispatch({
        type: Actions.hideAddGroupsToUnit,
      });
    });
  };

export const createFavoriteProperty = (propertyId: number) => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.favoritesLoading,
    id: propertyId,
  });
  api
    .createFavoriteProperty(propertyId)
    .then(() => {
      dispatch({
        type: Actions.favoritesSuccess,
        id: propertyId,
      });
      dispatch(getFavoriteProperties());
    })
    .catch(err => {
      dispatch({
        type: Actions.favoritesFailed,
      });
      OpertoLogger.Log(err);
    });
};
export const deleteFavoriteProperty = (propertyId: number) => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.favoritesLoading,
    id: propertyId,
  });
  api
    .deleteFavoriteProperty(propertyId)
    .then(() => {
      dispatch({
        type: Actions.favoritesSuccess,
        id: propertyId,
      });
      dispatch(getFavoriteProperties());
    })
    .catch(err => {
      dispatch({
        type: Actions.favoritesFailed,
      });
      OpertoLogger.Log(err);
    });
};
