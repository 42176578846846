import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { createTheme, IconButton, SxProps, ThemeProvider } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { paletteStyles } from '@operto/ui';
import React from 'react';

export interface ActionButtonProps {
  onClick: () => void;
  text: string;
  loading: boolean;
}

export interface ActionsDialogProps {
  open: boolean;
  loading?: boolean;
  title?: string;
  children: React.ReactNode;
  actionButtonOne: ActionButtonProps;
  actionButtonTwo?: ActionButtonProps;
  disabled?: boolean;
  onClose?: () => void;
  contentStyles?: SxProps;
  actionsStyles?: SxProps;
  rootStyles?: SxProps;
  testId?: string;
}

export const ActionsDialog = ({
  open,
  title,
  children,
  actionButtonOne,
  actionButtonTwo,
  contentStyles,
  actionsStyles,
  rootStyles,
  onClose,
  testId,
}: ActionsDialogProps) => {
  const theme = createTheme({
    palette: {
      primary: paletteStyles.palette.primary,
      secondary: paletteStyles.palette.secondary,
    },
  });

  return (
    <Dialog maxWidth='md' open={open} fullWidth sx={rootStyles} data-testid={testId}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {title && title}
        <IconButton
          edge='end'
          color='inherit'
          onClick={onClose}
          aria-label='close'
          sx={{ color: 'grey.500' }} // optional styling
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={contentStyles}>{children}</DialogContent>
      <DialogActions sx={{ padding: 2, ...actionsStyles }}>
        <ThemeProvider theme={theme}>
          {actionButtonTwo && (
            <LoadingButton
              data-testid='modal-action-two-btn'
              variant='text'
              color='secondary'
              onClick={actionButtonTwo.onClick}
              loading={actionButtonTwo.loading}
            >
              {actionButtonTwo.text}
            </LoadingButton>
          )}
          <LoadingButton
            data-testid='modal-action-one-btn'
            variant='contained'
            color='primary'
            onClick={actionButtonOne.onClick}
            loading={actionButtonOne.loading}
          >
            {actionButtonOne.text}
          </LoadingButton>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  );
};

export default ActionsDialog;
