import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Box, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { INoiseAware } from 'device/deviceType';
import { getHistoricalByDeviceId } from 'device/state/deviceActions';
import { devicesByPropertyIdSelector } from 'device/state/deviceSelector';
import { Property } from 'property/propertyType';
import { getProperty } from 'property/state/propertySelectors';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { patchThreshold } from 'threshold/state/thresholdAction';
import { getThresholdsByDeviceId } from 'threshold/state/thresholdSelectors';
import { IThresholdExtended, IThresholdNotificationsHR } from 'threshold/thresholdType';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import Widget from 'ui-library/Components/widget/Widget';
import RiskDisplay, { RiskLevel } from 'ui-library/Components/widget/content/RiskDisplay';
import ShowMoreToggle from 'ui-library/Components/widget/content/ShowMoreToggle';
import DeviceTitleDropdown from './DeviceTitleDropdown';
import NoiseAwareSetting from './NoiseAwareSetting';
import NoiseFooter from './NoiseFooter';

export const QUIET_NOISEAWARE_THRESHOLD = 30;
export const REGULAR_NOISEAWARE_THRESHOLD = 60;
export const QUIET_FROM_DEFAULT = 0;
export const QUIET_TO_DEFAULT = 10;
export const REGULAR_TO_DEFAULT = 24;

export interface NoiseCardProps {
  hideFooter?: boolean;
  isOnUnitDashboard?: boolean;
}

export enum NoiseThresholds {
  NoiseMed,
  NoiseHigh,
  NoiseReg,
  NoiseQuiet,
  NoiseQuietFrom,
  NoiseQuietTo,
}

const NoiseAwareCard = ({ hideFooter, isOnUnitDashboard = false }: NoiseCardProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { propertyId: pidStr } = useParams();
  const propertyId = Number(pidStr);
  const devices = useAppSelector(devicesByPropertyIdSelector(propertyId));
  const noiseAwareDevices = devices.filter(
    device => device.vendor === 'NoiseAware',
  ) as INoiseAware[];
  const [selectedDeviceId, setSelectedDeviceId] = React.useState(
    noiseAwareDevices?.[0]?.data?.device_id || 0,
  );
  const deviceInfo = noiseAwareDevices.find(d => d.data?.device_id === selectedDeviceId);
  const deviceNoise = Number(deviceInfo?.data?.noise);
  const deviceThreshold = useAppSelector<IThresholdNotificationsHR>(
    getThresholdsByDeviceId(selectedDeviceId as number),
  );
  const property: Property = useAppSelector(getProperty(propertyId));
  const [openDetails, setOpenDetails] = React.useState(false);
  const [quiet, setQuiet] = React.useState<IThresholdExtended>({
    id: 0,
    value: QUIET_NOISEAWARE_THRESHOLD,
    from: QUIET_FROM_DEFAULT,
    to: QUIET_TO_DEFAULT,
  });
  const [regular, setRegular] = React.useState<IThresholdExtended>({
    id: 0,
    value: REGULAR_NOISEAWARE_THRESHOLD,
  });
  const [currentRisk, setCurrentRisk] = React.useState(RiskLevel.NONE);
  const currentHour = Number(
    formatInTimeZone(new Date(), property?.timezone ?? 'America/Vancouver', 'HH'),
  );
  const isQuietMode = currentHour > Number(quiet?.from) && currentHour < Number(quiet?.to);

  const calculateNoiseAwareRisk = () => {
    let risk = RiskLevel.NONE;
    if (deviceNoise) {
      if (isQuietMode) {
        risk = deviceNoise >= quiet.value ? RiskLevel.HIGH : RiskLevel.LOW;
      } else {
        risk = deviceNoise >= regular.value ? RiskLevel.HIGH : RiskLevel.LOW;
      }
    }
    setCurrentRisk(risk);
  };

  React.useEffect(() => {
    if (noiseAwareDevices?.length > 0) {
      setSelectedDeviceId(noiseAwareDevices?.[0].data?.device_id);
    }
  }, [noiseAwareDevices, noiseAwareDevices.length]);

  React.useEffect(() => {
    if (deviceThreshold) {
      setQuiet(deviceThreshold.noiseQuiet);
    }
  }, [deviceThreshold]);

  React.useEffect(() => {
    if (deviceThreshold) {
      setRegular(deviceThreshold.noiseReg);
    }
  }, [deviceThreshold]);

  React.useEffect(() => {
    calculateNoiseAwareRisk();
  });

  const toggleOpenDetails = () => {
    setOpenDetails(!openDetails);
  };

  const updateThreshold = async (
    id: number | undefined,
    oldValue: number,
    newValue: number,
    field: 'value' | 'from' | 'to',
    setState: React.Dispatch<React.SetStateAction<IThresholdExtended>>,
    snackBarMessage: string,
  ) => {
    try {
      setState(newState => ({ ...newState, [field]: newValue }));
      await dispatch(patchThreshold(id, newValue));
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: snackBarMessage,
          variant: SnackbarVariant.SUCCESS,
        }),
      );
    } catch {
      setState(prevState => ({ ...prevState, [field]: oldValue }));
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Failed to update value',
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  // TODO: waiting for threshold api to update the redux, just assign value for now
  const handleNoiseAwareSave = async (field: NoiseThresholds, value: string) => {
    const newValue = Number(value);

    switch (field) {
      case NoiseThresholds.NoiseQuiet:
        await updateThreshold(
          quiet.id,
          quiet.value,
          newValue,
          'value',
          setQuiet,
          'Quiet Threshold Updated',
        );
        break;

      case NoiseThresholds.NoiseQuietFrom:
        await updateThreshold(
          quiet.fromId,
          quiet.from,
          newValue,
          'from',
          setQuiet,
          'Quiet Start Time Updated',
        );
        break;

      case NoiseThresholds.NoiseQuietTo:
        await updateThreshold(
          quiet.toId,
          quiet.to,
          newValue,
          'to',
          setQuiet,
          'Quiet End Time Updated',
        );
        break;

      case NoiseThresholds.NoiseReg:
        await updateThreshold(
          regular.id,
          regular.value,
          newValue,
          'value',
          setRegular,
          'Regular Threshold Updated',
        );
        break;
    }
  };

  const viewMoreDetails = () => {
    navigate(`/units/${propertyId}/devices/noise`);
  };

  const handleDeviceSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDeviceId(e.target.value);
    dispatch(getHistoricalByDeviceId(e.target.value as unknown as number));
  };

  const suffix = 'NRS';
  const deviceNoiseLabel = deviceNoise < 0 ? 'N/A' : `${deviceNoise}${suffix}`;

  if (noiseAwareDevices.length === 0) {
    return null;
  }

  return (
    <Widget
      title={
        <DeviceTitleDropdown
          devices={noiseAwareDevices}
          onChange={handleDeviceSelect}
          selectedDeviceId={selectedDeviceId as number}
          icon={<VolumeUpIcon sx={{ color: 'white' }} />}
        />
      }
      isCarouselCard
      open={openDetails}
      isOnUnitDashboard={isOnUnitDashboard}
      smartCardType='device'
      footer={!hideFooter && <NoiseFooter onDetailsClick={viewMoreDetails} />}
      fill='#008480'
    >
      <>
        <Box sx={{ marginBottom: '14px' }}>
          <ShowMoreToggle onClick={toggleOpenDetails} open={openDetails} fill='#008480' />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ color: 'white' }} variant='h4'>
              {deviceNoiseLabel}
            </Typography>
            {isQuietMode && (
              <Typography
                sx={{
                  color: 'text',
                  textAlign: 'left',
                  fontSize: '12px',
                  marginLeft: '4px',
                }}
                variant='subtitle1'
              >
                (Quiet Mode)
              </Typography>
            )}
          </Box>
          <RiskDisplay riskLevel={currentRisk} />
        </Box>
        {openDetails && (
          <Collapse in={openDetails}>
            <NoiseAwareSetting
              onSave={handleNoiseAwareSave}
              noiseQuiet={quiet}
              noiseRegular={regular}
            />
          </Collapse>
        )}
      </>
    </Widget>
  );
};

export default NoiseAwareCard;
