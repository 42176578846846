import { Grid, Slider } from '@mui/material';
import * as React from 'react';
import RadialBar from 'ui-library/Components/canvas/RadialBar';

interface Mark {
  value: number;
  label?: React.ReactNode;
}

export interface RadialBarRangeSliderProps {
  fromValue: number;
  toValue: number;
  upperRightText: string;
  lowerText: string;
  primaryColor: string;
  secondaryColor: string;
  baseColor: string;
  onChange: (newTemp: number[] | number) => void;
  marks: Mark[];
  valueText: string;
  onUpdate: (value: number[] | number) => void;
  max: number;
  upperLeftTextOne: string;
  upperLeftTextTwo: string;
}

const RadialBarRangeSlider = ({
  fromValue,
  toValue,
  upperRightText,
  lowerText,
  primaryColor,
  secondaryColor,
  baseColor,
  onChange,
  marks,
  onUpdate,
  valueText,
  max,
  upperLeftTextOne,
  upperLeftTextTwo,
}: RadialBarRangeSliderProps) => {
  const [optionsOne, setOptionsOne] = React.useState({
    value: (fromValue * 100) / max,
    upperRightText,
    lowerText,
    upperLeftTextOne,
  });
  const [optionsTwo, setOptionsTwo] = React.useState({
    value: (toValue * 100) / max,
    upperRightText,
    lowerText,
    upperLeftTextTwo,
  });

  const changeValue = (event: Event, newValue: number[] | number) => {
    onChange(newValue);
  };
  const callUpdate = (event: Event, value: number[] | number) => {
    onUpdate(value);
  };

  React.useEffect(() => {
    setOptionsOne({
      value: (fromValue * 100) / max,
      upperRightText,
      lowerText,
      upperLeftTextOne,
    });
    setOptionsTwo({
      value: (toValue * 100) / max,
      upperRightText,
      lowerText,
      upperLeftTextTwo,
    });
  }, [
    fromValue,
    toValue,
    upperRightText,
    lowerText,
    primaryColor,
    baseColor,
    marks,
    valueText,
    max,
    upperLeftTextOne,
    upperLeftTextTwo,
  ]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <RadialBar
          optionsOne={optionsOne}
          optionsTwo={optionsTwo}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          baseColor={baseColor}
        />
      </Grid>
      <Grid item xs={12}>
        <Slider
          value={[fromValue, toValue]}
          onChange={changeValue}
          valueLabelDisplay='off'
          aria-labelledby='double-slider'
          marks={marks}
          onChangeCommitted={callUpdate}
          max={max}
        />
      </Grid>
    </Grid>
  );
};

export default RadialBarRangeSlider;
