import axios from 'axios';
// import { INumericFilter } from '../types/netatmo';

const api = process.env.REACT_APP_API_URL;

export const getDevice = (deviceId: number) => axios.get(`${api}/v2/devices/${deviceId}`);

export const getDevices = () => axios.get(`${api}/v2/airquality/recent/member`);

export const getDeviceSettings = (deviceId: number) =>
  axios.get(`${api}/v2/devices/${deviceId}/settings`);

export const getHistorical = (deviceId: number) => {
  // /const filter = start ? `?startTime=${start}&endTime=${end}` : ``;
  // return axios.get(`${api}/v2/devices/${deviceId}/historical-data${filter}`);
  // TODO the airquality API takes 'days' parameter, the one under devices was always called with start=null and end=null so call it without days (it default to 7 on the server)
  return axios.get(`${api}/v2/airquality/${deviceId}/historical-data`);
};
