import SaveIcon from '@mui/icons-material/Save';
import { Button, Grid, TextField } from '@mui/material';
import { SettingCard } from '@operto/ui-library';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Host, useGuestPortal } from 'redux/guestportal';
import { ImageUploadType } from 'redux/guestportal/models';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { userPermissionSelector } from 'user/state/userSelectors';
import GPImageEditor from '../Common/GPImageEditor';

type HostDetailsCardProps = {
  host: Host;
  onSave: (host: Host) => void;
  permission: boolean;
};
export const HostDetailsCard: React.FC<HostDetailsCardProps> = props => {
  const { host, onSave, permission } = props;
  const [convertedHost, setConvertedHost] = React.useState(host);
  const [imageUrl, setImageUrl] = React.useState(host?.imageURL);
  const { propertyId } = useParams();

  React.useEffect(() => {
    setConvertedHost(host);
  }, [host]);

  React.useEffect(() => {
    setConvertedHost(prev => {
      return { ...prev, imageURL: imageUrl };
    });
  }, [imageUrl]);

  const onImageUpload = (url: string) => {
    setImageUrl(url);
  };
  const onChange = (data: Host) => {
    setConvertedHost(data);
  };
  return (
    <SettingCard
      title={propertyId ? 'Custom Host Details' : 'Primary Host Details'}
      subtitle={
        propertyId
          ? 'Set your guest portal custom address.'
          : 'Set your guest portal primary address.'
      }
      description={propertyId ? '' : 'You may over ride this at the Unit level if needed.'}
      footerChildren={
        <Button
          variant='contained'
          color='primary'
          startIcon={<SaveIcon />}
          onClick={() => {
            onSave(convertedHost);
          }}
          disabled={!permission}
        >
          Save
        </Button>
      }
    >
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField
            label='Full Name'
            placeholder="Enter host's full name"
            variant='outlined'
            margin='normal'
            fullWidth
            value={convertedHost?.name || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange({
                ...convertedHost,
                name: e.target.value,
              })
            }
            disabled={!permission}
          />
          <TextField
            id='name'
            label='Email Address'
            placeholder="Enter host's email address"
            variant='outlined'
            margin='normal'
            fullWidth
            value={convertedHost?.email || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange({
                ...convertedHost,
                email: e.target.value,
              })
            }
            disabled={!permission}
          />
          <TextField
            id='phone'
            label='Phone Number'
            placeholder="Enter host's phone number"
            variant='outlined'
            margin='normal'
            fullWidth
            value={convertedHost?.phone || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange({
                ...convertedHost,
                phone: e.target.value,
              })
            }
            disabled={!permission}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <ImageContainer>
            <GPImageEditor
              ratio='1:1'
              image={convertedHost?.imageURL ?? ''}
              onImageChange={onImageUpload}
              imageType={ImageUploadType.HOST_AVATAR}
            />
          </ImageContainer>
        </Grid>
      </Grid>
    </SettingCard>
  );
};

const ImageContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

type HostDetailsProps = {
  visible?: boolean;
};
export const HostDetails: React.FC<HostDetailsProps> = props => {
  const { visible } = props;
  const { host, setHost, fetchHostData } = useGuestPortal();
  React.useEffect(() => {
    fetchHostData();
  }, [fetchHostData]);
  const permission = useAppSelector(userPermissionSelector());

  const onSave = (data: Host) => {
    setHost(data);
  };
  if (!visible) {
    return null;
  }

  return (
    <LoadingContainer loading={!host}>
      <HostDetailsCard host={host} onSave={onSave} permission={permission} />
    </LoadingContainer>
  );
};

export default HostDetails;
