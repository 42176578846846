import { UserRole } from 'user/userTypes';

export const permissionString = (permission: UserRole) => {
  switch (permission) {
    case 'account-owner':
      return 'Account Owner';
    case 'global-manager':
      return 'Global Manager';
    case 'regional-manager':
      return 'Regional Manager';
    default:
      return 'Basic Access';
  }
};

export const Role = {
  AccountOwner: 'account-owner',
  GlobalManager: 'global-manager',
  RegionalManager: 'regional-manager',
  BasicUser: 'basic-user',
} as const;
