import React from 'react';
import { QRCode } from 'react-qrcode-logo';

export interface QRCodeWithLogoProps {
  url: string;
  imageUrl?: string;
  color: string;
  size: number;
  id?: string;
}

const QRCodeWithLogo = ({ url, imageUrl, color, size, id }: QRCodeWithLogoProps) => {
  return (
    <QRCode
      value={url}
      logoImage={imageUrl}
      size={size}
      logoOpacity={0.9}
      logoWidth={size / 4}
      fgColor={`#${color}`}
      enableCORS
      id={id}
    />
  );
};

export default QRCodeWithLogo;
