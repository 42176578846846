// @ts-nocheck

import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import SelectTimezone from 'Common/TextField/SelectTimezone';
import { emptyProperty } from 'property/propertyType';
import React from 'react';
import { AddCard } from '@operto/ui-library';
import { Property } from 'property/propertyType';
import SimpleReactValidator from 'simple-react-validator';

export interface IGeneralContainerProps {
  data?: Property;
  validator?: SimpleReactValidator;
  onChange?: (id: string, value: string) => void;
  onFieldBlur?: () => void;
}

const GeneralContainer = (props: IGeneralContainerProps) => {
  const { data, validator, onChange, onFieldBlur } = props;
  const [errors, setErrors] = React.useState(emptyProperty);
  const valueChanger = (e: React.ChangeEvent<HTMLInputElement>) => {
    const field = e.target.id || e.target.name;
    onChange(field, e.target.value);
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    const field = e.target.id || e.target.name;
    const newErrors: Record<string, unknown> = {};

    Object.assign(newErrors, errors);
    newErrors[field] = !validator.fieldValid(field);
    setErrors(newErrors);
    onFieldBlur(e);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <AddCard title='Property Details'>
          <Grid container direction='column'>
            <Typography align='left'>Set Property Details</Typography>
            <Grid container direction='row' spacing={4}>
              <Grid item md={6} xs={12}>
                <TextField
                  required
                  id='name'
                  label='Property Name'
                  placeholder='Enter property name'
                  variant='outlined'
                  fullWidth
                  onChange={valueChanger}
                  value={data?.name}
                  error={errors?.name}
                  onBlur={handleOnBlur}
                  helperText={validator.message('name', data?.name, 'required')}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  id='address'
                  label='Address'
                  placeholder='Enter property address'
                  variant='outlined'
                  fullWidth
                  onChange={valueChanger}
                  value={data?.address}
                  error={errors?.address}
                  onBlur={handleOnBlur}
                  helperText={validator.message('address', data?.address, 'required')}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  id='city'
                  label='City'
                  placeholder='Enter city'
                  variant='outlined'
                  fullWidth
                  onChange={valueChanger}
                  value={data?.city}
                  error={errors?.city}
                  onBlur={handleOnBlur}
                  helperText={validator.message('city', data?.city, 'required')}
                  InputLabelProps={{ shrink: true }}
                />
                <SelectTimezone
                  id='timezone'
                  value={data?.timezone}
                  onChange={onChange}
                  label='Timezone'
                  validator={validator.message('timezone', data?.timezone, 'required')}
                  error={errors?.timezone}
                />
              </Grid>
            </Grid>
          </Grid>
        </AddCard>
      </Grid>

      <Grid item xs={12}>
        <AddCard title='Property Type'>
          <Grid container direction='column'>
            <Typography align='left'>Select property type</Typography>
            <Grid container direction='row' spacing={4}>
              <Grid item md={6} xs={12}>
                <TextField
                  required
                  select
                  name='property_type'
                  label='Property Type'
                  placeholder='Select property type'
                  variant='outlined'
                  fullWidth
                  onChange={valueChanger}
                  value={data?.property_type}
                  error={errors?.property_type}
                  onBlur={handleOnBlur}
                  helperText={validator.message('property_type', data?.property_type, 'required')}
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem key={'primary'} value={'primary'}>
                    Primary
                  </MenuItem>
                  <MenuItem key={'common'} value={'common'}>
                    Common
                  </MenuItem>
                  <MenuItem key={'additional'} value={'additional'}>
                    Additional
                  </MenuItem>
                  <MenuItem key={'virtual'} value={'virtual'}>
                    Virtual
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </AddCard>
      </Grid>
    </Grid>
  );
};

export default GeneralContainer;
