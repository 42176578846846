import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { ConfirmationModal } from '../../../types/ui';
import { ConfirmFunctions } from '../../../types/confimations';
import { Actions } from '../../../types/actions';

const show: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleConfirmationPopup:
      return !state;
    default:
      return state;
  }
};

const ids: Reducer<number[]> = (state = [], action) => {
  switch (action.type) {
    case Actions.toggleConfirmationPopup:
      return action.ids;
    default:
      return state;
  }
};

const functionType: Reducer<ConfirmFunctions> = (state = ConfirmFunctions.default, action) => {
  switch (action.type) {
    case Actions.toggleConfirmationPopup:
      return action.functionType;
    default:
      return state;
  }
};

const reducer: Reducer<ConfirmationModal> = combineReducers<ConfirmationModal>({
  show,
  ids,
  functionType,
});

export default reducer;
