import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Menu, MenuItem } from '@mui/material';
import { useMedia } from '@operto/ui';
import GuestsTable from 'Common/Tables/Guests/GuestsTable';
import { getMessengerSetting } from 'company/state/companyAction';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getReservationAccessCode } from 'reservation/state/reservationActions';
import { getCurrentReservation } from 'reservation/state/reservationSelectors';
import { FilterType } from '../../../guest/guestType';

const paramFilterTypes = ['all', 'pre-stay', 'in-stay', 'post-stay', 'blocked'] as const;
type ParamFilterType = (typeof paramFilterTypes)[number];

const tabItems = [
  {
    label: 'All Guests',
    value: FilterType.ALL_GUESTS,
  },
  {
    label: 'Pre-Stay',
    value: FilterType.PRE_STAY,
  },
  {
    label: 'In-Stay',
    value: FilterType.IN_STAY,
  },
  {
    label: 'Post-Stay',
    value: FilterType.POST_STAY,
  },
  {
    label: 'Blocked',
    value: FilterType.BLOCKED,
  },
];

const determineTabItem = (type: ParamFilterType) => {
  const index = paramFilterTypes.indexOf(type);
  return tabItems[index] || tabItems[0];
};

const GuestsContainer = () => {
  const { propertyId, roomType, type } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useAppDispatch();
  const reservation = useAppSelector(getCurrentReservation(Number(propertyId)));
  const tabItem = determineTabItem(type as ParamFilterType);
  const navigate = useNavigate();
  const { isMobile } = useMedia();

  const filterChangeHandler = (filter: ParamFilterType) => {
    setAnchorEl(null);
    const prefixPath = roomType ? `/room-types/${roomType}` : `/units`;
    navigate(`${prefixPath}/${propertyId}/guests/${filter}`);
  };

  const handleClickAnchor = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  const header = (
    <>
      <Button
        color='primary'
        variant='contained'
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClickAnchor}
        size={isMobile ? 'small' : 'medium'}
      >
        {tabItem.label}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseAnchor}>
        <MenuItem onClick={() => filterChangeHandler('all')}>All</MenuItem>
        <MenuItem onClick={() => filterChangeHandler('pre-stay')}>Pre-Stay</MenuItem>
        <MenuItem onClick={() => filterChangeHandler('in-stay')}>In-Stay</MenuItem>
        <MenuItem onClick={() => filterChangeHandler('post-stay')}>Post-Stay</MenuItem>
        <MenuItem onClick={() => filterChangeHandler('blocked')}>Blocked</MenuItem>
      </Menu>
    </>
  );

  useEffect(() => {
    if (reservation) {
      dispatch(getReservationAccessCode(reservation?.id));
    }
  }, [reservation, reservation?.id, dispatch]);

  useEffect(() => {
    dispatch(getMessengerSetting());
  }, [dispatch]);

  return (
    <>
      <GuestsTable
        propertyView={roomType === undefined}
        filterType={tabItem.value}
        roomType={roomType}
        header={header}
      />
    </>
  );
};

export default GuestsContainer;
