import { Box, SxProps, Typography } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { theme } from '@operto/ui';
import { parse, startOfDay } from 'date-fns';
import { format } from 'date-fns-tz';
import React from 'react';
import { FilterDataType, FilterGroup } from 'types/filter-type';
import { DatePickerOption } from './DatePickerOption';
import { titleStyle } from './FilterPanelMobile';
import { FiltersToolbarMobileProps } from './FiltersToolbarMobile';

export type DatePickerFilterPanelMobileProps = Omit<
  FiltersToolbarMobileProps,
  'filterGroups' | 'updateTotalFilterLabelMobile'
> & {
  filter: FilterGroup;
};

export const DatePickerFilterPanelMobile = ({
  filter,
  searchParams,
  onFilterChange,
}: DatePickerFilterPanelMobileProps) => {
  const handleChange = (value: DateRange<Date>) => {
    const fromKey = `${filter.groupName}_from`;
    const untilKey = `${filter.groupName}_until`;

    const fromValue = value[0];
    let untilValue = value[0];
    if (value[1] != null) {
      untilValue = value[1];
    }

    searchParams.set(fromKey, format(fromValue, 'yyyy-MM-dd'));
    searchParams.set(untilKey, format(untilValue, 'yyyy-MM-dd'));

    onFilterChange(searchParams);
  };

  return (
    <Box sx={container} id={`index-${filter.groupName}`}>
      <Box sx={headerStyle}>
        <Typography sx={titleStyle}>{filter.label}</Typography>
      </Box>
      {filter.options.map((subOption: FilterDataType, index: number) => {
        return (
          <DatePickerOption
            key={index}
            onChange={handleChange}
            numberOfCalendars={1}
            defaultValue={
              searchParams.get(`${filter.groupName}_from`) != null
                ? [
                    parse(
                      searchParams.get(`${filter.groupName}_from`),
                      'yyyy-MM-dd',
                      startOfDay(new Date()),
                    ),
                    parse(
                      searchParams.get(`${filter.groupName}_until`),
                      'yyyy-MM-dd',
                      startOfDay(new Date()),
                    ),
                  ]
                : [startOfDay(new Date()), startOfDay(new Date())]
            }
          />
        );
      })}
    </Box>
  );
};

const container: SxProps = {
  gap: '8px',
  '.MuiDateRangeCalendar-root': {
    justifyContent: 'center',
  },
  borderBottom: `1px solid ${theme.palette.divider}`,
};

const headerStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '16px',
};
