import axios from 'axios';

const api = process.env.REACT_APP_API_URL;
export const getGroups = () => axios.get(`${api}/v3/groups`, {});

export const createGroup = (data: unknown) => axios.post(`${api}/v2/groups`, data);

export const updateGroup = (data: Record<string, unknown>) =>
  axios.put(`${api}/v2/groups/${data.id}`, data);

export const deleteGroup = (id: number) => axios.delete(`${api}/v2/groups/${id}`);

export const addUnitToUnitGroup = (property_ids: number[], groupId: number) =>
  axios.post(`${api}/v2/groups/${groupId}/properties`, {
    property_ids,
  });

export const addUnitToGroup = (property_ids: number[], groupId: number, type?: string) =>
  axios.post(`${api}/v2/groups/${groupId}/properties`, {
    property_ids,
    type,
  });

// FUTURE RELEASE
// export const removeUnitsToUnitGroup = (
//   property_ids: number[],
//   groupId: number,
// ) =>
//   axios.post(`${api}/v2/groups/${groupId}/properties`, {
//     property_ids,
//   });

export const removeUnitFromGroup = (propertyId: number, groupId: number, type?: string) =>
  axios.delete(`${api}/v2/groups/${groupId}/properties/${propertyId}`, { data: { type } });
