import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import { Chip, IconButton, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { trpc } from '@operto/trpc-client';
import { Variable } from '@operto/variables-shared';
import { getValidColor } from 'Common/ColorPicker';
import CategoriesDropdown from 'Pages/GuestPortal/Common/CategoriesDropdown';
import { isPageOwnInvalidVariable } from 'Pages/Variables/helpers/VariableHelpers';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import { UNCATEGORIZED_ID } from 'helper/helper';
import { logger } from 'lib/logger';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ICategory, IPage, useGuestPortal, useGuestPortalStatus } from 'redux/guestportal';
import { useGuidesPages } from 'redux/guestportal/hooks/useGuidesPages';
import { useAppSelector } from 'redux/hooks';
import { SortTable } from 'ui-library/Components/table/SortTable';
import { userPermissionSelector } from 'user/state/userSelectors';
import ActionDropDownMenu, { ActionDropDownMenuProps } from '../../../Common/ActionDropDownMenu';
import { GuidesActionsType } from '../../types';

export const newPageTemplate = (author: string): Partial<IPage> => {
  const now = getUnixTime(new Date());
  return {
    category_id: UNCATEGORIZED_ID,
    title: '',
    subtitle: '',
    description: '',
    last_edit_by: author,
    last_edit_at: now,
    url: '',
    video_url: '',
    images_url: [],
    published: false,
    featured: false,
    scheduled: false,
    scheduled_start: now,
    scheduled_end: now,
    button: {
      enabled: false,
      title: 'Button',
      color: getValidColor(),
      hyperlink: '',
      messenger_enabled: false,
      subject: '',
      autoreply_message: '',
    },
  };
};

const columns = (
  onAction: (action: GuidesActionsType, page: IPage) => void,
  isProperty: boolean,
  permission: boolean,
  categoryList: ICategory[],
  updateCategory: (newLocation: { page: IPage; newCategoryId: string }) => void,
  customVariables: Variable[],
): GridColDef<IPage>[] => [
  {
    field: 'title',
    headerName: 'Page Title',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, IPage>) => {
      return (
        <>
          <Typography>{row.title}</Typography>
          {isPageOwnInvalidVariable(row, customVariables) && (
            <Chip color='primary' variant='outlined' label='Invalid Variable' />
          )}
        </>
      );
    },
  },
  {
    field: 'category_id',
    headerName: 'Category',
    flex: 1,
    width: 300,
    renderCell: ({ row }: GridRenderCellParams<unknown, IPage>) => {
      return renderCategoryList(row, categoryList, updateCategory, isProperty);
    },
  },
  {
    field: 'last_edit_by',
    headerName: 'Last Edited By',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, IPage>) => {
      return <Typography>{row.last_edit_by}</Typography>;
    },
  },
  {
    field: 'last_edit_at',
    headerName: 'Last Edited',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, IPage>) => {
      return <Typography>{format(fromUnixTime(row.last_edit_at), 'P')}</Typography>;
    },
  },
  {
    field: 'featured',
    headerName: 'Featured',
    flex: 1,
    hide: isProperty,
    renderCell: ({ row }: GridRenderCellParams<unknown, IPage>) => {
      return row.featured && <StarIcon color='primary' />;
    },
  },
  {
    field: 'published',
    headerName: 'Published',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, IPage>) => {
      return row.published && <CheckCircleIcon color='primary' />;
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    renderCell: ({ row }: GridRenderCellParams<unknown, IPage>) => {
      const menuProps: ActionDropDownMenuProps = {
        disabled: !permission,
        onEdit: () => onAction('edit', row),
        onPublished: () => onAction('publish', row),
        ...(!isProperty && { onDelete: () => onAction('delete', row) }),
        ...(!isProperty && { onFeatured: () => onAction('feature', row) }),
      };

      return (
        <>
          <IconButton onClick={() => onAction('edit', row)} disabled={!permission} size='large'>
            <EditIcon color={permission ? 'primary' : 'disabled'} />
          </IconButton>
          <ActionDropDownMenu {...menuProps} />
        </>
      );
    },
  },
];

const renderCategoryList = (
  page: IPage,
  categories: ICategory[],
  updateCategory: (data: { page: IPage; newCategoryId: string }) => void,
  isProperty: boolean,
) => {
  const selectedCategory = categories.find(c => c.category_id === page?.category_id);
  if (isProperty) {
    return <Typography>{selectedCategory?.category}</Typography>;
  }

  return (
    <CategoriesDropdown
      categoriesList={categories}
      selectedCategory={selectedCategory}
      onChange={async e => {
        const newCategoryId = e.target.value;
        updateCategory({ page, newCategoryId });
      }}
    />
  );
};

type GuidesDetailsPagesTableProps = {
  onAction: (action: GuidesActionsType, page?: IPage) => void;
};

export const GuidesDetailsPagesTable = ({ onAction }: GuidesDetailsPagesTableProps) => {
  const {
    data: customVariables,
    isLoading: isVariablesLoading,
    isError,
    error,
  } = trpc.variables.getVariables.useQuery({
    type: 'custom',
  });
  const permission = useAppSelector(userPermissionSelector());
  const { pagesList, categoriesList } = useGuestPortal();
  const { isLoading } = useGuestPortalStatus();
  const { move, sort } = useGuidesPages();
  const { propertyId } = useParams();
  const [filteredList, setFilteredList] = useState<IPage[]>([]);

  const handleSearch = useCallback(
    (value?: string) => {
      if (!value?.length) {
        return setFilteredList(pagesList);
      }

      const filter = value.toLowerCase();
      setFilteredList(
        pagesList.filter(
          item =>
            item.title?.toLowerCase().includes(filter) ||
            item.category?.toLowerCase().includes(filter) ||
            item.last_edit_by?.toLowerCase().includes(filter),
        ),
      );
    },
    [pagesList],
  );

  const handleCategoryUpdate = ({
    newCategoryId,
    page,
  }: {
    page: IPage;
    newCategoryId: string;
  }) => {
    move(newCategoryId, page);
  };

  const handleSort = (order: IPage[]) => {
    sort(order.map(p => ({ id: p.content_id, type: 'content' })));
  };

  useEffect(() => {
    if (isError) {
      logger.error(error);
    }
  }, [error, isError]);

  return (
    <SortTable
      title='Pages'
      loading={isLoading || isVariablesLoading}
      initialState={{ pinnedColumns: { right: ['actions'] } }}
      rows={filteredList}
      rowReordering={false} // diabling to keep old functionality the same
      getRowId={row => row.content_id}
      columns={columns(
        onAction,
        !!propertyId,
        permission,
        categoriesList,
        handleCategoryUpdate,
        customVariables,
      )}
      onSearch={handleSearch}
      onAdd={!propertyId ? () => onAction('add') : null}
      onSort={handleSort}
    />
  );
};
