import axios from 'axios';
import { UpsellFields } from 'Pages/Marketplace/UpsellPage';

const api = process.env.REACT_APP_NEO_REST_API;
export const getCompanyOffers = (companyId: number) => {
  const url = `${api}/upsells/companies/${companyId}/offers`;
  return axios.get(url);
};

export const getOffersAvability = (companyId: number) => {
  const url = `${api}/upsells/companies/${companyId}/offersAvailability`;
  return axios.get(url);
};

export const getUnitsGroups = (companyId: number) => {
  const url = `${api}/upsells/companies/${companyId}/units-groups`;
  return axios.get(url);
};

export const getOfferByID = (companyId: number, upsellId: string) => {
  const url = `${api}/upsells/companies/${companyId}/offers/${upsellId}`;
  return axios.get(url);
};

export const getOfferTemplateByID = (templateId: string) => {
  const url = `${api}/upsells/offerTemplate/${templateId}`;
  return axios.get(url);
};

export const updateCompanyOffer = (companyId: number, upsellDetails: UpsellFields) => {
  const url = `${api}/upsells/companies/${companyId}/offers/${upsellDetails.id}`;
  return axios.put(url, upsellDetails);
};

export const uploadFileUrl = (companyId: number) => {
  const url = `${api}/upsells/companies/${companyId}/media`;
  return url;
};

export const getCompanyRequests = (companyId: number) => {
  const url = `${api}/upsells/companies/${companyId}/requests`;
  return axios.get(url);
};

export const getCompanyRequest = (companyId: string, requestId: string) => {
  const url = `${api}/upsells/companies/${companyId}/requests/${requestId}`;
  return axios.get(url);
};

export const updateRequestStatus = (
  companyId: string,
  requestId: string,
  status: string,
  declinedReason?: string | null,
  declinedAt?: string | null,
) => {
  const url = `${api}/upsells/companies/${companyId}/requests/${requestId}`;
  return axios.put(url, {
    status: status,
    declinedReason: declinedReason,
    declinedAt: declinedAt,
  });
};

export const getOfferTemplates = () => {
  const url = `${api}/upsells/offerTemplateList`;
  return axios.get(url);
};

export const createOffer = (companyId: number, body: UpsellFields) => {
  const url = `${api}/upsells/companies/${companyId}/offers`;
  return axios.post(url, body);
};

export const deleteOffer = (companyId: number, offerId: string) => {
  const url = `${api}/upsells/companies/${companyId}/offers/${offerId}`;
  return axios.delete(url);
};
