import { Avatar, Box, Stack, SxProps, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

export default function CommunicationsSMSTemplatePreview({ message }: { message: string }) {
  const { t } = useTranslation();

  return (
    <Stack sx={rootStyles}>
      <Avatar sx={avatarStyles} />

      <Box flex={1} height={'fit-content'}>
        <Box sx={messageStyles}>
          <Typography sx={textStyles}>{message}</Typography>
          <Typography sx={noreplyStyles}>{t('no_reply')}</Typography>
        </Box>
      </Box>
    </Stack>
  );
}

const rootStyles: SxProps = {
  width: '375px',
  backgroundColor: 'white',
  flex: 1,
  borderRadius: '28px',
  border: '8px solid var(--Grey-600, #757575)',
  overflowY: 'auto',
  padding: '24px 16px',
  gap: '8px',
  flexDirection: 'row',
};

const avatarStyles: SxProps = {
  backgroundColor: 'black',
};

const messageStyles: SxProps = {
  padding: '10px 16px',
  borderRadius: '22px',
  border: '1px solid var(--Grey-600, #757575)',
};

const noreplyStyles: SxProps = {
  marginTop: '22px',
};

const textStyles: SxProps = {
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
};
