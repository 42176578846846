import { MoreVertOutlined } from '@mui/icons-material';
import { IconButton, PopoverOrigin } from '@mui/material';
import { CommunicationType } from 'hooks/useCommunications';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CommunicationsMoreMenu, { MENU_OPTIONS } from './CommunicationsMoreMenu';
import { MenuOptionType } from './CommunicationsNewMenu';
import CommunicationsTemplateDeleteConfirmDialog, {
  TemplateDeletionTypes,
} from './CommunicationsTemplateDeleteConfirmDialog';

export default function CommunicationsTableAction({
  templateId,
  channelType,
  communications,
}: {
  templateId: string;
  channelType: string;
  communications: CommunicationType[];
}) {
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const { t } = useTranslation();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const menuOpen = Boolean(menuAnchorEl);

  const menuAnchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  };

  const menuTransformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };

  const handleCloseDeleteTemplateDialog = () => {
    setShowDeleteDialog(false);
  };

  const handleSuccessDeleteTemplateDialog = () => {
    navigate('/communications');
    snackbar(t('deleted'));
  };

  const handleFailureDeleteTemplateDialog = (error: string) => {
    // Implement failure logic, such as showing an error message
    logger.error('Deletion failed:', error);
  };

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMoreClose = (selectedOption?: MenuOptionType) => {
    if (selectedOption === MENU_OPTIONS.EDIT) {
      navigate(`/communications/edit/${channelType}/${templateId}`);
    }

    if (selectedOption === MENU_OPTIONS.DELETE) {
      setShowDeleteDialog(true);
    }

    setMenuAnchorEl(null);
  };

  const handleNewLanguageSelect = (newLanguage: string) => {
    navigate(`/communications/create/${channelType}/${templateId}/${newLanguage}`);
  };

  return (
    <>
      <IconButton size='large' onClick={handleMoreClick} role='button' data-testid='more-button'>
        <MoreVertOutlined />
      </IconButton>

      <CommunicationsMoreMenu
        templateId={templateId}
        anchorEl={menuAnchorEl}
        open={menuOpen}
        handleClose={handleMoreClose}
        anchorOrigin={menuAnchorOrigin}
        transformOrigin={menuTransformOrigin}
        onSelectNewLanguage={handleNewLanguageSelect}
        communications={communications}
      />
      {showDeleteDialog && (
        <CommunicationsTemplateDeleteConfirmDialog
          deletionType={TemplateDeletionTypes.ALL_LANGUAGES}
          channel={channelType}
          templateName={communications.find(c => c.id === templateId)?.name}
          templateId={templateId}
          contentId={communications.find(c => c.id === templateId)?.contentId}
          onClose={handleCloseDeleteTemplateDialog}
          onSuccess={handleSuccessDeleteTemplateDialog}
          onFailure={handleFailureDeleteTemplateDialog}
        />
      )}
    </>
  );
}
