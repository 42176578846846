import { Group, GroupJSON } from './types';

export const toJSON = (group: Group): Omit<GroupJSON, 'id'> => {
  return {
    name: group.name.trim(),
    description: group.description?.trim(),
    group_type: group.groupType,
    property_ids: group.propertyIds,
    member_ids: group.memberIds,
  };
};

const parseGroup = (response: GroupJSON): Group => {
  return {
    id: response.id,
    name: response.name.trim(),
    description: response.description?.trim(),
    groupType: response.group_type,
    propertyIds: response.property_ids,
    memberIds: response.member_ids,
  };
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
export const parseGroups = (response: GroupJSON[]) => {
  const sorted = response.sort((a, b) => collator.compare(a.name, b.name));
  return sorted.map(parseGroup);
};
