import Stack from '@mui/material/Stack';
import { Text } from '@operto/ui';
import React from 'react';

export const NotFound = () => {
  return (
    <Stack justifyContent='center' alignItems='center' width='100%'>
      <Text variant='h1-700'>404 Page Not Found</Text>
      <Text>{"Looks like we can't find your destination"}</Text>
    </Stack>
  );
};
