import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, Stack, SxProps } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import {
  computeCurrentListItems,
  computeItemsToMove,
  getPinnedRowClassName,
  getUniqueNumbers,
  pinnedComparator,
  removeDuplicateNumbers,
} from 'Pages/Groups/EditGroup/group-utils';
import { featurePermissionByMemberRole } from 'helper/helper';
import { IMember } from 'member/memberType';
import React, { useState } from 'react';
import { INITIAL_STATE, useGetGroupsQuery } from 'redux/groups/api-slice';
import { Group } from 'redux/groups/types';
import { useAppSelector } from 'redux/hooks';
import { MEMBERS_ROLES } from 'redux/members/types';
import {
  addButtonStyle,
  addRemoveButtonContainerStyle,
  itemsTableStyle,
  removeButtonStyle,
  tableContainerStyle,
} from 'ui-library/Components/table/table-styles';
import { currentUserSelector } from 'user/state/userSelectors';
import {
  GroupsSelectionTable,
  renderGroupName,
  renderNumberOfUnits,
} from '../GroupsSelectionTable';

const GroupTableType = {
  ASSIGNED: 'assigned',
  NOT_ASSIGNED: 'notAssigned',
};
type GroupTableType = (typeof GroupTableType)[keyof typeof GroupTableType];

const createColumnDef = (selectedGroups: number[]): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: selectedGroups.length === 0 ? 'Groups' : `${selectedGroups.length} selected`,
      flex: 1,
      valueGetter: params => params.row?.isPinned,
      renderCell: renderGroupName,
      sortable: false,
      sortComparator: pinnedComparator,
    },
    {
      field: 'units',
      headerName: 'Units',
      flex: 1,
      renderCell: renderNumberOfUnits,
      sortable: false,
    },
  ];
};

type GroupsContainerProps = {
  member: IMember;
  onChange: (value: string, ids: number[]) => void;
};

export const GroupsContainer = ({ member, onChange }: GroupsContainerProps) => {
  const { data: groupsNotAssigned = INITIAL_STATE, isFetching: groupsNotAssignedFetching } =
    useGetGroupsQuery();

  const [selectedAssignedGroups, setSelectedAssignedGroups] = useState<number[]>([]);
  const [selectedNotAssignedGroups, setSelectedNotAssignedGroups] = useState<number[]>([]);

  const [pinnedAssignedGroups, setPinnedAssignedGroups] = useState<Group[]>(
    groupsNotAssigned.groups.reduce((result, group) => {
      if (member.groups.includes(group.id)) {
        result.push({ ...group, isPinned: true });
      }
      return result;
    }, []),
  );
  const [pinnedNotAssignedGroups, setPinnedNotAssignedGroups] = useState<Group[]>([]);

  const handleAdd = () => {
    const groupsToMove: Group[] = computeItemsToMove(
      groupsNotAssigned.groups,
      selectedNotAssignedGroups,
    );

    // moving groups from not assigned table to assigned table
    setPinnedAssignedGroups(prevPinnedAssignedGroups => [
      ...prevPinnedAssignedGroups,
      ...groupsToMove,
    ]);

    // updating the groups
    const updatedGroup = removeDuplicateNumbers([...member.groups, ...selectedNotAssignedGroups]);
    onChange('groups', updatedGroup);

    // removing groups from not assigned table
    setPinnedNotAssignedGroups(prevPinnedNotAssignedGroups =>
      prevPinnedNotAssignedGroups.filter(group => !selectedNotAssignedGroups.includes(group.id)),
    );

    // resetting selected groups
    setSelectedNotAssignedGroups([]);
  };

  const handleRemove = () => {
    const groupsToMove: Group[] = computeItemsToMove([], selectedAssignedGroups);

    // moving group from assigned table to not assigned table
    setPinnedNotAssignedGroups(prevPinnedNotAssignedGroup => {
      const updatedGroups = [...prevPinnedNotAssignedGroup, ...groupsToMove];
      return updatedGroups;
    });

    // removing groups from assigned table
    setPinnedAssignedGroups(prevPinnedAssignedGroup => {
      const updatedGroups = prevPinnedAssignedGroup.filter(
        group => !selectedAssignedGroups.includes(group.id),
      );
      return updatedGroups;
    });

    // updating the groups
    const updatedGroup = getUniqueNumbers([...member.groups, ...selectedAssignedGroups]);
    onChange('groups', updatedGroup);

    // resetting selected groups
    setSelectedAssignedGroups([]);
  };

  const handleSelectedGroups = (selectedGroupIds: number[], groupTableType: GroupTableType) => {
    if (groupTableType === GroupTableType.ASSIGNED) {
      setSelectedAssignedGroups(selectedGroupIds);
    } else {
      setSelectedNotAssignedGroups(selectedGroupIds);
    }
  };

  const handleTitleInfo = (tableType: GroupTableType) => {
    if (tableType === GroupTableType.ASSIGNED) {
      return '0 assigned groups';
    } else {
      return `${groupsNotAssigned.totalGroups} NOT assigned groups`;
    }
  };

  const user = useAppSelector(currentUserSelector());

  const isRowSelectable = (selectedRows: number[]): boolean => {
    //disable row if the scope doesn't include the role or any
    const allowedScope = featurePermissionByMemberRole(user?.role, member?.role, 'groups', 'edit');
    //you are allowed to edit yourself as a global manager, this is a bit more complex to be covered
    // in the simple permission setup, it will have to be a case covered in the fill permission system
    const allowIfSelfGlobal =
      user?.role === MEMBERS_ROLES.GLOBAL_MANAGER && user?.id === member?.id;
    return (allowedScope || allowIfSelfGlobal) && selectedRows.length === 0;
  };

  return (
    <Stack direction='row' width='100%' sx={groupSelectionTableContainerStyle}>
      <Box sx={tableContainerStyle} data-testid='assigned-group-table'>
        <GroupsSelectionTable
          loading={false}
          selectionModel={selectedAssignedGroups}
          onSelectionChange={groupIds => handleSelectedGroups(groupIds, GroupTableType.ASSIGNED)}
          rows={computeCurrentListItems([], pinnedAssignedGroups, pinnedNotAssignedGroups)}
          rowCount={0}
          getRowClassName={getPinnedRowClassName}
          columns={createColumnDef(selectedAssignedGroups)}
          tableInfo={handleTitleInfo(GroupTableType.ASSIGNED)}
          isRowSelectable={() => isRowSelectable(selectedNotAssignedGroups)}
          disableSearch={selectedNotAssignedGroups.length > 0}
          sortingMode='server'
          sx={itemsTableStyle}
        />
      </Box>
      <Box sx={addRemoveButtonContainerStyle}>
        <Button
          startIcon={<KeyboardArrowLeftIcon />}
          sx={addButtonStyle(selectedNotAssignedGroups.length > 0 && !groupsNotAssignedFetching)}
          onClick={() => handleAdd()}
          disabled={groupsNotAssignedFetching || selectedNotAssignedGroups.length === 0}
        >
          Add
        </Button>
        <Button
          endIcon={<KeyboardArrowRightIcon />}
          sx={removeButtonStyle(selectedAssignedGroups.length > 0)}
          onClick={() => handleRemove()}
          disabled={groupsNotAssignedFetching || selectedAssignedGroups.length === 0}
        >
          Remove
        </Button>
      </Box>
      <Box sx={tableContainerStyle} data-testid='not-assigned-group-table'>
        <GroupsSelectionTable
          loading={groupsNotAssignedFetching}
          selectionModel={selectedNotAssignedGroups}
          onSelectionChange={groupIds =>
            handleSelectedGroups(groupIds, GroupTableType.NOT_ASSIGNED)
          }
          rows={computeCurrentListItems(
            groupsNotAssigned.groups,
            pinnedNotAssignedGroups,
            pinnedAssignedGroups,
          )}
          getRowClassName={getPinnedRowClassName}
          columns={createColumnDef(selectedNotAssignedGroups)}
          tableInfo={handleTitleInfo(GroupTableType.NOT_ASSIGNED)}
          isRowSelectable={() => isRowSelectable(selectedAssignedGroups)}
          disableSearch={selectedAssignedGroups.length > 0}
          sortingMode='server'
          sx={itemsTableStyle}
        />
      </Box>
    </Stack>
  );
};

const groupSelectionTableContainerStyle: SxProps = {
  height: 'calc(100vh - 220px)',
};
