import { Autocomplete, Box, FormControl, TextField, Typography } from '@mui/material';
import { AddCard } from '@operto/ui-library';
import useTranslation from 'hooks/useTranslation';
import React, { ChangeEvent, useMemo } from 'react';
import renderTextField from './TextInput';

interface InformationSectionProps {
  name: string;
  header?: string;
  subheader?: string;
  description: string;
  category: string | null;
  setName: (name: string) => void;
  setHeader: (header: string) => void;
  setSubheader: (subheader: string) => void;
  setDescription: (description: string) => void;
  setCategory: (category: string) => void;
}

const getCategoryList = () => {
  return [
    {
      title: 'Early Check In',
    },
    {
      title: 'Late Check Out',
    },
    {
      title: 'Pet Fees',
    },
    {
      title: 'Accommodation Add-ons',
    },
    {
      title: 'Pool Heating',
    },
    {
      title: 'Experiences',
    },
    {
      title: 'Relaxation and Wellness',
    },
    {
      title: 'Other',
    },
  ];
};

const InformationSection = ({
  name,
  description,
  category = '',
  setName,
  setDescription,
  setCategory,
}: InformationSectionProps) => {
  const { t } = useTranslation();
  const maxLengthShort = 30;
  const maxLengthLong = 1000;
  const categoryState = useMemo(() => {
    const categoriesList = getCategoryList();
    return {
      selected: categoriesList.find(item => item.title === category) ?? null,
      options: categoriesList,
    };
  }, [category]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleCategoryChange = (event: React.SyntheticEvent, value: { title: string } | null) => {
    setCategory(value ? value.title : null);
  };

  return (
    <AddCard
      title={
        <Box display='flex' flexDirection='column' justifyContent='space-between'>
          <Typography variant='subtitle1'>{t('information') + ' *'}</Typography>
          <Typography variant='body2'>
            {t('guests_can_view_the_offer_name_and_description')}
          </Typography>
        </Box>
      }
    >
      <Box
        sx={{
          paddingRight: 4,
        }}
      >
        <FormControl fullWidth margin='normal'>
          {renderTextField(
            t('offer_name'),
            t('e_g_early_check_in_late_check_out_pet_fees'),
            name ?? '',
            handleNameChange,
            maxLengthShort,
            true,
            1,
            false,
            1,
            1,
            null,
            null,
            'guestName',
          )}
          {renderTextField(
            t('description'),
            t('e_g_comfortable_extra_bed_for_an_additional_guest_including_setup_and_bedding'),
            description ?? '',
            handleDescriptionChange,
            maxLengthLong,
            true,
            0,
            false,
            10,
            20,
          )}
          <Autocomplete
            options={categoryState.options}
            getOptionLabel={option => option.title}
            onChange={handleCategoryChange}
            value={categoryState.selected}
            renderInput={params => (
              <TextField
                {...params}
                label={t('category')}
                placeholder={t('select_category')}
                error={!categoryState.selected}
                helperText={!categoryState.selected ? t('field_is_required') : ''}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderColor: !categoryState.selected ? 'red' : 'default',
                  },
                }}
              />
            )}
          />
        </FormControl>
      </Box>
    </AddCard>
  );
};

export default InformationSection;
