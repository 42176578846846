import axios, { AxiosResponse } from 'axios';
import { Property } from 'property/propertyType';

const appApiVersion = 'v2';
const api = process.env.REACT_APP_API_URL;

export const getPropertiesByGroupId = (
  groupId: number,
  pageNum?: number | undefined,
  numPerPage?: number | undefined,
) => {
  const params = {
    pageNum,
    numPerPage,
  };
  return axios.get(`${api}/v3/groups/${groupId}/properties`, {
    params,
  });
};

export const getProperties = (urlSearchParams: URLSearchParams): Promise<AxiosResponse> => {
  return axios.get(`${api}/v2/companies/properties`, { params: urlSearchParams });
};

export const getFavouriteProperties = () =>
  axios.get(`${api}/v2/companies/properties?favourite=yes`, {});

export const getProperty = (propertyId: number) => axios.get(`${api}/v2/properties/${propertyId}`);

export const getPropertyReservations = (propertyId: number) =>
  axios.get(`${api}/v2/properties/${propertyId}/reservations`);

export const getPropertyReservationsCurrent = (propertyId: number) =>
  axios.get(`${api}/v2/properties/${propertyId}/reservations-current`);

export const getPropertyAccessCodes = (propertyId: number, type = 'all') =>
  axios.get(`${api}/v2/properties/${propertyId}/access-code`, { data: { type } });
export const getPropertyAccessCode = (propertyId: number, id: number) =>
  axios.get(`${api}/v2/properties/${propertyId}/access-codes/${id}`);

// GET property Devices will get the devices
// GET if type is not null, a filter is added to call

// types = sensors, thermostats, intercom, locks
// col =

export const getPropertyDevices = (propertyId: number, type: string, col: string) => {
  const filter = `?type=${type}${col ? `&cols=${col}` : ``}`;
  return axios.get(`${api}/v2/properties/${propertyId}/devices${type ? filter : ``}`);
};

export const getDeviceThresholdsByPropertyId = (propertyId: string) =>
  axios.get(`${api}/v2/properties/${propertyId}/alerts`);

export const createProperty = (data: Property) => axios.post(`${api}/v2/properties`, data);

export const patchProperty = (propertyId: number, data: unknown) =>
  axios.patch(`${api}/v2/properties/${propertyId}`, data);

export const getPropertyMembers = (propertyId: number) => {
  return axios.get(`${api}/v2/properties/${propertyId}/members`);
};

export const getFavoriteProperties = () => axios.get(`${api}/v2/dashboard/property-favorites`);

export const getUnitsFilterOptions = () =>
  axios.get(`${api}/v2/companies/properties/filter-options`);

export const patchFavouriteProperty = (propertyId: number, data: unknown) =>
  axios.patch(`${api}/v2/properties/${propertyId}/favourite`, data);

export const linkProperties = (
  propertyId: number,
  commonPropertyId: number,
  guestDaysAhead = 21,
  guestEnabled = true,
  checkoutEnabled = true,
  memberEnabled = true,
  backupEnabled = true,
) =>
  axios.put(
    `${api}/${appApiVersion}/properties/${propertyId}/common-properties/${commonPropertyId}`,
    {
      guestDaysAhead,
      guestEnabled,
      checkoutEnabled,
      memberEnabled,
      backupEnabled,
    },
  );

export const unlinkProperties = (propertyId: number, commonPropertyId: number) =>
  axios.delete(
    `${api}/${appApiVersion}/properties/${propertyId}/common-properties/${commonPropertyId}`,
  );

export const patchBackupCode = (propertyId: number, accessCodeId: number) =>
  axios.patch(`${api}/v2/properties/${propertyId}/backupcode/${accessCodeId}`);
