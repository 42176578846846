import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GP_URLS } from '..';
import BrandingTabPage from '../Branding/index';
import AddressPage from './Address';
import GlobalTextPage from './GlobalText';
import HostDetailsPage from './HostDetails';
import NoGuestPortalPage from './NoGuestPortal';

enum GlobalTabItem {
  Branding = 0,
  Address = 1,
  HostDetails = 2,
  GlobalText = 3,
}

type GlobalTabContainerProps = {
  onTabClick: (tab: GlobalTabItem) => void;
  children?: React.ReactNode;
};
const GlobalTabContainer: React.FC<GlobalTabContainerProps> = props => {
  const { children, onTabClick } = props;
  const location = useLocation();

  React.useEffect(() => {
    switch (location.pathname) {
      case GP_URLS.BRANDING:
        onTabClick(GlobalTabItem.Branding);
        break;
      case GP_URLS.ADDRESS:
        onTabClick(GlobalTabItem.Address);
        break;
      case GP_URLS.HOSTDETAILS:
        onTabClick(GlobalTabItem.HostDetails);
        break;
      case GP_URLS.GLOBALTEXT:
        onTabClick(GlobalTabItem.GlobalText);
        break;
    }
  }, [location, onTabClick]);
  return (
    <>
      {/* <TabWrapper>
        <Button color='primary'>Preview</Button>
      </TabWrapper> */}
      {children}
    </>
  );
};

type GlobalProps = {
  active: boolean;
};

export const Global: React.FC<GlobalProps> = props => {
  const [activeTab, setActiveTab] = useState<GlobalTabItem>(GlobalTabItem.Branding);
  const { active } = props;
  if (!active) {
    return (
      <GlobalTabContainer onTabClick={tab => setActiveTab(tab)}>
        <NoGuestPortalPage />
      </GlobalTabContainer>
    );
  }

  return (
    <GlobalTabContainer onTabClick={tab => setActiveTab(tab)}>
      <BrandingTabPage visible={activeTab === GlobalTabItem.Branding} />
      <AddressPage visible={activeTab === GlobalTabItem.Address} />
      <HostDetailsPage visible={activeTab === GlobalTabItem.HostDetails} />
      <GlobalTextPage visible={activeTab === GlobalTabItem.GlobalText} />
    </GlobalTabContainer>
  );
};

export default Global;
