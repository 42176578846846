import * as React from 'react';

import Alert from '@mui/lab/Alert';
import { AlertColor } from '@mui/lab/Alert';
import { Collapse } from '@mui/material';

export interface SlidebarAlertProps {
  severity: AlertColor;
  message: string;
}

const SlidebarAlert = ({ severity, message = '' }: SlidebarAlertProps) => (
  <Collapse in={message !== ''}>
    <Alert severity={severity}>{message}</Alert>
  </Collapse>
);

export default SlidebarAlert;
