// @ts-nocheck - FIXME
import { Box, Grid, Typography } from '@mui/material';
import EmptyStateContainer, { EmptyStateContainerProps } from 'Common/Empty/EmptyStateContainer';
import { utcToZonedTime } from 'date-fns-tz';
import isWithinInterval from 'date-fns/isWithinInterval';
import subDays from 'date-fns/subDays';
import subWeeks from 'date-fns/subWeeks';
import { IDevice, IDeviceData } from 'device/deviceType';
import historicalDeviceSelector from 'device/state/historicalDeviceSelector';
import { dateStringToUTCFormat } from 'helper/date';
import useTranslation from 'hooks/useTranslation';
import { IMember } from 'member/memberType';
import { getCurrentMember } from 'member/state/memberActions';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getTimeZone } from 'redux/selectors/timeZone';
import { IReservation } from 'reservation/reservationType';
import {
  getDailyReservations,
  getWeeklyReservations,
} from 'reservation/state/reservationSelectors';
import { IThresholdNotificationsHR } from 'threshold/thresholdType';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import GraphWidget from 'ui-library/Components/widget/GraphWidget';
import { currentUserSelector } from 'user/state/userSelectors';

export const NOISE = 0;
export const OCCUPANCY = 1;
export const TEMPERATURE = 2;
export const HUMIDITY = 3;

export interface DevicesContainerProps {
  name: string;
  referenceLineHigh: number;
  tabIdx: number;
  deviceId: number;
  propertyId: number;
  referenceLineLow: number;
  thresholds: IThresholdNotificationsHR;
  devices: IDeviceData[];
}

const DevicesContainer = ({
  name,
  referenceLineHigh,
  tabIdx,
  propertyId,
  referenceLineLow,
  deviceId,
  devices,
}: DevicesContainerProps) => {
  const dispatch = useAppDispatch();
  const [timePeriod, setTimePeriod] = useState('Today');
  const timezone = useAppSelector(getTimeZone());
  const dailyData = useAppSelector(historicalDeviceSelector(24, deviceId, timezone));
  const weeklyData = useAppSelector(historicalDeviceSelector(168, deviceId, timezone));
  const currentUser = useAppSelector(currentUserSelector());
  const weeklyReservations = useAppSelector(getWeeklyReservations(propertyId));
  const dailyReservations = useAppSelector(getDailyReservations(propertyId));
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const thermostatDevices = devices.filter(device => device.data.temperature);
  const humidityDevices = devices.filter(device => device.data.humidity);
  const noiseDevices = devices.filter(device => device.vendor === 'Netatmo');
  const noiseAwareDevices = devices.filter(device => device.vendor === 'NoiseAware');
  const occupancyDevices = devices.filter(device => device.data.co2);
  const device = devices.filter(device => device?.data?.device_id === deviceId);
  const isNoiseAwareOnline = device[0]?.data?.reachable;

  const referenceAreaObjectUpdate = (reservation: IReservation, period: string) => {
    const now = utcToZonedTime(new Date(), 'UTC');

    let start: Date;
    if (period === 'weekly') {
      start = subWeeks(now, 1);
    } else {
      start = subDays(now, 1);
    }

    const checkIn = new Date(utcToZonedTime(dateStringToUTCFormat(reservation.check_in), timezone));
    const checkOut = new Date(
      utcToZonedTime(dateStringToUTCFormat(reservation.check_out), timezone),
    );
    const interval = { start, end: now };

    const startValue = start.valueOf() / 1000;
    const endValue = now.valueOf() / 1000;
    const checkInValue = checkIn.valueOf() / 1000;
    const checkOutValue = checkOut.valueOf() / 1000;

    // add the reservation to the object if it was in range of start and end of period
    // the period can be a week or 24 hours
    if (isWithinInterval(checkIn, interval)) {
      if (isWithinInterval(checkOut, interval)) {
        ReferenceAreaObject.push({
          name: reservation.guest_name,
          x1: checkInValue,
          x2: checkOutValue,
        });
      } else {
        ReferenceAreaObject.push({
          name: reservation.guest_name,
          x1: checkInValue,
          x2: endValue,
        });
      }
    } else {
      if (isWithinInterval(checkOut, interval)) {
        ReferenceAreaObject.push({
          name: reservation.guest_name,
          x1: startValue,
          x2: checkOutValue,
        });
      } else {
        ReferenceAreaObject.push({
          name: reservation.guest_name,
          x1: startValue,
          x2: endValue,
        });
      }
    }
  };

  const ReferenceAreaObject: unknown[] = [];
  if (timePeriod === 'Today') {
    dailyReservations?.forEach((reservation: IReservation) => {
      referenceAreaObjectUpdate(reservation, 'daily');
    });
  } else {
    weeklyReservations?.forEach((reservation: IReservation) => {
      referenceAreaObjectUpdate(reservation, 'weekly');
    });
  }

  const getData = (filter: string) => {
    if (filter !== timePeriod) {
      setTimePeriod(filter);
    }
    if (filter === 'Today') {
      return dailyData;
    } else {
      return weeklyData;
    }
  };

  const getSuffix = (currentUser: IMember, noiseDevice: IDevice) => {
    switch (tabIdx) {
      case TEMPERATURE:
        return currentUser?.is_celsius ? '°C' : '°F';
      case NOISE:
        return noiseDevice?.vendor === 'Netatmo' ? 'dB' : 'NRS';
      case OCCUPANCY:
        return 'ppm';
      case HUMIDITY:
        return '%';
      default:
        return '';
    }
  };

  const emptyStateValues: EmptyStateContainerProps = {
    showEmpty: true,
    buttonText: 'Contact Us',
    onClick: () => {
      window.open('mailto:support@operto.com');
    },
  };

  if (pathname.includes('noise') && noiseDevices.length === 0 && noiseAwareDevices.length === 0) {
    emptyStateValues.message =
      'To learn more about Noise Monitoring Solutions, please contact Client Success';
    emptyStateValues.iconUrl = '/img/empty-state/Noise@2x.svg';
  } else if (pathname.includes('temperature') && thermostatDevices.length === 0) {
    emptyStateValues.message =
      'To learn more about monitoring solutions, please contact Client Success';
    emptyStateValues.iconUrl = '/img/empty-state/Thermostat@2x.svg';
  } else if (pathname.includes('occupancy') && occupancyDevices.length === 0) {
    emptyStateValues.message =
      'To learn more about monitoring solutions, please contact Client Success';
    emptyStateValues.iconUrl = '/img/empty-state/Thermostat@2x.svg';
  } else if (pathname.includes('humidity') && humidityDevices.length === 0) {
    emptyStateValues.message =
      'To learn more about monitoring solutions, please contact Client Success';
    emptyStateValues.iconUrl = '/img/empty-state/Thermostat@2x.svg';
  } else {
    emptyStateValues.showEmpty = false;
  }

  useEffect(() => {
    dispatch(getCurrentMember());
  }, [dispatch]);

  if (emptyStateValues.showEmpty) {
    return <EmptyStateContainer {...emptyStateValues} />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={9}>
        {!isNoiseAwareOnline && noiseAwareDevices.length > 0 ? (
          <Box
            sx={{
              width: '100%',
              height: '400px',
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant='h6' color='warning'>
              {t('device_offline')}
            </Typography>
          </Box>
        ) : (
          <LoadingContainer loading={weeklyData.length === 0 && dailyData.length === 0}>
            <Box sx={{ width: '100%', height: '400px', backgroundColor: 'white' }}>
              {(weeklyData.length > 0 || dailyData.length > 0) && (
                <GraphWidget
                  graphData={getData}
                  name={name}
                  suffix={getSuffix(currentUser, noiseDevices)}
                  yOffset={10}
                  ReferenceAreaObject={ReferenceAreaObject}
                  referenceLineHigh={referenceLineHigh}
                  referenceLineLow={referenceLineLow}
                />
              )}
            </Box>
          </LoadingContainer>
        )}
      </Grid>

      <Grid item xs={12} md={3}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default DevicesContainer;
