import * as api from 'api/messagingAPI';
import { logger } from 'lib/logger';

export const createGuestChannel = (reservation_id: number) => async () => {
  try {
    const data = await api.createChannel(reservation_id);
    return data.data.data;
  } catch (err) {
    logger.error(err);
  }
};

export const createMemberChannel = (memberIds: number[]) => async () => {
  try {
    const data = await api.createChannelMembers(memberIds);
    return data.data.data;
  } catch (err) {
    logger.error(err);
  }
};

export const notifyGuestAction = (reservationId: string, hostMessage: string) => async () => {
  try {
    const data = await api.notifyGuest(reservationId, hostMessage);
    return data.data.data;
  } catch (err) {
    logger.error(err);
  }
};

export const updateFavouriteAction = (channelId: string, favourite: boolean) => async () => {
  try {
    const data = await api.updateFavourite(channelId, favourite);
    return data.data.data;
  } catch (err) {
    logger.error(err);
  }
};

export const updatePropertyAction =
  (channelId: string, reservationId: string, channelPropertyId: string) => async () => {
    try {
      const data = await api.updateProperty(channelId, reservationId, channelPropertyId);
      return data.data.data;
    } catch (err) {
      logger.error(err);
    }
  };
