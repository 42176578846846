import { TinyColor } from '@ctrl/tinycolor';
import { paletteStyles } from '@operto/ui';
import { MuiColorInput, MuiColorInputColors, matchIsValidColor } from 'mui-color-input';
import React, { useEffect, useRef, useState } from 'react';

const HEX_VALUE_REGEX = /^#([0-9a-f]{3}){1,2}$/i;

export const getValidColor = (color = '') => {
  const copy = `#${String(color).replaceAll('#', '')}`;
  if (HEX_VALUE_REGEX.test(copy)) {
    return copy;
  }

  return new TinyColor(paletteStyles.palette.primary.main).toHexString();
};

type ColorPickerProps = {
  value: string;
  onChange: (hexValue: string) => void;
};

export const ColorPicker = ({ value, onChange }: ColorPickerProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [hexValue, setHexValue] = useState(getValidColor(value));
  const [hasError, setHasError] = useState(false);

  /**
   * NOTE: newValue defaults to #000000 if the input is invalid which turn matchIsValidColor
   * to return TRUE even though inputRef actual value is not valid. we ignore the newValue and
   * use the inputRef actual value instead.
   */
  const handleChange = (_newValue: string, colors: MuiColorInputColors) => {
    const isValid = matchIsValidColor(inputRef.current?.value);
    setHexValue(inputRef.current?.value);
    setHasError(!isValid);
    if (isValid) {
      onChange(colors.hex.toUpperCase());
    }
  };

  useEffect(() => {
    setHexValue(getValidColor(value));
  }, [value]);

  return (
    <MuiColorInput
      data-testid='color-picker'
      inputRef={inputRef}
      variant='standard'
      format='hex'
      value={hexValue}
      onChange={handleChange}
      error={hasError}
      helperText={hasError && 'Please choose a valid color'}
    />
  );
};
