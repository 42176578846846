// @ts-nocheck

import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import React from 'react';
import { AddCard } from '@operto/ui-library';

export interface IFeaturesContainerProps {
  data?: Property;
  onChange?: (id: string, value: string) => void;
}

const FeaturesContainer = (props: IFeaturesContainerProps) => {
  const { data, onChange } = props;

  const valueChanger = (e: React.ChangeEvent<HTMLInputElement>) => {
    const field = e.target.id || e.target.name;
    onChange(field, e.target.checked);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <AddCard title='Property Features'>
          <Grid container direction='column'>
            <Typography align='left'>Set property features</Typography>
            <Grid container direction='row'>
              <Grid container xs={6} direction='column'>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={valueChanger}
                      checked={data?.property_enabled}
                      name='property_enabled'
                      size='small'
                    />
                  }
                  label='Property Enabled'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={valueChanger}
                      checked={data?.backup_code_enabled}
                      name='backup_code_enabled'
                      size='small'
                    />
                  }
                  label='Backup Code Enabled'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={valueChanger}
                      checked={data?.blackout_notify_enabled}
                      name='blackout_notify_enabled'
                      size='small'
                    />
                  }
                  label='Blackout Notify Enabled'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={valueChanger}
                      checked={data?.lock_enabled}
                      name='lock_enabled'
                      size='small'
                    />
                  }
                  label='Lock Enabled'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={valueChanger}
                      checked={data?.environment_enabled}
                      name='environment_enabled'
                      size='small'
                    />
                  }
                  label='Environment Enabled'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={valueChanger}
                      checked={data?.checkout_code_enabled}
                      name='checkout_code_enabled'
                      size='small'
                    />
                  }
                  label='Checkout Code Enabled'
                />
              </Grid>
              <Grid container xs={6} direction='column'>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={valueChanger}
                      checked={data?.battery_alert_enabled}
                      name='battery_alert_enabled'
                      size='small'
                    />
                  }
                  label='Battery Alert Enabled'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={valueChanger}
                      checked={data?.thermostat_enabled}
                      name='thermostat_enabled'
                      size='small'
                    />
                  }
                  label='Thermostat Enabled'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={valueChanger}
                      checked={data?.intercom_enabled}
                      name='intercom_enabled'
                      size='small'
                    />
                  }
                  label='Intercom Enabled'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={valueChanger}
                      checked={data?.member_code_enabled}
                      name='member_code_enabled'
                      size='small'
                    />
                  }
                  label='Member Code Enabled'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={valueChanger}
                      checked={data?.lock_alert_enabled}
                      name='lock_alert_enabled'
                      size='small'
                    />
                  }
                  label='Lock Alert Enabled'
                />
              </Grid>
            </Grid>
          </Grid>
        </AddCard>
      </Grid>
    </Grid>
  );
};

export default FeaturesContainer;
