import { AddCard } from '@operto/ui-library';
import { FormContainer } from 'Common/FormContainer';
import { useMinCheckInOutDateString } from 'Common/Widgets/Reservation/ReservationForm';
import { ICode } from 'code/codeType';
import { updateCodeAccessTime } from 'code/state/codeAction';
import { getCheckinCodeByAccessCodeId } from 'code/state/guestCodesSelectors';
import { isAfter, isBefore } from 'date-fns';
import { timeZoneToUTC, utcStringToDate, utcToTimeZone } from 'helper/date';
import { logger } from 'lib/logger';
import { Property } from 'property/propertyType';
import { getProperty } from 'property/state/propertySelectors';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { FormTextField } from 'ui-library/Components/input/FormTextField';

export interface EditAccessCodesProps {
  accessCodeId: number;
  propertyId: number;
  handleClose: () => void;
}

const EditAccessCodes = ({ propertyId, accessCodeId, handleClose }: EditAccessCodesProps) => {
  const dispatch = useAppDispatch();
  const codeObj = useAppSelector(getCheckinCodeByAccessCodeId(accessCodeId));
  const property: Property = useAppSelector(getProperty(propertyId));
  const timezone = property?.timezone || 'America/Vancouver';
  const {
    handleSubmit,
    reset,
    watch,
    control,
    formState: { isDirty, isValid },
  } = useForm<ICode>({ mode: 'all' });
  const [saving, setSaving] = useState(false);
  const [minCheckIn, minCheckOut] = useMinCheckInOutDateString(timezone, codeObj.valid_from);
  const hasStarted = isBefore(utcStringToDate(codeObj.valid_from), new Date());

  const validateCheckInDate = (checkIn: string) => {
    const checkInDate = new Date(checkIn);
    const compareDate = new Date(minCheckIn);
    if (isBefore(checkInDate, compareDate)) {
      return 'Access valid from date cannot be in the past';
    }

    return true;
  };

  const validateCheckOutDate = (checkOut: string) => {
    const checkInDate = new Date(watch('valid_from'));
    const checkOutDate = new Date(checkOut);
    if (!isAfter(checkOutDate, checkInDate)) {
      return 'Access valid until time cannot be before the access valid from time';
    }

    return true;
  };

  const onSubmit = async (data: ICode) => {
    let success = true;
    setSaving(true);
    const updated = {
      ...codeObj,
      valid_from: timeZoneToUTC(data.valid_from, timezone, 'yyyy-MM-dd HH:mm:ss'),
      valid_until: timeZoneToUTC(data.valid_until, timezone, 'yyyy-MM-dd HH:mm:ss'),
    };

    try {
      await dispatch(updateCodeAccessTime(updated));
      handleClose();
    } catch (error) {
      success = false;
      logger.error(error);
    }

    setSaving(false);
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `Access code was${success ? ' ' : ' not '}updated successfully.`,
        variant: success ? SnackbarVariant.SUCCESS : SnackbarVariant.ERROR,
      }),
    );
  };

  useEffect(() => {
    if (codeObj) {
      const localCheckInTime = codeObj?.valid_from
        ? utcToTimeZone(codeObj?.valid_from, timezone)
        : minCheckIn;
      const localCheckOutTime = codeObj?.valid_until
        ? utcToTimeZone(codeObj?.valid_until, timezone)
        : minCheckOut;

      reset({
        ...codeObj,
        valid_from: localCheckInTime,
        valid_until: localCheckOutTime,
      });
    }
  }, [minCheckOut, minCheckIn, reset, codeObj, timezone]);

  return (
    <FormContainer
      title={'Guest Details'}
      dirty={isValid && isDirty}
      loading={saving}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <AddCard title='Reservation Details'>
        <FormTextField field='name' label='Guest Full Name' disabled control={control} />
      </AddCard>

      <AddCard title={'Access Code'}>
        <FormTextField
          rules={{
            required: {
              value: true,
              message: 'Access valid from is required',
            },
            validate: validateCheckInDate,
          }}
          inputProps={{ min: minCheckIn }}
          field='valid_from'
          type='datetime-local'
          label='Access Valid From'
          helperText={hasStarted ? 'Access code valid from has started' : undefined}
          disabled={hasStarted}
          control={control}
        />
        <FormTextField
          rules={{
            required: {
              value: true,
              message: 'Access valid until is required',
            },
            validate: validateCheckOutDate,
          }}
          inputProps={{ min: minCheckOut }}
          field='valid_until'
          type='datetime-local'
          label='Access Valid Until'
          control={control}
        />
      </AddCard>
    </FormContainer>
  );
};

export default EditAccessCodes;
