import { Stack } from '@mui/material';
import emailCardImage from 'assets/img/communications/communications-blank-page-email-card-image.png';
import smsCardImage from 'assets/img/communications/communications-blank-page-sms-card-image.png';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import CommunicationsBlankPagePanelCard from './CommunicationsBlankPagePanelCard';
import { EmailChannelIcon, SmsChannelIcon } from './CommunicationsIcons';
import { MENU_OPTIONS, MenuOptionType } from './CommunicationsNewMenu';

export default function CommunicationsBlankPagePanelCards({
  handleNewMenuClose,
}: {
  handleNewMenuClose: (selectedOption?: MenuOptionType) => void;
}) {
  const { t } = useTranslation();

  return (
    <Stack flexDirection='row' gap={'16px'} justifyContent='center'>
      <CommunicationsBlankPagePanelCard
        title={t('email_access_code')}
        subtitle={t('english_default')}
        description={t('email_card_description')}
        image={emailCardImage}
        titleIcon={<EmailChannelIcon />}
        onClick={() => handleNewMenuClose(MENU_OPTIONS.EMAIL_BUILDER)}
      />

      <CommunicationsBlankPagePanelCard
        title={t('sms_access_code')}
        subtitle={t('english_default')}
        description={t('sms_card_description')}
        image={smsCardImage}
        titleIcon={<SmsChannelIcon />}
        onClick={() => handleNewMenuClose(MENU_OPTIONS.SMS_BUILDER)}
      />
    </Stack>
  );
}
