import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { Actions } from '../../types/actions';
import { ActiveAlertDict, ActiveAlertState } from '../../types/activeAlerts';

const initialActiveAlertState: ActiveAlertDict = {};

const byId: Reducer<ActiveAlertDict> = (state = initialActiveAlertState, action) => {
  switch (action.type) {
    case Actions.storeCompanyActiveAlerts:
      return action.activeAlertsDict;
    default:
      return state;
  }
};
const propertyList: Reducer<number[]> = (state = [], action) => {
  switch (action.type) {
    case Actions.storeCompanyActiveAlerts:
      return action.propertyIds;
    default:
      return state;
  }
};

const reducer: Reducer<ActiveAlertState> = combineReducers<ActiveAlertState>({
  list: propertyList,
  byId,
});

export default reducer;
