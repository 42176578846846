import { ArrowRight, Check, InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  Stack,
  SxProps,
  Tooltip,
} from '@mui/material';
import { sortLanguages } from 'helper/helper';
import useTranslation from 'hooks/useTranslation';
import React, { useState } from 'react';

export default function LanguageSelector({
  label,
  value,
  onChange,
  languageList,
  disableNewLanguage,
  newLanguageList,
  onSelectNewLanguage,
}: LanguageSelectorProps) {
  const { t, languages } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openNewLanguage, setOpenNewLanguage] = useState(false);
  const [openNewLanguageTooltip, setOpenNewLanguageTooltip] = useState(false);

  const sortedLanguages = sortLanguages(
    languages
      .filter(lang => languageList.some(list => list === lang.locale))
      .map(lang => lang.locale),
  );

  const sortedNewLanguageList = sortLanguages(newLanguageList ?? []);

  const getLanguageName = (language: string) => {
    return (
      (languages.find(l => l.locale === language)?.name ?? language) +
      (language === 'en' ? ' (default)' : '')
    );
  };

  const handleChange = (newLanguage: string | '') => {
    if (newLanguage === '') {
      return;
    }

    onChange(newLanguage);
  };

  const handleNewLanguageMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
    if (disableNewLanguage) {
      setOpenNewLanguageTooltip(true);
      return;
    }

    setAnchorEl(e.currentTarget);
  };

  const handleNewLanguageTooltipClose = () => {
    setOpenNewLanguageTooltip(false);
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id='language-select-label'>{label}</InputLabel>

        <Select
          open={openNewLanguage}
          data-testid='language-select'
          labelId='language-select-label'
          value={value}
          label={label}
          onClick={() => setOpenNewLanguage(prev => !prev)}
          onChange={e => handleChange(e.target.value as string)}
          sx={selectStyles}
        >
          {sortedLanguages.map(locale => (
            <MenuItem key={locale} value={locale} sx={menuItemStyles}>
              {getLanguageName(locale)}

              {value === locale && (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              )}
            </MenuItem>
          ))}

          <Divider />

          {newLanguageList && newLanguageList.length ? (
            <Tooltip title={t('new_language_tooptip')} open={openNewLanguageTooltip}>
              <Box
                onMouseEnter={handleNewLanguageMouseEnter}
                onMouseLeave={handleNewLanguageTooltipClose}
              >
                <MenuItem value={''} disabled={disableNewLanguage} sx={menuItemStyles}>
                  {t('add_language')}

                  <Stack sx={iconContainer}>
                    {disableNewLanguage && (
                      <ListItemIcon sx={infoIconStyles}>
                        <InfoOutlined />
                      </ListItemIcon>
                    )}

                    <ListItemIcon sx={arrowRightStyles}>
                      <ArrowRight />
                    </ListItemIcon>
                  </Stack>
                </MenuItem>
              </Box>
            </Tooltip>
          ) : (
            <MenuItem value={''} disabled>
              {t('all_languages_added')}
            </MenuItem>
          )}
        </Select>
      </FormControl>

      <Menu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {sortedNewLanguageList.map(language => (
          <MenuItem
            key={language}
            onClick={() => {
              setOpenNewLanguage(false);
              setAnchorEl(null);
              onSelectNewLanguage?.(language);
            }}
          >
            {getLanguageName(language)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

const selectStyles: SxProps = { '& svg[data-testid="CheckIcon"]': { display: 'none' } };
const menuItemStyles: SxProps = { justifyContent: 'space-between' };
const arrowRightStyles: SxProps = { '&.MuiListItemIcon-root': { minWidth: '24px' } };

const iconContainer: SxProps = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '8px',
};

const infoIconStyles: SxProps = {
  '&.MuiListItemIcon-root': { minWidth: '24px' },
  '& > svg': { color: 'black' },
};

type LanguageSelectorProps = {
  label: string;
  value: string;
  onChange: (selectedLanguage: string) => void;
  languageList: string[];
  disableNewLanguage?: boolean;
  newLanguageList?: string[];
  onSelectNewLanguage?: (newLanguage: string) => void;
};
