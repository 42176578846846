// @ts-nocheck

import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { AddCard } from '@operto/ui-library';
import { IMember } from 'member/memberType';
import React from 'react';
import styled from 'styled-components';
import ValidationIcon from 'ui-library/Components/icon/ValidationIcon';

interface ReviewMemberContainerProps {
  data: IMember;
}

const ReviewMemberContainer = ({ data }: ReviewMemberContainerProps) => {
  const handleStaffType = (staff: string) => {
    if (staff === 'contractor') {
      return 'Contractor';
    }
    return 'Full Time Staff';
  };
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AddCard title='Review User Settings'>
            <Grid container direction='column'>
              <SettingsLabel>You can update this user&apos;s settings at any time.</SettingsLabel>
              <Grid item xs={3}>
                <SettingLabelContainer>
                  <ValidationIcon isValid={data?.name} />
                  <Typography>Name:</Typography>
                </SettingLabelContainer>
              </Grid>
              <Grid item xs={9}>
                <Typography align='left'>{data.name}</Typography>
              </Grid>
              <Grid item xs={3}>
                <SettingLabelContainer>
                  <ValidationIcon isValid={data?.job_title} />
                  <Typography>Job Title:</Typography>
                </SettingLabelContainer>
              </Grid>
              <Grid item xs={9}>
                <Typography align='left'>{data.job_title}</Typography>
              </Grid>
              <Grid item xs={3}>
                <SettingLabelContainer>
                  <ValidationIcon isValid={data?.staff_type} />
                  <Typography>Employee Type:</Typography>
                </SettingLabelContainer>
              </Grid>
              <Grid item xs={9}>
                <SettingsLabel align='left'>{handleStaffType(data.staff_type)}</SettingsLabel>
              </Grid>
              <Grid item xs={3}>
                <SettingLabelContainer>
                  <ValidationIcon isValid={data?.email} />
                  <Typography>Email:</Typography>
                </SettingLabelContainer>
              </Grid>
              <Grid item xs={9}>
                <Typography align='left'>{data.email}</Typography>
              </Grid>
              <Grid item xs={3}>
                <SettingLabelContainer>
                  <ValidationIcon isValid={data?.contact_number} />
                  <Typography>Phone Number:</Typography>
                </SettingLabelContainer>
              </Grid>
              <Grid item xs={9}>
                <Typography align='left'>{data.contact_number}</Typography>
              </Grid>
            </Grid>
          </AddCard>
        </Grid>
      </Grid>
    </>
  );
};

const SettingLabelContainer = styled.div`
  display: flex;
  align-items: center;
  & :first-child {
    margin-right: 10px;
  }
`;
const SettingsLabel = styled(Typography)`
  && {
    margin-bottom: 12px;
    margin-top: 12px;
  }
`;

export default ReviewMemberContainer;
