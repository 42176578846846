import CloseIcon from '@mui/icons-material/Close';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Box, Typography } from '@mui/material';
import React from 'react';
import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { IPage } from 'redux/guestportal/models';
import styled from 'styled-components';
import { EditElements } from './GuidesDetailsPagesFormTabEdit';

const Image = (props: { src: string; onClick: (source: string) => void }) => {
  const { src } = props;

  return (
    <>
      <img src={src} style={{ height: '100%', width: '100%', objectFit: 'scale-down' }} />
      <CloseIcon
        onClick={() => props.onClick?.(props.src)}
        style={{
          position: 'absolute',
          backgroundColor: '#00000022',
          color: 'white',
          top: 10,
          left: 10,
        }}
      />
    </>
  );
};

type GuidesDetailsPagesFormTabEditMediaCarouselProps = {
  page: IPage;
  onPageChange?: (page: IPage) => void;
};

const GuidesDetailsPagesFormTabEditMediaCarousel = ({
  page,
  onPageChange,
}: GuidesDetailsPagesFormTabEditMediaCarouselProps) => {
  // NOTE: carousel component is weird that it wants multiple children defined right away.
  // this hack is taken from the souce itself
  // http://react-responsive-carousel.js.org/storybook/?path=/story/01-basic--base
  const urls = page.images_url?.map(url => ({ type: 'image', url })) || [];
  if (page.video_url?.length > 0) {
    urls.unshift({ type: 'video', url: page.video_url });
  }

  const medias = (
    <div>
      {urls.map(m => {
        if (m.type === 'video') {
          return <ReactPlayer url={m.url} key={m.url} controls width='100%' height={250} />;
        } else if (m.type === 'image') {
          return (
            <Image
              key={m.url}
              src={m.url}
              onClick={async image =>
                onPageChange?.({
                  ...page,
                  images_url: page.images_url?.filter(u => image !== u),
                })
              }
            />
          );
        }
      })}
    </div>
  );

  return (
    <CarouselContainer showThumbs={false} showArrows dynamicHeight={false}>
      {medias.props.children}
    </CarouselContainer>
  );
};

const GuidesDetailsPagesFormTabEditMediaPlaceHolder = () => (
  <Box
    style={{
      backgroundColor: '#FAFAFA',
      borderRadius: 5,
      height: 250,
    }}
  >
    <YouTubeIcon style={{ width: '100%', height: 75, marginTop: 60 }} color='primary' />
    <Typography variant='h5' style={{ textAlign: 'center' }}>
      Click to add Video and Images
    </Typography>
  </Box>
);

type GuidesDetailsPagesFormTabEditMediaProps = {
  page: IPage;
  onElementClick: (element: EditElements) => void;
};
export const GuidesDetailsPagesFormTabEditMedia = (
  props: GuidesDetailsPagesFormTabEditMediaProps,
) => {
  const { page, onElementClick } = props;

  return (
    <Box
      style={{
        width: '100%',
        height: 250,
        marginTop: 20,
      }}
      onClick={() => onElementClick(EditElements.Media)}
      data-testid='guides-details-pages-form-tab-edit-media'
    >
      {page?.video_url || page?.images_url?.length > 0 ? (
        <GuidesDetailsPagesFormTabEditMediaCarousel {...props} />
      ) : (
        <GuidesDetailsPagesFormTabEditMediaPlaceHolder />
      )}
    </Box>
  );
};

const CarouselContainer = styled(Carousel)`
  && {
    object-fit: cover;
    .slider-wrapper {
      height: 250px;
      .slider {
        height: 100%;
        img {
          object-fit: cover !important;
        }
      }
    }
  }
`;

export default GuidesDetailsPagesFormTabEditMedia;
