// @ts-nocheck

import * as React from 'react';

import { ListItem, ListItemAvatar, ListItemText, Paper } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import RightSideTopbar from 'Common/Slidebar/components/RightSideTopbar';
import EditPeople from 'Pages/EditPeople/EditPeople';
import { BaseContainer } from 'Style/Components/BaseComponents';
import { IMember } from 'member/memberType';
import { memberByIdSelector, membersByPropertyId } from 'member/state/memberSelectors';
import { isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppSelector } from 'redux/hooks';
import styledBy from 'styled-by';
import styled from 'styled-components';
import OpertoProgressCircle from 'ui-library/Components/misc/OpertoProgressCircle';
import { HeaderContainer } from 'ui-library/Styled/sidebar';
import MemberProfile from './memberProfileGetter';

export interface MemberListContainerProps {
  propertyId: number;
  memberId?: number;
  handleClose: () => void;
}

const MemberListContainer = ({ propertyId, memberId, handleClose }: MemberListContainerProps) => {
  const [pageNum, setPageNum] = React.useState(0);
  const [selectedMemberId, setSelectedMemberId] = React.useState<number>(memberId);
  const [isMobileDetails, setIsMobileDetails] = React.useState(false);

  const handleFetchMore = () => {
    const newPageNum = pageNum + 1;
    setPageNum(newPageNum);
  };

  const handleItemClick = (memberId: number) => {
    // dispatch(toggleSlidebarAction(SlidebarType.CLOSE, ''));
    setSelectedMemberId(memberId);
    setIsMobileDetails(true);
  };

  const handleHide = () => {
    setIsMobileDetails(false);
  };

  const members = useAppSelector(membersByPropertyId(propertyId, 100));
  let selectedMember = useAppSelector(memberByIdSelector(selectedMemberId)) as IMember;
  if (!selectedMember) {
    [selectedMember] = members;
  }

  return (
    <MainContainer>
      <ListWrapper isMobile={isMobile}>
        <HeaderContainer>
          <MemberToolBar title='Member List' handleClose={handleClose} />
        </HeaderContainer>
        <ListContainer>
          <MemberList id='scrollable-activity'>
            <InfiniteScroll
              dataLength={members?.length || 0}
              next={handleFetchMore}
              hasMore={members?.length > 0}
              scrollableTarget='scrollable-activity'
              loader={<OpertoProgressCircle />}
              endMessage={<p>No more left</p>}
            >
              {members &&
                members.map((member: IMember) => (
                  <>
                    <ListItem
                      selected={member?.id === selectedMember?.id}
                      key={member.id}
                      button
                      onClick={() => {
                        handleItemClick(member.id);
                      }}
                    >
                      <ListItemAvatar>
                        <MemberProfile size='medium' memberData={member} fullName={member.name} />
                      </ListItemAvatar>
                      <ListItemText primary={member.name} />
                    </ListItem>
                    <Divider />
                  </>
                ))}
            </InfiniteScroll>
          </MemberList>
        </ListContainer>
      </ListWrapper>
      <EditContainer isOpen={selectedMember && isMobileDetails} isMobile={isMobile} elevation='8'>
        {selectedMember && <EditPeople onHide={handleHide} memberId={selectedMember.id} />}
      </EditContainer>
    </MainContainer>
  );
};

const MemberToolBar = styled(RightSideTopbar)`
  && {
    display: flex;
    padding: 0px;
    justify-content: flex-start;
  }
`;

const EditContainer = styled(Paper)`
  && {
    overflow-y: scroll;
    z-index: 10;
    width: 80%;
    background-color: ${props => props.theme.containerBGColor};
    border-radius: 0;
    transition: all 500ms cubic-bezier(1, 0, 0, 1);
    ${styledBy(
      'isMobile',
      props => `
      right:${props.isOpen ? '0' : '-100%'};
      width:100%;
      position:absolute;
    `,
    )};
  }
`;

const ListWrapper = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 20%;
  ${styledBy(
    'isMobile',
    () => `
      width:100%;
      position:relative;
    `,
  )};
`;
const MainContainer = styled(BaseContainer)`
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: ${props => props.theme.containerAccentBGColor};
`;

const ListContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  margin-top: 60px;
`;

const MemberList = styled(List)`
  && {
    width: 100%;
    height: 100%;
  }
`;

export default MemberListContainer;
