import AutoBuzzIcon from '@mui/icons-material/CachedOutlined';
import KeypadIcon from '@mui/icons-material/DialpadOutlined';
import VoiceIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Text } from '@operto/ui';
import { AddCard } from '@operto/ui-library';
import { FormContainer } from 'Common/FormContainer';
import { saveEditIntercom } from 'device/state/intercomActions';
import { IntercomByPropertyIdSelector } from 'device/state/intercomSelectors';
import { Intercom } from 'intercom/intercomType';
import { logger } from 'lib/logger';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { FormTextField, FormTextFieldRegexPhone } from 'ui-library/Components/input/FormTextField';

const INTERCOM_METHODS = [
  <>
    <KeypadIcon fontSize='small' sx={{ mr: 1, height: 'auto' }} />
    <Text>Keypad</Text>
  </>,
  <>
    <VoiceIcon fontSize='small' sx={{ mr: 1, height: 'auto' }} />
    <Text>Voice</Text>
  </>,
  <>
    <AutoBuzzIcon fontSize='small' sx={{ mr: 1, height: 'auto' }} />
    <Text>Auto Buzz</Text>
  </>,
];

export interface IntercomEditContainerProps {
  propertyId: number;
  handleClose: () => void;
}

const IntercomEditContainer = ({ propertyId, handleClose }: IntercomEditContainerProps) => {
  const dispatch = useAppDispatch();
  const intercom = useAppSelector(IntercomByPropertyIdSelector(propertyId));
  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: intercom,
  });
  const [saving, setSaving] = useState(false);

  const getIntercomMethod = () => {
    const [auto_buzz, voice] = watch(['intercom_config.auto_buzz', 'intercom_config.voice']);
    if (!auto_buzz && voice) {
      return 1;
    }

    if (auto_buzz && !voice) {
      return 2;
    }

    return 0;
  };

  const handleChangeIntercom = (value: number) => {
    setValue('intercom_config.auto_buzz', value === 2, { shouldDirty: true });
    setValue('intercom_config.voice', value === 1, { shouldDirty: true });
  };

  const handleSaveChange = async (updated: Intercom) => {
    let success = true;
    setSaving(true);

    try {
      await dispatch(saveEditIntercom(updated));
      handleClose();
    } catch (error) {
      success = false;
      logger.error(
        `Error saving intercom configId: ${updated?.intercom_config?.id}, error: ${error}`,
      );
    }

    setSaving(false);
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `${success ? 'Successfully saved' : 'Error saving'} intercom settings`,
        variant: success ? SnackbarVariant.SUCCESS : SnackbarVariant.ERROR,
      }),
    );
  };

  return (
    <FormContainer
      title='Edit Intercom Settings'
      loading={saving}
      dirty={isValid && isDirty}
      onClose={handleClose}
      onSubmit={handleSubmit(handleSaveChange)}
    >
      <AddCard title='Intercom'>
        <Typography variant='body2'>Select intercom method</Typography>
        <Typography sx={{ marginBottom: '14px' }} variant='body2'>
          This is how your guests will enter their intercom passcode
        </Typography>
        <FormControl sx={{ mx: 0, width: 200 }}>
          <InputLabel id='intercom-method'>Intercom Method</InputLabel>
          <Select
            label='Intercom Method'
            labelId='intercom-method'
            value={getIntercomMethod()}
            onChange={e => handleChangeIntercom(e.target.value as unknown as number)}
            renderValue={value => (
              // NOTE: this renderValue is a workaround for the Select component not aligning icon and text vertically
              <Stack direction='row' alignItems='center'>
                {INTERCOM_METHODS[value]}
              </Stack>
            )}
          >
            {INTERCOM_METHODS.map((method, index) => (
              <MenuItem key={index} value={index}>
                {method}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Divider sx={{ marginY: 3 }} />

        <Typography sx={{ marginBottom: '14px' }} variant='body2'>
          Choose the number used to open door
        </Typography>
        <FormTextField
          select
          field='intercom_config.intercom_button'
          label='Intercom Number'
          sx={{ width: 200 }}
          control={control}
        >
          {[...Array(10).keys()].map(key => (
            <MenuItem value={`${key}`} key={key}>
              {key}
            </MenuItem>
          ))}
        </FormTextField>

        <Divider sx={{ marginY: 3 }} />

        <Typography sx={{ marginBottom: '14px' }} variant='body2'>
          Please provide the building manage with this new intercom phone number
        </Typography>
        <FormTextField
          sx={{ width: 200 }}
          rules={{
            pattern: {
              value: FormTextFieldRegexPhone,
              message: 'Valid phone number format is required',
            },
          }}
          field='intercom_number.number'
          type='tel'
          label='Phone Number'
          control={control}
        />
      </AddCard>
    </FormContainer>
  );
};

export default IntercomEditContainer;
