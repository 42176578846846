import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query/react';
import { isTesting } from 'lib/constants';
import thunk from 'redux-thunk';
import { NovaRTKApi } from 'store/novaRTKQApi';
import { Actions } from 'types/actions';
import rootReducer from './reducers';
import { NeoGatewayV2RTKQapi, NeoRestRTKQapi, RTKQapi } from './rtkq-api';

const getSerializableCheck = () => {
  if (isTesting) {
    return false; // no check in tests
  }

  return {
    ignoredPaths: [
      RTKQapi.reducerPath,
      NeoRestRTKQapi.reducerPath,
      NovaRTKApi.reducerPath,
      NeoGatewayV2RTKQapi.reducerPath,
    ], // ability to pass URLSearchParams objects to rtkq as an arguement
  };
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: !isTesting, // no check in tests
      serializableCheck: getSerializableCheck(),
    }).concat([
      thunk,
      RTKQapi.middleware,
      NeoRestRTKQapi.middleware,
      NovaRTKApi.middleware,
      NeoGatewayV2RTKQapi.middleware,
    ]),
});

export const dispatchStoreReset = () => {
  store.dispatch({ type: Actions.logout });
  store.dispatch(RTKQapi.util.resetApiState());
  store.dispatch(NeoRestRTKQapi.util.resetApiState());
  store.dispatch(NovaRTKApi.util.resetApiState());
  store.dispatch(NeoGatewayV2RTKQapi.util.resetApiState());
};

export type AppDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
