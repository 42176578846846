import { NotifiableError } from '@bugsnag/js';
import * as api from 'api/intercoms';
import { AxiosResponse } from 'axios';
import { Intercom } from 'intercom/intercomType';

import { OpertoLogger } from 'Logger/logger';
import { ApplicationState } from 'redux/reducers';
import { AppDispatch } from 'redux/store';
import { Actions } from 'types/actions';

export const getIntercom = () => (dispatch: AppDispatch, getState: () => ApplicationState) => {
  const propertyId = getState().ui.propertyDetailId;
  api
    .getIntercom(propertyId)
    .then((data: AxiosResponse) => {
      const intercom = data.data.data;
      if (
        (intercom && intercom.intercom_config && intercom.intercom_config.property_id) ||
        (intercom.intercom_number && intercom.intercom_number.property_id)
      ) {
        dispatch({
          type: Actions.upsertIntercom,
          intercom,
          propertyId:
            (intercom.intercom_config && intercom.intercom_config.property_id) ||
            (intercom.intercom_number && intercom.intercom_number.property_id),
        });
      }
    })
    .catch((err: NotifiableError) => {
      OpertoLogger.Log(err);
    });
};

export const saveEditIntercom = (updatedIntercom: Intercom) => async (dispatch: AppDispatch) => {
  const {
    number,
    external_id,
    provider,
    property_id: numberPropertyId,
  } = updatedIntercom.intercom_number;
  const {
    forwards,
    id: configId,
    intercom_button,
    auto_buzz,
    voice,
    property_id: configPorpertyId,
  } = updatedIntercom.intercom_config;

  /**
   * NOTE: right now, this doesn't support multiple forwards. BE payload expects a single forward.
   * we assume first item is the only one we can update.
   */
  const forwardNum = forwards.find(Boolean);
  const propertyId = numberPropertyId ?? configPorpertyId;
  const payload = {
    id: configId,
    property_id: propertyId,
    intercom_button,
    intercom_number: number,
    intercom_external_id: external_id,
    number_provider: provider,
    intercom_sms_enabled: !auto_buzz && !voice,
    intercom_auto_buzz: auto_buzz && !voice,
    intercom_voice: !auto_buzz && voice,
    number_has_voice: !auto_buzz && voice,
    number_has_mms: !auto_buzz && !voice,
    number_has_sms: !auto_buzz && !voice,
    forward_number: forwardNum?.number || '',
    press_to_accept: forwardNum?.press_to_accept ?? false,
  };

  await api.editIntercom(payload, propertyId);
  dispatch({
    type: Actions.upsertIntercom,
    intercom: updatedIntercom,
    propertyId,
  });
};

export const getPropertyIntercom = (propertyId: number) => (dispatch: AppDispatch) => {
  return api
    .getPropertyIntercom(propertyId)
    .then((data: AxiosResponse) => {
      const intercom = data.data.data;
      if (
        (intercom && intercom.intercom_config && intercom.intercom_config.property_id) ||
        (intercom.intercom_number && intercom.intercom_number.property_id)
      ) {
        dispatch({
          type: Actions.upsertIntercom,
          intercom,
          propertyId:
            (intercom.intercom_config && intercom.intercom_config.property_id) ||
            (intercom.intercom_number && intercom.intercom_number.property_id),
        });
      }
    })
    .catch((err: NotifiableError) => {
      OpertoLogger.Log(err);
    });
};
