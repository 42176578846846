import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  MenuItem,
  Tab as MuiTab,
  Paper,
  Popover,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useRef, useState } from 'react';
import { TabItems, TabLabel } from '../TabBar';

type DropDownMenuProps = {
  item: TabItems;
  handleClick: (value: string | number) => void;
  setTabIndex: (value: number) => void;
  active?: boolean;
  tabItemsStyle?: SxProps<Theme>;
};

const DropDownMenu = ({
  item,
  handleClick,
  setTabIndex,
  active,
  tabItemsStyle,
}: DropDownMenuProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const dropDownContainerStyles: SxProps = {
    paddingBottom: '18px',
  };

  const dropDownPopperStyles: SxProps = {
    zIndex: 1000,
  };

  const pullDownStyles: SxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...tabItemsStyle,
  };

  const customTabStyles: SxProps = {
    textTransform: 'capitalize',
  };

  const customExpandIconStyles: SxProps = {
    fill: !active ? 'darkgray' : 'gray',
  };

  return (
    <Box sx={pullDownStyles}>
      <MuiTab
        sx={customTabStyles}
        data-testid={'muiTab'}
        label={<TabLabel item={item} />}
        ref={anchorRef}
        onClick={() => setOpen(!open)}
        disabled={!active}
      />

      <ExpandMoreIcon sx={customExpandIconStyles} />

      <Popover
        sx={dropDownPopperStyles}
        data-testid={`drop-down-item-${item.label}`}
        elevation={1}
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper sx={dropDownContainerStyles} elevation={0}>
          {item.childItems.map((cItem, index) => {
            return (
              <MenuItem
                data-testid={'menuItem'}
                value={cItem.label}
                key={index}
                classes={{
                  root: classes.inputRoot,
                }}
                onClick={() => {
                  setTabIndex(+item.value);
                  handleClick(cItem.value);
                  setOpen(false);
                }}
              >
                <Typography variant='body2'>{cItem.label}</Typography>
              </MenuItem>
            );
          })}
        </Paper>
      </Popover>
    </Box>
  );
};

const useStyles = makeStyles({
  selectRoot: {
    opacity: 0.7,
    fontSize: '0.875rem',
    border: 'none',
    fontWeight: 500,
  },
  inputRoot: {
    border: 'none',
    fontSize: '0.875rem',
    fontWeight: 500,
    paddingTop: '8px',
    paddingBottom: '8px',
  },
});

export default DropDownMenu;
