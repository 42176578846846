import { createSelector } from '@reduxjs/toolkit';
import { IMember, MemberFilterType, MemberState, RecordTrack } from 'member/memberType';
import { PaymentInfo } from 'payment/state/paymentType';
import { ApplicationState } from 'redux/reducers';

const getMembers = (state: ApplicationState) => state.members;
const getUserId = (state: ApplicationState) => state.user.user.id;
const getPaymentInfo = (state: ApplicationState) => state.paymentInfo;

export const getSelf = () =>
  createSelector(
    [getUserId, getMembers],
    (userId: number, members: MemberState): IMember => members.byId[userId],
  );

export const membersByTypeSelector = (type: string) => {
  return createSelector([getMembers], ({ meta, byId }: MemberState) => {
    const members: IMember[] = [];
    meta[MemberFilterType.ALL_MEMBERS].ids.forEach(id => {
      const member = byId[id];
      if (member.type === type) {
        members.push(member);
      }
    });

    return members;
  });
};

export const memberByIdSelector = (memberId: number) =>
  createSelector([getMembers, getUserId], (members: MemberState) => members.byId[memberId]);

export const memberSelector = () => {
  return createSelector([getMembers], ({ meta, byId }: MemberState) => {
    const members: IMember[] = [];
    meta[MemberFilterType.ALL_MEMBERS].ids.forEach(id => {
      const member = byId[id];
      members.push(member);
    });

    return members;
  });
};

export const membersByIdsSelector = (memberIds: number[]) => {
  return createSelector([getMembers], (members: MemberState) => {
    return memberIds.map(memberId => members.byId[memberId]);
  });
};

export const membersByFilterType = (filterType: MemberFilterType, propertyId?: number) => {
  return createSelector([getMembers], (state: MemberState) => {
    const members: IMember[] = [];
    state.meta[filterType].ids.forEach(id => {
      const member = state.byId[id];
      if (propertyId && !member.properties.includes(Number(propertyId))) {
        return;
      }

      if (filterType === MemberFilterType.ALL_MEMBERS && member?.status === 'active') {
        members.push(member);
        return;
      }

      if (
        filterType === MemberFilterType.STAFF &&
        ['employee', 'part-time'].includes(member?.staff_type)
      ) {
        members.push(member);
        return;
      }

      if (
        filterType === MemberFilterType.CONTRACTOR &&
        ['contractor'].includes(member?.staff_type)
      ) {
        members.push(member);
        return;
      }

      if (filterType === MemberFilterType.INACTIVE && ['inactive'].includes(member?.status)) {
        members.push(member);
        return;
      }
      if (filterType === MemberFilterType.EXCLUDE_GROUP) {
        members.push(member);
        return;
      }
      if (filterType === MemberFilterType.GROUP) {
        members.push(member);
        return;
      }
    });

    return {
      members,
      totalMembers: state.meta[filterType].total_records,
      isMemberLoading: state.meta.isLoading,
    };
  });
};

export const groupIdForMembersSelector = (memberIds: number[]) => {
  return createSelector([getMembers], (members: MemberState) => {
    return memberIds
      ?.filter(memberId => {
        return members.byId[memberId]?.groups_private?.length > 0;
      })
      .map(memberId => {
        return members.byId[memberId]?.groups_private?.[0];
      });
  });
};

export const membersByPropertyId = (propertyId: number) => {
  return createSelector([getMembers], ({ meta, byId }: MemberState) => {
    const members: IMember[] = [];
    meta[MemberFilterType.ALL_MEMBERS].ids.forEach(id => {
      const member = byId[id];
      if (member?.properties?.includes(propertyId)) {
        members.push(member);
      }
    });
    return members;
  });
};

// TODO: get count fom server
export const memberCountByPropertyId = (propertyId: number) => {
  return createSelector([getMembers], ({ meta, byId }: MemberState) => {
    const members: IMember[] = [];
    meta[MemberFilterType.ALL_MEMBERS].ids.forEach(id => {
      const member = byId[id];
      if (member?.properties?.includes(Number(propertyId))) {
        members.push(member);
      }
    });

    return members.length;
  });
};

export const memberMetaSelector = (type: MemberFilterType) =>
  createSelector([getMembers], (members: MemberState): RecordTrack => members.meta[type]);

export const selfCCInfo = () => {
  return createSelector([getPaymentInfo], (paymentInfo: PaymentInfo) => {
    if (paymentInfo.paymentMethod.card_last_four) {
      return {
        fourDigit: paymentInfo.paymentMethod.card_last_four,
        expiryMonth: paymentInfo.paymentMethod.expiry_month,
        expiryYear: paymentInfo.paymentMethod.expiry_year,
      };
    }

    return undefined;
  });
};

export const stripeToken = () =>
  createSelector([getPaymentInfo], (paymentInfo: PaymentInfo) => {
    return paymentInfo.stripeToken;
  });

export default getMembers;
