import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, Menu, MenuItem, SxProps, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { trpc } from '@operto/trpc-client';
import { Property, Variable } from '@operto/variables-shared';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import InlineTextEditor from 'Common/Templates/Editors/InlineTextEditor';
import SearchField from 'Common/TextField/SearchField';
import { CustomTooltip } from 'Pages/Variables/Common/CustomTooltip';
import { trackEvent } from 'lib/analytics';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import styled from 'styled-components';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { SmartCardIconButton } from 'ui-library/Styled/smartcard';
import { ActionDataGrid } from 'ui-library/Styled/tables';
import { currentUserSelector } from 'user/state/userSelectors';
import {
  getTotalVariablePropertiesCount,
  roundToNearestWholeNumber,
} from '../helpers/VariableHelpers';
import VariablesMoreInfoTopBar from './VariablesMoreInfoTopBar';

export interface VariablesMoreInfoTableProps {
  variable: Variable;
  rows: Property;
  isEditable: boolean;
  onCloseMoreTable: () => void;
  systemVariables: Variable[];
  customVariables: Variable[];
}

const hasHTMLTags = (value: string) => {
  const htmlRegex = /(<([^>]+)>)/gi;
  return htmlRegex.test(value);
};

const stripHTMLToText = (htmlString: string) => {
  const tempDivElement = document.createElement('div');
  tempDivElement.innerHTML = htmlString;
  return tempDivElement.textContent || tempDivElement.innerText || '';
};

export const renderStringInEmptyCell = (value: string | null) => (value.length > 0 ? value : '-');

const VariablesMoreInfoTable = ({
  variable,
  systemVariables,
  customVariables,
  rows,
  isEditable,
  onCloseMoreTable,
}: VariablesMoreInfoTableProps) => {
  const [editSelectionList, setEditSelectionList] = useState([]);
  const [infoSearchValue, setInfoSearchValue] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isHideValuesModalMode, setIsHideValuesModalMode] = useState(false);
  const [isGroupModalMode, setIsGroupModalMode] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [isEditVariableModalMode, setIsEditVariableModalMode] = useState(false);
  const [editValueTo, setEditValueTo] = useState('');

  const MAX_GROUP_NAME_LENGTH = 24;

  const updateVariableTRPC = trpc.variables.updateVariable.useMutation();
  const variableUtils = trpc.useUtils();

  const dispatch = useAppDispatch();

  const currentUser = useSelector(currentUserSelector());

  const mutateVariable = (mutatedVariable: Variable) => {
    updateVariableTRPC.mutate(
      { ...mutatedVariable, updatedBy: currentUser?.name },
      {
        onSuccess: () => {
          variableUtils.variables.getVariables.refetch({ type: 'custom' });
        },
        onSettled: () => {
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Variable updated successfully.',
              variant: SnackbarVariant.SUCCESS,
            }),
          );
        },
        onError: () => {
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Variable has not been updated due to an error.',
              variant: SnackbarVariant.ERROR,
            }),
          );
        },
      },
    );
  };

  const handleInfoSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInfoSearchValue(event?.currentTarget?.value);
  };

  const onEditSelection = (selectionList: string[]) => {
    setEditSelectionList(selectionList);
  };

  const renderValueCell = (params: GridRenderCellParams) => {
    const isDisabled = !params.row?.enabled;

    return (
      <CustomTooltip
        data-testid='custom-tooltip'
        title={<Box width={'100px'} dangerouslySetInnerHTML={{ __html: params.row.value }}></Box>}
        placement='bottom'
        leaveDelay={100}
        followCursor
      >
        <Typography
          data-testid='value-row-cell'
          fontSize={14}
          color={isDisabled ? 'text.disabled' : 'inherit'}
          sx={variableValueSxProps}
        >
          {isDisabled && <VisibilityOffIcon style={{ marginRight: 5 }} />}
          {hasHTMLTags(params.row.value) &&
            `${stripHTMLToText(renderStringInEmptyCell(params.row.value))}...`}
          {!hasHTMLTags(params.row.value) && renderStringInEmptyCell(params.row.value)}
        </Typography>
      </CustomTooltip>
    );
  };

  const variableTableCol: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Unit Name',
      flex: 3,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const isDisabled = !params.row?.enabled;

        return (
          <Typography
            data-testid='id-row-cell'
            fontSize={14}
            color={isDisabled ? 'text.disabled' : 'inherit'}
          >
            {renderStringInEmptyCell(params.row.name)}
          </Typography>
        );
      },
    },
    {
      field: 'group',
      headerName: 'Group',
      flex: 3,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const isDisabled = !params.row?.enabled;

        return (
          <Typography
            data-testid='group-row-cell'
            fontSize={14}
            color={isDisabled ? 'text.disabled' : 'inherit'}
          >
            {renderStringInEmptyCell(params.row.group)}
          </Typography>
        );
      },
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 3,
      type: 'string',
      sortable: false,
      renderCell: renderValueCell,
      editable: isEditable,
    },
  ];

  const handleHideShow = (isShowing: boolean) => {
    const variableCopy = { ...variable };
    const variablePropertiesCopy = { ...rows };

    editSelectionList.forEach((propertyId: string) => {
      variablePropertiesCopy[propertyId].enabled = isShowing;
    });

    variableCopy.properties = variablePropertiesCopy;

    mutateVariable(variableCopy);

    trackEvent({
      screen: 'Variables',
      event: 'EDITED',
      feature: isShowing ? 'Bulk Properties Enabled' : 'Bulk Properties Disabled',
      averagePropertySelectionCount: roundToNearestWholeNumber(
        editSelectionList.length / getTotalVariablePropertiesCount(variableCopy),
      ),
    });

    closeHideModal();
    setEditSelectionList([]);
  };

  const handleSetGroup = () => {
    const variableCopy = { ...variable };
    const variablePropertiesCopy = { ...rows };

    editSelectionList.forEach((propertyId: string) => {
      variablePropertiesCopy[propertyId].group = newGroupName;
    });

    mutateVariable(variableCopy);
    trackEvent({
      screen: 'Variables',
      event: 'EDITED',
      feature: 'Bulk Properties Group',
      averagePropertySelectionCount: roundToNearestWholeNumber(
        editSelectionList.length / getTotalVariablePropertiesCount(variableCopy),
      ),
    });
    setNewGroupName('');
    closeGroupModal();
    setEditSelectionList([]);
  };

  const handleVariableSelectionEdit = () => {
    const variableCopy = { ...variable };
    const variablePropertiesCopy = { ...rows };
    editSelectionList.forEach((propertyId: string) => {
      variablePropertiesCopy[propertyId].value = editValueTo;
    });
    variableCopy.properties = variablePropertiesCopy;

    mutateVariable(variableCopy);
    trackEvent({
      screen: 'Variables',
      event: 'EDITED',
      feature: 'Bulk Properties Value',
      averagePropertySelectionCount: roundToNearestWholeNumber(
        editSelectionList.length / getTotalVariablePropertiesCount(variableCopy),
      ),
    });
    setEditValueTo('');
    setIsEditVariableModalMode(false);
  };

  const handleVariableInlineUpdate = (propertyId: string, value: string) => {
    const variableCopy = { ...variable };
    const variablePropertiesCopy = { ...rows };

    variablePropertiesCopy[propertyId].value = value;
    variableCopy.properties = variablePropertiesCopy;

    mutateVariable(variableCopy);
    trackEvent({
      screen: 'Variables',
      event: 'EDITED',
      feature: ' Inline Value',
    });
  };

  const closeHideModal = () => {
    setIsHideValuesModalMode(false);
    setAnchorEl(null);
  };

  const closeGroupModal = () => {
    setIsGroupModalMode(false);
    setAnchorEl(null);
    setNewGroupName('');
  };

  const infoData = Object.keys(rows)
    .map((propertyId: string) => rows[propertyId])
    .filter(infoRow => infoRow.name?.toLowerCase().includes(infoSearchValue.toLowerCase()));

  return (
    <>
      <ConfirmDialog
        data-testid='hide-properties-modal'
        open={isHideValuesModalMode}
        title='Hide Value'
        submitButtonColor='primary'
        onSubmit={() => handleHideShow(false)}
        onClose={closeHideModal}
        submitButtonText='Confirm'
      >
        <Typography>
          Are you sure that you want to hide the values? The value would not show for the selected
          units.
        </Typography>
      </ConfirmDialog>
      <ConfirmDialog
        data-testid='set-group-modal'
        open={isGroupModalMode}
        title='Set Group'
        submitButtonColor='primary'
        onSubmit={handleSetGroup}
        onClose={closeGroupModal}
        submitButtonText='save'
      >
        <TextField
          data-testid='set-group-input'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target?.value;
            if (value.length <= MAX_GROUP_NAME_LENGTH) {
              setNewGroupName(value);
            }
          }}
          value={newGroupName}
          label='Enter Group'
          sx={{ width: '97%' }}
          helperText={`Character Limit: ${newGroupName.length}/${MAX_GROUP_NAME_LENGTH}`}
        />
      </ConfirmDialog>
      <ConfirmDialog
        open={isEditVariableModalMode}
        title='Edit variable value'
        submitButtonColor='primary'
        onSubmit={handleVariableSelectionEdit}
        onClose={() => {
          setIsEditVariableModalMode(false);
          setEditValueTo('');
        }}
        submitButtonText='Save'
      >
        <InlineTextEditor
          style={{ width: '535px', minHeight: '57px' }}
          value={editValueTo}
          onTextChange={text => {
            setEditValueTo(text);
          }}
          showScriptButton={false}
          disableToolbar
        />
      </ConfirmDialog>
      <Box sx={{ minHeight: 400, height: 400, width: '60%' }}>
        <Card
          variant='outlined'
          sx={{
            height: 399.5,
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderTop: 0,
          }}
        >
          <CardContent>
            <TopBarContainer>
              <VariablesMoreInfoTopBar
                variable={variable}
                systemVariables={systemVariables}
                customVariables={customVariables}
                isEditable={isEditable}
                onCloseMoreTable={onCloseMoreTable}
              />
            </TopBarContainer>
            <Box style={{ height: 300 }}>
              <Stack direction='column'>
                <Stack
                  direction='row'
                  style={{
                    marginTop: 20,
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {editSelectionList.length > 0 && isEditable && (
                    <Box style={{ alignSelf: 'center' }}>
                      <Button
                        data-testid='edit-value-button'
                        startIcon={<EditIcon />}
                        onClick={() => {
                          setIsEditVariableModalMode(true);
                        }}
                        variant='contained'
                        style={{ height: 37 }}
                      >
                        Edit Value
                      </Button>
                      <SmartCardIconButton
                        data-testid='more-button'
                        $show
                        size='small'
                        style={{ marginLeft: 5 }}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                          setAnchorEl(event.currentTarget);
                        }}
                      >
                        <MoreHorizIcon />
                      </SmartCardIconButton>
                      <Menu
                        data-testid='more-info-menu'
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                      >
                        <MenuItem
                          data-testid='show-value-button'
                          onClick={() => handleHideShow(true)}
                        >
                          Display Value
                        </MenuItem>
                        <MenuItem
                          data-testid='hide-value-button'
                          onClick={() => {
                            setIsHideValuesModalMode(true);
                          }}
                        >
                          Hide Value
                        </MenuItem>
                        <MenuItem
                          data-testid='set-group-button'
                          onClick={() => {
                            setIsGroupModalMode(true);
                          }}
                        >
                          Set Group
                        </MenuItem>
                      </Menu>
                    </Box>
                  )}
                  {editSelectionList.length === 0 && (
                    <Typography variant='body2' style={{ alignSelf: 'center' }}>
                      Select units to edit or display/hide value(s)
                    </Typography>
                  )}
                  <CustomSearchField
                    size='small'
                    onChange={handleInfoSearch}
                    value={infoSearchValue}
                    key='search-field'
                  />
                </Stack>
                <Box style={{ height: 250 }}>
                  <CustomActionDataGrid
                    disableColumnMenu
                    hideFooterRowCount
                    sx={{ border: 0 }}
                    rows={infoData}
                    columns={variableTableCol}
                    checkboxSelection={isEditable}
                    selectionModel={editSelectionList}
                    disableSelectionOnClick
                    onSelectionModelChange={onEditSelection}
                    onCellEditCommit={params => {
                      handleVariableInlineUpdate(params.id.toString(), params.value.toString());
                    }}
                  />
                </Box>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

const TopBarContainer = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const CustomActionDataGrid = styled(ActionDataGrid)`
  && {
    .MuiDataGrid-row .MuiDataGrid-cellCheckbox:first-child {
      padding: 0;
    }
  }
`;

const CustomSearchField = styled(SearchField)`
  && {
    .MuiFormControl-root css-e363es-MuiFormControl-root {
      margin: 0 !important;
    }
  }
`;

const variableValueSxProps: SxProps = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
};

export default VariablesMoreInfoTable;
