import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { OverviewData } from '../../../types/ui';
import { Actions } from '../../../types/actions';

const checkIns: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.hydrateOverview:
      return action.checkIns;
    default:
      return state;
  }
};

const confirmedCheckIns: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.hydrateOverview:
      return action.confirmedCheckIns;
    default:
      return state;
  }
};

const checkOuts: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.hydrateOverview:
      return action.checkOuts;
    default:
      return state;
  }
};

const confirmedCheckOuts: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.hydrateOverview:
      return action.confirmedCheckOuts;
    default:
      return state;
  }
};

const cleaning: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.hydrateOverview:
      return action.cleaning;
    default:
      return state;
  }
};

const confirmedCleaning: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.hydrateOverview:
      return action.confirmedCleaning;
    default:
      return state;
  }
};

const overviewData: Reducer<OverviewData> = combineReducers<OverviewData>({
  checkIns,
  confirmedCheckIns,
  checkOuts,
  confirmedCheckOuts,
  cleaning,
  confirmedCleaning,
});

export default overviewData;
