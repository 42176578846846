import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, IconButton, SxProps, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IHeaderButton } from './SideBarMenuTypes';

interface SideBarHeaderProps {
  open: boolean;
  toggleNav?: () => void;
  headerButton?: IHeaderButton;
}

const SideBarHeader: React.FC<SideBarHeaderProps> = ({ open, headerButton, toggleNav }) => {
  const mainContainer: SxProps = {
    paddingLeft: '14px',
    display: open ? 'block' : 'none',
  };

  const headerHomeStyle: SxProps = {
    display: 'flex',
    alignItems: 'center',
    marginTop: headerButton ? '-42px' : '-30px',
    padding: '5.25px',
  };

  const headerLogoStyle: SxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '8px',
    marginLeft: '10px',
  };

  const logoStyle: React.CSSProperties = {
    width: '103px',
    height: '29px',
  };

  const iconChevronStyle: SxProps = {
    color: 'primary.main',
    width: '48px',
    height: '48px',
  };

  const iconMenuStyle: SxProps = {
    color: 'primary.main',
  };

  const HomeTextStyle: SxProps = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '13px',
    textTransform: 'capitalize',
    color: 'primary.main',
  };

  const navigate = useNavigate();
  const goToUrl = (path: string) => navigate(path);

  return (
    <>
      {!open && (
        <IconButton
          onClick={toggleNav}
          sx={{ borderRadius: '48px', height: '48px' }}
          data-testid='menuIcon'
        >
          <MenuIcon sx={iconMenuStyle} />
        </IconButton>
      )}

      <Box sx={mainContainer}>
        {headerButton && (
          <Box sx={headerHomeStyle}>
            <Button
              onClick={() => goToUrl(headerButton.path)}
              sx={{ gap: '8px' }}
              data-testid='goToUrl'
            >
              <ArrowBackIcon sx={{ fontSize: '18px', padding: 0 }} />
              <Typography sx={HomeTextStyle}>{headerButton.label}</Typography>
            </Button>
          </Box>
        )}

        <Box sx={headerLogoStyle}>
          <img alt='Operto logo' src='/img/logos/operto-logo-black.svg' style={logoStyle} />

          <IconButton sx={iconChevronStyle} onClick={toggleNav} data-testid='arrowIcon'>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default SideBarHeader;
