import TemperatureIcon from '@mui/icons-material/Thermostat';
import * as React from 'react';

export interface NoiseIconProps {
  customColor?: string;
}

const ThermostatIcon = ({ customColor = 'error.main' }: NoiseIconProps) => {
  const thermostatIcon = <TemperatureIcon sx={{ color: customColor }} />;
  return thermostatIcon;
};

export default ThermostatIcon;
