import { Box, SxProps, Typography } from '@mui/material';
import TableNoResultsFoundIcon from 'assets/img/icons/table-no-results-found.svg';
import React from 'react';

const TableNoResultsFound = () => {
  return (
    <Box data-testid='table-no-results-box' sx={tableNoResultsBoxStyles}>
      <TableNoResultsFoundIcon />
      <Typography variant='body-lg-600'>No results found</Typography>
    </Box>
  );
};

const tableNoResultsBoxStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export default TableNoResultsFound;
