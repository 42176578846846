import ErrorIcon from '@mui/icons-material/Error';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import LockIcon from '@mui/icons-material/LockOutlined';
import Chip from '@mui/material/Chip';
import React from 'react';
import { RowCellTableTooltip } from 'ui-library/Components/table/TableTooltip';

export interface LockCellProps {
  isLocked?: boolean;
  isOnline?: boolean;
  showLockIcon?: boolean;
  isOfflineLock?: boolean;
}

export type LockCellTooltipText = 'Locked' | 'Unlocked' | 'Offline';

const renderLockCellToolTip = (lockStatus: LockCellTooltipText, lockIcon: React.ReactElement) => {
  return <RowCellTableTooltip title={lockStatus}>{lockIcon}</RowCellTableTooltip>;
};

const LockCell = ({ isLocked, isOnline, showLockIcon, isOfflineLock }: LockCellProps) => {
  if (isOfflineLock) {
    return renderLockCellToolTip('Offline', <LockIcon color='disabled' />);
  }

  if (!isOnline) {
    const icon = showLockIcon ? <LockIcon /> : <ErrorIcon />;
    return <Chip size='small' icon={icon} label='Offline' color='error' />;
  }

  if (isLocked) {
    return renderLockCellToolTip('Locked', <LockIcon />);
  }

  return renderLockCellToolTip('Unlocked', <LockOpenIcon />);
};

export default LockCell;
