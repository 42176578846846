import jwt_decode from 'jwt-decode';
import * as api from '../../api/integrations';

import { toggleAddIntegration } from '../../redux/actions/ui';

import { NotifiableError } from '@bugsnag/js';
import { OpertoLogger } from 'Logger/logger';
import { AppDispatch } from 'redux/store';
import { getProperties } from '../../redux/actions/properties';
import { ApplicationState } from '../../redux/reducers';
import { Actions } from '../../types/actions';
import { parseGetIntegrationResponse } from '../integrationsParsers';
import {
  IntegrationApiProvider,
  IntegrationDict,
  IntegrationProvider,
  TGuestyPayload,
} from '../integrationsTypes';

export const integrateWithGuesty = (token: string) => (dispatch: AppDispatch) => {
  api
    .integrateWithGuesty(token)
    .then(() => {
      dispatch(toggleAddIntegration(IntegrationProvider.Guesty));
      dispatch(getIntegrations());
    })
    .catch((err: NotifiableError) => {
      OpertoLogger.Log(err);
    });
};

export const integrateWithMyVr =
  (code: string) => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const token: string = getState().user.accessToken;
    const decoded: Record<string, unknown> & Record<string, number> = jwt_decode(token);
    const companyId = decoded.cid;
    return api
      .integrateWithMyVR(code, companyId)
      .then(() => {
        dispatch(getIntegrations());
        return true;
      })
      .catch((err: Error) => {
        OpertoLogger.Log(err);
        throw Error(err.message);
      });
  };

export const enableGuestyIntegration =
  () => async (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const state: ApplicationState = getState();
    const integrationId: string = state.integrations.list.find(
      id => state.integrations.byId[id].name === IntegrationApiProvider.Guesty,
    );

    try {
      await api.enableIntegration(parseInt(integrationId));
      dispatch(getIntegrations());
    } catch (err) {
      OpertoLogger.Log(err);
      throw Error(err.message);
    }
  };

export const disableGuestyIntegration =
  () => async (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const state: ApplicationState = getState();
    const integrationId: string = state.integrations.list.find(
      id => state.integrations.byId[id].name === IntegrationApiProvider.Guesty,
    );

    try {
      await api.disableIntegration(parseInt(integrationId));
      dispatch(getIntegrations());
    } catch (err) {
      OpertoLogger.Log(err);
      throw Error(err.message);
    }
  };

export const disableCloudbedsIntegration =
  () => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const state: ApplicationState = getState();
    const integrationId: string = state.integrations.list.find(
      id => state.integrations.byId[id].name === IntegrationApiProvider.Cloudbeds,
    );

    api
      .disableIntegration(parseInt(integrationId))
      .then(() => {
        dispatch(getIntegrations());
      })
      .catch(err => {
        OpertoLogger.Log(err);
      });
  };

export const enableCloudbedsIntegration =
  () => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const state: ApplicationState = getState();
    const integrationId: string = state.integrations.list.find(
      id => state.integrations.byId[id].name === IntegrationApiProvider.Cloudbeds,
    );

    api
      .enableIntegration(parseInt(integrationId))
      .then(() => {
        dispatch(getIntegrations());
      })
      .catch(err => {
        OpertoLogger.Log(err);
      });
  };

export const integrateWithHostAway =
  (accountId: string, apiKey: string) => (dispatch: AppDispatch) => {
    api
      .integrateWithHostAway(accountId, apiKey)
      .then(() => {
        dispatch(toggleAddIntegration(IntegrationProvider.HostAway));
        return true;
      })
      .catch((err: Error) => {
        OpertoLogger.Log(err);
        throw Error(err.message);
      });
  };

export const integrateWithMews = (token: string) => (dispatch: AppDispatch) =>
  api
    .integrateWithMews(token)
    .then(() => {
      dispatch(toggleAddIntegration(IntegrationProvider.Mews));
      return true;
    })
    .catch((err: Error) => {
      OpertoLogger.Log(err);
      throw Error(err.message);
    });

export const integrateWithCloudbeds = (code: string) => (dispatch: AppDispatch) => {
  return api
    .postIntegration({ provider: IntegrationProvider.Cloudbeds, code })
    .then(() => {
      dispatch(getIntegrations());
      return true;
    })
    .catch((err: Error) => {
      OpertoLogger.Log(err);
      throw Error(err.message);
    });
};

export const integrateWithGuestySetting =
  (data: Omit<TGuestyPayload, 'provider'>) => async (dispatch: AppDispatch) => {
    try {
      await api.postIntegration({ provider: IntegrationProvider.Guesty, ...data });
      dispatch(getIntegrations());
    } catch (err) {
      OpertoLogger.Log(err);
      throw err.message;
    }
  };

export const integrateWithAvantio =
  (username: string, password: string) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const token: string = getState().user.accessToken;
    const decoded: Record<string, unknown> & Record<string, number> = jwt_decode(token);
    const companyId = decoded.cid;
    api
      .integrateWithAvantio(companyId, username, password)
      .then(() => {
        dispatch(toggleAddIntegration(IntegrationProvider.Avantio));
        return true;
      })
      .catch((err: Error) => {
        OpertoLogger.Log(err);
        throw Error(err.message);
      });
  };

export const integrateWithKigo =
  (apiKey: string, apiSecret: string, apiToken: string) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const token: string = getState().user.accessToken;
    const decoded: Record<string, unknown> & Record<string, number> = jwt_decode(token);
    const companyId = decoded.cid;
    api
      .integrateWithKigo(companyId, apiKey, apiSecret, apiToken)
      .then(() => {
        dispatch(toggleAddIntegration(IntegrationProvider.Kigo));
        return true;
      })
      .catch((err: Error) => {
        OpertoLogger.Log(err);
        throw Error(err.message);
      });
  };

export const integrateWithVRS = () => (dispatch: AppDispatch, getState: () => ApplicationState) => {
  const token: string = getState().user.accessToken;
  const decoded: Record<string, unknown> & Record<string, number> = jwt_decode(token);
  const companyId = decoded.cid;
  api
    .integrateWithVRS(companyId)
    .then(() => {
      dispatch(toggleAddIntegration(IntegrationProvider.VRScheduler));
      return true;
    })
    .catch((err: Error) => {
      OpertoLogger.Log(err);
      throw Error(err.message);
    });
};

export const integrateWithHostfully =
  (apiKey: string, apiSecret: string) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const token: string = getState().user.accessToken;
    const decoded: Record<string, unknown> & Record<string, number> = jwt_decode(token);
    const companyId = decoded.cid;
    api
      .integrateWithHostfully(companyId, apiKey, apiSecret)
      .then(() => {
        dispatch(toggleAddIntegration(IntegrationProvider.Hostfully));
        return true;
      })
      .catch((err: Error) => {
        OpertoLogger.Log(err);
        throw Error(err.message);
      });
  };

export const integrateWithDirect =
  (apiKey: string, apiSecret: string) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const token: string = getState().user.accessToken;
    const decoded: Record<string, unknown> & Record<string, number> = jwt_decode(token);
    const companyId = decoded.cid;
    api
      .integrateWithDirect(companyId, apiKey, apiSecret)
      .then(() => {
        dispatch(toggleAddIntegration(IntegrationProvider.Direct));
        return true;
      })
      .catch((err: Error) => {
        OpertoLogger.Log(err);
        throw Error(err.message);
      });
  };

export const integrateWithLightmaker =
  (apiKey: string, apiSecret: string) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const token: string = getState().user.accessToken;
    const decoded: Record<string, unknown> & Record<string, number> = jwt_decode(token);
    const companyId = decoded.cid;
    api
      .integrateWithLightmaker(companyId, apiKey, apiSecret)
      .then(() => {
        dispatch(toggleAddIntegration(IntegrationProvider.Lightmaker));
        return true;
      })
      .catch((err: Error) => {
        OpertoLogger.Log(err);
        throw Error(err.message);
      });
  };

export const importCloudbedUnits =
  () => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const state: ApplicationState = getState();
    const integrationId: string = state.integrations.list.find(
      id => state.integrations.byId[id].name === IntegrationApiProvider.Cloudbeds,
    );
    api
      .importIntegrationUnits(parseInt(integrationId))
      .then(() => {
        dispatch(getProperties());
        dispatch({
          type: Actions.cloudbedsPropertyImportComplete,
          message: 'Success!',
        });
      })
      .catch((err: Error) => {
        dispatch({
          type: Actions.cloudbedsPropertyImportComplete,
          message: 'Error importing',
        });
        OpertoLogger.Log(err);
      });
  };

export const importGuestyUnits =
  () => async (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const state: ApplicationState = getState();
    const integrationId: string = state.integrations.list.find(
      id => state.integrations.byId[id].name === IntegrationApiProvider.Guesty,
    );
    if (!integrationId) {
      throw Error('No Integration found');
    }
    try {
      await api.importIntegrationUnits(parseInt(integrationId));
      dispatch(getProperties());
      dispatch({
        type: Actions.guestyPropertyImportComplete,
        message: 'Success!',
      });
    } catch (err) {
      dispatch({
        type: Actions.guestyPropertyImportComplete,
        message: 'Error importing',
      });
      OpertoLogger.Log(err);
    }
  };

export const getIntegrations = () => (dispatch: AppDispatch) => {
  api
    .getIntegrations()
    .then(data => {
      const integrations = parseGetIntegrationResponse(data.data.data);
      const integrationsById: IntegrationDict = {};
      const integrationsList: number[] = [];
      integrations.forEach(integration => {
        integrationsById[integration.id] = integration;
        integrationsList.push(integration.id);
      });
      dispatch({
        type: Actions.hydrateIntegrations,
        integrationsById,
        integrationsList,
      });
    })
    .catch(err => {
      OpertoLogger.Log(err);
    });
};

export const enableIntegration =
  () => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const token: string = getState().user.accessToken;
    const decoded: Record<string, unknown> & Record<string, number> = jwt_decode(token);
    const companyId = decoded.cid;
    api
      .reenableCloudBedIntegration(companyId)
      .then(() => {
        dispatch(getIntegrations());
      })
      .catch(err => {
        OpertoLogger.Log(err);
      });
  };
