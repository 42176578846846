/*
Reference article given by Sophia: https://dev.to/admitkard/auto-generate-avatar-colors-randomly-138j
This algorithm is used to generate a random color based on the target string, with the goal of generating distinct profile background colors
We are basically using a hashing function to generate a unique code for a string value and normalizing that to a HSL color.  
*/

export const hashTarget = (target: string): number => {
  let hashSum = 0;
  for (let i = 0; i < target.length; i++) {
    hashSum = target.charCodeAt(i) + ((hashSum << 5) - hashSum);
  }

  return Math.abs(hashSum);
};

export const normalizeHash = (hash: number, min: number, max: number) => {
  return Math.floor((hash % (max - min)) + min);
};

export const generateRandomHSL = (target: string): string => {
  const hashValue = hashTarget(target);
  const h = normalizeHash(hashValue, 0, 360);
  const s = normalizeHash(hashValue, 20, 80);
  const l = normalizeHash(hashValue, 20, 80);

  return `hsl(${h}, ${s}%, ${l}%)`;
};
