import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { Actions } from '../../types/actions';
import { CodeDict, CodeState } from '../codeType';

const initialListState: number[] = [];

const list: Reducer<number[]> = (state = initialListState, action) => {
  switch (action.type) {
    case Actions.hydrateCodes:
      return action.codeList;

    case Actions.hydrateCode:
      return [action.code.id];

    case Actions.upsertCodes:
      return Array.from(new Set([...state, ...action.codeList]));

    case Actions.upsertCode:
    case Actions.addTeamCode:
      return Array.from(new Set([...state, action.code.id]));

    case Actions.deleteCode:
      return state.filter(id => id !== action.id);

    default:
      return state;
  }
};

const initialCodeState: CodeDict = {};

const byId: Reducer<CodeDict> = (state = initialCodeState, action) => {
  switch (action.type) {
    case Actions.hydrateCodes:
      return action.codeDict;

    case Actions.hydrateCode:
      return { [action.code.id]: action.code };

    case Actions.upsertCodes:
      return {
        ...state,
        ...action.codeDict,
      };

    case Actions.upsertCode:
    case Actions.addTeamCode:
      return {
        ...state,
        [action.code.id]: action.code,
      };

    case Actions.deleteCode:
      delete state[action.id];
      return state;

    default:
      return state;
  }
};

const reducer: Reducer<CodeState> = combineReducers<CodeState>({
  byId,
  list,
});

export default reducer;
