/**
 * Helper function to enable/disable a react query hook based on variables being initialized
 * @param variables
 * @returns true when all variables are initialized and non-empty
 */
export const enabledIfVariablesInitialized = (...variables: unknown[]) => {
  return {
    enabled: variables.every(variable => {
      return !!variable && (typeof variable !== 'string' || variable.length > 0);
    }),
  };
};
