import { Typography } from '@mui/material';
import { createStripeConnectAccount } from 'api/paymentAPI';
import ActionsDialog from 'Common/Dialog/ActionsDialog';
import { companySelector } from 'company/state/companySelectors';
import useTranslation from 'hooks/useTranslation';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';

const oauthKey = process.env.REACT_APP_STRIPE_OAUTH_KEY;

export type StripeAccountIdFormProps = {
  open: boolean;
  accountId: string;
  handleSetAccountId: (accountId: string) => void;
  handleCancel: () => void;
};

export const StripeAccountIdForm = ({
  open,
  accountId,
  handleSetAccountId,
  handleCancel,
}: StripeAccountIdFormProps) => {
  const { t } = useTranslation();
  const company = useSelector(companySelector());
  const companyId: string = company.id.toString();

  const dispatch = useAppDispatch();

  const [isCreateAccountLoading, setIsCreateAccountLoading] = useState<boolean>(false);
  const [isConnectAccountLoading, setIsConnectAccountLoading] = useState<boolean>(false);

  const createStripeAccount = async () => {
    setIsCreateAccountLoading(true);
    try {
      const createAccountResponse = await createStripeConnectAccount(companyId);
      const stripeAccountId = createAccountResponse.data.data;
      handleSetAccountId(stripeAccountId);
    } catch (error) {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: t('stripe_account_create_error'),
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  const connectStripeAccount = async () => {
    setIsConnectAccountLoading(true);
    const redirectURI = encodeURIComponent(
      `${window.location.origin}/settings/integrations/stripe`,
    );
    window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${oauthKey}&scope=read_write&redirect_uri=${redirectURI}`;
  };

  return (
    <ActionsDialog
      title={accountId ? t('stripe_switch_account_title') : t('stripe_link_account_title')}
      open={open}
      onClose={() => handleCancel()}
      actionButtonOne={{
        text: accountId ? t('stripe_switch_account') : t('stripe_connect_account'),
        onClick: connectStripeAccount,
        loading: isConnectAccountLoading,
      }}
      actionButtonTwo={
        accountId
          ? null
          : {
              text: t('stripe_create_account'),
              onClick: createStripeAccount,
              loading: isCreateAccountLoading,
            }
      }
      testId='stripe-account-id-form'
    >
      {accountId ? (
        <Typography variant='body1'>
          {t('stripe_switch_account_description', {
            bolded_text: (
              <Typography variant='subtitle1' display='inline'>
                {t('stripe_switch_account_bolded_text', { account_id: accountId })}
              </Typography>
            ),
          })}
        </Typography>
      ) : (
        <Typography variant='body1'>{t('stripe_link_account_description')}</Typography>
      )}
    </ActionsDialog>
  );
};

export default StripeAccountIdForm;
