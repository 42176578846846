export interface TableConfig {
  pageNum: number;
  numPerPage: number;
  total_records: number;
}

export const isFetchMore = (
  meta: TableConfig,
  pageNum: number,
  isLoading: boolean,
  data: unknown[],
  tablePageSize: number,
) => {
  if (tablePageSize > 50) {
    return (
      meta.pageNum * (meta.numPerPage / tablePageSize) + meta.numPerPage / tablePageSize ===
        pageNum + 1 &&
      !isLoading &&
      meta.total_records > data.length
    );
  } else {
    return (
      meta.pageNum * (meta.numPerPage / tablePageSize) + meta.numPerPage / tablePageSize ===
        pageNum + 1 &&
      !isLoading &&
      meta.total_records > data.length
    );
  }
};
