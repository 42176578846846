import { Box } from '@mui/material';
import React from 'react';
import Iframe from 'react-iframe';

type PreviewDeviceProps = {
  url: string;
};

export const PreviewDevice = ({ url }: PreviewDeviceProps) => {
  return (
    <Box
      maxWidth='409px'
      height='842px'
      bgcolor='#F9FDFF'
      p={2}
      borderRadius={6}
      boxShadow='10px 10px 30px rgba(67, 98, 159, 0.1), inset -2px -10px 20px rgba(80, 118, 153, 0.4), inset 10px 2px 20px #FFFFFF;' // straight from figma
      alignContent='center'
    >
      <Box
        bgcolor='rgba(157, 176, 185, 0.43);'
        width='84px'
        height='9px'
        mt='30px'
        mx='auto'
        borderRadius={6}
        boxShadow='inset 0px 4px 4px rgba(185, 181, 181, 0.25);' // straight from figma
      />
      <Box width='375px' height='735px' mt='14px'>
        <Iframe
          url={url}
          width='100%'
          height='100%'
          styles={{
            border: '1px solid #DCE0E3',
            borderRadius: '15px',
          }}
        />
      </Box>
    </Box>
  );
};
