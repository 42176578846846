import EditIcon from '@mui/icons-material/Edit';
import MessageIcon from '@mui/icons-material/Message';
import { IconButton } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ActionCell } from 'Common/Tables/Properties/TableCells';
import { messengerSettingSelector } from 'company/state/companySelectors';
import { logger } from 'lib/logger';
import MemberProfile from 'member/memberProfileGetter';
import { IMember, MemberFilterType } from 'member/memberType';
import {
  getMembersByCompany,
  getPropertyMembers,
  toggleMemberStatus,
} from 'member/state/memberActions';
import { membersByFilterType } from 'member/state/memberSelectors';
import React, { useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleSlidebar, toggleSnackbar } from 'redux/actions/ui';
import { useAppSelector } from 'redux/hooks';
import { SlidebarType, SnackbarTypes, SnackbarVariant } from 'types/ui';
import ActionDropDownMenu from 'ui-library/Components/drop-down/ActionDropDownMenu';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import { TableCell } from 'ui-library/Components/table/TableCell';
import { userSelector } from 'user/state/userSelectors';
import { UserState } from 'user/userTypes';
import { roleHasPermission } from '../../../helper/helper';
import { permissionString } from '../helpers';

type MembersTableProps = {
  title: string;
  filterType: MemberFilterType;
  propertyId?: number;
  isAssigning?: boolean;
  onAdd?: () => void;
};

const MembersTable = ({
  title,
  filterType,
  propertyId,
  isAssigning = false,
  onAdd,
}: MembersTableProps) => {
  const [saving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { members, totalMembers, isMemberLoading } = useSelector(
    membersByFilterType(filterType, propertyId),
  );
  const messengerSetting = useSelector(messengerSettingSelector());
  const memberMessengerEnabled = messengerSetting.mp_member_messenger_enabled;
  const loggedInMember: UserState = useAppSelector(userSelector());

  const handleFetch = useCallback(
    (pageNum: number, numPerPage: number, searchValue?: string) => {
      if (propertyId) {
        dispatch(getPropertyMembers(propertyId, pageNum === 0, filterType));
      } else {
        dispatch(
          getMembersByCompany({
            filterType,
            pageNum,
            numPerPage,
            keyword: searchValue,
          }),
        );
      }
    },
    [dispatch, filterType, propertyId],
  );

  const handleEdit = (member: IMember) => {
    dispatch(toggleSlidebar(SlidebarType.EDIT_MEMBERS, member));
  };

  const handleAdd = () => {
    if (onAdd) {
      onAdd?.();
      return;
    }

    dispatch(toggleSlidebar(SlidebarType.ADD_NEW_MEMBERS, ''));
  };

  const handleToggleMemberStatus = async (memberId: number, status: boolean) => {
    let success = true;
    setSaving(true);

    try {
      await dispatch(toggleMemberStatus(memberId, status));
    } catch (error) {
      success = false;
      logger.error('error disabling member', error);
    }

    setSaving(false);
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `${
          success
            ? `Successfully ${status ? 'enabled' : 'disabled'}`
            : `Error ${status ? 'enabling' : 'disabling'}`
        } member`,
        variant: success ? SnackbarVariant.SUCCESS : SnackbarVariant.ERROR,
      }),
    );
  };

  const handleMessageClicked = () => {
    // member is a bit more complicated and need to be done later
    navigate(`/messenger/members`);
  };

  const renderMembers = ({ row }: GridRenderCellParams<unknown, IMember>) => {
    return <TableCell title={row.name} description={row.email} />;
  };

  const renderAvatar = (params: GridRenderCellParams<unknown, IMember>) => {
    return <MemberProfile size='medium' memberData={params.row} />;
  };

  const renderActions = (params: GridRenderCellParams<unknown, IMember>) => {
    if (isMobile) {
      return <ActionCell handleClick={handleMoreClick} />;
    }

    const hasPermission = roleHasPermission(
      loggedInMember.user.id,
      loggedInMember.role,
      params.row.id,
      params.row.role,
    );

    return (
      <>
        <IconButton
          size='small'
          color='primary'
          onClick={() => {
            handleEdit(params.row);
          }}
          disabled={!hasPermission}
        >
          <EditIcon />
        </IconButton>

        {memberMessengerEnabled && (
          <IconButton size='small' color='primary' onClick={handleMessageClicked}>
            <MessageIcon />
          </IconButton>
        )}

        <ActionDropDownMenu
          onEdit={handleEdit}
          showEdit={filterType !== MemberFilterType.INACTIVE}
          showDisable={filterType !== MemberFilterType.INACTIVE}
          selectedRow={params.row}
          onDisable={memberId => handleToggleMemberStatus(memberId, false)}
          onEnable={memberId => handleToggleMemberStatus(memberId, true)}
          disabled={!hasPermission}
        />
      </>
    );
  };

  const handleMoreClick = () => {
    setOpen(!open);
  };

  const columnsDef: GridColDef<IMember>[] = [
    {
      field: 'id',
      headerName: ' ',
      renderCell: renderAvatar,
      width: 70,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Members',
      flex: 1.5,
      renderCell: renderMembers,
      sortable: true,
    },
    { field: 'job_title', headerName: 'Role', flex: 1, sortable: false },
    {
      field: 'contact_number',
      headerName: 'Phone',
      width: 180,
      sortable: false,
    },
    {
      field: 'role',
      headerName: 'Account Type',
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => permissionString(row.role),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: renderActions,
      width: 150,
      type: 'number',
      sortable: false,
    },
  ];

  return (
    <PaginatedTable
      enableToolbar
      title={title}
      initialState={{ pinnedColumns: { right: ['action'] } }}
      loading={saving || isMemberLoading}
      rows={members}
      rowCount={!isAssigning ? totalMembers : undefined}
      columns={columnsDef}
      onFetch={handleFetch}
      onAdd={handleAdd}
    />
  );
};

export default MembersTable;
