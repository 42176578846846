import MembersTable from 'Common/Tables/People/MembersTable';
import { tabItems } from 'Pages/People/MembersPage';
import { getMessengerSetting } from 'company/state/companyAction';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toggleSlidebar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SlidebarType } from 'types/ui';

const paramFilterTypes = ['all', 'employees', 'contractors', 'inactive'] as const;
type ParamFilterType = (typeof paramFilterTypes)[number];
const determineTabItem = (type: ParamFilterType) => {
  const index = paramFilterTypes.indexOf(type);
  if (index === -1) {
    return tabItems[0];
  }

  return tabItems[index];
};

const MembersContainer = () => {
  const { propertyId: pidStr, type } = useParams();
  const propertyId = Number(pidStr);
  const dispatch = useAppDispatch();
  const tabItem = determineTabItem(type as ParamFilterType);

  const handleMemberAddClick = () => {
    dispatch(toggleSlidebar(SlidebarType.ASSIGN_MEMBERS_TO_UNITS, ''));
  };

  useEffect(() => {
    dispatch(getMessengerSetting());
  }, [dispatch]);

  return (
    <MembersTable
      data-testid='members-table'
      title={tabItem.label}
      filterType={tabItem.value}
      propertyId={propertyId}
      isAssigning
      onAdd={handleMemberAddClick}
    />
  );
};

export default MembersContainer;
