// @ts-nocheck

import { createSelector } from '@reduxjs/toolkit';
import { DeviceState, IDevice } from 'device/deviceType';
import { PropertyState } from 'property/propertyType';
import { ApplicationState } from 'redux/reducers';

const getDevices = (state: ApplicationState) => state.devices;
const getProperties = (state: ApplicationState) => state.properties;
const getPropertyDetailId = (state: ApplicationState) => state.ui.propertyDetailId;

export const devicesFromPropertyDetailSelector = createSelector(
  [getProperties, getPropertyDetailId],
  (properties: PropertyState, propertyId: number) => {
    const property = properties.byId[propertyId];
    let devices = property?.devices || [];
    devices = devices?.filter(device => !device?.device_name?.includes('Intercom'));
    const commonPropertyDevices = property?.common_property_devices || [];
    const commonPropertyLocks = commonPropertyDevices?.filter(
      device => device.device_name === 'SmartLock',
    );
    const allDevices = [...devices, ...commonPropertyLocks];
    return allDevices;
  },
);
export const devicesByPropertyIdSelector = (propertyId: number) =>
  createSelector([getDevices], (devices: DeviceState) =>
    devices?.list
      .map((deviceId: number) => {
        return devices.byId[deviceId];
      })
      .filter((device: IDevice) => Number(device?.data?.property_id) === Number(propertyId)),
  );

export const hasSensorDevicesSelector = () =>
  createSelector(
    [getDevices],
    (devices: DeviceState) =>
      devices?.list
        .map((deviceId: number) => {
          return devices.byId[deviceId];
        })
        .filter(
          (device: IDevice) => device?.vendor === 'Netatmo' || device?.vendor === 'NoiseAware',
        ).length > 0,
  );
