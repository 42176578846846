import * as React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useParams } from 'react-router-dom';

const MobileKey = () => {
  const { resId, lastName } = useParams<{ resId: string; lastName: string }>();

  React.useEffect(() => {
    if (isIOS || isAndroid) window.location.href = `operto-mk://verify/${resId}/${lastName}`;
    if (isIOS) window.location.href = 'https://apps.apple.com/ca/app/apple-store/id375380948';
    if (isAndroid)
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.nianticlabs.pokemongo';
  }, [resId, lastName]);
  return <div>Hellow World MobileKey</div>;
};

export default MobileKey;
