import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ColorPicker } from 'Common/ColorPicker';
import ImageEditor from 'Common/Templates/Editors/ImageEditor';
import { companySelector } from 'company/state/companySelectors';
import React, { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import QRCodeWithLogo from './QRCodeWithLogo';
const guestPortalURL = process.env.REACT_APP_GUEST_PORTAL_URL;
const QRCodeId = 'QR';
export interface QRCodeGeneratorDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
}

export const QRCodeGeneratorDialog = ({ open, onClose, title }: QRCodeGeneratorDialogProps) => {
  const [logoEnabled, setLogoEnabled] = useState(false);
  const [logoImageUrl, setLogoImageUrl] = useState('');
  const [color, setColor] = useState<string>('000');
  const [size, setSize] = useState(140);
  const [errorMessageForSize, setErrorMessageForSize] = useState('');
  const [format, setFormat] = useState('jpg');

  const company = useAppSelector(companySelector());

  const handleLogoEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLogoEnabled(event.target.checked);
  };

  const handleImageUrl = (url: string) => {
    setLogoImageUrl(url);
  };

  const handleSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setErrorMessageForSize('');
      setSize(Number((event.target as HTMLInputElement).value));
    } else {
      setErrorMessageForSize('Invalid number');
    }
  };

  const handleFormat = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormat((event.target as HTMLInputElement).value);
  };

  const handleDownload = () => {
    const canvas = document.getElementById(QRCodeId) as HTMLCanvasElement;
    if (canvas) {
      const imageURL = canvas
        .toDataURL(`image/${format}`)
        .replace(`image/${format}`, 'image/octet-stream');
      const downloadLink = document.createElement('a');
      downloadLink.href = imageURL;
      downloadLink.download = `guest_portal_QR_code.${format}`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const handleClose = () => {
    onClose();
    setLogoEnabled(false);
    setLogoImageUrl('');
    setColor('000');
    setSize(140);
    setFormat('jpg');
    setErrorMessageForSize('');
  };

  if (!company) return;

  return (
    <Dialog open={open} fullWidth data-testid='qr-generator-dialog' maxWidth='md'>
      <DialogTitle>QR Code generator</DialogTitle>
      <DialogContent>
        <Box sx={{ flexGrow: 1, mt: 3 }}>
          <Grid container>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='add_logo_to_QR_code'
                    color='primary'
                    checked={logoEnabled}
                    onChange={handleLogoEnabled}
                  />
                }
                label='Add logo to the QR code.'
                sx={{ mb: logoEnabled ? 2 : 3 }}
              />

              {logoEnabled && <ImageEditor onDrop={handleImageUrl} height={150} />}

              <Stack direction='row' mb={3} mt={logoEnabled && 3}>
                <Stack mr={6}>
                  <Typography>Color</Typography>
                  <ColorPicker value={color} onChange={value => setColor(value)} />
                </Stack>
                <Stack>
                  <Typography>Size</Typography>
                  <TextField
                    id='size-helper'
                    variant='outlined'
                    size='small'
                    sx={{ width: '120px' }}
                    value={size}
                    onChange={handleSize}
                    aria-describedby='size-helper-text'
                    error={!!errorMessageForSize}
                    helperText={errorMessageForSize}
                  />
                </Stack>
              </Stack>

              <FormControl>
                <Typography>File format</Typography>
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='row-radio-buttons-group'
                  value={format}
                  onChange={handleFormat}
                >
                  <FormControlLabel value='jpg' control={<Radio />} label='JPEG' />
                  <FormControlLabel value='png' control={<Radio />} label='PNG' />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item justifyContent='flex-end' xs={4}>
              <Stack sx={{ alignItems: 'flex-end' }}>
                {/* Hide the actual canvas to be captured. */}
                <Stack sx={{ display: 'none' }}>
                  <QRCodeWithLogo
                    url={`${guestPortalURL}/${company.code}/welcome`}
                    imageUrl={logoImageUrl}
                    color={color}
                    size={size}
                    id={QRCodeId}
                  />
                </Stack>
                {/* This QR code is displayed as a preview. It will not change in size and will not be captured. */}
                <QRCodeWithLogo
                  url={`${guestPortalURL}/${company.code}/welcome`}
                  imageUrl={logoImageUrl}
                  color={color}
                  size={140}
                />
                <Stack width={`${size + 20}px`} alignItems='center'>
                  {title && (
                    <Typography variant='subtitle2' sx={{ mt: 1 }}>
                      {title}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          autoFocus
          variant='contained'
          onClick={() => {
            handleDownload();
          }}
          color='primary'
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QRCodeGeneratorDialog;
