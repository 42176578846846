import axios from 'axios';
import { IMember } from 'member/memberType';
import { PasswordData } from 'Pages/Account/TabContainers/ChangePasswordForm';

const api = process.env.REACT_APP_API_URL;

export const getMembers = () => axios.get(`${api}/v2/members`);
export const getMembersByCompanyWithPagination = (
  pageNum = 0,
  numPerPage = 100,
  staff_type?: string,
  status?: string,
  keyword?: string,
  exclude_in_group?: number,
  group_id?: number,
) => {
  let params: Record<string, string | number> = { pageNum, numPerPage };
  if (status) {
    params = { ...params, status };
  }
  if (staff_type) {
    params = { ...params, staff_type };
  }
  if (keyword) {
    params = { ...params, keyword };
  }
  if (exclude_in_group) {
    params = { ...params, exclude_in_group };
  }
  if (group_id) {
    params = { ...params, group_id };
  }
  return axios.get(`${api}/v2/companies/members`, {
    params,
  });
};
export const getMember = (id: number) => axios.get(`${api}/v2/members/${id}`);
export const getCurrentMember = () => axios.get(`${api}/v2/members/self`);
export const createMember = (data: IMember) => axios.post(`${api}/v2/members`, data);
export const updateMember = (data: IMember) => axios.put(`${api}/v2/members/${data.id}`, data);

export const updateMemberEmail = (email: string, memberId: number) =>
  axios.put(`${api}/v2/members/${memberId}/email`, { email });

export const updateAccountHolderPassword = (memberId: number, password: PasswordData) =>
  axios.put(`${api}/v2/members/${memberId}/password`, password);

export const sendMemberPasswordSetupEmail = (memberId: number, email: string) =>
  axios.put(`${api}/v2/members/${memberId}/password-setup`, { email });

export const refreshMemberCode = (memberId: string) =>
  axios.patch(`${api}/v2/members/${memberId}/memberaccess/refresh-access`);

export const uploadMemberProfilePicture = (memberProfilePicture: { image_base64: string }) =>
  axios.patch(`${api}/v2/members/image`, memberProfilePicture);

export const getMemberProfilePicture = (memberId: number) =>
  axios.get(`${api}/v2/members/${memberId}/image`);

export const removeMemberFromGroup = (groupId: number, memberId: number) =>
  axios.delete(`${api}/v2/groups/${groupId}/members/${memberId}`);

export const toggleMemberStatus = (memberId: number, status: boolean) =>
  axios.patch(`${api}/v2/members/${memberId}/memberaccess/status`, { status });

export const getMembersByGroupId = (groupId: number) => {
  return axios.get(`${api}/v2/groups/${groupId}/members`);
};
