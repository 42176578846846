import { useMedia } from '@operto/ui';
import ChannelListItem from 'Common/Lists/ChannelListItem';
import { formatRelativeToTimeZone } from 'helper/date';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Channel } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';
import ProfileIcon from 'ui-library/Components/icon/ProfileIcon';
import { useAppDispatch } from '../../redux/hooks';
import { updateFavouriteAction, updatePropertyAction } from './MessagingActions';

export interface MessageChatPreviewContainerProps {
  previousChannelId?: string;
  channel?: Channel;
  onNewChat?: (newChannel: boolean) => void;
  lastClickedOnChannel?: (channel: Channel) => void;
  hasCurrentChannel?: boolean;
  showFavouriteIcon: boolean;
}

const MessageChanneListItem = (props: MessageChatPreviewContainerProps) => {
  const {
    previousChannelId,
    channel,
    onNewChat,
    lastClickedOnChannel,
    hasCurrentChannel,
    showFavouriteIcon,
  } = props;
  const { isDesktop, isMobile, isTablet } = useMedia();
  const { setActiveChannel } = useChatContext();
  const dispatch = useAppDispatch();
  const favourite = (channel?.data?.favourite as boolean) ?? false;
  const navigate = useNavigate();

  const handleChannelOnClick = (channel: Channel) => {
    onNewChat(false);
    setActiveChannel(channel as never);
    lastClickedOnChannel(channel);

    dispatch(
      updatePropertyAction(channel.id, channel.id.split('-')[1], channel.data?.property_id + ''),
    );
  };

  const calculateNameChars = (name: string): string => {
    if (isDesktop || isMobile) {
      return name?.slice(0, 30) + (name?.length >= 30 ? '...' : '');
    }

    if (isTablet) {
      const maxLength = hasCurrentChannel ? 20 : 90;
      return name?.slice(0, maxLength) + (name?.length >= maxLength ? '...' : '');
    }
  };

  const lastMessageText = channel.state.messages[channel.state.messages.length - 1]?.text || '';
  const titleText = channel.data?.name || 'Channel';
  const lastMessageAt = channel?.data?.last_message_at as string;
  const formattedTime = lastMessageAt ? formatRelativeToTimeZone(lastMessageAt, 'UTC') : '';
  const captionInfo = {
    title: channel?.data?.['property_name'] as string,
    url: `/units/${channel?.data?.['property_id'] as number}`,
  };

  let onClickFavouriteTimeout: NodeJS.Timeout;

  const onClickFavouriteIcon = async () => {
    await dispatch(updateFavouriteAction(channel.id, !favourite));

    if (hasCurrentChannel && channel.id === (previousChannelId as string)) {
      clearTimeout(onClickFavouriteTimeout);
      onClickFavouriteTimeout = setTimeout(() => navigate(`/messenger/${channel.id}`), 300);
    }
  };

  return (
    <ChannelListItem
      title={calculateNameChars(titleText)}
      onClick={() => {
        handleChannelOnClick(channel);
      }}
      captionInfo={captionInfo}
      message={lastMessageText}
      hasNewMessage={channel.countUnread() > 0}
      newMessages={channel.countUnread()}
      iconObject={
        <ProfileIcon
          imageUrl={channel.data?.image as string}
          size={'medium'}
          fullName={channel.data?.name}
        />
      }
      rightSubtext={formattedTime}
      isFavourite={favourite}
      showFavouriteIcon={showFavouriteIcon}
      onClickFavouriteIcon={onClickFavouriteIcon}
    />
  );
};
export default MessageChanneListItem;
