import Menu from '@mui/material/Menu';
import * as React from 'react';
import { SmartCardIconButton } from 'ui-library/Styled/smartcard';

import MenuVerticalIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';

export interface LocationsDropDownProps {
  onEdit?: (params: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  onDelete?: (params: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  isGroupAccess?: boolean;
}

const LocationsDropDown = ({ onEdit, onDelete, isGroupAccess }: LocationsDropDownProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SmartCardIconButton $show size='small' onClick={handleClick}>
        <MenuVerticalIcon />
      </SmartCardIconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <MenuItem
          onClick={params => {
            onEdit(params);
            setAnchorEl(null);
          }}
        >
          {isGroupAccess ? 'View Location' : 'Edit Location'}
        </MenuItem>
        <MenuItem
          onClick={params => {
            onDelete(params);
            setAnchorEl(null);
          }}
        >
          Delete Location
        </MenuItem>
      </Menu>
    </>
  );
};

export default LocationsDropDown;
