import { getSelf } from 'member/state/memberSelectors';
import React, { useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import TabBar, { TabBarProps, TabItems } from 'ui-library/Components/tabBar/TabBar';
import { userStateSelector } from 'user/state/userSelectors';

export const ROOM_TYPE_UNIT_GUEST_TAB = 0;

export const ROOM_TYPE_UNIT_ACTIVITY_TAB = 1;

export const ROOM_TYPE_UNIT_GUEST_PORTAL_TAB = 2;
export const ROOM_TYPE_UNIT_GUEST_PORTAL_UNIT_DETAILS_TAB = 2.1;
export const ROOM_TYPE_UNIT_GUEST_PORTAL_CUSTOM_HOST_TAB = 2.2;
export const ROOM_TYPE_UNIT_GUEST_PORTAL_CUSTOM_ADDRESS_TAB = 2.3;
export const ROOM_TYPE_UNIT_GUEST_PORTAL_CUSTOM_TEXT_TAB = 2.4;
export const ROOM_TYPE_UNIT_GUEST_PORTAL_CUSTOM_HOUSE_MANUAL_TAB = 2.5;
export const ROOM_TYPE_UNIT_GUEST_PORTAL_CUSTOM_GUIDES_TAB = 2.6;

const tabItems: TabItems[] = [
  { label: 'Guests', value: ROOM_TYPE_UNIT_GUEST_TAB, isDropDown: false },
  { label: 'Activities', value: ROOM_TYPE_UNIT_ACTIVITY_TAB, isDropDown: false },
  {
    label: 'Guest Portal',
    isDropDown: true,
    childItems: [
      {
        label: 'Unit Details',
        value: ROOM_TYPE_UNIT_GUEST_PORTAL_UNIT_DETAILS_TAB,
      },
      {
        label: 'Custom Host',
        value: ROOM_TYPE_UNIT_GUEST_PORTAL_CUSTOM_HOST_TAB,
      },
      {
        label: 'Custom Address',
        value: ROOM_TYPE_UNIT_GUEST_PORTAL_CUSTOM_ADDRESS_TAB,
      },
      {
        label: 'Custom Text',
        value: ROOM_TYPE_UNIT_GUEST_PORTAL_CUSTOM_TEXT_TAB,
      },
      {
        label: 'Custom Guides',
        value: ROOM_TYPE_UNIT_GUEST_PORTAL_CUSTOM_GUIDES_TAB,
      },
    ],
  },
];

const RoomTypeUnitPage = () => {
  const [filterIndex, setFilterIndex] = useState(0);
  const userState = useAppSelector(userStateSelector());
  const isGlobalGuestPortalEnabled = userState?.features?.mp_guest_portal_onboarding_enabled;
  const self = useAppSelector(getSelf());
  const isGuestPortalEnabled = self?.role !== 'basic-user';
  const navigate = useNavigate();
  const { roomType, propertyId } = useParams();

  const setTabIndex = (tabValue: number) => {
    // truncate decimal points to detect which tab you are
    const integerTabValue = Math.floor(tabValue);

    selectFilter(integerTabValue);
  };

  const selectFilter = (index: number) => {
    switch (index) {
      case ROOM_TYPE_UNIT_GUEST_TAB:
        navigate(`/room-types/${roomType}/${propertyId}/guests`);
        setFilterIndex(ROOM_TYPE_UNIT_GUEST_TAB);
        break;

      case ROOM_TYPE_UNIT_ACTIVITY_TAB:
        navigate(`/room-types/${roomType}/${propertyId}/activities`);
        setFilterIndex(ROOM_TYPE_UNIT_ACTIVITY_TAB);
        break;

      case ROOM_TYPE_UNIT_GUEST_PORTAL_UNIT_DETAILS_TAB:
        navigate(`/room-types/${roomType}/${propertyId}/guest-portal/unit-details`);
        setFilterIndex(ROOM_TYPE_UNIT_GUEST_PORTAL_TAB);
        break;

      case ROOM_TYPE_UNIT_GUEST_PORTAL_CUSTOM_HOST_TAB:
        navigate(`/room-types/${roomType}/${propertyId}/guest-portal/custom-host`);
        setFilterIndex(ROOM_TYPE_UNIT_GUEST_PORTAL_TAB);
        break;

      case ROOM_TYPE_UNIT_GUEST_PORTAL_CUSTOM_ADDRESS_TAB:
        navigate(`/room-types/${roomType}/${propertyId}/guest-portal/custom-address`);
        setFilterIndex(ROOM_TYPE_UNIT_GUEST_PORTAL_TAB);
        break;

      case ROOM_TYPE_UNIT_GUEST_PORTAL_CUSTOM_TEXT_TAB:
        navigate(`/room-types/${roomType}/${propertyId}/guest-portal/custom-text`);
        setFilterIndex(ROOM_TYPE_UNIT_GUEST_PORTAL_CUSTOM_TEXT_TAB);
        break;

      case ROOM_TYPE_UNIT_GUEST_PORTAL_CUSTOM_HOUSE_MANUAL_TAB:
        navigate(`/room-types/${roomType}/${propertyId}/guest-portal/custom-house-manual`);
        setFilterIndex(ROOM_TYPE_UNIT_GUEST_PORTAL_TAB);
        break;

      case ROOM_TYPE_UNIT_GUEST_PORTAL_CUSTOM_GUIDES_TAB:
        navigate(`/room-types/${roomType}/${propertyId}/guest-portal/guides`);
        setFilterIndex(ROOM_TYPE_UNIT_GUEST_PORTAL_TAB);
        break;

      default:
        navigate(`/room-types`);
        setFilterIndex(ROOM_TYPE_UNIT_GUEST_TAB);
    }
  };

  const fileteringTabItems = () => {
    if (isGlobalGuestPortalEnabled && isGuestPortalEnabled) {
      return tabItems;
    }
    return tabItems.filter(tabItems => tabItems.label !== 'Guest Portal');
  };

  return (
    <>
      <TabBar
        tabItems={fileteringTabItems()}
        tabIndex={filterIndex}
        onTabChange={setTabIndex}
        onSubTabChange={selectFilter as TabBarProps['onSubTabChange']}
      />
      <Outlet />
    </>
  );
};

export default RoomTypeUnitPage;
