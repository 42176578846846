// @ts-nocheck

import Menu from '@mui/material/Menu';
import * as React from 'react';
import { BaseContainer } from 'Style/Components/BaseComponents';
import styled from 'styled-components';
import { SmartCardIconButton } from 'ui-library/Styled/smartcard';

import MenuVerticalIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import { toggleSlidebar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SlidebarType } from 'types/ui';

export interface GuestCodeDropDownProps {
  reservationId?: number;
  accessCodeId?: number;
  checkin?: string;
  checkout?: string;
  propertyId: number;
}

const GuestCodeDropDown = ({
  reservationId,
  accessCodeId,
  propertyId,
  checkin,
  checkout,
}: GuestCodeDropDownProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReservationEdit = () => {
    dispatch(
      toggleSlidebar(SlidebarType.EDIT_ACCESS_CODES, {
        accessCodeId,
        checkin,
        checkout,
        propertyId,
      }),
    );
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MainContainer>
      <SmartCardIconButton $show size='small' onClick={handleClick}>
        <MenuVerticalIcon />
      </SmartCardIconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
      >
        <MenuItem
          disabled={!reservationId}
          onClick={() => {
            handleReservationEdit();
          }}
        >
          Edit Reservation
        </MenuItem>
      </Menu>
    </MainContainer>
  );
};

const MainContainer = styled(BaseContainer)``;

export default GuestCodeDropDown;
