import { Box, Typography } from '@mui/material';
import { FormContainer } from 'Common/FormContainer';
import WarningIcon from 'Common/Icons/WarningIcon';
import { PMSDisconnectMessage } from 'Common/ToolTip/TooltipMessages';
import { getCheckinCodeByResId } from 'code/state/guestCodesSelectors';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import addHours from 'date-fns/addHours';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isFuture from 'date-fns/isFuture';
import { guestsByIdSelector } from 'guest/state/guestSelectors';
import { DATE_FORMAT, timeZoneToUTC, utcStringToDate, utcToTimeZone } from 'helper/date';
import { getProperty } from 'property/state/propertySelectors';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { GuestProfile } from 'redux/guest-service/types';
import { useAppSelector } from 'redux/hooks';
import { IReservation } from 'reservation/reservationType';
import { getReservationById } from 'reservation/state/reservationSelectors';
import AddCard from 'ui-library/Components/card/AdderCard';
import {
  FormTextField,
  FormTextFieldRegexEmail,
  FormTextFieldRegexPhone,
} from 'ui-library/Components/input/FormTextField';
import { FormHeader } from 'ui-library/Styled/sidebar';
import GuestProfileCard from '../Overview/GuestProfileCard';
import { GuestTermsCard } from '../Overview/GuestTermsCard';

export const useMinCheckInOutDateString = (timezone: string, dateString?: string) => {
  const getMinCheckInOutDateStrings = (startDate: Date) => {
    const minCheckInStr = format(startDate, DATE_FORMAT);
    const minCheckOutStr = format(addHours(startDate, 1), DATE_FORMAT);
    return [minCheckInStr, minCheckOutStr];
  };

  return useMemo(() => {
    let startDateInUTC = dateString ? utcStringToDate(dateString) : new Date();
    if (isFuture(startDateInUTC)) {
      startDateInUTC = new Date();
    }

    return getMinCheckInOutDateStrings(utcToZonedTime(startDateInUTC, timezone));
  }, [dateString, timezone]);
};

// NOTE: data structure is messed up. We actually prioritize IGuest data over IReservation data.
// some helper hook to contruct a needed data for reservation
const useReservationSelector = (id?: number) => {
  const { pathname } = useLocation();
  const params = useParams<{ propertyId: string }>();
  const propertyId = params?.propertyId || pathname.match(/\d+/g)?.shift();
  const reservation = useAppSelector(getReservationById(id));
  const guest = useAppSelector(guestsByIdSelector(id));
  const code = useAppSelector(getCheckinCodeByResId(id));
  const property = useAppSelector(getProperty(Number(reservation?.property_id ?? propertyId)));
  const guest_email = guest?.email ?? reservation?.guest_email;
  const guest_phone = guest?.phone ?? reservation?.guest_phone;

  return useMemo(
    () => ({
      isNew: id === undefined,
      reservation: {
        ...reservation,
        id: guest?.id ?? reservation?.id ?? id,
        property_id: Number(reservation?.property_id ?? propertyId),
        guest_name: guest?.name ?? reservation?.guest_name,
        guest_email: FormTextFieldRegexEmail.test(guest_email) ? guest_email : '',
        guest_phone: FormTextFieldRegexPhone.test(guest_phone) ? guest_phone : '',
        check_in: code?.valid_from ?? guest?.check_in ?? reservation?.check_in,
        check_out: code?.valid_until ?? guest?.check_out ?? reservation?.check_out,
        timezone: property?.timezone ?? guest?.timezone ?? 'America/Vancouver',
        hasOverWrittenDuration: code?.over_written_duration ?? false,
      },
    }),
    [
      code?.over_written_duration,
      code?.valid_from,
      code?.valid_until,
      guest?.check_in,
      guest?.check_out,
      guest?.name,
      guest?.id,
      guest?.timezone,
      guest_email,
      guest_phone,
      id,
      property?.timezone,
      propertyId,
      reservation,
    ],
  );
};

export type ReservationFormProps = {
  reservationId?: number;
  submitting?: boolean;
  guestProfile?: GuestProfile;
  onUnlinkSuccess?: (message: string) => void;
  onSubmit: (data: IReservation) => void;
  handleClose: () => void;
};

export const ReservationForm = ({
  reservationId,
  submitting,
  guestProfile,
  onUnlinkSuccess,
  onSubmit,
  handleClose,
}: ReservationFormProps) => {
  const { reservation, isNew } = useReservationSelector(reservationId);

  const {
    handleSubmit,
    reset,
    watch,
    control,
    formState: { isDirty, isValid },
  } = useForm<IReservation>({ mode: 'all' });
  const [minCheckIn, minCheckOut] = useMinCheckInOutDateString(
    reservation.timezone,
    reservation.check_in,
  );
  const hasStarted = isBefore(utcStringToDate(reservation.check_in), new Date());

  let accessSource = 'PMS';
  if (reservation?.source === 'dashboard' || !reservation?.source) {
    accessSource = 'Manual';
  }

  const validateCheckInDate = (checkIn: string) => {
    const checkInDate = new Date(checkIn);
    const compareDate = new Date(minCheckIn);
    if (isBefore(checkInDate, compareDate)) {
      return `${isNew ? 'Start' : 'Access valid from'} date cannot be in the past`;
    }

    return true;
  };

  const validateCheckOutDate = (checkOut: string) => {
    const checkInDate = new Date(watch('check_in'));
    const minCheckOutDate = new Date(minCheckOut);
    const checkOutDate = new Date(checkOut);
    if (!isAfter(checkOutDate, checkInDate)) {
      if (isNew) {
        return 'End date cannot be before start date';
      }

      return 'Access valid until time cannot be before the access valid from time';
    }

    if (isBefore(checkOutDate, minCheckOutDate)) {
      return `End date cannot be before ${format(minCheckOutDate, DATE_FORMAT)}`;
    }

    return true;
  };

  useEffect(() => {
    if (reservation) {
      const localCheckInTime = reservation.check_in
        ? utcToTimeZone(reservation.check_in, reservation.timezone)
        : minCheckIn;
      const localCheckOutTime = reservation.check_out
        ? utcToTimeZone(reservation.check_out, reservation.timezone)
        : minCheckOut;

      reset({
        ...reservation,
        check_in: localCheckInTime,
        check_out: localCheckOutTime,
      });
    }
  }, [minCheckOut, minCheckIn, reservation, reset]);

  return (
    <FormContainer
      title={isNew ? 'Create new reservation' : 'Guest details'}
      loading={submitting}
      dirty={isValid && isDirty}
      onClose={handleClose}
      onSubmit={handleSubmit((data: IReservation) => {
        onSubmit({
          ...reservation,
          ...data,
          guest_name: data.guest_name.trim(),
          check_in: timeZoneToUTC(data.check_in, data.timezone, 'yyyy-MM-dd HH:mm:ss'),
          check_out: timeZoneToUTC(data.check_out, data.timezone, 'yyyy-MM-dd HH:mm:ss'),
        });
      })}
    >
      <AddCard title='Reservation'>
        <Typography mb={1} variant='subtitle1'>
          Guest profile
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Typography variant='body2'>{accessSource}</Typography>
          {reservation?.hasOverWrittenDuration && (
            <WarningIcon tooltipTitle={PMSDisconnectMessage} />
          )}
        </Box>
        {isNew && <FormHeader align='left'>Input the Guest Details</FormHeader>}
        <FormTextField
          rules={{
            required: {
              value: true,
              message: 'Guest name is required',
            },
          }}
          field='guest_name'
          label='Guest full name'
          disabled={!isNew && reservation?.source !== 'dashboard'}
          control={control}
        />
        <FormTextField
          rules={{
            pattern: {
              value: FormTextFieldRegexEmail,
              message: 'Valid email address format is required',
            },
          }}
          field='guest_email'
          type='email'
          label='Email'
          disabled={!isNew && reservation?.source !== 'dashboard'}
          control={control}
        />
        <FormTextField
          rules={{
            pattern: {
              value: FormTextFieldRegexPhone,
              message: 'Valid phone number format is required',
            },
          }}
          field='guest_phone'
          type='tel'
          label='Phone'
          disabled={!isNew && reservation?.source !== 'dashboard'}
          control={control}
        />
        <Typography my={1} variant='subtitle1'>
          {isNew ? 'Reservation details' : 'Access code'}
        </Typography>
        {isNew && <FormHeader align='left'>Input the Reservation Dates</FormHeader>}
        <FormTextField
          rules={{
            required: {
              value: true,
              message: `${isNew ? 'Start date' : 'Access valid from'} is required`,
            },
            validate: validateCheckInDate,
          }}
          inputProps={{ min: minCheckIn }}
          field='check_in'
          type='datetime-local'
          label={isNew ? 'Start date' : 'Access valid from'}
          helperText={hasStarted ? 'Access code valid from has started' : undefined}
          disabled={hasStarted}
          control={control}
        />
        <FormTextField
          rules={{
            required: {
              value: true,
              message: `${isNew ? 'End date' : 'Access valid until'} is required`,
            },
            validate: validateCheckOutDate,
          }}
          inputProps={{ min: minCheckOut }}
          field='check_out'
          type='datetime-local'
          label={isNew ? 'End date' : 'Access valid until'}
          control={control}
        />
      </AddCard>
      {guestProfile && (
        <GuestProfileCard
          guestProfile={guestProfile}
          reservation={reservation}
          onUnlinkSuccess={onUnlinkSuccess}
        />
      )}
      {!isNew && <GuestTermsCard guestId={reservation.id} />}
    </FormContainer>
  );
};

export default ReservationForm;
