import { Autocomplete, Chip, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { Tag } from '@operto/tags-shared';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React from 'react';

type MemberTagsFieldProps = {
  selectedTags: Tag[];
  tagOptions: Tag[];
  onTagsChange: (event: React.SyntheticEvent, value: string[]) => void;
};

export const MemberTagsField = ({
  selectedTags,
  tagOptions,
  onTagsChange,
}: MemberTagsFieldProps) => {
  const tagError = (inputValue: string, selectedTagsLength: number): boolean => {
    return selectedTagsLength >= 10 && inputValue.trim() !== '';
  };

  return (
    <Autocomplete
      multiple
      id='tags-filled'
      options={tagOptions}
      defaultValue={selectedTags?.map(tag => tag.label) ?? []}
      value={selectedTags?.map(tag => tag.label).sort((a, b) => a.localeCompare(b)) ?? []}
      freeSolo={selectedTags?.length < 10}
      disableClearable
      clearOnBlur
      onChange={onTagsChange}
      getOptionDisabled={() => selectedTags.length === 10}
      componentsProps={{
        paper: {
          sx: {
            borderRadius: '4px',
          },
        },
        popper: {
          sx: {
            overflow: 'scroll',
            maxHeight: '196px',
            boxShadow: 4,
          },
        },
      }}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip
            key={index}
            variant='outlined'
            label={option}
            sx={{
              color: 'primary.main',
              backgroundColor: 'primary.p8',
              borderColor: 'text.white',
              '& .MuiChip-deleteIcon': { color: '#155EFF80 !important' },
            }}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option, inputValue, { insideWords: true });
        const parts = parse(option, matches);
        return (
          <ListItem {...props} key={option} sx={{ height: '36px' }}>
            <ListItemText>
              {parts.map((part, index) => (
                <Typography
                  key={index}
                  component='span'
                  sx={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </Typography>
              ))}
            </ListItemText>
          </ListItem>
        );
      }}
      renderInput={params => {
        const inputValue = params.inputProps.value as string;
        return (
          <TextField
            {...params}
            helperText={'Maximum of 10 tags, each with up to 32 characters'}
            error={tagError(inputValue, selectedTags?.length)}
            sx={{ margin: 0, overflow: 'scroll' }}
            inputProps={{ ...params.inputProps, maxLength: 32 }}
            data-testid='tags-text-field'
          />
        );
      }}
    />
  );
};
