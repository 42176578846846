import axios from 'axios';
import { ThermostatSetting } from 'types/thermostats';

const opertoApi = process.env.REACT_APP_API_URL;

export const getTempSettingByPropertyIdEcobee = (propertyId: number) =>
  axios.get(`${opertoApi}/v2/properties/${propertyId}/thermostats/settings-ecobee`);

export const getTempSettingByPropertyIdTadoHeating = (propertyId: number) =>
  axios.get(`${opertoApi}/v2/properties/${propertyId}/thermostats/settings-tado-heating`);

export const getTempSettingByPropertyIdTadoAirConditioning = (propertyId: number) =>
  axios.get(`${opertoApi}/v2/properties/${propertyId}/thermostats/settings-tado-air-conditioning`);

export const updateTempSettingByPropertyIdEcobee = (
  propertyId: number,
  setting: ThermostatSetting,
) =>
  axios.put(
    `${opertoApi}/v2/properties/${propertyId}/thermostats/settings-ecobee/${setting.id}`,
    setting,
  );

export const updateTempSettingByPropertyIdTadoHeating = (
  propertyId: number,
  setting: ThermostatSetting,
) =>
  axios.put(
    `${opertoApi}/v2/properties/${propertyId}/thermostats/settings-tado-heating/${setting.id}`,
    setting,
  );

export const updateTempSettingByPropertyIdTadoAirConditioning = (
  propertyId: number,
  setting: ThermostatSetting,
) =>
  axios.put(
    `${opertoApi}/v2/properties/${propertyId}/thermostats/settings-tado-air-conditioning/${setting.id}`,
    setting,
  );
