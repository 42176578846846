import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { Badge, Box, Divider, SxProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Text, theme } from '@operto/ui';
import { LinkedAccountData, getLinkedAccounts } from 'auth/LinkedAccountsHelper';
import { ICompany } from 'company/companyType';
import MemberProfile from 'member/memberProfileGetter';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { toggleSlidebar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SlidebarType } from 'types/ui';
import { logout } from 'user/state/userActions';
import { currentUserSelector } from 'user/state/userSelectors';

interface LinkedAccountCompanySelectorProps {
  company: ICompany;
  onAccountClicked: (linkedAccount: LinkedAccountData) => void;
}

const ProfileDropDown = ({ onAccountClicked }: LinkedAccountCompanySelectorProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const currentUser = useAppSelector(currentUserSelector());
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [profileCache, setProfileCache] = React.useState('');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const linkedAccounts: LinkedAccountData[] = getLinkedAccounts().sort(val => {
    return val.isLoggedIn ? -1 : 1;
  });

  const handleAccountClicked = (linkedAccount: LinkedAccountData) => {
    onAccountClicked(linkedAccount);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClicked = () => {
    handleClose();
    dispatch(toggleSlidebar(SlidebarType.EDIT_MEMBERS, currentUser));
  };

  const handleSettingsClicked = () => {
    handleClose();
    navigate('/settings/account');
  };

  const handleLogOutClick = () => {
    dispatch(logout());
    handleClose();
    if (currentUser?.is_migrated_to_cognito) {
      window.location.href = process.env.REACT_APP_AMPLIFY_APP_AUTH_URL;
      return;
    }
    navigate('/login');
  };

  const onLoad = React.useCallback(
    (base64Data: string) => {
      setProfileCache(base64Data);
    },
    [setProfileCache],
  );

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{ padding: '4px' }}
        data-testid='linked-account-menu-item'
      >
        <MemberProfile
          size='large'
          memberData={currentUser}
          cachedProfile={profileCache}
          onLoad={onLoad}
        />
      </IconButton>

      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={handleProfileClicked} divider sx={{ marginBottom: 1 }}>
          <IconButton size='medium' onClick={handleClick}>
            <MemberProfile size='large' memberData={currentUser} cachedProfile={profileCache} />
          </IconButton>
          <Box>
            <Text>{currentUser?.name}</Text>
            <Text variant='body-sm-400'>{currentUser?.email}</Text>
          </Box>
        </MenuItem>
        {linkedAccounts?.map((account: LinkedAccountData, index) => {
          return (
            <MenuItem
              onClick={() => {
                handleAccountClicked(account);
              }}
              sx={AccountsList}
              key={`${account.name}-${index}`}
            >
              <Text>
                <Badge
                  sx={!account.expired ? BadgeActiveStyle : BadgeNotActiveStyle}
                  overlap='circular'
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant='dot'
                />
                {account?.name}
              </Text>
              <Text variant='body-sm-400'>{account?.email}</Text>
            </MenuItem>
          );
        })}
        <Divider />
        <MenuItem onClick={handleSettingsClicked}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogOutClick}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          Log Out
        </MenuItem>
      </Menu>
    </>
  );
};

const BadgeActiveStyle: SxProps = {
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    marginRight: '9px',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
};
const BadgeNotActiveStyle: SxProps = {
  '& .MuiBadge-badge': {
    backgroundColor: '#a4a4a4',
    marginRight: '9px',
    color: '#969696',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
};

const AccountsList: SxProps = {
  marginBottom: 1,
  width: '100%',
  padding: '10px 20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
};

export default ProfileDropDown;
