import { MoreHorizOutlined } from '@mui/icons-material';
import { AvatarGroup, Box, IconButton, Stack, SxProps, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { featurePermission } from 'helper/helper';
import { trackEvent } from 'lib/analytics';
import { logger } from 'lib/logger';
import { membersByIdsSelector } from 'member/state/memberSelectors';
import { getPropertiesFromIds } from 'property/state/propertySelectors';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toggleSnackbar } from 'redux/actions/ui';
import { useDeleteGroupMutation } from 'redux/groups/api-slice';
import { Group } from 'redux/groups/types';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import ProfileIcon from 'ui-library/Components/icon/ProfileIcon';
import { currentUserSelector } from 'user/state/userSelectors';
import { DeleteGroupDialog } from './Common/DeleteGroupDialog';

const groupCardBoxStyles: SxProps = {
  display: 'flex',
  padding: '16px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '140px',
  minWidth: '268px',
};

const cellMenuIconStyles: SxProps = {
  width: '32px',
  height: '32px',
};

const computeCellStackStyles = (isRow: boolean, sx?: SxProps): SxProps => {
  return {
    width: '100%',
    flexDirection: isRow ? 'row' : 'column',
    justifyContent: 'space-between',
    ...sx,
  };
};

const VIEW_DETAILS = 'View details';
const DELETE = 'Delete';

export const groupCellMenuOptions = [VIEW_DETAILS, DELETE] as const;

type GroupCellMenuProps = {
  group: Group;
  disable?: boolean;
  onMenuClick?: (menu: string) => void;
};

const GroupCellMenu = ({ group, onMenuClick, disable = false }: GroupCellMenuProps) => {
  const dispatch = useAppDispatch();
  const [deleteGroup, { isLoading }] = useDeleteGroupMutation();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const user = useAppSelector(currentUserSelector());
  const viewGroupPermission = featurePermission(user?.role, 'groups', 'view');
  const deleteGroupPermission = featurePermission(user?.role, 'groups', 'delete');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (menuOption: string) => {
    setAnchorEl(null);

    if (menuOption === VIEW_DETAILS) {
      onMenuClick(VIEW_DETAILS);
    } else if (menuOption === DELETE) {
      setOpenDeleteConfirmation(true);
    }
  };

  const handleDeleteGroup = async () => {
    let success = true;
    try {
      await deleteGroup(group).unwrap();
      trackEvent({
        event: 'DELETED',
        screen: 'DELETE GROUP',
      });
      setOpenDeleteConfirmation(false);
    } catch (error) {
      success = false;
      logger.error(`Error deleting group: ${group.name}, error:`, error);
    }

    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `${success ? 'Successfully deleted' : 'Error deleting'} group ${group.name}`,
        variant: success ? SnackbarVariant.SUCCESS : SnackbarVariant.ERROR,
      }),
    );
  };

  return (
    <>
      <IconButton
        disabled={disable}
        data-testid='group-cell-menu-icon-dots'
        onClick={handleClick}
        sx={cellMenuIconStyles}
      >
        <MoreHorizOutlined />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
      >
        {groupCellMenuOptions.map(menuOption => (
          <MenuItem
            key={menuOption}
            onClick={() => handleClose(menuOption)}
            disabled={
              (menuOption === DELETE && !deleteGroupPermission) ||
              (menuOption === VIEW_DETAILS && !viewGroupPermission)
            }
          >
            {menuOption}
          </MenuItem>
        ))}
      </Menu>

      <DeleteGroupDialog
        group={group}
        open={openDeleteConfirmation}
        loading={isLoading}
        onCancel={() => setOpenDeleteConfirmation(false)}
        onDelete={handleDeleteGroup}
      />
    </>
  );
};

interface GroupsCellProps {
  group: Group;
  disableMenu?: boolean;
  onCellClick?: (group: Group) => void;
}

export const GroupCell = ({ group, disableMenu = false, onCellClick }: GroupsCellProps) => {
  const { name, description, propertyIds = [], memberIds = [] } = group;
  const groupProperties = useAppSelector(getPropertiesFromIds(propertyIds));
  const groupMembers = useAppSelector(membersByIdsSelector(memberIds));
  const totalProperties = groupProperties.length;
  const totalMembers = groupMembers.length;
  const navigate = useNavigate();

  const onMenuClick = (menu: string) => {
    if (menu === VIEW_DETAILS) {
      navigate(`/groups/${group.id}/units`);
    }
  };

  return (
    <Box sx={groupCardBoxStyles} onClick={() => onCellClick?.(group)}>
      <Stack sx={computeCellStackStyles(true)}>
        <Typography noWrap variant='h4-700'>
          {name}
        </Typography>
        {<GroupCellMenu disable={disableMenu} onMenuClick={onMenuClick} group={group} />}
      </Stack>

      {description && (
        <Typography noWrap variant='caption-lg-400'>
          {description}
        </Typography>
      )}

      <Stack sx={computeCellStackStyles(true, { marginTop: '16px' })}>
        <Stack sx={computeCellStackStyles(false)}>
          <Typography variant='caption-lg-400'>
            {totalProperties} {totalProperties === 1 ? 'Unit' : 'Units'}
          </Typography>
          <Typography variant='caption-lg-400'>
            {totalMembers} {totalMembers === 1 ? 'Member' : 'Members'}
          </Typography>
        </Stack>

        <AvatarGroup sx={avatarGroupStyle} max={5}>
          {groupMembers.map(
            groupMember =>
              groupMember && (
                <ProfileIcon
                  key={groupMember.id}
                  imageUrl={groupMember.profile_picture_url}
                  fullName={groupMember.name}
                  useRandomColor
                />
              ),
          )}
        </AvatarGroup>
      </Stack>
    </Box>
  );
};

const avatarGroupStyle: SxProps = {
  '& .MuiAvatar-root': {
    width: '38px',
    height: '38px',
    fontSize: '1rem',
  },
};
