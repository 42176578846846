import {
  CloseOutlined,
  DeleteOutlineOutlined,
  InfoOutlined,
  LinearScale,
  ModeEditOutlined,
} from '@mui/icons-material';
import {
  TitlebarActionIcon,
  TitlebarActionIconContainer,
  TitlebarBreadCrumb,
  TitlebarBreadCrumbChild,
  TitlebarButton,
  TitlebarRoot,
  TitlebarTitle,
  TitlebarTitleButton,
  TitlebarTitleIcon,
} from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import IOSSwitch from '../../../Common/IOSSwitch/IOSSwitch';
import AutomateInfoDialog from '../AutomateInfoDialog';

export default function AutomateWorkflowTitlebar({
  title,
  onTitleChange,
  onSaveClick,
  onDeleteClick,
  deleteDisabled,
  saveDisabled,
  helperText,
  editMode,
  setEditMode,
  onEnableWorkflowClick,
  isSaving,
  isWorkflowEnabled,
}: AutomateWorkflowTitlebarProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [prevTitle, setPrevTitle] = React.useState(title);

  const crumbs: TitlebarBreadCrumbChild[] = [
    {
      name: t('home'),
      href: '/',
    },
    {
      name: t('automate'),
      href: '/automate',
    },
  ];

  const [showInformationDialog, setShowInformationDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleInformationClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setShowInformationDialog(true);
  };

  return (
    <TitlebarRoot>
      <TitlebarTitleIcon>
        <LinearScale />
      </TitlebarTitleIcon>

      <TitlebarBreadCrumb crumbs={crumbs} onClick={crumb => navigate(crumb.href)}>
        <TitlebarTitle
          title={title}
          editMode={editMode}
          helperText={helperText}
          onChange={e =>
            onTitleChange(
              (e as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>).target.value,
            )
          }
        >
          {editMode ? (
            <TitlebarTitleButton
              onClick={() => {
                onTitleChange(prevTitle);
                setEditMode(false);
              }}
            >
              <CloseOutlined />
            </TitlebarTitleButton>
          ) : (
            <TitlebarTitleButton
              onClick={() => {
                setEditMode(true);
                setPrevTitle(title);
              }}
            >
              <ModeEditOutlined />
            </TitlebarTitleButton>
          )}
        </TitlebarTitle>
      </TitlebarBreadCrumb>

      <TitlebarActionIconContainer alignItems='center'>
        <TitlebarActionIcon
          tooltip={t('more_information')}
          onClick={event => handleInformationClick(event)}
        >
          <InfoOutlined />
        </TitlebarActionIcon>
        {showInformationDialog && (
          <AutomateInfoDialog
            onClose={() => {
              setShowInformationDialog(false);
              setAnchorEl(null);
            }}
            open={showInformationDialog}
            anchorEl={anchorEl}
          />
        )}

        <TitlebarActionIcon
          tooltip={t('delete_flow')}
          onClick={onDeleteClick}
          disabled={deleteDisabled}
        >
          <DeleteOutlineOutlined />
        </TitlebarActionIcon>

        <IOSSwitch
          active={isWorkflowEnabled}
          onChange={onEnableWorkflowClick}
          tooltip={isWorkflowEnabled ? t('active') : t('inactive')}
          tooltipSx={{ margin: '0 12px 0 12px' }}
        />
      </TitlebarActionIconContainer>

      <TitlebarButton onClick={onSaveClick} disabled={saveDisabled} loading={isSaving}>
        {t('save')}
      </TitlebarButton>
    </TitlebarRoot>
  );
}

type AutomateWorkflowTitlebarProps = {
  title: string;
  onTitleChange: (title: string) => void;
  onSaveClick: () => void;
  onDeleteClick: () => void;
  deleteDisabled?: boolean;
  saveDisabled?: boolean;
  helperText?: string;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
  onEnableWorkflowClick: () => void;
  isSaving?: boolean;
  isWorkflowEnabled?: boolean;
};
