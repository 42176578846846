import axios from 'axios';

const api = process.env.REACT_APP_API_URL;

export const getProperties = () =>
  axios.get(`${api}/v2/company/properties`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  });
export const getFavoriteProperties = () => axios.get(`${api}/v2/dashboard/property-favorites`);
export const createFavoriteProperty = (propertyId: number) =>
  axios.post(`${api}/v2/properties/${propertyId}/favourite`);
export const deleteFavoriteProperty = (propertyId: number) =>
  axios.delete(`${api}/v2/properties/${propertyId}/favourite`);
export const getPropertyEvents = (id: string) =>
  axios.get(`${api}/v2/hub-events?property_id=${id}`);
export const getPropertyReservations = (propertyId: string) =>
  axios.get(`${api}/v2/properties/${propertyId}/reservations`);
export const getPropertyCodes = (propertyId: string, type = 'all') =>
  axios.get(`${api}/v2/properties/${propertyId}/access-code`, { data: { type } });
