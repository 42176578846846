import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { Favorites } from '../../../types/ui';
import { Actions } from '../../../types/actions';

const show: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.favoritesLoading:
      return 'pending';
    case Actions.favoritesSuccess:
      return 'success';
    case Actions.favoritesFailed:
      return 'failed';
    case Actions.removeAddFavorite:
      return 'removed';
    default:
      return state;
  }
};

const id: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.favoritesSuccess:
      return action.id;
    case Actions.favoritesLoading:
      return action.id;
    default:
      return state;
  }
};

const favorites: Reducer<Favorites> = combineReducers<Favorites>({
  show,
  id,
});

export default favorites;
