import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { PROPERTY_NAME_TYPE } from 'reservation/reservationType';

export interface GuestsUnitNameRoomTypeCellProps {
  propertyName: PROPERTY_NAME_TYPE;
  propertyId?: string;
}

const GuestsUnitNameCell = ({ propertyName, propertyId }: GuestsUnitNameRoomTypeCellProps) => {
  const navigate = useNavigate();
  const renderIcon = (propertyName: PROPERTY_NAME_TYPE) => {
    const sxProps = {
      textTransform: 'capitalize',
      color: propertyName === PROPERTY_NAME_TYPE.REQUESTED ? '#ED6C02' : '#0288D1',
      borderColor: propertyName === PROPERTY_NAME_TYPE.REQUESTED ? '#ED6C02' : '#0288D1',
      backgroundColor: 'transparent',
    };

    const sxPropsForUnitName = {
      color: 'rgba(0, 0, 0, 0.87)',
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    };

    const handleViewUnit = () => {
      navigate(`/units/${propertyId}`);
    };

    switch (propertyName) {
      case PROPERTY_NAME_TYPE.REQUESTED:
      case PROPERTY_NAME_TYPE.UNASSIGNED:
        return <Chip label={propertyName} variant='outlined' size='small' sx={sxProps} />;

      default:
        return (
          <Link onClick={handleViewUnit} sx={sxPropsForUnitName}>
            {propertyName}
          </Link>
        );
    }
  };

  return renderIcon(propertyName);
};

export default GuestsUnitNameCell;
