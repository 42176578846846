import NoiseIcon from '@mui/icons-material/VolumeUp';
import { Box, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { INetatmo } from 'device/deviceType';
import { devicesByPropertyIdSelector } from 'device/state/deviceSelector';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { patchThreshold } from 'threshold/state/thresholdAction';
import { getThresholdsByDeviceId } from 'threshold/state/thresholdSelectors';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import Widget from 'ui-library/Components/widget/Widget';
import RiskDisplay, { RiskLevel } from 'ui-library/Components/widget/content/RiskDisplay';
import ShowMoreToggle from 'ui-library/Components/widget/content/ShowMoreToggle';
import ThresholdSetting, {
  ThresholdInputs,
} from 'ui-library/Components/widget/content/ThresholdSetting';
import DeviceTitleDropdown from './DeviceTitleDropdown';
import NoiseFooter from './NoiseFooter';

export interface NoiseCardProps {
  hideFooter?: boolean;
  isOnUnitDashboard?: boolean;
}

export enum NoiseThresholds {
  NoiseMed,
  NoiseHigh,
}

export const MEDIUM_NOISE_THRESHOLD = 30;
export const HIGH_NOISE_THRESHOLD = 60;

const NetatmoNoiseCard = ({ hideFooter, isOnUnitDashboard = false }: NoiseCardProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { propertyId: pidStr } = useParams();
  const propertyId = Number(pidStr);
  const devices = useAppSelector(devicesByPropertyIdSelector(propertyId));
  const netatmoNoiseDevice = devices.filter(device => device.vendor === 'Netatmo') as INetatmo[];
  const [openDetails, setOpenDetails] = useState(false);
  const [medium, setMedium] = useState(MEDIUM_NOISE_THRESHOLD);
  const [high, setHigh] = useState(HIGH_NOISE_THRESHOLD);
  const [currentRisk, setCurrentRisk] = useState(RiskLevel.NONE);
  const [selectedDeviceId, setSelectedDeviceId] = useState(
    netatmoNoiseDevice?.[0]?.data?.device_id || 0,
  );
  const deviceThreshold = useAppSelector(getThresholdsByDeviceId(selectedDeviceId as number));

  // @ts-ignore
  const deviceData = netatmoNoiseDevice.find(d => d.data?.device_id === selectedDeviceId);

  const handleDeviceSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDeviceId(e.target.value);
  };

  const calculateRisk = useCallback(() => {
    if (deviceData?.data?.noise) {
      const current = parseFloat(deviceData.data.noise as string);
      if (current >= high) {
        setCurrentRisk(RiskLevel.HIGH);
      } else if (current > medium && current < high) {
        setCurrentRisk(RiskLevel.MEDIUM);
      } else {
        setCurrentRisk(RiskLevel.LOW);
      }
    } else {
      setCurrentRisk(RiskLevel.NONE);
    }
  }, [deviceData, high, medium]);

  useEffect(() => {
    if (deviceThreshold) {
      const { noiseMed, noiseHigh } = deviceThreshold;
      setMedium(noiseMed?.value);
      setHigh(noiseHigh?.value);
    }
  }, [deviceThreshold]);

  useEffect(() => {
    if (netatmoNoiseDevice?.length > 0) {
      setSelectedDeviceId(netatmoNoiseDevice?.[0].data?.device_id);
    }
  }, [netatmoNoiseDevice, netatmoNoiseDevice.length]);

  useEffect(() => {
    calculateRisk();
  }, [medium, high, calculateRisk]);

  const toggleOpenDetails = () => {
    setOpenDetails(!openDetails);
  };

  const viewMoreDetails = () => {
    navigate(`/units/${propertyId}/devices/noise`);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>, field: ThresholdInputs) => {
    if (field === ThresholdInputs.FirstThreshold) {
      setMedium(Number(e.target.value));
    } else {
      setHigh(Number(e.target.value));
    }
  };

  const handleNetamoSave = (field: ThresholdInputs) => {
    const { noiseMed, noiseHigh } = deviceThreshold;
    const value = field === ThresholdInputs.FirstThreshold ? medium : high;
    const id = field === ThresholdInputs.FirstThreshold ? noiseMed.id : noiseHigh.id;
    // @ts-ignore
    dispatch(patchThreshold(id, value)).then(() => {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Noise threshold updated',
          variant: SnackbarVariant.SUCCESS,
        }),
      );
    });
  };

  const suffix = 'dB';

  if (netatmoNoiseDevice.length === 0) {
    return null;
  }

  return (
    <Widget
      title={
        <DeviceTitleDropdown
          devices={netatmoNoiseDevice}
          onChange={handleDeviceSelect}
          selectedDeviceId={selectedDeviceId as number}
          icon={<NoiseIcon sx={{ color: 'white' }} />}
        />
      }
      open={openDetails}
      isCarouselCard
      isOnUnitDashboard={isOnUnitDashboard}
      smartCardType='device'
      footer={!hideFooter && <NoiseFooter onDetailsClick={viewMoreDetails} />}
      fill='#008480'
    >
      <>
        <Box sx={{ marginBottom: '14px' }}>
          <ShowMoreToggle onClick={toggleOpenDetails} open={openDetails} fill='#008480' />
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline' }}>
            <Typography sx={{ color: 'white' }} variant='h4'>
              {deviceData?.data?.noise !== undefined ? `${deviceData?.data.noise}${suffix}` : 'N/A'}
            </Typography>
          </Box>
          <RiskDisplay riskLevel={currentRisk} />
        </Box>
        {openDetails && (
          <Collapse in={openDetails}>
            <ThresholdSetting
              onChange={handleValueChange}
              suffix={suffix}
              onSave={handleNetamoSave}
              values={[medium, high]}
              labels={['Medium Threshold', 'High Threshold']}
            />
          </Collapse>
        )}
      </>
    </Widget>
  );
};

export default NetatmoNoiseCard;
