import CheckIcon from '@mui/icons-material/Check';
import { GridCellParams, GridColumns } from '@mui/x-data-grid-pro';
import React, { useCallback } from 'react';
import { GPProperty, useGuestPortal, useGuestPortalStatus } from 'redux/guestportal';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import { AssignedUnitsTableActions } from './AssignedUnitsTableActions';

const renderGuestPortalStatus = ({ row }: GridCellParams<unknown, GPProperty>) => {
  return row.guest_portal_status === 'active' && <CheckIcon color='primary' />;
};

const columnsDef = (onGPPreview: (propertyId?: number) => void, smwEnabled: boolean) => {
  const columns: GridColumns<GPProperty> = [
    {
      field: 'name',
      headerName: 'Unit name',
      flex: 2,
    },
    {
      field: 'propertyId',
      headerName: 'Prop. ID',
      flex: 1,
    },
    {
      field: 'location',
      headerName: 'Location',
      flex: 2,
    },
    {
      field: 'guest_portal_status',
      headerName: 'Activated',
      flex: 1,
      renderCell: renderGuestPortalStatus,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }: GridCellParams<unknown, GPProperty>) => {
        return (
          <AssignedUnitsTableActions
            assignedProperty={row}
            smwEnabled={smwEnabled}
            onPreview={() => onGPPreview(row.propertyId)}
          />
        );
      },
    },
  ];

  // add SMW column
  if (smwEnabled) {
    columns.splice(4, 0, {
      field: 'smw_enabled',
      headerName: 'YourKey+ Enabled',
      flex: 2,
    });
  }

  return columns;
};

export type AssignedUnitsTableProps = {
  onGpPreview?: (propertyId: number) => void;
  smwEnabled: boolean;
};

const AssignedUnitsTable = ({ onGpPreview, smwEnabled }: AssignedUnitsTableProps) => {
  const { fetchAssignedProperties, assignedPropertiesRecords, assignedProperties } =
    useGuestPortal();
  const { isLoading } = useGuestPortalStatus();

  const handleFetch = useCallback(
    (pageNum: number, numPerPage: number, searchValue?: string) => {
      fetchAssignedProperties({
        keyword: searchValue,
        newPage: pageNum,
        numPerPage,
        append: pageNum > 0,
      });
    },
    [fetchAssignedProperties],
  );

  return (
    <PaginatedTable
      enableToolbar
      title={'Units'}
      initialState={{ pinnedColumns: { right: ['actions'] } }}
      loading={isLoading}
      rows={assignedPropertiesRecords}
      rowCount={assignedProperties.meta.total_records}
      getRowId={assignedProperty => assignedProperty.propertyId}
      columns={columnsDef(onGpPreview, smwEnabled)}
      onFetch={handleFetch}
    />
  );
};

export default AssignedUnitsTable;
