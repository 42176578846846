import { RTKQapi } from 'redux/rtkq-api';

const appApiVersion = 'v2';

export const PORTAL_GUEST_TAG = 'Portal_Guest';

const portalGuestReservationTag = RTKQapi.enhanceEndpoints({ addTagTypes: [PORTAL_GUEST_TAG] });

export const PortalGuestApi = portalGuestReservationTag.injectEndpoints({
  endpoints: builder => ({
    getGuestId: builder.query({
      query: (reservationId: number) => `${appApiVersion}/reservations/${reservationId}/guest`,
    }),
    unclaimGuestReservation: builder.mutation({
      query: ({ reservationId, guestId }: { reservationId: string; guestId: string }) => ({
        url: `${appApiVersion}/reservations/${reservationId}/guest`,
        method: 'DELETE',
        body: {
          reservationId,
          guestId,
        },
      }),
    }),
  }),
});

export const { useGetGuestIdQuery, useUnclaimGuestReservationMutation } = PortalGuestApi;
