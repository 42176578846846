import { Box, Fab, SxProps } from '@mui/material';
import React from 'react';

interface FabIconProps {
  icon: React.ReactNode;
  styles?: SxProps;
  size: 'small' | 'medium' | 'large';
  color: 'primary' | 'secondary';
  ariaLabel: string;
  onClick: () => void;
}

const FabIcon = ({ icon, styles, size, color, ariaLabel, onClick }: FabIconProps) => {
  return (
    <Box sx={styles}>
      <Fab size={size} color={color} aria-label={ariaLabel} onClick={onClick}>
        {icon}
      </Fab>
    </Box>
  );
};

export default FabIcon;
