import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, IconButton, SxProps, Theme, Toolbar } from '@mui/material';
import { useMedia } from '@operto/ui';
import AlertBell from 'Common/DropDown/AlertBell';
import LinkedAccountCompanySelector from 'Common/DropDown/LinkedAccountCompanySelector';
import MessageDropDown from 'Common/DropDown/MessageDropDown';
import ProfileDropDown from 'Common/DropDown/ProfileDropDown';
import { LinkedAccountData, setActiveAuthAccount } from 'auth/LinkedAccountsHelper';
import { getMessengerSetting } from 'company/state/companyAction';
import { companySelector, messengerSettingSelector } from 'company/state/companySelectors';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as userActions from 'redux/actions/users';
import { useGuestPortal } from 'redux/guestportal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styled from 'styled-components';
import { userStateSelector } from 'user/state/userSelectors';

export interface OpertoTopBarProps {
  drawerWidth: number;
  open: boolean;
  toggle: () => void;
}

const OpertoTopBar = ({ drawerWidth, open, toggle }: OpertoTopBarProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isDesktop, isMobile, isTablet } = useMedia();

  const self = useAppSelector(userStateSelector());
  const { fetchSettings, settings } = useGuestPortal();
  const messengerSetting = useAppSelector(messengerSettingSelector());
  const company = useAppSelector(companySelector());
  const memberMessengerEnabled = messengerSetting.mp_member_messenger_enabled;
  const guestMessengerEnabled = messengerSetting.mp_guest_messenger_enabled;
  const smartButtonMessengerEnabled = messengerSetting.gp_smart_button_messenger_enabled;

  // If guest only wants one way messaging, they still need to receive checkin early messages even if guest messenger is turned off
  const checkinEarlyEnabled = settings?.checkinEarlyEnabled;

  const appBarStyle: SxProps<Theme> = {
    backgroundColor: 'background.paper',
    borderRadius: 0,
    width: isDesktop ? `calc(100% - ${drawerWidth}px)` : '100%',
    borderBottom: theme => `1px solid ${theme.palette.divider}`,
    transition: theme =>
      theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    ...(open && {
      marginLeft: `${drawerWidth}px`,
      transition: theme =>
        theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
    }),
  };

  const toolbarStyle: SxProps<Theme> = {
    backgroundColor: isDesktop ? 'transparent' : 'accentBackground',
    padding: isMobile
      ? '0px 16px!important'
      : isTablet
      ? '0px 24px 0px 16px!important'
      : '0px 24px!important',
  };

  const showMessageDropDown = () => {
    if (!self?.features?.mp_two_way_enabled) {
      return <></>;
    } else if (
      self?.features?.mp_two_way_enabled &&
      !memberMessengerEnabled &&
      !guestMessengerEnabled &&
      !smartButtonMessengerEnabled &&
      !checkinEarlyEnabled
    ) {
      return <></>;
    } else {
      return <MessageDropDown />;
    }
  };

  useEffect(() => {
    dispatch(getMessengerSetting());
    if (company?.guestPortalStatus === 'active') {
      fetchSettings();
    }
  }, [company?.guestPortalStatus, dispatch, fetchSettings]);

  const handleAccountClicked = async (linkedAccount: LinkedAccountData) => {
    if (linkedAccount.isLoggedIn) {
      return;
    }
    if (linkedAccount.expired) {
      if (company.allMembersMigratedToCognito) {
        window.open(process.env.REACT_APP_AMPLIFY_APP_AUTH_URL || '', '_self');
      } else {
        navigate(`/login?acct=${encodeURIComponent(linkedAccount.email)}`);
      }
      return;
    }

    setActiveAuthAccount(linkedAccount.email); //update local storage

    dispatch(userActions.storeToken(linkedAccount.token || linkedAccount.idToken)); //update redux store
    navigate(pathname); // clear search params for this account, let new account load their own data
    window.location.reload(); //reload page which will trigger all new HTTP requests with new(updated) token
  };

  return (
    <AppBar sx={appBarStyle}>
      <Toolbar id='operto-toolbar' sx={toolbarStyle}>
        <ContentSection id='operto-content-section'>
          <IconButton
            aria-label='open drawer'
            onClick={toggle}
            edge='start'
            sx={{
              color: 'rgba(0, 0, 0, 0.54);',
              display: isDesktop ? 'none' : 'flex',
              width: '48px',
              height: '48px',
              borderRadius: '48px',
              margin: 0,
              flex: '0 0 48px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            color='inherit'
          >
            {!open ? <MenuIcon /> : <ChevronLeftIcon />}
          </IconButton>

          <StartSection>
            <LinkedAccountCompanySelector
              company={company}
              drawerOpen={open}
              drawerWidth={drawerWidth}
              handleAccountClicked={handleAccountClicked}
            />
          </StartSection>

          <EndSection>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
              }}
            >
              <AlertBell />
              {showMessageDropDown()}
              <ProfileDropDown onAccountClicked={handleAccountClicked} company={company} />
            </Box>
          </EndSection>
        </ContentSection>
      </Toolbar>
    </AppBar>
  );
};

const ContentSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const StartSection = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1 1 auto;
`;

const EndSection = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 144px;
`;

export default OpertoTopBar;
