// @ts-nocheck

import { Menu, MenuItem, Popover, Switch, SxProps, Typography } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ActionCell } from 'Common/Tables/Properties/TableCells';
import {
  deleteGroup,
  getGroupsPaginated,
  togglePublicAccess,
} from 'location/state/LocationsActions';
import { locationsByIdSelector, locationsMetaSelector } from 'location/state/LocationsSelectors';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toggleSlidebar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { FilterType, Group, PublicAccessState } from 'types/groups';
import { SlidebarType } from 'types/ui';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import LocationsDropDown from './LocationsDropDownMenu';

interface LocationsTableProps {
  groups: Group[];
  isGroupAccess: boolean;
  loading: boolean;
}

const LocationsTable = ({ loading, groups, isGroupAccess }: LocationsTableProps) => {
  const dispatch = useAppDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const [locationObjectId, setLocationObjectId] = useState(0);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const meta = useAppSelector(
    locationsMetaSelector(isGroupAccess ? FilterType.ACCESS_GROUP : FilterType.PROPERTY),
  );
  const [searchValue, setSearchValue] = useState('');
  const locationObj = useAppSelector(locationsByIdSelector(locationObjectId));

  const handleClose = useCallback(() => {
    navigate(isGroupAccess ? '/saltogroups' : '/locations');
  }, [navigate, isGroupAccess]);

  const handleFetch = useCallback(
    (pageNum: number, numPerPage: number, searchValue?: string) => {
      dispatch(
        getGroupsPaginated({
          locationType: isGroupAccess ? FilterType.ACCESS_GROUP : FilterType.PROPERTY,
          pageNum,
          numPerPage,
          keyword: searchValue,
        }),
      );
      // we don't have backend paginated search set up for for locations yet, so this meanwhile
      setSearchValue(searchValue || '');
    },
    [dispatch, isGroupAccess],
  );

  useEffect(() => {
    const path = location.pathname.split('/');
    switch (path.slice(-1)[0]) {
      case 'edit':
        setLocationObjectId(Number(locationId));
        if (locationObj !== undefined) {
          dispatch(toggleSlidebar(SlidebarType.EDIT_LOCATION, locationObj, handleClose));
        }
        break;
      case 'add':
        dispatch(toggleSlidebar(SlidebarType.ADD_NEW_LOCATION, isGroupAccess, handleClose));
        break;
    }
  }, [locationObj, isGroupAccess, location.pathname, locationId, dispatch, handleClose]);

  const handleClick = () => {
    setOpen(!open);
  };

  const renderProperties = (params: GridRenderCellParams) => (
    <div>{params.row.properties?.length || 0}</div>
  );

  const renderMembers = (params: GridRenderCellParams) => {
    return <div>{params.row.member_count || 0}</div>;
  };

  const renderGroupType = (params: GridRenderCellParams) => {
    return <div>{params.row.group_type === null ? 'member' : params.row.group_type}</div>;
  };

  const handleDelete = (params: GridRenderCellParams) => {
    dispatch(deleteGroup(params.row.id));
  };

  const handleEdit = (params: GridRenderCellParams) => {
    dispatch(toggleSlidebar(SlidebarType.EDIT_LOCATION, params.row, handleClose()));
    navigate(`${isGroupAccess ? '/saltogroups' : '/locations'}/${params.row.id}/edit`);
  };

  const handleAdd = () => {
    dispatch(toggleSlidebar(SlidebarType.ADD_NEW_LOCATION, isGroupAccess));
    navigate(`${isGroupAccess ? '/saltogroups' : '/locations'}/add`);
  };

  const renderActions = (params: GridRenderCellParams) => {
    if (isMobile) {
      <ActionCell handleClick={handleClick} />;
    }
    return (
      <LocationsDropDown
        isGroupAccess={isGroupAccess}
        onEdit={() => handleEdit(params)}
        onDelete={() => handleDelete(params)}
      />
    );
  };

  function RenderPublicAccess(params: GridRenderCellParams) {
    const [checked, setChecked] = useState(params.row?.public_access_on);
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
      dispatch(togglePublicAccess(params.row, checked));
    };
    if (params.row?.group_type === 'public') {
      return (
        <div>
          {params.row?.public_access?.state === PublicAccessState.Warning ? (
            <Switch
              checked={checked}
              onChange={handleChange}
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup='true'
              onMouseEnter={handlePopoverOpen}
              inputProps={{ 'aria-label': 'disabled checkbox' }}
              sx={warningSwitchStyles}
            />
          ) : (
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'disabled checkbox' }}
              sx={onOffSwitchStyles}
            />
          )}
          <Popover
            id='mouse-over-popover'
            sx={{
              pointerEvents: 'none',
            }}
            open={
              params.row?.public_access?.state === PublicAccessState.Warning && Boolean(anchorEl)
            }
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={
                params.row?.public_access?.state === PublicAccessState.Warning && Boolean(anchorEl)
              }
              onClose={handlePopoverClose}
              PaperProps={{
                style: {
                  maxHeight: 300,
                  width: '20ch',
                },
              }}
            >
              {params.row?.public_access?.locked?.map((locked: boolean) => (
                <>
                  <MenuItem onClick={handlePopoverClose}>
                    <Typography variant='inherit'>{locked}</Typography>
                  </MenuItem>
                </>
              ))}
              {params.row?.public_access?.unlocked?.map((unlocked: boolean) => (
                <>
                  <MenuItem onClick={handlePopoverClose}>
                    <Typography variant='inherit' style={{ color: '#D8D8D8' }}>
                      {unlocked}
                    </Typography>
                  </MenuItem>
                </>
              ))}
            </Menu>
          </Popover>
        </div>
      );
    } else {
      return 'N/A';
    }
  }

  const columnsDef: GridColumns = [
    { field: 'name', headerName: 'Location Name', width: 250 },
    { field: 'description', headerName: 'Description', flex: 1.5 },
    {
      field: 'group_type',
      headerName: 'Group Type',
      flex: 1,
      renderCell: renderGroupType,
      sortable: true,
    },
    {
      field: 'formattedTime',
      headerName: 'Time Schedule',
      flex: 2,
      sortable: false,
    },
    {
      field: 'properties',
      headerName: 'Units',
      flex: 0.5,
      renderCell: renderProperties,
      sortable: false,
    },
    {
      field: 'members',
      headerName: 'Members',
      flex: 0.5,
      renderCell: renderMembers,
      sortable: false,
    },

    {
      field: 'Actions',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: renderActions,
      type: 'number',
    },
    {
      field: 'id',
      headerName: 'Public Access',
      flex: 0.7,
      renderCell: () => {
        return <RenderPublicAccess />;
      },
      sortable: false,
    },
  ];

  const getVisibleColumns = () => {
    const visibleColumns = {
      name: true,
      description: true,
      group_type: true,
      formattedTime: true,
      properties: true,
      members: true,
      Actions: true,
      id: isGroupAccess,
    };
    if (isMobile) {
      visibleColumns.description = false;
      visibleColumns.formattedTime = false;
      visibleColumns.members = false;
      visibleColumns.group_type = false;
      visibleColumns.id = false;
    }

    return visibleColumns;
  };

  const data = groups.filter(
    (row: Group) =>
      (row.name && row.name.toLowerCase().includes(searchValue.toLowerCase())) ||
      (row.description && row.description.toLowerCase().includes(searchValue.toLowerCase())),
  );

  return (
    <PaginatedTable
      enableToolbar
      initialState={{ pinnedColumns: { right: ['Actions'] } }}
      loading={loading}
      rows={data}
      rowCount={meta.total_records}
      columns={columnsDef}
      columnVisibilityModel={getVisibleColumns()}
      onFetch={handleFetch}
      onAdd={handleAdd}
    />
  );
};

const warningSwitchStyles: SxProps = {
  '& .switchBase.checked': {
    color: 'warning',
  },
  '& .switchBase': {
    color: 'warning',
  },
  '& .switchBase.checked + .track': {
    backgroundColor: 'warning',
    opacity: 0.3,
  },
};

const onOffSwitchStyles: SxProps = {
  '& .switchBase.checked': {
    color: '#19b45a',
  },
  '& .switchBase.checked + .track': {
    backgroundColor: '#19b45a',
    opacity: 0.3,
  },
};

export default LocationsTable;
