import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, ButtonGroup, Paper, SxProps } from '@mui/material';
import { OpertoTheme } from 'App/OpertoTheme';
import DropImage from 'Common/Templates/Editors/ImageEditor';
import { OpertoLogger } from 'Logger/logger';
import React, { useState } from 'react';
import ImageManagerDialog from 'ui-library/Components/dialogs/ImageManagerDialog';

type ImageEditorProps = {
  image: string;

  onSave?: (base64Img: string) => void;
  onDrop?: (base64Img: string) => void;
  onDelete?: () => void;

  ratio?: '1:1' | '3:2' | '4:3' | '2:3';
};
export const ImageEditor: React.FC<ImageEditorProps> = props => {
  const { image, ratio = '1:1', onSave, onDrop, onDelete } = props;

  const [openEdit, setOpenEdit] = useState(false);

  let width = 160;
  const height = 160;
  let ratioNumeric = 1;
  if (ratio === '3:2') {
    ratioNumeric = 1.5;
  } else if (ratio === '4:3') {
    ratioNumeric = 1.333;
  } else if (ratio === '2:3') {
    ratioNumeric = 0.666;
  }
  width *= ratioNumeric;
  return (
    <Box
      sx={{
        width: `${width}px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ImageManagerDialog
        open={openEdit}
        imageCropAspectRatio={ratioNumeric}
        imageUrl={image}
        onClose={() => setOpenEdit(false)}
        onSave={imageData => {
          try {
            onSave?.(imageData);
            setOpenEdit(false);
          } catch (error) {
            const metadata = { imageData };
            OpertoLogger.Log(error);
            OpertoLogger.LeaveBreadCrumb('on save on image manager dialog', metadata, 'state');
          }
        }}
      />
      {!image || image?.length === 0 ? (
        <DropImage item={image} onDrop={imageData => onDrop?.(imageData)} />
      ) : (
        <Paper>
          <Box
            sx={{
              width: `${width}px`,
              height: `${height}px`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                backgroundImage: `url('${image}')`,
                height: '160px',
                width: `${width}px`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
          </Box>
          <ButtonGroup
            sx={{
              width: '100%',
              height: '50px',
              borderTop: `${OpertoTheme.textColorLight} 1px solid !important`,
              borderTopRightRadius: '0 !important',
              borderTopLeftRadius: '0 !important',
            }}
          >
            <Button
              sx={{
                ...ActionButtonSxProps,
                borderRight: `${OpertoTheme.textColorLight} 1px solid !important`,
              }}
              onClick={() => setOpenEdit(true)}
            >
              <EditIcon color='secondary' />
            </Button>
            <Button sx={ActionButtonSxProps} onClick={() => onDelete?.()}>
              <DeleteIcon color='action' />
            </Button>
          </ButtonGroup>
        </Paper>
      )}
    </Box>
  );
};

const ActionButtonSxProps: SxProps = {
  border: 'none',
  width: '50%',
  // @ts-ignore
  color: OpertoTheme.palette.secondary,
};

export default ImageEditor;
