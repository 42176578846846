import * as React from 'react';

import styled from 'styled-components';
import { Divider } from '@mui/material';

export interface PlaceholderProps {
  depth: number;
}

const PlaceholderLine = ({ depth }: PlaceholderProps) => <MainContainer depth={depth} />;

const MainContainer = styled(Divider)<{ depth: number }>`
  && {
    margin-top: 5px;
    margin-right: ${props => (props.depth > 0 ? '30px' : 0)};
    margin-left: ${props => (props.depth > 0 ? '30px' : 0)};
    margin-bottom: 10px;
    color: ${props => props.theme.palette.primary};
    background-color: ${props => props.theme.palette.primary};
  }
`;

export default PlaceholderLine;
