import { GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { INITIAL_STATE, useGetUnitsQuery } from 'redux/units/api-slice';
import { Unit, UnitFilterType } from 'redux/units/types';
import { TableCell } from 'ui-library/Components/table/TableCell';
import { groupNameChipStyle } from '../../../ui-library/Components/table/table-styles';
import { NamedChip } from '../Common/NamedChip';
import { UnitsSelectionTable } from '../Common/UnitsSelectionTable';

const renderUnitName = ({ row }: GridRenderCellParams<unknown, Unit>) => {
  return <TableCell title={row.name} description={row.id} />;
};

const renderChip = ({ row }: GridRenderCellParams<unknown, Unit>) => {
  const groupNames = row.groups?.map(group => group.name) ?? [];
  return <NamedChip names={groupNames} sx={groupNameChipStyle} />;
};

const columnsDef: GridColDef<Unit>[] = [
  { field: 'name', headerName: 'Unit', flex: 1, renderCell: renderUnitName },
  { field: 'groups', headerName: 'Group', flex: 1, sortable: false, renderCell: renderChip },
];

type GroupsFormTabUnitsProps = {
  propertyIds: number[];
  onChange: (propertyIds: number[]) => void;
};

export const GroupsFormTabUnits = ({ propertyIds, onChange }: GroupsFormTabUnitsProps) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'name', sort: 'asc' }]);
  const {
    data: { units } = INITIAL_STATE,
    isLoading,
    isFetching,
  } = useGetUnitsQuery({
    filterType: UnitFilterType.ALL_UNITS,
    numPerPage: 100000,
  });

  return (
    <UnitsSelectionTable
      loading={isLoading || isFetching}
      rows={units}
      columns={columnsDef}
      selectionModel={propertyIds}
      onSelectionChange={onChange}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
    />
  );
};
