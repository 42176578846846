import { Box, Button, Link, Typography } from '@mui/material';
import InfoIcon from 'assets/img/icons/info.svg';
import { IntegrationProvider } from 'integrations/integrationsTypes';
import { PropertyFilterType } from 'property/propertyType';
import { getProperties } from 'property/state/propertyActions';
import { getPropertiesWithFilter } from 'property/state/propertySelectors';
import React, { useEffect, useState } from 'react';
import { togglePanelSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getPanelSnackBar } from 'shared/state/sharedSelectors';
import { SnackbarTypes } from 'types/ui';
import { PanelSnackbarTypes } from '../../types/ui';
import { panelSnackbarStyle } from './useStyles';

interface IMessageProps {
  type?: string;
  message?: string;
  link: string;
}

const PanelSnackbarMessage = ({ type, message, link }: IMessageProps) => {
  if (type == PanelSnackbarTypes.RESYNC) {
    return (
      <Typography style={{ padding: 5 }} variant='subtitle2'>
        Properties are resynced. Please{' '}
        <Link href={link} target='_blank' rel='noopener noreferrer'>
          click here
        </Link>{' '}
        to request to activate your services.
      </Typography>
    );
  }
  return (
    <Typography style={{ padding: 5 }} variant='subtitle2'>
      {message}
    </Typography>
  );
};

const PanelSnackBar = () => {
  const dispatch = useAppDispatch();
  const snackbarState = useAppSelector(getPanelSnackBar);
  const [prevSnackbarState, setPrevSnackbarState] = useState(null);
  const properties = useAppSelector(getPropertiesWithFilter(PropertyFilterType.ALL_PROPERTIES));

  const snackbarAction: SnackbarTypes = snackbarState
    ? (Object.keys(snackbarState ?? {})[0] as SnackbarTypes)
    : SnackbarTypes.CLOSE;
  const snackbar = snackbarState
    ? snackbarState[snackbarAction]
    : { message: '', link: '', type: '', provider: '' };

  useEffect(() => {
    if (snackbarAction !== SnackbarTypes.CLOSE)
      setPrevSnackbarState({
        message: snackbarState[snackbarAction]?.message,
        link: snackbarState[snackbarAction]?.link,
      });
  }, [snackbarAction, snackbarState]);

  useEffect(() => {
    if (
      snackbar?.type == PanelSnackbarTypes.CONNECT &&
      snackbar?.provider == IntegrationProvider.Guesty
    ) {
      dispatch(getProperties());
    }
  }, [dispatch, snackbar?.provider, snackbar?.type]);

  const handleClose = () => {
    if (snackbar.type == PanelSnackbarTypes.CONNECT) {
      if (snackbar.link) {
        window.open(snackbar.link, '_blank');
      }
    }

    if (snackbar?.provider == IntegrationProvider.Guesty && properties.length === 0) return;

    dispatch(togglePanelSnackbar(SnackbarTypes.CLOSE, prevSnackbarState));
  };

  if (snackbarAction !== SnackbarTypes.OPEN) {
    return null;
  }

  return (
    <Box sx={panelSnackbarStyle}>
      <InfoIcon />

      <PanelSnackbarMessage message={snackbar.message} link={snackbar.link} type={snackbar.type} />

      <Button
        color='inherit'
        size='small'
        onClick={() => handleClose()}
        style={{ position: 'absolute', right: 0 }}
      >
        {snackbar.type == PanelSnackbarTypes.CONNECT ? 'REQUEST' : 'CLOSE'}
      </Button>
    </Box>
  );
};

export default PanelSnackBar;
