// @ts-nocheck

import { Grid, Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { AddCard } from '@operto/ui-library';
import { Role } from 'Common/Tables/helpers';
import { roleCanGrantSelectedPermission } from 'Pages/EditPeople/TabContainers/PermissionsContainer';
import { IMember, MemberSiteAccess } from 'member/memberType';
import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { FormHeader } from 'ui-library/Styled/sidebar';
import { userSelector } from 'user/state/userSelectors';
import { UserState } from 'user/userTypes';

interface PermissionsComponentProps {
  onChange: () => void;
  data: IMember;
}

const PermissionsComponent = ({ onChange, data }: PermissionsComponentProps) => {
  const valueChanger = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const field = 'role';
    onChange(field, value);
  };

  const loggedInMember: UserState = useAppSelector(userSelector());

  const dashboardAccessHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const siteAccessIds = data.site_access_id ?? [];
    const updatedSiteAccessIds = e.target.checked
      ? [...siteAccessIds, MemberSiteAccess.DASHBOARD]
      : siteAccessIds.filter(id => id !== MemberSiteAccess.DASHBOARD);

    onChange('site_access_id', updatedSiteAccessIds);
    onChange(e.target.name, e.target.checked);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <AddCard title='User Permissions'>
          <Grid container direction='column'>
            <FormHeader>Select what this user can or cannot access in their dashboard.</FormHeader>
            <RadioGroup>
              <FormControlLabel
                control={
                  <Radio
                    value='account-owner'
                    onChange={valueChanger}
                    checked={'role' in data && data.role === 'account-owner'}
                    data-testid='accountowner_radio'
                    disabled={
                      !roleCanGrantSelectedPermission(loggedInMember, data, Role.AccountOwner)
                    }
                  />
                }
                label='Account Owner'
              />
              <FormControlLabel
                control={
                  <Radio
                    value='global-manager'
                    onChange={valueChanger}
                    checked={'role' in data && data.role === 'global-manager'}
                    data-testid='globalmanager_radio'
                    disabled={
                      !roleCanGrantSelectedPermission(loggedInMember, data, Role.GlobalManager)
                    }
                  />
                }
                label='Global Manager'
              />
              <FormControlLabel
                control={
                  <Radio
                    value='regional-manager'
                    onChange={valueChanger}
                    checked={'role' in data && data.role === 'regional-manager'}
                    data-testid='regionalmanager_radio'
                    disabled={
                      !roleCanGrantSelectedPermission(loggedInMember, data, Role.RegionalManager)
                    }
                  />
                }
                label='Regional Manager'
              />
              <FormControlLabel
                control={
                  <Radio
                    value='basic-user'
                    onChange={valueChanger}
                    checked={'role' in data && data.role === 'basic-user'}
                    data-testid='basicuser_radio'
                    disabled={!roleCanGrantSelectedPermission(loggedInMember, data, Role.BasicUser)}
                  />
                }
                label='Basic Access'
              />
            </RadioGroup>
          </Grid>
        </AddCard>

        <AddCard title='Access to Dashboard'>
          <Grid>
            <FormHeader>
              Would you like to grant the team member access to the dashboard?
            </FormHeader>
            <FormControlLabel
              control={
                <Switch
                  name='is_dashboard_access'
                  color='primary'
                  onChange={dashboardAccessHandler}
                  checked={data.is_dashboard_access}
                  data-testid='granddashboardaccess_switch'
                />
              }
              label='Grant Dashboard Access'
            />
          </Grid>
        </AddCard>
      </Grid>
    </Grid>
  );
};

export default PermissionsComponent;
