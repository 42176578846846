import { FilterType } from 'event/eventType';
import MainActivityCard from 'event/SmartCards/MainActivityCard';
import { getEvents } from 'event/state/eventActions';
import { EventsTotalCountSelector, SearchEventsSelector } from 'event/state/eventSelectors';
import { logger } from 'lib/logger';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

const RoomTypeActivitiesPage = () => {
  const dispatch = useAppDispatch();
  const { roomType } = useParams();
  const [keyword, setKeyword] = useState('');
  const [mainActivityLoading, setMainActivityLoading] = useState(false);
  const meta = useAppSelector(EventsTotalCountSelector(FilterType.ALL_ACTIVITIES));
  const filteredEvents = useAppSelector(SearchEventsSelector(keyword, FilterType.ALL_ACTIVITIES));

  const handleSearchChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    setKeyword(event.currentTarget.value);
  };

  const handleFetchMore = React.useCallback(
    async (pageNum: number) => {
      setMainActivityLoading(true);
      try {
        await dispatch(getEvents(FilterType.ALL_ACTIVITIES, pageNum, roomType));
      } catch (error) {
        logger.error(error);
      }

      setMainActivityLoading(false);
    },
    [dispatch, roomType],
  );

  useEffect(() => {
    handleFetchMore(0);
  }, [handleFetchMore]);

  return (
    <MainActivityCard
      meta={meta}
      events={filteredEvents}
      onSearch={handleSearchChange}
      onFetchMore={() => handleFetchMore(meta.pageNum + 1)}
      isMainActivity
      loading={mainActivityLoading}
    />
  );
};

export default RoomTypeActivitiesPage;
