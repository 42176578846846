export const TextInsert = (a: string, b: string, pos: number) =>
  [a.slice(0, pos), b, a.slice(pos)].join('');

export const insertTextAtPosition = (
  destination: string,
  source: string,
  pos: { start: number; end: number },
) => {
  return destination.substring(0, pos.start) + source + destination.substring(pos.end);
};

export const truncateCharacters = (title: string, limitLength: number) => {
  if (title.length > limitLength) {
    return `${title.substr(0, limitLength)}...`;
  } else {
    return title;
  }
};

export const validateTextLength = (text: string, limitLength: number) => {
  if (text?.length > limitLength) {
    return `Max ${limitLength} characters limit`;
  }
  return true;
};
