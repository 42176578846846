import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, Button, Chip, Grid, IconButton, SxProps, Typography } from '@mui/material';
import { AddCard } from '@operto/ui-library';
import { UpsellFields } from 'Pages/Marketplace/UpsellPage';
import useTranslation from 'hooks/useTranslation';
import React, { useCallback, useState } from 'react';
import { useUploadMediaMutation } from 'redux/media/api-slice';

interface MediaSectionProps {
  media: string[];
  updateMediaFields: (field: keyof UpsellFields['media'], value: string[]) => void;
}

const MediaSection = ({ media, updateMediaFields }: MediaSectionProps) => {
  const { t } = useTranslation();
  const [isDragging, setIsDragging] = useState(false);
  const [uploadMedia] = useUploadMediaMutation();

  const uploadFile = useCallback(
    async (file: File, addFileCallback: (url: string) => void) => {
      try {
        const response = await uploadMedia({
          service: 'upsells',
          media: new Blob([await file.arrayBuffer()], { type: file.type }),
        }).unwrap();

        addFileCallback(response.data.mediaUrl);
      } catch (error) {
        throw new Error(error);
      }
    },
    [uploadMedia],
  );

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      handleDragLeave();
      const files = Array.from(event.dataTransfer.files);
      files.forEach(file => {
        uploadFile(file, (url: string) => {
          updateMediaFields('files', [...media, url]);
        });
      });
    },
    [media, updateMediaFields, uploadFile],
  );

  const handleFileSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.target.files);
      files.forEach(file => {
        uploadFile(file, (url: string) => {
          updateMediaFields('files', [...media, url]);
        });
      });
    },
    [media, updateMediaFields, uploadFile],
  );

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDelete = (index: number) => {
    updateMediaFields(
      'files',
      media.filter((_, i) => i !== index),
    );
  };

  const DragOverlay = () => (
    <Box sx={dragOverlayStyles}>
      <CollectionsOutlinedIcon sx={dragIconStyles} />
      <Typography variant='h6'>{t('drop')}</Typography>
    </Box>
  );

  const getGridSize = (index: number, length: number) => {
    return index === 0 && length % 2 === 1 ? 12 : 6;
  };
  return (
    <AddCard
      title={
        <Box display='flex' flexDirection='column' justifyContent='space-between'>
          <Typography variant='subtitle1'>{`${t('offer_images')} *`}</Typography>
        </Box>
      }
    >
      <Box sx={columnCardMediumStyles}>
        <Box sx={uploadButtonContainerStyles}>
          <Button variant='contained' component='label' color='primary'>
            {t('+upload')}
            <input type='file' multiple hidden onChange={handleFileSelect} />
          </Button>
          {/* // TODO: Implement stock photos */}
          {/* <Button variant='outlined' color='primary'>
            {t('+stock_photos')}
          </Button> */}
        </Box>
        <Box
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          sx={!media.length ? emptyMediaBoxStyles : mediaBoxStyles}
        >
          {isDragging && <DragOverlay />}
          {!media.length && (
            <Box sx={emptyMediaContentStyles}>
              <CollectionsOutlinedIcon sx={emptyMediaIconStyles} />
              <Typography variant='body2' color='textSecondary'>
                {t('drag_n_drop_photos')}
              </Typography>
            </Box>
          )}
          <Grid container spacing={2} mt={2}>
            {media.map((src, index) => (
              <Grid
                item
                xs={getGridSize(index, media.length)}
                sm={getGridSize(index, media.length)}
                md={getGridSize(index, media.length)}
                key={src}
                sx={{ position: 'relative' }}
              >
                <Box
                  component='img'
                  src={src}
                  alt={`media-${index}`}
                  sx={index === 0 && media.length % 2 === 1 ? coverImageStyles : imageStyles}
                />
                {index === 0 && <Chip label='Cover' sx={coverChipStyles} />}
                <IconButton
                  aria-label='delete'
                  size='small'
                  sx={deleteButtonStyles}
                  onClick={() => handleDelete(index)}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </AddCard>
  );
};

const columnCardMediumStyles: SxProps = {
  padding: 2,
  position: 'relative',
};

const dragOverlayStyles: SxProps = {
  position: 'absolute',
  top: 0,
  left: 0,
  borderRadius: 2,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  zIndex: 2,
  pointerEvents: 'none',
};

const dragIconStyles: SxProps = {
  fontSize: 50,
};

const uploadButtonContainerStyles: SxProps = {
  display: 'flex',
  gap: 2,
  marginBottom: 2,
  justifyContent: 'flex-end',
};

const emptyMediaBoxStyles: SxProps = {
  border: '2px dashed #CACDDA80',
  backgroundColor: '#F6F7FB',
  borderRadius: 2,
  padding: 2,
  textAlign: 'center',
  cursor: 'pointer',
  position: 'relative',
};

const mediaBoxStyles: SxProps = {
  borderRadius: 2,
  padding: 2,
  textAlign: 'center',
  cursor: 'pointer',
  position: 'relative',
};

const emptyMediaContentStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  pointerEvents: 'none',
};

const emptyMediaIconStyles: SxProps = {
  fontSize: 100,
  marginBottom: 2,
  color: '#999999f3',
};

const coverImageStyles: SxProps = {
  width: '100%',
  height: 'auto',
  borderRadius: 2,
  backgroundOrigin: 'border-box',
  backgroundClip: 'content-box',
  backgroundSize: 'cover',
  border: '1px solid #ddd',
};

const imageStyles: SxProps = {
  width: '100%',
  height: 'auto',
  borderRadius: 2,
  border: '1px solid #ddd',
};

const coverChipStyles: SxProps = {
  position: 'absolute',
  top: 25,
  left: 25,
  backgroundColor: 'rgba(255, 255, 255, 1)',
  color: '#000000',
  fontSize: '0.75rem',
};

const deleteButtonStyles: SxProps = {
  position: 'absolute',
  top: 25,
  right: 8,
  backgroundColor: 'rgba(255, 255, 255, 1)',
  borderRadius: '50%',
  padding: 0.5,
  color: '#000000',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
};

export default MediaSection;
