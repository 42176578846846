import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { Actions } from '../../../types/actions';
import { LockCommands, lockCommandStatus } from '../../../types/ui';

const defaultState = {
  codeStatus: '',
  previousLockStatus: false,
  propertyId: 0,
};
const codeCommandStatus: Reducer<string> = (state = defaultState.codeStatus, action) => {
  switch (action.type) {
    case Actions.toggleLockPending:
    case Actions.toggleUnLockPending:
      return lockCommandStatus.pending;
    case Actions.toggleLockFailed:
    case Actions.toggleUnLockFailed:
      return lockCommandStatus.failure;
    case Actions.toggleLockSuccess:
    case Actions.toggleUnLockSuccess:
      return lockCommandStatus.success;
    default:
      return state;
  }
};

// true means previous lock status was locked ie unlocked command
// false means previous lock status was unlocked ie lock command

const previousLockStatus: Reducer<boolean> = (state = defaultState.previousLockStatus, action) => {
  switch (action.type) {
    case Actions.toggleLockPending:
      return false;
    case Actions.toggleUnLockPending:
      return true;
    default:
      return state;
  }
};

const propertyId: Reducer<number> = (state = defaultState.propertyId, action) => {
  switch (action.type) {
    case Actions.toggleLockPending:
    case Actions.toggleUnLockPending:
      return action.propertyId;
    default:
      return state;
  }
};

const showLockCommandBanner: Reducer<boolean> = (state = true, action) => {
  switch (action.type) {
    case Actions.toggleLockPending:
    case Actions.toggleUnLockPending:
      return true;
    case Actions.dismissLockCommandBanner:
      return false;
    default:
      return state;
  }
};
const aLockCommand: Reducer<LockCommands> = combineReducers<LockCommands>({
  codeCommandStatus,
  previousLockStatus,
  propertyId,
  showLockCommandBanner,
});

export default aLockCommand;
