import TemperatureIcon from '@mui/icons-material/Thermostat';
import { Box, SxProps, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { INetatmo, ITado } from 'device/deviceType';
import { devicesByPropertyIdSelector } from 'device/state/deviceSelector';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { patchThreshold } from 'threshold/state/thresholdAction';
import { getThresholdsByDeviceId } from 'threshold/state/thresholdSelectors';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import Widget from 'ui-library/Components/widget/Widget';
import RiskDisplay, { RiskLevel } from 'ui-library/Components/widget/content/RiskDisplay';
import ShowMoreToggle from 'ui-library/Components/widget/content/ShowMoreToggle';
import ThresholdSetting, {
  ThresholdInputs,
} from 'ui-library/Components/widget/content/ThresholdSetting';
import { currentUserSelector } from 'user/state/userSelectors';
import DeviceTitleDropdown from './DeviceTitleDropdown';
import TemperatureFooter from './TemperatureFooter';

const cToF = (temperature: number) => Number(temperature * 1.8 + 32).toFixed(2);
const fToC = (temperature: number) => Number((temperature - 32) * (5 / 9)).toFixed(2);

export interface TemperatureCardProps {
  hideFooter?: boolean;
  isOnUnitDashboard?: boolean;
}

export const HIGH_TEMPERATURE_THRESHOLD = 40;
export const LOW_TEMPERATURE_THRESHOLD = 20;

const TemperatureCard = ({ hideFooter, isOnUnitDashboard = false }: TemperatureCardProps) => {
  const { propertyId: pidStr } = useParams();
  const propertyId = Number(pidStr);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { is_celsius: isCelsius } = useAppSelector(currentUserSelector());
  const devices = useAppSelector(devicesByPropertyIdSelector(propertyId));
  const thermostatDevices = devices.filter(device => device.data.temperature) as
    | INetatmo[]
    | ITado[];
  const [selectedDeviceId, setSelectedDeviceId] = React.useState(
    thermostatDevices?.[0]?.data?.device_id || 0,
  );
  const [openDetails, setOpenDetails] = useState(false);
  const [high, setHigh] = useState(
    isCelsius ? HIGH_TEMPERATURE_THRESHOLD : Number(cToF(HIGH_TEMPERATURE_THRESHOLD)),
  );
  const [low, setLow] = useState(
    isCelsius ? LOW_TEMPERATURE_THRESHOLD : Number(cToF(LOW_TEMPERATURE_THRESHOLD)),
  );

  const deviceThreshold = useAppSelector(getThresholdsByDeviceId(selectedDeviceId as number));
  // @ts-ignore
  const deviceData = thermostatDevices.find(d => d.data?.device_id === selectedDeviceId);

  useEffect(() => {
    if (deviceThreshold) {
      const { tempHigh, tempLow } = deviceThreshold;
      const low = isCelsius ? tempLow?.value : cToF(tempLow?.value);

      setLow(Number(low));
      const high = isCelsius ? tempHigh?.value : cToF(tempHigh?.value);
      setHigh(Number(high));
    }
  }, [deviceThreshold, isCelsius]);

  useEffect(() => {
    if (thermostatDevices?.length > 0) {
      setSelectedDeviceId(thermostatDevices?.[0].data?.device_id);
    }
  }, [thermostatDevices, thermostatDevices.length]);

  const calculateRisk = () => {
    if (deviceData?.data?.temperature) {
      const temperature = isCelsius
        ? deviceData.data.temperature
        : cToF(parseFloat(deviceData.data.temperature));
      const current = parseFloat(temperature);
      if (current > high || current < low) {
        return RiskLevel.HIGH;
      }
      return RiskLevel.LOW;
    }
    return RiskLevel.NONE;
  };

  const toggleOpenDetails = () => {
    setOpenDetails(!openDetails);
  };

  const viewMoreDetails = () => {
    navigate(`/units/${propertyId}/devices/temperature`);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>, field: ThresholdInputs) => {
    if (field === ThresholdInputs.FirstThreshold) {
      setLow(Number(e.target.value));
    } else {
      setHigh(Number(e.target.value));
    }
  };

  const handleSave = (field: ThresholdInputs) => {
    const { tempHigh, tempLow } = deviceThreshold;
    const value = field === ThresholdInputs.FirstThreshold ? low : high;
    const id = field === ThresholdInputs.FirstThreshold ? tempLow?.id : tempHigh?.id;
    const thresholdType = field === ThresholdInputs.FirstThreshold ? 'Low' : 'High';
    const temperature = isCelsius ? value : fToC(value);
    // @ts-ignore
    dispatch(patchThreshold(id, temperature)).then(() => {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: `${thresholdType} temperature threshold updated.`,
          variant: SnackbarVariant.SUCCESS,
        }),
      );
    });
  };

  const handleDeviceSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDeviceId(e.target.value);
  };

  const temperatureUnit = isCelsius ? 'C' : 'F';
  let displayTemperature = 0;
  if (deviceData?.data) {
    displayTemperature = isCelsius
      ? deviceData.data.temperature
      : cToF(deviceData?.data.temperature);
  }

  if (thermostatDevices.length === 0) {
    return null;
  }

  return (
    <Widget
      title={
        <DeviceTitleDropdown
          devices={thermostatDevices}
          onChange={handleDeviceSelect}
          selectedDeviceId={selectedDeviceId as number}
          icon={<TemperatureIcon sx={{ color: 'white' }} />}
        />
      }
      isCarouselCard
      open={openDetails}
      isOnUnitDashboard={isOnUnitDashboard}
      smartCardType='device'
      footer={!hideFooter && <TemperatureFooter onDetailsClick={viewMoreDetails} />}
      fill='#3F51B5'
    >
      <>
        <Box sx={informationContainerStyles}>
          <ShowMoreToggle onClick={toggleOpenDetails} open={openDetails} fill='#3F51B5' />
          <Typography sx={{ color: 'white' }} variant='h4'>
            {deviceData?.data.temperature ? `${displayTemperature}°${temperatureUnit}` : 'N/A'}
          </Typography>
          <RiskDisplay riskLevel={calculateRisk()} />
        </Box>
        {openDetails && (
          <Collapse in={openDetails}>
            <ThresholdSetting
              values={[low, high]}
              onChange={handleValueChange}
              onSave={handleSave}
              suffix={`°${temperatureUnit}`}
              labels={['Low Threshold', 'High Threshold']}
            />
          </Collapse>
        )}
      </>
    </Widget>
  );
};

const informationContainerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  textAlign: 'left',
  marginBottom: '14px',
};

export default TemperatureCard;
