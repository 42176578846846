import AddIcon from '@mui/icons-material/Add';
import { ListItemIcon, Menu, MenuItem, MenuList, PopoverOrigin, SxProps } from '@mui/material';
import { Text } from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

export const BUILDER_TYPE = {
  DESIGN_BUILDER: 'builder',
  SOURCE_CODE: 'code',
} as const;

export const MENU_OPTIONS = {
  EMAIL_BUILDER: `email/${BUILDER_TYPE.DESIGN_BUILDER}`,
  EMAIL_CODE: `email/${BUILDER_TYPE.SOURCE_CODE}`,
  SMS_BUILDER: 'sms',
} as const;

export type MenuOptionType = (typeof MENU_OPTIONS)[keyof typeof MENU_OPTIONS];

export default function CommunicationsNewMenu({
  anchorEl,
  open,
  handleClose,
  useWidthOfAnchor = false,
  anchorOrigin,
  transformOrigin,
}: {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: (selectedOption?: MenuOptionType) => void;
  useWidthOfAnchor?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}) {
  const { t } = useTranslation();

  return (
    <Menu
      disablePortal
      id='new-communication-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={() => handleClose(undefined)}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
        sx: { width: useWidthOfAnchor ? anchorEl?.offsetWidth : undefined },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
        ...(anchorOrigin || {}),
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
        ...(transformOrigin || {}),
      }}
    >
      <MenuList sx={{ textAlign: 'left' }}>
        <Text variant='h5' style={{ padding: '6px 16px' }}>
          {t('email')}
        </Text>
        <MenuItem
          onClick={() => handleClose(MENU_OPTIONS.EMAIL_BUILDER)}
          sx={menuItemStyles}
          data-testid='email-builder'
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          {t('design_builder')}
        </MenuItem>
        <MenuItem
          onClick={() => handleClose(MENU_OPTIONS.EMAIL_CODE)}
          sx={menuItemStyles}
          data-testid='email-code'
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          {t('source_code')}
        </MenuItem>

        <Text variant='h5' style={{ padding: '6px 16px' }}>
          {t('sms')}
        </Text>
        <MenuItem
          onClick={() => handleClose(MENU_OPTIONS.SMS_BUILDER)}
          sx={menuItemStyles}
          data-testid='sms-builder'
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          {t('design_builder')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

const menuItemStyles: SxProps = {
  gap: '20px',
};
