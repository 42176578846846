import { SxProps } from '@mui/material';

export const groupNameChipStyle: SxProps = {
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export const tableContainerStyle: SxProps = {
  width: '46%',
  height: '100%',
};

export const removeButtonStyle = (isSelected: boolean): SxProps => ({
  width: '87px',
  height: '30px',
  backgroundColor: isSelected ? '#F44336' : '#FFFFFF',
  color: isSelected ? '#FFFFFF' : 'rgba(0, 0, 0, 0.38)',
  padding: '4px 12px',
  borderRadius: '6px',
  marginBottom: '16px',
  fontSize: '13px',
  fontWeight: '700',
  textTransform: 'capitalize',
  '& .MuiButton-endIcon': {
    marginLeft: '4px',
  },
  '&:hover': {
    backgroundColor: isSelected ? '#F44336' : '#FFFFFF',
  },
});

export const addButtonStyle = (isSelected: boolean): SxProps => ({
  width: '87px',
  height: '30px',
  backgroundColor: isSelected ? '#2E7D32' : '#FFFFFF',
  color: isSelected ? '#FFFFFF' : 'rgba(0, 0, 0, 0.38)',
  padding: '4px 12px',
  borderRadius: '6px',
  marginBottom: '16px',
  fontSize: '13px',
  fontWeight: '700',
  justifyContent: 'flex-start',
  textTransform: 'capitalize',
  '& .MuiButton-startIcon': {
    marginRight: '4px',
  },
  '&:hover': {
    backgroundColor: isSelected ? '#2E7D32' : '#FFFFFF',
  },
});

export const addRemoveButtonContainerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0px 16px',
};

export const itemsTableStyle: SxProps = {
  height: '100%',
  '& .group_edit_pinned_row': {
    backgroundColor: '#ecf2ff',
  },
};

export const paginatedTableStyle: SxProps = {
  height: 'calc(100% - 145px)',
  '.MuiDataGrid-columnHeaders': {
    display: 'none',
  },
};

export const searchToolbarStyle: SxProps = {
  paddingY: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: '-56px',
  position: 'relative',
  zIndex: 10,
};

export const editButtonStyle: SxProps = {
  color: 'white',
  backgroundColor: '#155EFF',
  height: '100%',
  width: '100px',
  padding: '8px 22px',
  '&:hover': {
    backgroundColor: '#155EFF',
  },
};
