import fromUnixTime from 'date-fns/fromUnixTime';
import isBefore from 'date-fns/isBefore';
import jwtDecode, { JwtPayload } from 'jwt-decode';
export const MULTI_MP_AUTH = 'MultiMpAuthCognito';

export interface LinkedAccountData {
  email: string;
  cid: string;
  name: string;
  token?: string;
  isLoggedIn: boolean;
  expired: boolean;
  idToken?: string;
}

export const getLinkedAccounts = (): LinkedAccountData[] => {
  const multiAuth = localStorage.getItem(MULTI_MP_AUTH);
  if (!multiAuth) {
    setMultiAuthObject([]);
    return [];
  }
  const parsed = JSON.parse(multiAuth) as LinkedAccountData[];
  return parsed;
};

export const logoutAccountAndExpireToken = () => {
  const allAuths = getLinkedAccounts();
  const index = allAuths.findIndex(x => x.isLoggedIn);
  allAuths[index] = { ...allAuths[index], isLoggedIn: false, expired: true, token: '' };
  setMultiAuthObject(allAuths);
};

export const logoutAccount = () => {
  const allAuths = getLinkedAccounts();
  const index = allAuths.findIndex(x => x.isLoggedIn);
  allAuths[index] = { ...allAuths[index], isLoggedIn: false };
  setMultiAuthObject(allAuths);
};

export const checkForTokenExpiry = () => {
  const linkedAccounts = getLinkedAccounts();
  const updatedAccounts: LinkedAccountData[] = [];
  linkedAccounts.forEach(acct => {
    try {
      const tokenKey = acct.token ? 'token' : 'idToken';
      const parsedToken = jwtDecode<JwtPayload>(acct[tokenKey]);
      const expired = isBefore(fromUnixTime(parsedToken.exp), new Date());
      updatedAccounts.push({
        ...acct,
        expired: expired,
        isLoggedIn: expired ? false : acct.isLoggedIn,
      });
    } catch (error) {
      updatedAccounts.push({
        ...acct,
        expired: true,
        isLoggedIn: false,
      });
    }
  });
  setMultiAuthObject(updatedAccounts);
};

const setMultiAuthObject = (multiAuthJSData: LinkedAccountData[]) => {
  localStorage.setItem(MULTI_MP_AUTH, JSON.stringify(multiAuthJSData));
};

const getAuthDataFromMultiAuthByEmail = (email: string): LinkedAccountData => {
  return getLinkedAccounts().find(x => x.email === email);
};

const getAuthDataFromMultiAuthByToken = (token: string) => {
  const allAuths = getLinkedAccounts();
  return allAuths.find(x => x.token === token);
};

export const getLoggedInAccount = (): LinkedAccountData => {
  return getLinkedAccounts().find(x => x.isLoggedIn);
};

export const setActiveAuthAccount = (email: string) => {
  const allAuths = getLinkedAccounts();
  const correctedAuths: LinkedAccountData[] = [];
  allAuths.forEach(auth => {
    if (auth.email !== email) {
      correctedAuths.push({ ...auth, isLoggedIn: false });
    } else {
      correctedAuths.push({ ...auth, isLoggedIn: true });
    }
  });
  localStorage.removeItem(MULTI_MP_AUTH);
  localStorage.setItem(MULTI_MP_AUTH, JSON.stringify(correctedAuths));
};

export const addOrReplaceMultiAuthByEmail = (email: string, newData: LinkedAccountData) => {
  const allAuth = getLinkedAccounts();
  const index = allAuth.findIndex(x => x.email === email);
  if (index !== -1) {
    allAuth[index] = { email: email, ...newData };
  } else {
    allAuth.push({ email: email, ...newData });
  }
  setMultiAuthObject(allAuth);
};

export const appendCompanyNameByEmail = (email: string, name: string) => {
  const authData = getAuthDataFromMultiAuthByEmail(email);
  addOrReplaceMultiAuthByEmail(email, { ...authData, name: name });
};

export const appendCompanyNameByToken = (token: string, name: string) => {
  const authObj = getAuthDataFromMultiAuthByToken(token);
  if (authObj) {
    addOrReplaceMultiAuthByEmail(authObj.email, { ...authObj, name: name });
  }
};

export const appendCompanyIdByToken = (token: string, id: string) => {
  const authObj = getAuthDataFromMultiAuthByToken(token);
  if (authObj) {
    addOrReplaceMultiAuthByEmail(authObj.email, { ...authObj, cid: id });
  }
};

export const deleteAuthDataByEmail = (email: string) => {
  const allAuth = getLinkedAccounts();
  const index = allAuth.findIndex(x => x.email === email);
  allAuth.splice(index, 1);
  setMultiAuthObject(allAuth);
};
