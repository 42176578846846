// @ts-nocheck

import * as React from 'react';

import { Box, SxProps } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ActivityFeedListItem from 'Common/Lists/ActivityFeedListItem';
import { getPropertyEvents } from 'event/state/eventActions';
import { recentEventsSelector } from 'event/state/eventSelectors';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import OpertoProgressCircle from 'ui-library/Components/misc/OpertoProgressCircle';
import { IEvent } from './eventType';

export interface PropertyEventsContainerProps {
  propertyId: number;
}

const PropertyEventsContainer = ({ propertyId }: PropertyEventsContainerProps) => {
  const [pageNum, setPageNum] = React.useState(0);
  const dispatch = useAppDispatch();

  const handleFetchMore = () => {
    const newPageNum = pageNum + 1;
    setPageNum(newPageNum);
    dispatch(getPropertyEvents(propertyId, newPageNum));
  };

  const events = useAppSelector(recentEventsSelector(propertyId, 1000));
  return (
    <Box sx={baseContainerStyles}>
      Hellow World PropertyActivityView{propertyId}
      <Box
        sx={{
          marginLeft: '24px',
          marginRight: '24px',
        }}
      >
        <List sx={{ width: '100%', height: '600px', overflowY: 'scroll' }} id='scrollable-activity'>
          <InfiniteScroll
            dataLength={events?.length || 0}
            next={handleFetchMore}
            hasMore
            scrollableTarget='scrollable-activity'
            loader={<OpertoProgressCircle />}
            endMessage={<p>No more left</p>}
          >
            {events &&
              events.map((event: IEvent) => (
                <>
                  <ActivityFeedListItem
                    codeType={event.type}
                    message={event.message}
                    payload={event}
                  />
                  <Divider variant='inset' component='li' />
                </>
              ))}
          </InfiniteScroll>
        </List>
      </Box>
    </Box>
  );
};

const baseContainerStyles: SxProps = {
  color: 'text',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 500ms ease-out',
};

export default PropertyEventsContainer;
