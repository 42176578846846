import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { ResetPassword } from '../../../types/ui';
import { Actions } from '../../../types/actions';

const resetStatus: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.pendingUpdatePassword:
      return 'pending';
    case Actions.failedUpdatedPassword:
      return 'failed';
    case Actions.updatedPassword:
      return 'success';
    case Actions.resetUpdatePassword:
      return '';
    default:
      return state;
  }
};

const id: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.failedUpdatedPassword:
      return action.memberId;
    case Actions.updatedPassword:
      return action.memberId;
    default:
      return state;
  }
};

const resetPassword: Reducer<ResetPassword> = combineReducers<ResetPassword>({
  resetStatus,
  id,
});

export default resetPassword;
