import { createUnitsFilterOptions } from 'Pages/Unit/UnitsFilterData';
import { logger } from 'lib/logger';
import { PropertyFilterType } from 'property/propertyType';
import { getUnitsFilterOptions } from 'property/state/propertyActions';
import { useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import { FilterGroup, UnitsFilterOption } from 'types/filter-type';
import { userSelector } from 'user/state/userSelectors';
import { UserState } from 'user/userTypes';
import { useFilterLocalStorage } from './useFilterLocalStorage';
import { useSyncFilters } from './useSyncFilters';

export default function useUnitsFilters(filterType: PropertyFilterType) {
  const loggedInMember: UserState = useAppSelector(userSelector());
  const { getFilters, setFilters } = useFilterLocalStorage();
  const key = filterType !== PropertyFilterType.FAVOURITES ? 'units' : 'favourites';
  const { syncStatus, searchParams, filterGroups, setSyncStatus, syncFilters, addFilterGroups } =
    useSyncFilters({
      key: `${loggedInMember.user.id}_${key}`,
      persistFilters: setFilters,
      hydrateFilters: getFilters,
    });

  const onFilterChange = (params: URLSearchParams) => {
    syncFilters(params);
  };

  const onFilterClear = (filterGroupKey: string) => {
    const defaultForGroup = defaultUnitsFilterOption[filterGroupKey];
    if (Array.isArray(defaultForGroup)) {
      searchParams.delete(filterGroupKey);
    } else {
      Object.keys(defaultForGroup).map(key => searchParams.delete(key));
    }

    syncFilters(searchParams);
  };

  const isClearableFilters = (filterGroupKey: string) => {
    const defaultForGroup = defaultUnitsFilterOption[filterGroupKey];
    if (Array.isArray(defaultForGroup)) {
      return searchParams.get(filterGroupKey)?.length > 0;
    }

    return Object.keys(defaultForGroup).some(k => searchParams.get(k)?.length > 0);
  };

  const updateChipLabel = (filterGroup: string) => {
    const defaultForGroup = defaultUnitsFilterOption[filterGroup];
    let count = 0;
    let updatedLabel = '';
    if (Array.isArray(defaultForGroup)) {
      const filterValue = searchParams.get(filterGroup)?.split(',') ?? [];
      const selectedFilterGroup = filterGroups.find(
        filterOption => filterOption.groupName === filterGroup,
      );
      const selectedFilter = selectedFilterGroup?.options.find(
        option => option.name === filterValue[0],
      );

      updatedLabel = filterValue.length != 0 ? selectedFilter.label : updatedLabel;
      count = filterValue.length - 1 > 0 ? filterValue.length - 1 : 0;
    } else {
      let isFirstDeviceFilterType = true;
      searchParams.forEach((value, key) => {
        // grab the first device filter type and use it as the label
        if (Object.keys(defaultForGroup).includes(key) && isFirstDeviceFilterType) {
          updatedLabel = `${findLabelByKey(key, filterGroup)} ${value.split(',')[0]}`;
          isFirstDeviceFilterType = false;
        }
        if (Object.keys(defaultForGroup).includes(key)) {
          count += value.split(',').length;
        }
      });
      count = count - 1;
    }

    return updatedLabel !== '' ? `${updatedLabel} ${count > 0 ? `+${count}` : ''}` : '';
  };

  const updateTotalFilterLabelMobile = () => {
    return Array.from(searchParams).length > 0 ? Array.from(searchParams).length : '';
  };

  const findLabelByKey = (key: string, filterGroup: string) => {
    let nestedFilterGroup: FilterGroup;
    for (let i = 0; i < filterGroups.length; i++) {
      if (filterGroups[i].groupName === filterGroup) {
        nestedFilterGroup = filterGroups[i];
        break;
      }
    }

    for (let i = 0; i < nestedFilterGroup.options.length; i++) {
      if (nestedFilterGroup.options[i].name === key) {
        return nestedFilterGroup.options[i].label;
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getUnitsFilterOptions();
        const createdFilterData = createUnitsFilterOptions(response);
        addFilterGroups(createdFilterData);
      } catch (error) {
        logger.error('Fetch Units filter options failed, error: ', error);
        addFilterGroups([]);
      }
    }

    if (syncStatus === 'unsynched') {
      setSyncStatus('synching');
      if (key === 'units') {
        fetchData();
      } else {
        addFilterGroups([]);
      }
    }
  }, [addFilterGroups, key, setSyncStatus, syncStatus]);

  return {
    isSynched: syncStatus === 'synched',
    filterGroups,
    searchParams,
    onFilterChange,
    onFilterClear,
    isClearableFilters,
    updateChipLabel,
    updateTotalFilterLabelMobile,
  };
}

export const defaultUnitsFilterOption: UnitsFilterOption = {
  type: [],
  devices: {
    lock: [],
    lock_battery: [],
    lock_keypad: [],
  },
  group: [],
  room_type: [],
  unit_status: [],
  sort_by: [],
};
