import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

type UpsellTemplateListProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  children: React.ReactNode | React.ReactNode[];
};

const UpsellTemplateModal = ({ open, setOpen, children }: UpsellTemplateListProps) => {
  const templateCards = Array.isArray(children) ? children : [children];
  const { t } = useTranslation();
  const MAX_WIDTH = 1300;

  return (
    <>
      <Dialog maxWidth={false} open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t('create_from_template')}</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Grid container maxWidth={MAX_WIDTH} spacing={2} mb={3}>
            {templateCards.map((child, index) => (
              <Grid item xs={12} md={6} lg={4} xl={4} key={`${index}`}>
                {child}
              </Grid>
            ))}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpsellTemplateModal;
