import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Avatar, Box, IconButton, SxProps, Theme, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { useMedia } from '@operto/ui';
import FavouriteIcon from 'Common/Icons/FavouriteIcon';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { useChatContext } from 'stream-chat-react';
import ProfileIcon from 'ui-library/Components/icon/ProfileIcon';
import { updateFavouriteAction } from './MessagingActions';

interface ChannelHeaderProps {
  onChevronClick?: () => void;
  showFavouriteIcon?: boolean;
  showUnitName?: boolean;
}
const CustomChannelHeader = (props: ChannelHeaderProps) => {
  const { isMobile } = useMedia();
  const { channel } = useChatContext();
  const { name } = channel.data || {};
  const members = channel.data.member_count;
  const showFavouriteIcon = props?.showFavouriteIcon;
  const showUnitName = props?.showUnitName;
  const online = channel.state.watcher_count;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const favourite = (channel?.data?.favourite as boolean) ?? false;
  const captionInfo = {
    title: channel?.data?.['property_name'] as string,
    url: `/units/${channel?.data?.['property_id'] as number}`,
  };

  const mainContainerStyles: SxProps<Theme> = {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    paddingTop: '12px',
    paddingLeft: isMobile ? 0 : '16px',
    paddingBottom: '12px',
    paddingRight: '24px',
    borderBottom: theme => `1px solid ${theme.palette.divider}`,
  };

  const truncatedTextStyle: SxProps = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  };

  const renderChevronIcon = () => {
    return (
      <IconButton
        sx={{ height: '48px', width: '48px' }}
        onClick={props.onChevronClick}
        data-testid='chevron-icon'
      >
        <ChevronLeftIcon sx={{ height: '100%', width: '100%' }} />
      </IconButton>
    );
  };

  let onClickFavouriteTimeout: NodeJS.Timeout;

  const onClickFavouriteIcon = async () => {
    await dispatch(updateFavouriteAction(channel.id, !favourite));
    clearTimeout(onClickFavouriteTimeout);
    onClickFavouriteTimeout = setTimeout(() => navigate(`/messenger/${channel.id}`), 300);
  };

  const handleClick = (e: React.MouseEvent) => {
    if (captionInfo?.url) {
      navigate(captionInfo.url);
      e.stopPropagation();
    }
  };

  return (
    <Box sx={mainContainerStyles}>
      {isMobile && renderChevronIcon()}
      <Avatar>
        <ProfileIcon
          imageUrl={channel.data?.image as string}
          size={'medium'}
          fullName={channel.data?.name}
        />
      </Avatar>

      <Box sx={{ flexGrow: 1, width: '118.5px' }}>
        <Typography variant='subtitle1' sx={truncatedTextStyle}>
          {name}
        </Typography>
        <Typography variant='body2'>
          {members} members, {online} online
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',
          gap: '8px',
          width: '100px',
          marginBottom: 3,
        }}
      >
        {showUnitName && (
          <Link
            component='span'
            variant='caption2'
            color='primary'
            onClick={handleClick}
            sx={truncatedTextStyle}
          >
            {captionInfo?.title ? captionInfo.title : ''}
          </Link>
        )}
        {showFavouriteIcon && (
          <FavouriteIcon
            isFavourite={favourite}
            onClick={onClickFavouriteIcon}
            styles={{ padding: 0 }}
          />
        )}
      </Box>
    </Box>
  );
};

export default CustomChannelHeader;
