import ErrorFilled from '@mui/icons-material/Error';
import Error from '@mui/icons-material/ErrorOutlineOutlined';
import { Box, Tooltip } from '@mui/material';
import React, { ReactNode } from 'react';

interface WarningIconProps {
  tooltipTitle: ReactNode;
  fill?: boolean;
}

const WarningIcon = ({ tooltipTitle, fill }: WarningIconProps) => {
  return (
    <Tooltip title={tooltipTitle}>
      <Box
        sx={{
          color: 'warn',
          alignItems: 'center',
          display: 'flex',
          marginLeft: '4px',
        }}
        data-testid='warning-icon'
      >
        {fill ? <ErrorFilled /> : <Error />}
      </Box>
    </Tooltip>
  );
};

export default WarningIcon;
