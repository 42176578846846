import { Box, Grid, Stack, Typography } from '@mui/material';
import { Text, useMedia } from '@operto/ui';
import AlertsCard from 'Common/Widgets/Overview/AlertsCard';
import CheckInCard from 'Common/Widgets/Overview/CheckInCard';
import CheckOutCard from 'Common/Widgets/Overview/CheckOutCard';
import { companySelector } from 'company/state/companySelectors';
import {
  getPropertyAlertsSummary,
  getReservationsByRoomStatusCard,
} from 'dashboard/state/dashboardActions';
import MainActivityCard from 'event/SmartCards/MainActivityCard';
import { FilterType } from 'event/eventType';
import { getEvents } from 'event/state/eventActions';
import { EventsTotalCountSelector, SearchEventsSelector } from 'event/state/eventSelectors';
import { logger } from 'lib/logger';
import { PropertyFilterType } from 'property/propertyType';
import { getProperties } from 'property/state/propertyActions';
import * as React from 'react';
import { useGuestPortal } from 'redux/guestportal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { currentUserSelector } from 'user/state/userSelectors';

const DashboardPage = () => {
  const dispatch = useAppDispatch();
  const { fetchSettings } = useGuestPortal();
  const user = useAppSelector(currentUserSelector());

  const name = user?.name;
  const { isMobile } = useMedia();
  const [keyword, setKeyword] = React.useState('');
  const [filterType, setFilterType] = React.useState(FilterType.ALL_ACTIVITIES);
  const [mainActivityLoading, setMainActivityLoading] = React.useState(false);
  const [isAlertLoading, setIsAlertLoading] = React.useState(false);
  const [isTodayCheckoutLoading, setIsTodayCheckoutLoading] = React.useState(false);
  const [isTodayCheckinLoading, setIsTodayCheckinLoading] = React.useState(false);
  const company = useAppSelector(companySelector());

  const meta = useAppSelector(EventsTotalCountSelector(filterType));
  const filteredEvents = useAppSelector(SearchEventsSelector(keyword, filterType));

  React.useEffect(() => {
    setIsAlertLoading(true);
    setIsTodayCheckoutLoading(true);
    setIsTodayCheckinLoading(true);

    const dispatchAsync = async () => {
      await dispatch(getPropertyAlertsSummary());
      setIsAlertLoading(false);
      await dispatch(getProperties());
      await dispatch(getProperties({ filterType: PropertyFilterType.CLEANING }));

      await dispatch(getReservationsByRoomStatusCard('todays-check-out'));
      setIsTodayCheckoutLoading(false);

      await dispatch(getReservationsByRoomStatusCard('todays-check-in'));
      setIsTodayCheckinLoading(false);
    };

    dispatchAsync();
  }, [dispatch]);

  const handleFetchMore = React.useCallback(
    async (pageNum: number) => {
      setMainActivityLoading(true);
      try {
        await dispatch(getEvents(filterType, pageNum));
      } catch (error) {
        logger.error(error);
      }

      setMainActivityLoading(false);
    },
    [filterType, dispatch],
  );

  React.useEffect(() => {
    handleFetchMore(0);
  }, [handleFetchMore]);

  React.useEffect(() => {
    if (company?.guestPortalStatus === 'active') {
      fetchSettings();
    }
  }, [company?.guestPortalStatus, fetchSettings]);

  const handleSearchChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    setKeyword(event.currentTarget.value);
  };

  const handleTabChange = (newValue: FilterType) => {
    setFilterType(newValue);
  };

  return (
    <Stack alignItems='center'>
      {!isMobile && (
        <Text variant='h1-300' style={{ marginY: 6 }}>{`Hi ${
          name || 'there'
        }. Here's what's happening today!`}</Text>
      )}
      <Grid direction={isMobile ? 'column-reverse' : 'row'} container justifyContent='center'>
        <Grid item xs={12} lg={8}>
          {!isMobile && (
            <Typography variant='subtitle1' align='left'>
              Today&apos;s Activity
            </Typography>
          )}

          <Box sx={{ marginTop: '24px', maxWidth: isMobile ? window.innerWidth : '100%' }}>
            <MainActivityCard
              meta={meta}
              filterIdx={filterType}
              events={filteredEvents}
              onSearch={handleSearchChange}
              onTabChange={handleTabChange}
              onFetchMore={() => handleFetchMore(meta.pageNum + 1)}
              isMainActivity
              loading={mainActivityLoading}
            />
          </Box>
        </Grid>

        <Grid item xs={12} lg={3} sx={{ marginLeft: '40px' }}>
          {!isMobile && (
            <Typography variant='subtitle1' align='left'>
              Things to watch
            </Typography>
          )}

          <Grid container spacing={3} sx={{ marginTop: '0' }}>
            <Grid item xs={12}>
              <LoadingContainer loading={isAlertLoading}>
                <AlertsCard isCarouselCard />
              </LoadingContainer>
            </Grid>

            <Grid item xs={12}>
              <LoadingContainer loading={isTodayCheckinLoading}>
                <CheckInCard isCarouselCard />
              </LoadingContainer>
            </Grid>

            <Grid item xs={12}>
              <LoadingContainer loading={isTodayCheckoutLoading}>
                <CheckOutCard isCarouselCard />
              </LoadingContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DashboardPage;
