import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import * as React from 'react';

export interface ValidationIconProps {
  isValid: boolean;
}

const ValidationIcon = ({ isValid }: ValidationIconProps) =>
  isValid ? <CheckCircleIcon color='primary' /> : <CancelIcon color='error' />;

export default ValidationIcon;
