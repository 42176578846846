// @ts-nocheck - FIXME - hard to refactor this file if can't see errors
import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { UNCATEGORIZED_ID } from 'helper/helper';
import omit from 'lodash/omit';
import { OpertoLogger } from 'Logger/logger';
import { v4 as uuidv4 } from 'uuid';
import { ImageUploadType, IPage } from '.';
import { newCategoryTemplate } from './hooks/useGuidesCategories';
import {
  CustomText,
  GuestPortal,
  GuestPortalResponse,
  Guide,
  Host,
  ICategory,
  IEntranceInstructions,
  ISection,
  Order,
  PropertyAddress,
  Settings,
  Theme,
} from './models';
import {
  categoryToJSON,
  customTextToJSON,
  gpLinkToJson,
  hostToJSON,
  parseAddress,
  parseCategories,
  parseCustomText,
  parseFeatures,
  parseHost,
  parsePages,
  parsePageToCategoryResponse,
  parseProperties,
  parseSections,
  parseSettings,
  parseTheme,
  propertyAddressToJSON,
  settingsToJSON,
  themeToJSON,
} from './parser';

export const postGuestPortalActivate = createAsyncThunk(
  'postGuestPortalActivate',
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/global/initialize`,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      OpertoLogger.Log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const postInitializeGuestPortalUnit = createAsyncThunk(
  'postInitializeGuestPortalUnit',
  async (propertyId: number | string, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${propertyId}/initialize`,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      OpertoLogger.Log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchGuestPortalActivate = createAsyncThunk(
  // TODO should probably have a gp endpoint instead of using compay
  'patchGuestPortalActivate',
  async (isEnabled: boolean, thunkAPI) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/v2/GUEST_PORTAL_UNINSTALL_URL`,
        {
          guest_portal_enabled: isEnabled ? 'active' : 'inactive',
        },
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      OpertoLogger.Log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const getGuestPortal = createAsyncThunk('getGuestPortal', async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/guest-portal/global`);
    const { status, message } = response.data as GuestPortalResponse;
    if (status === 'success') {
      return response.data;
    }

    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    OpertoLogger.Log(error);
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getAssignedProperties = createAsyncThunk(
  'getAssignedProperties',
  async (
    data: {
      keyword: string;
      newPage: number;
      numPerPage: number;
      append?: boolean;
      filter?: string;
    },
    thunkAPI,
  ) => {
    try {
      const params = {
        keyword: data.keyword,
        pageNum: data.newPage,
        numPerPage: data.numPerPage,
      };
      if (data.filter) {
        params.status = data.filter;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/assigned-units`,
        { params },
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        response.data.shouldAppend = data?.append;
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = {
        keyword: data.keyword,
        pageNum: data.newPage,
        numPerPage: data.numPerPage,
      };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('get assigned properties', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const generateGuestPortal = createAsyncThunk(
  'generateGuestPortal',
  async (data: { property_id: number; skip_welcome_screens: boolean }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/preview`,
        data,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }
      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { ...data };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('generate guest portals', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const movePageToNewCategory = createAsyncThunk(
  'moveToNewCategory',
  async (data: { page: IPage; newCategoryId: string }, thunkAPI) => {
    try {
      const { page, newCategoryId } = data;
      // guides/sections/

      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections/${page.section_id}/categories/${page.category_id}/contents/move`,
        {
          newCategoryId,
          contentId: page.content_id,
        },
      );
      const { status } = response;
      if (response && status === 200) {
        return response.data;
      }

      return thunkAPI.rejectWithValue(false);
    } catch (error) {
      OpertoLogger.Log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const movePageToNewCategoryWithOrder = createAsyncThunk(
  'movePageToNewCategoryWithOrder',
  async (
    data: {
      page: IPage;
      newCategoryId: string;
      contents_order?: Order[];
      categories_order?: Order[];
    },
    thunkAPI,
  ) => {
    try {
      const { page, newCategoryId, contents_order, categories_order } = data;
      const payload = {
        newCategoryId,
        contentId: page.content_id,
      } as {
        newCategoryId: string;
        contentId: string;
        contents_order?: Order[];
        categories_order?: Order[];
      };
      if (contents_order?.length > 0) {
        payload.contents_order = contents_order;
      }
      if (categories_order?.length > 0) {
        payload.categories_order = categories_order;
        // TODO: UNCATEGORIZED / no login in thunks
        //  This will make sure category 0 is in the order no matter what.
        //  Not having category 0 in the payload will break the data.
        //  This logic should be handled in the backend to match best practices.
        //  As of July 5th. the decision is made to do this in FE to push the feature out asap.
        //  This is not approved by Shayan forever.
        //  If you see this a few months after July 5th. sorry.
        const isUncategorizedInTheOrder =
          payload?.categories_order?.filter(
            categoryOrder => categoryOrder.id === 'bb7172e8-8add-4622-92bf-9f739ec279f0',
          ).length > 0;
        if (!isUncategorizedInTheOrder) {
          const tempCatOrder = [] as Order[];
          tempCatOrder.push({
            id: 'bb7172e8-8add-4622-92bf-9f739ec279f0',
            type: 'category',
          } as Order);
          payload.categories_order = tempCatOrder.concat(payload.categories_order);
        }
      }

      // guides/sections/

      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections/${page.section_id}/categories/${page.category_id}/contents/move-with-reorder`,
        payload,
      );
      const { status } = response;
      if (response && status === 200) {
        return response.data;
      }

      return thunkAPI.rejectWithValue(false);
    } catch (error) {
      OpertoLogger.Log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const getHostData = createAsyncThunk('getHostData', async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/guest-portal/host-data`);
    const { status, message } = response.data as GuestPortalResponse;
    if (status === 'success') {
      return response.data;
    }

    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    OpertoLogger.Log(error);
    return thunkAPI.rejectWithValue(error.message);
  }
});
export const getInfoData = createAsyncThunk('getInfoData', async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/guest-portal/info-data`);
    const { status, message } = response.data as GuestPortalResponse;
    if (status === 'success') {
      return response.data;
    }

    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    OpertoLogger.Log(error);
    return thunkAPI.rejectWithValue(error.message);
  }
});
export const getCustomText = createAsyncThunk('getCustomText', async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/guest-portal/messages`);
    const { status, message } = response.data as GuestPortalResponse;
    if (status === 'success') {
      return response.data;
    }

    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    OpertoLogger.Log(error);
    return thunkAPI.rejectWithValue(error.message);
  }
});
export const getPropertyHostData = createAsyncThunk(
  'getPropertyHostData',
  async (propertyId: number | string, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${propertyId}/host-data`,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { propertyId };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('get property host data', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const getPropertyInfoData = createAsyncThunk(
  'getPropertyInfoData',
  async (propertyId: number | string, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${propertyId}/info-data`,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { propertyId };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('get property info data', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const getPropertyCustomText = createAsyncThunk(
  'getPropertyCustomText',
  async (propertyId: number | string, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${propertyId}/messages`,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { propertyId };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('get property custom text', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const getTermsData = createAsyncThunk('getTermsData', async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/guest-portal/terms-data`);
    const { status, message } = response.data as GuestPortalResponse;
    if (status === 'success') {
      return response.data;
    }

    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    OpertoLogger.Log(error);
    return thunkAPI.rejectWithValue(error.message);
  }
});
export const getGuidesFeatured = createAsyncThunk('getGuidesFeatured', async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/guest-portal/features`);
    const { status, message } = response.data as GuestPortalResponse;
    if (status === 'success') {
      return response.data;
    }

    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    OpertoLogger.Log(error);
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getGuidesSections = createAsyncThunk('getGuidesSections', async (_, thunkAPI) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections`,
    );
    const { status, message } = response.data as GuestPortalResponse;
    if (status === 'success') {
      return response.data;
    }

    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    OpertoLogger.Log(error);
    return thunkAPI.rejectWithValue(error.message);
  }
});
export const getPropertyGuidesSection = createAsyncThunk(
  'getPropertyGuidesSection',
  async (propertyId: number, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${propertyId}/guides/sections`,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { propertyId };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('get property guides section', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const getGuidesPages = createAsyncThunk(
  'getGuidesPages',
  async (sectionId: string, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections/${sectionId}/contents`,
      );
      const { status, message } = response.data as GuestPortalResponse;

      if (status === 'success') {
        const { records, order } = response.data.data;
        if (records.length > order.length) {
          // if records are more than order, it means some records are missing in order, so we need to add them. (records = source of truth)
          const orderIds = new Set(order.map(o => o.id));
          const missingRecordsInOrder = records.filter(record => !orderIds.has(record.content_id));
          const missingOrder = missingRecordsInOrder.map(record => ({
            id: record.content_id,
            type: 'content',
          }));
          await thunkAPI.dispatch(
            patchGuidePagesOrder({ order: order.concat(missingOrder), section_id: sectionId }),
          );
        } else if (order.find((orderItem: Order) => orderItem.type === 'category')) {
          // if order has category type and the item is in records, we need to change the type to content. (records = source of truth)
          const parsedPageOrder = parsePages(response.data.data).order;
          await thunkAPI.dispatch(
            patchGuidePagesOrder({ order: parsedPageOrder, section_id: sectionId }),
          );
        }
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { sectionId };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('get guides pages', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const getPropertyGuidesPages = createAsyncThunk(
  'getPropertyGuidesPages',
  async (params: { propertyId: number; sectionId: string }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${params.propertyId}/guides/sections/${params.sectionId}/contents`,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { ...params };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('get property guides pages', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const getPropertyGuidesCategories = createAsyncThunk(
  'getPropertyGuidesCategories',
  async (params: { propertyId: number; sectionId: string }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${params.propertyId}/guides/sections/${params.sectionId}/categories`,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { ...params };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('get property guides categories', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const getGuidesCategories = createAsyncThunk(
  'getGuidesCategories',
  async (sectionId: string, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections/${sectionId}/categories`,
      );
      const { status, message } = response.data as GuestPortalResponse;

      // a hack to fix the ordering issues of old accounts, this should be removed after a while
      if (status === 'success') {
        const parsedCategoryOrder = parseCategories(response.data.data).order;
        const hasContentInOrder = response.data.data?.order.some(order => order.type === 'content');
        if (hasContentInOrder) {
          await thunkAPI.dispatch(
            patchGuideCategoriesOrder({ order: parsedCategoryOrder, section_id: sectionId }),
          );
        }
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { sectionId };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('get guides categories', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const getGuidesAssignedUnitsOrder = createAsyncThunk(
  'getGuidesAssignedUnitsOrder',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections/order`,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      OpertoLogger.Log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const getGuidesPagesOrder = createAsyncThunk(
  'getGuidesPagesOrder',
  async (sectionId: string, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections/${sectionId}/contents/order`,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { sectionId };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('get guides pages order', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const getGuidesCategoriesOrder = createAsyncThunk(
  'getGuidesCategoriesOrder',
  async (sectionId: string, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections/${sectionId}/categories/order`,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { sectionId };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('get guides categories order', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const getPropertyGuidesSectionsOrder = createAsyncThunk(
  'getPropertyGuidesSectionsOrder',
  async (propertyId: number | string, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${propertyId}/guides/sections/order`,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { propertyId };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('get property guides sections order', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const getPropertyGuidesPagesOrder = createAsyncThunk(
  'getPropertyGuidesPagesOrder',
  async (data: { sectionId: string; propertyId: number | string }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${data.propertyId}/guides/sections/${data.sectionId}/contents/order`,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { ...data };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('get property guides pages order', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const getPropertyGuidesCategoriesOrder = createAsyncThunk(
  'getPropertyGuidesCategoriesOrder',
  async (data: { sectionId: string; propertyId: number | string }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${data.propertyId}/guides/sections/${data.sectionId}/categories/order`,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { ...data };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('get property guides categories order', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const getThemeData = createAsyncThunk('getThemeData', async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/guest-portal/theme-data`);
    const { status, message } = response.data as GuestPortalResponse;
    if (status === 'success') {
      return response.data;
    }

    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    OpertoLogger.Log(error);
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getSettings = createAsyncThunk('getSettings', async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/guest-portal/settings`);
    const { status, message } = response.data as GuestPortalResponse;
    if (status === 'success') {
      return response.data;
    }

    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    OpertoLogger.Log(error);
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const patchActivateGPUnit = createAsyncThunk(
  'patchActivateGPUnit',
  async (payload: { property_ids: number[]; status: 'active' | 'inactive' }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/activation`,
        payload,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        response.data.activtateStatus = payload.status;
        response.data.propertyIds = payload.property_ids;
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = {
        ...payload,
      };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch activate GP unit', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const patchGuideSection = createAsyncThunk(
  'patchGuideSection',
  async (section: ISection, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections`,
        section,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { section };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch guide section', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchGuideCategory = createAsyncThunk(
  'patchGuideCategory',
  async (category: ICategory, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections/${category.section_id}/categories`,
        categoryToJSON(category),
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { category };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch guide category', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const postGuideSection = createAsyncThunk(
  'postGuideSection',
  async (section: ISection, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections`,
        section,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        // FIXME: this should be part of the POST section API
        // in the BE to create uncategorized item right away.
        // but for some reason we are hacking it in FE.
        const section = response.data.data as ISection;
        const uncategorized = newCategoryTemplate('');
        thunkAPI.dispatch(
          postGuideCategory({
            ...uncategorized,
            category: 'Uncategorized',
            category_id: UNCATEGORIZED_ID,
            section_id: section.section_id,
            published: true,
          }),
        );

        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { section };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch guide section', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const postGuideCategory = createAsyncThunk(
  'postGuideCategory',
  async (category: ICategory, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections/${category.section_id}/categories`,
        category,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { category };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch guide category', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchGuideUnset = createAsyncThunk(
  'patchGuideUnset',
  async (data: { section_id: string; category_id?: string; content_id?: string }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/unset-guide`,
        data,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        let type = 'section';
        if (data.content_id !== '') {
          type = 'content';
        } else if (data.content_id === '' && data.category_id !== '') {
          type = 'category';
        }
        response.data.type = type;
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { ...data };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch guide unset', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchGuidePages = createAsyncThunk(
  'patchGuidePages',
  async (page: IPage, thunkAPI) => {
    // using this to submit the old category id
    const categoryId = page?.oldCategoryId || page?.category_id;

    // Just using this for now since we're doing restructuring
    page = omit(page, 'oldCategoryId');
    page = omit(page, 'categoriesList');
    page = omit(page, 'section');
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections/${page.section_id}/categories/${categoryId}/contents`,
        page,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { page };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch guide pages', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const postGuidePage = createAsyncThunk('postGuidePage', async (page: IPage, thunkAPI) => {
  try {
    // Just using this for now since we're doing restructuring
    page = omit(page, 'oldCategoryId');
    page = omit(page, 'categoriesList');
    page = omit(page, 'section');

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections/${page.section_id}/categories/${page.category_id}/contents`,
      page,
    );
    const { status, message } = response.data as GuestPortalResponse;
    if (status === 'success') {
      return response.data;
    }

    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    const metadata = { page };
    OpertoLogger.Log(error);
    OpertoLogger.LeaveBreadCrumb('patch guide page', metadata, 'state');
    return thunkAPI.rejectWithValue(error.message);
  }
});
export const postPropertyGuidePage = createAsyncThunk(
  'postGuidePages',
  async ({ propertyId, page }: { propertyId: number; page: IPage }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v2/property/${propertyId}/guest-portal/guides/sections/${page.section_id}/categories/${page.category_id}/contents`,
        page,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { propertyId, page };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch guide pages', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const patchPropertyGuidePages = createAsyncThunk(
  'patchPropertyGuidePages',
  async ({ propertyId, page }: { propertyId: number; page: IPage }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${propertyId}/guides/sections/${page.section_id}/categories/${page.category_id}/contents`,
        page,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { propertyId, page };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch property guide pages', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const patchPropertyGuideSection = createAsyncThunk(
  'patchPropertyGuideSection',
  async ({ propertyId, section }: { propertyId: number; section: ISection }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${propertyId}/guides/sections`,
        section,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { propertyId, section };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch property guide section', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const patchPropertyGuideCategory = createAsyncThunk(
  'patchPropertyGuideCategory',
  async ({ propertyId, category }: { propertyId: string; category: ICategory }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${propertyId}/guides/sections/${category.section_id}/categories`,
        categoryToJSON(category),
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { propertyId, category };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch property guide category', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchGuidePageUnset = createAsyncThunk(
  'patchGuidePageUnset',
  async (content_id: string, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/unset-page/${content_id}`,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { content_id };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch guide page unset', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchGuideSectionsOrder = createAsyncThunk(
  'patchGuideSectionsOrder',
  async (order: Order[], thunkAPI) => {
    try {
      if (order?.length === 0) {
        return thunkAPI.rejectWithValue('patch null');
      }

      const data = { sections_order: order };
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections/order`,
        data,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { order };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch guide sections order', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchGuideFeaturedOrder = createAsyncThunk(
  'patchGuideFeaturedOrder',
  async (order: Order[], thunkAPI) => {
    try {
      if (order?.length === 0) {
        return thunkAPI.rejectWithValue('patch null');
      }

      const data = { featured_list: order };
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/features`,
        data,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { order };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch guide featured order', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchGuidePagesOrder = createAsyncThunk(
  'patchGuidePagesOrder',
  async (data: { order: Order[]; section_id: string }, thunkAPI) => {
    try {
      if (data.order?.length === 0) {
        return thunkAPI.rejectWithValue('patch null');
      }

      const newOrder = { contents_order: data.order };
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections/${data.section_id}/contents/order`,
        newOrder,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { ...data };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch guide pages order', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchGuideCategoriesOrder = createAsyncThunk(
  'patchGuideCategoriesOrder',
  async (data: { order: Order[]; section_id: string }, thunkAPI) => {
    try {
      if (data.order?.length === 0) {
        return thunkAPI.rejectWithValue('patch null');
      }

      const newOrder = { categories_order: data.order };
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/sections/${data.section_id}/categories/order`,
        newOrder,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { ...data };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch guide categories order', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const patchPropertyGuideSectionsOrder = createAsyncThunk(
  'patchPropertyGuideSectionsOrder',
  async (data: { order: Order[]; propertyId: string | number }, thunkAPI) => {
    try {
      if (data.order?.length === 0) {
        return thunkAPI.rejectWithValue('patch null');
      }

      const newOrder = { sections_order: data.order };
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${data.propertyId}/guides/sections/order`,
        newOrder,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { ...data };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch property guide sections order', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchPropertyGuidePagesOrder = createAsyncThunk(
  'patchPropertyGuidePagesOrder',
  async (data: { order: Order[]; section_id: string; propertyId: string | number }, thunkAPI) => {
    try {
      if (data.order?.length === 0) {
        return thunkAPI.rejectWithValue('patch null');
      }

      const newOrder = { contents_order: data.order };
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${data.propertyId}/guides/sections/${data.section_id}/contents/order`,
        newOrder,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { ...data };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch property guide pages order', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchPropertyGuideCategoriesOrder = createAsyncThunk(
  'patchPropertyGuideCategoriesOrder',
  async (data: { order: Order[]; section_id: string; propertyId: string | number }, thunkAPI) => {
    try {
      if (data.order?.length === 0) {
        return thunkAPI.rejectWithValue('patch null');
      }

      const newOrder = { contents_order: data.order };
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${data.propertyId}/guides/sections/${data.section_id}/categories/order`,
        newOrder,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { ...data };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch property guide categories order', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchGuideImages = createAsyncThunk(
  'patchGuideImages',
  async (imagesBase64: string[], thunkAPI) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/image/guides`,
        imagesBase64.map(base64 => ({
          image_filename: uuidv4(),
          image_base64: base64,
        })),
      );

      const { status, message, data } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return data.urls;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { imagesBase64 };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch guide images', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchRandomImages = createAsyncThunk(
  'patchRandomImages',
  async (data: { imagesBase64: string[]; imageType: ImageUploadType }, thunkAPI) => {
    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_URL}/v2/guest-portal/image`, {
        image_filename: uuidv4(),
        image_base64: data.imagesBase64[0],
        image_type: data.imageType,
      });

      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return [response.data.data.url];
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { ...data };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch random images', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchTheme = createAsyncThunk('patchTheme', async (theme: Theme, thunkAPI) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/v2/guest-portal/theme-data`,
      themeToJSON(theme),
    );

    const { status, message } = response.data as GuestPortalResponse;
    if (status === 'success') {
      return message;
    }

    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    const metadata = { theme };
    OpertoLogger.Log(error);
    OpertoLogger.LeaveBreadCrumb('patch theme', metadata, 'state');
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const patchHost = createAsyncThunk('patchHost', async (host: Host, thunkAPI) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/v2/guest-portal/host-data`,
      hostToJSON(host),
    );

    const { status, message } = response.data as GuestPortalResponse;
    if (status === 'success') {
      return message;
    }

    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    const metadata = { host };
    OpertoLogger.Log(error);
    OpertoLogger.LeaveBreadCrumb('patch host', metadata, 'state');
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const patchCustomText = createAsyncThunk(
  'patchCustomText',
  async (text: CustomText, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/messages`,
        customTextToJSON(text),
      );

      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return message;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { text };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch custom text', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchPropertyAddress = createAsyncThunk(
  'patchPropertyAddress',
  async (address: PropertyAddress, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/info-data`,
        propertyAddressToJSON(address),
      );

      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return message;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { address };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch property address', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchPropertyHost = createAsyncThunk(
  'patchPropertyHost',
  async (host: Host, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${host.propertyId}/host-data`,
        hostToJSON(host),
      );

      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return message;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { host };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch property host', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchPropertyCustomText = createAsyncThunk(
  'patchPropertyCustomText',
  async ({ propertyId, text }: { propertyId: string; text: CustomText }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${propertyId}/messages`,
        customTextToJSON(text),
      );

      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return message;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { text };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch property costom text', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchPropertyPropertyAddress = createAsyncThunk(
  'patchPropertyPropertyAddress',
  async (address: PropertyAddress, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${address.propertyId}/info-data`,
        propertyAddressToJSON(address),
      );

      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return message;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { address };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch property address', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchSettings = createAsyncThunk(
  'patchSettings',
  async (settings: Settings, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/settings`,
        settingsToJSON(settings),
      );

      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return message;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { settings };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch settings', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const getFeatures = createAsyncThunk('getFeatures', async (_, thunkAPI) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/v2/guest-portal/guides/features`,
    );

    const { status, message } = response.data as GuestPortalResponse;
    if (status === 'success') {
      return response.data;
    }

    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    OpertoLogger.Log(error);
    return thunkAPI.rejectWithValue(error.message);
  }
});
export const getUnitFeatures = createAsyncThunk(
  'getUnitFeatures',
  async (property_id: number, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/properties/${property_id}/guides/features`,
      );

      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = { property_id };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('get unit features', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchFeatures = createAsyncThunk('patchFeatures', async (guide: Guide, thunkAPI) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/v2/guest-portal/features`,
      { section_id: guide.id, featured_list: guide.featuredIds },
    );

    const { status, message } = response.data as GuestPortalResponse;
    if (status === 'success') {
      return message;
    }

    return thunkAPI.rejectWithValue(message);
  } catch (error) {
    const metadata = { ...guide };
    OpertoLogger.Log(error);
    OpertoLogger.LeaveBreadCrumb('patch features', metadata, 'state');
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getEntranceInstructions = createAsyncThunk(
  'getEntranceInstructions',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/settings/entrance-instructions`,
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      OpertoLogger.Log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchEntranceInstructions = createAsyncThunk(
  'patchEntranceInstructionsEnabled',
  async (data: IEntranceInstructions, thunkAPI) => {
    try {
      const { entrance_instructions_enabled, ...restData } = data;

      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/guest-portal/settings/entrance-instructions`,
        {
          ...restData,
          entrance_instructions_enabled: entrance_instructions_enabled.map(
            ({ propertyId, enabled }) => ({
              propertyId,
              enabled,
            }),
          ),
        },
      );
      const { status, message } = response.data as GuestPortalResponse;
      if (status === 'success') {
        return message;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const metadata = {
        ...data,
      };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('patch GP Entrance instructions', metadata, 'state');
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<GuestPortal>) => {
  builder.addCase(postGuestPortalActivate.fulfilled, state => {
    state.status = 'succeeded';
    // NOTE: dont need to show snackbar for globals
    // state.message = (action.payload as GuestPortalResponse).message;
  });
  builder.addCase(patchGuestPortalActivate.fulfilled, state => {
    state.status = 'succeeded';
    // NOTE: dont need to show snackbar for globals
    // state.message = (action.payload as GuestPortalResponse).message;
  });

  builder.addCase(patchGuestPortalActivate.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });

  builder.addCase(
    postInitializeGuestPortalUnit.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;

      const id = action.meta.arg.toString();
      const assignedProperty = state.assignedProperties?.byId[id];
      if (assignedProperty) {
        assignedProperty.guest_portal_initialized = true;
      }
    },
  );
  builder.addCase(getGuidesSections.fulfilled, (state, action: PayloadAction<string>) => {
    state.status = 'succeeded';
    state.sections = parseSections(action.payload.data);
  });
  builder.addCase(getGuidesSections.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getGuidesSections.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(movePageToNewCategory.fulfilled, (state, action: PayloadAction<string>) => {
    state.status = 'succeeded';
    state.message = action.payload.message;

    const page = action.payload.data as IPage;
    state.pages.byId[page.content_id] = page;
  });
  builder.addCase(movePageToNewCategory.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(movePageToNewCategory.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  // NOTE: not used currently, this API is too buggy
  builder.addCase(
    movePageToNewCategoryWithOrder.fulfilled,
    (state, action: PayloadAction<string>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;
      state = parsePageToCategoryResponse(state, action.payload);
    },
  );
  builder.addCase(movePageToNewCategoryWithOrder.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(movePageToNewCategoryWithOrder.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(getGuidesPages.fulfilled, (state, action: PayloadAction<string>) => {
    state.status = 'succeeded';
    state.pages = parsePages(action.payload.data);
  });
  builder.addCase(getGuidesPages.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getGuidesPages.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(getGuidesCategories.fulfilled, (state, action: PayloadAction<string>) => {
    state.status = 'succeeded';
    state.categories = parseCategories(action.payload.data);
  });
  builder.addCase(getGuidesCategories.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getGuidesCategories.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(getPropertyGuidesSection.fulfilled, (state, action: PayloadAction<string>) => {
    state.status = 'succeeded';
    state.sections = parseSections(action.payload.data);
  });
  builder.addCase(getPropertyGuidesSection.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getPropertyGuidesSection.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(getPropertyGuidesPages.fulfilled, (state, action: PayloadAction<string>) => {
    state.status = 'succeeded';
    state.pages = parsePages(action.payload.data);
  });
  builder.addCase(getPropertyGuidesPages.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getPropertyGuidesPages.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(getPropertyGuidesCategories.fulfilled, (state, action: PayloadAction<string>) => {
    state.status = 'succeeded';
    state.categories = parseCategories(action.payload.data);
  });
  builder.addCase(getPropertyGuidesCategories.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getPropertyGuidesCategories.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    getPropertyGuidesSectionsOrder.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.sections.order = action.payload.data;
    },
  );
  builder.addCase(getPropertyGuidesSectionsOrder.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getPropertyGuidesSectionsOrder.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    getPropertyGuidesPagesOrder.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;
      state.pages.order = action.payload.data?.contents_order;
    },
  );
  builder.addCase(getPropertyGuidesPagesOrder.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getPropertyGuidesPagesOrder.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    getPropertyGuidesCategoriesOrder.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.categories.order = action.payload.data;
    },
  );
  builder.addCase(getPropertyGuidesCategoriesOrder.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getPropertyGuidesCategoriesOrder.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    getGuidesAssignedUnitsOrder.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.assignedProperties.order = action.payload.data;
    },
  );
  builder.addCase(getGuidesAssignedUnitsOrder.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getGuidesAssignedUnitsOrder.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(getHostData.fulfilled, (state, action: PayloadAction<GuestPortalResponse>) => {
    state.status = 'succeeded';
    // NOTE: dont need to show snackbar for globals
    // state.message = (action.payload as GuestPortalResponse).message;
    state.host = parseHost(action.payload.data);
  });
  builder.addCase(getHostData.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getHostData.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(getCustomText.fulfilled, (state, action: PayloadAction<GuestPortalResponse>) => {
    state.status = 'succeeded';
    state.customText = parseCustomText(action.payload.data);
  });
  builder.addCase(getCustomText.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getCustomText.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    getPropertyHostData.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      // NOTE: dont need to show snackbar for globals
      // state.message = (action.payload as GuestPortalResponse).message;
      state.host = parseHost(action.payload.data);
    },
  );
  builder.addCase(getPropertyHostData.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getPropertyHostData.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    getPropertyCustomText.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.customText = parseCustomText(action.payload.data);
    },
  );
  builder.addCase(getPropertyCustomText.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getPropertyCustomText.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    getPropertyInfoData.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.address = parseAddress(action.payload.data);
    },
  );
  builder.addCase(getPropertyInfoData.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getPropertyInfoData.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });

  builder.addCase(getThemeData.fulfilled, (state, action: PayloadAction<GuestPortalResponse>) => {
    state.status = 'succeeded';
    state.theme = parseTheme(action.payload.data);
  });
  builder.addCase(getThemeData.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getThemeData.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(getInfoData.fulfilled, (state, action: PayloadAction<GuestPortalResponse>) => {
    state.status = 'succeeded';
    state.address = parseAddress(action.payload.data);
  });
  builder.addCase(getInfoData.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getInfoData.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(getSettings.fulfilled, (state, action: PayloadAction<GuestPortalResponse>) => {
    state.status = 'succeeded';
    state.settings = parseSettings(action.payload.data);
  });
  builder.addCase(getSettings.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getSettings.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(getFeatures.fulfilled, (state, action: PayloadAction<GuestPortalResponse>) => {
    state.status = 'succeeded';
    state.featured = parseFeatures(action.payload.data);
  });
  builder.addCase(getFeatures.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getFeatures.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    getUnitFeatures.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.featured = parseFeatures(action.payload.data);
    },
  );
  builder.addCase(getUnitFeatures.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getUnitFeatures.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    getAssignedProperties.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';

      // NOTE: dont need to show snackbar for globals
      // state.message = (action.payload as GuestPortalResponse).message;
      if (action.payload?.shouldAppend) {
        const parsedProperties = parseProperties(action.payload.data);
        const orignalList = state.assignedProperties?.list || [];
        const orignalDict = state.assignedProperties?.byId || {};
        const newList = new Set([...orignalList, ...parsedProperties.list]);
        const newDict = { ...orignalDict, ...parsedProperties.byId };
        const newAssignedProperties = {
          byId: newDict,
          list: [...newList],
          meta: parsedProperties.meta,
        };
        state.assignedProperties = newAssignedProperties;
      } else {
        state.assignedProperties = parseProperties(action.payload.data);
      }
    },
  );
  builder.addCase(getAssignedProperties.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getAssignedProperties.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(getEntranceInstructions.fulfilled, (state, action) => {
    state.status = 'succeeded';
    state.entranceInstructions = action.payload.data;
    state.assignedProperties = parseProperties(action.payload.data);
  });
  builder.addCase(getEntranceInstructions.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getEntranceInstructions.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });

  builder.addCase(
    patchGuideSection.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;

      const section = action.payload.data as ISection;
      state.sections.byId[section.section_id] = section;
    },
  );
  builder.addCase(patchGuideSection.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchGuideSection.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    patchActivateGPUnit.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;

      const { propertyIds, activtateStatus } = action.payload;
      propertyIds?.forEach(id => {
        const assignedProperty = state.assignedProperties?.byId[id];
        if (assignedProperty) {
          assignedProperty.guest_portal_status = activtateStatus;
        }
      });
    },
  );
  builder.addCase(patchActivateGPUnit.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchActivateGPUnit.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    patchPropertyGuideSection.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;

      const section = action.payload.data as ISection;
      state.sections.byId[section.section_id] = section;
    },
  );
  builder.addCase(patchPropertyGuideSection.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchPropertyGuideSection.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(postGuidePage.fulfilled, (state, action: PayloadAction<GuestPortalResponse>) => {
    state.status = 'succeeded';
    state.message = action.payload.message;

    const page = action.payload.data as IPage;
    state.pages.byId[page.content_id] = page;
    state.pages.order = [{ id: page.content_id, type: 'content' }, ...state.pages.order];
  });
  builder.addCase(postGuidePage.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(postGuidePage.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    postPropertyGuidePage.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;

      const page = action.payload.data as IPage;
      state.pages.byId[page.content_id] = page;
      state.pages.order = [{ id: page.content_id, type: 'content' }, ...state.pages.order];
    },
  );
  builder.addCase(postPropertyGuidePage.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(postPropertyGuidePage.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });

  builder.addCase(
    patchGuideCategory.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;

      const category = action.payload.data as ICategory;
      state.categories.byId[category.category_id] = category;
    },
  );
  builder.addCase(patchGuideCategory.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchGuideCategory.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    patchPropertyGuideCategory.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;

      const category = action.payload.data as ICategory;
      state.categories.byId[category.category_id] = category;
    },
  );
  builder.addCase(patchPropertyGuideCategory.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchPropertyGuideCategory.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });

  builder.addCase(
    postGuideSection.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;

      const section = action.payload.data as ISection;
      state.sections.byId[section.section_id] = section;
      state.sections.order = [{ id: section.section_id, type: 'section' }, ...state.sections.order];
    },
  );
  builder.addCase(postGuideSection.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(postGuideSection.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });

  builder.addCase(
    postGuideCategory.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      // TODO: UNCATEGORIZED
      if (action?.meta?.arg?.category_id !== UNCATEGORIZED_ID) {
        state.status = 'succeeded';
        state.message = action.payload.message;
      }

      const category = action.payload.data as ICategory;
      state.categories.byId[category.category_id] = category;
      state.categories.order = [
        { id: category.category_id, type: 'category' },
        ...state.categories.order,
      ];
    },
  );
  builder.addCase(postGuideCategory.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(postGuideCategory.rejected, (state, action) => {
    // TODO: UNCATEGORIZED
    if (action?.meta?.arg?.category_id !== UNCATEGORIZED_ID) {
      state.status = 'failed';
      state.message = (action.payload as string) ?? null;
    }
  });

  builder.addCase(
    patchGuideUnset.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;

      switch (action.payload.type) {
        case 'section':
          {
            const sections = { ...state.sections };
            const sectionId = action?.meta?.arg?.section_id;
            if (sectionId) {
              delete sections.byId[sectionId];
              sections.order = sections.order.filter(({ id }) => id !== sectionId);
              state.sections = sections;
            }
          }
          break;
        case 'category':
          {
            const categories = { ...state.categories };
            const categoryId = action?.meta?.arg?.category_id;
            if (categoryId) {
              delete categories.byId[categoryId];
              categories.order = categories.order.filter(({ id }) => id !== categoryId);
              state.categories = categories;
            }
          }
          break;
        case 'content':
          {
            const pages = { ...state.pages };
            const pageId = action?.meta?.arg?.content_id;
            if (pageId) {
              delete pages.byId[pageId];
              pages.order = pages.order.filter(({ id }) => id !== pageId);
              state.pages = pages;
            }
          }
          break;
        default:
      }
    },
  );
  builder.addCase(patchGuideUnset.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(
    patchGuidePages.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;

      const page = action.payload.data as IPage;
      state.pages.byId[page.content_id] = page;
    },
  );
  builder.addCase(patchGuidePages.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchGuidePages.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    patchPropertyGuidePages.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;

      const page = action.payload.data as IPage;
      state.pages.byId[page.content_id] = page;
    },
  );
  builder.addCase(patchPropertyGuidePages.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchPropertyGuidePages.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(patchGuideUnset.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    patchGuidePageUnset.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;

      // TODO!!!
      // state.pages = parseGuides(action.payload.data);
    },
  );
  builder.addCase(patchGuidePageUnset.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchGuidePageUnset.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    patchGuideSectionsOrder.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;
      state.sections.order = action.payload.data;
    },
  );
  builder.addCase(patchGuideSectionsOrder.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchGuideSectionsOrder.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });

  builder.addCase(
    patchGuideFeaturedOrder.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;
      state.featured.order = action.payload.data;
    },
  );
  builder.addCase(patchGuideFeaturedOrder.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchGuideFeaturedOrder.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    patchGuidePagesOrder.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;
      state.pages.order = action.payload.data?.contents_order;
    },
  );
  builder.addCase(patchGuidePagesOrder.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchGuidePagesOrder.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    patchGuideCategoriesOrder.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;
      state.categories.order = action.payload.data?.categories_order;
    },
  );
  builder.addCase(patchGuideCategoriesOrder.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchGuideCategoriesOrder.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    patchPropertyGuideSectionsOrder.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;
      state.sections.order = action.payload.data;
    },
  );
  builder.addCase(patchPropertyGuideSectionsOrder.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchPropertyGuideSectionsOrder.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    patchPropertyGuidePagesOrder.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;
      state.pages.order = action.payload.data;
    },
  );
  builder.addCase(patchPropertyGuidePagesOrder.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchPropertyGuidePagesOrder.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    patchPropertyGuideCategoriesOrder.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload.message;
      state.categories.order = action.payload.data;
    },
  );
  builder.addCase(patchPropertyGuideCategoriesOrder.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchPropertyGuideCategoriesOrder.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(patchGuideImages.fulfilled, state => {
    state.status = 'succeeded';
    state.message = null;
  });
  builder.addCase(patchGuideImages.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchGuideImages.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(patchRandomImages.fulfilled, state => {
    state.status = 'succeeded';
    state.message = null;
  });
  builder.addCase(patchRandomImages.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchRandomImages.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(patchTheme.fulfilled, (state, action: PayloadAction<GuestPortalResponse>) => {
    state.status = 'succeeded';
    state.message = action.payload;
  });
  builder.addCase(patchTheme.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchTheme.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(patchHost.fulfilled, (state, action: PayloadAction<GuestPortalResponse>) => {
    state.status = 'succeeded';
    state.message = action.payload;
  });
  builder.addCase(patchHost.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchHost.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    patchCustomText.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload;
    },
  );
  builder.addCase(patchCustomText.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchCustomText.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    patchPropertyAddress.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload;
    },
  );
  builder.addCase(patchPropertyAddress.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchPropertyAddress.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    patchPropertyHost.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload;
    },
  );
  builder.addCase(patchPropertyHost.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchPropertyHost.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    patchPropertyCustomText.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload;
    },
  );
  builder.addCase(patchPropertyCustomText.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchPropertyCustomText.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(
    patchPropertyPropertyAddress.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.message = action.payload;
    },
  );
  builder.addCase(patchPropertyPropertyAddress.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchPropertyPropertyAddress.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(patchSettings.fulfilled, (state, action: PayloadAction<GuestPortalResponse>) => {
    state.status = 'succeeded';
    state.message = action.payload;
  });
  builder.addCase(patchSettings.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchSettings.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(patchEntranceInstructions.fulfilled, (state, action) => {
    state.status = 'succeeded';
    state.message = action.payload;
  });
  builder.addCase(patchEntranceInstructions.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchEntranceInstructions.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });

  builder.addCase(
    generateGuestPortal.fulfilled,
    (state, action: PayloadAction<GuestPortalResponse>) => {
      state.status = 'succeeded';
      state.gpLink = gpLinkToJson(action.payload.data);
    },
  );
  builder.addCase(generateGuestPortal.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(generateGuestPortal.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
};
