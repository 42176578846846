import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';
import { Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import format from 'date-fns-tz/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ISection, useGuestPortal, useGuestPortalStatus } from 'redux/guestportal';
import { useGuidesSections } from 'redux/guestportal/hooks/useGuidesSections';
import { useAppSelector } from 'redux/hooks';
import { SortTable } from 'ui-library/Components/table/SortTable';
import { userPermissionSelector } from 'user/state/userSelectors';
import ActionDropDownMenu, { ActionDropDownMenuProps } from '../../Common/ActionDropDownMenu';
import { GuidesActionsType } from '../types';

const columns = (
  onAction: (action: GuidesActionsType, section?: ISection) => void,
  isProperty: boolean,
  hasGuestPortal: boolean,
): GridColDef<ISection>[] => [
  {
    field: 'section',
    headerName: 'Guide Name',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, ISection>) => {
      return <Typography>{row.section}</Typography>;
    },
  },
  {
    field: 'created_by',
    headerName: 'Created By',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, ISection>) => {
      return <Typography>{row.created_by}</Typography>;
    },
  },
  {
    field: 'created_at',
    headerName: 'Date Created',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, ISection>) => {
      return <Typography>{format(fromUnixTime(row.created_at as number), 'P')}</Typography>;
    },
  },
  {
    field: 'last_edit_by',
    headerName: 'Last Edited By',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, ISection>) => {
      return <Typography>{row.last_edit_by}</Typography>;
    },
  },
  {
    field: 'featured',
    headerName: 'Featured',
    flex: 1,
    hide: isProperty,
    renderCell: ({ row }: GridRenderCellParams<unknown, ISection>) => {
      return row.featured && <StarIcon color='primary' />;
    },
  },
  {
    field: 'published',
    headerName: 'Published',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, ISection>) => {
      return row.published && <CheckCircleIcon color='primary' />;
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    renderCell: ({ row }: GridRenderCellParams<unknown, ISection>) => {
      const menuProps: ActionDropDownMenuProps = {
        disabled: !hasGuestPortal,
        onEdit: () => onAction('edit', row),
        onPublished: () => onAction('publish', row),
        ...(!isProperty && { onDelete: () => onAction('delete', row) }),
        ...(!isProperty && { onFeatured: () => onAction('feature', row) }),
      };

      return <ActionDropDownMenu {...menuProps} />;
    },
  },
];

export type GuidesTableProps = {
  onAction: (action: GuidesActionsType, section?: ISection) => void;
  loading?: boolean;
};

const GuidesTable = ({ onAction, loading }: GuidesTableProps) => {
  const { propertyId } = useParams();
  const navigate = useNavigate();
  const hasGuestPortal = useAppSelector(userPermissionSelector());
  const { sectionsList } = useGuestPortal();
  const { sort } = useGuidesSections();
  const { isLoading } = useGuestPortalStatus();
  const [filteredList, setFilteredList] = useState<ISection[]>([]);

  const handleSearch = useCallback(
    (value?: string) => {
      if (!value?.length) {
        return setFilteredList(sectionsList);
      }

      const filter = value.toLowerCase();
      setFilteredList(
        sectionsList.filter(
          item =>
            item.section?.toLowerCase().includes(filter) ||
            item.created_by?.toLowerCase().includes(filter) ||
            item.last_edit_by?.toLowerCase().includes(filter),
        ),
      );
    },
    [sectionsList],
  );

  const handleRowClick = ({ row }: GridRowParams<ISection>) => {
    const sectionId = row.section_id;
    const url = propertyId
      ? `/units/${propertyId}/guest-portal/guides/${sectionId}/pages`
      : `/guest-portal/guides/${sectionId}/pages`;
    navigate(url);
  };

  const handleSort = (order: ISection[]) => {
    sort(order.map(o => ({ id: o.section_id, type: 'section' })));
  };

  return (
    <SortTable
      title='Guides'
      initialState={{ pinnedColumns: { right: ['actions'] } }}
      loading={loading || isLoading}
      rows={filteredList}
      getRowId={row => row.section_id}
      columns={columns(onAction, !!propertyId, hasGuestPortal)}
      rowReordering={propertyId === undefined}
      onSearch={handleSearch}
      onSort={handleSort}
      onRowClick={handleRowClick}
      onAdd={!propertyId ? () => onAction('add') : null}
    />
  );
};

export default GuidesTable;
