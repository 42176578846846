import * as React from 'react';

import { Paper } from '@mui/material';
import { BaseContainer } from 'Style/Components/BaseComponents';
import { isMobile } from 'react-device-detect';
import styledBy from 'styled-by';
import styled, { ThemeProvider } from 'styled-components';
import { PageContentContainer } from 'ui-library/Styled/containers';

export interface AccessContainerProps {
  children: React.ReactNode;
}

const AccessContainer = ({ children }: AccessContainerProps) => (
  <ThemeProvider theme={{ isMobile }}>
    <MainContainer>
      <MainPaper elevation={isMobile ? 0 : 2}>
        <ContentContainer>
          <BlueSideContainer>
            <TextContainer>
              <OpertoLogoImg src='/img/logos/operto-logo-white.svg' />
            </TextContainer>
          </BlueSideContainer>
          <WhiteSideContainer>
            <WhiteSideContentContainer>{children}</WhiteSideContentContainer>
          </WhiteSideContainer>
        </ContentContainer>
      </MainPaper>
    </MainContainer>
  </ThemeProvider>
);

const OpertoLogoImg = styled.img`
  width: 50%;
`;

const TextContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  min-height: 608px;
  width: 450px;
  justify-content: center;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
`;

const WhiteSideContainer = styled.div`
  background-color: white;
  min-height: 608px;
  width: ${props => (props.theme.isMobile ? '100%' : '65%')};
  justify-content: center;
  display: flex;
  position: relative;
`;

const BlueSideContainer = styled.div`
  position: relative;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 9%);
  background-color: ${props => props.theme.palette.primary};
  min-height: 610px;
  padding-left: 60px;
  width: ${props => (props.theme.isMobile ? '0%' : '35%')};
  ${styledBy('isMobile', `display:none;`)}
`;
const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const MainContainer = styled(PageContentContainer)`
  padding: 0;
  height: 100vh;
  max-width: 100%;
  margin: 0;
  width: 100%;
`;

const MainPaper = styled(Paper)`
  && {
    height: 100vh;
  }
`;

const WhiteSideContentContainer = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
`;

export default AccessContainer;
