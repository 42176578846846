import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { Actions } from '../../../types/actions';
import { MakeAvailable } from '../../../types/ui';

type State = {
  codeId: number;
  failed: boolean;
  ids: number[];
  showPending: boolean;
  completed: string;
};

const defaultState: State = {
  codeId: 0,
  failed: false,
  ids: [],
  showPending: false,
  completed: '',
};
const showMakeAvailable: Reducer<boolean> = (state = defaultState.showPending, action) => {
  switch (action.type) {
    case Actions.makeAvailablePending:
      return true;
    default:
      return state;
  }
};
const ids: Reducer<number[]> = (state = defaultState.ids, action) => {
  switch (action.type) {
    case Actions.makeAvailablePending:
      return action.ids;
    case Actions.removeMakeAvailableId:
      return action.ids;
    default:
      return state;
  }
};
const dismissedIds: Reducer<number[]> = (state = defaultState.ids, action) => {
  switch (action.type) {
    case Actions.removeMakeAvailableId:
      return action.dismissedIds;
    default:
      return state;
  }
};
const makeAvailableFailed: Reducer<boolean> = (state = defaultState.failed, action) => {
  switch (action.type) {
    case Actions.makeAvailableFailed:
      return true;
    case Actions.makeAvailableSuccess:
      return false;
    default:
      return state;
  }
};
const makeAvailableComplete: Reducer<string> = (state = defaultState.completed, action) => {
  switch (action.type) {
    case Actions.makeAvailableReset:
      return '';
    case Actions.makeAvailableFailed:
      return 'Incompleted';
    case Actions.makeAvailableComplete:
      return 'Completed';
    default:
      return state;
  }
};
const aMakeAvailable: Reducer<MakeAvailable> = combineReducers<MakeAvailable>({
  showMakeAvailable,
  makeAvailableFailed,
  ids,
  dismissedIds,
  makeAvailableComplete,
});

export default aMakeAvailable;
