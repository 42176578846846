import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { IconButton, SxProps } from '@mui/material';
import React from 'react';

interface FavouriteIconProps {
  isFavourite: boolean;
  styles?: SxProps;
  onClick?: () => void;
}

const FavouriteIcon = ({ isFavourite, styles, onClick }: FavouriteIconProps) => {
  const handleClick = (e: React.MouseEvent | React.TouchEvent) => {
    if (onClick) {
      onClick();
    }
    e.stopPropagation();
  };

  return (
    <IconButton sx={styles} onClick={handleClick} data-testid='favourite-icon-button'>
      {isFavourite ? (
        <StarIcon sx={{ fill: '#FFB400' }} data-testid='star-icon' />
      ) : (
        <StarOutlineIcon sx={{ fill: '#08163E3B' }} data-testid='star-outline-icon' />
      )}
    </IconButton>
  );
};

export default FavouriteIcon;
