import styled from 'styled-components';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

export const BigSwitch = styled(Switch)`
  && {
    width: 110px;
    height: 60px;
    padding: 16px 0px;
    .MuiButtonBase-root {
      padding-left: 0;
    }

    .MuiSwitch-thumb {
      width: 38px;
      height: 38px;
      transition-property: color;
      transition-duration: 300ms;
    }

    .MuiSwitch-track {
      height: 26px;
      border-radius: 25px;

      background-color: ${props => props.theme.palette.primary};
    }
    .Mui-checked {
      transform: translateX(72px);
      color: ${props => props.theme.palette.info};
    }
    .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
      opacity: 1;
    }
  }
`;

export const IconSelect = styled(Select)`
  && {
    .MuiSelect-selectMenu {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .MuiListItemIcon-root {
      min-width: 34px;
      margin-left: 4px;
    }
  }
`;

export const OutlineFormControl = styled(FormControl)`
  .MuiFormLabel-root {
    background-color: ${props => props.theme.containerAccentBGColor};
  }
`;
