import * as React from 'react';

import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { BaseContainer } from 'Style/Components/BaseComponents';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export interface TabActivatorProps {
  title: string;
  icon: React.ReactNode;
  switchLabel: string;
  onSwitched?: (isSwitchOn: boolean) => void;
}

const TabActivator = ({ title, icon, switchLabel, onSwitched }: TabActivatorProps) => {
  const [isSwitchOn, setIsSwitchOn] = React.useState(false);
  const onSwitch = () => {
    setIsSwitchOn(!isSwitchOn);
    onSwitched(isSwitchOn);
  };
  return (
    <>
      <MainContainer isMobile>
        <Grid container spacing={2}>
          {icon}
          <Row item xs={12}>
            <Typography align='center' variant='subtitle1'>
              {title}
            </Typography>
          </Row>
          <Row item xs={12}>
            <Typography align='center' variant='caption'>
              Click the toggle below to enable.
            </Typography>
          </Row>
          <Row item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isSwitchOn}
                  onChange={onSwitch}
                  name='gp-activated'
                  color='primary'
                />
              }
              label={switchLabel}
            />
          </Row>
        </Grid>
      </MainContainer>
    </>
  );
};
const MainContainer = styled(BaseContainer)<{ isMobile: boolean }>`
  margin-top: ${isMobile ? '40px' : '100px'};
`;

const Row = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
`;

export default TabActivator;
