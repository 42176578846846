import * as React from 'react';

import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const OpertoProgressCircle = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '24px',
      width: '100%',
    }}
  >
    <CircularProgress
      sx={{
        height: '50px',
        width: '50px',
      }}
    />
  </Box>
);

export default OpertoProgressCircle;
