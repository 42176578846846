import { getValidColor } from 'Common/ColorPicker';
import getUnixTime from 'date-fns/getUnixTime';
import { logger } from 'lib/logger';
import { IMember } from 'member/memberType';
import { getSelf } from 'member/state/memberSelectors';
import { useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGuestPortal } from 'redux/guestportal';
import { useAppSelector } from 'redux/hooks';
import { ISection, Order } from '../models';

export const newSectionTemplate = (author: string): ISection => {
  const now = getUnixTime(new Date());
  return {
    section: '',
    featured: false,
    published: false,
    created_at: now,
    created_by: author,
    last_edit_at: now,
    last_edit_by: author,
    section_color: getValidColor(),
    section_cover: '',
    scheduled: false,
    scheduled_start: now,
    scheduled_end: now,
  };
};

export const useGuidesSections = () => {
  const navigate = useNavigate();
  const { propertyId, guideId, categoryId, pageId } = useParams();
  const editingUser = useAppSelector(getSelf()) as IMember;
  const {
    featured,
    sections,
    saveSection,
    savePropertySection,
    addSection,
    removeSection,
    fetchGuideSections,
    fetchPropertyGuideSections,
    setGuideSectionsOrder,
    setPropertyGuideSectionsOrder,
  } = useGuestPortal();
  const savedSectionInProgress = useRef<ISection>();

  const section = useMemo(() => {
    if (savedSectionInProgress.current) {
      return savedSectionInProgress.current;
    }

    // NOTE: determine what section to show based on the url params
    // dont return a section if a category or page is selected
    if (guideId === undefined || categoryId || pageId) {
      return undefined;
    }

    if (guideId === 'add') {
      return newSectionTemplate(editingUser?.name ?? 'Unknown');
    }

    const sectionItem = sections.byId[guideId];
    if (sectionItem) {
      return sectionItem; // lucky us, we cached it already
    }

    const featuredItem = featured.byId[guideId];
    if (featuredItem) {
      // NOTE: this part is a mess. IFeatured is not an actual ISection type.
      // its a subset/partial of ISection object, so we need to somehow construct it to IPage
      return {
        ...featuredItem?.data,
        section_id: featuredItem?.section_id,
      } as ISection;
    }

    logger.error(`item not found but guideId is ${guideId}`);
    return undefined;
  }, [categoryId, editingUser?.name, featured.byId, guideId, pageId, sections.byId]);

  const save = async (section: ISection) => {
    savedSectionInProgress.current = guideId ? section : null;
    let success = false;
    if (section?.section_id?.length > 0) {
      section.last_edit_by = editingUser?.name ?? 'Unknown';
      section.last_edit_at = getUnixTime(new Date());
      success = propertyId
        ? await savePropertySection(Number(propertyId), section)
        : await saveSection(section);
    } else {
      success = await addSection(section); // add new section
    }

    if (success) {
      savedSectionInProgress.current = null;
      if (guideId) navigate(-1);
    }
  };

  const remove = async ({ section_id }: ISection) => {
    await removeSection({
      section_id,
      category_id: '',
      content_id: '',
    });
  };

  const sort = async (order: Order[]) => {
    if (propertyId) {
      await setPropertyGuideSectionsOrder({
        order,
        propertyId: Number(propertyId),
      });
    } else {
      await setGuideSectionsOrder({ order });
    }
  };

  useEffect(() => {
    if (propertyId) {
      fetchPropertyGuideSections(Number(propertyId));
    } else {
      fetchGuideSections();
    }
  }, [fetchGuideSections, fetchPropertyGuideSections, propertyId]);

  return {
    section,
    save,
    remove,
    sort,
  };
};
