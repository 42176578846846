import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import { Box, Collapse, Divider, List, Stack, SxProps, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FilterDataType, SlideDownDataType } from 'types/filter-type';
import { CheckboxOption } from './CheckboxOption';
import { FilterPanelProps, isSelected } from './FilterPanel';

type SlideDownOptionProps = Pick<FilterPanelProps, 'searchParams'> & {
  option: FilterDataType;
  showDivider?: boolean;
  isOpen?: boolean;
  onChange: (checked: boolean, option: FilterDataType) => void;
};

export const SlideDownOption = ({
  searchParams,
  option,
  showDivider,
  isOpen,
  onChange,
}: SlideDownOptionProps) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(isOpen);

  return (
    <Stack sx={collapseHeadWrapperStyle}>
      <Box
        sx={collapseHeadWrapperStyle}
        onClick={() => setIsCollapseOpen(!isCollapseOpen)}
        data-testid={`${option.name}-slidedown`}
      >
        <Typography variant='subtitle-lg-600'>{option.label}</Typography>
        {isCollapseOpen ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
      </Box>
      <Collapse in={isCollapseOpen}>
        <List>
          {option.slideDownData.map((data: SlideDownDataType) => {
            switch (data.filterType) {
              case 'checkbox':
                return (
                  <CheckboxOption
                    key={`${option.name}-${data.name}-${data.filterType}`}
                    testID={`${option.name}-${data.name}-${data.filterType}`}
                    option={data}
                    checked={isSelected(data.name, searchParams.get(option.name))}
                    onChange={checked => onChange(checked, data)}
                  />
                );
            }
          })}
        </List>
      </Collapse>
      {showDivider && <Divider sx={{ marginTop: '10px' }} />}
    </Stack>
  );
};

const collapseHeadWrapperStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'left',
  marginY: 1,
  '&:hover': {
    cursor: 'pointer',
  },
};
