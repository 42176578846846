import { FormControl, Stack, TextField, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import { IPaymentInfo } from 'guest/guestType';
import React, { useEffect, useState } from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { IReservation } from 'reservation/reservationType';
import { manualOverrideSecurityDeposit } from 'reservation/state/reservationActions';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';

export interface SecurityDepositDialogProps {
  onClose: () => void;
  open: boolean;
  reservation: IReservation;
  paymentInfo: IPaymentInfo;
}

const SecurityDepositDialog = ({
  onClose,
  open,
  reservation,
  paymentInfo,
}: SecurityDepositDialogProps) => {
  const dispatch = useAppDispatch();
  const [notes, setNotes] = useState<string>('');
  const [isNewNote, setIsNewNote] = useState<boolean>(true);

  const handleDeposit = async () => {
    if (reservation) {
      const success = await dispatch(manualOverrideSecurityDeposit(reservation.id, notes));

      if (success) {
        onClose();
      }
    } else {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'There was an error while manually accepting the security deposit',
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  useEffect(() => {
    setNotes(paymentInfo.notes);
    if (paymentInfo.notes !== null) {
      setIsNewNote(false);
    }
  }, [paymentInfo.notes]);

  const handleChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (notes === null || e.currentTarget.value?.length <= 50) setNotes(e.currentTarget.value);
  };

  const textLeft = `Maximum 50 characters (${notes?.length || 0}/50)`;
  const title = isNewNote ? 'Manually Accept Security Deposit' : 'Edit Manual Security Deposit';
  const description = isNewNote
    ? `Are you sure that you want to accept security deposit manually?\nIt will allow guests to check in and get access. \nPlease add a note below. (e.g. Paid $250 with cash)`
    : 'Edit note below.';

  return (
    <ConfirmDialog
      open={open}
      title={title}
      submitButtonText='Confirm'
      onSubmit={handleDeposit}
      disabled={notes?.length === 0}
      onClose={onClose}
    >
      <DialogContent>
        <Typography component='span' variant='body1'>
          {description}
        </Typography>
        <Stack direction='row' justifyContent='flex-start' alignItems='center' mt={2}>
          <FormControl sx={{ m: 1, width: '100%' }} variant='outlined'>
            <TextField
              value={notes}
              onChange={handleChangeEvent}
              aria-describedby='outlined-weight-helper-text'
              helperText={textLeft}
            />
          </FormControl>
        </Stack>
      </DialogContent>
    </ConfirmDialog>
  );
};

export default SecurityDepositDialog;
