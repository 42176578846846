import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PublishIcon from '@mui/icons-material/Publish';
import StarIcon from '@mui/icons-material/Star';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { useCallback } from 'react';

export interface ActionDropDownMenuProps {
  onEdit?: React.MouseEventHandler;
  onDelete?: React.MouseEventHandler;
  onPublished?: React.MouseEventHandler;
  onFeatured?: React.MouseEventHandler;
  disabled?: boolean;
}

const ActionDropDownMenu: React.FC<ActionDropDownMenuProps> = ({
  onEdit,
  onDelete,
  onPublished,
  onFeatured,
  disabled,
}: ActionDropDownMenuProps) => {
  const [actionsMenu, setActionsMenu] = React.useState<HTMLButtonElement | null>(null);

  const onEditPressed = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      setActionsMenu(null);
      e.stopPropagation();
      onEdit?.(e);
    },
    [onEdit],
  );

  const onDeletePressed = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      setActionsMenu(null);
      e.stopPropagation();
      onDelete?.(e);
    },
    [onDelete],
  );

  const onPublishedPressed = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      setActionsMenu(null);
      e.stopPropagation();
      onPublished?.(e);
    },
    [onPublished],
  );

  const onFeaturedPressed = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      setActionsMenu(null);
      e.stopPropagation();
      onFeatured?.(e);
    },
    [onFeatured],
  );

  return (
    <>
      <IconButton
        onClick={e => {
          if (!disabled) {
            e.stopPropagation();
            setActionsMenu(e.currentTarget);
          }
        }}
        size='large'
      >
        <MoreVertIcon color={disabled ? 'disabled' : 'primary'} />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={actionsMenu}
        open={Boolean(actionsMenu)}
        onClose={(e: React.SyntheticEvent) => {
          e.stopPropagation();
          setActionsMenu(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {onEdit && (
          <MenuItem onClick={onEditPressed}>
            <ListItemIcon>
              <EditIcon color='primary' />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        {onPublished && (
          <MenuItem onClick={onPublishedPressed}>
            <ListItemIcon>
              <PublishIcon color='primary' />
            </ListItemIcon>
            <ListItemText>Publish</ListItemText>
          </MenuItem>
        )}
        {onFeatured && (
          <MenuItem onClick={onFeaturedPressed}>
            <ListItemIcon>
              <StarIcon color='primary' />
            </ListItemIcon>
            <ListItemText>Featured</ListItemText>
          </MenuItem>
        )}

        {onDelete && (
          <div>
            <Divider />
            <MenuItem onClick={onDeletePressed}>
              <ListItemIcon>
                <DeleteOutlineOutlinedIcon color='primary' />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </div>
        )}
      </Menu>
    </>
  );
};

export default ActionDropDownMenu;
