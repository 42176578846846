import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Chip, SxProps } from '@mui/material';
import React from 'react';
import { FilterGroup } from 'types/filter-type';

export type GroupChipMobileProps = {
  key: string;
  filter: FilterGroup;
  handleBottomSlideOpen: (isOpen: boolean, groupName: string) => void;
  updateChipLabel: (filterGroup: string) => string;
};

export const GroupChipMobile = ({
  filter,
  updateChipLabel,
  handleBottomSlideOpen,
}: GroupChipMobileProps) => {
  return (
    <Chip
      key={filter.groupName}
      label={
        updateChipLabel(filter.groupName) !== '' ? updateChipLabel(filter.groupName) : filter.label
      }
      variant='outlined'
      sx={filterPillStyle(updateChipLabel(filter.groupName) !== '')}
      onClick={() => handleBottomSlideOpen(true, filter.groupName)}
      deleteIcon={
        <ArrowDropDownIcon sx={pillArrowStyle(updateChipLabel(filter.groupName) !== '')} />
      }
      data-testid={`${filter.groupName}-filter-button-mobile`}
    />
  );
};

export const pillArrowStyle = (isActive: boolean): SxProps => ({
  color: isActive ? '#155EFF !important' : 'text.primary !important',
});

export const filterPillStyle = (isActive: boolean): SxProps => ({
  backgroundColor: isActive ? 'primary.p8' : 'text.white',
  color: isActive ? '#155EFF' : 'text.primary',
  borderColor: isActive ? 'text.white' : '#08163E3B',
});
