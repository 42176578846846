import { PinDrop } from '@mui/icons-material';
import { AddressCard, ImageCard } from 'Pages/GuestPortal/Global/Address';
import TabActivator from 'Pages/Unit/GuestPortal/TabActivator';
import TabDeactivator from 'Pages/Unit/GuestPortal/TabDeactivator';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import { PropertyAddress, useGuestPortal } from 'redux/guestportal';
import { useAppSelector } from 'redux/hooks';
import { PageHeader } from 'ui-library/Styled/containers';
import { userPermissionSelector } from 'user/state/userSelectors';

const CustomAddress = () => {
  const { propertyId: pidStr } = useParams();
  const propertyId = Number(pidStr);
  const { fetchInfoData, fetchPropertyInfoData, setPropertyPropertyAddress, address } =
    useGuestPortal();
  const [isSwitched, setIsSwitched] = React.useState(address?.isCustom || false);
  const [convertedInfoData, setConvertedInfoData] = React.useState<PropertyAddress>();

  const switched = (switchPreviousState: boolean) => {
    setConvertedInfoData({ ...convertedInfoData, isCustom: !switchPreviousState });
    setIsSwitched(!switchPreviousState);
    onSave({ ...convertedInfoData, isCustom: !switchPreviousState });
  };
  const onSave = (customProperty: PropertyAddress) => {
    setPropertyPropertyAddress(customProperty);
  };

  const changeAddress = (address: PropertyAddress) => {
    setConvertedInfoData({
      ...convertedInfoData,
      name: address.name,
      city: address.city,
      state: address.state,
      address: address.address,
      country: address.country,
    });
  };

  const changeURL = (newUrl: string) => {
    setConvertedInfoData({ ...convertedInfoData, imageURL: newUrl });
  };

  useEffect(() => {
    if (propertyId) {
      fetchPropertyInfoData(propertyId);
    } else {
      fetchInfoData();
    }
  }, [propertyId, fetchPropertyInfoData, fetchInfoData]);

  const permission = useAppSelector(userPermissionSelector());

  useEffect(() => {
    setIsSwitched(address?.isCustom);
    if (address?.isCustom) {
      setConvertedInfoData({
        ...address,
        propertyId: pidStr,
      });
    } else {
      setConvertedInfoData({
        city: '',
        name: '',
        state: '',
        address: '',
        country: '',
        imageURL: '',
        propertyId: pidStr,
        isCustom: false,
      });
    }
  }, [address, pidStr]);

  if (!address) {
    return null;
  }

  return (
    <>
      <PageHeader>Custom Address</PageHeader>
      {isSwitched ? (
        <>
          <TabDeactivator switchLabel={'Disable Custom Address'} onSwitched={switched} />
          <AddressCard
            property={convertedInfoData}
            onSave={onSave}
            changeAddress={changeAddress}
            permission={permission}
          />
          <ImageCard
            property={convertedInfoData}
            onSave={onSave}
            changeURL={changeURL}
            permission={permission}
          />
        </>
      ) : (
        <TabActivator
          title={'Need a custom address for this unit?'}
          icon={
            <PinDrop
              color='primary'
              sx={{
                height: isMobile ? '70px' : '120px',
                width: isMobile ? '70px' : '120px',
                marginX: 'auto',
              }}
            />
          }
          switchLabel={'Enable Custom Address'}
          onSwitched={switched}
        />
      )}
    </>
  );
};

export default CustomAddress;
