import Collapse from '@mui/material/Collapse';
import * as React from 'react';

export interface TabTransitionProps {
  show: boolean;
  children: JSX.Element;
  orientation?: 'horizontal' | 'vertical';
}

const TabTransition = ({ show, children, orientation = 'horizontal' }: TabTransitionProps) => (
  <Collapse
    orientation={orientation}
    timeout={{ enter: 300, exit: 300 }}
    in={show}
    sx={{ height: '100%' }}
  >
    {children}
  </Collapse>
);

export default TabTransition;
