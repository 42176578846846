import Grid from '@mui/material/Grid';
import EmptyStateContainer, { EmptyStateContainerProps } from 'Common/Empty/EmptyStateContainer';
import EnergyWidget from 'Pages/Unit/EnergyWidget';
import { devicesByPropertyIdSelector } from 'device/state/deviceSelector';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { getThermostatSettings } from 'redux/actions/thermostats';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import thermostatsAssociatedToProperty from 'redux/selectors/thermostatSettingsAssociatedToProperty';
import { ThermostatSetting } from 'types/thermostats';
import { PageHeader } from 'ui-library/Styled/containers';

const EnergyManagerPage = () => {
  const { propertyId } = useParams<{ propertyId: string }>();
  const dispatch = useAppDispatch();
  const thermoSettings = useAppSelector(thermostatsAssociatedToProperty(+propertyId));

  React.useEffect(() => {
    dispatch(getThermostatSettings(+propertyId));
  }, [propertyId, dispatch]);

  const titleProducer = (type: string, roomStatus: string) => {
    let reformattedStatus = '';
    switch (roomStatus) {
      case 'OCCUPIED':
        reformattedStatus = 'ARRIVAL';
        break;

      default:
        reformattedStatus = 'VACANT';
    }
    return `${type} ${reformattedStatus}`;
  };

  const devices = useAppSelector(devicesByPropertyIdSelector(+propertyId));
  const emptyStateObject: EmptyStateContainerProps = {
    showEmpty: true,
    buttonText: 'Contact Us',
    message: 'To learn more about Energy Saving Solutions, please contact Client Success',
    onClick: () => {
      window.open('mailto:support@operto.com');
    },
    iconUrl: '/img/empty-state/Energy@2x.svg',
  };
  return (
    <>
      <PageHeader>{'Energy Manager'}</PageHeader>
      {thermoSettings?.length > 0 ? (
        <Grid container spacing={1}>
          {thermoSettings !== undefined &&
            thermoSettings
              .filter(
                (setting: ThermostatSetting) =>
                  setting.thermostat_room_status !== 'OCCUPIED_ENERGY_SETBACK',
              )
              .map((setting: ThermostatSetting, index: number) => {
                const device = devices.find(
                  d => Number(d.data?.device_id) === Number(setting.device_id),
                );
                const deviceName = `${device?.data?.name || setting.thermostat_type} | `;
                return (
                  <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                    {setting.type === 'ECOBEE' ? (
                      <EnergyWidget
                        twoBars
                        tempObject={setting}
                        title={titleProducer(deviceName, setting.thermostat_room_status)}
                      />
                    ) : (
                      setting.thermostat_room_status !== 'OCCUPIED_ENERGY_SETBACK' && (
                        <EnergyWidget
                          footerButtons
                          tempObject={setting}
                          title={titleProducer(deviceName, setting.thermostat_room_status)}
                        />
                      )
                    )}
                  </Grid>
                );
              })}
        </Grid>
      ) : (
        <EmptyStateContainer {...emptyStateObject} />
      )}
    </>
  );
};

export default EnergyManagerPage;
