import { UserRole } from 'user/userTypes';

export enum AccessCodeTypes {
  Guest = 1,
  Cleaner = 2,
  Manager = 3,
  CheckoutCode = 4,
  CleaningStarted = 5,
  CleaningCompleted = 6,
  InspectionStarted = 7,
  InspectionCompleted = 8,
  BackupCode = 19,
  EmployeeAccess = 20,
  Maintenance = 21,
  Preloaded = 22,
  Generated = 23,
  Scheduled = 24,
  MasterCode = 25,
  MaintenanceComplete = 26,
  Owner = 28,
  GuestIntercomPermanent = 27,
  Unknown = 99,
}
export interface ICode {
  guest_name: string;
  name?: string;
  incidental_payment: boolean;
  check_in: string;
  check_out: string;
  exit_code: string | null;
  incidental_payment_enabled: boolean;
  timezone: string;
  code_status?: string;
  id: number;
  access_code: string;
  access_code_type_id: number;
  created_at: string;
  deleted_at: string | null;
  duration?: string | null;
  install_tries: number;
  installed_at: string | null;
  failed_at: string | null;
  master_property_id?: number;
  lock_code_index: number | null;
  max_uses: number | null;
  member_name: string;
  property_id: number;
  member_id: number | null;
  member_type: number | null;
  queued_at: string | null;
  reservation_id: number | null;
  linked_reservation_id: number;
  uninstall_tries: number;
  updated_at: string;
  use_count: number;
  valid_from: string;
  valid_until: string | null;
  lock_provider: string;
  lock_provider_method: string | null;
  over_written_duration: boolean;
  member_role_id: number | null;
  member_role: UserRole | null;
}

export interface CodeDict {
  [Key: number]: ICode;
}

export interface CodeState {
  list: number[];
  byId: CodeDict;
}
