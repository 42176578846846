import { Box, Typography } from '@mui/material';
import { utcToTimeZone } from 'helper/date';
import * as React from 'react';

export interface DateDisplayProps {
  date: string;
  timezone: string;
}

const DateDisplay = ({ date, timezone }: DateDisplayProps) => {
  const yearLabel = timezone ? utcToTimeZone(date, timezone, 'yyyy') : 'N/A';
  const weekLabel = timezone ? utcToTimeZone(date, timezone, 'EEE') : 'N/A';
  const dateLabel = timezone ? utcToTimeZone(date, timezone, 'MMM dd') : 'N/A';
  const timeLabel = timezone ? utcToTimeZone(date, timezone, 'hh:mm a') : 'N/A';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        color: 'text.white',
        padding: 0,
      }}
    >
      <Typography variant='subtitle1' sx={{ color: 'text.white' }}>
        {yearLabel}
      </Typography>
      <Typography
        variant='h2'
        sx={{ fontSize: '24px', fontWeight: 300, lineHeight: '32.02px', marginTop: '10px' }}
      >
        {weekLabel}
      </Typography>
      <Typography variant='h2' sx={{ fontSize: '24px', fontWeight: 300, lineHeight: '32.02px' }}>
        {dateLabel}
      </Typography>
      <Typography variant='caption' sx={{ color: 'text.white', marginTop: '5px' }}>
        {timeLabel}
      </Typography>
    </Box>
  );
};

export default DateDisplay;
