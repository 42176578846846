import { BatteryStatus } from 'device/deviceType';

export enum PROPERTY_ALERT_TYPE {
  COMPLIANCE_RISK = 'complianceRiskAlert',
  BATTERY_WARNING = 'batteryWarning',
  ENVIRONMENT_RISK = 'environmentRiskAlert',
  LOCK_OFFLINE = 'lockOffline',
  HUB_OFFLINE = 'hubOffline',
}

export interface PropertyAlertMeta {
  pageNum: number;
  numPerPage: number;
  total_records: number;
}
export interface IPropertyAlertOverview {
  alerts: IPropertyAlert[];
  property_id: number;
  property_name: string;
}

export interface IPropertyAlertLockData extends Record<string, unknown> {
  battery?: number;
  battery_status?: BatteryStatus;
  property_id?: number;
}

export interface IPropertyAlert {
  data?: IPropertyAlertLockData;
  device_id?: number;
  message: string;
  property_id: number;
  property_name: string;
  time_ago: string;
  title: string;
  type: PROPERTY_ALERT_TYPE;
}

export interface PropertyAlertState {
  byId: PropertyAlertDict;
  list: number[];
  meta: PropertyAlertMeta;
}

export interface PropertyAlertDict {
  [Key: string]: IPropertyAlertOverview;
}
