import { logoutAccountAndExpireToken } from 'auth/LinkedAccountsHelper';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { AppDispatch } from 'redux/store';
import { Actions } from '../../types/actions';

export interface TokenInfo {
  mid: string;
  cid: string;
  userClaims?: string;
  product?: string;
}

export const storeToken = (auth: string) => (dispatch: AppDispatch) => {
  axios.defaults.headers.common.Authorization = `Bearer ${auth}`;
  localStorage.setItem('auth', JSON.stringify(auth));
  const decode: TokenInfo = jwtDecode(auth);
  dispatch({
    type: Actions.storeToken,
    token: auth,
    memberId: decode.mid,
    companyId: decode.cid,
  });
};

export const logout = () => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.logout,
  });
  localStorage.removeItem('mpAuth');
  logoutAccountAndExpireToken();
};
