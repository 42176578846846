import { ICategory } from 'redux/guestportal';
import { MEMBERS_ROLES, MembersRolesTypes } from 'redux/members/types';
import { UserRole } from 'user/userTypes';
import { PermissionActionsTypes, PermissionTypes, permissionRoles } from './permissionsHelper';

export const convertToBase64 = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = error => {
      reject(error);
    };
  });
};

export const cToF = (temperature: string) => {
  return parseInt(`${parseFloat(temperature) * 1.8}`) + 32;
};

export function camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word: string) => {
      return word === ' ' ? word : word.toUpperCase();
    })
    .replace(/\s+/g, ' ');
}

export const toTitleCase = (str: string) =>
  str && str.length > 0
    ? str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    : str;

// The current id to represent the fake category to show uncategorized pages
export const UNCATEGORIZED_ID = 'bb7172e8-8add-4622-92bf-9f739ec279f0';

export const isUncategorizedAvailable = (data: ICategory[]) => {
  return data.some(({ category_id }) => category_id === UNCATEGORIZED_ID);
};

export const roleHasPermission = (
  currentUserId: number,
  currentUserRole: UserRole,
  compareMemberId: number,
  compareMemberRole: UserRole,
): boolean => {
  // User has permission to view/edit their own profile
  if (currentUserId === compareMemberId) {
    return true;
  }

  // User only has permission to view/edit members with lower roles
  let restrictedRoles: UserRole[] = [];

  switch (currentUserRole) {
    case MEMBERS_ROLES.ACCOUNT_OWNER:
      return true;
    case MEMBERS_ROLES.GLOBAL_MANAGER:
      restrictedRoles = [MEMBERS_ROLES.ACCOUNT_OWNER, MEMBERS_ROLES.GLOBAL_MANAGER];
      return !restrictedRoles.includes(compareMemberRole);
    case MEMBERS_ROLES.REGIONAL_MANAGER:
      restrictedRoles = [
        MEMBERS_ROLES.ACCOUNT_OWNER,
        MEMBERS_ROLES.GLOBAL_MANAGER,
        MEMBERS_ROLES.REGIONAL_MANAGER,
      ];
      return !restrictedRoles.includes(compareMemberRole);
    case MEMBERS_ROLES.BASIC_USER:
      return false;
    default:
      return true;
  }
};

// this will be replaced by authorization
export const featurePermission = (
  role: MembersRolesTypes | UserRole,
  permissionType: PermissionTypes,
  action: PermissionActionsTypes,
) => {
  const currentRole = permissionRoles?.find(r => r.role === role) || undefined;
  const permissionGroup = currentRole?.permissions[permissionType];
  const permission = permissionGroup?.find(a => a?.includes(action));
  return Boolean(permission?.includes(currentRole.role) || permission?.includes('any'));
};

export const featurePermissionByMemberRole = (
  role: MembersRolesTypes | UserRole,
  memberRole: MembersRolesTypes | UserRole,
  permissionType: PermissionTypes,
  action: PermissionActionsTypes,
) => {
  const currentRole = permissionRoles?.find(r => r.role === role) || undefined;
  const permissionGroup = currentRole?.permissions[permissionType];
  const scope = permissionGroup?.find(a => a?.includes(action));
  return scope?.includes(memberRole) || scope?.includes('any');
};

export const sortLanguages = (languagesArray: string[]) => {
  return languagesArray.sort((a, b) => {
    if (a === 'en') return -1;
    if (b === 'en') return 1;
    return a.localeCompare(b);
  });
};

export const toDecimalStr = (value: string, scale = 2) => {
  const parsed = parseFloat(value);
  return isNaN(parsed) ? null : parsed.toFixed(scale);
};

export type ExportFormat = 'csv' | 'xml';

export interface ExportData {
  [key: string]: string | number | boolean | Date | null | undefined;
}

export interface ExportOptions {
  format?: ExportFormat | string;
  columns: Record<string, string>;
  data?: ExportData[];
}

export const exportContent = ({ format, columns, data = [] }: ExportOptions): string => {
  const mappedData = data.map(item =>
    Object.keys(columns).reduce((acc, key) => {
      const value = item[key];
      acc[columns[key]] = value !== undefined && value !== null ? String(value) : '';
      return acc;
    }, {} as Record<string, string>),
  );

  let content = '';

  if (format === 'csv') {
    const csvHeader = Object.values(columns).join(',');
    const csvRows = mappedData.map(row => Object.values(row).join(',')).join('\n');
    content = `${csvHeader}\n${csvRows}`;
  } else if (format === 'xml') {
    const xmlRows = mappedData
      .map(
        row =>
          `<item>${Object.entries(row)
            .map(([key, value]) => `<${key}>${value}</${key}>`)
            .join('')}</item>`,
      )
      .join('\n');
    content = `<items>\n${xmlRows}\n</items>`;
  }

  return content;
};

export const exportFile = (options: ExportOptions) => {
  const content = exportContent(options);
  const encodedUri = encodeURI(`data:text/${options.format};charset=utf-8,${content}`);
  const fileExtension = options.format;

  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `export.${fileExtension}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
