import { SideDrawer } from 'Common/Drawer/SideDrawer';
import GPPreviewContainer from 'Pages/GuestPortal/Preview/GPPreviewContainer';
import React from 'react';

export type GuestPortalGlobalPreviewProps = {
  isOpen: boolean;
  onClose: (isClosed: boolean) => void;
};

export const GuestPortalGlobalPreview: React.FC<GuestPortalGlobalPreviewProps> = props => {
  const { isOpen, onClose } = props;

  const handleClose = () => {
    onClose(false);
  };

  return (
    <SideDrawer open={isOpen} onClose={handleClose}>
      <GPPreviewContainer onClose={handleClose} />
    </SideDrawer>
  );
};

export default GuestPortalGlobalPreview;
