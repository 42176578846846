import { GridRowParams } from '@mui/x-data-grid-pro';
import { Task } from '@operto/tasks-shared';
import useTranslation from 'hooks/useTranslation';
import { MemberFilterType } from 'member/memberType';
import { getMembersByCompany } from 'member/state/memberActions';
import { membersByIdsSelector } from 'member/state/memberSelectors';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleSlidebar } from 'redux/actions/ui';
import { useAppSelector } from 'redux/hooks';
import { SlidebarType } from 'types/ui';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import columns from './TasksTableColumns';
import TasksTitlebar from './TasksTitlebar';
import useTasks, { TODO_STATUS } from './useTasks';

const PAGE_NUM = 0;
const PAGE_SIZE = 100;

export default function TasksTable() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const firstLoad = useRef(true);

  const { data: tasks = [], isLoading } = useTasks({ statusFilter: TODO_STATUS });

  const [searchString, setSearchString] = useState('');
  const [filteredTasks, setFilteredTasks] = useState<Task[]>();

  const memberIds = tasks.map(task => +task.assigneeId);
  const members = useAppSelector(membersByIdsSelector(memberIds));

  const handleSearch = useCallback(
    (pageNum: number, numPerPage: number, searchString?: string) => {
      setSearchString(searchString || '');

      if (!searchString?.length) {
        return setFilteredTasks(tasks);
      }

      const lowerCaseSearchString = searchString.toLowerCase();

      const filteredData = tasks?.filter(row => {
        return (
          row.priority?.toLowerCase()?.includes(lowerCaseSearchString) ||
          row.guest?.toLowerCase()?.includes(lowerCaseSearchString) ||
          row.unit?.toLowerCase()?.includes(lowerCaseSearchString) ||
          row.title?.toLowerCase()?.includes(lowerCaseSearchString) ||
          row.description?.toLowerCase()?.includes(lowerCaseSearchString) ||
          row.assignee?.toLowerCase()?.includes(lowerCaseSearchString)
        );
      });

      setFilteredTasks(filteredData);
    },
    [tasks],
  );

  const handleRowClick = ({ row }: GridRowParams<Task>) => {
    dispatch(toggleSlidebar(SlidebarType.TASKS_DETAILS, row));
  };

  useEffect(() => {
    dispatch(
      getMembersByCompany({
        filterType: MemberFilterType.ALL_MEMBERS,
        pageNum: 0,
        numPerPage: 100000,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
    }

    handleSearch(PAGE_NUM, PAGE_SIZE, searchString);
  }, [handleSearch, searchString]);

  return (
    <>
      <TasksTitlebar
        onCreateClick={() => dispatch(toggleSlidebar(SlidebarType.TASKS_DETAILS, {}))}
      />

      <PaginatedTable
        enableToolbar
        onRowClick={handleRowClick}
        columns={columns(members, t)}
        rows={filteredTasks ?? []}
        onFetch={handleSearch}
        loading={isLoading}
      />
    </>
  );
}
