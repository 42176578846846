import { Divider, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useCallback, useState } from 'react';
import { Unit } from 'redux/units/types';
import { SelectionTable, SelectionTableProps } from 'ui-library/Components/table/SelectionTable';
import { TableCell } from 'ui-library/Components/table/TableCell';

export const renderUnits = ({ row }: GridRenderCellParams<unknown, Unit>) => {
  return <TableCell title={row.name} description={row.id} testId={`group-unit-cell-${row.id}`} />;
};

const columnsDef: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Units',
    flex: 1,
    renderCell: renderUnits,
    sortable: false,
  },
];

type UnitsSelectionTableProps = Omit<SelectionTableProps, 'rows' | 'columns' | 'onFetch'> & {
  rows: Unit[];
  columns?: GridColDef[];
  searchLabel?: string;
  tableInfo?: string;
};

export const UnitsSelectionTable = ({
  rows: units,
  tableInfo,
  searchLabel = 'Enter unit name',
  ...rest
}: UnitsSelectionTableProps) => {
  const [searchValue, setSearchValue] = useState<string>();
  const filteredList = units.filter(
    unit => !searchValue || unit.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const renderTableInfo = () => {
    return (
      <>
        <Typography variant='subtitle-lg-600' sx={{ padding: '16px' }}>
          {tableInfo}
        </Typography>
        <Divider />
      </>
    );
  };

  const handleFetch = useCallback((_pageNum: number, _numPerPage: number, searchValue?: string) => {
    setSearchValue(searchValue);
  }, []);

  return (
    <SelectionTable
      searchLabel={searchLabel}
      rows={filteredList}
      columns={columnsDef}
      onFetch={handleFetch}
      sortModel={[{ field: 'name', sort: 'asc' }]}
      sx={{ height: '100%' }}
      renderTableInfo={tableInfo && renderTableInfo()}
      {...rest}
    />
  );
};
