import { FormControl, NativeSelect } from '@mui/material';
import * as React from 'react';

export interface AccessFormatDropDownProps {
  value: string;
  onChange: (value: string) => void;
}

const AccessFormatDropDown = ({ value = '', onChange }: AccessFormatDropDownProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  return (
    <FormControl sx={{ minWidth: '140px' }}>
      <NativeSelect value={value} onChange={handleChange}>
        <option value=''>None</option>
        <option value='nuki:2'>nuki:2</option>
        <option value='salto:1'>salto:1</option>
      </NativeSelect>
    </FormControl>
  );
};

export default AccessFormatDropDown;
