import { Address, Property } from 'property/propertyType';

export enum PublicAccessState {
  On = 'on',
  Off = 'off',
  Warning = 'warning',
}
export interface PublicAccess {
  state: PublicAccessState;
  locked?: string[];
  unlocked?: string[];
}
export interface Group {
  id: number;
  name: string;
  units: Property[];
  properties: Property[];
  group_type: string;
  public_access?: PublicAccess;
  time_frame?: {
    hours: {
      start: string;
      end: string;
    };
  };
  formattedTime?: string;
  locationType?: string;
  public_access_state?: PublicAccessState;
  description?: string;
  address?: Address;
}

export interface LocationData {
  name: string;
  description: string;
  units: number[];
  days: {
    mon: boolean;
    tue: boolean;
    wed: boolean;
    thu: boolean;
    fri: boolean;
    sat: boolean;
    sun: boolean;
  };
  hours: {
    start: string;
    end: string;
  };
  timezone: string;
  selectAllDays: boolean;
  selectAllHours: boolean;
  group_type: string;
}
export interface GroupDict {
  [Key: number]: Group;
}

export enum FilterType {
  PROPERTY = 'property',
  ACCESS_GROUP = 'accessGroup',
  ALL = 'all',
}

export interface RecordTrack {
  pageNum: number;
  numPerPage: number;
  total_records: number;
}

export interface GroupCounts {
  [FilterType.PROPERTY]: RecordTrack;
  [FilterType.ACCESS_GROUP]: RecordTrack;
  [FilterType.ALL]: RecordTrack;
}

export interface GroupType {
  type: FilterType;
}
export interface GroupState {
  list: number[];
  byId: GroupDict;
  meta: GroupCounts;
  groupType: GroupType;
}
