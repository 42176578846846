import { Button, Stack, SxProps } from '@mui/material';
import React from 'react';
import { FilterGroup } from 'types/filter-type';
import { DatePickerFilterPanel } from './DatePickerFilterPanel';
import { FilterPanel } from './FilterPanel';

export type FiltersToolbarProps = {
  searchParams: URLSearchParams;
  filterGroups: FilterGroup[];
  onFilterChange: (searchParams: URLSearchParams) => void;
  onFilterClear: (filterGroup: string) => void;
  isClearableFilters: (filterGroup: string) => boolean;
  updateChipLabel?: (filterGroup: string) => string;
};

export const FiltersToolbar = ({ searchParams, filterGroups, ...rest }: FiltersToolbarProps) => {
  const renderFilters = (filterGroup: FilterGroup, key: number) => {
    if (
      filterGroup.groupName === 'check_in' ||
      filterGroup.groupName === 'check_out' ||
      filterGroup.groupName === 'scheduled'
    ) {
      return (
        <DatePickerFilterPanel
          key={`${filterGroup}-filter-panel-${key}`}
          searchParams={searchParams}
          filter={filterGroup}
          {...rest}
        />
      );
    }

    return (
      <FilterPanel
        key={`${filterGroup}-filter-panel-${key}`}
        searchParams={searchParams}
        filter={filterGroup}
        {...rest}
      />
    );
  };

  return (
    <Stack direction='row' spacing={1} p={2}>
      {filterGroups.map((filterGroup: FilterGroup, index: number) =>
        renderFilters(filterGroup, index),
      )}
      {searchParams.toString().length > 0 && (
        <Button
          size='small'
          variant='text'
          onClick={() => rest.onFilterChange(new URLSearchParams())}
          data-testid='clear-all-button'
          sx={clearAllButtonStyle}
          style={{ marginLeft: '16px' }}
        >
          Clear filters
        </Button>
      )}
    </Stack>
  );
};

const clearAllButtonStyle: SxProps = {
  fontSize: '13px',
  fontWeight: '700',
  lineHeight: '171%',
  letterSpacing: '0.46px',
  textTransform: 'none',
};
