import React from 'react';
import { DefaultGenerics, StreamChat } from 'stream-chat';

export interface IClientContext {
  clientObject: {
    streamChatClient: StreamChat<DefaultGenerics>;
    clientReady: boolean;
    clientUnreadCount: number;
  };
  setClientObject?: React.Dispatch<
    React.SetStateAction<{
      streamChatClient: StreamChat<DefaultGenerics>;
      clientReady: boolean;
      clientUnreadCount: number;
    }>
  >;
}

export const streamChatClient = StreamChat.getInstance(
  process.env?.REACT_APP_STREAM_CHAT_KEY?.toString() || '',
);

export const ClientContext = React.createContext<IClientContext>({
  clientObject: { streamChatClient, clientReady: false, clientUnreadCount: 0 },
});
