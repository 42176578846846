import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React, { useState } from 'react';
import AutomateDeleteConfirmDialog from './AutomateDeleteConfirmDialog';

export interface AutomateActionMenuProps {
  workflowId: string;
  onClickEdit: () => void;
  onDeleteSuccess: () => void;
  onDeleteError: (error: Error) => void;
}

const AutomateActionMenu = ({
  workflowId,
  onClickEdit,
  onDeleteSuccess,
  onDeleteError,
}: AutomateActionMenuProps) => {
  const { t } = useTranslation();
  const [actionsMenuEl, setActionsMenuEl] = useState<HTMLButtonElement | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<string | null>(null);

  const handleDeleteClick = () => {
    setActionsMenuEl(null); // Close the menu
    setIsDeleteDialogOpen(true);
    setSelectedWorkflowId(workflowId);
  };

  const handleCloseDialog = () => {
    setIsDeleteDialogOpen(false);
    setSelectedWorkflowId(null);
  };

  const handleDeleteSuccess = () => {
    setIsDeleteDialogOpen(false);
    setSelectedWorkflowId(null);
    onDeleteSuccess();
  };

  const handleDeleteFailure = (error: Error) => {
    onDeleteError(error);
  };

  return (
    <>
      <IconButton
        data-testid='action-icon-btn'
        onClick={e => setActionsMenuEl(e.currentTarget)}
        size='large'
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={actionsMenuEl}
        open={Boolean(actionsMenuEl)}
        onClose={() => setActionsMenuEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onClickEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>{t('edit_workflow')}</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <DeleteOutlineIcon />
          </ListItemIcon>
          <ListItemText>{t('delete_workflow')}</ListItemText>
        </MenuItem>
      </Menu>
      {isDeleteDialogOpen && selectedWorkflowId && (
        <AutomateDeleteConfirmDialog
          workflowId={selectedWorkflowId}
          onClose={handleCloseDialog}
          onSuccess={handleDeleteSuccess}
          onFailure={handleDeleteFailure}
        />
      )}
    </>
  );
};

export default AutomateActionMenu;
