import { Box, FormHelperText, Grid, MenuItem, Select, SxProps } from '@mui/material';
import {
  NoiseThresholds,
  QUIET_FROM_DEFAULT,
  QUIET_NOISEAWARE_THRESHOLD,
  QUIET_TO_DEFAULT,
  REGULAR_NOISEAWARE_THRESHOLD,
} from 'device/SmartCards/NoiseAwareCard';
import * as React from 'react';
import { IThresholdExtended } from 'threshold/thresholdType';

export interface NoiseAwareSettingProps {
  onSave: (noise: NoiseThresholds, value: string) => void;
  noiseRegular: IThresholdExtended;
  noiseQuiet: IThresholdExtended;
}

const NoiseAwareSetting = ({ onSave, noiseRegular, noiseQuiet }: NoiseAwareSettingProps) => {
  const numNRSChoices = Array(20).fill(20);
  const numTimeChoices = Array(24).fill(24);

  const quietFrom = Number(noiseQuiet?.from || QUIET_FROM_DEFAULT);
  const quietTo = Number(noiseQuiet?.to || QUIET_TO_DEFAULT);
  const quietValue = Number(noiseQuiet?.value || QUIET_NOISEAWARE_THRESHOLD);
  const regValue = Number(noiseRegular?.value || REGULAR_NOISEAWARE_THRESHOLD);

  return (
    <Box sx={mainContainerStyle}>
      <Grid container spacing={3}>
        <Grid item lg={6}>
          <Select
            sx={thresholdInputStyles}
            fullWidth
            value={quietFrom}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onSave(NoiseThresholds.NoiseQuietFrom, e.target.value);
            }}
          >
            {numTimeChoices.map((choice, idx) => {
              const period = idx > 11 ? 'pm' : 'am';
              const hour = idx % 12 ? idx % 12 : 12;
              return <MenuItem value={idx} key={idx}>{`${hour}:00 ${period}`}</MenuItem>;
            })}
          </Select>

          <FormHelperText sx={{ color: 'white' }} id='outlined-weight-helper-text'>
            Quiet Start Time
          </FormHelperText>
        </Grid>
        <Grid item lg={6}>
          <Select
            sx={thresholdInputStyles}
            fullWidth
            value={quietTo}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onSave(NoiseThresholds.NoiseQuietTo, e.target.value);
            }}
          >
            {numTimeChoices.map((choice, idx) => {
              const period = idx > 11 ? 'pm' : 'am';
              const hour = idx % 12 ? idx % 12 : 12;
              return <MenuItem value={idx} key={idx}>{`${hour}:00 ${period}`}</MenuItem>;
            })}
          </Select>
          <FormHelperText sx={{ color: 'white' }} id='outlined-weight-helper-text'>
            Quiet End Time
          </FormHelperText>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={6}>
          <Select
            sx={thresholdInputStyles}
            data-testid='noise-quiet-threshold-input'
            fullWidth
            value={quietValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onSave(NoiseThresholds.NoiseQuiet, e.target.value);
            }}
          >
            {numNRSChoices.map((choice, idx) => (
              <MenuItem value={idx * 5} key={idx}>{`${idx * 5} NRS`}</MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ color: 'white' }} id='outlined-weight-helper-text'>
            Quiet Threshold
          </FormHelperText>
        </Grid>
        <Grid item lg={6}>
          <Select
            sx={thresholdInputStyles}
            data-testid='noise-regular-threshold-input'
            fullWidth
            value={regValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onSave(NoiseThresholds.NoiseReg, e.target.value);
            }}
          >
            {numNRSChoices.map((choice, idx) => (
              <MenuItem value={idx * 5} key={idx}>{`${idx * 5} NRS`}</MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ color: 'white' }} id='outlined-weight-helper-text'>
            Regular Threshold
          </FormHelperText>
        </Grid>
      </Grid>
    </Box>
  );
};

const mainContainerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  color: 'text',
  transition: 'all 500ms ease-out',
};

const thresholdInputStyles: SxProps = (mode?: string) => {
  return {
    fontSize: '22px',
    color: mode === 'dark' ? 'text.primary' : 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
  };
};

export default NoiseAwareSetting;
