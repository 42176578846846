import { combineReducers, Reducer } from '@reduxjs/toolkit';
import {
  PropertyCounts,
  PropertyDict,
  PropertyFilterType,
  PropertyState,
  RecordTrack,
} from 'property/propertyType';
import { Actions } from 'types/actions';

export const initialUnitById: PropertyDict = {};

const propertiesById: Reducer<PropertyDict> = (state = initialUnitById, action) => {
  switch (action.type) {
    case Actions.upsertProperties:
      return { ...state, ...action.propertiesById };

    case Actions.updateProperty:
    case Actions.insertProperty:
      return { ...state, [action.property.id]: action.property };

    case Actions.upsertProperty:
      return {
        ...state,
        [action.property.id]: action.property,
      };
    case Actions.deleteProperty:
      return state;

    default:
      return state;
  }
};

const initialRecordTrack: RecordTrack = {
  ids: [],
  pageNum: 0,
  numPerPage: 50,
  total_records: 0,
};

export const initialMeta: PropertyCounts = {
  currentFilter: PropertyFilterType.ALL_PROPERTIES,
  isLoading: false,
  [PropertyFilterType.ALL_PROPERTIES]: initialRecordTrack,
  [PropertyFilterType.ACTIVE_TODAY]: initialRecordTrack,
  [PropertyFilterType.UNACTIVATED]: initialRecordTrack,
  [PropertyFilterType.VACANT]: initialRecordTrack,
  [PropertyFilterType.CLEANING]: initialRecordTrack,
  [PropertyFilterType.FAVOURITES]: initialRecordTrack,
  [PropertyFilterType.COMMON]: initialRecordTrack,
  [PropertyFilterType.ROOM_TYPE]: initialRecordTrack,
  [PropertyFilterType.PUBLIC]: initialRecordTrack,
  [PropertyFilterType.ACCESS_GROUP]: initialRecordTrack,
  [PropertyFilterType.ALERT]: initialRecordTrack,
};

const meta: Reducer<PropertyCounts> = (state = initialMeta, action) => {
  switch (action.type) {
    case Actions.updatePropertyCleaningCounts:
      return { ...state, cleaning: action.meta };

    case Actions.updatePropertyGroupTypeCounts:
      return { ...state, public: action.meta };

    case Actions.updatePropertyCounts: {
      const filter: PropertyFilterType = action.filterType;
      let updatedMetaForFilterType = state[filter];

      if (action.meta) {
        const updatedIds =
          action.meta.pageNum === 0 ? action.meta.ids : [...state[filter].ids, ...action.meta.ids];

        updatedMetaForFilterType = {
          ...state[filter],
          ...action.meta,
          ids: Array.from(new Set(updatedIds)),
        };
      }

      return {
        ...state,
        currentFilter: action.filterType ?? state.currentFilter,
        isLoading: action.isLoading ?? state.isLoading,
        [action.filterType]: updatedMetaForFilterType,
      };
    }

    default:
      return state;
  }
};

const reducer: Reducer<PropertyState> = combineReducers<PropertyState>({
  byId: propertiesById,
  meta,
});

export default reducer;
