import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { Actions } from 'types/actions';
import { SettingsDict, SettingsState } from '../settingsType';

const initialSettingsById: SettingsDict = {};

const settingsByDeviceId: Reducer<SettingsDict> = (state = initialSettingsById, action) => {
  switch (action.type) {
    case Actions.hydrateDeviceSettings:
      return {
        ...state,
        [action.propertyId]: [...state[action.propertyId], ...action.settings],
      };
    default:
      return state;
  }
};

const reducer: Reducer<SettingsState> = combineReducers<SettingsState>({
  byDeviceId: settingsByDeviceId,
});

export default reducer;
