import { ToggleOff, ToggleOn } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PageviewIcon from '@mui/icons-material/Pageview';
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { guestVerificationEnabledSelector } from 'company/state/companySelectors';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { isAdmin } from 'redux/actions/ui';
import { GPProperty, useGuestPortal } from 'redux/guestportal';
import { userPermissionSelector } from 'user/state/userSelectors';

interface AssignedUnitsTableActionsProps {
  assignedProperty: GPProperty;
  smwEnabled: boolean;
  onPreview: () => void;
}

export function AssignedUnitsTableActions({
  assignedProperty,
  onPreview,
  smwEnabled,
}: AssignedUnitsTableActionsProps) {
  const hasPermission = useSelector(userPermissionSelector());
  const isVerificationEnabled = useSelector(guestVerificationEnabledSelector());
  const [actionsMenu, setActionsMenu] = useState<HTMLButtonElement | null>(null);
  const { updateGPActivated, initializeGuestPortalForUnit, updateAssignedProperty } =
    useGuestPortal();
  const isGPActive = assignedProperty.guest_portal_status === 'active';

  const handleVerificationPressed = () => {
    updateAssignedProperty({
      ...assignedProperty,
      guest_verification_enabled: !assignedProperty?.guest_verification_enabled,
    });
    setActionsMenu(null);
  };

  const handleSmwToggled = () => {
    updateAssignedProperty({
      ...assignedProperty,
      smw_enabled: !assignedProperty.smw_enabled,
    });
    setActionsMenu(null);
  };

  const renderActions = () => {
    return (
      <Menu
        id='simple-menu'
        anchorEl={actionsMenu}
        open={Boolean(actionsMenu)}
        onClose={() => setActionsMenu(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            updateGPActivated({
              property_ids: [assignedProperty.propertyId],
              status: isGPActive ? 'inactive' : 'active',
            });
            setActionsMenu(null);
          }}
          disabled={!assignedProperty.guest_portal_initialized}
        >
          <ListItemIcon>{isGPActive ? <ToggleOff /> : <ToggleOn color='primary' />}</ListItemIcon>
          <ListItemText>{isGPActive ? 'Deactivate' : 'Active'}</ListItemText>
        </MenuItem>

        {isAdmin() && isVerificationEnabled && (
          <MenuItem disabled={!isGPActive} onClick={handleVerificationPressed}>
            <ListItemText>
              {assignedProperty.guest_verification_enabled ? 'Disable' : 'Enable'} Verification
            </ListItemText>
          </MenuItem>
        )}

        {isAdmin() && smwEnabled && (
          <MenuItem onClick={handleSmwToggled}>
            <ListItemText>
              {assignedProperty.smw_enabled ? 'Disable' : 'Enable'} YourKey+
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
    );
  };

  return (
    <Stack direction='row'>
      <IconButton size='small' color='primary' onClick={onPreview} disabled={!hasPermission}>
        <PageviewIcon />
      </IconButton>
      <IconButton
        size='small'
        onClick={e => setActionsMenu(e.currentTarget)}
        disabled={!hasPermission}
      >
        <MoreVertIcon />
      </IconButton>

      {renderActions()}
      {isAdmin() && (
        <Button
          disabled={assignedProperty.guest_portal_initialized}
          onClick={() => initializeGuestPortalForUnit(assignedProperty.propertyId)}
        >
          Enable
        </Button>
      )}
    </Stack>
  );
}
