import { SvgIconComponent } from '@mui/icons-material';
import { Icon, SxProps } from '@mui/material';
import React from 'react';

interface SideBarIconProps {
  icon: SvgIconComponent | string;
  className?: string;
  active?: boolean;
}

const SideBarIcon: React.FC<SideBarIconProps> = ({ icon: IconComponent, className, active }) => {
  const customStyle: SxProps = {
    color: active ? 'text.white' : 'text.secondary',
    fill: active ? 'text.white' : 'text.secondary',
  };

  return (
    <Icon
      className={className}
      sx={{
        ...customStyle,
      }}
      data-testid='sideBarIcon'
    >
      <IconComponent />
    </Icon>
  );
};

export default SideBarIcon;
