// @ts-nocheck

import { BaseContainer } from 'Style/Components/BaseComponents';
import _ from 'lodash';
import BasicMemberTable from 'member/Tables/BasicMemberTable';
import React from 'react';
import styled from 'styled-components';
import { SideBarContent } from 'ui-library/Styled/sidebar';
import { MemberCounts, RecordTrack } from './memberType';

const MemberFilterList = ['all_members', 'employee', 'contractor', 'inactive'];

const MemberUnitsContainer = ({
  data,
  members,
  meta,
  onChange,
  tabIdx,
  uncheckedSelection,
  checkedSelection,
}: {
  data: Record<string, unknown>;
  members: IMember[];
  checkedSelection: number[];
  uncheckedSelection: number[];
  tabIdx?: number;
  meta: RecordTrack;
  loading: boolean;
  onChange: React.Dispatch<
    React.SetStateAction<{
      check: number[];
      uncheck: number[];
      newChecked: number[];
      newUnChecked: number[];
      meta: MemberCounts;
    }>
  >;
}) => {
  const originalUncheckedList = uncheckedSelection;
  const originalCheckedList = checkedSelection;

  const [uncheckedList, setUncheckedList] = React.useState(data.uncheck);
  const [checkedList, setCheckedList] = React.useState(data.check);
  const [searchValue, setSearchValue] = React.useState('');
  const [newChecked, setNewChecked] = React.useState([]);
  const [newUnChecked, setNewUnchecked] = React.useState([]);

  const handleUncheckedChange = (uncheckedList: string[]) => {
    setUncheckedList(uncheckedList);
    let tempNewChecked = newChecked;
    tempNewChecked = tempNewChecked.filter(item => !uncheckedList.includes(item));
    setNewChecked(tempNewChecked);
  };
  const handleCheckedChange = (checkedList: string[]) => {
    setCheckedList(checkedList);
  };
  const handleNewChecked = (newCheckedItem: unknown) => {
    const addItem = !originalCheckedList.includes(newCheckedItem);
    if (addItem) {
      const tempNewChecked = newChecked;
      tempNewChecked.push(newCheckedItem);
      setNewChecked(tempNewChecked);
    }
    let tempNewUnchecked = newUnChecked;
    tempNewUnchecked = tempNewUnchecked.filter(item => item !== newCheckedItem);
    setNewUnchecked(tempNewUnchecked);
  };

  const handleNewDeleted = (newItemToDelete: unknown) => {
    if (
      !newUnChecked.includes(newItemToDelete[0]) &&
      !originalUncheckedList.includes(newItemToDelete[0])
    ) {
      setNewUnchecked([...newUnChecked, ...newItemToDelete]);
    }
  };

  React.useEffect(() => {
    onChange({ check: checkedList, uncheck: uncheckedList, newChecked, newUnChecked });
  }, [checkedList, uncheckedList, newChecked, newUnChecked, onChange]);

  const handleSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  const memberData = members.filter((row: IMember) => {
    return (
      row.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
      row.job_title?.toLowerCase().includes(searchValue.toLowerCase())
    );
  });

  const propertyCheckboxList = _.difference(
    checkedList?.filter(id => memberData.map(member => member.id).includes(id)),
    uncheckedList,
  );
  return (
    <MainContainer>
      <CustomSideBarContent>
        <BasicMemberTable
          meta={meta}
          tabIdx={tabIdx}
          members={memberData}
          selectionModel={propertyCheckboxList}
          filterType={MemberFilterList[tabIdx]}
          uncheckedList={uncheckedList}
          updateUnchecked={handleUncheckedChange}
          checkedList={checkedList}
          updateChecked={handleCheckedChange}
          updateNewChecked={handleNewChecked}
          updateNewDeleted={handleNewDeleted}
          handleSearch={handleSearch}
          searchValue={searchValue}
        />
      </CustomSideBarContent>
    </MainContainer>
  );
};

const MainContainer = styled(BaseContainer)``;
const CustomSideBarContent = styled(SideBarContent)`
  && {
    padding: 24px;
  }
`;

export default MemberUnitsContainer;
