import { InputLabelProps, MenuItem } from '@mui/material';
import { Task } from '@operto/tasks-shared';
import { AddCard } from '@operto/ui-library';
import { FormContainer } from 'Common/FormContainer';
import format from 'date-fns/format';
import { DATE_FORMAT } from 'helper/date';
import { logger } from 'lib/logger';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormTextField } from 'ui-library/Components/input/FormTextField';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import useTasks from './useTasks';

export interface TaskDetailsProps {
  handleClose: () => void;
  taskId?: string;
}

const TITLE_MAX_LENGTH = 200;
const DESCRIPTION_MAX_LENGTH = 1000;

const TasksDetails = ({ handleClose, taskId }: TaskDetailsProps) => {
  const { isFetching, isLoading, getTask } = useTasks({ refetchOnWindowFocus: false });
  const isNew = taskId === undefined;
  const task: Task | undefined = !isNew ? getTask(taskId) : undefined;
  const minDueDate = format(new Date(), DATE_FORMAT);
  const {
    handleSubmit,
    watch,
    reset,
    control,
    formState: { isDirty, isValid },
  } = useForm<Task>({ mode: 'all' });

  const titleValue = watch('title');
  const titleLengthText = titleValue && `${titleValue?.length} / ${TITLE_MAX_LENGTH}`;

  const descriptionValue = watch('description');
  const descriptionLengthText =
    descriptionValue && `${descriptionValue?.length} / ${DESCRIPTION_MAX_LENGTH}`;

  const labelOptions = [
    { value: 'housekeeping', text: 'Housekeeping' },
    { value: 'accounting', text: 'Accounting' },
    { value: 'front desk', text: 'Front Desk' },
    { value: 'valet', text: 'Valet' },
    { value: 'maintenance', text: 'Maintenance' },
    { value: 'room service', text: 'Room Service' },
    { value: 'other', text: 'Other' },
  ];

  const statusOptions = [
    { value: 'requested', text: 'Requested' },
    { value: 'reviewed', text: 'Reviewed' },
    { value: 'scheduled', text: 'Scheduled' },
    { value: 'in progress', text: 'In Progress' },
    { value: 'completed', text: 'Completed' },
    { value: 'canceled', text: 'Canceled' },
    { value: 'closed', text: 'Closed' },
    { value: 'declined', text: 'Declined' },
  ];

  const priorityOptions = [
    { value: 'none', text: 'None' },
    { value: 'low', text: 'Low' },
    { value: 'medium', text: 'Medium' },
    { value: 'high', text: 'High' },
  ];

  const submitTask = (data: Task) => {
    if (data?.priority === 'none') {
      data.priority = undefined;
    }
    // TODO: just logging for now, submit functionality comes with OPN1-22047
    logger.info(`Submitted Task: ${JSON.stringify(data)}`);
    handleClose();
  };

  useEffect(() => {
    if (!isDirty) {
      // pre-populate the form in edit mode, reset due date and priority in create mode
      if (task?.data) {
        reset(task.data);
      }

      if (isNew) {
        reset({
          dueDateLocalized: minDueDate,
          priority: 'none',
        });
      }
    }
  }, [isDirty, task?.data, isNew, minDueDate, reset]);

  return (
    <LoadingContainer loading={!isNew && (isFetching || isLoading)}>
      <FormContainer
        title={isNew ? 'Create Task' : 'Task Details'}
        onClose={handleClose}
        dirty={isValid && isDirty}
        onSubmit={isNew && handleSubmit(submitTask)} // TODO: create button moved with OPN1-22047
        submitButtonTitle={isNew ? 'Add' : ''} // TODO: edit mode button comes with OPN1-22049
      >
        <AddCard title='Task'>
          <FormTextField
            rules={{
              required: 'Title is required',
              validate: value => value?.length <= TITLE_MAX_LENGTH || titleLengthText,
            }}
            required
            field='title'
            label='Title'
            control={control}
            helperText={titleLengthText}
            FormHelperTextProps={{ sx: { textAlign: titleValue && 'right' } }}
            InputLabelProps={taskDetailsAsteriskStyles}
          />
          <FormTextField
            rules={{
              required: 'Label is required',
            }}
            required
            select
            field='type'
            label='Label'
            control={control}
            InputLabelProps={taskDetailsAsteriskStyles}
          >
            {labelOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </FormTextField>
          <FormTextField select field='status' label='Status' control={control}>
            {statusOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </FormTextField>
          <FormTextField
            field='dueDateLocalized'
            label='Due on'
            type='datetime-local'
            control={control}
            inputProps={{ min: minDueDate }}
          />
          <FormTextField select field='priority' label='Priority' control={control}>
            {priorityOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </FormTextField>
          <FormTextField
            rules={{
              validate: value => value?.length <= DESCRIPTION_MAX_LENGTH || descriptionLengthText,
            }}
            multiline
            rows={8}
            field='description'
            label='Description'
            control={control}
            helperText={descriptionLengthText}
            FormHelperTextProps={{ sx: { textAlign: descriptionValue && 'right' } }}
          />
        </AddCard>

        <AddCard title='Assignee'>
          <FormTextField select field='assigneeId' label='Assignee' control={control}>
            <MenuItem value='none'>None</MenuItem>
            {/* TODO: assignee select menu comes in OPN1-22103 */}
          </FormTextField>
        </AddCard>
      </FormContainer>
    </LoadingContainer>
  );
};

const taskDetailsAsteriskStyles: InputLabelProps = {
  shrink: true,
  sx: { '.MuiInputLabel-asterisk': { color: '#D32F2F' } },
};

export default TasksDetails;
