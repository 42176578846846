import { GridValidRowModel } from '@mui/x-data-grid-pro';
import { PROPERTY_ALERT_TYPE } from 'propertyAlerts/propertyAlertType';
import React from 'react';
import BatteryIcon from 'ui-library/Components/icon/BatteryIcon';
import HubIcon from 'ui-library/Components/icon/HubIcon';
import LockOfflineIcon from 'ui-library/Components/icon/LockOfflineIcon';
import NoiseIcon from 'ui-library/Components/icon/NoiseIcon';
import OccupancyIcon from 'ui-library/Components/icon/OccupancyIcon';
import ThermostatIcon from 'ui-library/Components/icon/ThermostatIcon';

interface SingleAlertRendererProps {
  rowData: GridValidRowModel;
}

const SingleAlertRenderer = ({ rowData }: SingleAlertRendererProps) => {
  if (rowData.data && rowData.type === PROPERTY_ALERT_TYPE.BATTERY_WARNING) {
    // battery level alert
    if (rowData.data.battery_status || rowData.data.battery) {
      return (
        <>
          <BatteryIcon status={rowData.data.battery_status || rowData.data.battery} />{' '}
          {rowData.title}
        </>
      );
    }
  }
  if (rowData.data && rowData.type === PROPERTY_ALERT_TYPE.COMPLIANCE_RISK) {
    // noise alert
    if (rowData.title.indexOf('occupancy') !== -1) {
      return (
        <>
          <OccupancyIcon />
          &nbsp;<span>{rowData.title}</span>
        </>
      );
    }
    if (rowData.data.noise !== undefined) {
      return (
        <>
          <NoiseIcon />
          &nbsp;<span>{rowData.title}</span>
        </>
      );
    }
  }
  if (rowData.data && rowData.type === PROPERTY_ALERT_TYPE.ENVIRONMENT_RISK) {
    // temp alert
    if (rowData.data.current_value) {
      return (
        <>
          <ThermostatIcon />
          &nbsp;
          <span>
            {rowData.title} {rowData.current_value}
          </span>
        </>
      );
    }
  }
  if (rowData.data && rowData.type === PROPERTY_ALERT_TYPE.LOCK_OFFLINE) {
    // lock offline
    return (
      <>
        <LockOfflineIcon showAsChip />
        &nbsp;{rowData.title}
      </>
    );
  }
  if (rowData.data && rowData.type === PROPERTY_ALERT_TYPE.HUB_OFFLINE) {
    // lock offline
    return (
      <>
        <HubIcon showAsPill />
        &nbsp;<span>{rowData.title}</span>
      </>
    );
  }
  return rowData.title;
};

export default SingleAlertRenderer;
