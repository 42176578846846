import { Box, Divider, ListItem, Typography } from '@mui/material';
import List from '@mui/material/List';
import { useMedia } from '@operto/ui';
import ActivityFeedListItem from 'Common/Lists/ActivityFeedListItem';
import SearchField from 'Common/TextField/SearchField';
import { startOfDay } from 'date-fns';
import isSameDay from 'date-fns/isSameDay';
import { ActivityCodes, FilterType, IEvent, RecordTrack } from 'event/eventType';
import { utcStringToDate, utcToTimeZone } from 'helper/date';
import * as React from 'react';
import { Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import OpertoProgressCircle from 'ui-library/Components/misc/OpertoProgressCircle';
import TabBar from 'ui-library/Components/tabBar/TabBar';
import Widget from 'ui-library/Components/widget/Widget';

const activityTabItems = [
  { label: 'View All', value: FilterType.ALL_ACTIVITIES, isDropDown: false },
  { label: 'Team', value: FilterType.TEAM, isDropDown: false },
  { label: 'Access', value: FilterType.ACCESS, isDropDown: false },
  { label: 'Devices', value: FilterType.DEVICES, isDropDown: false },
  { label: 'Guests', value: FilterType.GUESTS, isDropDown: false },
];

const DateSeparator = ({ event }: { event: IEvent }) => {
  return (
    <ListItem
      key={`event-${event.id}`}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Divider sx={{ flex: 1 }} />
      <Typography
        variant='body2'
        sx={{
          background: '#fff',
          paddingLeft: '10px',
          paddingRight: '10px',
          fontSize: '14px',
          color: '#00000099',
        }}
      >
        {utcToTimeZone(event.created_at, event.timezone, 'EEEE MMMM dd, yyyy')}
      </Typography>
      <Divider sx={{ flex: 1 }} />
    </ListItem>
  );
};

export interface ActivityWidgetProps {
  events: IEvent[];
  meta: RecordTrack;
  filterIdx?: FilterType;
  keyword?: string;
  // TODO: revisit this, why is this a mouse event and not just plain text string change?
  onSearch: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onTabChange?: (newValue: FilterType) => void;
  onFetchMore: () => void;
  isMainActivity: boolean;
  loading?: boolean;
}

const MainActivityCard = ({
  events,
  meta,
  filterIdx,
  keyword,
  onSearch,
  onTabChange,
  onFetchMore,
  isMainActivity,
  loading,
}: ActivityWidgetProps) => {
  const hasMore = events?.length > 0 && meta.totalFetched > 0;
  const { isMobile } = useMedia();

  const renderEvents = () => {
    let prevEvent: IEvent;
    return events?.map((event: IEvent, index: number) => {
      let showDateSeparator = false;
      if (prevEvent) {
        showDateSeparator = !isSameDay(
          startOfDay(utcStringToDate(prevEvent.created_at)),
          startOfDay(utcStringToDate(event.created_at)),
        );
      }
      prevEvent = event;

      return (
        <Fragment key={`activity-feed-list-item-${event.id}-${index}`}>
          {showDateSeparator && <DateSeparator event={event} />}
          <ActivityFeedListItem
            title={event.title}
            message={event.message}
            codeType={event.type as ActivityCodes}
            payload={event}
          />
        </Fragment>
      );
    });
  };

  return (
    <Widget
      title={!isMobile && 'Recent Activities'}
      removeGutter
      isMainActivity={isMainActivity}
      fill='background.paper'
      mode={'light'}
    >
      <Box sx={{ padding: isMobile ? '12px' : '24px' }}>
        <SearchField
          value={keyword}
          onChange={event => onSearch(event as unknown as React.MouseEvent<HTMLButtonElement>)}
          fullWidth
          isMainActivity={isMainActivity}
        />
      </Box>
      {filterIdx && onTabChange && (
        <TabBar
          tabItems={activityTabItems}
          tabItemsStyle={{ flexGrow: 1 }}
          tabIndex={activityTabItems.findIndex(item => item.value === filterIdx)}
          onTabChange={index => onTabChange(activityTabItems[index].value)}
        />
      )}
      <LoadingContainer loading={loading}>
        <List
          id='scrollable-activity'
          sx={{
            width: '100%',
            height: '550px',
            overflowY: 'scroll',
          }}
        >
          <InfiniteScroll
            dataLength={events?.length || 0}
            next={onFetchMore}
            hasMore={hasMore}
            scrollableTarget='scrollable-activity'
            loader={<OpertoProgressCircle />}
          >
            {renderEvents()}
          </InfiniteScroll>
        </List>
      </LoadingContainer>
    </Widget>
  );
};

export default MainActivityCard;
