import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Badge } from '@mui/material';
import { getMemberProfilePicture } from 'api/memberAPI';
import { AxiosResponse } from 'axios';
import * as React from 'react';
import ProfileIcon from 'ui-library/Components/icon/ProfileIcon';
import { IMember, MemberSiteAccess } from './memberType';

export interface MemberProfileProps {
  memberData: IMember;
  size?: 'small' | 'medium' | 'large' | 'exlarge';
  cachedProfile?: string;
  onLoad?: (base64Data: string) => void;
}

const MemberProfile = ({ memberData, size, cachedProfile = '', onLoad }: MemberProfileProps) => {
  const data = memberData;
  const isDashboard = data?.site_access_id?.find((id: number) => id === MemberSiteAccess.DASHBOARD);
  const [profile_picture_blob, setProfile_picture_blob] = React.useState(cachedProfile);

  React.useEffect(() => {
    if (data?.profile_picture_url !== '' && data?.profile_picture_url !== null && data?.id) {
      getMemberProfilePicture(data?.id).then((data: AxiosResponse) => {
        const base64Data = data.data.data.image_base64;
        if (cachedProfile === '') {
          setProfile_picture_blob(base64Data);
          onLoad?.(base64Data);
        }
      });
    }
  }, [data, cachedProfile, onLoad]);

  return (
    <>
      {isDashboard && (
        <Badge
          overlap='circular'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          badgeContent={
            <VerifiedUserIcon
              sx={{
                color: data.is_password_set ? '#4CAF50' : '#F0AD24',
                width: '16px',
                height: '20px',
              }}
            />
          }
        >
          <ProfileIcon size={size} imageUrl={profile_picture_blob} fullName={data.name} />
        </Badge>
      )}
      {!isDashboard && <ProfileIcon size={size} imageUrl={profile_picture_blob} />}
    </>
  );
};

export default MemberProfile;
