import { Stack, SxProps } from '@mui/material';
import { Text } from '@operto/ui';
import React from 'react';

const isStringOrNumber = (str: unknown) => {
  return typeof str === 'string' || typeof str === 'number';
};

type TableCellProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
  testId?: string;
  flexDirection?: 'column' | 'row';
  sx?: SxProps;
};

export const TableCell = ({ title, description, testId, flexDirection, sx }: TableCellProps) => {
  return (
    <Stack data-testid={testId} flexDirection={flexDirection} sx={sx}>
      {isStringOrNumber(title) ? <Text.BodySmall>{title}</Text.BodySmall> : title}
      {isStringOrNumber(description) ? (
        <Text.CaptionLarge color='text.secondary'>{description}</Text.CaptionLarge>
      ) : (
        description
      )}
    </Stack>
  );
};
