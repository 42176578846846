import { companySelector } from 'company/state/companySelectors';
import { IMember } from 'member/memberType';
import { getSelf } from 'member/state/memberSelectors';
import * as React from 'react';
import { useAppSelector } from 'redux/hooks';
import AccountContainer from './AccountContainer';

export const ACCOUNT_TAB = 0;
export const USER_PROFILE_TAB = 1;
export const GUEST_PORTAL_TAB = 2;
export const NOTIFICATIONS_TAB = 3;

const Account = () => {
  const company = useAppSelector(companySelector());
  const member = useAppSelector(getSelf()) as IMember;

  return <AccountContainer company={company} member={member} />;
};

export default Account;
