import { Box, Stack, Typography } from '@mui/material';
import format from 'date-fns-tz/format';
import { utcStringToDate } from 'helper/date';
import React from 'react';
import QRCode from 'react-qr-code';

const PreviewQRCodeLabel = ({ title, value }: { title: string; value: string }) => {
  return (
    <Stack direction='row' spacing={1}>
      <Typography variant='body1'>{title}</Typography>
      <Typography variant='body1'>{value}</Typography>
    </Stack>
  );
};

type PreviewQRCodeProps = {
  url: string;
  name?: string;
  checkIn?: string;
  checkOut?: string;
};

export const PreviewQRCode = ({ url, name, checkIn, checkOut }: PreviewQRCodeProps) => {
  return (
    <Stack spacing={2} justifyContent='center'>
      <Box>
        <Typography variant='subtitle2'>Login with: </Typography>
        {name && <PreviewQRCodeLabel title='Last Name:' value={name.split(' ')[1]} />}
        {checkIn && (
          <PreviewQRCodeLabel
            title='Start Date:'
            value={format(utcStringToDate(checkIn), 'MMM dd yyyy')}
          />
        )}
        {checkOut && (
          <PreviewQRCodeLabel
            title='End Date:'
            value={format(utcStringToDate(checkOut), 'MMM dd yyyy')}
          />
        )}
      </Box>

      <Box width='180px'>
        <Typography variant='body2'>Scan and preview on mobile.</Typography>
        <QRCode
          value={url}
          style={{ height: 'auto', maxWidth: '100%', width: '100%', marginTop: 8 }}
        />
      </Box>
    </Stack>
  );
};
