import Typography from '@mui/material/Typography';
import { logger } from 'lib/logger';
import React from 'react';
import { useUnclaimGuestReservationMutation } from 'redux/portal-guest-reservation/api-slice';
import { IReservation } from 'reservation/reservationType';
import ConfirmDialog from './ConfirmDialog';

export interface UnlinkAccountDialogProps {
  onClose: () => void;
  open: boolean;
  reservation: IReservation;
  guestId: string;
  onUnlinkSuccess: (message: string) => void;
}

const UnlinkAccountDialog = ({
  onClose,
  open,
  reservation,
  guestId,
  onUnlinkSuccess,
}: UnlinkAccountDialogProps) => {
  const [unclaimGuestReservation] = useUnclaimGuestReservationMutation();

  const handleUnlinkAccount = async () => {
    try {
      // Call RTK query to core to delete the record of the guest
      const target = { reservationId: reservation.id.toString(), guestId: guestId };
      await unclaimGuestReservation(target).unwrap();
      onUnlinkSuccess('Account unlinked');
    } catch (error) {
      onUnlinkSuccess('Error unlinking account');
      logger.error('Error when unlinking account');
    }
  };

  return (
    <ConfirmDialog
      open={open}
      title={
        <Typography variant='h3-700' marginX='24px' marginTop='16px'>
          Unlink account?
        </Typography>
      }
      onClose={onClose}
      submitButtonText='Unlink'
      submitButtonColor='primary'
      onSubmit={handleUnlinkAccount}
    >
      <Typography variant='body1'>This will unlink the Guest ID from the reservation.</Typography>
    </ConfirmDialog>
  );
};

export default UnlinkAccountDialog;
