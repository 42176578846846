import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { Features, GetStream } from 'member/memberType';
import { IUser, UserState } from 'user/userTypes';
import { Actions } from '../../types/actions';
import { UserRole } from '../../user/userTypes';

export const initialGetStreamUser: GetStream = { expiry: 0, id: '', token: '' };
export const initialState: IUser = {
  id: 0,
  name: '',
  companyName: '',
  getStreamUser: initialGetStreamUser,
};

const user: Reducer<IUser> = (state = initialState, action) => {
  switch (action.type) {
    case Actions.storeToken:
      return {
        id: action.memberId,
        name: '',
        companyName: '',
        getStreamUser: initialGetStreamUser,
      };
    // for now
    default:
      return state;
  }
};

const getStreamUser: Reducer<GetStream> = (state = initialGetStreamUser, action) => {
  switch (action.type) {
    case Actions.storeGetStreamUser:
      return {
        expiry: action.member.getstream.expiry,
        id: action.member.getstream.id,
        token: action.member.getstream.token,
      };
    default:
      return state;
  }
};

export const initialFeatures: Features = {
  incident_payment_enabled: true,
  mp_guest_enabled: true,
  mp_guest_portal_onboarding_enabled: true,
  mp_two_way_enabled: true,
  mp_campaigns_enabled: true,
};
const features: Reducer<Features> = (state = initialFeatures, action) => {
  switch (action.type) {
    case Actions.storeFeatures:
      return {
        incident_payment_enabled:
          action.member.features.incident_payment_enabled[0]?.incident_payment_enabled,
        mp_guest_enabled: action.member.features.mp_guest_enabled,
        mp_guest_portal_onboarding_enabled:
          action.member.features.mp_guest_portal_onboarding_enabled,
        mp_two_way_enabled: action.member.features.mp_two_way_enabled,
        mp_campaigns_enabled: action.member.features.mp_campaigns_enabled,
      };
    default:
      return state;
  }
};

export const initialRole: UserRole = '';
const role: Reducer<UserRole> = (state = initialRole, action) => {
  switch (action.type) {
    case Actions.storeRole:
      return action.member.role;
    default:
      return state;
  }
};

export const initialAccessToken = '';
const accessToken: Reducer<string> = (state = initialAccessToken, action) => {
  switch (action.type) {
    case Actions.storeToken:
      return action.token;
    default:
      return state;
  }
};
const permission: Reducer<string> = (state = initialAccessToken, action) => {
  switch (action.type) {
    case Actions.identifyUser:
      return action.permissino;
    default:
      return state;
  }
};

const reducers: Reducer<UserState> = combineReducers<UserState>({
  user,
  accessToken,
  permission,
  getStreamUser,
  features,
  role,
});

export default reducers;
