import { Button, Stack, SxProps } from '@mui/material';
import { SearchTextField } from 'Common/TextField/SearchTextField';
import React, { useState } from 'react';
import LoadingContainer from '../misc/LoadingContainer';
import { CheckboxOption } from '../table/CheckboxOption';
import { clearButtonStyle } from '../table/FilterPanel';

export type Option = {
  label: string;
  id: string | number;
};

type SelectableMenuProps = {
  onChange: (checked: boolean, option: Option) => void;
  options: Option[];
  selectedOptions: Option[];
  onClear?: () => void;
  isLoading?: boolean;
};

export const SelectableMenu = ({
  onChange,
  options,
  selectedOptions,
  onClear,
  isLoading,
}: SelectableMenuProps) => {
  const [searchValue, setSearchValue] = useState<string>('');

  return (
    <Stack>
      <SearchTextField
        onChange={value => setSearchValue(value)}
        sx={searchBarContainerStyle}
        InputProps={{ sx: { borderRadius: '5px', minHeight: '55px' } }}
        size='medium'
        data-testid='tag-filter-search-bar'
      />

      <LoadingContainer loading={isLoading}>
        <Stack sx={checkBoxesContainer}>
          {options?.map((option: Option) => {
            if (
              searchValue === '' ||
              option.label?.toLowerCase().includes(searchValue.toLowerCase())
            ) {
              const isChecked = selectedOptions.some(
                checkedOption => checkedOption.id === option.id,
              );
              return (
                <CheckboxOption
                  key={`${option.id}`}
                  testID={`${option.id}`}
                  option={option}
                  onChange={checked => onChange(checked, option)}
                  checked={isChecked}
                />
              );
            }
          })}
        </Stack>
      </LoadingContainer>

      <Stack direction='row' justifyContent='end'>
        <Button
          variant='text'
          onClick={() => onClear()}
          sx={clearButtonStyle(selectedOptions.length > 0)}
        >
          Clear
        </Button>
      </Stack>
    </Stack>
  );
};

const searchBarContainerStyle: SxProps = {
  padding: '0',
  marginTop: '10px',
  marginLeft: '0',
  width: '100%',
  '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
    left: '25px',
  },
};

const checkBoxesContainer: SxProps = {
  height: '250px',
  overflowY: 'scroll',
  padding: '10px 0',
  marginBottom: '20px',
};
