import * as React from 'react';

import { StickyNote2Outlined } from '@mui/icons-material';
import { Box, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { Text, useMedia } from '@operto/ui';
import { IPaymentInfo, PaymentStatus } from 'guest/guestType';

export interface SecurityDepositCellProps {
  securityDeposit: IPaymentInfo;
}

const SecurityDepositBoxSxProps: SxProps = {
  borderRadius: '4px',
  padding: '0px 4px',
  display: 'flex',
  alignItems: 'center',
  marginRight: '8px',
};

const SecurityDepositCell = ({ securityDeposit }: SecurityDepositCellProps) => {
  const { isDesktop } = useMedia();

  const renderIcon = (status: PaymentStatus) => {
    switch (status) {
      case PaymentStatus.DECLINED:
        return (
          <Box
            sx={{
              ...SecurityDepositBoxSxProps,
              color: '#AE2F24',
              backgroundColor: '#FFD5D2',
              textTransform: 'capitalize',
            }}
          >
            <Typography
              sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '166%', letterSpacing: '0.1px' }}
            >
              Declined
            </Typography>
          </Box>
        );
      case PaymentStatus.CHARGED:
        return (
          <Box
            sx={{
              ...SecurityDepositBoxSxProps,
              color: '#206A83',
              backgroundColor: '#C6EDFB',
              textTransform: 'capitalize',
            }}
          >
            <Typography
              sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '166%', letterSpacing: '0.1px' }}
            >
              Charged
            </Typography>
          </Box>
        );
      case PaymentStatus.PENDING:
        return (
          <Box
            sx={{
              ...SecurityDepositBoxSxProps,
              color: '#5E4EB3',
              backgroundColor: '#E0D8FE',
              textTransform: 'capitalize',
            }}
          >
            <Typography
              sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '166%', letterSpacing: '0.1px' }}
            >
              Pending
            </Typography>
          </Box>
        );
      case PaymentStatus.RELEASED:
        return (
          <Box
            sx={{
              ...SecurityDepositBoxSxProps,
              color: '#00000099',
              backgroundColor: '#F1F2F5',
              textTransform: 'capitalize',
            }}
          >
            <Typography
              sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '166%', letterSpacing: '0.1px' }}
            >
              Released
            </Typography>
          </Box>
        );
      case PaymentStatus.HOLD:
        return (
          <Box
            sx={{
              ...SecurityDepositBoxSxProps,
              color: '#7F5E01',
              backgroundColor: '#F9E59F',
              textTransform: 'capitalize',
            }}
          >
            <Typography
              sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '166%', letterSpacing: '0.1px' }}
            >
              Hold
            </Typography>
          </Box>
        );
      case PaymentStatus.MANUALLY_ACCEPTED:
        return (
          <Box
            sx={{
              ...SecurityDepositBoxSxProps,
              color: '#216E4E',
              backgroundColor: '#BBF3DA',
            }}
          >
            <Typography
              sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '166%', letterSpacing: '0.1px' }}
            >
              Manually accepted
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  const securityDepositAmount = securityDeposit?.deposit_amount;
  const securityChargeAmount = securityDeposit?.charge_amount;
  // To cover wrong inputs from core or stripe
  const securityDepositAmountText = Number.isNaN(securityDepositAmount)
    ? '---'
    : `$${securityDepositAmount?.toFixed(2)}`;

  const securityChargeAmountText = Number.isNaN(securityChargeAmount)
    ? '---'
    : `$${securityChargeAmount?.toFixed(2)}`;

  let finalText: string | React.ReactNode = (
    <Text.BodySmallSemiBold>{securityDepositAmountText}</Text.BodySmallSemiBold>
  );

  if (securityDeposit?.status === PaymentStatus.CHARGED) {
    finalText = (
      <>
        <Text.BodySmallSemiBold>{securityChargeAmountText} /</Text.BodySmallSemiBold>
        {securityDepositAmountText}
      </>
    );
  }

  const showNotes =
    securityDeposit?.status === PaymentStatus.MANUALLY_ACCEPTED && !!securityDeposit?.notes;

  return (
    <Stack direction={isDesktop ? 'row' : 'column'}>
      {renderIcon(securityDeposit?.status)}
      {showNotes ? (
        <Tooltip title={securityDeposit?.notes} placement='top'>
          <StickyNote2Outlined color='primary' sx={{ width: '16px', height: '16px' }} />
        </Tooltip>
      ) : (
        finalText
      )}
    </Stack>
  );
};

export default SecurityDepositCell;
