import { Add } from '@mui/icons-material';
import { ListItemIcon, Menu, MenuItem, PopoverOrigin, SxProps } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

export const MENU_OPTIONS = {
  NEW: 'new',
  TEMPLATE: 'template',
};

export type MenuOptionType = (typeof MENU_OPTIONS)[keyof typeof MENU_OPTIONS];

export default function UpsellNewMenu({
  anchorEl,
  open,
  handleClose,
  useWidthOfAnchor = false,
  anchorOrigin,
  transformOrigin,
}: {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: (selectedOption?: MenuOptionType) => void;
  useWidthOfAnchor?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}) {
  const { t } = useTranslation();

  return (
    <Menu
      disablePortal
      id='new-upsell-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={() => handleClose(undefined)}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
        sx: { width: useWidthOfAnchor ? anchorEl?.offsetWidth : undefined },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
        ...(anchorOrigin || {}),
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
        ...(transformOrigin || {}),
      }}
    >
      <MenuItem onClick={() => handleClose(MENU_OPTIONS.NEW)} sx={menuItemStyles}>
        <ListItemIcon>
          <Add />
        </ListItemIcon>
        {t('create_new')}
      </MenuItem>
      <MenuItem onClick={() => handleClose(MENU_OPTIONS.TEMPLATE)} sx={menuItemStyles}>
        <ListItemIcon>
          <Add />
        </ListItemIcon>
        {t('create_from_template')}
      </MenuItem>
    </Menu>
  );
}

const menuItemStyles: SxProps = {
  gap: '20px',
};
