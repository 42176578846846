import { Box, Checkbox, ListItemAvatar, Typography } from '@mui/material';
import { MessageFilterIndex } from 'Pages/Messaging/MessagingPage';
import * as React from 'react';
import ProfileIcon from 'ui-library/Components/icon/ProfileIcon';

export interface ChatListItemProps {
  contactName: string;
  onClick?: () => void;
  chatMemberType: MessageFilterIndex;
  checkboxOnClick?: (reservationId: number) => void;
  reservationId: number;
  memberExists: boolean;
  unit?: string;
  imageUrl?: string;
}

const ChatListItem = ({
  contactName,
  onClick,
  chatMemberType,
  checkboxOnClick,
  reservationId,
  memberExists,
  unit,
  imageUrl,
}: ChatListItemProps) => {
  const isMember = chatMemberType === MessageFilterIndex.MEMBERS;
  const isGuest = chatMemberType === MessageFilterIndex.GUESTS;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingBottom: '20px',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {isMember && (
        <Checkbox
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            checkboxOnClick?.(reservationId);
          }}
          checked={memberExists}
        />
      )}
      <ListItemAvatar>
        <ProfileIcon imageUrl={imageUrl} fullName={contactName} />
      </ListItemAvatar>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {isGuest && <Typography variant='caption'>{unit}</Typography>}
        <Typography variant='subtitle2'>{contactName}</Typography>
      </Box>
    </Box>
  );
};

export default ChatListItem;
