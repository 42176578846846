import * as React from 'react';

import { FooterContainer, SmartCardButton, SmartCardIconButton } from 'ui-library/Styled/smartcard';
import HelpIcon from '@mui/icons-material/Help';

export interface HumidityFooterProps {
  onDetailsClick: () => void;
}

const HumidityFooter = ({ onDetailsClick }: HumidityFooterProps) => (
  <FooterContainer justify='space-between'>
    <SmartCardButton onClick={onDetailsClick}>More Details</SmartCardButton>
    <SmartCardIconButton>
      <HelpIcon />
    </SmartCardIconButton>
  </FooterContainer>
);

export default HumidityFooter;
