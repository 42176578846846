import { SxProps } from '@mui/material';

export const chipStyle = (state: 'Enabled' | 'Disabled' | 'Disconnected'): SxProps => {
  if (state === 'Enabled') {
    return {
      color: 'text.white',
      backgroundColor: 'success.main',
    };
  }

  if (state === 'Disabled') {
    return {
      color: 'text.white',
      backgroundColor: 'text.disabled',
    };
  }

  return {
    color: 'text.disabled',
    backgroundColor: 'text.white',
  };
};

export const statusStyle = (connected: boolean): SxProps => ({
  color: connected ? 'text.primary' : 'text.disabled',
  margin: '5px',
});

export const stackStyle = (connected: boolean): SxProps => ({
  opacity: connected ? 1 : 0.3,
  alignItems: 'center',
  justifyContent: 'center',
  height: '120px',
});

export const avatarStyle = {
  position: 'absolute',
  top: '11px',
  right: '-3px',
};
