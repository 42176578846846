import Typography from '@mui/material/Typography';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import { logger } from 'lib/logger';
import * as React from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { IReservation } from 'reservation/reservationType';
import { deleteReservation } from 'reservation/state/reservationActions';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';

export interface DeleteReservationDialogProps {
  onClose: () => void;
  open: boolean;
  reservation: IReservation;
}

const DeleteReservationDialog = ({ onClose, open, reservation }: DeleteReservationDialogProps) => {
  const dispatch = useAppDispatch();

  const handleCancelReservaton = async () => {
    try {
      await dispatch(deleteReservation(reservation.id));
      onClose();
    } catch (error) {
      logger.error(`deleting reservation: ${reservation.id}, error: ${error}`);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Error Cancelling Reservation',
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  return (
    <ConfirmDialog
      open={open}
      title='Cancel Reservation'
      onClose={onClose}
      submitButtonText='Delete'
      submitButtonColor='error'
      onSubmit={handleCancelReservaton}
    >
      <Typography variant='body1'>Are you sure you want to delete this reservation?</Typography>
    </ConfirmDialog>
  );
};

export default DeleteReservationDialog;
