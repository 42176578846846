import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockOutlined from '@mui/icons-material/LockOutlined';
import { BatteryStatus } from 'device/deviceType';
import React from 'react';
import {
  FilterDataType,
  FilterGroup,
  SlideDownDataType,
  UnitsFilterOptionResponse,
} from 'types/filter-type';
import BatteryIcon from 'ui-library/Components/icon/BatteryIcon';

const activeFilters = ['property_type', 'devices'];

const lockSubOption: SlideDownDataType[] = [
  {
    filterType: 'checkbox',
    label: 'Offline',
    name: 'offline',
    icon: <ErrorOutlineIcon style={{ color: '#D32F2F' }} />,
  },
  {
    filterType: 'checkbox',
    label: 'Unlocked',
    name: 'unlocked',
    icon: <LockOpenIcon style={{ color: 'grey' }} />,
  },
  {
    filterType: 'checkbox',
    label: 'Locked',
    name: 'locked',
    icon: <LockOutlined style={{ color: 'grey' }} />,
  },
];
const batterySubOption: SlideDownDataType[] = [
  {
    filterType: 'checkbox',
    label: 'Empty',
    name: 'empty',
    icon: <BatteryIcon status={BatteryStatus.EMPTY} customColor='#D32F2F' />,
  },
  {
    filterType: 'checkbox',
    label: 'Critical',
    name: 'critical',
    icon: <BatteryIcon status={BatteryStatus.CRITICAL} customColor='#D32F2F' />,
  },
  {
    filterType: 'checkbox',
    label: 'Low',
    name: 'low',
    icon: <BatteryIcon status={BatteryStatus.LOW} customColor='grey' />,
  },
  {
    filterType: 'checkbox',
    label: 'Medium',
    name: 'medium',
    icon: <BatteryIcon status={BatteryStatus.MEDIUM} customColor='grey' />,
  },
  {
    filterType: 'checkbox',
    label: 'Good',
    name: 'good',
    icon: <BatteryIcon status={BatteryStatus.GOOD} customColor='grey' />,
  },
];
const keypadSubOption: SlideDownDataType[] = [
  {
    filterType: 'checkbox',
    label: 'Empty',
    name: 'empty',
    icon: <BatteryIcon status={BatteryStatus.EMPTY} customColor='#D32F2F' />,
  },
  {
    filterType: 'checkbox',
    label: 'Critical',
    name: 'critical',
    icon: <BatteryIcon status={BatteryStatus.CRITICAL} customColor='#D32F2F' />,
  },
  {
    filterType: 'checkbox',
    label: 'Low',
    name: 'low',
    icon: <BatteryIcon status={BatteryStatus.LOW} customColor='grey' />,
  },
  {
    filterType: 'checkbox',
    label: 'Medium',
    name: 'medium',
    icon: <BatteryIcon status={BatteryStatus.MEDIUM} customColor='grey' />,
  },
  {
    filterType: 'checkbox',
    label: 'Good',
    name: 'good',
    icon: <BatteryIcon status={BatteryStatus.GOOD} customColor='grey' />,
  },
];

const mapUnitTypeLabel = (label: string) => {
  switch (label) {
    case 'primary':
      return 'Primary';
    case 'common':
      return 'Common';
    case 'additional':
      return 'Additional';
    case 'virtual':
      return 'Virtual';
    default:
      return null;
  }
};

export const createUnitsFilterOptions = (data: UnitsFilterOptionResponse) => {
  const filterGroups: FilterGroup[] = [];
  for (const [key, value] of Object.entries(data)) {
    if (!activeFilters.includes(key)) continue;

    if (key === 'property_type') {
      const propertTypeOptions: FilterDataType[] = [];
      (value as string[]).map((option: string) => {
        // when option is null, undefined or empty string, skip it
        if (!option || option === '') return;
        propertTypeOptions.push({
          filterType: 'checkbox',
          label: mapUnitTypeLabel(option),
          name: option,
        });
      });

      filterGroups.push({
        label: 'Unit Type',
        groupName: 'type',
        options: propertTypeOptions,
        order: 1,
      });
    }

    if (key === 'devices') {
      const deviceOptions: FilterDataType[] = [];
      for (const deviceKey of Object.keys(data[key])) {
        let slideDownData: SlideDownDataType[] = [];
        let name = '';
        let label = '';
        switch (deviceKey) {
          case 'lock':
            slideDownData = lockSubOption;
            name = 'lock';
            label = 'Lock';
            break;
          case 'battery':
            slideDownData = batterySubOption;
            name = 'lock_battery';
            label = 'Lock Battery';
            break;
          case 'keypad':
            slideDownData = keypadSubOption;
            name = 'lock_keypad';
            label = 'Keypad Battery';
            break;
        }

        deviceOptions.push({
          filterType: 'slidedown',
          label: label,
          name: name,
          slideDownData: slideDownData,
        });
      }

      filterGroups.push({
        label: 'Device',
        groupName: 'devices',
        options: deviceOptions,
        order: 2,
      });
    }
  }
  return filterGroups.sort((a, b) => a.order - b.order);
};
