import HelpIcon from '@mui/icons-material/Help';
import { Button } from '@mui/material';
import * as React from 'react';
import { FooterContainer, SmartCardIconButton } from 'ui-library/Styled/smartcard';

export interface NoiseFooterProps {
  onDetailsClick: () => void;
}

const NoiseFooter = ({ onDetailsClick }: NoiseFooterProps) => (
  <FooterContainer justify='space-between'>
    <Button sx={{ color: 'white' }} onClick={onDetailsClick}>
      More Details
    </Button>
    <SmartCardIconButton>
      <HelpIcon />
    </SmartCardIconButton>
  </FooterContainer>
);

export default NoiseFooter;
