import { AnyAction, combineReducers } from 'redux';
import sharedReducer, { activityReducer } from 'shared/state/sharedReducers';
import { Actions } from 'types/actions';

import codeReducer from 'code/state/codeReducer';
import companyReducer from 'company/state/companyReducer';
import dashboardReducer from 'dashboard/state/dashboardReducers';
import deviceReducer from 'device/state/deviceReducer';
import eventsReducer from 'event/state/eventReducers';
import guestReducer from 'guest/state/guestReducers';
import integrationReducer from 'integrations/state/integrationsReducers';
import groupsReducer from 'location/state/LocationsReducers';
import memberReducer from 'member/state/memberReducers';
import propertyReducer from 'property/state/propertyReducers';
import propertyAlertsReducer from 'propertyAlerts/state/propertyAlertReducers';
import reservationReducer from 'reservation/state/reservationReducer';
import settingsReducer from 'settings/state/settingsReducer';
import thresholdReducer from 'threshold/state/thresholdReducer';
import activeAlerts from './activeAlerts';
import alertsReducer from './alerts';
import commonPropertyReducer from './common-properties';
import dashboardConfig from './dashboard-config';
import favoriteProperties from './favorite-properties';
import intercomReducer from './intercoms';
import noiseAwareReducer from './noise-aware';
import notificationReducer from './notifications';
import pastAlerts from './pastAlerts';
import paymentInfoReducer from './payment-info';
import roleReducer from './roles';
import thermostatReducer from './thermostat';
import uiReducer from './ui/index';
import userReducer from './user';

import { NeoGatewayV2RTKQapi, NeoRestRTKQapi, RTKQapi } from 'redux/rtkq-api';
import { NovaRTKApi } from 'store/novaRTKQApi';
import { guestPortalSlice } from '../guestportal';
import { securityDepositSlice } from '../security-deposit';

const combinedReducers = combineReducers({
  properties: propertyReducer,
  integrations: integrationReducer,
  intercom: intercomReducer,
  commonProperties: commonPropertyReducer,
  alerts: alertsReducer,
  company: companyReducer,
  groups: groupsReducer,
  codes: codeReducer,
  notifications: notificationReducer,
  paymentInfo: paymentInfoReducer,
  members: memberReducer,
  roles: roleReducer,
  guests: guestReducer,
  user: userReducer,
  thermostat: thermostatReducer,
  events: eventsReducer,
  ui: uiReducer,
  noiseAware: noiseAwareReducer,
  favoriteProperties,
  activeAlerts,
  pastAlerts,
  dashboardConfig,
  shared: sharedReducer,
  reservations: reservationReducer,
  settings: settingsReducer,
  devices: deviceReducer,
  activities: activityReducer,
  thresholds: thresholdReducer,
  dashboard: dashboardReducer,
  [guestPortalSlice.name]: guestPortalSlice.reducer,
  [securityDepositSlice.name]: securityDepositSlice.reducer,
  propertyAlerts: propertyAlertsReducer,
  [RTKQapi.reducerPath]: RTKQapi.reducer,
  [NeoRestRTKQapi.reducerPath]: NeoRestRTKQapi.reducer,
  [NovaRTKApi.reducerPath]: NovaRTKApi.reducer,
  [NeoGatewayV2RTKQapi.reducerPath]: NeoGatewayV2RTKQapi.reducer,
});

const rootReducer = (state: ReturnType<typeof combinedReducers>, action: AnyAction) => {
  if (action.type === Actions.logout) {
    state = {} as ReturnType<typeof combinedReducers>;
  }

  return combinedReducers(state, action);
};

export type ApplicationState = ReturnType<typeof rootReducer>;
export default rootReducer;
