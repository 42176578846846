import { DialogTitle } from '@mui/material';
import { Text } from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import ConfirmDialog from './ConfirmDialog';

export type CommNavigationType = {
  language: string;
  navigationType: 'create' | 'edit';
};

export interface ConfirmLeaveDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const ConfirmLeaveDialog = ({ open, onClose, onSubmit }: ConfirmLeaveDialogProps) => {
  const { t } = useTranslation();

  const confirmDialogTitleComponent = (
    <DialogTitle sx={{ padding: '16px 24px' }}>
      <Text variant='h3-700'>{t('leave_without_saving')}</Text>
    </DialogTitle>
  );

  return (
    <ConfirmDialog
      open={open}
      title={confirmDialogTitleComponent}
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonText={t('leave')}
      rootStyles={{ '& div[role=dialog]': { maxWidth: '444px', borderRadius: '10px' } }}
      actionsStyles={{
        padding: '0px 16px 16px 16px',
        '& button': { textTransform: 'capitalize', fontWeight: '700' },
      }}
    >
      <Text variant='body-lg-400'>{t('changes_you_made_will_not_be_saved')}</Text>
    </ConfirmDialog>
  );
};

export default ConfirmLeaveDialog;
