import { Box, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import SearchField from 'Common/TextField/SearchField';
import React, { useState } from 'react';
import { GPProperty } from 'redux/guestportal';

export const isPropertyDisabled = (isEntranceInstructions: boolean, property: GPProperty) => {
  if (isEntranceInstructions) return !property?.guest_portal_initialized;

  return property?.guest_portal_status === 'inactive' || !property?.guest_portal_initialized;
};

type PreviewSearchListProps = {
  properties: GPProperty[];
  propertyId?: string;
  onSelect: (propertyId: string) => void;
  isEntranceInstructions?: boolean;
};

export const PreviewSearchList = ({
  propertyId = '',
  properties,
  onSelect,
  isEntranceInstructions,
}: PreviewSearchListProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedPropertyId, setSelectedPropertyId] = useState<string>(propertyId);

  const handleSelect = (propertyId: string) => {
    setSelectedPropertyId(propertyId);
    onSelect(propertyId);
  };

  return (
    <Box
      bgcolor='white'
      p={2}
      borderRadius={2}
      boxShadow={1}
      maxWidth='260px'
      maxHeight='80vh'
      overflow='auto'
    >
      <Typography variant='subtitle2'>Units</Typography>
      <SearchField
        size='small'
        value={searchValue}
        onChange={event => setSearchValue(event.currentTarget.value)}
      />
      <List>
        {properties
          ?.filter(property => property.name.toLowerCase().includes(searchValue.toLowerCase()))
          .map(property => (
            <ListItemButton
              key={property.propertyId}
              selected={Number(selectedPropertyId) === property?.propertyId}
              disabled={isPropertyDisabled(isEntranceInstructions, property)}
              onClick={() => handleSelect(`${property.propertyId}`)}
            >
              <ListItemText primary={property.name} />
            </ListItemButton>
          ))}
      </List>
    </Box>
  );
};
