import CheckIcon from '@mui/icons-material/Check';
import { Stack, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { dashboardStatSelector } from 'dashboard/state/dashboardSelectors';
import { utcToTimeZone } from 'helper/date';
import * as React from 'react';
import { toggleSlidebar as toggleSlidebarAction } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IReservation } from 'reservation/reservationType';
import { SlidebarType } from 'types/ui';
import ProfileIcon from 'ui-library/Components/icon/ProfileIcon';
import Widget from 'ui-library/Components/widget/Widget';
import IconLabel from 'ui-library/Components/widget/content/IconLabel';
import ShowMoreToggle from 'ui-library/Components/widget/content/ShowMoreToggle';
import { MainLabel, MiniListIcon, MiniListItem, MiniListLabel } from 'ui-library/Styled/smartcard';
import CheckInFooter from './CheckInFooter';

export interface CheckinCardProps {
  isCarouselCard?: boolean;
}

const CheckinCard = (props: CheckinCardProps) => {
  const [openDetails, setOpenDetails] = React.useState(false);
  const dispatch = useAppDispatch();

  const handleViewAll = () => {
    dispatch(toggleSlidebarAction(SlidebarType.CHECK_IN_RESERVATIONS, ''));
  };

  const checkInStats = useAppSelector(dashboardStatSelector('check_in_stats'));
  //@ts-ignore TODO: fix this
  const reservations = checkInStats?.reservations
    .filter((reservation: IReservation) => reservation.room_status === 'guest-checking-in')
    .slice(0, 3);

  return (
    <Widget
      isMainActivity={false}
      title='Guests check-in'
      footer={<CheckInFooter onClick={handleViewAll} />}
      isCarouselCard={props.isCarouselCard}
      fill='primary.main'
    >
      <ShowMoreToggle
        open={openDetails}
        onClick={() => {
          setOpenDetails(!openDetails);
        }}
        fill='primary.main'
      />

      <Typography variant='h2Light'>
        {/* @ts-ignore TODO: fix this */}
        {`${checkInStats?.total_check_in || 0} of ${checkInStats?.total || 0}`}
      </Typography>

      <IconLabel icon={<CheckIcon />} label='Checked-in' iconColor='success.light' />

      <Collapse in={openDetails}>
        <List dense>
          {reservations.map((reservation: IReservation) => (
            <MiniListItem key={reservation.id}>
              <MiniListIcon>
                <ProfileIcon fullName={reservation?.guest_name?.charAt(0)} />
              </MiniListIcon>
              <Stack sx={{ flexDirection: 'column' }}>
                <MiniListLabel variant='body2'>{reservation.guest_name}</MiniListLabel>
                <MainLabel variant='caption'>
                  {utcToTimeZone(reservation?.check_in, reservation?.timezone, 'MM/dd/yy')}
                </MainLabel>
              </Stack>
            </MiniListItem>
          ))}
        </List>
      </Collapse>
    </Widget>
  );
};

export default CheckinCard;
