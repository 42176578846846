import * as React from 'react';

import { Box, Button, Menu, MenuItem, SxProps } from '@mui/material';
import { CodeTypes } from 'types/ui';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export interface BackupCodeFooterProps {
  onClick: (footerType: CodeTypes) => void;
}

const BackupCodeFooter = ({ onClick }: BackupCodeFooterProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleTab = (footerType: CodeTypes) => {
    onClick(footerType);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      {/* @ts-ignore */}
      <Box sx={containerStyles}>
        <Button sx={{ color: 'white' }} onClick={handleClick} endIcon={<ArrowDropDownIcon />}>
          All Codes
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={() => handleTab(CodeTypes.GUEST_CODES)}>Guest Codes</MenuItem>
          <MenuItem onClick={() => handleTab(CodeTypes.BACKUP_CODES)}>Backup Codes</MenuItem>
          <MenuItem onClick={() => handleTab(CodeTypes.TEAM_MEMBER_CODES)}>Member Codes</MenuItem>
        </Menu>
      </Box>
    </>
  );
};

const containerStyles: SxProps = {
  justifyContent: 'flex-start',
  display: 'flex',
  color: 'text',
  alignItems: 'center',
  height: '100%',
};

export default BackupCodeFooter;
