import { Box } from '@mui/material';
import * as React from 'react';
import { SmartCardButton } from 'ui-library/Styled/smartcard';

export interface CheckInFooterProps {
  onClick: () => void;
}

const AlertsFooter = ({ onClick }: CheckInFooterProps) => (
  <Box sx={{ justifyContent: 'flex-start' }}>
    <SmartCardButton onClick={onClick}>More Details</SmartCardButton>
  </Box>
);

export default AlertsFooter;
