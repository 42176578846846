import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Menu, MenuItem } from '@mui/material';
import GuestCodesContainer from 'Pages/Unit/TabContainers/GuestCodesContainer';
import BackupCodesTable from 'code/BackupCodesTable';
import TeamMemberCodesTable from 'code/TeamMemberCodesTable';
import { getPropertyAccessCodes } from 'code/state/codeAction';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';

const codeType = ['backups', 'members', 'guests'] as const;
type CodeTypeParam = (typeof codeType)[number];

const getTitleFromCodeTypeParam = (type: CodeTypeParam) => {
  if (type === 'backups') {
    return 'Backup Codes';
  }

  if (type === 'members') {
    return 'Team Member Codes';
  }

  return 'Guest Codes';
};

const UnitCodesContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { propertyId: pidStr, type } = useParams();
  const propertyId = Number(pidStr);
  const [searchValue, setSearchValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const menu = (
    <>
      <Button
        color='primary'
        variant='contained'
        onClick={e => setAnchorEl(e.currentTarget)}
        endIcon={<ArrowDropDownIcon />}
        size={isMobile ? 'small' : 'medium'}
      >
        {getTitleFromCodeTypeParam(type as CodeTypeParam)}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => handleTab(codeType.indexOf('guests'))}>
          {getTitleFromCodeTypeParam('guests')}
        </MenuItem>
        <MenuItem onClick={() => handleTab(codeType.indexOf('backups'))}>
          {getTitleFromCodeTypeParam('backups')}
        </MenuItem>
        <MenuItem onClick={() => handleTab(codeType.indexOf('members'))}>
          {getTitleFromCodeTypeParam('members')}
        </MenuItem>
      </Menu>
    </>
  );

  const tableProps = {
    propertyId,
    loading,
    searchToolbarProps: { title: menu, onChange: setSearchValue, value: searchValue },
  };

  const handleTab = (tab: number) => {
    setAnchorEl(null);
    navigate(`/units/${propertyId}/access/unit-code/${codeType[tab]}`);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getPropertyAccessCodes(propertyId, null)).then(() => setLoading(false));
  }, [dispatch, propertyId]);

  if (type === 'members') {
    return <TeamMemberCodesTable {...tableProps} />;
  }

  if (type === 'backups') {
    return <BackupCodesTable {...tableProps} />;
  }

  return <GuestCodesContainer {...tableProps} />;
};

export default UnitCodesContainer;
