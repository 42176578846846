import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { AddCard } from '@operto/ui-library';
import useTranslation from 'hooks/useTranslation';
import React, { ChangeEvent } from 'react';
import renderTextField from '../TabsComponents/TextInput';
import { CustomButtonTextField } from './CustomButtonTextField';

interface PricingProps {
  confirmationMethod: string;
  paymentType: string;
  actionButtonText: string;
  quantityStepperEnabled: boolean;
  maxQuantity: number;
  quantityName: string;
  setConfirmationMethod: (confirmationMethod: string) => void;
  setPaymentType: (paymentType: string) => void;
  setActionButtonText: (actionButtonText: string) => void;
  setQuantityStepperEnabled: (quantityStepperEnabled: boolean) => void;
  setMaxQuantity: (maxQuantity: number) => void;
  setQuantityName: (quantityName: string) => void;
}

export const FreeItemPricing = ({
  confirmationMethod,
  paymentType,
  quantityStepperEnabled,
  maxQuantity,
  quantityName,
  actionButtonText,
  setConfirmationMethod,
  setPaymentType,
  setActionButtonText,
  setQuantityStepperEnabled,
  setMaxQuantity,
  setQuantityName,
}: PricingProps) => {
  const maxLength = 30;
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuantityName(event.target.value);
  };
  const { t } = useTranslation();
  const handleActionButtonTextUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    setActionButtonText(event.target.value);
  };
  const handleActionButtonTextClear = () => {
    setActionButtonText('');
  };

  return (
    <>
      <AddCard
        title={
          <>
            <Box display='flex' flexDirection='row' justifyContent='flex-start'>
              <Radio checked={paymentType === 'free'} onClick={() => setPaymentType('free')} />
              <Box display='flex' flexDirection='column' justifyContent='space-between'>
                <Typography variant='subtitle1'>{'Free'}</Typography>
                <Typography variant='body2'>{'No additional charge'}</Typography>
              </Box>
            </Box>
          </>
        }
      >
        {paymentType === 'free' && (
          <Box display='flex' flexDirection='column' justifyContent='space-between'>
            <FormControlLabel
              control={
                <Checkbox
                  name='quantity_stepper_enabled'
                  color='primary'
                  checked={quantityStepperEnabled}
                  onChange={() => setQuantityStepperEnabled(!quantityStepperEnabled)}
                />
              }
              label={t('quantity_stepper_ui')}
              sx={{ paddingTop: '10px' }}
            />

            {quantityStepperEnabled && (
              <>
                {renderTextField(
                  t('item_name'),
                  t('item_name'),
                  quantityName,
                  handleNameChange,
                  maxLength,
                  true,
                  1,
                  false,
                )}

                <TextField
                  label={t('quantity_limit')}
                  variant='outlined'
                  fullWidth
                  value={maxQuantity ? maxQuantity : ''}
                  required={true}
                  onChange={event => setMaxQuantity(Number(event.target.value))}
                />
              </>
            )}

            <Typography variant='subtitle1'>{'How guests will confirm this offer'}</Typography>

            <FormControl fullWidth margin='normal'>
              <RadioGroup
                value={confirmationMethod}
                onChange={event => setConfirmationMethod(event.target.value)}
              >
                <FormControlLabel
                  sx={{ paddingTop: '10px' }}
                  value='request'
                  control={<Radio />}
                  label={'Request (manual approval)'}
                />
                {confirmationMethod === 'request' && (
                  <CustomButtonTextField
                    actionButtonText={actionButtonText}
                    handleActionButtonTextClear={handleActionButtonTextClear}
                    handleActionButtonTextUpdate={handleActionButtonTextUpdate}
                    testId='custom-button-text-field-free-manual'
                  />
                )}
                <FormControlLabel
                  sx={{ paddingTop: '10px' }}
                  value='instant'
                  control={<Radio />}
                  label={'Get for free (auto approval)'}
                />
                {confirmationMethod === 'instant' && (
                  <CustomButtonTextField
                    actionButtonText={actionButtonText}
                    handleActionButtonTextClear={handleActionButtonTextClear}
                    handleActionButtonTextUpdate={handleActionButtonTextUpdate}
                    testId='custom-button-text-field-free-instant'
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Box>
        )}
      </AddCard>
    </>
  );
};
