import { SettingsOutlined } from '@mui/icons-material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { SettingCard } from '@operto/ui-library';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import QRCodeGeneratorDialog from 'Common/Dialog/QRCodeGeneratorDialog';
import EntranceInstructionsForm from 'Pages/GuestPortal/Settings/EntranceInstructions/EntranceInstructionsForm';
import { GuestPortalStatus, ProviderTypes } from 'company/companyType';
import { setCompanyVerificationProvider, updateCompany } from 'company/state/companyAction';
import { companySelector } from 'company/state/companySelectors';
import { languages } from 'hooks/useTranslation';
import { trackEvent } from 'lib/analytics';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { isAdmin, toggleSnackbar } from 'redux/actions/ui';
import { GuestPortalLoginTypes, Settings as SettingsData, useGuestPortal } from 'redux/guestportal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styled from 'styled-components';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { userPermissionSelector } from 'user/state/userSelectors';
import AdminPanelIcon from '../../../Common/AdminPanelIcon';
import { IEntranceInstructionsProperty } from '../../../redux/guestportal/models';

export const getLang = (locale: string) => {
  const lang = languages.find(l => l.locale === locale)?.name;
  return lang || 'English';
};

export const setLang = (lang: string) => {
  const langName = languages.find(l => l.name === lang)?.locale;
  return langName || 'en';
};

const PickerListItemSecondaryAction = styled(ListItemSecondaryAction)`
  && {
    .MuiFormGroup-root {
      width: 200px;
      text-align: left;
    }
  }
`;

type SettingsListItemSwitchProps = {
  title: string | JSX.Element;
  description?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  permission: boolean;
};
export const SettingsListItemSwitch: React.FC<SettingsListItemSwitchProps> = props => {
  const { title, description, value, onChange, permission } = props;

  return (
    <ListItem>
      <ListItemText primary={title} secondary={description} />
      <ListItemSecondaryAction>
        <Switch
          data-testid='settings-list-item-switch-input'
          edge='end'
          onChange={(e, val) => {
            onChange(val);
          }}
          checked={value}
          disabled={!permission}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

type SettingsListItemPickerProps = {
  title: string;
  description: string;
  value: string;
  items: string[];
  onChange: (value: string) => void;
  permission: boolean;
};
export const SettingsListItemPicker = (props: SettingsListItemPickerProps) => {
  const { title, description, value, items, onChange, permission } = props;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleClick = (newVal: string) => {
    onChange(newVal);
    handleClose();
  };
  return (
    <ListItem>
      <ListItemText
        primary={title}
        secondary={description}
        secondaryTypographyProps={{ style: { whiteSpace: 'normal', maxWidth: '560px' } }}
      />
      <PickerListItemSecondaryAction style={{ right: 0 }}>
        <FormGroup>
          <Button
            variant='outlined'
            disableElevation
            onClick={e => {
              setAnchorEl(e.currentTarget);
              setOpen(true);
            }}
            endIcon={<KeyboardArrowDownIcon />}
            sx={{
              justifyContent: 'space-between',
              width: '184px',
              height: '56px',
              margin: 0,
              textTransform: 'capitalize',
              fontSize: '16px',
              fontWeight: 400,
              color: '#000000',
              borderColor: '#BDBDBD',
            }}
            disabled={!permission}
            data-testid={'settings-list-item-picker-input'}
          >
            {value}
          </Button>
          <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
            {items.map(item => (
              <MenuItem
                data-testid={`settings-list-item-picker-menu-item-${item}`}
                key={item}
                value={item}
                style={{ textTransform: 'capitalize', width: '184px' }}
                onClick={() => handleClick(item)}
              >
                {item}
              </MenuItem>
            ))}
          </Menu>
        </FormGroup>
      </PickerListItemSecondaryAction>
    </ListItem>
  );
};

type AdminOnlyTitleProps = { title: string };

// TODO: move to ui library
export const AdminOnlyTitle = ({ title }: AdminOnlyTitleProps) => {
  return (
    <Stack direction='row' alignItems='center'>
      <Typography mr={1.5}>{title}</Typography>
      <AdminPanelIcon />
    </Stack>
  );
};

type SettingsCardProps = {
  settings: SettingsData;
  onSettingsChange: (p: SettingsData) => void;
  hasGuestPortal?: boolean;
  hasSmw?: boolean;
};

type SettingsListItemButtonProps = {
  title: string | JSX.Element;
  description?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  buttonText?: string;
};
export const SettingsListItemButton = (props: SettingsListItemButtonProps) => {
  const { title, description, onClick, buttonText } = props;

  return (
    <ListItem>
      <ListItemText primary={title} secondary={description} />
      <ListItemSecondaryAction>
        <Button variant='outlined' onClick={onClick} data-testid='settings-list-item-qr-input'>
          <FileDownloadOutlinedIcon sx={{ mr: 1 }} /> {buttonText}
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export const SettingsListItemBottomButton = (props: SettingsListItemButtonProps) => {
  const { onClick, buttonText } = props;

  return (
    <ListItem disablePadding>
      <Button onClick={onClick} sx={{ padding: '8px 5px', marginLeft: '16px' }}>
        <SettingsOutlined sx={{ mr: 1 }} />
        <Typography
          sx={{
            textTransform: 'initial',
            fontSize: '15px',
            fontWeight: '700',
            lineHeight: '173%',
            letterSpacing: '0.46px',
          }}
        >
          {buttonText}
        </Typography>
      </Button>
    </ListItem>
  );
};

export const SettingsCard: React.FC<SettingsCardProps> = props => {
  const [
    confirmDisableGuestPortalAndYourKeyPlusDialog,
    setConfirmDisableGuestPortalAndYourKeyPlusDialog,
  ] = useState<boolean>(false);
  const [openQrCodeGenerator, setOpenQrCodeGenerator] = useState<boolean>(false);
  const [openEntranceInstructionsForm, setOpenEntranceInstructionsForm] = useState<boolean>(false);
  const { settings, onSettingsChange, hasGuestPortal } = props;
  const company = useAppSelector(companySelector());
  const { fetchEntranceInstructions, assignedPropertiesRecords, entranceInstructionsData } =
    useGuestPortal();

  const digitalCheckInUpdate = async (updateToKey: ProviderTypes) => {
    await dispatch(setCompanyVerificationProvider(company.id, [updateToKey]));

    if (updateToKey !== ProviderTypes.NONE && settings.guestVerification) {
      // this turns the access approval off
      await onSettingsChange({ ...settings, guestVerification: false });
    }
  };

  const onLocaleDetectionChanged = () => {
    onSettingsChange({ ...settings, localeDetectionEnabled: !settings.localeDetectionEnabled });
  };

  const dispatch = useAppDispatch();

  const handleDisableGP = async (enabled: boolean, force = false) => {
    const status = enabled ? GuestPortalStatus.ACTIVE : GuestPortalStatus.INACTIVE;
    try {
      // SHOW confirmation dialog if...
      // IF status is false (disabling) AND the company is SMW ENABLED AND not already showing the confirmation diaolog
      if (!enabled && company.smwEnabled && !force) {
        setConfirmDisableGuestPortalAndYourKeyPlusDialog(true);
        return;
      }

      const companyToUpdate = {
        ...company,
        guestPortalStatus: status,
        smwEnabled: !enabled ? false : company.smwEnabled,
      };

      await dispatch(updateCompany(companyToUpdate));
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: `Successfully updated company's Guest Portal status to ${status}`,
          variant: SnackbarVariant.SUCCESS,
        }),
      );

      // The logic below disabes 'guest_portal_status' for all a company's properties.
      // TURNING THIS FUNCTIONALITY OFF FOR NOW
      // This requires a chat with CS team to determine how they want this to flow
      // if (!enabled) {
      //   const activeProperties = assignedPropertiesRecords
      //     .filter(ap => ap.guest_portal_status === 'active')
      //     .map(ap => parseInt(ap.propertyId.toString()));

      //   updateGPActivated({ property_ids: activeProperties, status }); // disable GP for all the company's properties
      // }
    } catch (e) {
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `Failed to update company's Guest Portal status to ${status}`,
        variant: SnackbarVariant.ERROR,
      });
    }
  };

  const loginMethodNameFinder = () => {
    if (settings?.challengeLastName && settings?.challengeDate) {
      return GuestPortalLoginTypes.BOTH;
    } else if (settings?.challengeLastName) {
      return GuestPortalLoginTypes.LASTNAME;
    } else if (settings?.challengeDate) {
      return GuestPortalLoginTypes.DATE;
    } else {
      return GuestPortalLoginTypes.NONE;
    }
  };

  const challengeSettingsEnabled = company?.verificationProvider !== ProviderTypes.SUPERHOG;

  useEffect(() => {
    const fetchData = async () => {
      await fetchEntranceInstructions();
    };
    fetchData();
  }, [fetchEntranceInstructions]);

  const entranceInstructionsPropertyList: IEntranceInstructionsProperty[] =
    assignedPropertiesRecords?.map(property => {
      return {
        propertyId: property.propertyId,
        name: property.name,
        guest_portal_initialized: property.guest_portal_initialized,
        entrance_instructions_enabled: property.entrance_instructions_enabled,
      };
    });

  return (
    <Box maxWidth='838px' marginX='auto'>
      <SettingCard title='Guest Portal Settings'>
        <List>
          <SettingsListItemSwitch
            title='Near me'
            description='Display the Google Places activity feed for your location.'
            value={settings?.nearmeEnabled ?? false}
            onChange={value => onSettingsChange({ ...settings, nearmeEnabled: value })}
            permission={hasGuestPortal}
          />
          <SettingsListItemPicker
            title='Set the default language'
            description='Your guests will see when visiting their Guest Portal.'
            value={getLang(settings?.locale)}
            items={languages.map(l => l.name)}
            onChange={value => onSettingsChange({ ...settings, locale: setLang(value) })}
            permission={hasGuestPortal}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={settings?.localeDetectionEnabled ?? false}
                onChange={onLocaleDetectionChanged}
                name='lang setting'
                color='primary'
                sx={{
                  marginLeft: 4,
                }}
              />
            }
            label='Use guest’s browser language; fallback to default if not supported.'
          />
          {isAdmin() && (
            <SettingsListItemSwitch
              title={<AdminOnlyTitle title='Access approval' />}
              description='Manually approve access by showing or hiding the access code.'
              value={settings?.guestVerification ?? false}
              onChange={value => onSettingsChange({ ...settings, guestVerification: value })}
              permission={
                hasGuestPortal &&
                company?.verificationProvider !== ProviderTypes.SUPERHOG &&
                company?.verificationProvider !== ProviderTypes.AUTOHOST
              }
            />
          )}
          <SettingsListItemSwitch
            title='Reservation ID'
            description="Display your guest's reservation ID within the Guest Portal."
            value={settings?.reservationDisplayEnabled ?? false}
            onChange={value => onSettingsChange({ ...settings, reservationDisplayEnabled: value })}
            permission={hasGuestPortal}
          />
          {isAdmin() && (
            <SettingsListItemSwitch
              title={<AdminOnlyTitle title='Guest Portal' />}
              description="Switch off to deactivate this company's Guest Portal"
              value={company?.guestPortalStatus === 'active' ?? false}
              onChange={value => handleDisableGP(value)}
              permission={hasGuestPortal}
            />
          )}
          {isAdmin() && (
            <SettingsListItemSwitch
              title={<AdminOnlyTitle title='Scan QR code to check in' />}
              description='Facilitate guests to perform on-site check-in via QR code scanning.'
              value={settings?.checkinQrEnabled ?? false}
              onChange={value => onSettingsChange({ ...settings, checkinQrEnabled: value })}
              permission={isAdmin()}
            />
          )}
          {isAdmin() && (
            <SettingsListItemButton
              title={<AdminOnlyTitle title='Download QR code to check in' />}
              onClick={() => {
                setOpenQrCodeGenerator(true);
              }}
              buttonText='QR Code'
            />
          )}
        </List>
      </SettingCard>
      <SettingCard title='Check-in Settings'>
        <List>
          {isAdmin() && (
            <SettingsListItemPicker
              title='Digital check-in'
              description='Enable guests to digitally check-in by validating ID and upload selfies through a 3rd-party partner.'
              value={company?.verificationProvider || ProviderTypes.NONE}
              items={settings?.verificationProviders || [ProviderTypes.NONE]}
              onChange={async (value: ProviderTypes) => {
                digitalCheckInUpdate(value);
              }}
              permission={hasGuestPortal}
            />
          )}
          <SettingsListItemPicker
            title='Guest login method'
            description='Choose a method for the guest to log into their Guest Portal.'
            value={loginMethodNameFinder()}
            items={[
              GuestPortalLoginTypes.LASTNAME,
              GuestPortalLoginTypes.DATE,
              GuestPortalLoginTypes.BOTH,
              GuestPortalLoginTypes.NONE,
            ]}
            onChange={value =>
              onSettingsChange({
                ...settings,
                challengeDate:
                  value === GuestPortalLoginTypes.DATE || value === GuestPortalLoginTypes.BOTH
                    ? true
                    : false,
                challengeLastName:
                  value === GuestPortalLoginTypes.LASTNAME || value === GuestPortalLoginTypes.BOTH
                    ? true
                    : false,
              })
            }
            permission={hasGuestPortal}
          />
          <SettingsListItemSwitch
            title='Terms and conditions'
            description='Display your terms and conditions, edited in the Global Text tab.'
            value={settings?.termsEnabled ?? false}
            onChange={value =>
              onSettingsChange({ ...settings, termsRequired: value, termsEnabled: value })
            }
            permission={hasGuestPortal && challengeSettingsEnabled}
          />
          <SettingsListItemSwitch
            title='Welcome message'
            description='Display a personalized welcome message to the guest, edited in the Global Text tab.'
            value={settings?.welcomeRequired ?? false}
            onChange={value => onSettingsChange({ ...settings, welcomeRequired: value })}
            permission={hasGuestPortal && challengeSettingsEnabled}
          />
          <SettingsListItemSwitch
            title='Contact info'
            description='Require guests to input their contact information.'
            value={settings?.contactRequired ?? false}
            onChange={value => onSettingsChange({ ...settings, contactRequired: value })}
            permission={hasGuestPortal && challengeSettingsEnabled}
          />
          <SettingsListItemSwitch
            title='Enable early check-in'
            value={settings?.checkinEarlyEnabled ?? false}
            onChange={value => onSettingsChange({ ...settings, checkinEarlyEnabled: value })}
            permission={hasGuestPortal}
          />
          <Box>
            <SettingsListItemSwitch
              title='Entrance instructions'
              value={settings?.entranceInstructionsEnabled ?? false}
              onChange={value => {
                onSettingsChange({ ...settings, entranceInstructionsEnabled: value });
                trackEvent({
                  screen: 'Settings',
                  event: 'EDITED',
                  feature: 'Entrance instructions',
                  enabled: value,
                });
              }}
              permission={hasGuestPortal}
            />
            {settings?.entranceInstructionsEnabled && (
              <SettingsListItemBottomButton
                title='Entrance instructions'
                onClick={() => {
                  setOpenEntranceInstructionsForm(true);
                }}
                buttonText='Settings'
              />
            )}
          </Box>
        </List>
      </SettingCard>

      <ConfirmDialog
        open={confirmDisableGuestPortalAndYourKeyPlusDialog}
        title='Disable Guest Portal and YourKey+?'
        submitButtonText='Disable'
        onSubmit={() => {
          handleDisableGP(false, true);
          setConfirmDisableGuestPortalAndYourKeyPlusDialog(false);
        }}
        onClose={() => setConfirmDisableGuestPortalAndYourKeyPlusDialog(false)}
      >
        <Typography variant='body2'>
          This will disable both the Guest Portal and YourKey+ for the selected company.
          <br />
          Select &apos;DISABLE&apos; to proceed or &apos;CANCEL&apos; to return to the previous
          screen.
        </Typography>
      </ConfirmDialog>

      <QRCodeGeneratorDialog
        open={openQrCodeGenerator}
        onClose={() => {
          setOpenQrCodeGenerator(false);
        }}
      />
      {entranceInstructionsData && (
        <EntranceInstructionsForm
          data={entranceInstructionsData}
          properties={entranceInstructionsPropertyList}
          openDrawer={openEntranceInstructionsForm}
          onClose={() => setOpenEntranceInstructionsForm(false)}
          onFetch={fetchEntranceInstructions}
        />
      )}
    </Box>
  );
};

type SettingsProps = {
  active: boolean;
};
export const Settings = ({ active }: SettingsProps) => {
  const { settings, setSettings, fetchSettings } = useGuestPortal();
  React.useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const company = useAppSelector(companySelector());
  const hasGuestPortal = useAppSelector(userPermissionSelector());

  if (!active) {
    return null;
  }

  return (
    <Box data-testid='settings-card'>
      <SettingsCard
        settings={settings}
        onSettingsChange={setSettings}
        hasGuestPortal={hasGuestPortal}
        hasSmw={company?.smwEnabled}
      />
    </Box>
  );
};

export default Settings;
