// TODO: Need review because this is mainly used for styled components, and we are not using styled components anymore
export interface IPalette {
  primary: string;
  error: string;
  warn: string;
  default: string;
  success: string;
  background: string;
  info: string;
  accentBackground: string;
  disabled: string;
}

export interface IOpertoTheme {
  smartCards: SmartCards;
  activeTextColor: string;
  disabled: string;
  sideBarTextColor: string;
  sideBarColor: string;
  textColorWhite: string;
  textColor: string;
  textColorLight: string;
  containerAccentBGColor: string;
  containerBGColor: string;
  containerDarkBGColor: string;
  borderColor: string;
  buttonPrimaryColor: string;
  tabColorActive: string;
  tabColorInactive: string;
  tabColor: string;
  tabBGColor: string;
  subHeaderBGColor: string;
  subHeaderAltBGColor: string;
  topBarColor: string;
  graphFillColor: string;
  memberIconColor: string;
  campaignIconColor: string;
  guestPortalIconColor: string;
  messengerIconColor: string;
  radialBarBaseColor: string;
  radialBarFirstBarColor: string;
  radialBarSecondBarColor: string;
  palette: IPalette;
  icons: Icons;
  rightSideDrawerIcon: string;
  battery: Battery;
  secondaryButton: string;
  hoverButton: string;
  verificationStatus: VerificationStatus;
}

export interface Battery {
  disabled: string;
  good: string;
  medium: string;
  low: string;
  critical: string;
  empty: string;
}

export interface Icons {
  messengerIcon: string;
  campaignsIcon: string;
}

export interface SmartCards {
  bg1: string;
  bg2: string;
  bg3: string;
  bg4: string;
  bg5: string;
  bg6: string;
  alert: string;
}

export interface VerificationStatus {
  pending: string;
  confirmed: string;
  inReview: string;
  flagged: string;
  manuallyConfirmed: string;
  confirmedByFlow: string;
}

export const palette = {
  primary: '#004EFF',
  error: '#F44336',
  warn: '#FF9800',
  default: '#000000dd',
  success: '#4CAF50',
  background: '#FAFAFA',
  info: '#2196F3',
  accentBackground: '#FFFFFF',
  disabled: '#BDBDBD',
};

export const OpertoTheme = {
  smartCards: {
    bg1: '#E8F0FE',
    bg2: '#3F51B5',
    bg3: '#008480',
    bg4: '#246FDB',
    bg5: '#38C5AB',
    bg6: '#0E33CC',
    alert: '#B81365',
  },
  activeTextColor: '#000000de',
  disabled: palette.disabled,
  sideBarTextColor: '#5F6368',
  sideBarColor: '#FFFFFF',
  textColorWhite: '#FFFFFF',
  textColor: palette.default,
  textColorLight: '#A2AAAD',
  containerAccentBGColor: palette.accentBackground,
  containerBGColor: palette.background,
  containerDarkBGColor: '#f0f5f8',
  borderColor: '#E6EAF2',
  buttonPrimaryColor: palette.primary,
  tabColorActive: palette.primary,
  tabColorInactive: 'rgba(0,0,0,0.4)',
  tabColor: palette.background,
  tabBGColor: '#0040CC',
  subHeaderBGColor: '#E6E9F1',
  subHeaderAltBGColor: '#E0E4EF',
  topBarColor: '#FFFFFF',
  palette,
  graphFillColor: '#E9F0FD',
  memberIconColor: '#FD612C',
  campaignIconColor: '#B81365',
  guestPortalIconColor: '#742DDD',
  messengerIconColor: '#259C72',
  radialBarBaseColor: '#EAEEF0',
  radialBarFirstBarColor: palette.primary,
  radialBarSecondBarColor: `${palette.primary}`,
  rowSelectedColor: 'rgba(21,94,255, 0.4)',
  icons: {
    messengerIcon: 'img/icons/CONNECTICONS-GUEST-ICON-MESSENGER_1.svg',
    campaignsIcon: 'img/icons/CONNECTICONS-GUEST-ICON-CAMPAIGNS_1.svg',
  },
  rightSideDrawerIcon: palette.primary,
  battery: {
    disabled: palette.disabled,
    good: 'rgba(0,0,0,0.6)',
    medium: 'rgba(0,0,0,0.6)',
    low: palette.warn,
    critical: palette.error,
    empty: palette.error,
  },
  secondaryButton: '#E0E0E0',
  hoverButton: '#d5d5d5',
  verificationStatus: {
    pending: '#FFCA28',
    confirmed: palette.success,
    inReview: '#A96FFF',
    flagged: '#E65100',
    manuallyConfirmed: '#536DFE',
    confirmedByFlow: '#29B6F6',
  },
  sideBarColors: {
    primary: '#155EFF',
    secondary: '#757575',
    hover: '#155eff14',
  },
  eventColors: {
    lightBlue: '#EBF4FB',
    lightGreen: '#EBF8F3',
    lightPurple: '#F4F2FF',
    lightRed: '#FFEFEF',
    lightYellow: '#FEFAF1',
    blue: '#0074C8',
    green: '#219464',
    purple: '#7B61FF',
    red: '#FF3737',
    yellow: '#EDC14B',
    grey: '#EFF0F6',
    darkYellow: '#FFF4BA',
  },
};
