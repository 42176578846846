import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Collapse, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { ColorPicker } from 'Common/ColorPicker';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TransitionGroup } from 'react-transition-group';
import { ActionButton } from 'redux/guestportal/models';
import {
  FormTextField,
  FormTextFieldRegexEmail,
  FormTextFieldRegexPhone,
  FormTextFieldRegexURL,
} from 'ui-library/Components/input/FormTextField';

export type QRCodeFormHandle = {
  submit: () => void;
};

type ActionButtonTemplate = ActionButton & {
  isValid?: boolean;
};

const createButtonTemplates = (buttons: ActionButtonTemplate[]) => {
  return buttons.map(button => ({
    ...button,
    isValid: false,
  }));
};

type QRCodeButtonFormProps = {
  button: ActionButtonTemplate;
  onDelete: (button: ActionButtonTemplate) => void;
  onChange: (button: ActionButtonTemplate) => void;
};

const QRCodeButtonForm = React.forwardRef<QRCodeFormHandle, QRCodeButtonFormProps>(
  function QRCodeButtonForm({ button, onDelete, onChange }, ref) {
    const {
      handleSubmit,
      watch,
      setValue,
      reset,
      control,
      clearErrors,
      formState: { isValid },
    } = useForm<ActionButtonTemplate>({
      mode: 'all',
      defaultValues: button,
    });
    const [title, action, color] = watch(['title', 'action', 'color']);

    const getTextFieldLabel = () => {
      if (action === 'tel') {
        return 'Phone number';
      }

      if (action === 'email') {
        return 'Email address';
      }

      return 'Website address';
    };

    const getTextFieldRules = () => {
      if (action === 'tel') {
        return {
          value: FormTextFieldRegexPhone,
          message: 'Valid phone number format is required',
        };
      }

      if (action === 'email') {
        return {
          value: FormTextFieldRegexEmail,
          message: 'Valid email address format is required',
        };
      }

      return {
        value: FormTextFieldRegexURL,
        message: 'Valid web URL is required.',
      };
    };

    useImperativeHandle(
      ref,
      () => ({
        submit: handleSubmit(onChange),
      }),
      [handleSubmit, onChange],
    );

    useEffect(() => {
      reset(button);
    }, [button, reset]);

    useEffect(() => {
      setValue('isValid', isValid);
    }, [isValid, setValue]);

    useEffect(() => {
      const subscription = watch(values => onChange(values as ActionButton));
      return () => subscription.unsubscribe();
    }, [onChange, watch]);

    return (
      <>
        <Box>
          <Button variant='contained' sx={{ mr: 1, backgroundColor: color }}>
            {title?.length ? title : 'Button'}
          </Button>
          <IconButton aria-label='delete' onClick={() => onDelete(button)}>
            <DeleteIcon />
          </IconButton>
        </Box>
        <Stack spacing={2} p={2} my={2} border={1} borderRadius='10px' borderColor='divider'>
          <Typography variant='subtitle2'>Button display</Typography>
          <Stack direction='row' spacing={2} alignItems='center'>
            <FormTextField
              field='title'
              label='Display text'
              fullWidth={false}
              sx={{ my: 0 }}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Display text is required',
                },
              }}
            />
            <ColorPicker value={color} onChange={newValue => setValue('color', newValue)} />
          </Stack>
          <Typography variant='subtitle2'>Button link</Typography>
          <Stack direction='row' spacing={2}>
            <FormTextField
              select
              field='action'
              label='Select'
              fullWidth={false}
              sx={{ my: 0, width: 200 }}
              control={control}
              onChange={event => {
                setValue('action', event.target.value as never);
                setValue('value', '');
                clearErrors('value');
              }}
            >
              <MenuItem value='tel'>Phone</MenuItem>
              <MenuItem value='email'>Email</MenuItem>
              <MenuItem value='url'>URL/Link</MenuItem>
            </FormTextField>
            <FormTextField
              field='value'
              type={action}
              label={getTextFieldLabel()}
              fullWidth
              sx={{ my: 0 }}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: `${getTextFieldLabel()} is required`,
                },
                pattern: getTextFieldRules(),
              }}
            />
          </Stack>
        </Stack>
      </>
    );
  },
);

let submitTimeout: NodeJS.Timeout;
type QRCodeAddButtonFormProps = {
  buttons: ActionButton[];
  onSubmit: (buttons: ActionButton[]) => void;
};

export const QRCodeAddButtonForm = React.forwardRef<QRCodeFormHandle, QRCodeAddButtonFormProps>(
  function QRCodeAddButtonForm({ buttons = [], onSubmit }, ref) {
    const [actionButtons, setActionButtons] = useState<ActionButtonTemplate[]>(
      createButtonTemplates(buttons),
    );
    const [submitting, setSubmitting] = useState(false);
    const buttonRefs = useRef<QRCodeFormHandle[]>([]);

    useImperativeHandle(
      ref,
      () => ({
        submit: () => {
          // NOTE: a little tricky to coordinate the validity of dynamically created button.
          // 1. we call submit on all the buttons, let RHF do its validation
          // 2. validity of each will result to replacing the template button on the 'onChange' callback
          // 3. useEffect side effect will check if there are no invalid buttons
          // 4. pass the buttons to the parent controller
          buttonRefs.current.map(r => r && r.submit());

          clearTimeout(submitTimeout);
          submitTimeout = setTimeout(() => setSubmitting(true), 100);
        },
      }),
      [],
    );

    useEffect(() => {
      if (!submitting) {
        return;
      }

      setSubmitting(false);
      if (actionButtons.filter(b => !b.isValid).length > 0) {
        return;
      }

      onSubmit(actionButtons);
    }, [submitting, actionButtons, onSubmit]);

    return (
      <>
        <TransitionGroup>
          {actionButtons.map((button, index) => (
            <Collapse key={index}>
              <QRCodeButtonForm
                button={button}
                ref={ref => {
                  buttonRefs.current[index] = ref;
                }}
                onDelete={() => {
                  buttonRefs.current.splice(index, 1);
                  setActionButtons(prev => prev.filter((_b, i) => index !== i));
                }}
                onChange={newValue => {
                  setActionButtons(prev => prev.map((b, i) => (index === i ? newValue : b)));
                }}
              />
            </Collapse>
          ))}
        </TransitionGroup>
        <Box>
          <Button
            variant='text'
            startIcon={<AddIcon />}
            disabled={actionButtons.length >= 3}
            onClick={() =>
              setActionButtons([
                ...actionButtons,
                {
                  title: '',
                  action: 'tel',
                  value: '',
                  color: 'primary',
                },
              ])
            }
          >
            ADD BUTTON
          </Button>
        </Box>
      </>
    );
  },
);
