import { CustomizedJwtPayload, parseUserClaims } from 'auth/auth';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { Dispatch } from 'redux';
import { Actions } from '../../types/actions';

export const storeToken = (auth: string) => (dispatch: Dispatch) => {
  axios.defaults.headers.common.Authorization = `Bearer ${auth}`;
  localStorage.setItem('mpAuth', JSON.stringify(auth));
  const decode: CustomizedJwtPayload = getCustomizedJwtPayload(auth);
  dispatch({
    type: Actions.storeToken,
    token: auth,
    memberId: decode.mid,
    companyId: decode.cid,
  });
};

export const logout = (params?: string) => (dispatch: Dispatch) => {
  dispatch({
    type: Actions.logout,
  });

  localStorage.removeItem('mpAuth');

  if (params) {
    window.location.href = `/login${params}`;
  } else {
    window.location.href = `/login`;
  }
};

export const getCustomizedJwtPayload = (auth: string): CustomizedJwtPayload => {
  return parseUserClaims(jwtDecode(auth));
};
