import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useMedia } from '@operto/ui';
import debounce from 'lodash/debounce';
import React, { useMemo, useRef, useState } from 'react';

export type SearchTextFieldProps = Omit<TextFieldProps, 'onChange'> & {
  onChange: (value: string) => void;
};

export const SearchTextField = ({
  onChange,
  size = 'small',
  label = 'Search',
  value = '',
  InputProps,
  ...rest
}: SearchTextFieldProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const [searchValue, setSearchValue] = useState<string>(value as string);
  const [isFocus, setIsFocus] = useState(false);
  const isSmall = size === 'small';
  const { isMobile } = useMedia();

  const debounceSearch = useMemo(() => debounce(text => onChange(text), 600), [onChange]);

  const handleSearch = (text: string) => {
    setSearchValue(text);
    debounceSearch(text);
  };

  const renderEndAdornment = () => {
    if (isFocus || searchValue.length > 0) {
      return (
        <ClearIcon
          onClick={() => {
            handleSearch('');
            inputRef.current?.blur();
          }}
        />
      );
    }

    return null;
  };

  /**
   * NOTE: there is an issue when using start adorment. this is a workaround suggested from
   * https://github.com/mui/material-ui/issues/13898
   */
  return (
    <TextField
      inputRef={inputRef}
      variant='outlined'
      label={label}
      value={searchValue}
      size={size}
      sx={{
        width: isMobile ? '100%' : '320px',
        minWidth: '150px',
        mx: 0,
        '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
          transform: `translate(40px, ${isSmall ? '0.5' : '1'}rem)`,
        },
      }}
      onChange={e => handleSearch(e.target.value)}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      InputLabelProps={{ shrink: searchValue.length > 0 }}
      InputProps={{
        sx: { borderRadius: isSmall ? '24px' : '32px', backgroundColor: 'background.paper' },
        startAdornment: <SearchIcon onClick={() => inputRef.current?.focus()} />,
        endAdornment: renderEndAdornment(),
        ...InputProps,
      }}
      inputProps={{ autoComplete: 'off' }}
      {...rest}
    />
  );
};
