import { NotifiableError } from '@bugsnag/js';
import * as codeApi from 'api/accessCodeAPI';
import * as api from 'api/propertyAPI';
import { AxiosResponse } from 'axios';
import { CodeDict, ICode } from 'code/codeType';
import { OpertoLogger } from 'Logger/logger';
import { ApplicationState } from 'redux/reducers';
import { AppDispatch } from 'redux/store';
import { Actions } from 'types/actions';

export const getPropertyAccessCodes =
  (propertyId: number, type?: string) => (dispatch: AppDispatch) => {
    return api
      .getPropertyAccessCodes(propertyId, type)
      .then((data: AxiosResponse) => {
        const records = data.data.data;
        const codeList: number[] = [];
        const codeDict: CodeDict = {};
        records.forEach((each: ICode) => {
          codeList.push(each.id);
          codeDict[each.id] = each;
        });
        dispatch({
          type: Actions.hydrateCodes,
          codeList,
          codeDict,
        });
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  };

export const getPropertyAccessCode =
  (propertyId: number, id: number) => (dispatch: AppDispatch) => {
    return api
      .getPropertyAccessCode(propertyId, id)
      .then((data: AxiosResponse) => {
        const code = data.data.data;
        dispatch({
          type: Actions.upsertCode,
          code,
        });
      })
      .catch((err: NotifiableError) => {
        dispatch({
          type: Actions.deleteCode,
          id,
        });
        OpertoLogger.Log(err);
      });
  };

export const deleteBackupCode = (codeId: number, propertyId: number) => (dispatch: AppDispatch) => {
  return api.patchBackupCode(propertyId, codeId).then((data: AxiosResponse) => {
    const code = data.data.data;
    dispatch({
      type: Actions.upsertCode,
      code,
    });
  });
};

export const updateCodeAccessTime = (updated: ICode) => async (dispatch: AppDispatch) => {
  const result = await codeApi.updateReservationAccessTime(
    String(updated.id),
    updated.valid_from,
    updated.valid_until,
  );
  const code = result.data.data;
  dispatch({
    type: Actions.upsertCode,
    code,
  });
  dispatch(getPropertyAccessCodes(updated.property_id, null));
};

export const getCodes = () => (dispatch: AppDispatch) => {
  codeApi
    .getCodes()
    .then((data: AxiosResponse) => {
      const codes = data.data.data;
      const codeList: number[] = [];
      const codeDict: CodeDict = {};
      codes.forEach((each: ICode) => {
        codeList.push(each.id);
        codeDict[each.id] = each;
      });
      dispatch({
        type: Actions.hydrateCodes,
        codeList,
        codeDict,
      });
    })
    .catch((err: NotifiableError) => {
      OpertoLogger.Log(err);
    });
};

export const makeAvailable =
  (codeId: number, start: string, end: string) => (dispatch: AppDispatch) => {
    codeApi
      .updateReservationAccessTime(String(codeId), start, end)
      .then(() => {
        dispatch(getCodes());
      })
      .catch(err => {
        OpertoLogger.Log(err);
      });
  };

export const showMakeAvailableBanner = (dismiss: boolean) => (dispatch: AppDispatch) => {
  if (dismiss) {
    dispatch({
      type: Actions.hideMakeAvailableBanner,
    });
  }
};

export const removeMakeAvailableId =
  (id: number) => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const { ui } = getState();
    const codes: number[] = [];
    const dismissedCodes: number[] = [];
    const existingCodes: number[] = ui.makeAvailable.ids;
    existingCodes.forEach((each: number) => {
      codes[each] = each;
    });
    if (existingCodes[id]) {
      const index = existingCodes.indexOf(id, 0);
      if (index > -1) {
        codes.splice(index, 1);
      }
    }
    const dismissedExistingCodes: number[] = ui.makeAvailable.dismissedIds;
    dismissedExistingCodes.forEach((each: number) => {
      dismissedCodes[each] = each;
    });
    dismissedCodes[id] = id;
    dispatch({
      type: Actions.removeMakeAvailableId,
      dismissedIds: dismissedCodes,
      ids: codes,
    });
  };
