import axios from 'axios';

const appApi = process.env.REACT_APP_API_URL;
const appApiVersion = 'v2';

export const getLinkedProperties = (propertyId: number) =>
  axios.get(`${appApi}/${appApiVersion}/properties/${propertyId}/common-properties`, {});

export const linkProperties = (
  propertyId: number,
  commonPropertyId: number,
  guestDaysAhead = 21,
  guestEnabled = true,
  checkoutEnabled = true,
  memberEnabled = true,
  backupEnabled = true,
) =>
  axios.put(
    `${appApi}/${appApiVersion}/properties/${propertyId}/common-properties/${commonPropertyId}`,
    {
      guestDaysAhead,
      guestEnabled,
      checkoutEnabled,
      memberEnabled,
      backupEnabled,
    },
  );

export const unlinkProperties = (propertyId: number, commonPropertyId: number) =>
  axios.delete(
    `${appApi}/${appApiVersion}/properties/${propertyId}/common-properties/${commonPropertyId}`,
  );
