import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { IntegrationDict, IntegrationState } from 'integrations/integrationsTypes';
import { Actions } from '../../types/actions';
import uniq from 'lodash/uniq';

const list: Reducer<string[]> = (state = [], action) => {
  switch (action.type) {
    case Actions.hydrateIntegrations:
      return action.integrationsList;
    case Actions.upsertIntegrations:
      return uniq([...state, action.integration.id]);
    default:
      return state;
  }
};

const byId: Reducer<IntegrationDict> = (state = {}, action) => {
  switch (action.type) {
    case Actions.hydrateIntegrations:
      return action.integrationsById;
    case Actions.upsertIntegrations:
      return {
        ...state,
        [action.integration.id]: action.integration,
      };
    default:
      return state;
  }
};

const reducer: Reducer<IntegrationState> = combineReducers<IntegrationState>({
  list,
  byId,
});

export default reducer;
