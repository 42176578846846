import {
  Box,
  Button,
  ButtonProps,
  MenuItem,
  Paper,
  SxProps,
  Toolbar,
  Typography,
  TypographyProps,
} from '@mui/material';
import { paletteStyles } from '@operto/ui';
import React, { CSSProperties } from 'react';

interface SideBarItemProps {
  open: boolean;
  active: boolean;
  activeBg: string;
  children: React.ReactNode;
}

interface SideBarToolbarProps {
  children: React.ReactNode;
}

interface SideBarActionToolbarProps {
  children: React.ReactNode;
}

interface SideBarTitleProps {
  children: React.ReactNode;
}

interface HeaderContainerProps {
  children: React.ReactNode;
  listView?: boolean;
}

type FormHeaderProps = { children: React.ReactNode } & TypographyProps;

interface SideBarContentProps {
  children: React.ReactNode;
}

interface ContentContainerProps {
  children: React.ReactNode;
  customContainerStyles?: CSSProperties;
}

interface ActionButtonsContainerProps {
  children: React.ReactNode;
}

type ActionButtonProps = {
  previousButton?: boolean;
  testId?: string;
  children: React.ReactNode;
} & ButtonProps;

interface MainContentContainerProps {
  children: React.ReactNode;
}

const { palette } = paletteStyles;

export const SideBarItem = ({ open, activeBg, children }: SideBarItemProps) => {
  const sideBarItemStyles: SxProps = {
    '&&': {
      borderRadius: '25px',
      marginLeft: open ? '13px' : 0,
      marginRight: open ? '13px' : 0,
      justifyContent: open ? 'flex-start' : 'center',
      backgroundColor: open && activeBg,
    },
  };

  return <MenuItem sx={sideBarItemStyles}>{children}</MenuItem>;
};

const sideBarToolbarStyles: SxProps = {
  '&&': {
    display: 'inline-block',
    backgroundColor: palette.accentBackground,
    padding: '20px 30px 0px 30px',
  },
};
export const SideBarToolbar = ({ children }: SideBarToolbarProps) => {
  return <Toolbar sx={sideBarToolbarStyles}>{children}</Toolbar>;
};

const sideBarActionToolbarStyles: SxProps = {
  '&&': {
    display: 'inline-block',
    backgroundColor: palette.background.default,
    padding: '20px 30px',
    borderRadius: 0,
  },
};
export const SideBarActionToolbar = ({ children }: SideBarActionToolbarProps) => {
  return <Paper sx={sideBarActionToolbarStyles}>{children}</Paper>;
};

const sideBarTitleStyles: SxProps = {
  '&&': {
    marginLeft: '30px',
  },
};
export const SideBarTitle = ({ children }: SideBarTitleProps) => {
  return <Typography sx={sideBarTitleStyles}>{children}</Typography>;
};

export const HeaderContainer = ({ listView, children }: HeaderContainerProps) => {
  const headerContainerStyles: SxProps = {
    '&&': {
      backgroundColor: 'white',
      width: '100%',
      paddingBottom: listView && '14px',
      position: 'fixed',
      top: 0,
      zIndex: 10,
    },
  };

  return <Box sx={headerContainerStyles}>{children}</Box>;
};

const formHeaderStyles: SxProps = {
  '&&': {
    marginBottom: '8px',
  },
};
export const FormHeader = ({ children, variant, align }: FormHeaderProps) => {
  return (
    <Typography variant={variant} align={align} sx={formHeaderStyles}>
      {children}
    </Typography>
  );
};

const sideBarContentStyles: SxProps = {
  padding: '136px 24px 85px 24px',
};
export const SideBarContent = ({ children }: SideBarContentProps) => {
  return <Box sx={sideBarContentStyles}>{children}</Box>;
};

export const ContentContainer = ({ children, customContainerStyles }: ContentContainerProps) => {
  const contentContainerStyles: SxProps = {
    padding: '2rem',
    ...customContainerStyles,
  };
  return <Box sx={contentContainerStyles}>{children}</Box>;
};

const actionsButtonContainerStyles: SxProps = {
  paddingTop: '12px',
  paddingBottom: '12px',
  paddingLeft: '26px',
  borderTop: '0.5px solid #d4d4d4',
  backgroundColor: palette.background.default01,
  position: 'fixed',
  bottom: 0,
  zIndex: 10,
  width: '100%',
};
export const ActionButtonsContainer = ({ children }: ActionButtonsContainerProps) => {
  return <Box sx={actionsButtonContainerStyles}>{children}</Box>;
};

// need to clarify if there is an equivalent pallete bg color for this
const previousActionButtonBackgroundColor = 'rgb(224, 224, 224)';
const previousActionButtonBackgroundColorOnHover = 'rgb(213, 213, 213)';
export const ActionButton = ({
  previousButton,
  children,
  variant,
  title,
  startIcon,
  onClick,
  testId,
  ...props
}: ActionButtonProps) => {
  const actionButtonStyles: SxProps = {
    marginRight: '8px',
    backgroundColor: previousButton && previousActionButtonBackgroundColor,
    color: previousButton && palette.text.primary,
    '&:hover': {
      backgroundColor: previousButton && previousActionButtonBackgroundColorOnHover,
    },
  };

  return (
    <Button
      {...props}
      data-testid={testId}
      sx={actionButtonStyles}
      startIcon={startIcon}
      variant={variant}
      title={title}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

const mainContentContainerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'scroll',
};
export const MainContentContainer = ({ children }: MainContentContainerProps) => {
  return <Box sx={mainContentContainerStyles}>{children}</Box>;
};
