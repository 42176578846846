// @ts-nocheck
import { Box, Collapse, Grid, Switch, TextField, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SettingCard } from '@operto/ui-library';
import ImageEditor from 'Pages/GuestPortal/Common/GPImageEditor';
import addDays from 'date-fns/addDays';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import { UNCATEGORIZED_ID } from 'helper/helper';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import styled from 'styled-components';
import ScriptDropDownMenu from 'ui-library/Components/drop-down/ScriptDropDownMenu';
import { TextInsert } from 'ui-library/Helpers/textHelper';
import { GuidesDetailsCategoriesFormProps } from './GuidesDetailsCategoriesForm';

const AssignToCategoryCard: React.FC<GuidesDetailsCategoriesFormProps> = props => {
  const { category, onCategoryChange } = props;
  const [currentCursorPos, setCurrentCursorPos] = useState(0);
  const categoryInputRef = useRef();
  const [categoryName, setCategoryName] = useState<string>(category?.category ?? '');
  const [errors, setErrors] = React.useState([]);
  const handleOnBlur = (e: React.ChangeEvent) => {
    const field = e.target.id;
    validator.showMessageFor(field);
    setHelperErrorText(validator?.errorMessages?.category_name_input);
    const tempErrorObj: Record<string, unknown> = {};
    tempErrorObj[field] = !validator.fieldValid(field);
    setErrors(tempErrorObj);
  };
  // TODO: UNCATEGORIZED
  const validator = new SimpleReactValidator({
    validators: {
      no_uncategory: {
        message: ` ${`The word "uncategorized" is a reserved word. Please use other names that doesn't contain any reserved words.`}`,
        rule: val => {
          return !val.toLowerCase().includes('uncategorized');
        },
        required: true,
      },
    },
  });
  const [helperErrorText, setHelperErrorText] = useState('');
  return (
    <SettingCard
      title='Category Title'
      subtitle="Select this category's name as it appears in your menu"
    >
      <TextContainer>
        <TextField
          id='category_name_input'
          inputRef={categoryInputRef}
          style={{ width: '70%', marginTop: 30, marginBottom: 30, paddingRight: '10px' }}
          label='Category Title'
          placeholder="Enter this category's title"
          fullWidth
          onBlur={e => {
            setCurrentCursorPos(categoryInputRef?.current?.selectionEnd);
            handleOnBlur(e);
          }}
          inputProps={{ style: { textAlign: 'start' } }}
          variant='outlined'
          value={categoryName}
          error={errors.category_name_input}
          helperText={errors.category_name_input ? helperErrorText : ''}
          onChange={e => {
            setCategoryName(e.target.value);
            onCategoryChange?.({ ...category, category: e.target.value });
          }}
        />
        <Typography>
          {validator.message('category_name_input', categoryName, 'required|no_uncategory')}
        </Typography>
        <ScriptDropDownMenu
          top={7}
          onTextInsert={(text: string) => {
            onCategoryChange?.({
              ...category,
              category: TextInsert(category.category, text, currentCursorPos),
            });
          }}
        />
      </TextContainer>
    </SettingCard>
  );
};

const CategoryStatusCard: React.FC<GuidesDetailsCategoriesFormProps> = props => {
  const { category, onCategoryChange } = props;
  const { propertyId } = useParams();

  // TODO: UNCATEGORIZED
  const isUncategorized = category?.category_id === UNCATEGORIZED_ID;

  return (
    <SettingCard
      title='Category Status'
      subtitle="Set this category's Published or Scheduled status"
    >
      <Grid container style={{ marginTop: 20, alignItems: 'center' }}>
        <Grid item>
          <Switch
            onChange={(e, value) => onCategoryChange?.({ ...category, published: value })}
            checked={category?.published}
            disabled={isUncategorized}
          />
        </Grid>
        <Grid item>
          <Typography>Publish Category</Typography>
        </Grid>
      </Grid>
      {!propertyId && (
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item>
            <Switch
              onChange={(e, value) => onCategoryChange?.({ ...category, featured: value })}
              checked={category?.featured}
              disabled={isUncategorized}
            />
          </Grid>
          <Grid item>
            <Typography>Featured on Home Page</Typography>
          </Grid>
        </Grid>
      )}
      <Grid container style={{ marginBottom: 20, alignItems: 'center' }}>
        <Grid item>
          <Switch
            onChange={(e, value) => onCategoryChange?.({ ...category, scheduled: value })}
            checked={category?.scheduled}
            disabled={isUncategorized}
          />
        </Grid>
        <Grid item>
          <Typography>Schedule</Typography>
        </Grid>
      </Grid>
      <Typography style={{ display: isUncategorized ? 'inline' : 'none' }}>
        ** You cannot unpublish, delete, feature, or schedule `uncategorized` as it is a fake
        category to keep standalone pages.
      </Typography>
    </SettingCard>
  );
};

const ScheduleCategoryCard: React.FC<GuidesDetailsCategoriesFormProps> = props => {
  const { category, onCategoryChange } = props;
  const scheduleStart = category?.scheduled_start
    ? fromUnixTime(category.scheduled_start)
    : new Date();
  // if the server set 2 identical time stamps that means this hasn't been set
  const scheduleEnd =
    category?.scheduled_end !== category?.scheduled_start
      ? fromUnixTime(category.scheduled_end)
      : addDays(new Date(), 2);
  return (
    <SettingCard
      title='Schedule This Category'
      subtitle='Select the time period that this category will be published in.'
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container style={{ paddingTop: '20px' }}>
          <DatePicker
            disableToolbar
            disabled={!category?.scheduled}
            variant='inline'
            format='MM/dd/yyyy'
            margin='normal'
            label='Start Date'
            value={scheduleStart}
            onChange={date =>
              onCategoryChange?.({
                ...category,
                scheduled_start: getUnixTime(date),
              })
            }
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            renderInput={params => <TextField {...params} />}
          />
          <Box marginX={3} />
          <DatePicker
            disableToolbar
            disabled={!category?.scheduled}
            variant='inline'
            format='MM/dd/yyyy'
            margin='normal'
            label='End Date'
            value={scheduleEnd}
            onChange={date =>
              onCategoryChange({
                ...category,
                scheduled_end: getUnixTime(date),
              })
            }
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            renderInput={params => <TextField {...params} />}
          />
        </Grid>
      </LocalizationProvider>
    </SettingCard>
  );
};

const CategoryCoverImageCard: React.FC<GuidesDetailsCategoriesFormProps> = props => {
  const { category, onCategoryChange } = props;

  if (category === undefined) {
    return null;
  }

  return (
    <SettingCard
      title='Category Cover Image'
      subtitle="Set this Category’s cover image that appears next to your Category's Chapters. If a category image is not uploaded, a picture from Pages will automatically be selected. "
    >
      <ImageContainer>
        <ImageEditor
          image={category?.category_cover ?? ''}
          onImageChange={(url: string) => onCategoryChange?.({ ...category, category_cover: url })}
        />
      </ImageContainer>
    </SettingCard>
  );
};

type GuidesDetailsCategoriesFormTabSettingsProps = GuidesDetailsCategoriesFormProps & {
  onCategoryChange;
};
export const GuidesDetailsCategoriesFormTabSettings: React.FC<
  GuidesDetailsCategoriesFormTabSettingsProps
> = props => {
  const { category, onCategoryChange } = props;

  return (
    <Box>
      <CategoryStatusCard category={category} onCategoryChange={onCategoryChange} />
      <Collapse in={category.scheduled}>
        <ScheduleCategoryCard category={category} onCategoryChange={onCategoryChange} />
      </Collapse>
    </Box>
  );
};

type GuidesDetailsCategoriesFormTabEditProps = GuidesDetailsCategoriesFormProps & {
  onCategoryChange;
};
export const GuidesDetailsCategoriesFormTabEdit: React.FC<
  GuidesDetailsCategoriesFormTabEditProps
> = props => {
  const { category, onCategoryChange } = props;

  return (
    <Box>
      <AssignToCategoryCard category={category} onCategoryChange={onCategoryChange} />
      <CategoryCoverImageCard category={category} onCategoryChange={onCategoryChange} />
    </Box>
  );
};
const TextContainer = styled.div`
  display: flex;
  align-items: baseline;
  width: 300px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
`;
