import * as React from 'react';

import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { BaseContainer } from 'Style/Components/BaseComponents';
import { MouseEvent, useEffect, useState } from 'react';
import { ICategory, IPage, useGuestPortal } from 'redux/guestportal';
import styled from 'styled-components';

export interface ActionSectionProps {
  onEditPressed: (e: React.MouseEvent, selectedPage: IPage, selectedCategory: ICategory) => void;
  page: IPage;
  category: ICategory;
}

const ActionSection = ({ page, category, onEditPressed }: ActionSectionProps) => {
  const { saveCategory, savePage } = useGuestPortal();
  const [categoryCopy, setCategoryCopy] = useState<ICategory>(category);
  const [pageCopy, setPageCopy] = useState<IPage>(page);

  useEffect(() => {
    setCategoryCopy(category);
  }, [category]);

  useEffect(() => {
    setPageCopy(page);
  }, [page]);

  const onFeaturedPressed = (e: MouseEvent) => {
    if (pageCopy) {
      const changedPage = { ...pageCopy, featured: !pageCopy.featured };
      savePage(changedPage, pageCopy.section_id);
    }
    if (categoryCopy) {
      const changedCategory = { ...categoryCopy, featured: !categoryCopy.featured };
      saveCategory(changedCategory);
    }
    e.stopPropagation();
  };
  const onPublishedPressed = (e: MouseEvent) => {
    if (pageCopy) {
      const changedPage = { ...pageCopy, published: !pageCopy.published };
      savePage(changedPage, pageCopy.section_id);
    }
    if (categoryCopy) {
      const changedCategory = { ...categoryCopy, published: !categoryCopy.published };
      saveCategory(changedCategory);
    }
    e.stopPropagation();
  };

  return (
    <MainContainer>
      <Tooltip
        title={categoryCopy?.published || pageCopy?.published ? 'Published' : 'Not Published'}
        placement='top'
      >
        <ActiveIconButton
          size='small'
          onClick={onPublishedPressed}
          isActive={page?.published || category?.published}
        >
          <DoneIcon />
        </ActiveIconButton>
      </Tooltip>
      <Tooltip
        title={categoryCopy?.featured || pageCopy?.featured ? 'Featured' : 'Not Featured'}
        placement='top'
      >
        <ActiveIconButton
          size='small'
          onClick={onFeaturedPressed}
          isActive={pageCopy?.featured || categoryCopy?.featured}
        >
          <StarIcon />
        </ActiveIconButton>
      </Tooltip>
      <ActiveIconButton
        isActive
        size='small'
        onClick={e => {
          onEditPressed(e, pageCopy, categoryCopy);
        }}
      >
        <EditIcon />
      </ActiveIconButton>
    </MainContainer>
  );
};

const ActiveIconButton = styled(IconButton)<{ isActive: boolean }>`
  && {
    color: ${props => props.isActive && props.theme.palette.primary};
  }
`;

const MainContainer = styled(BaseContainer)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: ${props => props.theme.palette.primary};
`;

export default ActionSection;
