/* eslint-disable  @typescript-eslint/no-explicit-any */

import { makeStyles } from '@mui/styles';

const drawerWidth = 256;

// @ts-nocheck not sure what theme is being passed in here
export const useStyles = (isDesktop = true) => {
  return makeStyles((theme: any) => ({
    root: {
      display: 'flex',
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      backgroundColor: theme.palette.accentBackground,
    },
    drawCloseIcon: {},
    guestIcon: {
      fill: theme.memberIconColor,
    },
    drawerOpen: {
      width: drawerWidth,
      backgroundColor: theme.palette.accentBackground,
      paddingTop: '62px',
      borderRadius: isDesktop ? '0' : '0 24px 0 0',
    },
    drawerClose: {
      backgroundColor: theme.palette.accentBackground,
      paddingTop: '62px',
      width: '64px',
      overflowX: 'hidden',
    },
    drawerCloseForMobile: {
      display: 'none',
    },
    iconColor: { color: theme.textColorWhite },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1),
      backgroundColor: theme.palette.primary.main,
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }))();
};
