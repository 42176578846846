import EmailIcon from '@mui/icons-material/Email';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MessageIcon from '@mui/icons-material/Message';
import PhoneIcon from '@mui/icons-material/Phone';
import { IconButton, ListItemIcon, Menu, MenuItem, SxProps, Typography } from '@mui/material';
import { getCompanyId, messengerSettingSelector } from 'company/state/companySelectors';
import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IReservation } from 'reservation/reservationType';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';

export interface ReservationContactDropdownProps {
  open: boolean;
  onClose: () => void;
  reservation: IReservation;
  anchorEl: Element;
}

const ReservationContactDropdown = ({
  open,
  onClose,
  anchorEl,
  reservation,
}: ReservationContactDropdownProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const companyId = useAppSelector(getCompanyId());

  // MEMBER SETTINGS
  const messengerSetting = useAppSelector(messengerSettingSelector());
  const guestMessengerEnabled = messengerSetting.mp_guest_messenger_enabled;

  const handleCopy = (name: string) => {
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `Copied ${name} value.`,
        variant: SnackbarVariant.INFO,
      }),
    );
    onClose();
  };

  const messageItemStyles: SxProps = {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    height: '60px',
    '&:hover': {
      '.MuiIconButton-root': {
        opacity: 1,
      },
    },
    '.MuiIconButton-root': {
      opacity: 0,
    },
  };

  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      {guestMessengerEnabled && (
        <MenuItem
          onClick={() => {
            navigate(`/messenger/${companyId}-${reservation?.id}`);
          }}
          sx={messageItemStyles}
        >
          <ListItemIcon>
            <MessageIcon />
          </ListItemIcon>
          <Typography>Message {reservation?.guest_name || 'guest'}</Typography>
        </MenuItem>
      )}

      {reservation?.guest_phone && (
        <MenuItem sx={messageItemStyles}>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <Typography>{reservation?.guest_phone}</Typography>
          <CopyToClipboard
            onCopy={() => {
              handleCopy('phone number');
            }}
            text={reservation?.guest_phone}
          >
            <IconButton size='small'>
              <FileCopyIcon data-testid='phone-copy-button' />
            </IconButton>
          </CopyToClipboard>
        </MenuItem>
      )}
      {reservation?.guest_email && (
        <MenuItem sx={messageItemStyles}>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <Typography>{reservation?.guest_email}</Typography>
          <CopyToClipboard
            onCopy={() => {
              handleCopy('email');
            }}
            text={reservation?.guest_email}
          >
            <IconButton size='small'>
              <FileCopyIcon data-testid='email-copy-button' />
            </IconButton>
          </CopyToClipboard>
        </MenuItem>
      )}
    </Menu>
  );
};

export default ReservationContactDropdown;
