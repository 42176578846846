import { SendgridMessage, TwilioMessage } from '@operto/notifications-shared';
import { trpc } from '@operto/trpc-client';

export default function useNotifications() {
  const sendEmailMutation = trpc?.notifications?.sendEmail?.useMutation();
  const sendEmail = async (email: SendgridMessage) => {
    return await new Promise((resolve, reject) => {
      sendEmailMutation?.mutate(email, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const sendSmsMutation = trpc?.notifications?.sendSms?.useMutation();
  const sendSms = async (sms: TwilioMessage) => {
    return await new Promise((resolve, reject) => {
      sendSmsMutation?.mutate(sms, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  return {
    sendEmail,
    sendSms,
  };
}
