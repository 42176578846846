import OccupancyIcon from '@mui/icons-material/PeopleAltOutlined';
import { Box, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { devicesByPropertyIdSelector } from 'device/state/deviceSelector';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { patchThreshold } from 'threshold/state/thresholdAction';
import { getThresholdsByDeviceId } from 'threshold/state/thresholdSelectors';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import Widget from 'ui-library/Components/widget/Widget';
import RiskDisplay, { RiskLevel } from 'ui-library/Components/widget/content/RiskDisplay';
import ShowMoreToggle from 'ui-library/Components/widget/content/ShowMoreToggle';
import ThresholdSetting, {
  ThresholdInputs,
} from 'ui-library/Components/widget/content/ThresholdSetting';
import DeviceTitleDropdown from './DeviceTitleDropdown';
import OccupancyFooter from './OccupancyFooter';

export interface OccupancyCardProps {
  hideFooter?: boolean;
  isOnUnitDashboard?: boolean;
}

export const HIGH_OCCUPANCY_THRESHOLD = 800;
export const MEDIUM_OCCUPANCY_THRESHOLD = 600;

const OccupancyCard = ({ hideFooter, isOnUnitDashboard = false }: OccupancyCardProps) => {
  const { propertyId: pidStr } = useParams();
  const propertyId = Number(pidStr);
  const dispatch = useAppDispatch();
  const devices = useAppSelector(devicesByPropertyIdSelector(propertyId));
  const occupancyDevices = devices.filter(device => device.data.co2);
  const [openDetails, setOpenDetails] = useState(false);
  const [high, setHigh] = useState(HIGH_OCCUPANCY_THRESHOLD);
  const [medium, setMedium] = useState(MEDIUM_OCCUPANCY_THRESHOLD);
  const [currentRisk, setCurrentRisk] = useState(RiskLevel.NONE);
  const [selectedDeviceId, setSelectedDeviceId] = useState(devices?.[0]?.data?.device_id || 0);
  const deviceThreshold = useAppSelector(getThresholdsByDeviceId(selectedDeviceId as number));
  // @ts-ignore
  const deviceData = occupancyDevices.find(d => d.data?.device_id === selectedDeviceId);

  useEffect(() => {
    if (deviceThreshold) {
      const { co2Med, co2High } = deviceThreshold;
      setMedium(co2Med?.value);
      setHigh(co2High?.value);
    }
  }, [deviceThreshold]);

  useEffect(() => {
    if (occupancyDevices?.length > 0) {
      setSelectedDeviceId(occupancyDevices?.[0].data?.device_id);
    }
  }, [occupancyDevices, occupancyDevices.length]);

  const calculateRisk = useCallback(() => {
    if (deviceData?.data?.co2 !== undefined) {
      const current = parseFloat(deviceData.data.co2 as string);
      if (current >= high) {
        setCurrentRisk(RiskLevel.HIGH);
      } else if (current > medium && current < high) {
        setCurrentRisk(RiskLevel.MEDIUM);
      } else {
        setCurrentRisk(RiskLevel.LOW);
      }
    } else {
      setCurrentRisk(RiskLevel.NONE);
    }
  }, [deviceData, high, medium]);

  useEffect(() => {
    calculateRisk();
  }, [medium, high, calculateRisk]);

  const toggleOpenDetails = () => {
    setOpenDetails(!openDetails);
  };
  const navigate = useNavigate();

  const viewMoreDetails = () => {
    navigate(`/units/${propertyId}/devices/occupancy`);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>, field: ThresholdInputs) => {
    if (field === ThresholdInputs.FirstThreshold) {
      setMedium(Number(e.target.value));
    } else {
      setHigh(Number(e.target.value));
    }
  };
  const handleDeviceSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDeviceId(e.target.value);
  };

  const handleSave = (field: ThresholdInputs) => {
    const { co2Med, co2High } = deviceThreshold;
    const value = field === ThresholdInputs.FirstThreshold ? medium : high;
    const id = field === ThresholdInputs.FirstThreshold ? co2Med.id : co2High.id;
    const thresholdType = field === ThresholdInputs.FirstThreshold ? 'Medium' : 'High';
    // @ts-ignore
    dispatch(patchThreshold(id, value)).then(() => {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: `${thresholdType} ppm threshold updated.`,
          variant: SnackbarVariant.SUCCESS,
        }),
      );
    });
  };

  return (
    <Widget
      title={
        <DeviceTitleDropdown
          devices={occupancyDevices}
          onChange={handleDeviceSelect}
          selectedDeviceId={selectedDeviceId as number}
          icon={<OccupancyIcon sx={{ color: 'white' }} />}
        />
      }
      isCarouselCard
      open={openDetails}
      isOnUnitDashboard={isOnUnitDashboard}
      smartCardType='device'
      footer={!hideFooter && <OccupancyFooter onDetailsClick={viewMoreDetails} />}
      fill='#246FDB'
    >
      <Box sx={{ paddingBottom: '0.875rem' }}>
        <ShowMoreToggle onClick={toggleOpenDetails} open={openDetails} fill='#246FDB' />
        <Box>
          <Typography sx={{ color: 'white' }} variant='h4'>
            {deviceData?.data?.co2 ? `${deviceData?.data.co2}ppm` : 'N/A'}
          </Typography>
          <RiskDisplay riskLevel={currentRisk} />
        </Box>
        {openDetails && (
          <Collapse in={openDetails}>
            <ThresholdSetting
              suffix='ppm'
              values={[medium, high]}
              onChange={handleValueChange}
              onSave={handleSave}
              labels={['Medium Threshold', 'High Threshold']}
            />
          </Collapse>
        )}
      </Box>
    </Widget>
  );
};

export default OccupancyCard;
