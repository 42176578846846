import { Box, Button, Stack, Typography } from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { useSecurityDeposit } from 'redux/security-deposit';
import EditSecurityDepositDialog from './EditSecurityDepositDialog ';
import PaymentsTable from './PaymentsTable';

function PaymentsPage() {
  const [selectionList, setSelectionList] = useState<GridSelectionModel>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { updateSecurityDeposit } = useSecurityDeposit();

  const onSave = async (price: string) => {
    const success = await updateSecurityDeposit(selectionList as number[], parseFloat(price));

    if (success) {
      setSelectionList([]);
    }
    return success;
  };

  return (
    <>
      <EditSecurityDepositDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        selectionList={selectionList}
        onSave={onSave}
      />
      <Stack m={3} spacing={1}>
        {/* wrapping button and typography with fixed height so it is not jumpy when toggled */}
        <Box height={40}>
          {selectionList.length > 0 ? (
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              edit security deposit
            </Button>
          ) : (
            <Typography>Select units to edit or setup &apos;Security Deposit&apos;</Typography>
          )}
        </Box>
        <PaymentsTable selectionModel={selectionList} onSelectionModelChange={setSelectionList} />
      </Stack>
    </>
  );
}

export default PaymentsPage;
