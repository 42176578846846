import { Avatar } from '@mui/material';
import { IOpertoTheme, OpertoTheme } from 'App/OpertoTheme';
import * as React from 'react';
import { generateRandomHSL } from 'ui-library/Helpers/colorHelper';

export interface ProfileIconProps {
  imageUrl?: string;
  fullName?: string;
  size?: 'small' | 'medium' | 'large' | 'exlarge';
  useRandomColor?: boolean;
}

const ProfileIcon = ({
  imageUrl,
  fullName = '',
  size = 'medium',
  useRandomColor = false,
}: ProfileIconProps) => {
  if (imageUrl) {
    return <ProfileAvatar size={size} imgSrc={imageUrl} />;
  }
  if (fullName) {
    if (fullName !== '') {
      let initials = '';
      // if not small profile icon just show initials or just show first letter
      if (size !== 'small') {
        initials = fullName
          .split(/\s/)
          .slice(0, 2)
          .reduce((response: string, word: string) => {
            let value = response;
            value += word.slice(0, 1);
            return value;
          }, '');
      } else {
        initials = fullName?.charAt(0);
      }

      const backgroundColor = useRandomColor ? generateRandomHSL(fullName) : undefined;

      return (
        <ProfileAvatar theme={OpertoTheme} size={size} backgroundColor={backgroundColor}>
          {initials.toUpperCase()}
        </ProfileAvatar>
      );
    }
  }

  const profileAvatar = 'img/icons/AVATAR_Operatar.svg';

  return <ProfileAvatar size={size} imgSrc={profileAvatar} />;
};

interface ProfileAvatarProps {
  size: 'small' | 'medium' | 'large' | 'exlarge';
  theme?: IOpertoTheme;
  children?: string;
  imgSrc?: string;
  backgroundColor?: string;
}

const ProfileAvatar = ({ size, theme, children, imgSrc, backgroundColor }: ProfileAvatarProps) => {
  const defaultStyling = {
    backgroundColor: backgroundColor ? backgroundColor : 'rgba(0, 0, 0, 0.6)',
    color: `${theme?.textColorWhite}`,
    fontSize: '1rem',
  };

  const getSizedStyling = () => {
    switch (size) {
      case 'small':
        return {
          width: '28px',
          height: '28px',
        };

      case 'medium':
        return {
          width: '36px',
          height: '36px',
        };

      case 'large':
        return {
          width: '40px',
          height: '40px',
        };

      case 'exlarge':
        return {
          width: '150px',
          height: '150px',
        };

      default:
        return {};
    }
  };

  return (
    <Avatar
      src={imgSrc}
      sx={{
        ...defaultStyling,
        ...getSizedStyling(),
      }}
    >
      {children}
    </Avatar>
  );
};

export default ProfileIcon;
