import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { convertToBase64 } from 'helper/helper';
import React from 'react';
import { useDropzone } from 'react-dropzone';

export interface ImageEditorProps {
  item?: string;
  onDrop: (...args: string[]) => void;
  height?: number;
}

const ImageEditor = (props: ImageEditorProps) => {
  const onDrop = (acceptedFiles: File[]) => {
    const { onDrop } = props;
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    convertToBase64(file).then((base64: string) => {
      onDrop(base64);
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const { height } = props;
  return (
    <Box {...getRootProps()} sx={mainContainerStyles(height)}>
      <input {...getInputProps()} />
      <Box sx={{ fontSize: '2rem', marginBottom: '4px' }}>
        <CloudUploadIcon color='primary' />
      </Box>
      {isDragActive ? (
        <Typography>Drop the files here ...</Typography>
      ) : (
        <Typography variant='body2'>Drag and Drop or click to upload.</Typography>
      )}
    </Box>
  );
};

const mainContainerStyles = (height: number): SxProps => ({
  display: 'flex',
  padding: '24px',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  color: 'text.primary',
  borderRadius: '4px',
  border: '1px dashed',
  borderColor: 'text.disabled',
  height: height && `${height}px`,
});

export default ImageEditor;
