import { GridComparatorFn } from '@mui/x-data-grid-pro';
import { Group } from 'redux/groups/types';
import { Member } from 'redux/members/types';
import { Unit } from 'redux/units/types';

type HasId = {
  id?: number;
};

export const PINNED_ROW_CLASS_NAME = 'group_edit_pinned_row';

export const filterAndRemoveDuplicates = <T extends HasId>(
  originalArray: T[],
  pinnedArray: T[],
  arrayToFilter: T[],
) => {
  const combinedArray = [...pinnedArray, ...originalArray];
  const uniqueSet = new Set(combinedArray.map(item => item?.id));

  // remove duplicate items that are pinned in the table
  const uniqueItemsArray = [...uniqueSet].map(id => combinedArray.find(item => item?.id === id));

  // prevent searching for items that are pinned in the other table
  const filteredArray = uniqueItemsArray.filter(
    item => !arrayToFilter.find(itemToFilter => itemToFilter?.id === item?.id),
  );

  return filteredArray;
};

export const computeCurrentListItems = <T extends HasId>(
  items: T[],
  pinnedItems: T[],
  pinnedItemsToBeFiltered: T[],
) => {
  const filteredCurrentItems = filterAndRemoveDuplicates(
    items,
    pinnedItems,
    pinnedItemsToBeFiltered,
  );

  return filteredCurrentItems;
};

export const getPinnedRowClassName = (params: {
  row: (Member | Unit | Group) & { isPinned: boolean };
}) => {
  return params.row.isPinned ? PINNED_ROW_CLASS_NAME : '';
};

export const PINNED_STATUS = {
  NOT_PINNED: 1,
  PINNED: -1,
  NO_CHANGE: 0,
};

export const pinnedComparator: GridComparatorFn<Member | Unit | Group> = (pin1, pin2) => {
  if (pin1 === pin2) return PINNED_STATUS.NO_CHANGE;

  return pin1 ? PINNED_STATUS.PINNED : PINNED_STATUS.NOT_PINNED;
};

export const computeItemsToMove = <T extends HasId>(items: T[], selectedItems: number[]) => {
  return items.reduce((result, item) => {
    if (selectedItems.includes(item?.id)) {
      result.push({ ...item, isPinned: true });
    }
    return result;
  }, []);
};

export const computeListItemsToUpdate = <T extends HasId>(
  originalItemIds: number[],
  pinnedItemsInGroup: T[],
  pinnedItemsNotInGroup: T[],
): number[] => {
  const pinnedItemsNotInGroupIds = pinnedItemsNotInGroup.map(item => item?.id);
  const pinnedMembersInGroupIds = pinnedItemsInGroup.map(item => item?.id);
  const uniqueSet = new Set([...originalItemIds, ...pinnedMembersInGroupIds]);

  return [...uniqueSet].reduce((result, itemId) => {
    if (!pinnedItemsNotInGroupIds.includes(itemId)) {
      result.push(itemId);
    }
    return result;
  }, []);
};

export const removeDuplicateNumbers = (array: number[]) => {
  return Array.from(new Set(array));
};

export const getUniqueNumbers = (numbers: number[]) => {
  const myMap = new Map();
  for (let i = 0; i < numbers.length; i++) {
    if (myMap.has(numbers[i])) {
      myMap.set(numbers[i], myMap.get(numbers[i]) + 1);
    } else {
      myMap.set(numbers[i], 1);
    }
  }
  const uniqueNumbers: number[] = [];
  myMap.forEach((value, key) => {
    if (value === 1) {
      uniqueNumbers.push(key);
    }
  });

  return uniqueNumbers;
};
