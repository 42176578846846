import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Button, Chip, Stack, SxProps, Typography } from '@mui/material';
import { paletteStyles } from '@operto/ui';
import BottomSlidebar from 'Common/Slidebar/BottomSlidebar';
import React, { useEffect, useState } from 'react';
import { FilterGroup } from 'types/filter-type';
import { DatePickerFilterPanelMobile } from './DatePickerFilterPanelMobile';
import { FilterPanelMobile } from './FilterPanelMobile';
import { GroupChipMobile } from './GroupChipMobile';

export type FiltersToolbarMobileProps = {
  searchParams: URLSearchParams;
  filterGroups: FilterGroup[];
  onFilterChange: (searchParams: URLSearchParams) => void;
  onFilterClear: (filterGroup: string) => void;
  updateChipLabel: (filterGroup: string) => string;
  updateTotalFilterLabelMobile: () => number;
};

export const FiltersToolbarMobile = ({
  searchParams,
  filterGroups,
  onFilterChange,
  updateTotalFilterLabelMobile,
  ...rest
}: FiltersToolbarMobileProps) => {
  const [bottomSlideopen, setBottomSlideopen] = useState(false);
  const [updatedSearchParams, setUpdatedSearchParams] = useState<URLSearchParams>();
  const [selectedGroup, setSelectedGroup] = useState<string>(undefined);

  useEffect(() => {
    setUpdatedSearchParams(new URLSearchParams(searchParams));
  }, [searchParams]);

  const onUpdatedSearchParamsChange = (params: URLSearchParams) => {
    setUpdatedSearchParams(new URLSearchParams(params));
  };

  const handleBottomSlideOpen = (isOpen: boolean, groupName?: string) => {
    if (groupName) {
      setSelectedGroup(groupName);
    } else {
      setSelectedGroup('mobile-filter-title');
    }
    setBottomSlideopen(isOpen);
  };

  const onRenderComplete = () => {
    if (selectedGroup) {
      const element = document.getElementById(`index-${selectedGroup}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  const renderGroupChips = (filterGroup: FilterGroup, key: number) => {
    return (
      <GroupChipMobile
        key={`${filterGroup}-filter-panel-${key}`}
        handleBottomSlideOpen={handleBottomSlideOpen}
        filter={filterGroup}
        {...rest}
      />
    );
  };

  const renderMobileFilterSlider = () => {
    return (
      <>
        <Box sx={mobileFilterHeaderStyle} id='mobile-filter-title'>
          <CloseIcon
            sx={closeIconStyle}
            onClick={() => {
              setBottomSlideopen(false);
              onUpdatedSearchParamsChange(searchParams);
            }}
          />
          <Typography variant='h4-700'>Filters</Typography>
        </Box>
        {filterGroups.map((filterGroup: FilterGroup, key: number) => {
          if (filterGroup.groupName === 'check_in' || filterGroup.groupName === 'check_out') {
            return (
              <DatePickerFilterPanelMobile
                key={`${filterGroup}-filter-panel-mobile-${key}`}
                searchParams={updatedSearchParams}
                onFilterChange={onUpdatedSearchParamsChange}
                filter={filterGroup}
                {...rest}
              />
            );
          }

          return (
            <FilterPanelMobile
              key={`${filterGroup}-filter-panel-mobile-${key}`}
              searchParams={updatedSearchParams}
              onFilterChange={onUpdatedSearchParamsChange}
              filter={filterGroup}
              {...rest}
            />
          );
        })}
        <Box sx={mobileFilterBottomBar}>
          <Button
            sx={clearAllStyle}
            onClick={() => onUpdatedSearchParamsChange(new URLSearchParams())}
            data-testid='filter-clear-all-mobile'
          >
            Clear all
          </Button>
          <Button
            sx={showResultsStyle}
            onClick={() => {
              handleBottomSlideOpen(false);
              onFilterChange(updatedSearchParams);
            }}
            data-testid='filter-show-results-mobile'
          >
            Show results
          </Button>
        </Box>
      </>
    );
  };

  return (
    <Stack direction='row' spacing={1} p={2} sx={mobileFilterContainer}>
      <Chip
        icon={<TuneIcon sx={{ color: `${paletteStyles.palette.primary.main} !important` }} />}
        label={updateTotalFilterLabelMobile()}
        sx={mobileFilterBase(Array.from(searchParams).length > 0)}
        onClick={() => handleBottomSlideOpen(true)}
        data-testid='filter-tune-icon-mobile'
      />
      {filterGroups.map((filterGroup: FilterGroup, index: number) =>
        renderGroupChips(filterGroup, index),
      )}
      <BottomSlidebar
        anchor='bottom'
        open={bottomSlideopen}
        setOpen={() => {
          setBottomSlideopen(!open);
        }}
        sx={filterBottomSlidebarStyle}
        element={renderMobileFilterSlider()}
        onRenderComplete={onRenderComplete}
        testId='filter-bottom-slidebar-mobile'
      />
    </Stack>
  );
};

const mobileFilterContainer: SxProps = {
  overflow: 'auto',
  padding: '16px 6px',
};

const mobileFilterBase = (isActive: boolean): SxProps => ({
  backgroundColor: isActive ? 'primary.p8' : 'text.white',
  color: '#155EFF',
  fontSize: '13px',
  fontWeight: '700',
  lineHeight: '171%',
  height: '32px',
  padding: '0px 2px',
  letterSpacing: '0.46px',
  borderRadius: '6px',
  '.MuiChip-label.MuiChip-labelMedium': {
    padding: !isActive ? '0 12px 0 0' : '0 12px',
  },
});

const mobileFilterHeaderStyle: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: '10px',
  padding: '8px 0px',
  margin: '16px 0px',
};

const closeIconStyle: SxProps = {
  width: '24px',
  height: '24px',
  color: 'black',
  opacity: '0.54',
  position: 'absolute',
  left: '12px',
};

const filterBottomSlidebarStyle: SxProps = {
  padding: '0px',
  paddingBottom: '57px',
  height: '100%',
  zIndex: 20,
  backgroundColor: 'background.default',
};

const mobileFilterBottomBar: SxProps = {
  display: 'flex',
  justifyContent: 'space-around',
  position: 'fixed',
  bottom: '0',
  width: '100%',
  padding: '16px',
  backgroundColor: 'background.default',
  borderTop: '1px solid var(--light-other-divider, rgba(21, 41, 122, 0.12))',
  zIndex: 10,
};

const clearAllStyle: SxProps = {
  color: '#155EFF',
  width: '163.5px',
  padding: '8px 16px',
  fontWeight: '700',
  letterSpacing: '0.46px',
  fontSize: '15px',
  textTransform: 'none',
};

const showResultsStyle: SxProps = {
  backgroundColor: '#155EFF',
  color: 'white',
  borderRadius: '6px',
  width: '163.5px',
  padding: '8px 16px',
  fontWeight: '700',
  letterSpacing: '0.46px',
  fontSize: '15px',
  textTransform: 'none',
};
