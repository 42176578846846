import { Reducer, combineReducers } from '@reduxjs/toolkit';
import { AlertDict, AlertState } from '../../types/alerts';
import { Actions } from '../../types/actions';

const byId: Reducer<AlertDict> = (state = {}, action) => {
  switch (action.type) {
    case Actions.hydrateAlerts:
      return action.alertDict;
    default:
      return state;
  }
};

const list: Reducer<number[]> = (state = [], action) => {
  switch (action.type) {
    case Actions.hydrateAlerts:
      return action.alertList;
    default:
      return state;
  }
};

const reducer: Reducer<AlertState> = combineReducers<AlertState>({
  byId,
  list,
});

export default reducer;
