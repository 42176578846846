import { Box, Stack, SxProps } from '@mui/material';
import { Text } from '@operto/ui';
import WarningIcon from 'Common/Icons/WarningIcon';
import React, { ReactNode } from 'react';

type GuestNameTableCellProps = {
  guestName: string;
  guestId: number;
  warningMessage?: ReactNode;
};

export const GuestNameTableCell = ({
  guestName,
  guestId,
  warningMessage,
}: GuestNameTableCellProps) => {
  const sxProps: SxProps = { display: 'flex', alignItems: 'center' };
  return (
    <Stack direction='column'>
      <Text.BodySmall>{guestName}</Text.BodySmall>
      <Box sx={sxProps}>
        <Text.CaptionLarge color={'text.secondary'}>{guestId}</Text.CaptionLarge>
        {warningMessage && <WarningIcon tooltipTitle={warningMessage} />}
      </Box>
    </Stack>
  );
};
