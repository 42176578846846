import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import * as React from 'react';

export interface NoiseIconProps {
  customColor?: string;
}

const NoiseIcon = ({ customColor = 'error.main' }: NoiseIconProps) => {
  const batteryIcon = <VolumeUpIcon sx={{ color: customColor }} />;
  return batteryIcon;
};

export default NoiseIcon;
