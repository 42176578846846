import { NotifiableError } from '@bugsnag/js';
import * as api from 'dashboard/state/dashboardAPI';
import { logger } from 'lib/logger';
import { OpertoLogger } from 'Logger/logger';
import { AppDispatch } from 'redux/store';
import { IReservation, ReservationDict } from 'reservation/reservationType';
import { Actions } from 'types/actions';

export const getReservationsByRoomStatusCard =
  (roomStatus = '') =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await api.getReservationByRoomStatus(roomStatus);

      let actionType;
      let stats = {};
      const reservations = response.data.data;

      switch (roomStatus) {
        case 'todays-check-out':
          actionType = Actions.hydrateCheckOutStats;
          stats = {
            total_check_out: reservations.total_check_out,
            total: reservations.total,
            reservations: reservations.check_outs,
          };
          break;
        case 'todays-check-in':
          actionType = Actions.hydrateCheckInStats;
          stats = {
            total_check_in: reservations.total_check_in,
            total: reservations.total,
            reservations: reservations.check_ins,
          };
          break;
        default:
      }

      if (actionType !== undefined) {
        dispatch({
          type: actionType,
          stats,
        });
      }
    } catch (err) {
      logger.error('get reservations for the room status', roomStatus, err);
    }
  };

export const getReservationsByRoomStatus =
  (roomStatus = '') =>
  (dispatch: AppDispatch) => {
    return api
      .getReservationByRoomStatus(roomStatus)
      .then(data => {
        let actionType;
        let stats = {};
        const reservations = data.data.data;
        switch (roomStatus) {
          case 'todays-check-out':
            actionType = Actions.hydrateCheckOutStats;
            stats = {
              total_check_out: reservations.total_check_out,
              total: reservations.total,
              reservations: reservations.check_outs,
            };
            break;
          case 'todays-check-in':
            actionType = Actions.hydrateCheckInStats;
            stats = {
              total_check_in: reservations.total_check_in,
              total: reservations.total,
              reservations: reservations.check_ins,
            };

            break;
          default:
        }
        // checkOutStats
        if (actionType !== undefined) {
          dispatch({
            type: actionType,
            stats,
          });

          const selectedData =
            roomStatus === 'todays-check-out' ? reservations.check_outs : reservations.check_ins;
          const reservationIds: number[] = [];
          const reservationsById: ReservationDict = {};
          selectedData?.forEach((reservation: IReservation) => {
            reservationIds.push(reservation.property_id);
            reservationsById[reservation.property_id] = reservation;
          });

          dispatch({
            type: Actions.addReservations,
            reservationIds,
            reservationsById,
          });
        }
      })
      .catch(err => {
        const metadata = {
          roomStatus,
        };
        OpertoLogger.Log(err);
        OpertoLogger.LeaveBreadCrumb('get reservations by room status', metadata, 'state');
      });
  };

export const getPropertyAlertsSummary = () => (dispatch: AppDispatch) => {
  return api
    .getPropertyAlertsSummary()
    .then(data => {
      const propertyAlerts = data.data.data;
      dispatch({
        type: Actions.hydratePropertyAlertsStats,
        stats: {
          total_alerts: propertyAlerts.total_alerts ?? 0,
          total_high_alerts: propertyAlerts.total_high_alerts ?? 0,
          total_low_alerts: propertyAlerts.total_low_alerts ?? 0,
        },
      });
    })
    .catch((err: NotifiableError) => {
      OpertoLogger.Log(err);
    });
};
