import { IntegrationProvider } from 'integrations/integrationsTypes';
import { AppDispatch } from 'redux/store';
import { Actions } from 'types/actions';
import { ConfirmFunctions } from 'types/confimations';
import {
  SlidebarType,
  SnackbarContentType,
  SnackbarPanelContentType,
  SnackbarTypes,
} from 'types/ui';

export const storePropertyId = (id: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.storePropertyId,
    id,
  });
};

export const toggleAddGroup = () => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.toggleAddGroup,
  });
};

export const toggleConfirmationPopup =
  (ids: number[], functionType: ConfirmFunctions) => (dispatch: AppDispatch) => {
    dispatch({
      type: Actions.toggleConfirmationPopup,
      ids,
      functionType,
    });
  };
export const toggleAddTeamMember = (type: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.toggleAddTeamMember,
    memberType: type,
  });
};

export const toggleAddProperty = () => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.toggleAddProperty,
  });
};

export const toggleEditIntercom = () => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.toggleEditIntercom,
  });
};

export const toggleAddGuestyIntegration = {
  type: Actions.toggleAddIntegrationGuesty,
};

export const toggleAddHostawayIntegration = {
  type: Actions.toggleAddIntegrationHostaway,
};

export const toggleAddMewsIntegration = {
  type: Actions.toggleAddIntegrationMews,
};

export const toggleAddAvantioIntegration = {
  type: Actions.toggleAddIntegrationAvantio,
};

export const toggleAddKigoIntegration = {
  type: Actions.toggleAddIntegrationKigo,
};

export const toggleAddWebRezProIntegration = {
  type: Actions.toggleAddIntegrationWebRezPro,
};

export const toggleAddVRSIntegration = {
  type: Actions.toggleAddIntegrationVRScheduler,
};

export const toggleAddHostfullyIntegration = {
  type: Actions.toggleAddIntegrationHostfully,
};

export const toggleAddLightmakerIntegration = {
  type: Actions.toggleAddIntegrationLightmaker,
};

export const toggleAddDirectIntegration = {
  type: Actions.toggleAddIntegrationDirect,
};

export const toggleAddIntegration = (type: IntegrationProvider) => (dispatch: AppDispatch) => {
  // need to make place for data for different integrations
  switch (type) {
    case IntegrationProvider.Guesty:
      dispatch(toggleAddGuestyIntegration);
      break;
    case IntegrationProvider.HostAway:
      dispatch(toggleAddHostawayIntegration);
      break;
    case IntegrationProvider.Mews:
      dispatch(toggleAddMewsIntegration);
      break;
    case IntegrationProvider.Avantio:
      dispatch(toggleAddAvantioIntegration);
      break;
    case IntegrationProvider.Kigo:
      dispatch(toggleAddKigoIntegration);
      break;
    case IntegrationProvider.WebRezPro:
      dispatch(toggleAddWebRezProIntegration);
      break;
    case IntegrationProvider.Hostfully:
      dispatch(toggleAddHostfullyIntegration);
      break;
    case IntegrationProvider.Lightmaker:
      dispatch(toggleAddLightmakerIntegration);
      break;
    case IntegrationProvider.VRScheduler:
      dispatch(toggleAddVRSIntegration);
      break;
    case IntegrationProvider.Direct:
      dispatch(toggleAddDirectIntegration);
      break;
    default:
  }
};

export const showDeletePending = (id: number) => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.deleteBackupCodePending,
    id,
  });
};

export const setPropertyId = (id: number) => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.setPropertyId,
    id,
  });
};

export const changeTabs = (tabIndex: number) => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.changePropertyDetailTab,
    tabIndex,
  });
};

export const activateUnits = (units: unknown[]) => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.activateUnits,
    units: units.length,
  });
};

export const toggleAddMemberToTeam = () => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.toggleAddMemberToTeam,
  });
};

export const showViewMoreUnits = (unitIds: number[], title: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.showViewMoreUnits,
    unitIds,
    title,
  });
};

export const showViewMoreReservations =
  (reservationIds: number[], title: string, showCodeStatus: boolean) => (dispatch: AppDispatch) => {
    dispatch({
      type: Actions.showViewMoreReservations,
      reservationIds,
      title,
      showCodeStatus,
    });
  };

export const toggleSidebar = () => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.toggleMobileSidebar,
  });
};

export const toggleSlidebar =
  (actionType: SlidebarType, SlidebarContent: unknown, onClose?: () => void) =>
  (dispatch: AppDispatch) => {
    dispatch({
      type: Actions.toggleSlidebar,
      content: {
        [actionType]: { content: SlidebarContent, onClose },
      },
    });
  };

export const toggleSnackbar =
  (actionType: SnackbarTypes, snackbarContent: SnackbarContentType) => (dispatch: AppDispatch) => {
    dispatch({
      type: Actions.toggleSnackbar,
      content: {
        [actionType]: snackbarContent,
      },
    });
  };

export const togglePanelSnackbar =
  (actionType: SnackbarTypes, snackbarContent: SnackbarPanelContentType) =>
  (dispatch: AppDispatch) => {
    dispatch({
      type: Actions.togglePanelSnackbar,
      content: {
        [actionType]: snackbarContent,
      },
    });
  };

export const isAdmin = () => !!localStorage.getItem('masq');
