import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditIcon from '@mui/icons-material/Edit';
import MessageIcon from '@mui/icons-material/Message';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import WarningIcon from 'Common/Icons/WarningIcon';
import { accessCodesByResIdSelector } from 'code/state/accessCodeSelector';
import { FilterTypeIndex, FilterTypeLabel } from 'guest/guestType';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { IReservation } from 'reservation/reservationType';
import ReservationContactDropdown from './ReservationContactDropdown';

import { PMSDisconnectMessage } from 'Common/ToolTip/TooltipMessages';
export interface ReservationFooterProps {
  onEditClick?: () => void;
  onCreateClick?: () => void;
  reservation: IReservation;
  hideButtons?: boolean;
  propertyId: number;
}

const ReservationFooter = ({
  onEditClick,
  onCreateClick,
  reservation,
  hideButtons,
  propertyId,
}: ReservationFooterProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);
  const [anchorDropdownEl, setAnchorDropdownEl] = React.useState<null | Element>(null);
  const accessCode = useAppSelector(accessCodesByResIdSelector(reservation?.id));
  const navigate = useNavigate();

  const handleDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorDropdownEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDropdownClose = () => {
    setAnchorDropdownEl(null);
  };

  const handleMessageClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTab = (index: FilterTypeIndex) => {
    switch (index) {
      case FilterTypeIndex.PRE_STAY:
        navigate(`/units/${propertyId}/guests/pre-stay`);
        break;
      case FilterTypeIndex.IN_STAY:
        navigate(`/units/${propertyId}/guests/in-stay`);
        break;
      case FilterTypeIndex.POST_STAY:
        navigate(`/units/${propertyId}/guests/post-stay`);
        break;
      case FilterTypeIndex.BLOCKED:
        navigate(`/units/${propertyId}/guests/blocked`);
        break;
      default:
        navigate(`/units/${propertyId}/guests`);
    }
  };

  return (
    <Box
      sx={{
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        height: '48px',
      }}
    >
      <Button
        onClick={handleDropdownClick}
        endIcon={<ArrowDropDownIcon />}
        sx={{ color: 'white', textTransform: 'none', fontWeight: 700 }}
      >
        All Guests
      </Button>
      <Menu
        anchorEl={anchorDropdownEl}
        open={Boolean(anchorDropdownEl)}
        onClose={handleDropdownClose}
      >
        <MenuItem onClick={() => handleTab(FilterTypeIndex.ALL_GUESTS)}>
          {FilterTypeLabel.ALL_GUESTS}
        </MenuItem>
        <MenuItem onClick={() => handleTab(FilterTypeIndex.PRE_STAY)}>
          {FilterTypeLabel.PRE_STAY}
        </MenuItem>
        <MenuItem onClick={() => handleTab(FilterTypeIndex.IN_STAY)}>
          {FilterTypeLabel.IN_STAY}
        </MenuItem>
        <MenuItem onClick={() => handleTab(FilterTypeIndex.POST_STAY)}>
          {FilterTypeLabel.POST_STAY}
        </MenuItem>
        <MenuItem onClick={() => handleTab(FilterTypeIndex.BLOCKED)}>
          {FilterTypeLabel.BLOCKED}
        </MenuItem>
      </Menu>
      {hideButtons && (
        <Box>
          {reservation ? (
            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '16px' }}>
              {reservation.source === 'dashboard' && (
                <IconButton
                  onClick={onEditClick}
                  sx={{
                    width: '24px',
                    height: '24px',
                  }}
                >
                  <EditIcon sx={{ color: 'text.white' }} />
                </IconButton>
              )}
              {accessCode?.over_written_duration && (
                <WarningIcon tooltipTitle={PMSDisconnectMessage} fill />
              )}
              <IconButton
                onClick={handleMessageClicked}
                sx={{
                  width: '24px',
                  height: '24px',
                }}
              >
                <MessageIcon sx={{ color: 'text.white' }} />
              </IconButton>
            </Box>
          ) : (
            <IconButton
              onClick={onCreateClick}
              sx={{
                width: '24px',
                height: '24px',
              }}
            >
              <AddIcon sx={{ color: 'text.white' }} />
            </IconButton>
          )}
          <ReservationContactDropdown
            onClose={handleClose}
            open={Boolean(anchorEl)}
            reservation={reservation}
            anchorEl={anchorEl}
          />
        </Box>
      )}
    </Box>
  );
};

export default ReservationFooter;
