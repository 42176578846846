import { Box, Switch, Tooltip, TooltipProps } from '@mui/material';
import React from 'react';

export default function IOSSwitch({
  active,
  onChange,
  tooltip,
  tooltipSx,
  disabled,
}: IOSSwitchProps) {
  return (
    <Tooltip
      data-testid='ios-switch-tooltip'
      title={tooltip}
      slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -3] } }] } }}
      sx={tooltipSx}
    >
      <Box>
        <Switch
          data-testid='ios-switch'
          disableRipple
          disabled={disabled}
          checked={active}
          onChange={onChange}
          sx={switchStyles}
        />
      </Box>
    </Tooltip>
  );
}

type IOSSwitchProps = {
  active: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  tooltip?: string;
  tooltipSx?: TooltipProps['sx'];
};

const switchStyles = {
  height: '32px',
  width: '52px',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '5px',
    marginTop: '4px',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#155EFF',
        opacity: 1,
        border: 0,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#fff',
      },
      '& .MuiSwitch-thumb:before': {
        opacity: 0.4,
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          '#000',
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: 'rgba(29, 27, 32, 0.12)',
        borderColor: 'rgba(8, 22, 62, 0.23)',
        opacity: 1,
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        backgroundColor: 'white',
        opacity: 1,
        '&::before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            '#000',
          )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        },
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      backgroundColor: 'rgba(8, 22, 62, 0.23)',
      opacity: 0.4,
      '&::before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="10" width="10" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          '#000',
        )}" d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>')`,
      },
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      borderColor: 'rgba(8, 22, 62, 0.23)',
      opacity: 1,
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#7B7C7E',
    boxSizing: 'border-box',
    width: '24px',
    height: '24px',
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="10" width="10" viewBox="0 0 22 22"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    border: '2px solid #7B7C7E',
    opacity: 1,
    transition: 'background-color 500ms',
  },
};
