import { logger } from 'lib/logger';
import { useEffect } from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { clearStatus, selectGuestPortal } from '../slice';

export const useGuestPortalStatus = () => {
  const dispatch = useAppDispatch();
  const { status, message } = useAppSelector(selectGuestPortal);

  useEffect(() => {
    if (['idle', 'loading'].includes(status)) {
      return;
    }

    // show snackbar success
    if (status === 'succeeded' && message !== null) {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message,
          variant: SnackbarVariant.SUCCESS,
        }),
      );
    }

    // show snackbar error
    if (status === 'failed' && message !== null) {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message,
          variant: SnackbarVariant.ERROR,
        }),
      );

      logger.error(message);
    }

    // clear status to idle
    dispatch(clearStatus());
  }, [status, dispatch, message]);

  return {
    isIdle: status === 'idle',
    isLoading: status === 'loading',
    isSuccess: status === 'succeeded',
    isError: status === 'failed',
  };
};
