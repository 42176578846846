import { createSelector } from '@reduxjs/toolkit';
import { DashboardState } from 'dashboard/dashboardTypes';
import { ApplicationState } from 'redux/reducers';

const getDashboard = (state: ApplicationState) => state.dashboard;

export const dashboardStatSelector = (statType: string) =>
  createSelector(
    [getDashboard],
    (dashboard: DashboardState) => dashboard && dashboard[statType as keyof DashboardState],
  );
