import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { MoreAlerts } from '../../../types/ui';
import { Actions } from '../../../types/actions';

const show: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.hideViewMoreAlerts:
      return false;
    case Actions.showViewMoreAlerts:
      return !state;
    default:
      return state;
  }
};
const unitIds: Reducer<number[]> = (state = [], action) => {
  switch (action.type) {
    case Actions.showViewMoreAlerts:
      return action.unitIds;
    default:
      return state;
  }
};

const moreAlerts: Reducer<MoreAlerts> = combineReducers<MoreAlerts>({
  show,
  unitIds,
});

export default moreAlerts;
