import { Stack } from '@mui/material';
import { AddCard } from '@operto/ui-library';
import ActionSnackbar from 'Common/Snackbar/ActionSnackbar';
import { TGuestyPayload } from 'integrations/integrationsTypes';
import React, { useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormTextField } from 'ui-library/Components/input/FormTextField';
import { IntegrationsFormHandle, IntegrationsFormProps } from '../IntegrationsSideDrawer';

type GuestyIntegrationFormProps = IntegrationsFormProps & {
  onSave: (data: Omit<TGuestyPayload, 'provider'>) => void;
  onClose: () => void;
};

const GuestyIntegrationForm = React.forwardRef<IntegrationsFormHandle, GuestyIntegrationFormProps>(
  ({ onSave, onClose }, ref) => {
    const [showExitConfirmation, setShowExitConfirmation] = useState(false);

    const {
      handleSubmit,
      control,
      formState: { isDirty },
    } = useForm<Omit<TGuestyPayload, 'provider'>>({
      mode: 'all',
      defaultValues: {},
    });

    useImperativeHandle(
      ref,
      () => ({
        submit: handleSubmit(onSave),
        close: () => {
          setShowExitConfirmation(isDirty);
          if (!isDirty) {
            onClose?.();
          }
        },
      }),
      [handleSubmit, isDirty, onClose, onSave],
    );

    return (
      <Stack m={1}>
        <AddCard title='Integration Settings'>
          <ActionSnackbar
            onClose={onClose}
            open={showExitConfirmation}
            setOpen={setShowExitConfirmation}
            severity='error'
            actionButtonText='YES'
            snackbarMessage='You have unsaved changes that will be lost if you exit. Are you sure you want to exit?'
          />

          <FormTextField
            rules={{
              required: {
                value: true,
                message: 'Integration Token is required',
              },
            }}
            field='api_token.'
            label='Integration Token'
            helperText='Please copy the ‘integration token’ from your Guesty account and paste it here.'
            control={control}
            sx={{ mb: 4 }}
          />
        </AddCard>
      </Stack>
    );
  },
);

GuestyIntegrationForm.displayName = 'GuestyIntegrationForm';

export default GuestyIntegrationForm;
