import { Chip, List, ListItem, Popover, Stack, SxProps, Typography } from '@mui/material';
import { Text } from '@operto/ui';
import React, { useState } from 'react';
import { filterChipStyle, listStyle } from 'ui-library/Components/table/FilterPanel';

type NamedChipProps = {
  names: string[];
  sx?: SxProps;
};

export const NamedChip = ({ names, sx }: NamedChipProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  if (names === undefined || names.length < 1) {
    return null;
  }

  const sortedNames = names.sort((a: string, b: string) => a.localeCompare(b));

  const getDisplayNames = () => {
    const displayedName = sortedNames.find(Boolean);
    if (sortedNames.length === 1) {
      return displayedName;
    }

    return (
      <Stack flexDirection='row'>
        <Typography variant='body-sm-400'>{displayedName}</Typography>
        <Typography variant='body-sm-600' color='blue' sx={{ marginLeft: '5px' }}>
          +{sortedNames.length - 1}
        </Typography>
      </Stack>
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Chip
        sx={sx ?? filterChipStyle(true)}
        label={getDisplayNames()}
        onClick={handleClick}
        data-testid={`named-chip-${sortedNames.find(Boolean)}`}
      />
      <Popover
        id='group-chip-popover'
        sx={popoverStyle}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List sx={listStyle}>
          {sortedNames.map((name, index) => (
            <ListItem
              sx={{
                height: '52px',
                width: '320px',
                alignItems: 'center',
              }}
              divider={index < sortedNames.length - 1}
              key={`named-chip-${index}`}
            >
              <Text variant='body-sm-400' style={{ marginLeft: '-12px' }}>
                {name}
              </Text>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

const popoverStyle = {
  '& .MuiPopover-paper': {
    borderRadius: '12px',
    padding: '0px 24px',
    minWidth: '320px',
  },
};
