import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { ServiceNotifications } from '../../../types/ui';
import { Actions } from '../../../types/actions';

const showAddMemberNotification: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.showServiceNotification:
      return true;
    case Actions.hideServiceNotification:
      return false;
    default:
      return state;
  }
};
const notificationType: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.showServiceNotification:
      return action.notificationType;
    case Actions.hideServiceNotification:
      return 0;
    default:
      return state;
  }
};

const serviceNotifications: Reducer<ServiceNotifications> = combineReducers<ServiceNotifications>({
  show: showAddMemberNotification,
  notificationType,
});

export default serviceNotifications;
