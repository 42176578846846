import { NotifiableError } from '@bugsnag/js';
import { OpertoLogger } from 'Logger/logger';
import * as groupApi from 'api/groupAPI';
import * as api from 'api/memberAPI';
import * as propertyAPI from 'api/propertyAPI';
import { AxiosResponse } from 'axios';
import { getCodes } from 'code/state/codeAction';
import { IMember, MemberDict, MemberFilterType } from 'member/memberType';
import { toggleSlidebar, toggleSnackbar } from 'redux/actions/ui';
import { GROUP_LIST_ID, GROUP_TAG } from 'redux/groups/api-slice';
import { MEMBER_LIST_ID, MEMBER_TAG } from 'redux/members/api-slice';
import { ApplicationState } from 'redux/reducers';
import { RTKQapi } from 'redux/rtkq-api';
import { AppDispatch } from 'redux/store';
import { Actions } from 'types/actions';
import { SlidebarType, SnackbarTypes, SnackbarVariant } from 'types/ui';

export const getCurrentMember = () => (dispatch: AppDispatch) => {
  return api
    .getCurrentMember()
    .then(data => {
      const member = data.data.data;
      dispatch({
        type: Actions.insertMember,
        member,
      });
      dispatch({
        type: Actions.storeGetStreamUser,
        member,
      });
      dispatch({
        type: Actions.storeFeatures,
        member,
      });
      dispatch({
        type: Actions.storeRole,
        member,
      });
    })
    .catch((err: NotifiableError) => {
      OpertoLogger.Log(err);
    });
};

export const getMembers = () => (dispatch: AppDispatch) => {
  api
    .getMembers()
    .then(data => {
      const { members } = data.data.data;
      const memberIds: number[] = [];
      const membersById: MemberDict = {};

      members.forEach((each: IMember) => {
        memberIds.push(each.id);
        membersById[each.id] = each;
      });
      dispatch({
        type: Actions.addMembers,
        memberIds,
        membersById,
      });
    })
    .catch(err => {
      OpertoLogger.Log(err);
    });
};

export const getMembersByGroup =
  (groupId: number, isHydrate = true) =>
  (dispatch: AppDispatch) => {
    return api
      .getMembersByGroupId(groupId)
      .then((data: AxiosResponse) => {
        const members = data.data.data;
        const memberIds: number[] = [];
        const membersById: MemberDict = {};
        members.forEach((each: IMember) => {
          memberIds.push(each.id);
          membersById[each.id] = each;
          // membersById[each.id].groupBelongsTo = groupId;
        });
        if (isHydrate) {
          dispatch({
            type: Actions.hydrateMembers,
            memberIds,
            membersById,
          });
        } else {
          dispatch({
            type: Actions.addMembers,
            memberIds,
            membersById,
          });
        }
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  };

export const getPropertyMembers =
  (propertyId: number, isHydrate = false, filterType?: MemberFilterType) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: Actions.updateMemberCounts,
      filterType: filterType,
      isLoading: true,
    });
    try {
      const data = await propertyAPI.getPropertyMembers(propertyId);

      const { members } = data.data.data;
      const memberIds: number[] = [];
      const membersById: MemberDict = {};
      members?.forEach((member: IMember) => {
        memberIds.push(member.id);
        membersById[member.id] = member;
      });
      if (isHydrate) {
        dispatch({
          type: Actions.hydrateMembers,
          memberIds,
          membersById,
        });
      } else {
        dispatch({
          type: Actions.addMembers,
          memberIds,
          membersById,
        });
      }
      dispatch({
        type: Actions.updateMemberCounts,
        filterType: filterType,
        isLoading: false,
        meta: {
          ids: memberIds,
          total_records: members?.total_records,
          numPerPage: members?.numPerPage,
          pageNum: members?.pageNum,
        },
      });
    } catch (err) {
      OpertoLogger.Log(err);
    }
  };

export const getMembersByCompany =
  ({
    filterType,
    pageNum,
    numPerPage,
    keyword,
    excludeInGroup,
    groupId,
  }: {
    filterType: MemberFilterType;
    pageNum: number;
    numPerPage: number;
    keyword?: string;
    excludeInGroup?: number;
    groupId?: number;
  }) =>
  async (dispatch: AppDispatch, getState: () => ApplicationState) => {
    let staff_type = '';
    let status = '';
    if (filterType === MemberFilterType.CONTRACTOR || filterType === MemberFilterType.STAFF) {
      staff_type = filterType;
    }
    if (filterType === MemberFilterType.INACTIVE) {
      status = filterType;
    } else if (filterType === MemberFilterType.ALL_MEMBERS) {
      status = 'active';
    }

    try {
      // save our current filter
      dispatch({
        type: Actions.updateMemberCounts,
        filterType,
        isLoading: true,
      });

      const result = await api.getMembersByCompanyWithPagination(
        pageNum,
        numPerPage,
        staff_type,
        status,
        keyword,
        excludeInGroup,
        groupId,
      );

      const { currentFilter } = getState().members.meta;
      if (currentFilter !== filterType) {
        // ignoring API response that is not our current filter, we dont want to override current filter
        return;
      }

      // process correct filter
      const members = result.data.data;
      const memberIds: number[] = [];
      const membersById: MemberDict = {};
      members?.records.forEach((each: IMember) => {
        memberIds.push(each.id);
        membersById[each.id] = each;
        const aMember = membersById[each.id];
        aMember.type = filterType;
      });

      dispatch({
        type: Actions.upsertMembers,
        membersById,
      });

      dispatch({
        type: Actions.updateMemberCounts,
        filterType,
        isLoading: false,
        meta: {
          ids: memberIds,
          total_records: members?.total_records,
          numPerPage: members?.numPerPage,
          pageNum: members?.pageNum,
        },
      });
    } catch (error) {
      const metadata = {
        pageNum,
        numPerPage,
        staff_type,
        status,
        keyword,
      };
      OpertoLogger.Log(error);
      OpertoLogger.LeaveBreadCrumb('get members by company', metadata, 'state');
    }
  };

export const getMember = (id: number) => (dispatch: AppDispatch) => {
  api
    .getMember(id)
    .then((data: AxiosResponse) => {
      dispatch({
        type: Actions.updateMembers,
        member: data.data.data,
      });
    })
    .catch(err => {
      OpertoLogger.Log(err);
    });
};

export const refreshMemberCode = (memberId: number) => (dispatch: AppDispatch) => {
  api
    .refreshMemberCode(String(memberId))
    .then((data: AxiosResponse) => {
      dispatch(getMember(data.data.data.id));
      dispatch(toggleSlidebar(SlidebarType.CLOSE, ''));

      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Refreshing Access Code was successful.',
          variant: SnackbarVariant.SUCCESS,
        }),
      );
    })
    .catch((err: NotifiableError) => {
      OpertoLogger.Log(err);
    });
};

export const createMember = (member: IMember) => async (dispatch: AppDispatch) => {
  member.group_ids = member.groups;
  const result = await api.createMember(member);
  const memberData = result.data.data;
  dispatch({
    type: Actions.addTeamMember,
    member: memberData,
  });
  dispatch(getCodes());
  dispatch(
    getMembersByCompany({
      filterType: MemberFilterType.ALL_MEMBERS,
      pageNum: 0,
      numPerPage: 50,
    }),
  );
  dispatch(
    RTKQapi.util.invalidateTags([
      { type: GROUP_TAG as never, id: GROUP_LIST_ID },
      { type: MEMBER_TAG as never, id: MEMBER_LIST_ID },
    ]),
  );
  return memberData;
};

export const updateMember = (memberData: IMember) => async (dispatch: AppDispatch) => {
  await api.updateMember(memberData);
  dispatch(
    getMembersByCompany({
      filterType: MemberFilterType.ALL_MEMBERS,
      pageNum: 0,
      numPerPage: 25,
    }),
  );
  dispatch(
    RTKQapi.util.invalidateTags([
      { type: GROUP_TAG as never, id: GROUP_LIST_ID },
      { type: MEMBER_TAG as never, id: MEMBER_LIST_ID },
    ]),
  );
  dispatch(getCodes());
  dispatch({
    type: Actions.updateMembers,
    member: memberData,
  });
};

export const updateMemberPreferences = (memberData: IMember) => async (dispatch: AppDispatch) => {
  await api.updateMember(memberData);
  dispatch({
    type: Actions.updateMembers,
    member: memberData,
  });
};

export const toggleMemberStatus =
  (memberId: number, status: boolean) => async (dispatch: AppDispatch) => {
    await api.toggleMemberStatus(memberId, status);
    dispatch({
      type: status ? Actions.enableMember : Actions.disableMember,
      memberId,
    });
  };

export const addUnitsToMember =
  (unitIds: string[], memberId: number) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const member = getState().members.byId[memberId];
    const memberPrivateGroupId = member.groups_private[0];
    const numberUnitIds = unitIds.map((each: string) => Number(each));
    groupApi
      .addUnitsToUnitGroup(numberUnitIds, memberPrivateGroupId)
      .then(() => {
        const memberProperties = member.properties;
        dispatch({
          type: Actions.addPropertiesToMember,
          propertyIds: memberProperties,
          memberId,
        });
        dispatch({
          type: Actions.hideAddUnitsToMember,
        });
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  };

export const removeUnitsFromMember =
  (unitIds: string[], memberId: number) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const member = getState().members.byId[memberId];
    const memberPrivateGroupId = member.groups_private[0];
    const numberUnitIds = unitIds.map((each: string) => Number(each));
    numberUnitIds.forEach(unitId =>
      groupApi
        .removeUnitFromUnitGroup(unitId, memberPrivateGroupId)
        .then(() => {
          const memberProperties = member.properties;
          dispatch({
            type: Actions.addPropertiesToMember,
            propertyIds: memberProperties,
            memberId,
          });
          dispatch({
            type: Actions.hideAddUnitsToMember,
          });
        })
        .catch((err: NotifiableError) => {
          OpertoLogger.Log(err);
        }),
    );
  };

export const addTeamsToMember =
  (teamIds: number[], memberId: number) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const member = getState().members.byId[memberId];
    const memberObj = { ...member };
    memberObj.teams = [...memberObj.teams, ...teamIds];
    memberObj.group_ids = memberObj.groups;
    api
      .updateMember(memberObj)
      .then((data: AxiosResponse) => {
        dispatch({
          type: Actions.updateMembers,
          member: data.data.data,
        });
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  };

export const addGroupsToMember =
  (groupIds: number[], memberId: number) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const member = getState().members.byId[memberId];
    const memberObj = { ...member };
    memberObj.groups = groupIds;
    memberObj.group_ids = memberObj.groups;
    api
      .updateMember(memberObj)
      .then((data: AxiosResponse) => {
        dispatch({
          type: Actions.updateMembers,
          member: data.data.data,
        });
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  };

export const removeMemberFromGroup =
  (groupId: number, memberId: number) => (dispatch: AppDispatch) => {
    api
      .removeMemberFromGroup(groupId, memberId)
      .then(() => {
        dispatch(getMember(memberId));
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  };

export const updateMemberEmail =
  (email: string, memberData: IMember) => (dispatch: AppDispatch) => {
    api
      .updateMemberEmail(email, memberData.id)
      .then((data: AxiosResponse) => {
        dispatch({
          type: Actions.updateMembers,
          member: data.data.data,
        });
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  };

export const refreshLoader =
  (id: number) => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const state: ApplicationState = getState();
    const memberId = state.ui.memberProfile.id;
    const { previousMemberAccessCode } = state.ui.refreshLoader;
    const currentMemberAccessCode = state.members.byId[memberId].member_access?.pin_code;
    if (
      memberId &&
      state.members.byId[memberId] &&
      currentMemberAccessCode &&
      previousMemberAccessCode
    ) {
      if (currentMemberAccessCode !== previousMemberAccessCode) {
        dispatch({
          type: Actions.toggleRefreshLoaderSuccess,
        });
      } else if (currentMemberAccessCode === previousMemberAccessCode) {
        dispatch({
          type: Actions.toggleRefreshLoaderPending,
          id,
          previousMemberAccessCode,
        });
      }
    } else if (
      memberId == null ||
      state.members.byId[memberId] == null ||
      (currentMemberAccessCode == null && previousMemberAccessCode == null)
    ) {
      dispatch({
        type: Actions.toggleRefreshLoaderFailed,
        id,
        previousMemberAccessCode,
      });
    }
  };
