import { NotifiableError } from '@bugsnag/js';
import * as api from 'api/propertyAlertAPI';
import { AxiosResponse } from 'axios';
import { OpertoLogger } from 'Logger/logger';
import {
  IPropertyAlertOverview,
  PropertyAlertDict,
  PropertyAlertMeta,
} from 'propertyAlerts/propertyAlertType';
import { AppDispatch } from 'redux/store';
import { Actions } from 'types/actions';

export const getPropertiesAlerts =
  ({ pageNum = 0, numPerPage = 50, keyword }: Record<string, unknown> = {}) =>
  (dispatch: AppDispatch) => {
    const alertsList: number[] = [];
    const alertsDict: PropertyAlertDict = {};

    return api
      .getMultiplePropertiesAlerts(pageNum as number, numPerPage as number, keyword as string)
      .then((data: AxiosResponse) => {
        const { records, numPerPage, pageNum, total_records } = data.data.data;
        const meta = <PropertyAlertMeta>{ numPerPage, pageNum, total_records };

        records.forEach((alert: IPropertyAlertOverview) => {
          alertsList.push(alert.property_id);
          alertsDict[alert.property_id] = {
            ...alert,
            alerts: alert.alerts.sort(
              (a, b) => new Date(b.time_ago).valueOf() - new Date(a.time_ago).valueOf(),
            ),
          };
        });

        if (pageNum > 0) {
          dispatch({
            type: Actions.insertPropertyAlerts,
            alertsList,
            alertsDict,
          });
        } else {
          dispatch({
            type: Actions.hydratePropertyAlerts,
            alertsList,
            alertsDict,
          });
        }

        dispatch({
          type: Actions.updatePropertyAlertsCount,
          meta,
        });
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  };
