import { Box } from '@mui/material';
import { getCzSuccessCenterAuthToken } from 'api/czAPI';
import { userStripeId as stripeId } from 'lib/analytics';
import { getSelf } from 'member/state/memberSelectors';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';

const SUCCESS_CENTER_CALLBACK_BASE_URL = new URL(
  '/SuccessCenterCallback',
  new URL(process.env.REACT_APP_CHURNZERO_URL),
);

export default function SuccessCenter() {
  const [loadingContent, setLoadingContent] = useState(true);
  const { search } = useLocation();
  const [czAuthToken, setCzAuthToken] = useState<string | null>(null);
  const [contentUrl, setContentUrl] = useState('');
  const currentMember = useAppSelector(getSelf());

  const searchParams = new URLSearchParams(search);
  const nextId = searchParams.get('next');

  const buildContentUrl = useCallback((): URL => {
    const contentUrl = new URL(SUCCESS_CENTER_CALLBACK_BASE_URL);
    const encodedCzAuthToken = encodeURIComponent(czAuthToken);

    contentUrl.searchParams.append('next', nextId);
    contentUrl.searchParams.append('token', encodedCzAuthToken);

    return contentUrl;
  }, [nextId, czAuthToken]);

  useEffect(() => {
    const fetchChurnZeroToken = async () => {
      const {
        data: {
          data: { authToken },
        },
      } = await getCzSuccessCenterAuthToken({
        contactExternalId: currentMember?.email,
        accountExternalId: stripeId,
      });

      return authToken as string;
    };

    if (currentMember?.email && stripeId) {
      fetchChurnZeroToken().then(token => {
        setLoadingContent(false);
        setCzAuthToken(token);
      });
    }
  }, [currentMember?.email, nextId]);

  useEffect(() => {
    if (czAuthToken) {
      const contentUrl = buildContentUrl();
      setContentUrl(contentUrl.href);
    }
  }, [czAuthToken, buildContentUrl]);

  return (
    <LoadingContainer loading={loadingContent}>
      <Box width='100%' height='735px' mt='14px'>
        <Iframe
          url={contentUrl}
          width='100%'
          height='100%'
          styles={{
            border: '1px solid #DCE0E3',
            borderRadius: '15px',
          }}
        />
      </Box>
    </LoadingContainer>
  );
}
