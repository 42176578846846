import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { NotificationDict, NotificationState } from '../../types/notifications';
import { Actions } from '../../types/actions';

const listNotifications: Reducer<string[]> = (state = [], action) => {
  switch (action.type) {
    case Actions.insertNotification:
      return [...state, action.notification.name];
    case Actions.deleteNotification:
      return state;
    default:
      return state;
  }
};

const notificationsById: Reducer<NotificationDict> = (state = {}, action) => {
  switch (action.type) {
    case Actions.insertNotification:
    case Actions.upsertNotification:
      return {
        ...state,
        [action.notification.name]: {
          ...action.notification,
          value: action.notification.name,
        },
      };
    case Actions.deleteNotification:
      return state;
    default:
      return state;
  }
};

const cleaningNote: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.updatingCleaningNote:
      return !state;
    case Actions.updatedCleaningNote:
      return !state;
    default:
      return state;
  }
};

const reducer: Reducer<NotificationState> = combineReducers<NotificationState>({
  byId: notificationsById,
  list: listNotifications,
  updateCleaningNote: cleaningNote,
});

export default reducer;
