// @ts-nocheck
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { BaseContainer } from 'Style/Components/BaseComponents';
import * as React from 'react';
import styled from 'styled-components';
import { Reservation } from 'types/reservations';
import Widget from 'ui-library/Components/widget/Widget';
import DateDisplay from 'ui-library/Components/widget/content/DateDisplay';

export interface CodeWidgetProps {
  reservation: Reservation;
}

const CodeWidget = ({ reservation }: CodeWidgetProps) => {
  const filled = false;
  return (
    <Widget title='Current Access'>
      <ContentContainer>
        <DatesContainer>
          <DateDisplay filled={filled} date={reservation?.check_in} timezone='Vancouver' />
          <VDivider orientation='vertical' filled={filled} />
          <DateDisplay filled={filled} date={reservation?.check_out} timezone='Vancouver' />
        </DatesContainer>
        <StatusContainer>
          <StatusText variant='body' filled={filled}>
            Code Status: {reservation?.code || 'None'}
          </StatusText>
        </StatusContainer>
        <ChipContainer>
          <AccessCode label={`Access Code:  ${reservation?.guest_access_code || 'None'}`} />
        </ChipContainer>
      </ContentContainer>
    </Widget>
  );
};

const ChipContainer = styled.div`
  max-width: 80%;
`;

const AccessCode = styled(Chip)`
  && {
    display: flex;
    background-color: #0036b2;
    color: ${props => props.theme.textColorWhite};
    font-size: 18px;
    height: 48px;
    justify-content: center;
  }
`;

const VDivider = styled(Divider)`
  && {
    margin-right: 24px;
    color: ${props => (props.filled ? props.theme.textColorWhite : props.theme.textColors)};
  }
`;
const StatusText = styled(Typography)`
  && {
    color: ${props => (props.filled ? props.theme.textColorWhite : props.theme.textColors)};
    font-size: 14px;
    line-height: 20px;
  }
`;
const StatusContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const DatesContainer = styled.div`
  display: flex;
  margin-top: 20px;
  div {
    width: 50%;
  }
  height: 108px;
`;

const ContentContainer = styled(BaseContainer)``;

export default CodeWidget;
