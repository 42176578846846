import { Member, MemberJSON } from './types';

export const toJSON = (member: Member): Omit<MemberJSON, 'id'> => {
  return {
    name: member.name,
    email: member.email,
    contact_number: member.contactNumber,
    profile_picture_url: member.profilePictureUrl,
    job_title: member.jobTitle,
    role: member.role,
  };
};

const parseMember = (response: MemberJSON): Member => {
  return {
    id: response.id,
    name: response.name,
    email: response.email,
    contactNumber: response.contact_number,
    profilePictureUrl: response.profile_picture_url,
    jobTitle: response.job_title,
    role: response.role as Member['role'],
  };
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
export const parseMembers = (response: MemberJSON[]) => {
  const sorted = response.sort((a, b) => collator.compare(a.name, b.name));
  return sorted.map(parseMember);
};
