import { CircularProgress, IconButton } from '@mui/material';
import { trpc } from '@operto/trpc-client';
import PDFIcon from 'assets/img/icons/pdf.svg';
import { logger } from 'lib/logger';
import React, { useState } from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import TableTooltip from 'ui-library/Components/table/TableTooltip';

// https://developer.mozilla.org/en-US/docs/Web/API/Window/open
const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const useGuestTermsPreview = ({
  guestId,
  reservationId,
}: {
  guestId: string;
  reservationId: number;
}) => {
  const dispatch = useAppDispatch();
  const [isFetching, setFetching] = useState(false);
  const { refetch } = trpc.guests.getGuestTerms.useQuery(
    { guestId, reservationId },
    {
      enabled: false,
      retry(failureCount, error) {
        // retry if not unauth, forbidden or not found
        return failureCount < 3 && ![401, 403, 404].includes(error.data.httpStatus);
      },
    },
  );

  const fetchPdfUrl = async () => {
    setFetching(true);

    try {
      const response = await refetch();

      openInNewTab(response.data.url);
    } catch (error) {
      logger.error(error);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Error previewing PDF file. Please try again later',
          variant: SnackbarVariant.ERROR,
        }),
      );
    }

    setFetching(false);
  };

  return {
    isFetching,
    fetchPdfUrl,
  };
};

type GuestTermsCellProps = {
  guestId: string;
  reservationId: number;
  visible: boolean;
};

export const GuestTermsCell = ({ guestId, reservationId, visible }: GuestTermsCellProps) => {
  const { isFetching, fetchPdfUrl } = useGuestTermsPreview({ guestId, reservationId });

  if (!visible) {
    return null;
  }

  if (isFetching) {
    return <CircularProgress size={20} style={{ marginLeft: 10 }} />;
  }

  return (
    <TableTooltip title='Preview PDF file'>
      <IconButton color='primary' aria-label='pdf-download-button' onClick={fetchPdfUrl}>
        <PDFIcon />
      </IconButton>
    </TableTooltip>
  );
};
