import { GroupOutlined, HomeWorkOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { validateGroupNameHelper } from 'helper/groupNameHelper';
import { trackEvent } from 'lib/analytics';
import { logger } from 'lib/logger';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toggleSnackbar } from 'redux/actions/ui';
import { useGetGroupsQuery, useUpdateGroupDetailsMutation } from 'redux/groups/api-slice';
import { Group } from 'redux/groups/types';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { HoverableTextField } from 'ui-library/Components/input/HoverableTextField';
import TabBar from 'ui-library/Components/tabBar/TabBar';
import { validateTextLength } from 'ui-library/Helpers/textHelper';
import { GroupMembersTable } from './GroupMembersTable';
import { GroupUnitsTable } from './GroupUnitsTable';

const tabItems = [
  {
    label: 'Units',
    value: 'units',
    isDropDown: false,
    icon: <HomeWorkOutlined />,
  },
  {
    label: 'Members',
    value: 'members',
    isDropDown: false,
    icon: <GroupOutlined />,
  },
];

type GroupDetailsProps = {
  group: Group;
};

export const TAB_INDEX = {
  UNITS: 0,
  MEMBERS: 1,
};

const TAB_TYPE = {
  UNITS: 'units',
  MEMBERS: 'members',
};

export const GroupDetails = ({ group }: GroupDetailsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const lastPart = pathParts.pop();
  const dispatch = useAppDispatch();
  const [updateGroupDetails] = useUpdateGroupDetailsMutation();
  const { handleSubmit, control, setValue, reset } = useForm<Group>({
    mode: 'all',
    defaultValues: group,
  });

  const { data } = useGetGroupsQuery();

  const handleTabIndexChange = (value: number) => {
    if (value === TAB_INDEX.UNITS) {
      navigate(`/groups/${group.id}/units`);
    } else {
      navigate(`/groups/${group.id}/members`);
    }
  };

  const renderGroupDetailsTable = () => {
    switch (lastPart) {
      case TAB_TYPE.UNITS:
        return <GroupUnitsTable group={group} />;
      case TAB_TYPE.MEMBERS:
        return <GroupMembersTable group={group} />;
    }
  };

  const handleClose = (field: 'name' | 'description') => {
    setValue(field, group[field]);
  };

  const onSubmit = async (field: 'name' | 'description', data: Group) => {
    let success = true;
    try {
      const updatedFields: Partial<Group> = {};
      if (field === 'name') {
        updatedFields.name = data.name;
      }
      if (field === 'description') {
        updatedFields.description = data.description;
      }
      const updatedGroupDetails = {
        id: group.id,
        ...updatedFields,
      };
      await updateGroupDetails(updatedGroupDetails).unwrap();
      reset(updatedGroupDetails);
      trackEvent({
        event: 'EDITED',
        screen: 'GROUP DETAILS',
      });
    } catch (error) {
      success = false;
      logger.error(`Error updating group details: ${error}`);
    }

    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `${success ? 'Successfully updated' : 'Error updating'} group details`,
        variant: success ? SnackbarVariant.SUCCESS : SnackbarVariant.ERROR,
      }),
    );
  };

  useEffect(() => {
    setValue('name', group.name);
  }, [group.name, setValue]);

  useEffect(() => {
    setValue('description', group.description);
  }, [group.description, setValue]);

  return (
    <Box sx={groupDetailsContainerStyle}>
      <Stack sx={headerContainerStyle} spacing={1}>
        <HoverableTextField
          field='name'
          rules={{
            required: {
              value: true,
              message: 'Group name is required',
            },
            validate: (text: string) => validateGroupNameHelper(text, data, group.name),
          }}
          control={control}
          text={group.name}
          onClose={() => handleClose('name')}
          onSave={handleSubmit(data => onSubmit('name', data))}
          size='medium'
        />
        <HoverableTextField
          field='description'
          rules={{
            validate: (text: string) => validateTextLength(text, 64),
          }}
          control={control}
          text={group.description}
          onClose={() => handleClose('description')}
          onSave={handleSubmit(data => onSubmit('description', data))}
        />
      </Stack>
      <TabBar
        tabItems={tabItems}
        tabIndex={lastPart === TAB_TYPE.UNITS ? TAB_INDEX.UNITS : TAB_INDEX.MEMBERS}
        onTabChange={handleTabIndexChange}
      />
      {renderGroupDetailsTable()}
    </Box>
  );
};

const groupDetailsContainerStyle = {
  padding: '16px',
  minHeight: '100%',
  height: '100%',
  width: '100%',
};

const headerContainerStyle = {
  paddingBottom: '16px',
  direction: 'column',
  paddingTop: '8px',
};
