import Occupancy from '@mui/icons-material/PeopleOutlined';
import {
  OCCUPANCY_STATUSES,
  TOccupancyStatuses,
} from 'Common/Tables/Properties/PropertyDataHelpers';
import React from 'react';
import { RowCellTableTooltip } from 'ui-library/Components/table/TableTooltip';

export interface OccupancyCellProps {
  occupancyLevel: TOccupancyStatuses;
}

export type OccupancyCellTooltipText = 'Low risk' | 'High risk';

const renderOccupancyCellToolTip = (
  noiseRisk: OccupancyCellTooltipText,
  occupancyIcon: React.ReactElement,
) => {
  return <RowCellTableTooltip title={noiseRisk}>{occupancyIcon}</RowCellTableTooltip>;
};

const OccupancyCell = ({ occupancyLevel }: OccupancyCellProps) => {
  switch (occupancyLevel) {
    case OCCUPANCY_STATUSES.LOW_RISK:
      return renderOccupancyCellToolTip('Low risk', <Occupancy color='primary' />);

    case OCCUPANCY_STATUSES.MED_RISK:
    case OCCUPANCY_STATUSES.HIGH_RISK:
      return renderOccupancyCellToolTip('High risk', <Occupancy color='error' />);

    default:
      return <Occupancy />;
  }
};
export default OccupancyCell;
