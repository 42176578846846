import { Grid, Stack, Typography } from '@mui/material';
import { GridColDef, GridRowParams, GridRowsProp } from '@mui/x-data-grid-pro';
import { DataGrid } from '@operto/ui';
import React from 'react';
import { IEntranceInstructionsProperty } from 'redux/guestportal';

interface EntranceInstructionsFormTabUnitsProps {
  data: IEntranceInstructionsProperty[];
  onChange: (propertyIds: number[]) => void;
}

const EntranceInstructionsFormTabUnits = ({
  data,
  onChange,
}: EntranceInstructionsFormTabUnitsProps) => {
  const title = 'Activate / Deactivate Units';

  const isPropertyInitialized = (property: IEntranceInstructionsProperty) => {
    return property?.guest_portal_initialized;
  };

  const columnsDef: GridColDef[] = [
    {
      field: 'units',
      headerName: 'Units',
      flex: 1.5,
      sortable: false,
      renderCell: params => renderCellText(params.value, params.row.enabled),
    },
    {
      field: 'id',
      headerName: 'Property ID',
      flex: 1.5,
      sortable: false,
      renderCell: params => renderCellText(params.value, params.row.enabled),
    },
  ];

  const renderCellText = (text: string, enabled: boolean) => (
    <Typography
      variant='body2'
      sx={{
        color: enabled ? 'inherit' : 'text.disabled',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {text}
    </Typography>
  );

  const handleSelect = (newSelection: number[]) => {
    onChange(newSelection);
  };

  const rows: GridRowsProp = data.map(property => ({
    id: property.propertyId,
    units: property.name,
    checked: property.entrance_instructions_enabled,
    enabled: isPropertyInitialized(property),
  }));

  const titleToolbar = () => (
    <Stack
      sx={{
        padding: '12px 16px',
        justifyContent: 'flex-start',
        height: '40px',
        borderBottom: 1,
        borderColor: theme => theme.palette.divider,
      }}
      direction='row'
      alignItems='center'
    >
      <Typography variant='subtitle1'>{title}</Typography>
    </Stack>
  );

  return (
    <Grid container justifyContent='space-between' sx={{ padding: '24px', height: '100%' }}>
      <Grid item sx={{ height: '100%' }}>
        <DataGrid
          title={title}
          initialState={{ pinnedColumns: { right: ['action'] } }}
          loading={false}
          rows={rows}
          rowCount={rows.length}
          columns={columnsDef}
          disableColumnMenu
          checkboxSelection
          selectionModel={data
            .filter(property => property.entrance_instructions_enabled === true)
            .map(property => property.propertyId)}
          isRowSelectable={(params: GridRowParams) => params.row.enabled}
          keepNonExistentRowsSelected
          onSelectionModelChange={handleSelect}
          components={{ Toolbar: titleToolbar }}
          hideToolbar
          hideFooter
          sx={{
            width: '684px',
            height: '864px',
            boxShadow: 2,
            borderRadius: 2,
            backgroundColor: 'background.paper',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default EntranceInstructionsFormTabUnits;
