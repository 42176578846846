import AddIcon from '@mui/icons-material/Add';
import {
  Chip,
  createTheme,
  Fab,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { trpc } from '@operto/trpc-client';
import { TableActionbar } from '@operto/ui-library';
import { Variable } from '@operto/variables-shared';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import { trackEvent } from 'lib/analytics';
import { logger } from 'lib/logger';
import isEqual from 'lodash/isEqual';
import { getProperties } from 'property/state/propertyActions';
import { getAllPropertiesIdsWithNamesSelector } from 'property/state/propertySelectors';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { Table } from 'ui-library/Components/table/Table';
import { currentUserSelector, userPermissionSelector } from 'user/state/userSelectors';
import { CustomTooltip } from '../Common/CustomTooltip';
import VariablesMoreInfoTable from '../Common/VariablesMoreInfoTable';
import VariableTableActions from '../Common/VariableTableActions';
import { downloadCustomVariableCSV } from '../helpers/CSVExportHelper';
import {
  generateDuplicateName,
  getAllVariableNamesList,
  getAllVariables,
  getCustomVariableById,
  getPropertiesForCustomVariablesAsList,
  getTotalShownVariablePropertiesCount,
  hasVariableSomeEmptyValuesForProperties,
} from '../helpers/VariableHelpers';

export const renderTextWithHover = (text: string, isWrapped = false) => (
  <CustomTooltip
    data-testid='custom-tooltip'
    title={text}
    placement='bottom'
    leaveDelay={100}
    followCursor
    noWrap={isWrapped}
  >
    <Typography data-testid='tooltip-text' fontSize={14}>
      {text?.length > 0 ? text : '-'}
    </Typography>
  </CustomTooltip>
);

export const newVariableTemplateWithoutId = (username = '', override?: Partial<Variable>) =>
  Object({
    name: '',
    updatedAt: Date.now().valueOf(),
    createdAt: Date.now().valueOf(),
    updatedBy: username,
    description: '',
    enabled: true,
    properties: {},
    type: 'custom',
    ...override,
  }) as Variable;

const CustomVariablePage = () => {
  const MAX_VARIABLE_NAME_LENGTH = 40;
  const DATE_VALUE_TO_UNIX = 1000;

  const [openMode, setOpenMode] = useState(false);
  const [chosenVariableOnOpenMode, setChosenVariableOnOpenMode] = useState<Variable>();
  const [moreInfoRows, setMoreInfoRows] = useState([]);
  const [newVariableName, setNewVariableName] = useState('');
  const [addAnchorEl, setAddAnchorEl] = useState(null);
  const [isAddVariableModalMode, setIsAddVariableModalMode] = useState(false);
  const [isDeleteVariableModalMode, setIsDeleteVariableModalMode] = useState(false);
  const [isChangeStatusModalMode, setIsChangeStatusModalMode] = useState(false);
  const [isVariableExist, setIsVariableExist] = useState(false);
  const [selectedVariableId, setSelectedVariableId] = useState();
  const dispatch = useAppDispatch();

  const {
    data: customVariables,
    isLoading: isCustomLoading,
    isError: isCustomError,
    error: customError,
  } = trpc.variables.getVariables.useQuery({
    type: 'custom',
  });

  const {
    data: systemVariables,
    isLoading: isSystemLoading,
    isError: isSystemError,
    error: systemError,
  } = trpc.variables.getVariables.useQuery({ type: 'system' });

  const createVariableTRPC = trpc.variables.createVariable.useMutation();
  const deleteVariableTRPC = trpc.variables.deleteVariable.useMutation();
  const updateVariableTRPC = trpc.variables.updateVariable.useMutation();
  const variableUtils = trpc.useUtils();

  const createVariableMutator = (variable: Variable) => {
    createVariableTRPC.mutate(
      { ...variable, updatedBy: currentUser?.name },
      {
        onSuccess: () => {
          variableUtils.variables.getVariables.refetch({ type: 'custom' });
        },
        onSettled: () => {
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Variable created successfully.',
              variant: SnackbarVariant.SUCCESS,
            }),
          );
        },
        onError: error => {
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Variable has not been created due to an error.',
              variant: SnackbarVariant.ERROR,
            }),
          );
          logger.error(error);
        },
      },
    );
  };

  const updateVariableMutator = (variable: Variable) =>
    updateVariableTRPC.mutate(
      { ...variable, updatedBy: currentUser?.name },
      {
        onSuccess: () => {
          variableUtils.variables.getVariables.refetch({ type: 'custom' });
        },
        onSettled: () => {
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Variable updated successfully.',
              variant: SnackbarVariant.SUCCESS,
            }),
          );
        },
        onError: error => {
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Variable has not been updated due to an error.',
              variant: SnackbarVariant.ERROR,
            }),
          );
          logger.error(error);
        },
      },
    );

  const isUserManager = useSelector(userPermissionSelector());
  const currentUser = useSelector(currentUserSelector());

  useEffect(() => {
    // to make sure that the chosen variable to edit(open mode) is updated when the variables are updated
    const realVariable = customVariables?.find(
      (variable: Variable) => variable.id === chosenVariableOnOpenMode?.id,
    );
    if (realVariable && !isEqual(realVariable, chosenVariableOnOpenMode)) {
      setChosenVariableOnOpenMode(realVariable);
    }
  }, [customVariables, chosenVariableOnOpenMode]);

  // TODO: VARIABLES --> this line all the way to next comment should be removed after getting all properties from the backend
  useEffect(() => {
    const urlSearchParams = new URLSearchParams();
    dispatch(getProperties({ urlSearchParams }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCustomError) logger.error(customError);
  }, [isCustomError, customError]);

  useEffect(() => {
    if (isSystemError) logger.error(systemError);
  }, [isSystemError, systemError]);

  const propertiesIdWithNames = useSelector(getAllPropertiesIdsWithNamesSelector());

  const tailoredPropertiesForVariable = () => {
    const tailoredProperties = newVariableTemplateWithoutId().properties;

    propertiesIdWithNames.forEach(propertyIdAndName => {
      tailoredProperties[propertyIdAndName.id] = {
        id: propertyIdAndName.id,
        name: propertyIdAndName.name,
        value: '',
        enabled: true,
        group: '',
      };
    });
    return tailoredProperties;
  };
  // end of removable code for properties in variable. remove until here when backend is done.

  const renderVariableName = (params: GridRenderCellParams) => {
    const theme = createTheme({
      palette: {
        primary: {
          main: '#0036B2',
        },
        secondary: {
          main: '#BDBDBD',
        },
        warning: {
          main: '#FF9800',
        },
        error: {
          main: '#F44336',
        },
        info: {
          main: '#2196F3',
        },
      },
    });
    const renderIcon = () => {
      if (!params.row.enabled) {
        return (
          <Chip
            data-testid='deactive-chip'
            label={'Deactivate'}
            color='secondary'
            variant='outlined'
            size='small'
          />
        );
      } else if (hasVariableSomeEmptyValuesForProperties([params.row])) {
        return (
          <Chip
            data-testid='missing-chip'
            label={'Missing Value'}
            color='warning'
            variant='outlined'
            size='small'
          />
        );
      }
    };
    return (
      <>
        <Typography
          variant='body2'
          style={{
            textTransform: 'capitalize',
            margin: 0,
            marginRight: '8px',
          }}
          noWrap
        >
          {params.row.name}
        </Typography>
        <ThemeProvider theme={theme}>{renderIcon()}</ThemeProvider>
      </>
    );
  };

  const renderEnabledUnits = (params: GridRenderCellParams) => {
    return (
      <Typography variant='body2' style={{ textTransform: 'capitalize', margin: 0 }}>
        {getTotalShownVariablePropertiesCount(params.row)}/
        {Object.keys(tailoredPropertiesForVariable()).length} Unit
      </Typography>
    );
  };

  const renderMainActions = (params: GridRenderCellParams) => {
    const selectedCustomVariable = getCustomVariableById(params.row.id, customVariables);

    const onEdit = () => {
      setChosenVariableOnOpenMode(selectedCustomVariable);
      setMoreInfoRows(mixVariablePropertiesWithRealProperties(params.row.id));
      setOpenMode(true);
    };

    const onDuplicate = () => {
      const selectedCustomVariableCopy = { ...selectedCustomVariable };
      delete selectedCustomVariableCopy.id;
      const allVariableNames = getAllVariableNamesList(customVariables);

      createVariableMutator({
        ...selectedCustomVariableCopy,
        name: generateDuplicateName(selectedCustomVariableCopy.name, allVariableNames),
      });
    };

    return (
      <VariableTableActions
        data-testid='custom-variable-table-actions'
        variable={selectedCustomVariable}
        onEdit={onEdit}
        onDuplicate={onDuplicate}
        onExport={() => {
          downloadCustomVariableCSV(selectedCustomVariable);
        }}
        onChangeStatus={(variableStatusToUpdate: boolean) => {
          setChosenVariableOnOpenMode(selectedCustomVariable);

          if (!variableStatusToUpdate) {
            setIsChangeStatusModalMode(true);
          } else {
            updateVariableMutator({ ...selectedCustomVariable, enabled: variableStatusToUpdate });
          }
        }}
        onDelete={() => {
          setSelectedVariableId(params.row.id);
          setIsDeleteVariableModalMode(true);
        }}
      />
    );
  };

  const columnsDef: GridColDef[] = openMode
    ? [
        {
          field: 'name',
          headerName: 'Variable Name',
          flex: 3,
          sortable: false,
          renderCell: renderVariableName,
        },
      ]
    : [
        {
          field: 'name',
          headerName: 'Variable Name',
          flex: 3,
          sortable: false,
          renderCell: renderVariableName,
        },
        {
          field: 'enabledUnits',
          headerName: 'Values Displayed',
          flex: 3,
          sortable: false,
          renderCell: renderEnabledUnits,
        },
        {
          field: 'description',
          headerName: 'Description',
          flex: 5,
          sortable: false,
          renderCell: params => renderTextWithHover(params.row.description, true),
        },
        {
          field: 'updatedAt',
          headerName: 'Last Updated',
          flex: 2,
          sortable: false,
          renderCell: params =>
            new Date(params.row.updatedAt * DATE_VALUE_TO_UNIX).toLocaleDateString(),
        },
        {
          field: 'updatedBy',
          headerName: 'Last Updated By',
          flex: 2,
          sortable: false,
        },
        {
          field: 'actions',
          headerName: 'Actions',
          flex: 1,
          sortable: false,
          renderCell: renderMainActions,
          disableColumnMenu: true,
          hide: !isUserManager && !openMode,
        },
      ];

  const mixVariablePropertiesWithRealProperties = (variableId: string) => {
    const variableProperties = getPropertiesForCustomVariablesAsList(variableId, customVariables);
    const propertiesInVariableCount = Object.keys(variableProperties).length;
    const propertiesCount = propertiesIdWithNames.length;
    if (propertiesCount > 0 && propertiesInVariableCount !== propertiesCount) {
      // add the properties that are in propertiesIdWithNames but not in the variable
      const newRows = { ...variableProperties };
      propertiesIdWithNames.forEach(propertyIdObject => {
        if (!newRows[propertyIdObject.id]) {
          newRows[propertyIdObject.id] = {
            name: propertyIdObject.name,
            id: propertyIdObject.id,
            value: '',
            group: '',
            enabled: false,
          };
        }
      });
      return newRows;
    }
    return variableProperties;
  };

  // handlers
  const onMoreInfoMode = (variableId: string) => {
    setChosenVariableOnOpenMode(getCustomVariableById(variableId, customVariables));
    setMoreInfoRows(mixVariablePropertiesWithRealProperties(variableId));
    setOpenMode(true);
  };

  const handleAddVariable = () => {
    setIsAddVariableModalMode(true);
  };

  const handleAddVariableSubmit = async () => {
    createVariableMutator(
      newVariableTemplateWithoutId(currentUser?.name, {
        name: newVariableName,
        properties: tailoredPropertiesForVariable(),
      }),
    );

    trackEvent({
      screen: 'Variables',
      event: 'CREATED',
      environment: 'settings',
    });
    variableUtils.variables.getVariables.refetch({ type: 'custom' });

    setNewVariableName('');
    setAddAnchorEl(null);
    setIsAddVariableModalMode(false);
  };

  const handleDeleteVariableConfirm = () => {
    if (selectedVariableId) {
      deleteVariableTRPC.mutate(
        { id: selectedVariableId },
        {
          onSuccess: () => {
            variableUtils.variables.getVariables.refetch({ type: 'custom' });
          },
          onSettled: () => {
            dispatch(
              toggleSnackbar(SnackbarTypes.OPEN, {
                message: 'Variable deleted successfully.',
                variant: SnackbarVariant.SUCCESS,
              }),
            );
          },
          onError: error => {
            dispatch(
              toggleSnackbar(SnackbarTypes.OPEN, {
                message: 'Variable has not been deleted due to an error.',
                variant: SnackbarVariant.ERROR,
              }),
            );
            logger.error(error);
          },
        },
      );
    }
    setIsDeleteVariableModalMode(false);
  };

  const handleChangeStatusVariableSubmit = () => {
    updateVariableMutator({ ...chosenVariableOnOpenMode, enabled: false });
    setIsChangeStatusModalMode(false);
  };

  if (isCustomLoading || isSystemLoading) {
    return null;
  }

  return (
    <>
      <ConfirmDialog
        data-testid='add-variable-modal'
        open={isAddVariableModalMode}
        title='Enter New Variable Name'
        submitButtonColor='primary'
        onSubmit={handleAddVariableSubmit}
        onClose={() => {
          setIsAddVariableModalMode(false);
          setAddAnchorEl(null);
          setNewVariableName('');
        }}
        submitButtonText='Save'
      >
        <TextField
          data-testid='add-variable-input'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newVarName = event.target.value;
            if (
              newVarName === '' ||
              (newVarName && newVarName.length <= MAX_VARIABLE_NAME_LENGTH)
            ) {
              if (
                getAllVariables(customVariables, systemVariables).find(
                  variable => variable.name.toLowerCase() === newVarName.toLowerCase().trim(),
                )
              ) {
                if (!isVariableExist) setIsVariableExist(true);
              } else {
                if (isVariableExist) setIsVariableExist(false);
              }
              setNewVariableName(newVarName);
            }
          }}
          value={newVariableName}
          label='Variable Name'
          sx={{ width: '97%' }}
          error={isVariableExist}
          helperText={
            isVariableExist
              ? 'Variable already exist.'
              : `Character Limit: ${newVariableName.length}/${MAX_VARIABLE_NAME_LENGTH}`
          }
        />
      </ConfirmDialog>
      <ConfirmDialog
        data-testid='delete-variable-modal'
        open={isDeleteVariableModalMode}
        title='Delete'
        submitButtonColor='error'
        onSubmit={handleDeleteVariableConfirm}
        submitButtonText='Delete'
        onClose={() => {
          setIsDeleteVariableModalMode(false);
        }}
      >
        <Typography>Are you sure you want to delete this variable?</Typography>
      </ConfirmDialog>
      <ConfirmDialog
        data-testid='deactive-variable-modal'
        open={isChangeStatusModalMode}
        title='Deactivate and Hide value'
        submitButtonColor='primary'
        onSubmit={handleChangeStatusVariableSubmit}
        onClose={() => {
          setIsChangeStatusModalMode(false);
        }}
        submitButtonText='confirm'
      >
        <Typography>
          Are you sure that you want to deactivate and hide this variable? Your will not be able to
          add this variable when you are creating the contents. If you already use this variable,
          the value would be hidden.
        </Typography>
      </ConfirmDialog>
      <TableActionbar
        startSection={<></>}
        endSection={
          <Fab
            data-testid='add-variable-button'
            size='small'
            color='primary'
            onClick={e => {
              setAddAnchorEl(e.currentTarget);
            }}
            key='fab'
          >
            <AddIcon />
          </Fab>
        }
        isMobile={false}
      />

      <Menu
        anchorEl={addAnchorEl}
        open={Boolean(addAnchorEl)}
        onClose={() => {
          setAddAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem data-testid='add-custom-variable-menu-item' onClick={handleAddVariable}>
          <ListItemText>Add Custom Variable</ListItemText>
        </MenuItem>
      </Menu>
      <Stack direction='row' spacing={0}>
        <Box
          sx={{ padding: 0, margin: 0, height: 400, width: openMode ? '40%' : '100%' }}
          data-testid='custom-variable-table'
        >
          <Table
            disableColumnMenu
            disableVirtualization
            hideFooterRowCount
            sx={
              openMode
                ? {
                    borderRight: 0,
                    borderBottomRightRadius: 0,
                    borderTop: 0,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 10,
                  }
                : {
                    borderTopRightRadius: 0,
                    borderTop: 0,
                    borderTopLeftRadius: 0,
                    width: '100%',
                    margin: 0,
                  }
            }
            rows={customVariables}
            columns={columnsDef}
            onCellClick={e => {
              if (e.field !== 'actions') {
                if (e.row.id === chosenVariableOnOpenMode?.id) {
                  setOpenMode(false);
                  setChosenVariableOnOpenMode(null);
                } else {
                  onMoreInfoMode(e.row.id);
                }
              }
            }}
          />
        </Box>
        {openMode && (
          <VariablesMoreInfoTable
            data-testid='more-info-table'
            variable={chosenVariableOnOpenMode}
            rows={moreInfoRows}
            isEditable={isUserManager}
            onCloseMoreTable={() => {
              setOpenMode(false);
              setChosenVariableOnOpenMode(null);
            }}
            systemVariables={systemVariables}
            customVariables={customVariables}
          />
        )}
      </Stack>
    </>
  );
};

export default CustomVariablePage;
