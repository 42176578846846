import { createSelector } from '@reduxjs/toolkit';
import { IntercomState } from 'intercom/intercomType';
import { ApplicationState } from 'redux/reducers';

const getIntercom = (state: ApplicationState) => state.intercom;

export const IntercomByPropertyIdSelector = (propertyId: number) => {
  return createSelector(
    [getIntercom],
    (intercom: IntercomState) => intercom && intercom?.byPropertyIds[propertyId],
  );
};
