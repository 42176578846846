import { Box, Stack } from '@mui/material';
import RightSideTopbar from 'Common/Slidebar/components/RightSideTopbar';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGuestPortal } from 'redux/guestportal';
import { PreviewDevice } from './PreviewDevice';
import { PreviewQRCode } from './PreviewQRCode';
import { PreviewSearchList } from './PreviewSearchList';

export interface GPPreviewContainerProps {
  globalPropertyId?: string;
  onClose?: () => void;
  isEntranceInstructions?: boolean;
}

const GPPreviewContainer = ({
  globalPropertyId,
  onClose,
  isEntranceInstructions,
}: GPPreviewContainerProps) => {
  const { generateGPPreview, fetchAssignedProperties, gpLink, assignedPropertiesRecords } =
    useGuestPortal();
  const url = gpLink?.url ? `${gpLink?.url}/?r=true` : '';
  const { propertyId } = useParams<{ propertyId?: string }>();

  const handlePropertyClick = useCallback(
    (propertyId: string) => {
      generateGPPreview({ property_id: Number(propertyId), skip_welcome_screens: true });
    },
    [generateGPPreview],
  );

  useEffect(() => {
    fetchAssignedProperties({
      newPage: 0,
      // We are using a high number here to ensure that all properties are fetched, we need to return it to 100 when we have pagination
      // working with the API
      numPerPage: 10000,
      append: true,
    });
  }, [fetchAssignedProperties]);

  useEffect(() => {
    if (assignedPropertiesRecords.length) {
      handlePropertyClick(
        propertyId ?? globalPropertyId ?? `${assignedPropertiesRecords?.[0]?.propertyId ?? '0'}`,
      );
    }
  }, [propertyId, globalPropertyId, handlePropertyClick, assignedPropertiesRecords]);

  return (
    <Box data-testid='gp-preview-container'>
      {onClose && <RightSideTopbar title={'Preview'} handleClose={onClose} />}
      <Stack direction='row' p={3} spacing={3} bgcolor='#F6F7FB'>
        <PreviewSearchList
          propertyId={propertyId}
          properties={assignedPropertiesRecords}
          onSelect={handlePropertyClick}
          isEntranceInstructions={isEntranceInstructions}
        />
        <PreviewDevice url={url} />

        <PreviewQRCode
          url={url}
          name={gpLink?.name}
          checkIn={gpLink?.check_in}
          checkOut={gpLink?.check_out}
        />
      </Stack>
    </Box>
  );
};

export default GPPreviewContainer;
