import { Grid, Slider } from '@mui/material';
import * as React from 'react';
import RadialBar from 'ui-library/Components/canvas/RadialBar';

interface Mark {
  value: number;
  label?: React.ReactNode;
}
export interface RadialBarSingleSliderProps {
  fromValue: number;
  upperRightText: string;
  lowerText: string;
  primaryColor: string;
  baseColor: string;
  onChange: (newTemp: number) => void;
  marks: Mark[];
  valueText: string;
  onUpdate: (newTemp: number) => void;
  upperLeftTextOne: string;
  max: number;
}

const RadialBarSingleSlider = ({
  fromValue,
  upperRightText,
  lowerText,
  primaryColor,
  baseColor,
  marks,
  onChange,
  valueText,
  onUpdate,
  upperLeftTextOne,
  max,
}: RadialBarSingleSliderProps) => {
  const changeTemperature = (_event: unknown, newValue: number) => {
    onChange(newValue);
  };

  const updateValue = (event: Event, value: number) => {
    onUpdate(value);
  };

  const [optionsOne, setOptionsOne] = React.useState({
    value: (fromValue * 100) / max,
    upperRightText,
    lowerText,
    upperLeftTextOne,
  });

  React.useEffect(() => {
    setOptionsOne({
      value: (fromValue * 100) / max,
      upperRightText,
      lowerText,
      upperLeftTextOne,
    });
  }, [
    fromValue,
    upperRightText,
    lowerText,
    primaryColor,
    baseColor,
    marks,
    valueText,
    upperLeftTextOne,
    max,
  ]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <RadialBar optionsOne={optionsOne} primaryColor={primaryColor} baseColor={baseColor} />
      </Grid>
      <Grid item xs={12}>
        <Slider
          defaultValue={fromValue}
          aria-labelledby='single-slider'
          valueLabelDisplay='off'
          marks={marks}
          onChange={changeTemperature}
          onChangeCommitted={updateValue}
          max={max}
        />
      </Grid>
    </Grid>
  );
};

export default RadialBarSingleSlider;
