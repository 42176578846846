import { Add, LinearScale } from '@mui/icons-material';
import {
  TitlebarBreadCrumb,
  TitlebarBreadCrumbChild,
  TitlebarButtonExpanded,
  TitlebarRoot,
  TitlebarTitleIcon,
} from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function AutomateTitlebar({ displayAddButton }: { displayAddButton?: boolean }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const crumbs: TitlebarBreadCrumbChild[] = [
    {
      name: t('home'),
      href: '/',
    },
    {
      name: t('automate'),
    },
  ];

  const onCreateFlowClick = () => {
    navigate('/automate/create');
  };

  return (
    <TitlebarRoot>
      <TitlebarTitleIcon>
        <LinearScale />
      </TitlebarTitleIcon>

      <TitlebarBreadCrumb crumbs={crumbs} onClick={crumb => navigate(crumb.href)} />

      {displayAddButton && (
        <TitlebarButtonExpanded onClick={onCreateFlowClick}>
          <Add />
          {t('create_flow')}
        </TitlebarButtonExpanded>
      )}
    </TitlebarRoot>
  );
}
