import { Reducer, combineReducers } from '@reduxjs/toolkit';
import { Actions } from 'types/actions';
import {
  INotificationDetails,
  IPropertyNotificationsHR,
  Thresholds,
  ThresholdState,
} from '../thresholdType';

const thresholdsByPropertyId: Reducer<IPropertyNotificationsHR> = (state = {}, action) => {
  switch (action.type) {
    case Actions.hydrateThresholds:
      return action.propertySpecificNotificationsHR;
    case Actions.updateThresholdNotification:
      return {
        ...state,
        [action.propertyId]: {
          ...state[action.propertyId],
          [action.notificationType]: action.notification,
        },
      };
    default:
      return state;
  }
};

const thresholdsByDeviceId: Reducer<IPropertyNotificationsHR> = (state = {}, action) => {
  switch (action.type) {
    case Actions.hydrateThresholds:
      return action.thresholdByDeviceId;
    default:
      return state;
  }
};

const thresholdUpdateStatus: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.beginThresholdUpdate:
      return 'updating';
    case Actions.thresholdUpdateSuccess:
      return 'success';
    case Actions.thresholdUpdateFailed:
      return 'failed';
    case Actions.endThresholdUpdate:
      return '';
    default:
      return state;
  }
};

const tempHigh: Reducer<INotificationDetails> = (state = { id: 0, value: 23.9 }, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
const tempLow: Reducer<INotificationDetails> = (state = { id: 0, value: 18.3 }, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
const humidityHigh: Reducer<INotificationDetails> = (state = { id: 0, value: 70 }, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
const humidityLow: Reducer<INotificationDetails> = (state = { id: 0, value: 30 }, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const co2High: Reducer<INotificationDetails> = (state = { id: 0, value: 2000 }, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
const co2Med: Reducer<INotificationDetails> = (state = { id: 0, value: 1000 }, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
const noiseHigh: Reducer<INotificationDetails> = (state = { id: 0, value: 70 }, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
const noiseMed: Reducer<INotificationDetails> = (state = { id: 0, value: 60 }, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const thresholds: Reducer<Thresholds> = combineReducers<Thresholds>({
  tempHigh,
  tempLow,
  humidityHigh,
  humidityLow,
  co2High,
  co2Med,
  noiseHigh,
  noiseMed,
});

const reducer: Reducer<ThresholdState> = combineReducers<ThresholdState>({
  thresholdsByPropertyId,
  thresholdsByDeviceId,
  thresholds,
  thresholdUpdateStatus,
});

export default reducer;
