import { IDevices } from '../../types/properties';

export const getDeviceName = (device: IDevices) => {
  switch (device.device_name) {
    case 'Intercom':
      return 'Intercom';
    case 'SmartLock':
      return `Smart Lock: ${device.name}`;
    case 'Netatmo':
    case 'NoiseAware':
      return `Environment: ${device.name}`;
    case 'Tado':
    case 'EcoBee':
      return `Thermostat: ${device.name}`;
    case 'Tado-Air-Conditioning':
      return `Tado Air Conditioning: \n${device.name}`;
    case 'Tado-Heating':
      return `Tado Heating: \n${device.name}`;
  }
};

export const limitLength = (length: number, stringToLimit?: string) => {
  if (!stringToLimit) {
    return '';
  }
  if (stringToLimit.length > length) {
    return stringToLimit.substr(0, length);
  }
  return stringToLimit;
};
