import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { LinkedAccountSelectorItem } from 'Common/DropDown/LinkedAccountCompanySelector';
import GlobalSnackbar from 'Common/Snackbar/GlobalSnackbar';
import { login } from 'api/userAPI';
import {
  LinkedAccountData,
  addOrReplaceMultiAuthByEmail,
  checkForTokenExpiry,
  getLinkedAccounts,
  logoutAccount,
  setActiveAuthAccount,
} from 'auth/LinkedAccountsHelper';
import { AxiosResponse } from 'axios';
import { streamChatClient } from 'helper/streamChatHelper';
import { useSharedLocalStorageReceiver } from 'hooks/useSharedLocalStorageReceiver';
import { trackEvent } from 'lib/analytics';
import * as React from 'react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { toggleSnackbar } from 'redux/actions/ui';
import * as userActions from 'redux/actions/users';
import { useAppDispatch } from 'redux/hooks';
import styled from 'styled-components';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import AccessContainer from './AccessContainer';

enum LoginState {
  CHOOSE_ACCOUNT = 'CHOOSE_ACCOUNT',
  USE_ANOTHER_ACCOUNT = 'USE_ANOTHER_ACCOUNT',
  ENTER_PASSWORD = 'ENTER_PASSWORD',
}

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [loginBtnLabel, setloginBtnLabel] = useState('Next');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loginState, setLoginState] = useState<LoginState>(LoginState.CHOOSE_ACCOUNT);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { search } = useLocation();
  const errorParams = new URLSearchParams(search).get('error');

  const linkedAccounts: LinkedAccountData[] = getLinkedAccounts().sort((val: LinkedAccountData) => {
    return val.isLoggedIn ? -1 : 1;
  });
  const params = new URLSearchParams(window.location.search);
  const emailSearchParam = params.get('acct');
  const initialAccount = emailSearchParam
    ? linkedAccounts.find(val => val.email === emailSearchParam)
    : linkedAccounts[0];
  const [selectedAccount, setSelectedAccount] = useState<LinkedAccountData>(initialAccount);
  React.useEffect(() => {
    streamChatClient.disconnectUser();
    checkForTokenExpiry();
    const params = new URLSearchParams(window.location.search);
    const emailSearchParam = params.get('email');
    setEmail(emailSearchParam || '');
  }, []);

  const validateInputs = () => {
    let isValid = true;
    if (email.length < 1) {
      setEmailError('Email too short');
      isValid = false;
    } else {
      setEmailError('');
    }
    if (password.length < 1) {
      setPasswordError('Password too short');
      isValid = false;
    } else {
      setPasswordError('');
    }
    return isValid;
  };

  const handleSubmit = async () => {
    const acct = getLinkedAccounts().find(val => val.email === selectedAccount?.email);
    if (acct && loginState === LoginState.CHOOSE_ACCOUNT) {
      if (!acct.expired || acct.isLoggedIn) {
        setButtonDisabled(true);
        setloginBtnLabel('Please Wait...');
        setActiveAuthAccount(acct.email); //update local storage
        await dispatch(userActions.storeToken(acct.token)); //update redux store
        navigate('/'); //redirect to home page

        trackEvent({
          screen: 'Login',
          event: 'SUBMITTED',
        });
      } else {
        setLoginState(LoginState.ENTER_PASSWORD);
      }
    }

    if (loginState === LoginState.USE_ANOTHER_ACCOUNT && !validateInputs()) return;

    if (loginState === LoginState.USE_ANOTHER_ACCOUNT || loginState === LoginState.ENTER_PASSWORD) {
      setButtonDisabled(true);
      setloginBtnLabel('Please Wait...');

      const loginEmail = loginState === LoginState.USE_ANOTHER_ACCOUNT ? email : acct?.email;

      try {
        const loginResponse: AxiosResponse = await login(loginEmail, password);
        const authToken = loginResponse.data.data.access_token;
        await dispatch(userActions.storeToken(authToken));

        // ----------------------------------------
        // update linked accounts
        logoutAccount(); // logout existing linked account
        addOrReplaceMultiAuthByEmail(loginEmail, {
          isLoggedIn: true,
          token: authToken,
          name: 'Unknown',
          expired: false,
          email: loginEmail,
          cid: '',
        });

        // ----------------------------------------

        const params = new URLSearchParams(window.location.search);
        const propertyId = params.get('property_id');
        const propertyDetailView = params.get('property_detail');
        let url = '/';
        if (propertyId && propertyDetailView === 'monitor') {
          url = `/?property_detail=monitor&property_id=${propertyId}`;
        }
        navigate(url);
        setButtonDisabled(false);
        setloginBtnLabel('Next');
      } catch (error) {
        setButtonDisabled(false);
        setloginBtnLabel('Next');
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Login Error: Please check your credentials.',
            variant: SnackbarVariant.ERROR,
          }),
        );
      }
    }
  };

  const handleForgotPassword = () => {
    const url = '/forgot';
    navigate(url);
  };

  const useAnotherAccountClick = () => {
    setLoginState(LoginState.USE_ANOTHER_ACCOUNT);
    setloginBtnLabel('Submit');
  };

  const handleAccountClicked = async (linkedAccount: LinkedAccountData) => {
    setloginBtnLabel('Next');
    setSelectedAccount(linkedAccount);
    setEmail(linkedAccount.email);
  };

  const handleBackClick = () => {
    setloginBtnLabel('Next');
    setLoginState(LoginState.CHOOSE_ACCOUNT);
  };

  useSharedLocalStorageReceiver();

  return (
    <AccessContainer>
      <GlobalSnackbar />
      <>
        {isMobile ? (
          <OpertoMobileLogo src='/img/logos/operto-logo-blue.svg' />
        ) : (
          <LoginLabel variant='h2'>Welcome to Operto</LoginLabel>
        )}

        <StyledCard variant='outlined'>
          <CardContent>
            {errorParams && (
              <ErrorMessage variant='body2'>
                Your session has timed out. Please login again.
              </ErrorMessage>
            )}

            {loginState === LoginState.USE_ANOTHER_ACCOUNT && (
              <>
                <ActionLabel variant='h6'>Login</ActionLabel>
                <InputField
                  inputProps={{ 'data-testid': 'username-input' }}
                  variant='outlined'
                  helperText={emailError}
                  error={emailError.length > 0}
                  label='email'
                  value={email}
                  tabIndex={0}
                  onChange={e => setEmail(e.target.value.trim().toLowerCase())}
                  autoFocus
                  required
                  fullWidth
                />
              </>
            )}

            {loginState !== LoginState.USE_ANOTHER_ACCOUNT && (
              <>
                <ActionLabel variant='h6'>Choose an account</ActionLabel>

                <Autocomplete
                  id='linkedaccount-autocomplete'
                  data-testid='linkedaccount-autocomplete'
                  fullWidth
                  options={linkedAccounts}
                  autoHighlight
                  getOptionLabel={option => option.email}
                  value={selectedAccount}
                  isOptionEqualToValue={(option, value) => option.email === value.email}
                  renderOption={(props, option) => (
                    <LinkedAccountSelectorItem
                      linkedAccount={option}
                      handleAccountClicked={handleAccountClicked}
                      key={`${option.name}-${option.email}`}
                    />
                  )}
                  onChange={(event: React.SyntheticEvent, newValue: LinkedAccountData | null) => {
                    setSelectedAccount(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Account'
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </>
            )}

            {(loginState === LoginState.ENTER_PASSWORD ||
              loginState === LoginState.USE_ANOTHER_ACCOUNT) && (
              <InputField
                inputProps={{ 'data-testid': 'password-input' }}
                variant='outlined'
                helperText={passwordError}
                error={passwordError.length > 0}
                label='password'
                value={password}
                tabIndex={1}
                onChange={e => setPassword(e.target.value)}
                type='password'
                required
                fullWidth
              />
            )}

            <StyledBox loginstate={loginState}>
              {loginState === LoginState.CHOOSE_ACCOUNT && (
                <StyledLink
                  onClick={useAnotherAccountClick}
                  variant='body2'
                  data-testid='use-another-account'
                >
                  + Use another account
                </StyledLink>
              )}

              {loginState === LoginState.ENTER_PASSWORD && (
                <StyledLink onClick={handleForgotPassword} variant='body2'>
                  Forgot password?
                </StyledLink>
              )}

              <SubmitButton
                color='primary'
                variant='contained'
                onClick={handleSubmit}
                disabled={buttonDisabled}
                data-testid='submit-btn'
                fullWidth={loginState === LoginState.USE_ANOTHER_ACCOUNT}
              >
                {loginBtnLabel}
              </SubmitButton>

              {loginState === LoginState.USE_ANOTHER_ACCOUNT && (
                <BackButton
                  onClick={handleBackClick}
                  data-testid='back-btn'
                  startIcon={<ChevronLeftIcon />}
                >
                  Back
                </BackButton>
              )}

              {loginState === LoginState.USE_ANOTHER_ACCOUNT && (
                <StyledLink
                  onClick={handleForgotPassword}
                  variant='body2'
                  style={{ float: 'right' }}
                >
                  Forgot password?
                </StyledLink>
              )}
            </StyledBox>
          </CardContent>
        </StyledCard>

        <LinkToTeamOperto>
          Looking for Operto Teams?{'　'}
          <LoginToTeamOperto href='https://teams.operto.com/login' target='_blank' rel='noreferrer'>
            Login Here &gt;&gt;&gt;
          </LoginToTeamOperto>
        </LinkToTeamOperto>
      </>
    </AccessContainer>
  );
};

interface LoginStateStyledProps {
  loginstate?: LoginState;
}

const OpertoMobileLogo = styled.img`
  width: 60%;
  margin-bottom: 10px;
`;

const InputField = styled(TextField)`
  && {
    margin-top: 5px !important;
  }
`;

const LoginLabel = styled(Typography)`
  && {
    margin-bottom: 20px;
    font-weight: 500;
    line-height: 1.08;
    letter-spacing: -0.3px;
    padding-left: 0;
    color: ${props => props.theme.palette.primary};
  }
`;

const StyledCard = styled(Card)`
  && {
    padding: 20px;
    color: ${props => props.theme.palette.default};
    width: ${isMobile ? '100%' : '400px'};
  }
`;

const ActionLabel = styled(Typography)`
  && {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    font-weight: 500;
    line-height: 1.08;
    padding-left: 0;
    color: ${props => props.theme.palette.default};
  }
`;

const StyledBox = styled(Box)<LoginStateStyledProps>`
  && {
    margin-top: 10px;
    display: ${props => (props.loginstate === LoginState.USE_ANOTHER_ACCOUNT ? 'block' : 'flex')};
    flex-direction: row;
  }
`;

const BackButton = styled(Button)`
  && {
    text-transform: none;
    margin-top: 6px;
    font-weight: 600;
  }
`;

const SubmitButton = styled(Button)`
  && {
    align-self: flex-end;
    margin-top: 8px;
    margin-left: auto;
    text-align: right;
    width: ${props => (props.fullWidth ? '100%' : 'auto')};
  }
`;

const StyledLink = styled(Link)<LoginStateStyledProps>`
  && {
    cursor: pointer;
    align-self: flex-end;
    margin-top: 12px;
    font-weight: 600;
    color: ${props => props.theme.palette.primary};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ErrorMessage = styled(Typography)`
  && {
    color: red;
    font-weight: 500;
    margin-bottom: 25px;
  }
`;

const LinkToTeamOperto = styled.div`
  margin-top: 24px;
  font-size: 0.875rem;
`;

const LoginToTeamOperto = styled.a`
  font-size: 0.875rem;
  text-decoration: none;
  color: ${props => props.theme.palette.primary};
  &:hover {
    text-decoration: underline;
  }
`;

export default LoginPage;
