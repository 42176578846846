import { Avatar, Badge, Box, ListItemText, SxProps, Theme, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import FavouriteIcon from 'Common/Icons/FavouriteIcon';
import { sxPropsForUnitName } from 'Common/Tables/PropertyAlerts/Components/LinkCell';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export interface ChannelListItemProps {
  message: string;
  iconObject: JSX.Element;
  title: string;
  onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  onClickFavouriteIcon?: () => void;
  rightSubtext: string;
  hasNewMessage: boolean;
  newMessages?: number;
  captionInfo?: { title?: string; url?: string };
  isFavourite?: boolean;
  showFavouriteIcon?: boolean;
}

const ChannelListItem = ({
  message,
  iconObject,
  title,
  rightSubtext,
  onClick,
  onClickFavouriteIcon,
  newMessages,
  captionInfo,
  isFavourite,
  showFavouriteIcon,
}: ChannelListItemProps) => {
  const navigate = useNavigate();
  const handleClick = (e: React.MouseEvent) => {
    if (captionInfo?.url) {
      navigate(captionInfo.url);
      e.stopPropagation();
    }
  };

  return (
    <ListItem sx={mainListItemStyles} onClick={onClick}>
      <Box>
        <Avatar data-testid='avatar'>{iconObject}</Avatar>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Box sx={captionContainerStyle}>
          <Box>
            <Link
              component='span'
              data-testid='caption-title'
              variant='caption'
              onClick={handleClick}
              sx={sxPropsForUnitName}
            >
              {captionInfo?.title ? captionInfo.title : ''}
            </Link>
          </Box>

          <Box>
            <Typography component='span' variant='caption' sx={{ color: '#00000099' }}>
              {rightSubtext}
            </Typography>
          </Box>
        </Box>
        <Box sx={messagesContainerStyle}>
          <ListItemText
            disableTypography
            primary={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    height: '22px',
                  }}
                >
                  <Typography component='span' variant='subtitle2'>
                    {title}
                  </Typography>
                </Box>
                {showFavouriteIcon && (
                  <FavouriteIcon
                    isFavourite={isFavourite}
                    styles={{ padding: 0 }}
                    onClick={onClickFavouriteIcon}
                  />
                )}
              </Box>
            }
            secondary={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  gap: '8px',
                }}
              >
                <Box
                  sx={{
                    height: '40px',
                    display: '-webkit-box',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  <Typography component='span' variant='body2'>
                    {message}
                  </Typography>
                </Box>
                <Box sx={{ marginRight: '11px', marginLeft: 'auto' }}>
                  {newMessages > 0 && (
                    <Badge
                      badgeContent={newMessages}
                      color='primary'
                      data-testid='new-messages-badge'
                    />
                  )}
                </Box>
              </Box>
            }
          />
        </Box>
      </Box>
    </ListItem>
  );
};

const captionContainerStyle: SxProps = {
  gap: '4px',
  display: 'flex',
  justifyContent: 'space-between',
};

const messagesContainerStyle: SxProps = {
  gap: '8px',
  textAlign: 'left',
  display: 'flex',
};

const mainListItemStyles: SxProps<Theme> = {
  gap: '8px',
  borderBottom: theme => `1px solid ${theme.palette.divider}`,
  '&:hover': {
    cursor: 'pointer',
  },
};

export default ChannelListItem;
