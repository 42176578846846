import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { Text } from '@operto/ui';
import BottomSlidebar from 'Common/Slidebar/BottomSlidebar';
import { getTemperature } from 'Common/Tables/Properties/PropertyDataHelpers';
import {
  ActionCell,
  LockCell,
  NoiseCell,
  OccupancyCell,
} from 'Common/Tables/Properties/TableCells';
import { companySelector, roomTypeEnabledSelector } from 'company/state/companySelectors';
import { ILock } from 'device/deviceType';
import { hasSensorDevicesSelector } from 'device/state/deviceSelector';
import useUnitsFilters from 'hooks/useUnitsFilters';
import { getProperties } from 'property/state/propertyActions';
import { getPropertiesByFilter } from 'property/state/propertySelectors';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Property } from 'types/properties';
import BatteryIcon from 'ui-library/Components/icon/BatteryIcon';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import { TableCell } from 'ui-library/Components/table/TableCell';
import { PropertyFilterType, PropertyQueryFilterType } from './propertyType';

export const PRIMARY_PROPERTIES = 0;
export const COMMON_PROPERTIES = 5;

export interface PropertiesTableProps {
  filterType: PropertyFilterType; //primary , favourites
  menu?: React.ReactNode;
}

const Properties = ({ filterType, menu }: PropertiesTableProps) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [pagination, setPagination] = useState<{
    pageNum: number;
    numPerPage: number;
    keyword?: string;
  }>();
  const unitsFilters = useUnitsFilters(filterType);
  const navigate = useNavigate();
  const roomTypeEnabled = useAppSelector(roomTypeEnabledSelector());
  const company = useAppSelector(companySelector());
  const { properties, totalProperties, isLoading } = useAppSelector(
    getPropertiesByFilter({
      filterType,
    }),
  );
  const hasSensor = useAppSelector(hasSensorDevicesSelector());

  const renderLock = (params: GridRenderCellParams<unknown, Property>) => {
    const lock = params.row?.devices?.find(d => d.device_name === 'SmartLock') as unknown as ILock;
    const isOfflineLock = lock?.provider?.includes('igloo');
    return (
      lock && (
        <LockCell
          data-testid='lock-cell'
          isLocked={lock.is_locked}
          isOnline={lock.is_online}
          isOfflineLock={isOfflineLock}
        />
      )
    );
  };

  const renderLockBattery = (params: GridRenderCellParams<unknown, Property>) => {
    const lock = params.row?.devices?.find(d => d.device_name === 'SmartLock') as unknown as ILock;
    const isEnabled = lock?.is_online;
    const isOfflineLock = lock?.provider?.includes('igloo');
    return (
      lock && !isOfflineLock && <BatteryIcon disabled={!isEnabled} status={lock?.battery_status} />
    );
  };

  const renderKeyPadBattery = (params: GridRenderCellParams<unknown, Property>) => {
    const lock = params.row?.devices?.find(d => d.device_name === 'SmartLock') as unknown as ILock;
    const isEnabled = lock?.is_online;
    const isOfflineLock = lock?.provider?.includes('igloo');

    return (
      lock &&
      !isOfflineLock && <BatteryIcon disabled={!isEnabled} status={lock?.battery_keypad_status} />
    );
  };

  const renderTemp = (params: GridRenderCellParams) => (
    <Text data-testid='temp-cell'>{getTemperature(params.row)}</Text>
  );

  const renderNoise = (params: GridRenderCellParams) => (
    <NoiseCell noiseLevel={params.row.noiseLevel} />
  );

  const renderOccupancy = (params: GridRenderCellParams) => (
    <OccupancyCell occupancyLevel={params.row.occupancyLevel} />
  );

  const renderAction = () => <ActionCell handleClick={handleMoreClick} />;

  const viewUnitPage = () => {
    const handleView = () => {
      navigate(`/units/${selectedId}`);
    };

    return <div onClick={handleView}>View</div>;
  };

  const handleMoreClick = () => {
    setOpen(!open);
  };

  const renderUnitName = ({ row }: GridRenderCellParams<unknown, Property>) => {
    return <TableCell title={row.name} description={row.id} />;
  };

  const columnsDef: GridColDef<Property>[] = [
    {
      field: 'name',
      headerName: 'Unit',
      flex: 1,
      sortable: true,
      renderCell: renderUnitName,
    },
    {
      field: 'lockStatus',
      headerName: 'Lock',
      flex: 1,
      renderCell: renderLock,
      sortable: false,
    },
    {
      field: 'lockBattery',
      headerName: 'Lock Battery',
      flex: 1,
      renderCell: renderLockBattery,
      sortable: false,
    },
    {
      field: 'keypadBattery',
      headerName: 'Keypad Battery',
      flex: 1,
      renderCell: renderKeyPadBattery,
      sortable: false,
    },
    {
      field: 'temperature',
      headerName: 'Temperature',
      flex: 1,
      renderCell: renderTemp,
      sortable: false,
    },
    {
      field: 'noiseLevel',
      headerName: 'Noise',
      renderCell: renderNoise,
      sortable: false,
      flex: 1,
    },
    {
      field: 'property_type',
      headerName: 'Occupancy',
      renderCell: renderOccupancy,
      sortable: false,
      flex: 1,
    },
    {
      field: 'room_type',
      headerName: 'Room Type',
      flex: 1,
      sortable: false,
    },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: renderAction,
      sortable: false,
      resizable: false,
      align: 'right',
      headerAlign: 'right',
    },
  ];

  const getSortModel = (): GridSortModel => {
    const sortBy = unitsFilters.searchParams.get('sort_by');
    if (sortBy) {
      const model = sortBy.split('(');
      return [
        {
          field: model[0],
          sort: model[1].replace(')', '') as GridSortDirection,
        },
      ];
    }

    return [];
  };

  const handleCellClick = (gridCellParams: GridCellParams) => {
    if (isMobile) {
      setSelectedId(gridCellParams.id);
    } else {
      navigate(`/units/${gridCellParams.id}`);
    }
  };

  const handleFetch = useCallback((pageNum: number, numPerPage: number, searchValue?: string) => {
    setPagination({ pageNum, numPerPage, keyword: searchValue });
  }, []);

  const handleSort = (sortModel: GridSortModel) => {
    const urlSearchParams = new URLSearchParams(unitsFilters.searchParams);
    if (sortModel.length > 0) {
      urlSearchParams.set('sort_by', `${sortModel[0]?.field}(${sortModel[0]?.sort})`);
    } else {
      urlSearchParams.delete('sort_by');
    }

    unitsFilters.onFilterChange(urlSearchParams);
  };

  useEffect(() => {
    if (unitsFilters.isSynched && pagination) {
      const urlSearchParams = new URLSearchParams(unitsFilters.searchParams);
      urlSearchParams.set(PropertyQueryFilterType.PAGE_NUMBER, `${pagination.pageNum}`);
      urlSearchParams.set(PropertyQueryFilterType.NUMBER_PER_PAGE, `${pagination.numPerPage}`);
      if (pagination.keyword) {
        urlSearchParams.set(PropertyQueryFilterType.KEYWORD, pagination.keyword);
      }

      // NOTE: use side effect to filter data. everytime there is a
      // change in any of the dependency, this will be triggered.
      dispatch(getProperties({ filterType, urlSearchParams }));
    }
  }, [dispatch, filterType, pagination, unitsFilters.isSynched, unitsFilters.searchParams]);

  if (!unitsFilters.isSynched) {
    return null; // TODO: skeleton loader would be nice
  }

  return (
    <>
      <PaginatedTable
        enableToolbar
        title={menu}
        initialState={{ pinnedColumns: { right: ['action'] } }}
        loading={isLoading}
        rows={properties}
        rowCount={totalProperties}
        columns={columnsDef}
        columnVisibilityModel={{
          lockBattery: !isMobile,
          keypadBattery: !isMobile && !company.smwEnabled,
          temperature: !isMobile && !company.smwEnabled && hasSensor,
          noiseLevel: !isMobile && !company.smwEnabled && hasSensor,
          property_type: !isMobile && !company.smwEnabled && hasSensor,
          room_type: !isMobile && roomTypeEnabled,
          action: isMobile,
        }}
        onCellClick={handleCellClick}
        onFetch={handleFetch}
        filtersToolbarProps={filterType == PropertyFilterType.FAVOURITES ? undefined : unitsFilters}
        sortingMode='server'
        sortModel={getSortModel()}
        onSortModelChange={handleSort}
      />

      {isMobile && (
        <BottomSlidebar
          anchor='bottom'
          open={open}
          setOpen={() => {
            setOpen(!open);
          }}
          element={viewUnitPage()}
        />
      )}
    </>
  );
};

export default Properties;
