// NOTE: most minimal props for now required in UI. add new properties as needed

// server data representation
export type UnitJSON = {
  id: number;
  name: string;
  company_id: number;
  lock_enabled?: boolean;
  property_enabled: boolean;
  property_type: string;
  groups: { id: number; name: string }[];
};

// client data representation
export type Unit = {
  id?: number;
  name: string;
  companyId: number;
  lockEnabled?: boolean;
  propertyEnabled: boolean;
  propertyType: string;
  groups: { id: number; name: string }[];
};

export type UnitsState = {
  units: Unit[];
  pageNum: number;
  numPerPage: number;
  totalUnits: number;
};

export const UnitFilterType = {
  ALL_UNITS: 'primary',
  ACTIVE: 'active_today',
  VACANT: 'vacant',
  UNACTIVATED: 'unactivated',
  FAVOURITES: 'favourites',
  COMMON: 'common',
  ROOM_TYPE: 'room_type',
  PUBLIC: 'public',
  CLEANING: 'cleaning',
  ACCESS_GROUP: 'accessGroup',
  ALERT: 'alert',
  EXCLUDE_GROUP: 'exclude_in_group',
  GROUP: 'group_id',
  ROOM_STATUS: 'room_status',
} as const;

export type UnitFilterType = (typeof UnitFilterType)[keyof typeof UnitFilterType];
