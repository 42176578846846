import { NotifiableError } from '@bugsnag/js';
import * as deviceApi from 'api/deviceAPI';
import * as api from 'api/propertyAPI';
import { AxiosResponse } from 'axios';
import { IDevice } from 'device/deviceType';
import { OpertoLogger } from 'Logger/logger';
import { AppDispatch } from 'redux/store';
import { Actions } from 'types/actions';

export const getDevice = (deviceId: number) => () => {
  deviceApi.getDevice(deviceId).then((data: AxiosResponse) => {
    const device = data.data.data;
    return device;
  });
};

export const getDevices = () => async (dispatch: AppDispatch) => {
  try {
    const data = await deviceApi.getDevices();
    const devices = data.data.data;
    if (devices.length) {
      const deviceList: number[] = [];
      const deviceDict: Record<string, unknown> = {};
      const propertyDeviceDict: Record<string, unknown[]> = {};
      // only handles a single device per property because the server only returns 1 device per property
      devices?.forEach((device: IDevice) => {
        const { vendor, type, property_id } = device;

        if (!propertyDeviceDict[property_id]) {
          propertyDeviceDict[property_id] = [];
        }

        deviceList.push(device.device_id);
        deviceDict[device.device_id] = {
          data: device,
          vendor,
          type,
        };
        propertyDeviceDict[property_id].push(device.device_id);
      });
      dispatch({
        type: Actions.hydrateDevices,
        deviceList,
        deviceDict,
        propertyDeviceDict,
      });
    }
  } catch (err) {
    OpertoLogger.Log(err);
  }
};

export const getDevicesByPropertyId =
  (propertyId: number, type = '', col = '') =>
  (dispatch: AppDispatch) => {
    return api
      .getPropertyDevices(propertyId, type, col)
      .then((data: AxiosResponse) => {
        const devices = data.data.data;

        const deviceList: number[] = [];
        const deviceDict: Record<string, unknown> = {};
        const propertyDeviceDict: Record<string, unknown[]> = {};

        devices?.forEach(
          (device: { data: IDevice[] | IDevice; vendor?: string; type?: string }) => {
            const { vendor } = device;
            const { type } = device;

            if (!propertyDeviceDict[propertyId]) {
              propertyDeviceDict[propertyId] = [];
            }
            // locks data is not in an array but sensors are...
            if (Array.isArray(device.data)) {
              device.data?.forEach((device: IDevice) => {
                deviceList.push(device.device_id);
                device.property_id = propertyId;

                deviceDict[device.device_id] = { data: device, vendor, type };
                propertyDeviceDict[propertyId].push(device.device_id);
              });
            } else if (device.data?.device_id) {
              deviceList.push(device.data.device_id);
              device.data.property_id = propertyId;
              deviceDict[device.data.device_id] = {
                data: device.data,
                vendor,
                type,
              };
              propertyDeviceDict[propertyId].push(device.data.device_id);
            }
          },
        );
        dispatch({
          type: Actions.hydrateDevices,
          deviceList,
          deviceDict,
          propertyDeviceDict,
        });
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  };

export const getHistoricalByDeviceId =
  (deviceId: number | undefined) => (dispatch: AppDispatch) => {
    deviceApi
      .getHistorical(deviceId)
      .then((data: AxiosResponse) => {
        const items = data.data.data;

        dispatch({
          type: Actions.insertHistorical,
          deviceId: items[0].device_id,
          data: items,
        });
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  };
