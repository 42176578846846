import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { Box, TextField, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { trpc } from '@operto/trpc-client';
import { Variable } from '@operto/variables-shared';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { SmartCardIconButton } from 'ui-library/Styled/smartcard';
import { currentUserSelector } from 'user/state/userSelectors';
import { renderTextWithHover } from '../Custom/CustomVariable';
import { getAllVariables } from '../helpers/VariableHelpers';

export interface VariablesMoreInfoTopBarProps {
  variable: Variable;
  customVariables: Variable[];
  systemVariables: Variable[];
  isEditable: boolean;
  onCloseMoreTable: () => void;
}

const VariablesMoreInfoTopBar = ({
  variable,
  isEditable,
  systemVariables,
  customVariables,
  onCloseMoreTable,
}: VariablesMoreInfoTopBarProps) => {
  const MAX_VARIABLE_NAME_LENGTH = 40;
  const MAX_VARIABLE_DESC_LENGTH = 200;

  const [variableName, setVariableName] = useState(variable?.name);
  const [variableDesc, setVariableDesc] = useState(variable?.description);

  const [isEditVariableNameInline, setIsEditingVariableNameInLine] = useState(false);
  const [editVariableNameInlineValue, setEditVariableNameInlineValue] = useState(variable.name);

  const [isEditVariableDescInline, setIsEditingVariableDescInLine] = useState(false);
  const [editVariableDescInlineValue, setEditVariableDescInlineValue] = useState(
    variable.description,
  );
  const [isVariableExist, setIsVariableExist] = useState(false);

  const updateVariableTRPC = trpc.variables.updateVariable.useMutation();
  const variableUtils = trpc.useUtils();

  const dispatch = useAppDispatch();

  const currentUser = useSelector(currentUserSelector());

  useEffect(() => {
    // need a side effect in here to make sure if the user
    // chose a different variable, names are updated
    setVariableName(variable.name);
    setVariableDesc(variable.description);
    setEditVariableNameInlineValue(variable.name);
    setEditVariableDescInlineValue(variable.description);
  }, [variable.name, variable.description]);

  const mutateVariable = (mutatedVariable: Variable) => {
    updateVariableTRPC.mutate(
      { ...mutatedVariable, updatedBy: currentUser?.name },
      {
        onSuccess: () => {
          variableUtils.variables.getVariables.refetch({ type: 'custom' });
        },
        onSettled: () => {
          setVariableDesc(mutatedVariable.description);
          setVariableName(mutatedVariable.name);
          setEditVariableNameInlineValue(mutatedVariable.name);
          setEditVariableDescInlineValue(mutatedVariable.description);
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Variable updated successfully.',
              variant: SnackbarVariant.SUCCESS,
            }),
          );
        },
        onError: () => {
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Variable has not been updated due to an error.',
              variant: SnackbarVariant.ERROR,
            }),
          );
        },
      },
    );
  };

  return (
    <Stack direction='column' width={'100%'}>
      <Stack direction='row' justifyContent={'space-between'}>
        {isEditVariableNameInline && (
          <Stack direction={'row'}>
            <TextField
              data-testid='edit-name-input'
              value={editVariableNameInlineValue}
              onChange={event => {
                const newVarName = event.target.value;
                if (
                  newVarName === '' ||
                  (newVarName && newVarName.length <= MAX_VARIABLE_NAME_LENGTH)
                ) {
                  if (
                    getAllVariables(customVariables, systemVariables).find(
                      (foundVar: Variable) =>
                        foundVar.name.toLowerCase() === newVarName.toLowerCase().trim(),
                    ) &&
                    variable.name.toLowerCase() !== newVarName.toLowerCase().trim()
                  ) {
                    if (!isVariableExist) setIsVariableExist(true);
                  } else {
                    if (isVariableExist) setIsVariableExist(false);
                  }
                  setEditVariableNameInlineValue(newVarName);
                }
              }}
              size='small'
              variant='standard'
              label='Variable Name'
              helperText={
                isVariableExist
                  ? 'Variable already exist.'
                  : `Character Limit: ${editVariableNameInlineValue.length}/${MAX_VARIABLE_NAME_LENGTH}`
              }
            />
            <Box my={2}>
              <SmartCardIconButton
                data-testid='edit-name-cancel'
                $show
                size='small'
                style={{ marginLeft: 5 }}
                onClick={() => {
                  setIsEditingVariableNameInLine(false);
                  setEditVariableNameInlineValue(variable.name);
                }}
              >
                <ClearIcon fontSize='inherit' />
              </SmartCardIconButton>
              <SmartCardIconButton
                data-testid='edit-name-save'
                $show
                size='small'
                style={{ marginLeft: 5 }}
                onClick={() => {
                  mutateVariable({ ...variable, name: editVariableNameInlineValue.trim() });
                  setIsEditingVariableNameInLine(false);
                }}
              >
                <DoneIcon fontSize='inherit' />
              </SmartCardIconButton>
            </Box>
          </Stack>
        )}
        {!isEditVariableNameInline && (
          <Stack direction={'row'}>
            <Typography alignSelf='center' style={{ textTransform: 'capitalize' }} variant='h5'>
              {variableName}
            </Typography>
            {isEditable && (
              <SmartCardIconButton
                data-testid='edit-name-button'
                $show
                size='small'
                style={{ marginLeft: 5 }}
                onClick={() => {
                  setIsEditingVariableNameInLine(true);
                }}
              >
                <EditIcon fontSize='inherit' />
              </SmartCardIconButton>
            )}
          </Stack>
        )}
        <Box>
          <SmartCardIconButton
            data-testid='close-table-button'
            $show
            size='small'
            style={{ marginLeft: 5 }}
            onClick={() => {
              onCloseMoreTable();
            }}
          >
            <ClearIcon fontSize='inherit' />
          </SmartCardIconButton>
        </Box>
      </Stack>
      <Stack direction='row'>
        {isEditVariableDescInline && (
          <>
            <TextField
              data-testid='edit-description-input'
              value={editVariableDescInlineValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const newDesc = event.target.value;
                if (newDesc === '' || newDesc?.length <= MAX_VARIABLE_DESC_LENGTH) {
                  setEditVariableDescInlineValue(newDesc);
                }
              }}
              size='small'
              variant='standard'
              label='Variable Description'
              multiline
              style={{ width: 300 }}
              helperText={`Character Limit: ${editVariableDescInlineValue.length}/${MAX_VARIABLE_DESC_LENGTH}`}
            />
            <Box my={2}>
              <SmartCardIconButton
                data-testid='edit-description-cancel'
                $show
                size='small'
                style={{ marginLeft: 5 }}
                onClick={() => {
                  setIsEditingVariableDescInLine(false);
                  setEditVariableDescInlineValue(variable.description);
                }}
              >
                <ClearIcon fontSize='inherit' />
              </SmartCardIconButton>
              <SmartCardIconButton
                data-testid='edit-description-save'
                $show
                size='small'
                style={{ marginLeft: 5 }}
                onClick={() => {
                  setIsEditingVariableDescInLine(false);
                  mutateVariable({ ...variable, description: editVariableDescInlineValue.trim() });
                }}
              >
                <DoneIcon fontSize='inherit' />
              </SmartCardIconButton>
            </Box>
          </>
        )}
        {!isEditVariableDescInline && (
          <>
            {variable?.description?.length > 0 ? (
              <Box width='90%'>{renderTextWithHover(variableDesc, true)}</Box>
            ) : (
              <Typography variant='body2' alignSelf='center' color='text.disabled'>
                Enter Variable Description
              </Typography>
            )}
            {isEditable && (
              <SmartCardIconButton
                data-testid='edit-description-button'
                $show
                size='small'
                style={{ marginLeft: 5 }}
                onClick={() => {
                  setIsEditingVariableDescInLine(true);
                }}
              >
                <EditIcon fontSize='inherit' />
              </SmartCardIconButton>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default VariablesMoreInfoTopBar;
