import { NotifiableError } from '@bugsnag/js';
import { OpertoLogger } from 'Logger/logger';
import { ApplicationState } from 'redux/reducers';
import { AppDispatch } from 'redux/store';
import * as api from '../../api/commonProperties';
import { Actions } from '../../types/actions';
import { CommonPropertyAssociation } from '../../types/commonProperties';

export const getLinkedProperties =
  (propertyId: number): ((dispatch: AppDispatch) => Promise<void>) =>
  (dispatch: AppDispatch) =>
    api
      .getLinkedProperties(propertyId)
      .then((data: { data: { data: CommonPropertyAssociation[] } }) => {
        const linkedPropertyIds: CommonPropertyAssociation[] = data.data.data;
        if (linkedPropertyIds.length > 0) {
          dispatch({
            type: Actions.commonPropertyLinks,
            propertyId,
            linkedPropertyIds,
          });
        }
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });

export const linkCommonProperty =
  (propertyId: number, commonPropertyId: number) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const propertyId = getState().ui.propertyDetailId;
    api
      .linkProperties(propertyId, commonPropertyId)
      .then(() => {
        dispatch(getLinkedProperties(propertyId));
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  };

export const unlinkCommonProperty =
  (propertyId: number, commonPropertyId: number) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const propertyId = getState().ui.propertyDetailId;
    api
      .unlinkProperties(propertyId, commonPropertyId)
      .then(() => {
        dispatch(getLinkedProperties(propertyId));
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  };
