import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Grid, TextField } from '@mui/material';
import { SettingCard } from '@operto/ui-library';
import React from 'react';
import { useParams } from 'react-router-dom';
import { PropertyAddress, useGuestPortal } from 'redux/guestportal';
import { ImageUploadType } from 'redux/guestportal/models';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { userPermissionSelector } from 'user/state/userSelectors';
import ImageEditor from '../Common/GPImageEditor';

type PropertyCardProps = {
  property: PropertyAddress;
  onSave: (customProperty: PropertyAddress) => void;
  permission: boolean;
  changeAddress?: (address: PropertyAddress) => void;
  changeURL?: (url: string) => void;
};
export const AddressCard: React.FC<PropertyCardProps> = props => {
  const { property, onSave, changeAddress, permission } = props;
  const { propertyId } = useParams<{ propertyId: string }>();

  return (
    <SettingCard
      title='Custom Address'
      subtitle={
        propertyId
          ? "Set your location's custom primary address."
          : "Set your location's primary address."
      }
      description={"This is the physical address at which your guest's unit is located."}
      footerChildren={
        <Button
          variant='contained'
          color='primary'
          startIcon={<SaveIcon />}
          onClick={() => {
            onSave(property);
          }}
          disabled={!permission}
        >
          Save
        </Button>
      }
    >
      <Box my={2}>
        <TextField
          label='Property Name'
          placeholder="Enter property's name"
          variant='outlined'
          fullWidth
          value={property?.name || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            changeAddress({
              ...property,
              name: e.target.value,
            })
          }
          disabled={!permission}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField
            label='Address'
            placeholder="Enter property's primary address"
            variant='outlined'
            fullWidth
            value={property?.address || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              changeAddress({
                ...property,
                address: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs>
          <TextField
            label='City'
            placeholder="Enter property's city"
            variant='outlined'
            fullWidth
            value={property?.city || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              changeAddress({
                ...property,
                city: e.target.value,
              })
            }
            disabled={!permission}
          />
        </Grid>
      </Grid>
      <Box my={1}>
        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              label='State / Province'
              placeholder="Enter property's state or province"
              variant='outlined'
              fullWidth
              value={property?.state || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                changeAddress({
                  ...property,
                  state: e.target.value,
                })
              }
              disabled={!permission}
            />
          </Grid>
          <Grid item xs>
            <TextField
              label='Country'
              placeholder="Enter property's country"
              variant='outlined'
              fullWidth
              value={property?.country || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                changeAddress({
                  ...property,
                  country: e.target.value,
                })
              }
              disabled={!permission}
            />
          </Grid>
        </Grid>
      </Box>
    </SettingCard>
  );
};

export const ImageCard: React.FC<PropertyCardProps> = props => {
  const { property, onSave, changeURL, permission } = props;
  const [customProperty, setCustomProperty] = React.useState(property);
  const [shouldUpdate, setShouldUpdate] = React.useState(true);
  // const [customImageUrl, setCustomImageUrl] = React.useState(property?.imageURL);

  React.useEffect(() => {
    if (shouldUpdate) {
      setCustomProperty(property);
    }
  }, [property, shouldUpdate]);

  // React.useEffect(() => {
  //   setCustomProperty({ ...customProperty, imageURL: customImageUrl });
  // }, [customImageUrl]);

  return (
    <SettingCard
      title='Property Image'
      subtitle='Set your guest portals primary property photo'
      description='This can be an image of your locations exterior, your lobby or other common area.'
      footerChildren={
        <Button
          variant='contained'
          color='primary'
          startIcon={<SaveIcon />}
          onClick={() => {
            setShouldUpdate(true);
            onSave(customProperty);
          }}
          disabled={!permission}
        >
          Save
        </Button>
      }
    >
      <ImageContainer>
        <ImageEditor
          image={customProperty?.imageURL ?? ''}
          onImageChange={url => {
            changeURL(url);
            setShouldUpdate(true);
          }}
          ratio='4:3'
          imageType={ImageUploadType.BACKGROUND_LOGO}
        />
      </ImageContainer>
    </SettingCard>
  );
};

const ImageContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

type AddressProps = {
  visible?: boolean;
};
export const Address: React.FC<AddressProps> = props => {
  const { address, setPropertyAddress, fetchInfoData } = useGuestPortal();
  const [convertedAddress, setConvertedAddress] = React.useState(address);
  React.useEffect(() => {
    fetchInfoData();
  }, [fetchInfoData]);
  React.useEffect(() => {
    setConvertedAddress(address);
  }, [address]);

  const setUrl = (newUrl: string) => {
    setConvertedAddress({ ...convertedAddress, imageURL: newUrl });
  };

  const permission = useAppSelector(userPermissionSelector());

  const setAddress = (address: PropertyAddress) => {
    setConvertedAddress({
      ...convertedAddress,
      name: address.name,
      city: address.city,
      state: address.state,
      address: address.address,
      country: address.country,
    });
  };
  const onSave = () => {
    setPropertyAddress(convertedAddress);
  };
  const { visible } = props;
  if (!visible) {
    return null;
  }

  return (
    <>
      <LoadingContainer loading={!convertedAddress}>
        <AddressCard
          property={convertedAddress}
          onSave={onSave}
          changeAddress={setAddress}
          permission={permission}
        />
      </LoadingContainer>
      <LoadingContainer loading={!convertedAddress}>
        <ImageCard
          property={convertedAddress}
          onSave={onSave}
          changeURL={setUrl}
          permission={permission}
        />
      </LoadingContainer>
    </>
  );
};

export default Address;
