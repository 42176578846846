import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, Drawer, Stack, Tooltip } from '@mui/material';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellCheckboxRenderer,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { Tag, getTagsByMemberId } from '@operto/tags-shared';
import { trpc } from '@operto/trpc-client';
import { FormContainer } from 'Common/FormContainer';
import { featurePermissionByMemberRole } from 'helper/helper';
import { trackEvent } from 'lib/analytics';
import { useAppFeatures } from 'lib/app-features';
import { logger } from 'lib/logger';
import { MemberFilterType } from 'member/memberType';
import React, { useEffect, useState } from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useUpdateGroupMembersMutation } from 'redux/groups/api-slice';
import { Group } from 'redux/groups/types';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { INITIAL_STATE, useGetMembersQuery } from 'redux/members/api-slice';
import { MEMBERS_ROLES, Member } from 'redux/members/types';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import {
  addButtonStyle,
  addRemoveButtonContainerStyle,
  itemsTableStyle,
  removeButtonStyle,
  tableContainerStyle,
} from 'ui-library/Components/table/table-styles';
import { currentUserSelector } from 'user/state/userSelectors';
import { MembersSelectionTable, renderMembers } from '../Common/MembersSelectionTable';
import { NamedChip } from '../Common/NamedChip';
import {
  computeCurrentListItems,
  computeItemsToMove,
  computeListItemsToUpdate,
  getPinnedRowClassName,
  pinnedComparator,
} from './group-utils';

type GroupsFormProps = {
  onClose: () => void;
  group: Group;
  open: boolean;
};

export const GroupMembersEditForm = ({ onClose, group, open }: GroupsFormProps) => {
  const { isFeatureEnabled } = useAppFeatures();
  const dispatch = useAppDispatch();
  const { data: membersInGroup = INITIAL_STATE, isFetching: membersInGroupFetching } =
    useGetMembersQuery({
      filterType: MemberFilterType.GROUP,
      numPerPage: 100000,
      [MemberFilterType.GROUP]: group?.id,
    });

  const { data: membersNotInGroup = INITIAL_STATE, isFetching: membersNotInGroupFetching } =
    useGetMembersQuery({
      filterType: MemberFilterType.EXCLUDE_GROUP,
      numPerPage: 100000,
      [MemberFilterType.EXCLUDE_GROUP]: group?.id,
    });

  const { data: companyTags = [] } = trpc.tags.getTags.useQuery();

  const [updateGroupMembers, { isLoading: saveLoading }] = useUpdateGroupMembersMutation();

  const [selectedMembersInGroup, setSelectedMembersInGroup] = useState<number[]>([]);
  const [selectedMembersNotInGroup, setSelectedMembersNotInGroup] = useState<number[]>([]);

  const [pinnedMembersInGroup, setPinnedMembersInGroup] = useState<Member[]>([]);
  const [pinnedMembersNotInGroup, setPinnedMembersNotInGroup] = useState<Member[]>([]);

  const handleSave = async () => {
    try {
      await updateGroupMembers({
        groupId: group.id,
        memberIds: computeListItemsToUpdate(
          group.memberIds,
          pinnedMembersInGroup,
          pinnedMembersNotInGroup,
        ),
      }).unwrap();

      trackEvent({
        event: 'EDITED',
        screen: 'GROUP MEMBERS',
      });

      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Group members are updated successfully',
          variant: SnackbarVariant.SUCCESS,
        }),
      );
      onClose();
    } catch (error) {
      logger.error(`Error updating group members: ${error}`);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Group members are not updated successfully',
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  const handleAdd = () => {
    const membersToMove: Member[] = computeItemsToMove(
      membersNotInGroup.members,
      selectedMembersNotInGroup,
    );

    // moving members from not in group to in group
    setPinnedMembersInGroup(prevPinnedMembersInGroup => [
      ...prevPinnedMembersInGroup,
      ...membersToMove,
    ]);

    // removing members from not in group
    setPinnedMembersNotInGroup(prevPinnedMembersNotInGroup =>
      prevPinnedMembersNotInGroup.filter(member => !selectedMembersNotInGroup.includes(member.id)),
    );

    // resetting selected members
    setSelectedMembersNotInGroup([]);
  };

  const handleRemove = () => {
    const membersToMove: Member[] = computeItemsToMove(
      membersInGroup.members,
      selectedMembersInGroup,
    );

    // moving members from in group to not in group
    setPinnedMembersNotInGroup(prevPinnedMembersNotInGroup => [
      ...prevPinnedMembersNotInGroup,
      ...membersToMove,
    ]);

    // removing members from in group
    setPinnedMembersInGroup(prevPinnedMembersInGroup =>
      prevPinnedMembersInGroup.filter(member => !selectedMembersInGroup.includes(member.id)),
    );

    // resetting selected members
    setSelectedMembersInGroup([]);
  };

  const handleSelectedMembers = (selectedMemberIds: number[], memberType: MemberFilterType) => {
    if (memberType === MemberFilterType.GROUP) {
      setSelectedMembersInGroup(selectedMemberIds);
    } else {
      setSelectedMembersNotInGroup(selectedMemberIds);
    }
  };

  const handleTitleInfo = (tableType: MemberFilterType) => {
    if (tableType === MemberFilterType.GROUP) {
      return `${membersInGroup.totalMembers} Members in the '${group.name}' group`;
    } else {
      return `${membersNotInGroup.totalMembers} Members NOT in the '${group.name}' group`;
    }
  };

  useEffect(() => {
    return () => {
      if (!open) {
        setPinnedMembersNotInGroup([]);
        setPinnedMembersInGroup([]);
      }
    };
  }, [open]);

  const user = useAppSelector(currentUserSelector());

  const isRowSelectable = (rowData: { row: Member }, selectedMembers: number[]): boolean => {
    const { row: member } = rowData;
    //disable row if the scope doesn't include the role or any
    const allowedScope = featurePermissionByMemberRole(user?.role, member?.role, 'groups', 'edit');

    //you are allowed to edit yourself as a global manager, this is a bit more complex to be covered
    // in the simple permission setup, it will have to be a case covered in the fill permission system
    const allowIfSelfGlobal =
      user?.role === MEMBERS_ROLES.GLOBAL_MANAGER && user?.id === member?.id;

    return (allowedScope || allowIfSelfGlobal) && selectedMembers.length === 0;
  };

  const createColumnDef = (
    selectedMembers: number[],
    isRowSelectable: (params: GridCellParams) => boolean,
  ): GridColDef[] => {
    return [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        type: 'boolean',
        renderCell: params => {
          const selectable = isRowSelectable(params);
          return (
            <>
              {selectable ? (
                <GridCellCheckboxRenderer {...params} />
              ) : (
                <Tooltip title='Edit restricted' placement='bottom'>
                  <Box>
                    <GridCellCheckboxRenderer {...params} />
                  </Box>
                </Tooltip>
              )}
            </>
          );
        },
      },
      {
        field: 'name',
        headerName: selectedMembers.length === 0 ? 'Members' : `${selectedMembers.length} selected`,
        flex: 1,
        valueGetter: params => params.row?.isPinned,
        renderCell: renderMembers,
        sortable: false,
        sortComparator: pinnedComparator,
      },
      isFeatureEnabled('tags') && {
        field: 'tags',
        headerName: 'Member Tags',
        flex: 1,
        sortable: false,
        renderCell: ({ row: member }: GridRenderCellParams<unknown, Member>) => {
          const memberTags = getTagsByMemberId(member.id, companyTags);
          return <NamedChip names={memberTags?.map((tag: Tag) => tag.label)} />;
        },
      },
    ];
  };

  return (
    <Drawer
      open={open}
      anchor='right'
      onClose={onClose}
      PaperProps={{
        sx: { width: '80%', backgroundColor: 'background.default01', borderRadius: 0 },
      }}
      data-testid='group-members-edit-form'
    >
      <FormContainer
        title={`Edit members in the '${group?.name}' group`}
        loading={saveLoading}
        dirty={
          !membersInGroupFetching &&
          !membersNotInGroupFetching &&
          (pinnedMembersInGroup.length > 0 || pinnedMembersNotInGroup.length > 0)
        }
        onClose={onClose}
        onSubmit={handleSave}
      >
        <Stack direction='row' height='100%' width='100%'>
          <Box sx={tableContainerStyle} data-testid='in-group-selection-table'>
            <MembersSelectionTable
              loading={membersInGroupFetching}
              selectionModel={selectedMembersInGroup}
              onSelectionChange={memberIds =>
                handleSelectedMembers(memberIds, MemberFilterType.GROUP)
              }
              rows={computeCurrentListItems(
                membersInGroup.members,
                pinnedMembersInGroup,
                pinnedMembersNotInGroup,
              )}
              getRowClassName={getPinnedRowClassName}
              columns={createColumnDef(selectedMembersInGroup, params =>
                isRowSelectable(params, selectedMembersNotInGroup),
              )}
              tableInfo={handleTitleInfo(MemberFilterType.GROUP)}
              isRowSelectable={(params: GridRowParams) =>
                isRowSelectable(params, selectedMembersNotInGroup)
              }
              disableSearch={selectedMembersNotInGroup.length > 0}
              sortingMode='server'
              sx={itemsTableStyle}
            />
          </Box>
          <Box sx={addRemoveButtonContainerStyle}>
            <Button
              startIcon={<KeyboardArrowLeftIcon />}
              sx={addButtonStyle(
                selectedMembersNotInGroup.length > 0 && !membersNotInGroupFetching,
              )}
              onClick={() => handleAdd()}
              disabled={membersNotInGroupFetching || selectedMembersNotInGroup.length === 0}
              data-testid='group-members-edit-add-button'
            >
              Add
            </Button>
            <Button
              endIcon={<KeyboardArrowRightIcon />}
              sx={removeButtonStyle(selectedMembersInGroup.length > 0 && !membersInGroupFetching)}
              onClick={() => handleRemove()}
              disabled={membersInGroupFetching || selectedMembersInGroup.length === 0}
              data-testid='group-members-edit-remove-button'
            >
              Remove
            </Button>
          </Box>
          <Box sx={tableContainerStyle} data-testid='not-in-group-selection-table'>
            <MembersSelectionTable
              loading={membersNotInGroupFetching}
              selectionModel={selectedMembersNotInGroup}
              onSelectionChange={memberIds =>
                handleSelectedMembers(memberIds, MemberFilterType.EXCLUDE_GROUP)
              }
              rows={computeCurrentListItems(
                membersNotInGroup.members,
                pinnedMembersNotInGroup,
                pinnedMembersInGroup,
              )}
              getRowClassName={getPinnedRowClassName}
              columns={createColumnDef(selectedMembersNotInGroup, params =>
                isRowSelectable(params, selectedMembersInGroup),
              )}
              tableInfo={handleTitleInfo(MemberFilterType.EXCLUDE_GROUP)}
              isRowSelectable={(params: GridRowParams) =>
                isRowSelectable(params, selectedMembersInGroup)
              }
              disableSearch={selectedMembersInGroup.length > 0}
              sortingMode='server'
              sx={itemsTableStyle}
            />
          </Box>
        </Stack>
      </FormContainer>
    </Drawer>
  );
};
