import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import { GlobalText } from 'Pages/GuestPortal/Global/GlobalText';
import TabActivator from 'Pages/Unit/GuestPortal/TabActivator';
import TabDeactivator from 'Pages/Unit/GuestPortal/TabDeactivator';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import { CustomText as TypeCustomText, useGuestPortal } from 'redux/guestportal';
import { PageHeader } from 'ui-library/Styled/containers';

const CustomText = () => {
  const {
    fetchCustomText,
    setCustomText,
    fetchPropertyCustomText,
    setPropertyCustomText,
    customText,
  } = useGuestPortal();
  const { propertyId } = useParams<{ propertyId: string }>();

  const switched = (switchPreviousState: boolean) => {
    onSave({ ...customText, isCustom: !switchPreviousState });
  };

  const onSave = (text: TypeCustomText) => {
    if (propertyId) {
      setPropertyCustomText(propertyId, text);
    } else {
      setCustomText(text);
    }
  };

  useEffect(() => {
    if (propertyId) {
      fetchPropertyCustomText(Number(propertyId));
    } else {
      fetchCustomText();
    }
  }, [propertyId, fetchCustomText, fetchPropertyCustomText]);

  if (!customText) {
    return null;
  }

  return (
    <>
      <PageHeader>Custom Text</PageHeader>
      {customText.isCustom ? (
        <>
          <TabDeactivator switchLabel={'Disable Custom Text'} onSwitched={switched} />
          <GlobalText visible />
        </>
      ) : (
        <TabActivator
          title={'Need a custom text for this unit?'}
          icon={
            <FormatAlignCenterIcon
              color='primary'
              sx={{
                height: isMobile ? '70px' : '120px',
                width: isMobile ? '70px' : '120px',
                marginX: 'auto',
              }}
            />
          }
          switchLabel={'Enable Custom Text'}
          onSwitched={switched}
        />
      )}
    </>
  );
};

export default CustomText;
