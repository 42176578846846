import Save from '@mui/icons-material/Save';
import { Button, Stack } from '@mui/material';
import { SideDrawer, SideDrawerProps } from 'Common/Drawer/SideDrawer';
import RightSideTopbar from 'Common/Slidebar/components/RightSideTopbar';
import React from 'react';

export type IntegrationsFormHandle = {
  submit: () => void;
  close: () => void;
};

export type IntegrationsFormProps = {
  onClose?: () => void;
};

type IntegrationsSideDrawerProps = SideDrawerProps & {
  title: string;
  children: React.ReactNode;
  onClose: () => void;
  onSave?: () => void;
  dataTestId?: string;
};

export const IntegrationsSideDrawer = ({
  children,
  title,
  onClose,
  onSave,
  dataTestId,
  ...props
}: IntegrationsSideDrawerProps) => {
  return (
    <SideDrawer data-testid={dataTestId} onClose={onClose} {...props}>
      <RightSideTopbar
        title={title}
        handleClose={onClose}
        buttonComponent={
          onSave && (
            <Button
              variant='contained'
              color='primary'
              startIcon={<Save />}
              onClick={() => onSave()}
            >
              Save
            </Button>
          )
        }
      />
      <Stack display='flex' flex={1} overflow='auto' p={2}>
        {children}
      </Stack>
    </SideDrawer>
  );
};
