import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';
import styled from 'styled-components';

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    fontSize: 14,
    fontWeight: 400,
    padding: 10,
    boxShadow: '0 0 3px #ccc',
  },
});
