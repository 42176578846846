import { Reducer, combineReducers } from '@reduxjs/toolkit';
import {
  NoiseAwareDict,
  MultipleNoiseAwareDict,
  NoiseAwareState,
  NoiseAwareThresholds,
  IPropertyNotificationsHR,
  INotificationDetails,
  NoiseAwareHistoricalDict,
} from '../../types/noise-aware';
import { Actions } from '../../types/actions';

const initialNoiseAwareState: NoiseAwareDict = {};
const initialMultipleNoiseAwareState: MultipleNoiseAwareDict = [];

const byPropertyId: Reducer<NoiseAwareDict> = (state = initialNoiseAwareState, action) => {
  switch (action.type) {
    case Actions.insertNoiseAwareData:
      return {
        ...state,
        ...action.byPropertyId,
      };
    default:
      return state;
  }
};

const byPropertyIds: Reducer<MultipleNoiseAwareDict> = (
  state = initialMultipleNoiseAwareState,
  action,
) => {
  switch (action.type) {
    case Actions.insertMultipleNoiseAwareData:
      return action.byPropertyIds;
    default:
      return state;
  }
};

const byDeviceId: Reducer<NoiseAwareDict> = (state = initialNoiseAwareState, action) => {
  switch (action.type) {
    case Actions.insertNoiseAwareDataByDevice:
      return {
        ...state,
        ...action.byDeviceId,
      };
    default:
      return state;
  }
};

const historicalDataById: Reducer<NoiseAwareHistoricalDict> = (state = {}, action) => {
  switch (action.type) {
    case Actions.insertHistoricalNoiseAwareData:
      return {
        ...state,
        [action.propertyId]: action.data,
      };
    default:
      return state;
  }
};

const historicalDataByDeviceId: Reducer<NoiseAwareHistoricalDict> = (state = {}, action) => {
  switch (action.type) {
    case Actions.insertHistoricalNoiseAwareDataByDevice:
      return {
        ...state,
        [action.deviceId]: action.data,
      };
    default:
      return state;
  }
};

const thresholdsByPropertyId: Reducer<IPropertyNotificationsHR> = (state = {}, action) => {
  switch (action.type) {
    case Actions.hydrateNoiseAwareThresholds:
      return action.propertySpecificNotificationsHRNoiseAware;
    case Actions.updateNoiseAwareNotification:
      return {
        ...state,
        [action.propertyId]: {
          ...state[action.propertyId],
          [action.notificationType]: action.notification,
        },
      };
    default:
      return state;
  }
};

const thresholdsByDeviceId: Reducer<IPropertyNotificationsHR> = (state = {}, action) => {
  switch (action.type) {
    case Actions.hydrateNoiseAwareThresholds:
      return action.thresholdByDeviceId;
    default:
      return state;
  }
};

const thresholdUpdateStatus: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.beginNoiseAwareThresholdUpdate:
      return 'updating';
    case Actions.noiseAwareThresholdUpdateSuccess:
      return 'success';
    case Actions.noiseAwareThresholdUpdateFailed:
      return 'failed';
    case Actions.endNoiseAwareThresholdUpdate:
      return '';
    default:
      return state;
  }
};

const noiseReg: Reducer<INotificationDetails> = (state = { id: '', value: 70 }, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
const noiseQuiet: Reducer<INotificationDetails> = (state = { id: '', value: 60 }, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const thresholds: Reducer<NoiseAwareThresholds> = combineReducers<NoiseAwareThresholds>({
  noiseReg,
  noiseQuiet,
});

const reducer: Reducer<NoiseAwareState> = combineReducers<NoiseAwareState>({
  byPropertyId,
  byPropertyIds,
  byDeviceId,
  thresholdsByPropertyId,
  thresholdsByDeviceId,
  thresholds,
  historicalDataById,
  historicalDataByDeviceId,
  thresholdUpdateStatus,
});

export default reducer;
