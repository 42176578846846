import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import React, { ReactNode, useEffect } from 'react';

type SplitViewDataType = {
  id?: number;
  [key: string]: unknown;
};

export type SplitViewItemType = {
  listItem: ReactNode;
  splitViewData: SplitViewDataType;
};

type SplitViewProps = {
  onClose?: () => void;
  itemList: SplitViewItemType[];
  onItemSelected: (item: SplitViewDataType) => void;
  detailComponent: ReactNode;
  selectedItemId: string;
};

export const SplitView = ({
  onClose,
  itemList,
  onItemSelected,
  detailComponent,
  selectedItemId,
}: SplitViewProps) => {
  const handleItemSelected = (data: SplitViewItemType) => {
    if (onItemSelected) {
      onItemSelected(data.splitViewData);
    }
  };

  useEffect(() => {
    const element = document.getElementById(selectedItemId.toString());
    if (element) {
      const timeout = setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 100);

      return () => clearTimeout(timeout);
    }
  }, [selectedItemId]);

  return (
    <Stack sx={viewContainerStyle} direction='row'>
      <Box sx={listContainerStyle}>
        {itemList.map((item, index) => (
          <Box
            key={index}
            id={item.splitViewData.id.toString()}
            sx={itemContainerStyle(item.splitViewData.id.toString() === selectedItemId)}
            onClick={() => handleItemSelected(item)}
          >
            {item.listItem}
          </Box>
        ))}
      </Box>

      <Divider orientation='vertical' />

      {detailComponent}

      {onClose && <CloseIcon sx={closeButtonStyle} onClick={onClose} />}
    </Stack>
  );
};

const itemContainerStyle = (isSelected: boolean) => ({
  maxHeight: '300px',
  overflow: 'hidden',
  borderLeft: isSelected && '3px solid #155EFF',
  backgroundColor: isSelected && 'primary.p8',
});

const listContainerStyle = {
  minWidth: '360px',
  height: '100%',
  overflowY: 'scroll',
};

const viewContainerStyle = {
  width: '100%',
  height: '90%',
  background: 'white',
  position: 'relative',
  borderRadius: '16px',
  overflow: 'hidden',
};

const closeButtonStyle = {
  position: 'absolute',
  top: '16px',
  right: '16px',
  height: '24px',
  width: '24px',
  color: '#0000008A',
  cursor: 'pointer',
};
