import { logger } from 'lib/logger';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { completeMasquerading } from '../../api/ui';
import { storeToken } from '../../redux/actions/users';

const Masquerade = () => {
  const [redirect, setRedirect] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const masquerade = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const secret = urlParams.get('secret') || '';
      const masq = urlParams.get('masq_id') || '';

      if (secret && masq) {
        try {
          const data = await completeMasquerading(secret, masq);
          localStorage.setItem('masq', 'true');
          await dispatch(storeToken(data.data.data));
          setRedirect('/');
        } catch (error) {
          logger.error('error masquerading : ', error);
        }
      }
    };
    masquerade();
  }, [dispatch]);

  return (
    <div className='set-password'>
      <div className='set-box'>
        <div className='highlighted'>
          <div className='column-np blue-side'>
            <div className='title'>operto</div>
            <div className='description'>
              Operto revolutionizes the way you manage your properties.
              <br /> Truly automating your life.
            </div>
          </div>
          <div className='column-np white-side'>
            <h1>Working on getting you in</h1>
            <h2>If this takes longer than 20 seconds please try again</h2>
          </div>
          {redirect ? <Navigate to={redirect} /> : null}
        </div>
      </div>
    </div>
  );
};

export default Masquerade;
