import HumidityIcon from '@mui/icons-material/HotTub';
import { Box, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { devicesByPropertyIdSelector } from 'device/state/deviceSelector';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { patchThreshold } from 'threshold/state/thresholdAction';
import { getThresholdsByDeviceId } from 'threshold/state/thresholdSelectors';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import Widget from 'ui-library/Components/widget/Widget';
import RiskDisplay, { RiskLevel } from 'ui-library/Components/widget/content/RiskDisplay';
import ShowMoreToggle from 'ui-library/Components/widget/content/ShowMoreToggle';
import ThresholdSetting, {
  ThresholdInputs,
} from 'ui-library/Components/widget/content/ThresholdSetting';
import DeviceTitleDropdown from './DeviceTitleDropdown';
import HumidityFooter from './HumidityFooter';

export interface HumidityCardProps {
  hideFooter?: boolean;
  isOnUnitDashboard?: boolean;
}

export const HIGH_HUMIDITY_THRESHOLD = 60;
export const LOW_HUMIDITY_THRESHOLD = 30;

const HumidityCard = ({ hideFooter, isOnUnitDashboard = false }: HumidityCardProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { propertyId: pidStr } = useParams();
  const propertyId = Number(pidStr);
  const devices = useAppSelector(devicesByPropertyIdSelector(propertyId));
  const humidityDevices = devices.filter(device => device.data.humidity);
  const [openDetails, setOpenDetails] = useState(false);
  const [high, setHigh] = useState(HIGH_HUMIDITY_THRESHOLD);
  const [low, setLow] = useState(LOW_HUMIDITY_THRESHOLD);
  const [selectedDeviceId, setSelectedDeviceId] = React.useState(
    devices?.[0]?.data?.device_id || 0,
  );

  const deviceThreshold = useAppSelector(getThresholdsByDeviceId(selectedDeviceId as number));
  // @ts-ignore
  const deviceData = humidityDevices.find(d => d.data?.device_id === selectedDeviceId);

  const toggleOpenDetails = () => {
    setOpenDetails(!openDetails);
  };

  const viewMoreDetails = () => {
    navigate(`/units/${propertyId}/devices/humidity`);
  };

  useEffect(() => {
    if (deviceThreshold) {
      const { humidityLow, humidityHigh } = deviceThreshold;
      setLow(humidityLow.value);
      setHigh(humidityHigh.value);
    }
  }, [deviceThreshold]);

  useEffect(() => {
    if (humidityDevices?.length > 0) {
      setSelectedDeviceId(humidityDevices?.[0].data?.device_id);
    }
  }, [humidityDevices, humidityDevices.length]);

  const calculateRisk = () => {
    if (deviceData?.data?.humidity) {
      const current = parseFloat(deviceData.data.humidity as string);
      if (current > high || current < low) {
        return RiskLevel.HIGH;
      }
      return RiskLevel.LOW;
    }
    return RiskLevel.NONE;
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>, field: ThresholdInputs) => {
    if (field === ThresholdInputs.FirstThreshold) {
      setLow(Number(e.target.value));
    } else {
      setHigh(Number(e.target.value));
    }
  };

  const handleSave = (field: ThresholdInputs) => {
    const { humidityLow, humidityHigh } = deviceThreshold;
    const value = field === ThresholdInputs.FirstThreshold ? low : high;
    const id = field === ThresholdInputs.FirstThreshold ? humidityLow.id : humidityHigh.id;
    const thresholdType = field === ThresholdInputs.FirstThreshold ? 'Low' : 'High';

    // @ts-ignore
    dispatch(patchThreshold(id, value)).then(() => {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: `${thresholdType} humidity threshold updated.`,
          variant: SnackbarVariant.SUCCESS,
        }),
      );
    });
  };

  const handleDeviceSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDeviceId(e.target.value);
  };

  return (
    <Widget
      title={
        <DeviceTitleDropdown
          devices={humidityDevices}
          onChange={handleDeviceSelect}
          selectedDeviceId={selectedDeviceId as number}
          icon={<HumidityIcon />}
          mode={'dark'}
        />
      }
      isCarouselCard
      open={openDetails}
      isOnUnitDashboard={isOnUnitDashboard}
      smartCardType='device'
      footer={!hideFooter && <HumidityFooter onDetailsClick={viewMoreDetails} />}
      fill='#E8F0FE'
      mode='light'
    >
      <Box sx={{ paddingBottom: '0.875rem' }}>
        <Box sx={{ height: '100%' }}>
          <ShowMoreToggle onClick={toggleOpenDetails} open={openDetails} mode='dark' />
          <Typography variant='h4'>
            {deviceData?.data?.humidity ? `${deviceData?.data.humidity}%` : 'N/A'}
          </Typography>
          <RiskDisplay mode='dark' riskLevel={calculateRisk()} />
        </Box>
        {openDetails && (
          <Collapse in={openDetails}>
            <Box sx={{ marginTop: '14px' }}>
              <ThresholdSetting
                values={[low, high]}
                onSave={handleSave}
                onChange={handleValueChange}
                suffix='%'
                labels={['Low Threshold', 'High Threshold']}
                mode='dark'
              />
            </Box>
          </Collapse>
        )}
      </Box>
    </Widget>
  );
};

export default HumidityCard;
