import { ModeEditOutlineOutlined } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { Box, IconButton, Stack, SxProps } from '@mui/material';
import { Text } from '@operto/ui';
import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { FormTextFieldProps } from 'ui-library/Components/input/FormTextField';
import { FormTextField } from './FormTextField';

type HoverableTextFieldProps = FormTextFieldProps & {
  text?: string;
  onSave: () => void;
  onClose: () => void;
};

export const HoverableTextField = ({
  field,
  rules,
  control,
  text,
  onSave,
  onClose,
  size = 'small',
}: HoverableTextFieldProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const {
    field: { value },
    fieldState: { isDirty: isFieldDirty },
    formState: { isValid },
  } = useController({ control, name: field });
  const isDirty = isFieldDirty && value?.trim() !== text;
  const isSmall = size === 'small';

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    return () => {
      if (text !== undefined && isEditing) {
        setIsEditing(false);
      }
    };
  }, [text, isEditing]);

  const editButtonStyle: SxProps = {
    padding: '0px',
    marginLeft: '8px',
    width: isSmall ? '28px' : '32px',
    height: isSmall ? '28px' : '32px',
  };

  const textContainerStyle: SxProps = {
    height: isSmall ? '28px' : '32px',
    cursor: 'pointer',
    alignItems: 'center',
    width: 'fit-content',
  };

  if (isEditing) {
    return (
      <Stack direction='row' sx={textContainerStyle}>
        <FormTextField
          rules={rules}
          field={field}
          control={control}
          variant='standard'
          size={size}
          sx={{ width: isSmall ? '300px' : '180px' }}
        />
        <Box sx={{ marginLeft: '8px' }}>
          <IconButton
            onClick={() => {
              onClose();
              setIsEditing(false);
            }}
            size={size}
            data-testid='cancel-button'
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
          <IconButton
            onClick={() => {
              onSave();
              setIsEditing(false);
            }}
            disabled={!isDirty || !isValid}
            size={size}
            data-testid='submit-button'
          >
            <CheckIcon fontSize='inherit' />
          </IconButton>
        </Box>
      </Stack>
    );
  }

  return (
    <Stack
      direction='row'
      sx={textContainerStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        setIsEditing(true);
      }}
    >
      {isSmall ? <Text.BodyLarge>{text}</Text.BodyLarge> : <Text.H2Bold>{text}</Text.H2Bold>}
      {text.length === 0 && <Text.BodyLarge color='text.disabled'>Enter {field}</Text.BodyLarge>}
      {isHovered && (
        <IconButton
          onClick={() => {
            setIsEditing(true);
          }}
          sx={editButtonStyle}
          size={size}
          data-testid={`edit-${field}-button`}
        >
          <ModeEditOutlineOutlined fontSize='inherit' />
        </IconButton>
      )}
    </Stack>
  );
};
