import { Button, Stack, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripeToken } from 'member/state/memberSelectors';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getStripeToken } from 'redux/actions/paymentMethod';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import PaymentUpdateForm from './PaymentUpdateForm';

enum Gateways {
  US = 'US',
  CANADA = 'Canada',
  TEAM = 'Teams',
  EU = 'Europe',
}

function Payment() {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { token, gateway } = useAppSelector(stripeToken());
  let publicToken: string | null = null;

  if (gateway) {
    if (gateway === Gateways.US) {
      publicToken = process.env.REACT_APP_STRIPE_PUBLIC_TOKEN_US;
    } else if (gateway === Gateways.CANADA) {
      publicToken = process.env.REACT_APP_STRIPE_PUBLIC_TOKEN_CANADA;
    } else if (gateway === Gateways.TEAM) {
      publicToken = process.env.REACT_APP_STRIPE_PUBLIC_TOKEN_TEAM;
    }
    // Europe gateway doesn't exist yet
    // else if (gateway === Gateways.EU) {
    //   publicToken = process.env.REACT_APP_STRIPE_PUBLIC_TOKEN_EU;
    // }
    //
  }

  useEffect(() => {
    // Set up PaymentIntent as soon as the page loads
    setLoading(true);

    dispatch(getStripeToken()).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  const stripePromise = typeof publicToken === 'string' ? loadStripe(publicToken) : null;

  return (
    <LoadingContainer loading={loading}>
      {token && stripePromise ? (
        <Elements stripe={stripePromise} options={{ clientSecret: token }}>
          <PaymentUpdateForm />
        </Elements>
      ) : (
        <Stack justifyContent='center' alignItems='center' spacing={2}>
          <Typography>
            Please contact Client Success or Sales to active your Connect Dashboard.
          </Typography>
          <Button
            variant='contained'
            onClick={() => {
              window.open('mailto:support@operto.com');
            }}
          >
            Contact Us
          </Button>
        </Stack>
      )}
    </LoadingContainer>
  );
}

export default Payment;
