import { logger } from 'lib/logger';
import { useCallback } from 'react';

// This hook should be replaced once the BE is implemented
export function useFilterLocalStorage<T extends URLSearchParams>() {
  const setFilters = useCallback((key: string, queryParams: T) => {
    try {
      return localStorage.setItem(key, queryParams.toString());
    } catch (err) {
      logger.error(err);
    }
  }, []);

  const getFilters = useCallback((key: string) => {
    try {
      return new URLSearchParams(localStorage.getItem(key) ?? '');
    } catch (err) {
      logger.error(err);
    }
  }, []);

  return {
    setFilters,
    getFilters,
  };
}
