import { Box, Typography } from '@mui/material';
import { guestVerificationEnabledSelector } from 'company/state/companySelectors';
import { utcToTimeZone } from 'helper/date';
import * as React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IReservation, VerificationStatusText } from 'reservation/reservationType';
import { updateGuestReservationVerification } from 'reservation/state/reservationActions';
import MemberMenuItem from 'ui-library/Components/menu/MemberMenuItem';
import { showAccessCodeSelector } from 'user/state/userSelectors';
import ReservationDropdownDrawer from './ReservationDropdownDrawer';

export interface ContentListItemProps {
  reservation: IReservation;
  timezone: string;
}

const ContentListItem = ({ reservation, timezone }: ContentListItemProps) => {
  const dispatch = useAppDispatch();
  const showAccessCode = useAppSelector(showAccessCodeSelector());
  const guestVerificationEnabled = useAppSelector(guestVerificationEnabledSelector());

  const handleVerify = (reservation: IReservation) => {
    dispatch(
      updateGuestReservationVerification(!reservation?.incident_payment_verified, reservation),
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '24px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '24px',
        }}
      >
        <MemberMenuItem fullName={reservation?.guest_name} />
        {reservation && (
          <ReservationDropdownDrawer
            accessCode={reservation?.guest_access_code?.toString()}
            reservation={reservation}
            verifyOnClick={() => handleVerify(reservation)}
            verifyDisabled={showAccessCode}
            paymentInfo={reservation?.payment_info}
            showEdit
            isFillLight
          />
        )}
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography variant='body2'>
          Check-in: {utcToTimeZone(reservation?.check_in, timezone, 'yyyy MMM dd hh:mm a')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography variant='body2'>
          Check-out: {utcToTimeZone(reservation?.check_out, timezone, 'yyyy MMM dd hh:mm a')}
        </Typography>
      </Box>
      <Typography variant='body2'>
        Access Code: {reservation?.guest_access_code || 'N/A'}
      </Typography>
      {guestVerificationEnabled && (
        <Typography variant='body2'>
          Verification Status:{' '}
          {reservation?.verification_status
            ? VerificationStatusText[reservation?.verification_status]
            : '  N/A'}
        </Typography>
      )}
    </Box>
  );
};

export default ContentListItem;
