import { SvgIconComponent } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Icon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  SxProps,
  Typography,
} from '@mui/material';
import { useStyles } from 'Common/Sidebar/DrawerComponentStyles';
import clsx from 'clsx';
import React from 'react';

interface SideBarAccordionItemProps {
  isExpanded: boolean;
  open: boolean;
  summaryIcon: SvgIconComponent | string;
  summaryName: React.ReactNode;
  children: React.ReactNode;
  onAccordionClick?: React.MouseEventHandler<HTMLDivElement>;
  onMenuClick: (e: React.MouseEvent, link: string) => void;
  MenuItemLinks: { title: string; url: string }[];
}

const SideBarAccordionItem = ({
  open,
  summaryName,
  summaryIcon: IconComponent,
  children,
  isExpanded,
  onAccordionClick,
  MenuItemLinks,
  onMenuClick,
}: SideBarAccordionItemProps) => {
  const classes = useStyles();
  const customStyle: SxProps = {
    color: isExpanded ? 'primary.main' : 'text.secondary',
    fill: isExpanded ? 'primary.main' : 'text.secondary',
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [summaryExpanded, setSummaryExpanded] = React.useState(isExpanded);

  return (
    <>
      {open && (
        <Accordion
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '&:before': {
              height: 0,
            },
            '&.Mui-expanded': {
              margin: 0,
            },
          }}
          onClick={e => {
            onAccordionClick?.(e);
            setSummaryExpanded(!summaryExpanded);
          }}
          expanded={open && summaryExpanded}
        >
          <AccordionSummary
            sx={{
              height: '36px',
              minHeight: '36px',
              borderRadius: '48px',
              marginLeft: '13px',
              marginRight: '13px',
              '&.MuiSvgIcon-root': {
                fill: 'primary.main',
              },
              '&.Mui-expanded': {
                minHeight: '36px',
              },
              '&:hover': {
                backgroundColor: 'primary.p8',
                color: 'text.primary',
              },
              '&:hover span': {
                color: 'primary.main',
                fill: 'primary.main',
              },
            }}
            expandIcon={open ? <ExpandMoreIcon /> : null}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Icon
                className={clsx({ [classes.drawCloseIcon]: !open })}
                sx={{
                  ...customStyle,
                  marginRight: '10px',
                  marginLeft: '4px',
                }}
              >
                <IconComponent />
              </Icon>
              <Box style={{ display: open ? 'block' : 'none' }}>
                <Typography variant='body2'>{summaryName}</Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      )}
      {!open && (
        <>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              cursor: 'pointer',
              height: '36px',
              minHeight: '36px',
              borderRadius: '48px',
              '&:hover': {
                backgroundColor: 'primary.p8',
                color: 'text.primary',
              },
              '&:hover span': {
                color: 'primary',
                fill: 'primary',
              },
            }}
            onClick={e => {
              setAnchorEl(e.currentTarget);
              setOpenMenu(true);
            }}
          >
            <Icon
              className={clsx({ [classes.drawCloseIcon]: !open })}
              sx={{
                ...customStyle,
              }}
            >
              <IconComponent />
            </Icon>
          </Box>
          <Paper
            sx={{
              width: '122px',
              display: anchorEl && openMenu ? 'flex' : 'none',
            }}
          >
            <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setOpenMenu(false)}>
              {MenuItemLinks.map(MenuItemElement => (
                <MenuItem
                  key={MenuItemElement.title}
                  onClick={e => {
                    onMenuClick(e, MenuItemElement.url), setOpenMenu(false);
                  }}
                >
                  <ListItemText>{MenuItemElement.title}</ListItemText>
                </MenuItem>
              ))}
            </Menu>
          </Paper>
        </>
      )}
    </>
  );
};

export default SideBarAccordionItem;
