import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { PropertyFilterType, PropertyQueryFilterType } from 'property/propertyType';
import { getProperties } from 'property/state/propertyActions';
import { getPropertiesByFilter } from 'property/state/propertySelectors';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PaginatedTable, PaginatedTableProps } from 'ui-library/Components/table/PaginatedTable';

const columns: GridColDef[] = [
  { field: 'room_type', headerName: 'Room Type', flex: 1 },
  { field: 'name', headerName: 'Description', flex: 1 },
];

type RoomTypesTableProps = Omit<PaginatedTableProps, 'rows' | 'rowCount' | 'columns' | 'onFetch'>;

const RoomTypesTable = (props: RoomTypesTableProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { properties, totalProperties, isLoading } = useAppSelector(
    getPropertiesByFilter({ filterType: PropertyFilterType.ROOM_TYPE }),
  );

  const handleFetch = useCallback(
    (pageNum: number, numPerPage: number) => {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.set(PropertyQueryFilterType.PAGE_NUMBER, `${pageNum}`);
      urlSearchParams.set(PropertyQueryFilterType.NUMBER_PER_PAGE, `${numPerPage}`);
      dispatch(
        getProperties({
          filterType: PropertyFilterType.ROOM_TYPE,
          urlSearchParams,
        }),
      );
    },
    [dispatch],
  );

  const handleCellClick = (gridCellParams: GridCellParams) => {
    navigate(`/room-types/${gridCellParams.row.room_type}/${gridCellParams.row.id}`);
  };

  return (
    <PaginatedTable
      loading={isLoading}
      rows={properties}
      rowCount={totalProperties}
      columns={columns}
      onFetch={handleFetch}
      onCellClick={handleCellClick}
      {...props}
    />
  );
};

export default RoomTypesTable;
