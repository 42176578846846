import { MediaFile, Service } from '@operto/media-shared';
import { NeoGatewayV2RTKQapi } from 'redux/rtkq-api';
import { ResponseData } from 'redux/rtkq-utils';
import type { MediaResponseJSON } from './types';

export const MEDIA_TAG = 'Media';

export type UpdateDetailsQueryParams = {
  service: Service;
  media: MediaFile;
};

const mediaApiWithTag = NeoGatewayV2RTKQapi.enhanceEndpoints({
  addTagTypes: [MEDIA_TAG],
});

export const mediaServiceApi = mediaApiWithTag.injectEndpoints({
  endpoints: builder => ({
    uploadMedia: builder.mutation<ResponseData<MediaResponseJSON>, UpdateDetailsQueryParams>({
      query: ({ service, media }) => {
        const formData = new FormData();
        formData.append('service', service);
        formData.append('mediaFiles', media);
        return {
          url: 'media',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: [{ type: MEDIA_TAG }],
    }),
  }),
});

export const { useUploadMediaMutation } = mediaServiceApi;
