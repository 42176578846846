import { Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { trpc } from '@operto/trpc-client';
import { logger } from 'lib/logger';
import React, { useEffect } from 'react';
import { Table } from 'ui-library/Components/table/Table';
import { CustomTooltip } from '../Common/CustomTooltip';

const SystemVariablePage = () => {
  const renderVariableName = (params: GridRenderCellParams) => {
    return <Typography variant='body2'>{params.row.name}</Typography>;
  };

  const {
    data: systemVariables,
    isLoading,
    isError,
    error,
  } = trpc.variables.getVariables.useQuery({
    type: 'system',
    enabled: true,
  });

  useEffect(() => {
    if (isError) logger.error(error);
  }, [isError, error]);

  const renderTextWithHover = (params: GridRenderCellParams) => {
    return (
      <CustomTooltip
        data-testid='system-variable-table-tooltip'
        title={params?.row?.description}
        placement='bottom'
        leaveDelay={100}
        followCursor
      >
        <Typography data-testid='system-variable-table-description' fontSize={14}>
          {params?.row?.description}
        </Typography>
      </CustomTooltip>
    );
  };

  const columnsDef: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Variable Name',
      flex: 3,
      sortable: false,
      renderCell: renderVariableName,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 5,
      sortable: false,
      renderCell: renderTextWithHover,
    },
  ];

  return (
    <Table
      loading={isLoading}
      disableColumnMenu
      hideFooterRowCount
      rows={systemVariables ?? []}
      columns={columnsDef}
      disableSelectionOnClick
    />
  );
};

export default SystemVariablePage;
