import styled from 'styled-components';
import styledBy from 'styled-by';
import { DataGridPro } from '@mui/x-data-grid-pro';

export const ActionDataGrid = styled(DataGridPro)<{ actionbar?: boolean }>`
  && {
    background-color: ${props => props.theme.containerAccentBGColor};
    ${styledBy(
      'actionbar',
      () => `
      border-top-left-radius: 0 0;
      border-top-right-radius: 0 0;
      border-top:none;
    `,
    )};
    .MuiDataGrid-columnHeaderTitle {
      color: ${props => props.theme.palette.primary};
    }
    .MuiDataGrid-footerContainer {
      justify-content: flex-end;
    }
    .MuiDataGrid-selectedRowCount {
      display: none;
    }
    .MuiDataGrid-row .MuiDataGrid-cell:last-child {
      padding-right: 24px;
    }
    .MuiDataGrid-row .MuiDataGrid-cell:first-child {
      padding-left: 24px;
    }
  }
`;
