import { GridEnrichedColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@operto/ui';
import { getTemperature } from 'Common/Tables/Properties/PropertyDataHelpers';
import { LockCell, NoiseCell, OccupancyCell } from 'Common/Tables/Properties/TableCells';
import { PropertyFilterType, PropertyQueryFilterType } from 'property/propertyType';
import { getProperties } from 'property/state/propertyActions';
import { getPropertiesByFilter } from 'property/state/propertySelectors';
import * as React from 'react';
import { useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import LinkCell from '../PropertyAlerts/Components/LinkCell';

const renderLock = (params: GridRenderCellParams) => (
  <LockCell isLocked={params.row.is_locked} isOnline={params.row.devices[0]?.is_online} />
);

const renderTemp = (params: GridRenderCellParams) => <Text>{getTemperature(params.row)}</Text>;

const renderNoise = (params: GridRenderCellParams) => (
  <NoiseCell noiseLevel={params.row.noiseLevel} />
);

const renderOccupancy = (params: GridRenderCellParams) => (
  <OccupancyCell occupancyLevel={params.row.occupancyLevel} />
);

const renderUnitName = (params: GridRenderCellParams) => (
  <LinkCell text={params.row.name} url={`/units/${params.row.id}`} />
);

const columns = (): GridEnrichedColDef[] => [
  {
    field: 'name',
    headerName: 'Unit Name',
    flex: 3,
    sortable: false,
    renderCell: renderUnitName,
  },
  { field: 'id', headerName: 'Property ID', flex: 1, sortable: false },
  {
    field: 'lockStatus',
    headerName: 'Lock',
    width: 100,
    renderCell: renderLock,
    sortable: false,
  },
  {
    field: 'temperature',
    headerName: 'Temp',
    width: 100,
    renderCell: renderTemp,
    sortable: false,
  },
  {
    field: 'noiseLevel',
    headerName: 'Noise',
    description: 'Noise level of the unit',
    renderCell: renderNoise,
    sortable: false,
    width: 100,
  },
  {
    field: 'property_type',
    headerName: 'Occupancy',
    description: 'Occupancy level of the unit',
    renderCell: renderOccupancy,
    sortable: false,
    flex: 1.2,
  },
];

const AvailableCleaningTable = () => {
  const dispatch = useAppDispatch();

  const { properties, totalProperties, isLoading } = useAppSelector(
    getPropertiesByFilter({
      filterType: PropertyFilterType.CLEANING,
    }),
  );

  const handleFetch = useCallback(
    (pageNum: number, numPerPage: number, searchValue?: string) => {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.set(PropertyQueryFilterType.PAGE_NUMBER, `${pageNum}`);
      urlSearchParams.set(PropertyQueryFilterType.NUMBER_PER_PAGE, `${numPerPage}`);
      if (searchValue) {
        urlSearchParams.set(PropertyQueryFilterType.KEYWORD, searchValue);
      }
      dispatch(
        getProperties({
          filterType: PropertyFilterType.CLEANING,
          urlSearchParams,
        }),
      );
    },
    [dispatch],
  );

  return (
    <PaginatedTable
      enableToolbar={!isMobile}
      loading={isLoading}
      rows={properties}
      rowCount={totalProperties}
      columns={columns()}
      onFetch={handleFetch}
    />
  );
};

export default AvailableCleaningTable;
