export enum SettingType {
  checkinOverview = 'CHECKIN_OVERVIEW',
  todaysCheckins = 'TODAYS_CHECKINS',
  currentPropertyAlerts = 'CURRENT_PROPERTY_ALERTS',
  pastPropertyAlerts = 'PAST_PROPERTY_ALERTS',
  complianceGraph = 'COMPLIANCE_GRAPH',
  favorites = 'FAVORITES',
  masterActivityFeeds = 'MASTER_ACTIVITY_FEEDS',
  makeAvailable = 'MAKE_AVAILABLE',
  multipleNetatmos = 'MULTIPLE_NETATMO',
}

export interface ConfigState {
  list: SettingType[];
}
