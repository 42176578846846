import { Variable } from '@operto/variables-shared';
import format from 'date-fns/format';

export const customVariableExportPrep = (customVariable: Variable) => {
  if (!customVariable) return;

  const refinedVarList = ['Property Name,Group,Value\n'];
  Object.keys(customVariable.properties).forEach(propertyId => {
    const variable = customVariable.properties[propertyId];
    refinedVarList.push(`${variable.name},${variable.group},${variable.value}\n`);
  });
  return refinedVarList;
};

export const downloadCustomVariableCSV = (customVar: Variable) => {
  if (!customVar) return;

  const date = format(customVar.updatedAt, 'yyyyMMdd_hhmm');

  const allRows = customVariableExportPrep(customVar);
  const file = new Blob(allRows, { type: 'text/plain' });

  const element = document.createElement('a');
  element.href = URL.createObjectURL(file);
  element.download = `${customVar.name.replace(' ', '_')}_${date}.csv`;

  document.body.appendChild(element);
  element.click();
};
