import { ApplicationState } from '../reducers';

export const getTimeZone = () => (state: ApplicationState) => {
  const defaultTimeZone = 'America/Vancouver';
  const property = state.properties.byId[state.ui.propertyDetailId];
  if (property && property.address && property.address.timezone) {
    return property.address.timezone;
  }
  return defaultTimeZone;
};
