import { Stack, SxProps } from '@mui/material';
import React from 'react';

const AdminPanelIcon = ({ styles }: { styles?: SxProps }) => (
  <Stack sx={{ width: 18, height: 18, ...styles }}>
    <img src={'/img/icon-admin-panel.svg'} />
  </Stack>
);

export default AdminPanelIcon;
