import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { MoreFavorites } from '../../../types/ui';
import { Actions } from '../../../types/actions';

const show: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.hideViewMoreFavorites:
      return false;
    case Actions.showViewMoreFavorites:
      return !state;
    default:
      return state;
  }
};
const unitIds: Reducer<number[]> = (state = [], action) => {
  switch (action.type) {
    case Actions.showViewMoreFavorites:
      return action.unitIds;
    default:
      return state;
  }
};

const moreFavorites: Reducer<MoreFavorites> = combineReducers<MoreFavorites>({
  show,
  unitIds,
});

export default moreFavorites;
