import { Stack, Grid, Typography, Divider } from '@mui/material';
import React from 'react';

type IntegrationsSectionsProps = {
  title: string;
  children: React.ReactNode | React.ReactNode[];
};

export const IntegrationsSections = ({ title, children }: IntegrationsSectionsProps) => {
  const integrationCards = Array.isArray(children) ? children : [children];

  return (
    <>
      <Stack direction='row' alignItems='center' spacing={2} mb={2}>
        <Typography variant='subtitle2'>{title}</Typography>
        <Divider sx={{ flex: 1 }} />
      </Stack>
      <Grid container spacing={2} mb={3}>
        {integrationCards.map((child, index) => (
          <Grid item xs={12} md={6} lg={4} xl={3} key={`${title}-${index}`}>
            {child}
          </Grid>
        ))}
      </Grid>
    </>
  );
};
