// @ts-nocheck - revisit this
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Avatar, Box, Tab, Tabs, Typography } from '@mui/material';
import SlidebarAlert from 'Common/Slidebar/SlidebarAlert';
import RightSideTopbar from 'Common/Slidebar/components/RightSideTopbar';
import { OpertoLogger } from 'Logger/logger';
import FeaturesContainer from 'Pages/AddProperty/TabContainers/FeaturesContainer';
import GeneralContainer from 'Pages/AddProperty/TabContainers/GeneralContainer';
import OffsetsContainer from 'Pages/AddProperty/TabContainers/OffsetsContainer';
import ReviewContainer from 'Pages/AddProperty/TabContainers/ReviewContainer';
import { BaseContainer } from 'Style/Components/BaseComponents';
import { getCompanyId } from 'company/state/companySelectors';
import { Property, emptyProperty } from 'property/propertyType';
import * as React from 'react';
import { toggleSlidebar as toggleSlidebarAction } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import SimpleReactValidator from 'simple-react-validator';
import styled from 'styled-components';
import { SlidebarType } from 'types/ui';
import {
  ActionButton,
  ActionButtonsContainer,
  HeaderContainer,
  SideBarContent,
} from 'ui-library/Styled/sidebar';
import { TabWrapper } from 'ui-library/Styled/wrappers';
import { createProperty } from '../../api/propertyAPI';

export interface AddNewPropertyFormProps {
  handleClose: () => void;
}

enum TabIds {
  GENERAL,
  OFFSETS,
  FEATURES,
  REVIEW,
}

function TabLabel(number: number, label: string, selected: boolean, tabIdx: number) {
  return (
    <Box display='inline-flex' alignItems='center'>
      {tabIdx + 1 <= number && <NewPropertyAvatar selected={selected}>{number}</NewPropertyAvatar>}
      {tabIdx + 1 > number && <CheckCircleIcon />}

      <NewPropertyTabLabel variant='body2' selected={selected}>
        {label}
      </NewPropertyTabLabel>
    </Box>
  );
}

const AddNewPropertyForm = (props: AddNewPropertyFormProps) => {
  const dispatch = useAppDispatch();

  const companyId = useAppSelector(getCompanyId());

  const [errorMessage, setErrorMessage] = React.useState('');
  const [tabIdx, setTabIdx] = React.useState(0);

  const validator = new SimpleReactValidator();

  const [propertyData, setPropertyData] = React.useState<Property>(emptyProperty);

  React.useEffect(() => {
    setPropertyData(prev => {
      return { ...prev, company_id: companyId };
    });
  }, [companyId]);

  const handleChange = (event: React.ChangeEvent, newValue: number) => {
    setTabIdx(newValue);
  };

  const handleUpdate = (id: string, value: string) => {
    const newData: object = {};
    Object.assign(newData, propertyData);
    newData[id] = value;
    setPropertyData(newData as Property);
  };

  const onNextClick = () => {
    if (validator.allValid()) {
      setTabIdx(tabIdx + 1);
      setErrorMessage('');
    } else {
      setErrorMessage('Please fill out all required fields');
    }
  };

  const onPreviousClick = () => {
    setTabIdx(tabIdx - 1);
  };

  const onDataSave = () => {
    createProperty(propertyData)
      .then(() => {
        // dispatch(getProperties(propertyData));
        dispatch(toggleSlidebarAction(SlidebarType.CLOSE, ''));
      })
      .catch(err => {
        OpertoLogger.Log(err);
      });
  };

  const handleFieldBlur = () => {
    if (validator.allValid()) {
      setErrorMessage('');
    }
  };

  return (
    <MainContainer>
      <HeaderContainer>
        <RightSideTopbar title='Add New Property' handleClose={props.handleClose} />
        <TabWrapper>
          <Tabs value={tabIdx} onChange={handleChange}>
            <Tab label={TabLabel(1, 'General', tabIdx === TabIds.GENERAL, tabIdx)} />
            <Tab
              label={TabLabel(2, 'Offsets', tabIdx === TabIds.OFFSETS, tabIdx)}
              disabled={tabIdx < TabIds.OFFSETS}
            />
            <Tab
              label={TabLabel(3, 'Features', tabIdx === TabIds.FEATURES, tabIdx)}
              disabled={tabIdx < TabIds.FEATURES}
            />
            <Tab
              label={TabLabel(4, 'Review', tabIdx === TabIds.REVIEW, tabIdx)}
              disabled={tabIdx < TabIds.REVIEW}
            />
          </Tabs>
        </TabWrapper>
        <SlidebarAlert severity='error' message={errorMessage} />
      </HeaderContainer>
      <SideBarContent>
        {(() => {
          switch (tabIdx) {
            case TabIds.GENERAL:
              return (
                <GeneralContainer
                  data={propertyData}
                  onChange={handleUpdate}
                  onFieldBlur={handleFieldBlur}
                  validator={validator}
                />
              );
            case TabIds.OFFSETS:
              return (
                <OffsetsContainer
                  data={propertyData}
                  onChange={handleUpdate}
                  validator={validator}
                />
              );
            case TabIds.FEATURES:
              return <FeaturesContainer data={propertyData} onChange={handleUpdate} />;
            case TabIds.REVIEW:
              return <ReviewContainer data={propertyData} />;
            default:
              return (
                <GeneralContainer
                  data={propertyData}
                  onChange={handleUpdate}
                  validator={validator}
                />
              );
          }
        })()}
      </SideBarContent>
      <ActionButtonsContainer>
        {tabIdx > TabIds.GENERAL && (
          <ActionButton onClick={onPreviousClick} variant='contained'>
            Previous
          </ActionButton>
        )}
        {tabIdx < TabIds.REVIEW && (
          <ActionButton onClick={onNextClick} variant='contained' color='primary'>
            Next
          </ActionButton>
        )}
        {tabIdx === TabIds.REVIEW && (
          <ActionButton onClick={onDataSave} type='submit' variant='contained' color='primary'>
            Save New Property
          </ActionButton>
        )}
      </ActionButtonsContainer>
    </MainContainer>
  );
};

const NewPropertyAvatar = styled(Avatar)<{ selected: boolean }>`
  && {
    width: 24px;
    height: 24px;
    font-size: inherit;
    color: ${props => (props.selected ? props.theme.textColorWhite : '')};
    background-color: ${props => (props.selected ? props.theme.tabColorActive : '')};
  }
`;

const NewPropertyTabLabel = styled(Typography)<{ selected: boolean }>`
  && {
    text-transform: none;
    margin: 8px;
    color: ${props => (props.selected ? props.theme.palette.primary : '')};
  }
`;

const MainContainer = styled(BaseContainer)`
  && {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export default AddNewPropertyForm;
