import {
  SecurityDepositData,
  SecurityDeposit,
  Meta,
  ProductVariants,
  SecurityDepositTableData,
} from './models';

export const parseSecurityDeposit = (security_deposit: SecurityDepositData): SecurityDeposit => {
  const data: SecurityDepositTableData = parseProductVariants(
    security_deposit.records.product_variants.product_variants,
  );

  const meta: Meta = {
    pageNum: security_deposit.pageNum,
    numPerPage: security_deposit.numPerPage,
    total_records: security_deposit.total_product_variants,
  };

  const stateObj = {
    meta,
    data,
  };

  return stateObj;
};

export const parseProductVariants = (
  product_variants: ProductVariants[],
): SecurityDepositTableData => {
  const data = product_variants.map(record => {
    return {
      unit: record.property_name,
      id: record.assigned_property_id,
      securityDeposit: record.price,
      roomType: record.room_type,
      productId: record.product_id,
    };
  });

  return data;
};
