import axios from 'axios';

const api = process.env.REACT_APP_API_URL;

export const getIntercom = (propertyId: number) =>
  axios.get(`${api}/v2/properties/${propertyId}/intercom`);

export const editIntercom = (data: Record<string, unknown>, propertyId: number) =>
  axios.put(`${api}/v2/properties/${propertyId}/intercom`, data);

export const getPropertyIntercom = (propertyId: number) =>
  axios.get(`${api}/v2/properties/${propertyId}/intercom`);
