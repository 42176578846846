import { AxiosResponse } from 'axios';

// Returns a Promise that resolves after "ms" Milliseconds;
const timer = (ms: number) => new Promise(res => setTimeout(res, ms));

type PollParams = {
  fn: () => Promise<AxiosResponse>;
  validate: (result: AxiosResponse) => boolean;
  interval: number;
  maxAttempts: number;
};

export const poll = async ({ fn, validate, interval, maxAttempts }: PollParams) => {
  for (let attempts = 0; attempts < maxAttempts; attempts++) {
    const result = await fn();

    if (validate(result)) {
      return result;
    }

    // wait for interval before polling again
    await timer(interval);
  }

  throw new Error('Exceeded max attempts');
};
