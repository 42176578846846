import axios from 'axios';

const api = process.env.REACT_APP_API_URL;

// export const getReservations = (id: number) => axios.get(`${api}/v2/reservations/${id}`);

export const getReservations = () => axios.get(`${api}/v2/company/reservations`);

export const createReservation = (reservation: {
  property_id: number;
  guest_name: string;
  guest_count: number;
  guest_email: string;
  guest_mobile: string;
  check_in: string;
  check_out: string;
}) => axios.post(`${api}/v2/reservations`, reservation);

// TODO: need to pass all 4 values for now till back end updates
export const updateReservation = (reservation_id: number, payload: object) => {
  return axios.put(`${api}/v2/reservations/${reservation_id}`, payload);
};

export const deleteReservation = (reservation_id: number) =>
  axios.delete(`${api}/v2/reservations/${reservation_id}`);

export const getReservationAccessCode = (id: number) =>
  axios.get(`${api}/v2/reservations/${id}/accesscodes`);

// TODO: this should be a put
export const cancelPmsReservation = (reservationId: number) =>
  axios.post(`${api}/v2/reservations/${reservationId}/revoke`);

export const grantPmsReservation = (reservationId: number) =>
  axios.post(`${api}/v2/reservations/${reservationId}/grant`);

export const updateIncidentalPayment = (reservationId: number, incidentalPayment: boolean) =>
  axios.put(`${api}/reservation/${reservationId}/incidental-payment`, {
    incidental_payment: incidentalPayment,
  });

export const unblockPmsReservationCore = (reservationId: string) =>
  axios.post(`${api}/v2/reservations/${reservationId}/grant`);

export const hidePmsReservationCore = (reservationId: string) =>
  axios.post(`${api}/v2/reservations/${reservationId}/hide`);

export const patchManuallyOverrideVerification = (reservationId: string | number) =>
  axios.patch(`${api}/v2/reservation/${reservationId}/verification/manually-confirm`);

export const patchSecurityDepositManual = (reservationId: number, notes: string, status: string) =>
  axios.patch(`${api}/v2/reservations/${reservationId}/security-deposit`, {
    status,
    notes,
  });
