import LocationsTable from 'Common/Tables/Locations/LocationsTable';
import { logger } from 'lib/logger';
import { getGroupsPaginated } from 'location/state/LocationsActions';
import { getAllLocationsPaginatedWithFilter } from 'location/state/LocationsSelectors';
import { getProperties } from 'property/state/propertyActions';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Actions } from 'types/actions';
import { FilterType, Group } from 'types/groups';

const SaltoGroupsPage = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const accessGroups: Group[] = useAppSelector(
    getAllLocationsPaginatedWithFilter(FilterType.ACCESS_GROUP) as (state: unknown) => Group[],
  );

  useEffect(() => {
    const getSaltoGroupsAsync = async () => {
      try {
        setLoading(true);
        await dispatch(getGroupsPaginated({ locationType: FilterType.ACCESS_GROUP }));
        await dispatch(getProperties());
        dispatch({
          type: Actions.updateGroupType,
          groupType: FilterType.ACCESS_GROUP,
        });
      } catch (error) {
        logger.error(error);
      }
      setLoading(false);
    };
    getSaltoGroupsAsync();
  }, [dispatch]);

  return <LocationsTable isGroupAccess groups={accessGroups} loading={loading} />;
};

export default SaltoGroupsPage;
