import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import TabBar, { TabBarProps, TabItems } from 'ui-library/Components/tabBar/TabBar';

export enum VARIABLES_URLS {
  SYSTEM = '/settings/variables/system',
  CUSTOM = '/settings/variables/custom',
}
export enum VariablesTabItem {
  System = 0,
  Custom = 1,
}

export const Variables = () => {
  const [activeTab, setActiveTab] = useState<VariablesTabItem>(VariablesTabItem.System);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    if (pathname.includes(VARIABLES_URLS.SYSTEM)) {
      setActiveTab(VariablesTabItem.System);
    } else {
      setActiveTab(VariablesTabItem.Custom);
    }
  }, [location]);

  const tabItems = [
    {
      label: 'System',
      value: VariablesTabItem.System,
      isDropDown: false,
    },
    { label: 'Custom', value: VariablesTabItem.Custom, isDropDown: false },
  ];

  const setTabIndex = (tabValue: number) => {
    // if sub-menu, needs to be rounded down
    selectFilter(tabValue);
  };

  const selectFilter = (index: number) => {
    switch (index) {
      case VariablesTabItem.System:
        navigate(VARIABLES_URLS.SYSTEM);
        break;
      case VariablesTabItem.Custom:
        navigate(VARIABLES_URLS.CUSTOM);
        break;
      default:
        navigate(VARIABLES_URLS.SYSTEM);
        break;
    }
  };

  return (
    <>
      <TabBar
        tabItems={tabItems as TabItems[]}
        tabIndex={activeTab}
        onTabChange={setTabIndex}
        onSubTabChange={selectFilter as TabBarProps['onSubTabChange']}
      />
      <Outlet />
    </>
  );
};

export default Variables;
