// @ts-nocheck
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Grid, Switch, Typography } from '@mui/material';
import { SettingCard } from '@operto/ui-library';
import { ColorPicker } from 'Common/ColorPicker';
import React from 'react';
import { Theme, useGuestPortal } from 'redux/guestportal';
import { ImageUploadType } from 'redux/guestportal/models';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';
import AddCard from 'ui-library/Components/card/AdderCard';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { userPermissionSelector } from 'user/state/userSelectors';
import ImageEditor from '../Common/GPImageEditor';

type BrandingCardProps = {
  branding: Theme;
  onSave: () => void;
  onChange: (url: string) => void;
  permission?: boolean;
};
const PropertyLogoCard: React.FC<BrandingCardProps> = props => {
  const { branding, onSave, onChange, permission } = props;

  return (
    <SettingCard
      title='Primary Logo'
      subtitle='Upload your property logo or brandmark'
      description='Your logo or brandmark will apply to all units'
      footerChildren={
        <Button
          variant='contained'
          color='primary'
          startIcon={<SaveIcon />}
          onClick={() => {
            onSave();
          }}
          disabled={!permission}
        >
          Save
        </Button>
      }
    >
      <ImageEditorContainer>
        <ImageEditor
          image={branding?.companyLogo ?? ''}
          onImageChange={url => onChange(url)}
          imageType={ImageUploadType.COMP_LOGO}
        />
      </ImageEditorContainer>
    </SettingCard>
  );
};

const ImageEditorContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const BrandColorCard: React.FC<BrandingCardProps> = props => {
  const { branding, onSave, onChange, permission } = props;
  const [customBranding, setCustomBranding] = React.useState(branding);
  React.useEffect(() => {
    setCustomBranding(branding);
  }, [branding]);

  return (
    <SettingCard
      title='Brand Color'
      subtitle='Set your primary color.'
      description='Your brand color will be used for buttons, accents, link colors.'
      footerChildren={
        <Button
          variant='contained'
          color='primary'
          startIcon={<SaveIcon />}
          onClick={() => {
            onSave();
          }}
          disabled={!permission}
        >
          Save
        </Button>
      }
    >
      <Box m={4}>
        <Typography align='left'>Click to set the color</Typography>
        <ColorPicker value={customBranding?.primaryColor} onChange={onChange} />
      </Box>
    </SettingCard>
  );
};

export interface WelcomeImageHeaderProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  branding: Theme;
}

const WelcomeImageHeader = (props: WelcomeImageHeaderProps) => {
  const { onChange, branding } = props;
  const [customBranding, setCustomBranding] = React.useState(branding);
  React.useEffect(() => {
    setCustomBranding(branding);
  }, [branding]);

  return customBranding ? (
    <CustomeHeader>
      <CustomeTitle>Welcome Images</CustomeTitle>
      <SwitchConteiner>
        <Switch
          checked={customBranding?.welcomeImagesEnabled}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.checked);
          }}
          color='primary'
          name='enable email'
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <Typography>Show</Typography>
      </SwitchConteiner>
    </CustomeHeader>
  ) : (
    <div>Loading...</div>
  );
};

const WelcocmeImagesCard: React.FC<BrandingCardProps> = props => {
  const { branding, onSave, onChange, permission } = props;

  return (
    <AddCard
      headerChildren={<WelcomeImageHeader onChange={onChange} branding={branding} />}
      footerChildren={
        <Button
          variant='contained'
          color='primary'
          startIcon={<SaveIcon />}
          onClick={() => {
            onSave();
          }}
          disabled={!permission}
        >
          Save
        </Button>
      }
    >
      <Typography variant='body2' align='left'>
        Need more room for your logo to shine? Disable your Welcome Images here.
      </Typography>
      <Box m={4}>
        <ImageContainer
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={2}
          style={{ opacity: branding?.welcomeImagesEnabled ? 1 : 0.16 }}
        >
          <Grid item xs={3}>
            <img src={'/img/welcome-step-one.png'} style={{ height: 90 }} />
          </Grid>
          <Grid item xs={3}>
            <img src={'/img/welcome-step-two.png'} style={{ height: 90 }} />
          </Grid>
          <Grid item xs={3}>
            <img src={'/img/welcome-step-three.png'} style={{ height: 90 }} />
          </Grid>
          <Grid item xs={3}>
            <img src={'/img/welcome-step-four.png'} style={{ height: 110 }} />
          </Grid>
        </ImageContainer>
      </Box>
    </AddCard>
  );
};

type BrandingProps = { visible: boolean };
export const Branding: React.FC<BrandingProps> = props => {
  const { theme, setTheme, fetchThemeData } = useGuestPortal();

  const [convertedTheme, setConvertedTheme] = React.useState<Theme>(theme);

  React.useEffect(() => {
    fetchThemeData();
  }, [fetchThemeData]);

  React.useEffect(() => {
    setConvertedTheme(theme);
  }, [theme]);

  const onSave = () => {
    setTheme(convertedTheme);
  };
  const colorChange = (color: string) => {
    setConvertedTheme({ ...convertedTheme, primaryColor: color });
  };
  const imageChange = (url: string) => {
    setConvertedTheme({ ...convertedTheme, companyLogo: url });
  };

  const welcomeImageChange = (checked: boolean) => {
    setConvertedTheme({ ...convertedTheme, welcomeImagesEnabled: checked });
  };

  const permission = useAppSelector(userPermissionSelector());
  const { visible } = props;
  if (!visible) {
    return null;
  }

  return (
    <>
      <LoadingContainer loading={!convertedTheme}>
        <PropertyLogoCard
          branding={convertedTheme}
          onChange={imageChange}
          onSave={onSave}
          permission={permission}
        />
      </LoadingContainer>
      <LoadingContainer loading={!convertedTheme}>
        <BrandColorCard
          branding={convertedTheme}
          onChange={colorChange}
          onSave={onSave}
          permission={permission}
        />
      </LoadingContainer>
      <LoadingContainer loading={!convertedTheme}>
        <WelcocmeImagesCard
          branding={convertedTheme}
          onChange={welcomeImageChange}
          onSave={onSave}
          permission={permission}
        />
      </LoadingContainer>
    </>
  );
};

export default Branding;

const ImageContainer = styled(Grid)`
  && {
    max-width: 600px;
    margin: 0 auto;
  }
`;

const CustomeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 0.5px solid ${props => props.theme.borderColor};
`;

const SwitchConteiner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomeTitle = styled.div``;
