import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Avatar, Box, Tab as MuiTab, Paper, Tabs, Typography } from '@mui/material';
import { paletteStyles } from '@operto/ui';
import { SideDrawer } from 'Common/Drawer/SideDrawer';
import RightSideTopbar from 'Common/Slidebar/components/RightSideTopbar';
import ActionSnackbar from 'Common/Snackbar/ActionSnackbar';
import GPPreviewContainer from 'Pages/GuestPortal/Preview/GPPreviewContainer';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useState } from 'react';
import { IEntranceInstructions, IEntranceInstructionsProperty } from 'redux/guestportal';
import TabTransition from 'ui-library/Components/transitions/TabTransition';
import { useGuestPortal } from '../../../../redux/guestportal/hooks';
import EntranceInstructionsFormTabInstructions from './EntranceInstructionsFormTabInstructions';
import EntranceInstructionsFormTabUnits from './EntranceInstructionsFormTabUnits';

export enum EntranceInstructionsFormTabItem {
  Instructions = 0,
  Units,
  Preview,
}

const TabLabel = (tabNumber: number, label: string, selected: boolean, tabIdx: number) => {
  return (
    <Box display='inline-flex' alignItems='center'>
      {tabIdx < tabNumber && (
        <Avatar
          sx={
            selected
              ? {
                  width: theme => theme.spacing(3),
                  height: theme => theme.spacing(3),
                  fontSize: 'inherit',
                  color: theme => theme.palette.getContrastText(theme.palette.primary.main),
                  backgroundColor: theme => theme.palette.primary.main,
                }
              : {
                  width: theme => theme.spacing(3),
                  height: theme => theme.spacing(3),
                  fontSize: 'inherit',
                }
          }
        >
          {tabNumber + 1}
        </Avatar>
      )}
      {tabIdx >= tabNumber && <CheckCircleIcon />}

      <Typography
        variant='body2'
        sx={
          selected
            ? {
                textTransform: 'none',
                margin: theme => theme.spacing(1),
                color: theme => theme.palette.primary.main,
              }
            : { textTransform: 'none', margin: theme => theme.spacing(1) }
        }
      >
        {label}
      </Typography>
    </Box>
  );
};

const getUpdatedProperties = (
  properties: IEntranceInstructionsProperty[],
  propertyList: IEntranceInstructionsProperty[],
) => {
  const updatedProperties: IPropertyUpdate[] = [];
  properties?.forEach(property => {
    const existingProperty = propertyList.find(prop => prop.propertyId === property.propertyId);
    if (
      existingProperty?.entrance_instructions_enabled !== property.entrance_instructions_enabled
    ) {
      updatedProperties.push({
        propertyId: property.propertyId,
        entrance_instructions_enabled: !property.entrance_instructions_enabled,
      });
    }
  });
  return updatedProperties;
};

const updateEntranceInstructionsCopy = (
  entranceInstructionsCopy: IEntranceInstructions,
  updatedProperties: IPropertyUpdate[],
) => {
  return {
    ...entranceInstructionsCopy,
    entrance_instructions_enabled: entranceInstructionsCopy.entrance_instructions_enabled.map(
      (record: { propertyId: number; enabled: boolean }) => {
        const matchingProperty = updatedProperties.find(
          prop => prop.propertyId === record.propertyId,
        );
        if (matchingProperty) {
          return {
            propertyId: record.propertyId,
            enabled: matchingProperty.entrance_instructions_enabled,
          };
        }
        return record;
      },
    ),
  };
};

type EntranceInstructionsFormContainerProps = {
  activeTab: EntranceInstructionsFormTabItem;
  onTabClick: (tab: EntranceInstructionsFormTabItem) => void;
  children?: React.ReactNode;
  handleClose: () => void;
  onSave?: (data: IEntranceInstructions) => void;
  data: IEntranceInstructions;
  onClose: () => void;
  openSnackbar: boolean;
  setOpenSnackbar: (value: boolean) => void;
};

export const EntranceInstructionsFormContainer = ({
  activeTab,
  onTabClick,
  children,
  handleClose,
  onSave,
  data,
  onClose,
  openSnackbar,
  setOpenSnackbar,
}: EntranceInstructionsFormContainerProps) => {
  const snackbarSeverity = 'error';
  const snackbarButtonText = 'YES';
  const snackbarMessage =
    'You have unsaved changes that will be lost if you exit. Are you sure you want to exit?';

  if (data === undefined) {
    return null;
  }

  return (
    <>
      <ActionSnackbar
        onClose={() => {
          setOpenSnackbar(false);
          onClose();
        }}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={snackbarSeverity}
        actionButtonText={snackbarButtonText}
        snackbarMessage={snackbarMessage}
      />

      <RightSideTopbar
        title='Entrance Instructions'
        handleClose={handleClose}
        onSave={onSave}
        data={data}
      />
      <Paper sx={{ boxShadow: 'none' }} elevation={2} square>
        <Tabs
          value={activeTab}
          onChange={(event: React.ChangeEvent, newValue: number) => onTabClick(newValue)}
          sx={{ color: 'text.primary' }}
        >
          <MuiTab
            label={TabLabel(
              EntranceInstructionsFormTabItem.Instructions,
              'Instructions',
              activeTab === EntranceInstructionsFormTabItem.Instructions,
              activeTab,
            )}
            sx={{ width: '160px' }}
          />
          <MuiTab
            label={TabLabel(
              EntranceInstructionsFormTabItem.Units,
              'Units',
              activeTab === EntranceInstructionsFormTabItem.Units,
              activeTab,
            )}
            sx={{ width: '160px' }}
          />
          <MuiTab
            label={TabLabel(
              EntranceInstructionsFormTabItem.Preview,
              'Preview',
              activeTab === EntranceInstructionsFormTabItem.Preview,
              activeTab,
            )}
            sx={{ width: '160px' }}
          />
        </Tabs>
      </Paper>
      <Box
        sx={{
          height: '1024px',
          backgroundColor: paletteStyles.palette.background.default01,
        }}
      >
        {children}
      </Box>
    </>
  );
};

interface IPropertyUpdate {
  propertyId: number;
  entrance_instructions_enabled: boolean;
}

export type EntranceInstructionsFormProps = {
  openDrawer?: boolean;
  properties?: IEntranceInstructionsProperty[];
  data?: IEntranceInstructions;
  onClose?: () => void;
  onFetch?: () => void;
};

export const EntranceInstructionsForm = ({
  openDrawer,
  properties,
  data,
  onClose,
  onFetch,
}: EntranceInstructionsFormProps) => {
  const { saveEntranceInstructions } = useGuestPortal();

  const [activeTab, setActiveTab] = useState<EntranceInstructionsFormTabItem>(
    EntranceInstructionsFormTabItem.Instructions,
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [propertyList, setPropertyList] = useState<IEntranceInstructionsProperty[]>([]);
  const [updatedProperties, setUpdatedProperties] = useState<IPropertyUpdate[]>([]);
  const [entranceInstructionsCopy, setEntranceInstructionsCopy] =
    useState<IEntranceInstructions>(data);

  const showTabTransition = [
    EntranceInstructionsFormTabItem.Instructions,
    EntranceInstructionsFormTabItem.Units,
    EntranceInstructionsFormTabItem.Preview,
  ].includes(activeTab);

  useEffect(() => {
    if (propertyList.length === 0 && properties) {
      setPropertyList(properties);
    }
  }, [propertyList, properties]);

  // observe changes in properties and set the list of properties to be updated
  useEffect(() => {
    if (properties && propertyList) {
      const updateProperties = getUpdatedProperties(properties, propertyList);
      setUpdatedProperties(updateProperties);
      setEntranceInstructionsCopy(prevData =>
        updateEntranceInstructionsCopy(prevData, updateProperties),
      );
    }
  }, [properties, propertyList]);

  const handleClose = () => {
    if (isEqual(data, entranceInstructionsCopy)) {
      onClose();
    } else {
      setOpenSnackbar(true);
    }
  };

  const handleUnitChange = (propertyIds: number[]) => {
    setPropertyList(prevPropertyList =>
      prevPropertyList.map(property => {
        const newProperty: IEntranceInstructionsProperty = {
          ...property,
          entrance_instructions_enabled: propertyIds.includes(property.propertyId),
        };
        return newProperty;
      }),
    );
  };

  const handleSave = useCallback(async () => {
    const newData: IEntranceInstructions = {
      ...entranceInstructionsCopy,
      entrance_instructions_enabled: updatedProperties.map(prop => ({
        propertyId: prop.propertyId,
        enabled: prop.entrance_instructions_enabled,
      })),
    };
    await saveEntranceInstructions(newData);
    onFetch?.();
    onClose();
  }, [saveEntranceInstructions, entranceInstructionsCopy, updatedProperties, onClose, onFetch]);

  return (
    <SideDrawer open={openDrawer} onClose={handleClose}>
      <Box>
        <EntranceInstructionsFormContainer
          activeTab={activeTab}
          onSave={handleSave}
          onTabClick={tab => setActiveTab(tab)}
          handleClose={handleClose}
          data={entranceInstructionsCopy}
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          onClose={() => {
            onClose();
            setPropertyList(properties);
          }}
        >
          <TabTransition show={showTabTransition}>
            <Box sx={{ height: '100%' }}>
              {activeTab === EntranceInstructionsFormTabItem.Instructions && (
                <EntranceInstructionsFormTabInstructions
                  entranceInstructions={entranceInstructionsCopy}
                  onEntranceInstructionsChange={setEntranceInstructionsCopy}
                />
              )}
              {activeTab === EntranceInstructionsFormTabItem.Units && (
                <EntranceInstructionsFormTabUnits data={propertyList} onChange={handleUnitChange} />
              )}
              {activeTab === EntranceInstructionsFormTabItem.Preview && (
                <GPPreviewContainer isEntranceInstructions={true} />
              )}
            </Box>
          </TabTransition>
        </EntranceInstructionsFormContainer>
      </Box>
    </SideDrawer>
  );
};

export default EntranceInstructionsForm;
