// @ts-nocheck

import React from 'react';
import { Grid, Typography } from '@mui/material';
import { AddCard } from '@operto/ui-library';
import { FormHeader } from 'ui-library/Styled/sidebar';

interface AccessCodesContainerInterface {
  data: unknown;
}

const AccessCodesContainer = ({ data }: AccessCodesContainerInterface) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <AddCard title='Contact Details'>
          <Grid container direction='column'>
            <FormHeader align='left'>Refresh this user&apos;s access code.</FormHeader>
            <Grid container direction='row' spacing={4}>
              <Grid item xs={6}>
                <Grid item xs={6}>
                  <Typography>Access Code: {data.member_access.pin_code}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Exit Code: {data.member_access.pin_code_task_done}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AddCard>
      </Grid>
    </Grid>
  );
};

export default AccessCodesContainer;
