import CheckIcon from '@mui/icons-material/Check';
import { Button, Grid, Switch } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { AddCard } from '@operto/ui-library';
import { Role } from 'Common/Tables/helpers';
import { sendMemberPasswordSetupEmail } from 'api/memberAPI';
import { AxiosResponse } from 'axios';
import { IMember, MemberSiteAccess } from 'member/memberType';
import React, { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { FormHeader } from 'ui-library/Styled/sidebar';
import { userSelector } from 'user/state/userSelectors';
import { UserRole, UserState } from 'user/userTypes';

export const roleCanGrantSelectedPermission = (
  loggedInMember: UserState,
  data: IMember,
  selectedPermisson: UserRole,
) => {
  const currentUserId = loggedInMember.user.id;
  const currentUserRole = loggedInMember.role;
  const selectedUserId = data.id;
  const selectedUserRole = data.role;

  const isSameUser = currentUserId === selectedUserId;
  const isAccountOwner = currentUserRole === Role.AccountOwner;
  const isGlobalManager = currentUserRole === Role.GlobalManager;
  const isRegionalManager = currentUserRole === Role.RegionalManager;
  const isBasicUser = currentUserRole === Role.BasicUser;

  const selectedIsAccountOwner = selectedUserRole === Role.AccountOwner;
  const selectedIsGlobalManager = selectedUserRole === Role.GlobalManager;
  const selectedIsBasicUser = selectedUserRole === Role.BasicUser;

  const selectedPermissionIsAccountOwner = selectedPermisson === Role.AccountOwner;
  const selectedPermissionIsGlobalManager = selectedPermisson === Role.GlobalManager;
  const selectedPermissionIsBasicUser = selectedPermisson === Role.BasicUser;

  if (isAccountOwner) return true;

  if (isSameUser) {
    if (isGlobalManager) {
      return !(selectedIsAccountOwner || selectedPermissionIsAccountOwner);
    }

    if (isRegionalManager) {
      return !(
        selectedIsAccountOwner ||
        selectedIsGlobalManager ||
        selectedPermissionIsAccountOwner ||
        selectedPermissionIsGlobalManager
      );
    }

    if (isBasicUser) {
      return selectedIsBasicUser && selectedPermissionIsBasicUser;
    }
  } else {
    if (isGlobalManager) {
      return !(
        selectedIsAccountOwner ||
        selectedIsGlobalManager ||
        selectedPermissionIsAccountOwner ||
        selectedPermissionIsGlobalManager
      );
    }

    if (isRegionalManager) {
      return selectedIsBasicUser && selectedPermissionIsBasicUser;
    }

    if (isBasicUser) {
      return selectedIsBasicUser && selectedPermissionIsBasicUser;
    }
  }

  return false;
};

interface PermissionsComponentProps {
  data: IMember;
  onChange: (field: string, value: unknown) => void;
}

const PermissionsComponent = ({ data, onChange }: PermissionsComponentProps) => {
  const [sendButton, setSendButton] = useState<boolean>(true);
  const [sendSuccess, setSendSuccess] = useState<boolean>(false);
  const loggedInMember: UserState = useAppSelector(userSelector());

  const hasDashboardAccess = data.site_access_id?.includes(MemberSiteAccess.DASHBOARD) ?? false;

  const valueChanger = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const field = 'role';
    onChange(field, value);
  };

  const dashboardAccessHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const siteAccessIds = data.site_access_id ?? [];
    const updatedSiteAccessIds = e.target.checked
      ? [...siteAccessIds, MemberSiteAccess.DASHBOARD]
      : siteAccessIds.filter(id => id !== MemberSiteAccess.DASHBOARD);

    onChange(e.target.name, e.target.checked);
    onChange('site_access_id', updatedSiteAccessIds);
  };

  const handleSendEmail = () => {
    setSendButton(false);

    sendMemberPasswordSetupEmail(data.id, data.email).then((response: AxiosResponse) => {
      if (response.status === 200) {
        setSendSuccess(true);
        setSendButton(true);
      } else {
        setSendButton(true);
      }
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <AddCard title='User Permissions'>
          <Grid>
            <FormHeader>Select what this user can or cannot access in their dashboard.</FormHeader>

            <RadioGroup>
              <FormControlLabel
                control={
                  <Radio
                    value='account-owner'
                    onChange={valueChanger}
                    checked={'role' in data && data.role === 'account-owner'}
                    disabled={
                      !roleCanGrantSelectedPermission(loggedInMember, data, Role.AccountOwner)
                    }
                  />
                }
                label='Account Owner'
              />
              <FormControlLabel
                control={
                  <Radio
                    value='global-manager'
                    onChange={valueChanger}
                    checked={'role' in data && data.role === 'global-manager'}
                    disabled={
                      !roleCanGrantSelectedPermission(loggedInMember, data, Role.GlobalManager)
                    }
                  />
                }
                label='Global Manager'
              />
              <FormControlLabel
                control={
                  <Radio
                    value='regional-manager'
                    onChange={valueChanger}
                    checked={'role' in data && data.role === 'regional-manager'}
                    disabled={
                      !roleCanGrantSelectedPermission(loggedInMember, data, Role.RegionalManager)
                    }
                  />
                }
                label='Regional Manager'
              />
              <FormControlLabel
                control={
                  <Radio
                    value='basic-user'
                    onChange={valueChanger}
                    checked={'role' in data && data.role === 'basic-user'}
                    disabled={!roleCanGrantSelectedPermission(loggedInMember, data, Role.BasicUser)}
                  />
                }
                label='Basic Access'
              />
            </RadioGroup>
          </Grid>
        </AddCard>
        <AddCard title='Access to Dashboard'>
          <Grid container direction='column'>
            <FormHeader>
              Would you like to grant the team member access to the dashboard?
            </FormHeader>

            <FormControlLabel
              control={
                <Switch
                  name='is_dashboard_access'
                  color='primary'
                  onChange={dashboardAccessHandler}
                  checked={hasDashboardAccess}
                />
              }
              label='Grant Dashboard Access'
            />

            <FormHeader>Account Password</FormHeader>
            <div>
              <FormControlLabel
                control={<Checkbox checked={data.is_password_set} name='is_password_set' />}
                label='Is Password set?'
              />
              {!data.is_password_set && hasDashboardAccess && (
                <>
                  <FormHeader variant='body2'>
                    Team member has not finish setting up their account. Send email to complete
                    account setup.
                  </FormHeader>

                  <Button
                    variant='contained'
                    color={sendSuccess ? 'secondary' : 'primary'}
                    onClick={handleSendEmail}
                    disabled={!sendButton || sendSuccess}
                  >
                    Send Password Setup Email
                  </Button>
                  {sendSuccess ? <CheckIcon /> : !sendButton && <CircularProgress size={24} />}
                </>
              )}
            </div>
          </Grid>
        </AddCard>
      </Grid>
    </Grid>
  );
};

export default PermissionsComponent;
