import { Add, RemoveOutlined } from '@mui/icons-material';
import { Box, IconButton, Stack, TextField } from '@mui/material';
import { Text } from '@operto/ui';
import React from 'react';

export default function Counter({
  label,
  onIncrement,
  onDecrement,
  onChange,
  value,
  disableDecrease,
  disableIncrease,
  helperText,
}: CounterProps) {
  return (
    <Stack sx={rootContainerStyles}>
      {label && (
        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <Text variant='body-lg-400'>{label}</Text>
        </Box>
      )}

      {onDecrement && (
        <IconButton
          data-testid={`decrement-button-${label}`}
          disabled={disableDecrease}
          disableRipple
          size='large'
          onClick={onDecrement}
          sx={buttonStyles}
        >
          <RemoveOutlined />
        </IconButton>
      )}

      <TextField
        value={(value || 0) + ''}
        sx={[rootTextfieldStyles, helperText ? textFieldHelperTextStyles : {}, removeSpinnerStyles]}
        FormHelperTextProps={{
          sx: { ...(helperText ? helperTextStyles : {}) },
        }}
        inputProps={{ ...inputStyles, 'data-testid': `counter-textfield-${label}` }}
        type='number'
        onChange={e => onChange?.(+e.target.value)}
        helperText={helperText}
      />

      {onIncrement && (
        <IconButton
          data-testid={`increment-button-${label}`}
          disabled={disableIncrease}
          disableRipple
          size='large'
          onClick={onIncrement}
          sx={buttonStyles}
        >
          <Add />
        </IconButton>
      )}
    </Stack>
  );
}

type CounterProps = {
  label?: string;
  onIncrement?: () => void;
  onDecrement?: () => void;
  onChange?: (value: number) => void;
  value: number;
  disableDecrease?: boolean;
  disableIncrease?: boolean;
  helperText?: string;
};

const rootContainerStyles = {
  flexDirection: 'row',
  gap: '24px',
};

const buttonStyles = {
  width: '56px',
  height: '56px',
  color: 'rgba(0, 0, 0, 0.54)',
  border: '1px solid rgba(0, 0, 0, 0.54)',
  '&.Mui-disabled': {
    borderColor: 'rgba(0, 0, 0, 0.26)',
  },
};

const helperTextStyles = { color: 'rgba(211, 47, 47, 1)' };

const textFieldHelperTextStyles = {
  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(211, 47, 47, 1)',
      borderWidth: '2px',
    },
    '& .MuiInputLabel-outlined': {
      borderColor: 'rgba(211, 47, 47, 1)',
      borderWidth: '2px',
      '&.Mui-focused': {
        borderColor: 'rgba(211, 47, 47, 1)',
        borderWidth: '2px',
      },
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(211, 47, 47, 1)',
        borderWidth: '2px',
      },
    },
    '&:hover:not(.Mui-focused)': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(211, 47, 47, 1)',
        borderWidth: '2px',
      },
    },
  },
  '& .MuiInputLabel-outlined': {
    color: 'rgba(211, 47, 47, 1)',
    '&.Mui-focused': {
      color: 'rgba(211, 47, 47, 1)',
    },
  },
};

const removeSpinnerStyles = {
  'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
};

const rootTextfieldStyles = { width: '134px', textAlign: 'center', margin: 0 };

const inputStyles = {
  sx: { cursor: 'default', textAlign: 'center' },
};
