import { Box, Button, SxProps } from '@mui/material';
import * as React from 'react';

export interface CheckInFooterProps {
  onClick: () => void;
}

const CheckInFooter = ({ onClick }: CheckInFooterProps) => (
  <Box sx={{ justifyContent: 'flex-start' }}>
    <Button sx={smartCardButtonStyles} onClick={onClick}>
      View All
    </Button>
  </Box>
);

const smartCardButtonStyles: SxProps = {
  color: 'text.white',
};

export default CheckInFooter;
