import { createSelector } from '@reduxjs/toolkit';
import { CodeState } from 'code/codeType';
import { ApplicationState } from 'redux/reducers';
import matchMembersStartAndComplete from 'redux/selectors/matchMembersStartAndComplete';

const getCodes = (state: ApplicationState) => state.codes;

export const accessCodesByResIdSelector = (reservationId: number) =>
  createSelector([getCodes, matchMembersStartAndComplete], (codes: CodeState) =>
    Object.values(codes.byId).find(code => code.reservation_id === Number(reservationId)),
  );
