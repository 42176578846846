import CodeIcon from '@mui/icons-material/Code';
import DataObjectIcon from '@mui/icons-material/DataObject';
import LanguageIcon from '@mui/icons-material/Language';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, SxProps } from '@mui/material';
import * as React from 'react';

export interface ScriptDropDownMenuProps {
  onTextInsert: (text: string) => void;
  marginTop?: number;
  top?: number;
  addVariable?: boolean;
}

const ScriptDropDownMenu = ({
  onTextInsert,
  marginTop = 0,
  top = 0,
  addVariable = false,
}: ScriptDropDownMenuProps) => {
  const [actionsMenu, setActionsMenu] = React.useState<HTMLButtonElement | null>(null);

  const handleNoTranslate = () => {
    onTextInsert('[[Enter your No Translate text]]');
    setActionsMenu(null);
  };

  const handleAddVariable = () => {
    onTextInsert('*{{Enter Variable Name}}*');
    setActionsMenu(null);
  };

  return (
    <>
      <Button
        variant='outlined'
        onClick={e => setActionsMenu(e.currentTarget)}
        sx={{ padding: '15px 10px', top: `${top}px`, marginTop: `${marginTop}px` }}
      >
        <CodeIcon color='primary' />
      </Button>{' '}
      <Menu
        anchorEl={actionsMenu}
        open={Boolean(actionsMenu)}
        onClose={() => {
          setActionsMenu(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleNoTranslate} data-testid='no-translate-subtitle'>
          <ListItemIcon sx={ListIconSxProps}>
            <LanguageIcon color='primary' />
          </ListItemIcon>
          <ListItemText>No Translate</ListItemText>
        </MenuItem>
        {addVariable && (
          <MenuItem onClick={handleAddVariable}>
            <ListItemIcon sx={ListIconSxProps}>
              <DataObjectIcon color='primary' />
            </ListItemIcon>
            <ListItemText>Add Variable</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

const ListIconSxProps: SxProps = {
  minWidth: '32px',
};

export default ScriptDropDownMenu;
