import {
  BlockOutlined,
  CheckCircleOutlineOutlined,
  DeleteOutline,
  QueryBuilderOutlined,
  RotateRightOutlined,
} from '@mui/icons-material';
import { Box, SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-pro';
import { useMedia } from '@operto/ui';
import React from 'react';

const AccessCodeColorMapping = {
  Installed: {
    color: '#216E4E',
    backgroundColor: '#BBF3DA',
    icon: (
      <CheckCircleOutlineOutlined
        sx={{
          width: '11.67px',
          height: '11.67px',
          color: '#216E4E',
        }}
      />
    ),
  },
  Removed: {
    color: '#00000099',
    backgroundColor: '#F1F2F5',
    icon: (
      <DeleteOutline
        sx={{
          width: '11.67px',
          height: '11.67px',
          color: '#00000099',
        }}
      />
    ),
  },
  Blocked: {
    color: '#BB0000',
    backgroundColor: '#FFD5D2',
    icon: (
      <BlockOutlined
        sx={{
          width: '11.67px',
          height: '11.67px',
          color: '#BB0000',
        }}
      />
    ),
  },
  'Not installed': {
    color: '#5E4EB3',
    backgroundColor: '#E0D8FE',
    icon: (
      <RotateRightOutlined
        sx={{
          width: '11.67px',
          height: '11.67px',
          color: '#5E4EB3',
        }}
      />
    ),
  },
  Scheduled: {
    color: '#206A83',
    backgroundColor: '#C6EDFB',
    icon: (
      <QueryBuilderOutlined
        sx={{
          width: '11.67px',
          height: '11.67px',
          color: '#206A83',
        }}
      />
    ),
  },
} as {
  [K in AccessCodeStatus | InstalledAtTime]: {
    color: string;
    backgroundColor: string;
    icon: React.ReactNode;
  };
};

const AccessCodeStatusIcon = ({ status }: { status: AccessCodeStatus | InstalledAtTime }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: 'fit-content',
        height: '17px',
        borderRadius: '4px',
        backgroundColor: AccessCodeColorMapping[status]?.backgroundColor,
        padding: '4px',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '2px 8px 2px 0px',
      }}
    >
      <Box width={14} height={14} alignItems='center' display='flex' data-testid={`${status}-icon`}>
        {AccessCodeColorMapping[status]?.icon}
      </Box>
      <Typography
        sx={{
          fontSize: '10px',
          fontWeight: '700',
          color: AccessCodeColorMapping[status]?.color,
        }}
      >
        {status}
      </Typography>
    </Box>
  );
};

type AccessCodeStatus = 'Removed' | 'Blocked' | 'Installed' | 'Scheduled' | 'Not installed';
type InstalledAtTime = string | null;

type AccessCodeTableCellProps = {
  code: string;
  status: AccessCodeStatus | InstalledAtTime;
  disabled?: boolean;
};

export const mapInstalledAtToStatus = (installedAt: string | null) => {
  return installedAt ? 'Installed' : 'Not installed';
};

export const AccessCodeTableCell = ({ code, status, disabled }: AccessCodeTableCellProps) => {
  const { isDesktop } = useMedia();
  const sxProps: SxProps = { color: disabled ? 'text.disabled' : 'text.primary' };

  if (!code) {
    return (
      <Typography variant='caption' sx={sxProps}>
        N/A
      </Typography>
    );
  }

  return (
    <Stack direction={isDesktop ? 'row' : 'column'}>
      <AccessCodeStatusIcon status={status} />
      <Typography variant='caption' sx={sxProps}>
        {code}
      </Typography>
    </Stack>
  );
};

type FieldsToPropsMapping = Partial<Record<keyof AccessCodeTableCellProps, string>>;

export const accessCodeColumn = (fields: FieldsToPropsMapping): GridColDef<GridValidRowModel> => {
  return {
    field: 'access_code',
    headerName: 'Access Code',
    sortable: false,
    flex: 1,
    renderCell: ({ row: data }) => {
      // [Members] Display 'Installed' if access_codes.installed_at != null; otherwise, show 'Not installed'
      let status =
        fields.status === 'installed_at'
          ? mapInstalledAtToStatus(data[fields.status])
          : data[fields.status];

      // [Guests] BE returns 'Not Installed' for guests' code_status, display 'Not installed' instead of 'Not Installed'
      if (status === 'Not Installed') {
        status = 'Not installed';
      }

      return (
        <AccessCodeTableCell
          code={data[fields.code]}
          status={status}
          disabled={data[fields.disabled]}
        />
      );
    },
  };
};
