import {
  CloseOutlined,
  DeleteOutlineOutlined,
  InfoOutlined,
  ModeEditOutlined,
  SendOutlined,
} from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import {
  TitlebarActionIcon,
  TitlebarActionIconContainer,
  TitlebarBreadCrumb,
  TitlebarBreadCrumbChild,
  TitlebarButton,
  TitlebarRoot,
  TitlebarTitle,
  TitlebarTitleButton,
  TitlebarTitleIcon,
} from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CommunicationsTemplateDeleteConfirmDialog, {
  TemplateDeletionType,
  TemplateDeletionTypes,
} from './CommunicationsTemplateDeleteConfirmDialog';
import CommunicationsTemplateInfoDialog from './CommunicationsTemplateInfoDialog';

export default function CommunicationsTemplateTitlebar({
  title,
  onTitleChange,
  onSaveClick,
  deleteDisabled,
  saveDisabled,
  helperText,
  editMode,
  setEditMode,
  onSendClick,
  isSaving,
  onDeleteClick,
  showDeleteMenu,
  channel,
  contentId,
}: CommunicationsTemplateTitlebarProps) {
  const { t, languages } = useTranslation();
  const navigate = useNavigate();

  const [prevTitle, setPrevTitle] = React.useState(title);

  const { templateId, language } = useParams();

  const [deletionType, setDeletionType] = React.useState<TemplateDeletionType>(
    TemplateDeletionTypes.ENGLISH_ONLY,
  );
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const languageName =
    !language || language === 'en'
      ? t('english_default')
      : languages.find(l => l.locale === language)?.name ?? '';

  const deleteTemplateMenuItems = showDeleteMenu
    ? [
        { id: '1', displayName: `${t('delete')} ${languageName}`, code: `${language ?? 'en'}` },
        { id: '2', displayName: t('template_delete_menu_all'), code: 'all' },
      ]
    : [];

  const open = Boolean(anchorEl);

  const crumbs: TitlebarBreadCrumbChild[] = [
    {
      name: t('home'),
      href: '/',
    },
    {
      name: t('communications'),
      href: '/communications',
    },
  ];

  const handleInformationClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setShowInformationDialog(true);
  };

  const handleDeleteClick = (event: {
    currentTarget: Partial<React.MouseEvent<HTMLButtonElement>>;
  }) => {
    if (showDeleteMenu) {
      setAnchorEl(event.currentTarget);
    } else {
      setDeletionType(TemplateDeletionTypes.ENGLISH_ONLY);
      setShowDeleteDialog(true);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteMenuItemClick = (item: { id: string; displayName: string; code: string }) => {
    if (item.code === 'all') {
      setDeletionType(TemplateDeletionTypes.ALL_LANGUAGES);
    } else {
      setDeletionType(TemplateDeletionTypes.SINGLE_LANGUAGE);
    }
    handleCloseMenu();
    setShowDeleteDialog(true);
  };

  const handleCloseDeleteTemplateDialog = () => {
    setShowDeleteDialog(false);
  };

  const handleSuccessDeleteTemplateDialog = () => {
    onDeleteClick();
    if (deletionType !== TemplateDeletionTypes.SINGLE_LANGUAGE) {
      navigate('/communications');
    }
  };

  const handleFailureDeleteTemplateDialog = (error: string) => {
    logger.error('Deletion failed:', error);
  };

  const [showInformationDialog, setShowInformationDialog] = React.useState(false);

  return (
    <TitlebarRoot>
      <TitlebarTitleIcon>
        <SendOutlined />
      </TitlebarTitleIcon>

      <TitlebarBreadCrumb crumbs={crumbs} onClick={crumb => navigate(crumb.href)}>
        <TitlebarTitle
          title={title}
          editMode={editMode}
          helperText={helperText}
          onChange={e =>
            onTitleChange(
              (e as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>).target.value,
            )
          }
        >
          {editMode ? (
            <TitlebarTitleButton
              onClick={() => {
                onTitleChange(prevTitle);
                setEditMode(false);
              }}
            >
              <CloseOutlined />
            </TitlebarTitleButton>
          ) : (
            <TitlebarTitleButton
              onClick={() => {
                setEditMode(true);
                setPrevTitle(title);
              }}
            >
              <ModeEditOutlined />
            </TitlebarTitleButton>
          )}
        </TitlebarTitle>
      </TitlebarBreadCrumb>

      <TitlebarActionIconContainer>
        <TitlebarActionIcon
          tooltip={t('more_information')}
          onClick={event => handleInformationClick(event)}
        >
          <InfoOutlined />
        </TitlebarActionIcon>
        {showInformationDialog && (
          <CommunicationsTemplateInfoDialog
            onClose={() => {
              setShowInformationDialog(false);
              setAnchorEl(null);
            }}
            open={showInformationDialog}
            anchorEl={anchorEl}
          />
        )}

        <TitlebarActionIcon
          tooltip={t('delete_language')}
          disabled={deleteDisabled || showDeleteDialog}
          data-testid='delete-icon-id'
          id='delete-positioned-icon'
          aria-controls={open ? 'delete-positioned-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleDeleteClick}
        >
          <DeleteOutlineOutlined />
        </TitlebarActionIcon>
        {!deleteDisabled && deleteTemplateMenuItems?.length > 1 && (
          <Menu
            id='delete-positioned-menu'
            data-testid='delete-menu-id'
            aria-labelledby='delete-positioned-icon'
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {deleteTemplateMenuItems.map(item => (
              <MenuItem key={item.id} onClick={() => handleDeleteMenuItemClick(item)}>
                {item.displayName}
              </MenuItem>
            ))}
          </Menu>
        )}
        {showDeleteDialog && (
          <CommunicationsTemplateDeleteConfirmDialog
            deletionType={deletionType}
            channel={channel}
            locale={language}
            templateName={title}
            templateId={templateId}
            contentId={contentId}
            onClose={handleCloseDeleteTemplateDialog}
            onSuccess={handleSuccessDeleteTemplateDialog}
            onFailure={handleFailureDeleteTemplateDialog}
          />
        )}

        <TitlebarActionIcon
          tooltip={t('send_test')}
          onClick={onSendClick}
          data-testid='send-preview-btn'
        >
          <SendOutlined />
        </TitlebarActionIcon>
      </TitlebarActionIconContainer>

      <TitlebarButton onClick={onSaveClick} disabled={saveDisabled} loading={isSaving}>
        {t('save')}
      </TitlebarButton>
    </TitlebarRoot>
  );
}

type CommunicationsTemplateTitlebarProps = {
  title: string;
  onTitleChange: (title: string) => void;
  onSaveClick: () => void;
  deleteDisabled?: boolean;
  saveDisabled?: boolean;
  helperText?: string;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
  onSendClick: () => void;
  isSaving?: boolean;
  onDeleteClick: () => void;
  showDeleteMenu: boolean;
  channel: string;
  contentId: string;
};
