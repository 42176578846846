import { NotifiableError } from '@bugsnag/js';
import { ILock } from 'device/deviceType';
import { logger } from 'lib/logger';
import { OpertoLogger } from '../../Logger/logger';
import * as api from '../../api/propertyAPI';
import { Property, PropertyDict, PropertyFilterType } from '../../property/propertyType';
import { toggleSnackbar } from '../../redux/actions/ui';
import { ApplicationState } from '../../redux/reducers';
import { AppDispatch } from '../../redux/store';
import { Actions } from '../../types/actions';
import { SnackbarTypes, SnackbarVariant } from '../../types/ui';
import { PropertyQueryFilterType } from '../propertyType';

export interface GetPropertiesProps {
  filterType?: PropertyFilterType;
  roomStatus?: string;
  lockEnabled?: string;
  activeToday?: string;
  pageNum?: number;
  numPerPage?: number;
  type?: string;
  keyword?: string;
  groupType?: string;
  locationType?: string;
  shouldInsert?: boolean;
}

export const getPropertiesByGroupId =
  (groupId: number, pageNum = 0, numPerPage = 50) =>
  async (dispatch: AppDispatch) => {
    const data = await api.getPropertiesByGroupId(groupId, pageNum, numPerPage);
    const properties = data.data.data;
    if (properties.total_records && properties.records.length > 0) {
      const propertyIds: number[] = [];
      const propertiesById: PropertyDict = {};

      properties.records.forEach((each: Property) => {
        propertyIds.push(each.id);
        propertiesById[each.id] = each;
      });

      dispatch({
        type: Actions.upsertProperties,
        propertiesById,
      });

      dispatch({
        type: Actions.updatePropertyCounts,
        filterType: PropertyFilterType.ALL_PROPERTIES,
        meta: {
          ids: propertyIds,
          total_records: properties?.total_records,
          numPerPage: properties.numPerPage,
          pageNum: properties.pageNum,
        },
      });
    }
  };

const DEFAULT_PARAMS = {
  filterType: PropertyFilterType.ALL_PROPERTIES,
  urlSearchParams: new URLSearchParams({ pageNum: '0', numPerPage: '100' }),
};

export type GetPropertiesParams = {
  filterType?: PropertyFilterType;
  urlSearchParams?: URLSearchParams;
};

export const getProperties =
  ({
    filterType = DEFAULT_PARAMS.filterType,
    urlSearchParams = DEFAULT_PARAMS.urlSearchParams,
  }: GetPropertiesParams = DEFAULT_PARAMS) =>
  async (dispatch: AppDispatch, getState: () => ApplicationState) => {
    if (filterType === PropertyFilterType.UNACTIVATED) {
      urlSearchParams.set(PropertyQueryFilterType.LOCK_ENABLED, 'no');
    }

    if (filterType === PropertyFilterType.VACANT) {
      urlSearchParams.set(PropertyQueryFilterType.LOCK_ENABLED, 'yes');
      urlSearchParams.set(PropertyQueryFilterType.ROOM_STATUS, 'clean-available');
    }

    if (filterType === PropertyFilterType.CLEANING) {
      urlSearchParams.set(PropertyQueryFilterType.ROOM_STATUS, 'guest-checking-out');
    }

    if (filterType === PropertyFilterType.ACTIVE_TODAY) {
      urlSearchParams.set(PropertyQueryFilterType.LOCK_ENABLED, 'yes');
      urlSearchParams.set(PropertyQueryFilterType.ACTIVE_TODAY_ONLY, 'yes');
    }

    if (filterType === PropertyFilterType.ALL_PROPERTIES) {
      urlSearchParams.set(PropertyQueryFilterType.LOCK_ENABLED, 'yes');
    }

    if (filterType === PropertyFilterType.FAVOURITES) {
      urlSearchParams.set(PropertyQueryFilterType.FAVOURITES, 'yes');
    }

    if (filterType === PropertyFilterType.COMMON) {
      urlSearchParams.set(PropertyQueryFilterType.PROPERTY_TYPE, PropertyFilterType.COMMON);
    }

    if (filterType === PropertyFilterType.ROOM_TYPE) {
      urlSearchParams.set(PropertyQueryFilterType.PROPERTY_TYPE, PropertyFilterType.ROOM_TYPE);
    }

    if (filterType === PropertyFilterType.PUBLIC) {
      urlSearchParams.set(PropertyQueryFilterType.LOCK_ENABLED, 'yes');
      urlSearchParams.set(PropertyQueryFilterType.GROUP_TYPE, PropertyFilterType.PUBLIC);
    }

    if (filterType === PropertyFilterType.ACCESS_GROUP) {
      urlSearchParams.set(PropertyQueryFilterType.LOCATION_TYPE, PropertyFilterType.ACCESS_GROUP);
    }

    try {
      // save our current filter
      dispatch({
        type: Actions.updatePropertyCounts,
        filterType,
        isLoading: true,
      });

      const result = await api.getProperties(urlSearchParams);

      const { currentFilter } = getState().properties.meta;
      if (currentFilter !== filterType) {
        // ignoring API response that is not our current filter, we dont want to override current filter
        return;
      }

      // process correct filter
      const properties = result.data.data;
      const propertyIds: number[] = [];
      const propertiesById: PropertyDict = {};
      if (properties.total_records && properties.records.length > 0) {
        properties.records.forEach((property: Property) => {
          propertyIds.push(property.id);
          propertiesById[property.id] = property;
          const aProperty = propertiesById[property.id];
          aProperty.type = filterType;
          const propertyLock = property.devices[0] as unknown as ILock;
          aProperty.is_locked = propertyLock?.is_locked;
          aProperty.group_type = urlSearchParams.get(PropertyQueryFilterType.GROUP_TYPE);
          if (
            urlSearchParams.get(PropertyQueryFilterType.LOCATION_TYPE) ===
            PropertyFilterType.ACCESS_GROUP
          ) {
            aProperty.locationType = PropertyFilterType.ACCESS_GROUP;
          }
        });
      }

      dispatch({
        type: Actions.upsertProperties,
        propertiesById,
      });

      dispatch({
        type: Actions.updatePropertyCounts,
        filterType,
        isLoading: false,
        meta: {
          ids: propertyIds,
          total_records: properties?.total_records,
          numPerPage: properties.numPerPage,
          pageNum: properties.pageNum,
        },
      });
    } catch (error) {
      dispatch({
        type: Actions.updatePropertyCounts,
        filterType,
        isLoading: false,
      });

      logger.error('get properties failed, error: ', error);
    }
  };

export const patchProperty = (propertyId: number, data: unknown) => (dispatch: AppDispatch) => {
  api
    .patchProperty(propertyId, data)
    .then(result => {
      const property = result.data.data;
      dispatch({
        type: Actions.updateProperty,
        property,
      });
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Updating the unit was successful.',
          variant: SnackbarVariant.SUCCESS,
        }),
      );
    })
    .catch(err => {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Updating the unit was not successful.',
          variant: SnackbarVariant.ERROR,
        }),
      );
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: `ERROR: ${err.message}`,
          variant: SnackbarVariant.SUCCESS,
        }),
      );
    });
};

export const getFavouriteProperties = () => (dispatch: AppDispatch) => {
  api
    .getFavouriteProperties()
    .then(data => {
      const properties = data.data.data;
      const propertyIds: number[] = [];
      const propertiesById: PropertyDict = {};
      const filterType = PropertyFilterType.FAVOURITES;
      if (properties.total_records && properties.records.length > 0) {
        properties.records.forEach((property: Property) => {
          property.isFavourite = true;
          propertyIds.push(property.id);
          propertiesById[property.id] = property;
          propertiesById[property.id].type = filterType;
        });

        dispatch({
          type: Actions.upsertProperties,
          propertiesById,
        });

        dispatch({
          type: Actions.updatePropertyCounts,
          filterType,
          meta: {
            ids: propertyIds,
            total_records: properties?.total_records,
            numPerPage: properties.numPerPage,
            pageNum: properties.pageNum,
          },
        });
      }
    })
    .catch(err => {
      OpertoLogger.Log(err);
    });
};

export const getFavoritePropertyIds = () => (dispatch: AppDispatch) => {
  api
    .getFavoriteProperties()
    .then(data => {
      const propertyIds = data.data.data.property_ids;

      dispatch({
        type: Actions.hydrateFavoriteProperties,
        propertyIds,
      });
    })
    .catch(err => {
      OpertoLogger.Log(err);
    });
};

export const getUnitsFilterOptions = async () => {
  const response = await api.getUnitsFilterOptions();
  return response.data.data;
};

// favorites is seperate logic in the core api so have to make seperate call
export const patchPropertyFavourite =
  (propertyId: number, data: Record<string, unknown>) => (dispatch: AppDispatch) => {
    const action = data.status ? 'Adding' : 'Removing';
    api
      .patchFavouriteProperty(propertyId, data)
      .then(() => {
        // TODO: just update the state not pull it in again, don't have time to do that right now
        dispatch(getFavoritePropertyIds());
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: `${action} favorite unit was successful.`,
            variant: SnackbarVariant.SUCCESS,
          }),
        );
      })
      .catch(err => {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: `${action} favorite unit was not successful.`,
            variant: SnackbarVariant.ERROR,
          }),
        );
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: `ERROR: ${err.message}`,
            variant: SnackbarVariant.ERROR,
          }),
        );
      });
  };

export const getProperty = (propertyId: number) => (dispatch: AppDispatch) =>
  api
    .getProperty(propertyId)
    .then(data => {
      const property = data.data.data;
      // TODO: this is to fix a current server bug where the devices is not nested in devices
      if (property.devices) {
        property.devices = property.devices.devices.map((device: unknown) => device);
      }
      if (property.common_property_devices) {
        property.common_property_devices = property.common_property_devices.devices.map(
          (device: unknown) => device,
        );
      }

      dispatch({
        type: Actions.insertProperty,
        property,
      });
    })
    .catch(err => {
      OpertoLogger.Log(err);
    });

export const linkCommonProperty =
  (commonPropertyId: number) => (getState: () => ApplicationState) => {
    const propertyId = getState().ui.propertyDetailId;
    api
      .linkProperties(propertyId, commonPropertyId)
      .then(() => {
        // dispatch(getLinkedProperties());
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  };

export const unlinkCommonProperty =
  (commonPropertyId: number) => (getState: () => ApplicationState) => {
    const propertyId = getState().ui.propertyDetailId;
    api
      .unlinkProperties(propertyId, commonPropertyId)
      .then(() => {
        // dispatch(getLinkedProperties());
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  };
