import { getValidColor } from 'Common/ColorPicker';
import getUnixTime from 'date-fns/getUnixTime';
import { UNCATEGORIZED_ID } from 'helper/helper';
import { logger } from 'lib/logger';
import { IMember } from 'member/memberType';
import { getSelf } from 'member/state/memberSelectors';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGuestPortal } from 'redux/guestportal';
import { useAppSelector } from 'redux/hooks';
import { IPage, Order } from '../models';

export const newPageTemplate = (author: string, guideId: string): Partial<IPage> => {
  const now = getUnixTime(new Date());
  return {
    section_id: guideId,
    category_id: UNCATEGORIZED_ID,
    title: '',
    subtitle: '',
    description: '',
    last_edit_by: author,
    last_edit_at: now,
    url: '',
    video_url: '',
    images_url: [],
    published: false,
    featured: false,
    scheduled: false,
    scheduled_start: now,
    scheduled_end: now,
    button: {
      enabled: false,
      title: 'Button',
      color: getValidColor(),
      hyperlink: '',
      messenger_enabled: false,
      subject: '',
      autoreply_message: '',
    },
  };
};

export const useGuidesPages = () => {
  const navigate = useNavigate();
  const { propertyId, guideId, pageId } = useParams();
  const editingUser = useAppSelector(getSelf()) as IMember;
  const {
    featured,
    pages,
    savePage,
    savePropertyPage,
    addPage,
    moveContent,
    removeSection,
    fetchGuidePages,
    fetchPropertyGuidePages,
    setGuidePagesOrder,
    setPropertyGuidePagesOrder,
  } = useGuestPortal();
  const savedPageInProgress = useRef<IPage>();

  const page = useMemo(() => {
    if (savedPageInProgress.current) {
      return savedPageInProgress.current;
    }

    // NOTE: determine what page to show based on the url params
    if (pageId === undefined) {
      return undefined;
    }

    // NOTE: determine what page to show based on the url
    if (pageId === 'add') {
      return newPageTemplate(editingUser?.name ?? 'Unknown', guideId) as unknown as IPage;
    }

    const pageItem = pages.byId[pageId];
    if (pageItem) {
      return pageItem; // lucky us, we cached it already
    }

    const featuredItem = featured.byId[pageId];
    if (featuredItem) {
      // NOTE: this part is a mess. IFeatured is not an actual IPage type.
      // its a subset/partial of IPage object, so we need to somehow construct it to IPage
      return {
        ...featuredItem?.data,
        content_id: featuredItem?.content_id,
        category_id: featuredItem?.category_id,
        section_id: featuredItem?.section_id,
      } as IPage;
    }

    logger.error(`item not found but pageId is ${pageId}`);
    return undefined;
  }, [editingUser?.name, featured.byId, guideId, pageId, pages.byId]);

  const fetch = useCallback(async () => {
    if (!guideId?.length) {
      return;
    }

    if (propertyId) {
      await fetchPropertyGuidePages(Number(propertyId), guideId);
    } else {
      fetchGuidePages(guideId);
    }
  }, [fetchGuidePages, fetchPropertyGuidePages, guideId, propertyId]);

  const save = async (page: IPage) => {
    savedPageInProgress.current = pageId ? page : null;

    let success = false;
    if (page?.content_id?.length > 0) {
      if (propertyId) {
        page.last_edit_by = editingUser?.name ?? 'Unknown';
        success = await savePropertyPage(Number(propertyId), page, guideId);
      } else {
        success = await savePage(page, guideId);
      }
    } else {
      success = await addPage(page);
    }

    if (success) {
      savedPageInProgress.current = null;
      if (pageId) navigate(-1);
    }
  };

  const remove = async ({ section_id, category_id, content_id }: IPage) => {
    await removeSection({
      section_id,
      category_id,
      content_id,
    });
  };

  const move = async (newCategoryId: string, page: IPage) => {
    await moveContent({
      newCategoryId,
      page,
    });
  };

  const sort = async (order: Order[]) => {
    if (propertyId) {
      await setPropertyGuidePagesOrder({
        order,
        section_id: guideId,
        propertyId,
      });
    } else {
      await setGuidePagesOrder({ order, section_id: guideId });
    }
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    page,
    fetch,
    save,
    remove,
    move,
    sort,
  };
};
