import { Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';

export const enum ReservationTabs {
  NEXT_GUEST,
  UPCOMING,
}
export interface ReservationHeaderProps {
  value: ReservationTabs;
  onChange?: (event: React.ChangeEvent, value: number) => void;
  title?: string;
  showUpcoming?: boolean;
}

const ReservationHeader = ({ value, onChange, title, showUpcoming }: ReservationHeaderProps) => {
  return (
    <Tabs value={value} onChange={onChange} variant='fullWidth' aria-label='simple tabs example'>
      <Tab
        label={
          <Typography
            variant='subtitle2'
            sx={{
              fontFamily: 'inter',
              color: value === ReservationTabs.NEXT_GUEST ? 'text.white' : '#A2AAAD',
              textTransform: 'capitalize',
            }}
          >
            {title}
          </Typography>
        }
        value={ReservationTabs.NEXT_GUEST}
        sx={{
          backgroundColor: value !== ReservationTabs.NEXT_GUEST && '#0040CC',
        }}
      />
      {showUpcoming && (
        <Tab
          label={
            <Typography
              variant='subtitle2'
              sx={{
                fontFamily: 'inter',
                color: value !== ReservationTabs.UPCOMING ? '#A2AAAD' : 'text.white',
                textTransform: 'capitalize',
              }}
            >
              Upcoming
            </Typography>
          }
          value={ReservationTabs.UPCOMING}
          sx={{
            backgroundColor: value !== ReservationTabs.UPCOMING && '#0040CC',
          }}
        />
      )}
    </Tabs>
  );
};

export default ReservationHeader;
