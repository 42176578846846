import { MULTI_MP_AUTH } from 'auth/LinkedAccountsHelper';

type SignInDetails = {
  loginId: string;
  authFlowType: string;
};

type SessionUser = {
  username: string;
  email: string;
  product: string;
  cid?: string;
  name?: string;
  accessToken?: string;
  clockDrift?: number;
  idToken?: string;
  refreshToken?: string;
  signInDetails?: SignInDetails;
};

interface CognitoSessionTransmissionPayload {
  type: 'cognitoSessions';
  users: SessionUser[];
}

const isCognitoPayload = (payload: CognitoSessionTransmissionPayload | null) => {
  if (!payload) {
    return false;
  }

  if (payload.type !== 'cognitoSessions' || !payload.users || !Array.isArray(payload.users)) {
    return false;
  }

  for (const user of payload.users) {
    if (
      !user.username ||
      !user.email ||
      !user.cid ||
      !user.name ||
      !user.product.includes('connect') ||
      !user.accessToken ||
      !user.idToken
    ) {
      return false;
    }
  }

  return true;
};

export const useSharedLocalStorageReceiver = () => {
  window.addEventListener(
    'message',
    event => {
      const isEventOriginAllowed =
        event.origin.toLowerCase().includes('localhost') ||
        event.origin.toLowerCase().includes('operto');
      if (!isEventOriginAllowed || !event.isTrusted) {
        return;
      }

      const targetCognitoPayload: CognitoSessionTransmissionPayload = event.data;
      if (!isCognitoPayload(targetCognitoPayload)) {
        return;
      }

      localStorage.setItem(MULTI_MP_AUTH, JSON.stringify(targetCognitoPayload.users));
    },
    false,
  );
};
