import { Button, TextField, Typography } from '@mui/material';
import GlobalSnackbar from 'Common/Snackbar/GlobalSnackbar';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import styled from 'styled-components';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { forgotPassword } from '../../api/userAPI';
import AccessContainer from './AccessContainer';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const validateInputs = () => {
    let isValid = true;
    if (email.length < 1) {
      setEmailError('Email too short');
      isValid = false;
    } else {
      setEmailError('');
    }
    return isValid;
  };

  const handleSubmit = () => {
    if (!validateInputs()) return;
    setButtonDisabled(true);
    forgotPassword(email)
      .then(() => {
        // dispatch(userActions.storeToken(data.data.data.access_token, ""));
        const params = new URLSearchParams(window.location.search);
        const propertyId = params.get('property_id');
        const propertyDetailView = params.get('property_detail');
        let url = '/login';
        if (propertyId && propertyDetailView === 'monitor') {
          url = `/?property_detail=monitor&property_id=${propertyId}`;
        }
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Reset password email has been sent. Please check your email!',
            variant: SnackbarVariant.SUCCESS,
          }),
        );
        setTimeout(() => {
          setButtonDisabled(false);
          dispatch(
            toggleSnackbar(SnackbarTypes.CLOSE, {
              message: '',
              variant: SnackbarVariant.INFO,
            }),
          );
          navigate(url);
        }, 3000);
      })
      .catch(() => {
        setTimeout(() => {
          setButtonDisabled(false);
          // TODO:
          // Timeout of error
        }, 3000);
      });
  };

  return (
    <AccessContainer>
      <>
        <GlobalSnackbar />
        <HeaderText variant='h2' align='center'>
          Forgot Password
        </HeaderText>
        <InputField
          variant='standard'
          helperText={emailError}
          error={emailError.length > 0}
          label='email'
          value={email}
          tabIndex={0}
          onChange={e => setEmail(e.target.value.trim().toLowerCase())}
          autoFocus
          required
        />
        <SubmitButton
          color='primary'
          variant='contained'
          onClick={handleSubmit}
          disabled={buttonDisabled}
        >
          Submit
        </SubmitButton>
      </>
    </AccessContainer>
  );
};

const HeaderText = styled(Typography)`
  && {
    padding: 0px;
  }
`;

const InputField = styled(TextField)`
  margin-top: 5px !important;
  width: 100%;
`;

const SubmitButton = styled(Button)`
  margin-top: 25px !important;
  margin-bottom: 10px !important;
  border-radius: 25px !important;
`;

export default ForgotPasswordPage;
