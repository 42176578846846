import { WorkflowData } from '@operto/communications-shared';
import { trpc } from '@operto/trpc-client';
import { companySelector } from 'company/state/companySelectors';
import { useAppSelector } from 'redux/hooks';

export const useAutomate = (filters?: useAutomateProps) => {
  const company = useAppSelector(companySelector());
  const trpcUtils = trpc.useUtils();
  const { channels, running, templateId } = filters || {};

  const {
    data: workflows,
    isFetching,
    isError,
    isLoading,
  } = trpc.communications.getWorkflows.useQuery(
    { companyId: `${company.id}`, filterParams: { channels, running, templateId } },
    {
      retry(failureCount, error) {
        // retry if not unauth, forbidden or not found
        return failureCount < 3 && ![401, 403, 404].includes(error.data.httpStatus);
      },
    },
  );

  const createWorkflowMutation = trpc?.communications?.createWorkflow?.useMutation();
  const createWorkflow = async (workflow: WorkflowData) => {
    return await new Promise((resolve: (result: WorkflowData) => void, reject) => {
      createWorkflowMutation?.mutate(workflow, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const updateWorkflowMutation = trpc?.communications?.updateWorkflow?.useMutation();
  const updateWorkflow = async (workflow: WorkflowData) => {
    return await new Promise((resolve: (result: WorkflowData) => void, reject) => {
      updateWorkflowMutation?.mutate(workflow, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const deleteWorkflowMutation = trpc?.communications?.deleteWorkflow?.useMutation();
  const deleteWorkflow = async (workflowId: string) => {
    return await new Promise((resolve, reject) => {
      deleteWorkflowMutation?.mutate(
        { id: workflowId },
        {
          onSuccess: resolve,
          onError: reject,
        },
      );
    });
  };

  const automateRefetch = () =>
    trpcUtils.communications.getWorkflows.refetch({ companyId: `${company.id}` });

  return {
    workflows,
    isFetching,
    isError,
    isLoading,
    createWorkflow,
    updateWorkflow,
    deleteWorkflow,
    automateRefetch,
  };
};

type useAutomateProps = { channels?: ChannelType[]; running?: boolean[]; templateId?: string };
export type ChannelType = 'email' | 'sms';

/**
 * Transform offset in minutes to days, hours and minutes
 * @param offset time in minutes
 * @returns object with days, hours and minutes
 */
export const getDaysHoursMinutes = (offset: number) => {
  const MINUTES_IN_DAY = 60 * 24;
  const absOffset = Math.abs(offset);

  const days = Math.floor(absOffset / MINUTES_IN_DAY);
  const hours = Math.floor((absOffset % MINUTES_IN_DAY) / 60);
  const minutes = Math.round(absOffset % 60);
  const isNegative = offset < 0;

  return { days, hours, minutes, isNegative };
};

/**
 * Transform days, hours and minutes to offset in minutes
 * @param days
 * @param hours
 * @param minutes
 * @param isNegative
 * @returns time in minutes
 */
export const getOffsetInMinutes = (
  days: number,
  hours: number,
  minutes: number,
  isNegative?: boolean,
) => {
  return (days * 60 * 24 + hours * 60 + minutes) * (isNegative ? -1 : 1);
};

/**
 * Format days, hours and minutes to string
 * @param offset time in minutes
 * @returns  formatted string with days, hours and minutes
 */
export const getDaysHoursMinutesPrintFormat = (offset: number) => {
  const { days, hours, minutes } = getDaysHoursMinutes(offset);
  const daysString = days > 0 ? `${days} day${days > 1 ? 's' : ''}` : '';
  const hoursString = hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
  const minutesString = minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''}` : '';

  const daysSeparator =
    daysString && hoursString && minutesString
      ? ', '
      : daysString && (hoursString || minutesString)
      ? ' and '
      : '';

  const hoursSeparator = hoursString && minutesString ? ' and ' : '';

  return `${daysString}${daysSeparator}${hoursString}${hoursSeparator}${minutesString}`;
};

export const TriggerTypeOption = {
  BEFORE_CHECK_IN: 'BEFORE_CHECK_IN',
  CHECK_IN: 'CHECK_IN',
  AFTER_CHECK_IN: 'AFTER_CHECK_IN',
};

export type TriggerType = (typeof TriggerTypeOption)[keyof typeof TriggerTypeOption];
