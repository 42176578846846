import React, { useRef, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ActionSnackbar from 'Common/Snackbar/ActionSnackbar';
import { BaseContainer } from 'Style/Components/BaseComponents';
import { convertToBase64 } from 'helper/helper';

// Import the editor styles
import 'pintura/pintura.css';
// Import the editor default configuration
import { getEditorDefaults } from 'pintura';

// Import the editor component from `react-pintura`
import { PinturaEditor } from 'react-pintura';

export interface ImageManagerDialogProps {
  onSave: (imageData: string) => void;
  imageCropAspectRatio: number;
  onClose: () => void;
  open: boolean;
  imageUrl: string;
}
let editorConfig = getEditorDefaults({
  stickers: [
    '🙂',
    '😃',
    '😁',
    '😉',
    '😊',
    '🥰',
    '🤩',
    '😷',
    '😎',
    '⭐',
    '❤️',
    '☀️',
    '🎉',
    '✔️',
    '❌',
  ],
});

editorConfig = {
  ...editorConfig,
  enableButtonExport: false,
  cropEnableButtonFlipVertical: true,
  cropEnableButtonRotateRight: true,
};

const ImageManagerDialog = ({
  open,
  imageUrl,
  onClose,
  onSave,
  imageCropAspectRatio,
}: ImageManagerDialogProps) => {
  const pintura = useRef();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [unsaved, setUnsaved] = useState(false);
  const snackbarSeverity = 'error';
  const snackbarButtonText = 'YES';
  const snackbarMessage =
    'You have unsaved changes that will be lost if you exit. Are you sure you want to exit?';

  return (
    <Dialog maxWidth='lg' fullWidth open={open}>
      <ActionSnackbar
        onClose={() => {
          setOpenSnackbar(false);
          setUnsaved(false);
          onClose?.();
        }}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={snackbarSeverity}
        actionButtonText={snackbarButtonText}
        snackbarMessage={snackbarMessage}
      />
      <DialogTitle>Edit Image</DialogTitle>
      <DialogContent>
        <BaseContainer style={{ height: '700px' }} onClick={() => !unsaved && setUnsaved(true)}>
          <PinturaEditor
            ref={pintura}
            {...editorConfig}
            onProcess={(detail: Record<string, Blob>) => {
              const reader = new FileReader();
              const file = detail?.dest;
              reader.readAsDataURL(file);
              convertToBase64(file).then((base64: string) => {
                onSave?.(base64);
                setUnsaved(false);
              });
            }}
            src={imageUrl}
            imageCropAspectRatio={imageCropAspectRatio}
          ></PinturaEditor>
        </BaseContainer>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            // @ts-ignore
            pintura?.current?.editor?.processImage();
          }}
        >
          Save
        </Button>
        <Button
          onClick={() => {
            if (!unsaved) {
              setUnsaved(false);
              onClose?.();
            } else {
              setOpenSnackbar(true);
            }
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageManagerDialog;
