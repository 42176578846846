import ReservationsForm, { ReservationFormProps } from 'Common/Widgets/Reservation/ReservationForm';
import { logger } from 'lib/logger';
import React, { useState } from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { IReservation } from 'reservation/reservationType';
import { createReservation } from 'reservation/state/reservationActions';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';

type AddReservationProps = Omit<ReservationFormProps, 'onSubmit' | 'reservationId'>;

const AddReservation = ({ handleClose, ...rest }: AddReservationProps) => {
  const dispatch = useAppDispatch();
  const [saving, setSaving] = useState(false);

  const onSubmit = async (data: IReservation) => {
    let success = true;
    setSaving(true);

    try {
      await dispatch(createReservation(data.property_id, data));
      handleClose();
    } catch (error) {
      success = false;
      logger.error(`Error saving reservation: ${error}`);
    }

    setSaving(false);
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `Reservation was${success ? ' ' : ' not '}created successfully.`,
        variant: success ? SnackbarVariant.SUCCESS : SnackbarVariant.ERROR,
      }),
    );
  };

  return (
    <ReservationsForm submitting={saving} onSubmit={onSubmit} handleClose={handleClose} {...rest} />
  );
};

export default AddReservation;
