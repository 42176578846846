import CircleIcon from '@mui/icons-material/Circle';
import OutputIcon from '@mui/icons-material/Output';
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import React from 'react';

type AccountsListItemStatusProps = {
  name?: string;
  email: string;
  onDelete?: (name: string, email: string) => void;
  isRoot?: boolean;
  tokenExpired?: boolean;
};
export const AccountsListItemStatus = ({
  name,
  email,
  tokenExpired,
  onDelete,
  isRoot,
}: AccountsListItemStatusProps) => {
  return (
    <ListItem>
      <ListItemText
        data-testid='linked-account-item'
        primary={
          <Stack direction='row' sx={{ alignItems: 'center' }}>
            {isRoot && (
              <CircleIcon
                data-testid='isRoot'
                sx={{ marginRight: '8px', height: '10px', width: '10px', color: 'success.light' }}
              />
            )}
            <Typography fontSize='16px' fontWeight={500} letterSpacing='0.15px' lineHeight='175%'>
              {name}
            </Typography>
          </Stack>
        }
        secondary={
          <Typography variant='body2' color='rgba(0, 0, 0, 0.6)'>
            {email}
          </Typography>
        }
      />
      <ListItemSecondaryAction>
        {tokenExpired && (
          <Chip
            label='Signed out'
            variant='outlined'
            sx={{
              marginRight: '10px',
              backgroundColor: 'transparent',
              color: 'text.primary',
            }}
          />
        )}
        {!isRoot && (
          <IconButton
            title='Unlink Account'
            size='small'
            onClick={() => {
              onDelete(name, email);
            }}
            disabled={isRoot}
          >
            <OutputIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};
