import Chip from '@mui/material/Chip';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as React from 'react';

export interface NoiseIconProps {
  showAsPill?: boolean;
}

const HubIcon = ({ showAsPill = false }: NoiseIconProps) => {
  const theme = createTheme({
    components: {
      // Style sheet name ⚛️
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: 'error',
            color: 'text.white',
            '& svg': {
              color: 'text.white',
              '& svg': {
                color: 'text.white',
              },
            },
          },
        },
      },
    },
  });

  const hubImg = (
    <img
      src={`/img/hub-icon${showAsPill ? '-white' : ''}.png`}
      style={{ width: '24px', height: '24px' }}
    />
  );
  return (
    <ThemeProvider theme={theme}>
      {showAsPill ? <Chip size='small' icon={hubImg} label='Hub Offline' /> : <>{hubImg}</>}
    </ThemeProvider>
  );
};

export default HubIcon;
