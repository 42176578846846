import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { FavoritePropertyState } from 'types/favorite-properties';
import { Actions } from 'types/actions';

const initialUnitState: number[] = [];

const listProperties: Reducer<number[]> = (state = initialUnitState, action) => {
  switch (action.type) {
    case Actions.hydrateFavoriteProperties:
      return action.propertyIds;
    default:
      return state;
  }
};

const favoriteProperties: Reducer<FavoritePropertyState> = combineReducers<FavoritePropertyState>({
  list: listProperties,
});

export default favoriteProperties;
