import { createSelector } from '@reduxjs/toolkit';
import { IntegrationApiProviderType, IntegrationsApiSystem } from 'integrations/integrationsTypes';
import { ApplicationState } from 'redux/reducers';

const getIntegrations = (state: ApplicationState) => state.integrations;

export const findEnabledIntegrationsApiSystemSelector = (system: IntegrationsApiSystem) => {
  return createSelector([getIntegrations], ({ list, byId }) => {
    const result = list.find(id => byId[id].apiSystem === system && byId[id].enabled);
    return result !== undefined;
  });
};

export const findStatusIntegrationsApiProviderSelector = (
  providerName: IntegrationApiProviderType,
) => {
  return createSelector([getIntegrations], ({ list, byId }) => {
    let isEnabled = false;
    let isConnected = false;

    for (let i = 0; i < list.length; i++) {
      const id = list[i];

      if (byId[id].name === providerName) {
        isConnected = true;

        if (byId[id].enabled) {
          isEnabled = true;
        }

        break;
      }
    }

    return { isEnabled, isConnected };
  });
};
