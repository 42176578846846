import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { BaseContainer } from 'Style/Components/BaseComponents';
import * as React from 'react';
import styled from 'styled-components';

export interface TabDeactivatorProps {
  switchLabel: string;
  onSwitched: (isSwitched: boolean) => void;
}

const TabDeactivator = ({ switchLabel, onSwitched }: TabDeactivatorProps) => {
  const [isSwitchOn, setIsSwitchOn] = React.useState(true);
  const onSwitch = () => {
    setIsSwitchOn(!isSwitchOn);
    onSwitched(isSwitchOn);
  };
  return (
    <MainContainer isMobile>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CustomControl
            control={
              <Switch
                checked={isSwitchOn}
                onChange={onSwitch}
                name='custom-host-activated'
                color='primary'
              />
            }
            label={switchLabel}
          />
        </Grid>
        <Row item xs={12} sm={6}>
          <CustomMessageContainer container spacing={2}>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: 600, margin: 0 }} align='left' variant='caption'>
                IMPORTANT
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align='left' variant='caption'>
                Information entered here will OVERRIDE the global settings for this unit
              </Typography>
            </Grid>
          </CustomMessageContainer>
        </Row>
      </Grid>
    </MainContainer>
  );
};

const MainContainer = styled(BaseContainer)<{ isMobile: boolean }>`
  margin-top: 15px;
`;

const Row = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
`;

const CustomControl = styled(FormControlLabel)`
  && {
    padding-left: 40px;
  }
`;

const CustomMessageContainer = styled(Grid)`
  && {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export default TabDeactivator;
