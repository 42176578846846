import { Box } from '@mui/material';
import { useMedia } from '@operto/ui';
import React from 'react';
import { ChannelSort, Channel as TChannel } from 'stream-chat';
import { ChannelList } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import MessageChanneListItem from './MessageChanneListItem';
import MessageChannelListContainer from './MessageChannelListContainer';
import { MessageFilterIndex } from './MessagingPage';

interface ChannelMessagesListProps {
  previousChannelId: string;
  currentChannel: TChannel<DefaultStreamChatGenerics>;
  handleNewChat: (onNewChat: boolean) => void;
  handleChannelOnClick: (channel: TChannel) => void;
  handleFilterToggle: (filterIndex: MessageFilterIndex) => object;
  filterIndex: MessageFilterIndex;
  children: React.ReactElement;
}

const ChannelMessagesList = ({
  previousChannelId,
  currentChannel,
  handleNewChat,
  handleChannelOnClick,
  handleFilterToggle,
  filterIndex,
  children,
}: ChannelMessagesListProps) => {
  const { isDesktop, isTablet, isMobile } = useMedia();
  const maxWidth = () => {
    if (isDesktop) return '388px';
    if (isTablet && currentChannel) return '298px';
    return 'none';
  };

  const display = () => {
    if (isDesktop) return 'flex';
    if (isMobile && currentChannel) return 'none';
    return 'flex';
  };

  const options = { state: true, presence: true };
  const sort: ChannelSort = { last_updated: -1 };

  return (
    <Box
      sx={{
        maxWidth: maxWidth(),
        width: '100%',
        display: display(),
        flexDirection: 'column',
      }}
    >
      {children}
      <ChannelList
        List={props => <MessageChannelListContainer channelToDisplay={currentChannel} {...props} />}
        Preview={props => (
          <MessageChanneListItem
            previousChannelId={previousChannelId}
            onNewChat={handleNewChat}
            lastClickedOnChannel={handleChannelOnClick}
            hasCurrentChannel={currentChannel ? true : false}
            showFavouriteIcon={filterIndex === MessageFilterIndex.GUESTS}
            {...props}
          />
        )}
        filters={handleFilterToggle(filterIndex)}
        sort={sort}
        options={options}
      />
    </Box>
  );
};

export default ChannelMessagesList;
