import { Box, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useMedia } from '@operto/ui';
import IconForCode from 'event/IconForCode';
import { ActivityCodes, IEvent } from 'event/eventType';
import { formatRelativeToTimeZone } from 'helper/date';
import * as React from 'react';

export interface ActivityFeedListItemProps {
  codeType: ActivityCodes;
  message: string;
  payload: IEvent;
  title: string;
}

const ActivityFeedListItem = ({ codeType, message, payload, title }: ActivityFeedListItemProps) => {
  const { isMobile } = useMedia();
  return (
    <ListItem alignItems='flex-start' sx={{ display: 'flex', position: 'relative' }}>
      <ListItemAvatar>
        <IconForCode codeType={codeType} payload={payload} />
      </ListItemAvatar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: isMobile ? 'flex-start' : 'space-between',
            top: '16px',
            right: '16px',
          }}
        >
          <Typography
            variant='body1'
            sx={{
              color: 'rgba(0, 0, 0, 0.87);',
              paddingBottom: '4px',
            }}
          >
            {title}
          </Typography>
          <Typography
            variant='caption'
            sx={{
              color: 'rgba(0, 0, 0, 0.60);',
              paddingBottom: '8px',
            }}
          >
            {formatRelativeToTimeZone(payload.created_at, payload.timezone)}
          </Typography>
        </Box>
        <Typography
          variant='body2'
          sx={{
            color: 'rgba(0, 0, 0, 0.60);',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {message}
        </Typography>
      </Box>
    </ListItem>
  );
};

export default ActivityFeedListItem;
