import Skeleton from '@mui/lab/Skeleton';
import { Box, Button, CardMedia, Chip, Collapse, Divider, Typography } from '@mui/material';
import { ShowMoreToggle } from '@operto/ui';
import { ICode } from 'code/codeType';
import { guestVerificationEnabledSelector } from 'company/state/companySelectors';
import differenceInDays from 'date-fns/differenceInDays';
import { utcStringToDate, utcToTimeZone } from 'helper/date';
import { Property } from 'property/propertyType';
import * as React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IReservation, VerificationStatusText } from 'reservation/reservationType';
import { updateGuestReservationVerification } from 'reservation/state/reservationActions';
import MemberMenuItem from 'ui-library/Components/menu/MemberMenuItem';
import DateDisplay from 'ui-library/Components/widget/content/DateDisplay';
import { showAccessCodeSelector } from 'user/state/userSelectors';
import ReservationDropdownDrawer from './ReservationDropdownDrawer';

export interface ReservationContentMainProps {
  reservation: IReservation;
  property: Property;
  guestCode?: ICode;
  timezone: string;
  loading?: boolean;
  onCreate: () => void;
}

const ReservationContentMain = ({
  reservation,
  loading,
  guestCode,
  timezone,
  onCreate,
}: ReservationContentMainProps) => {
  const dispatch = useAppDispatch();
  const [openDetails, setOpenDetails] = React.useState(false);
  const guestVerificationEnabled = useAppSelector(guestVerificationEnabledSelector());
  const showAccessCode = useAppSelector(showAccessCodeSelector());
  const daysBetween = differenceInDays(
    utcStringToDate(reservation?.check_out),
    utcStringToDate(reservation?.check_in),
  );

  const toggleOpenDetails = () => {
    setOpenDetails(!openDetails);
  };

  const handleVerify = (reservation: IReservation) => {
    dispatch(
      updateGuestReservationVerification(!reservation?.incident_payment_verified, reservation),
    );
  };

  return (
    <>
      {reservation || loading ? (
        <Box>
          <ShowMoreToggle onClick={toggleOpenDetails} open={openDetails} fill='primary.main' />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <MemberMenuItem fullName={reservation?.guest_name} />
            {reservation && (
              <ReservationDropdownDrawer
                accessCode={guestCode?.access_code}
                reservation={reservation}
                verifyOnClick={() => handleVerify(reservation)}
                verifyDisabled={showAccessCode}
                paymentInfo={reservation?.payment_info}
                showEdit
                isFillLight
              />
            )}
          </Box>
          {!loading ? (
            <Box
              sx={{
                display: 'flex',
                marginTop: '20px',
                div: {
                  width: '50%',
                },
                height: '128px',
              }}
            >
              <DateDisplay date={reservation?.check_in} timezone={timezone} />
              <Divider
                orientation='vertical'
                sx={{
                  backgroundColor: 'text.white',
                  height: '128px',
                  marginInline: '10px',
                }}
              />
              <DateDisplay date={reservation?.check_out} timezone={timezone} />
            </Box>
          ) : (
            <Skeleton width='100%' height={120} />
          )}
          <Box
            sx={{
              marginTop: '20px',
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Typography variant='body2' sx={{ color: 'text.white' }}>
              Code Status: {guestCode?.code_status || '  N/A'}
            </Typography>
            <Typography variant='body2' sx={{ color: 'text.white' }}>
              Valid From:{' '}
              {guestCode?.valid_from
                ? utcToTimeZone(guestCode?.valid_from, timezone, 'MMM dd hh:mm a')
                : 'N/A'}
            </Typography>
            <Typography variant='body2' sx={{ color: 'text.white' }}>
              Valid Until:{' '}
              {guestCode?.valid_until
                ? utcToTimeZone(guestCode?.valid_until, timezone, 'MMM dd hh:mm a')
                : 'N/A'}
            </Typography>
            {openDetails && (
              <Collapse in={openDetails}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant='body2' sx={{ color: 'text.white', display: 'inline' }}>
                    Stay Duration: {daysBetween > 1 ? `${daysBetween} days` : `${daysBetween} day`}
                  </Typography>
                  {guestVerificationEnabled && (
                    <Typography variant='body2' sx={{ color: 'text.white' }}>
                      Verification Status:{' '}
                      {reservation?.verification_status
                        ? VerificationStatusText[reservation?.verification_status]
                        : '  N/A'}
                    </Typography>
                  )}
                </Box>
              </Collapse>
            )}
          </Box>
          <Box>
            <Chip
              label={`Access Code:  ${reservation?.guest_access_code || 'None'}`}
              sx={{
                display: 'flex',
                backgroundColor: '#0040CC',
                color: 'text.white',
                fontSize: 16,
                height: 44,
                borderRadius: '12px',
                padding: '10px',
                gap: '10px',
              }}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            color: 'text.white',
            height: '328px',
          }}
        >
          <CardMedia sx={{ marginBottom: '24px' }}>
            <img src='/img/widgets/ICO_No_Reservation_Light.svg' />
          </CardMedia>
          <Typography variant='body1'>No pending or active guests</Typography>
          <Button
            onClick={onCreate}
            sx={{ color: 'text.white', textTransform: 'none', fontWeight: 700 }}
          >
            Add New Reservation
          </Button>
        </Box>
      )}
    </>
  );
};

export default ReservationContentMain;
