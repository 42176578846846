import axios, { AxiosResponse } from 'axios';
import { GetGuestsParams } from 'guest/state/guestActions';

const api = process.env.REACT_APP_API_URL;

export const getGuests = ({
  propertyId,
  urlSearchParams,
}: GetGuestsParams): Promise<AxiosResponse> => {
  let url = `${api}/v2/companies/guests`;
  if (propertyId) {
    url = `${api}/v2/companies/properties/${propertyId}/guests`;
  }

  return axios.get(url, { params: urlSearchParams });
};

export const getGuestsFilterOptions = () => {
  return axios.get(`${api}/v2/companies/guests/filter-options`);
};
