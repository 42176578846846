import NotificationsIcon from '@mui/icons-material/NotificationsNone';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { IPropertyAlertsStats } from 'dashboard/dashboardTypes';
import { dashboardStatSelector } from 'dashboard/state/dashboardSelectors';
import * as React from 'react';
import { toggleSlidebar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SlidebarType } from 'types/ui';

const AlertBell = () => {
  const dispatch = useAppDispatch();
  const propertyAlertsState = useAppSelector(
    dashboardStatSelector('property_alerts_stats'),
  ) as IPropertyAlertsStats;

  const handleClick = () => {
    dispatch(toggleSlidebar(SlidebarType.SHOW_PROPERTY_ALERTS, {}));
  };

  return (
    <>
      <IconButton onClick={handleClick} size='large'>
        <Badge badgeContent={propertyAlertsState.total_alerts} color='error'>
          <NotificationsIcon />
        </Badge>
      </IconButton>
    </>
  );
};

export default AlertBell;
