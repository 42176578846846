import React from 'react';
import { ImageUploadType, useGuestPortal } from 'redux/guestportal';
import ImageEditor from 'ui-library/Components/misc/ImageEditor';

type ImageEditorProps = {
  image: string;
  onImageChange: (image: string) => void;
  imageType?: ImageUploadType;
  ratio?: '1:1' | '3:2' | '4:3';
};
export const GPImageEditor: React.FC<ImageEditorProps> = props => {
  const { image, onImageChange, imageType, ratio = '1:1' } = props;
  const { uploadGuideImages, uploadRandomImages } = useGuestPortal();

  return (
    <ImageEditor
      ratio={ratio}
      image={image}
      onSave={async (imageData: string) => {
        const urls = imageType
          ? await uploadRandomImages([imageData], imageType)
          : await uploadGuideImages([imageData]);
        onImageChange(urls.shift() ?? '');
      }}
      onDrop={async (base64: string) => {
        const urls = imageType
          ? await uploadRandomImages([base64], imageType)
          : await uploadGuideImages([base64]);
        onImageChange(urls.shift() ?? '');
      }}
      onDelete={() => onImageChange('')}
    />
  );
};

export default GPImageEditor;
