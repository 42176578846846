import { NotifiableError } from '@bugsnag/js';
import { Token } from '@stripe/stripe-js';
import * as api from 'api/payment-method';
import { AxiosResponse } from 'axios';
import { OpertoLogger } from 'Logger/logger';
import { ApplicationState } from 'redux/reducers';
import { AppDispatch } from 'redux/store';
import { Actions } from 'types/actions';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { toggleSnackbar } from './ui';

export const getStripeToken = () => async (dispatch: AppDispatch) => {
  try {
    const data = await api.getStripeToken();
    const stripeToken = data.data.data;
    if (stripeToken) {
      dispatch({
        type: Actions.storeStripeToken,
        stripeToken,
      });
    }
  } catch (err) {
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: err.response.data.message,
        variant: SnackbarVariant.ERROR,
      }),
    );
    OpertoLogger.Log(err);
  }
};

export const updatePaymentMethod = (paymentMethodId: string) => async () => {
  try {
    return await api.updatePaymentMethod(paymentMethodId);
  } catch (err) {
    OpertoLogger.Log(err);
  }
};

export const getPaymentMethod = () => (dispatch: AppDispatch) => {
  api
    .getPaymentMethod()
    .then((data: AxiosResponse) => {
      const paymentMethod = data.data.data;
      if (paymentMethod) {
        dispatch({
          type: Actions.hydratePaymentMethod,
          paymentMethod,
        });
      }
    })
    .catch((err: NotifiableError) => {
      OpertoLogger.Log(err);
    });
};
export const postToken =
  (token: Token) => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const state: ApplicationState = getState();
    const { id } = state.user.user;
    const email = state.members.byId[id] && state.members.byId[id].email;
    const companyName = state.company.name;
    api
      .postToken(token, email, companyName)
      .then((data: AxiosResponse) => {
        const paymentMethod = data.data.data;
        if (paymentMethod) {
          dispatch({
            type: Actions.hydratePaymentMethod,
            paymentMethod,
          });
        }
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  };
