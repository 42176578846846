import axios from 'axios';

const appApiVersion = 'v2';
const api = process.env.REACT_APP_API_URL;

export const getReservationByRoomStatus = (roomStatus: string) => {
  return axios.get(`${api}/${appApiVersion}/dashboard/${roomStatus}`, {});
};

export const getPropertyAlertsSummary = () => {
  return axios.get(`${api}/${appApiVersion}/dashboard/property-alerts-summary`, {});
};
