import { NodeModel, useDragOver } from '@minoru/react-dnd-treeview';
import { Box, Card, Typography } from '@mui/material';
import * as React from 'react';

export interface SortCardProps {
  onClick?: () => void;
  icon?: React.ReactNode;
  title: string;
  expandable?: boolean;
  actionsSection?: React.ReactNode;
  depth?: number;
  isExpanded?: boolean;
  node?: NodeModel;
  numOfChildren?: number;
}

const SortCard = ({
  title,
  onClick,
  icon,
  expandable,
  actionsSection,
  depth,
  isExpanded,
  node,
  numOfChildren,
}: SortCardProps) => {
  const dragOverProps = useDragOver(node?.id, isExpanded, onClick);
  const handleClick = () => {
    if (expandable && numOfChildren > 0) {
      onClick();
    }
  };
  return (
    <Box
      sx={{
        minWidth: depth > 0 ? '500px' : '600px',
        padding: expandable ? '30px' : 0,
        paddingBottom: depth === 0 && isExpanded ? 0 : undefined,
        cursor: 'move',
        marginBottom: '14px',
        marginLeft: depth > 0 && '30px',
      }}
      className={expandable && 'Category'}
      {...dragOverProps}
    >
      <Card
        sx={{
          backgroundColor: expandable ? '#F6F2E9' : 'inherit',
          minHeight: '16px',
          padding: '10px',
          borderRadius: '4px',
        }}
        onClick={handleClick}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ color: 'rgba(0, 0, 0, 0.18)', marginRight: '14px' }}>{icon}</Box>
            <Typography variant='body2'>{title}</Typography>
          </Box>
          {actionsSection}
        </Box>
      </Card>
    </Box>
  );
};

export default SortCard;
