import Bugsnag, { BreadcrumbType, NotifiableError } from '@bugsnag/js';

export abstract class OpertoLogger {
  public static Log(err: NotifiableError) {
    if (this.LoggerInitialized()) {
      Bugsnag.notify(err);
    }
  }

  public static LeaveBreadCrumb(
    message: string,
    metadata: Record<string, unknown>,
    type?: BreadcrumbType,
  ) {
    if (this.LoggerInitialized()) {
      Bugsnag.leaveBreadcrumb(message, metadata, type);
    }
  }

  public static LoggerInitialized(): boolean {
    return ['production', 'staging'].includes(process.env.NODE_ENV);
  }
}
