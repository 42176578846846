const getReleaseStage = () => {
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    return 'production';
  }

  if (process.env.JEST_WORKER_ID !== undefined) {
    return 'test';
  }

  return 'development';
};

export const RELEASE_STAGE = getReleaseStage();
export const isProduction = RELEASE_STAGE === 'production';
export const isDevelopment = RELEASE_STAGE === 'development';
export const isTesting = RELEASE_STAGE === 'test';
