import { Stack } from '@mui/material';
import { Text } from '@operto/ui';
import { ConfirmDialog, ConfirmDialogProps } from 'Common/Dialog/ConfirmDialog';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

type UnassignUnitDialogProps = Pick<ConfirmDialogProps, 'open' | 'loading'> & {
  onCancel: () => void;
  onUnassign: () => void;
};

export const UnassignUnitDialog = ({
  open,
  onCancel,
  onUnassign,
  loading,
  ...rest
}: UnassignUnitDialogProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      open={open}
      title={t('unassign_locks_dialog_title')}
      onClose={onCancel}
      data-testId='unassign-unit-dialog'
      submitButtonText={t('unassign_locks_dialog_submit_button')}
      submitButtonColor='primary'
      onSubmit={onUnassign}
      loading={loading} // Disable buttons when loading
      {...rest}
    >
      <Stack spacing={2}>
        <Text.BodyLarge>{t('unassign_locks_dialog_description')}</Text.BodyLarge>
      </Stack>
    </ConfirmDialog>
  );
};
