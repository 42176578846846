import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { RoleDict, RoleState } from '../../types/roles';
import { Actions } from '../../types/actions';

const list: Reducer<string[]> = (state = [], action) => {
  switch (action.type) {
    case Actions.insertRole:
      return [...state, action.role.id];
    case Actions.deleteRole:
      return state;
    // for now
    default:
      return state;
  }
};

const byId: Reducer<RoleDict> = (state = {}, action) => {
  switch (action.type) {
    case Actions.insertRole:
    case Actions.upsertRole:
      return {
        ...state,
        [action.role.id]: action.role,
      };
    case Actions.deleteRole:
      return state;
    // for now
    default:
      return state;
  }
};

const reducer: Reducer<RoleState> = combineReducers<RoleState>({
  byId,
  list,
});

export default reducer;
