import { TemplateData } from '@operto/communications-shared';
import { Status, Task } from '@operto/tasks-shared';
import { trpc } from '@operto/trpc-client';

export const REFRESH_INTERVAL = 5 * 60 * 1000;

export default function useTasks({
  statusFilter = [],
  refetchOnWindowFocus = true,
}: {
  statusFilter?: Status[];
  refetchOnWindowFocus?: boolean;
}) {
  const {
    data,
    isFetching,
    isLoading,
  }: { data?: Task[]; isFetching: boolean; isLoading: boolean } = trpc.tasks?.getTasks.useQuery(
    { status: statusFilter },
    {
      retry(failureCount, error) {
        // retry if not unauth, forbidden or not found
        return failureCount < 3 && ![401, 403, 404].includes(error.data.httpStatus);
      },
      refetchInterval: REFRESH_INTERVAL,
      refetchOnWindowFocus: refetchOnWindowFocus,
    },
  );

  const getTask = (taskId: string): TemplateData =>
    trpc.tasks.getTask.useQuery(
      {
        id: taskId,
      },
      { enabled: !!taskId, refetchOnWindowFocus: refetchOnWindowFocus },
    );

  return {
    isFetching,
    isLoading,
    data,
    getTask,
  };
}

export const TODO_STATUS = ['requested', 'reviewed', 'in progress'];
export const NOT_STARTED_STATUS = ['requested', 'reviewed', 'scheduled'];
