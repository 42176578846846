import { Button, Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import ScriptDropDownMenu from 'ui-library/Components/drop-down/ScriptDropDownMenu';
import { SettingCard } from '@operto/ui-library';
import { insertTextAtPosition } from 'ui-library/Helpers/textHelper';
import { QRCodeFormHandle, QRCodeAddButtonForm } from './QRCodeAddButtonForm';
import { FormTextField } from 'ui-library/Components/input/FormTextField';
import { useForm } from 'react-hook-form';
import { ActionButton } from 'redux/guestportal/models';
import { isAdmin } from 'redux/actions/ui';

type SelectionRange = {
  selectionStart: number;
  selectionEnd: number;
};

type QRCodeMessagesFormData = {
  title?: string;
  message: string;
  buttons: ActionButton[];
};

type QRCodeMessagesFormProps = QRCodeMessagesFormData & {
  name: string;
  header: string;
  onSave: (data: QRCodeMessagesFormData) => void;
};

export const QRCodeMessagesForm = ({
  name,
  header,
  title,
  message,
  buttons,
  onSave,
}: QRCodeMessagesFormProps) => {
  const titleRef = useRef<SelectionRange>({ selectionStart: 0, selectionEnd: 0 });
  const messageRef = useRef<SelectionRange>({ selectionStart: 0, selectionEnd: 0 });
  const buttonRef = useRef<QRCodeFormHandle>();
  const { handleSubmit, watch, setValue, control } = useForm<QRCodeMessagesFormData>({
    mode: 'all',
    defaultValues: {
      title,
      message,
      buttons,
    },
  });
  const titleLabel = `${name} Message`;
  const titleHeader = isAdmin() ? `${titleLabel} (Admin Only)` : titleLabel;

  const onSubmit = async (buttons: ActionButton[]) => {
    setValue('buttons', buttons);
    const submit = await handleSubmit(onSave);
    submit();
  };

  return (
    <SettingCard
      title={titleHeader}
      footerChildren={
        <Button
          variant='contained'
          startIcon={<SaveIcon />}
          onClick={() => buttonRef.current.submit()}
        >
          Save
        </Button>
      }
    >
      <Stack spacing={3} mb={2}>
        <Typography variant='body2'>{header}</Typography>
        {title && (
          <Stack direction='row' alignItems='center' spacing={2}>
            <FormTextField
              inputRef={titleRef}
              field='title'
              label={`Enter ${name.toLowerCase()} title`}
              fullWidth
              sx={{ my: 0 }}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: `${name.toLowerCase()} title is required`,
                },
              }}
            />
            <ScriptDropDownMenu
              onTextInsert={(text: string) =>
                setValue(
                  'title',
                  insertTextAtPosition(watch('title'), text, {
                    start: titleRef.current.selectionStart,
                    end: titleRef.current.selectionEnd,
                  }),
                )
              }
            />
          </Stack>
        )}
        <Stack direction='row' alignItems='center' spacing={2}>
          <FormTextField
            inputRef={messageRef}
            multiline
            field='message'
            label={`Enter ${name.toLowerCase()} message`}
            fullWidth
            sx={{ my: 0 }}
            control={control}
            rules={{
              required: {
                value: true,
                message: `${name.toLowerCase()} message is required`,
              },
            }}
          />
          <ScriptDropDownMenu
            onTextInsert={(text: string) =>
              setValue(
                'message',
                insertTextAtPosition(watch('message'), text, {
                  start: messageRef.current.selectionStart,
                  end: messageRef.current.selectionEnd,
                }),
              )
            }
          />
        </Stack>
      </Stack>
      <QRCodeAddButtonForm ref={buttonRef} buttons={watch('buttons')} onSubmit={onSubmit} />
    </SettingCard>
  );
};
