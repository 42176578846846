import { Box, List, SxProps, Theme, Typography } from '@mui/material';
import { theme } from '@operto/ui';
import React from 'react';
import { DEVICES, FilterDataType, FilterGroup } from 'types/filter-type';
import { CheckboxOption } from './CheckboxOption';
import { FilterChangeEvent, isSelected } from './FilterPanel';
import { FiltersToolbarMobileProps } from './FiltersToolbarMobile';

export type FilterPanelMobileProps = Omit<
  FiltersToolbarMobileProps,
  'filterGroups' | 'updateTotalFilterLabelMobile'
> & {
  filter: FilterGroup;
};

export const FilterPanelMobile = ({
  filter,
  searchParams,
  onFilterChange,
}: FilterPanelMobileProps) => {
  const handleChange = ({ key, value, checked, singleSelect }: FilterChangeEvent) => {
    const currentValue = searchParams.get(key)?.split(',') ?? [];
    let updated: string[] = []; // no value
    if (!singleSelect) {
      if (checked) {
        updated = [...currentValue, value]; // append value
      } else {
        updated = currentValue.filter(val => val !== value); // remove value
      }
    } else if (checked) {
      updated = [value]; // replace current value
    }

    if (updated.length === 0) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, updated.toString());
    }

    onFilterChange(searchParams);
  };

  return (
    <Box sx={container} id={`index-${filter.groupName}`}>
      {filter.groupName !== DEVICES && (
        <Typography sx={titleStyle} id={`index-${filter.groupName}`}>
          {filter.label}
        </Typography>
      )}
      <Box sx={checkboxContainerStyle}>
        {filter.options.map((subOption: FilterDataType) => {
          switch (subOption.filterType) {
            case 'checkbox':
              return (
                <List
                  sx={checkBoxWrapper}
                  key={`${filter.groupName}-${subOption.name}-${subOption.filterType}-wrapper`}
                >
                  <CheckboxOption
                    key={`${filter.groupName}-${subOption.name}-${subOption.filterType}`}
                    testID={`${filter.groupName}-${subOption.name}-${subOption.filterType}`}
                    option={subOption}
                    checked={isSelected(subOption.name, searchParams.get(filter.groupName))}
                    onChange={checked =>
                      handleChange({
                        key: filter.groupName,
                        checked,
                        value: subOption.name,
                        singleSelect: subOption.singleSelect,
                      })
                    }
                  />
                </List>
              );
            case 'slidedown':
              return (
                <List
                  sx={nestedFilterContainer}
                  key={`${filter.groupName}-${subOption.name}-${subOption.filterType}-wrapper`}
                >
                  <Typography sx={nestedFilterHeaderStyle} variant='subtitle-lg-600'>
                    {subOption.label}
                  </Typography>
                  <Box sx={nestedFilterCheckboxWrapper}>
                    {subOption.slideDownData.map((data: FilterDataType) => (
                      <CheckboxOption
                        key={`${subOption.name}-${data.name}-${data.filterType}`}
                        testID={`${subOption.name}-${data.name}-${data.filterType}`}
                        option={data}
                        checked={isSelected(data.name, searchParams.get(subOption.name))}
                        onChange={checked =>
                          handleChange({
                            key: subOption.name,
                            checked,
                            value: data.name,
                            singleSelect: subOption.singleSelect,
                          })
                        }
                      />
                    ))}
                  </Box>
                </List>
              );
          }
        })}
      </Box>
    </Box>
  );
};

const container: SxProps = {
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingBottom: '16px',
};

const nestedFilterContainer: SxProps = {
  borderBottom: '1px solid rgba(21, 41, 122, 0.12)',
  padding: '0 0 16px 0',
  display: 'block',
};

const nestedFilterCheckboxWrapper: SxProps = {
  padding: '0px 16px',
};

const nestedFilterHeaderStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 16px',
};

const checkBoxWrapper: SxProps = {
  padding: '0px 16px',
};

export const headerStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
  gap: '16px',
};

export const titleStyle: SxProps = {
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '24px',
  color: '#08163E',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
  gap: '16px',
};

const checkboxContainerStyle: SxProps<Theme> = {
  padding: '0px',
  overflow: 'auto',
  '::-webkit-scrollbar': {
    width: '4px',
  },
  '::-webkit-scrollbar-track': {
    width: '0px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '2px',
  },
  '::-webkit-scrollbar-thumb:hover': theme => ({
    background: theme.palette.divider,
  }),
};
