import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { MoreUnits } from '../../../types/ui';
import { Actions } from '../../../types/actions';

const show: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.hideViewMoreUnits:
      return false;
    case Actions.showViewMoreUnits:
      return !state;
    default:
      return state;
  }
};
const unitIds: Reducer<number[]> = (state = [], action) => {
  switch (action.type) {
    case Actions.showViewMoreUnits:
      return action.unitIds;
    default:
      return state;
  }
};
const title: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.showViewMoreUnits:
      return action.title;
    default:
      return state;
  }
};

const moreUnits: Reducer<MoreUnits> = combineReducers<MoreUnits>({
  show,
  unitIds,
  title,
});

export default moreUnits;
