import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material';
import RightSideTopbar from 'Common/Slidebar/components/RightSideTopbar';
import { login } from 'api/userAPI';
import { addOrReplaceMultiAuthByEmail } from 'auth/LinkedAccountsHelper';
import { AxiosResponse } from 'axios';
import { getCompanyInformation } from 'company/state/companyAction';
import React, { useState } from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import * as userActions from 'redux/actions/users';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import AddCard from 'ui-library/Components/card/AdderCard';
import { Creds } from './LinkedAccounts';

interface LinkAccountProps {
  handleClose: () => void;
}

export const LinkAccount = ({ handleClose }: LinkAccountProps) => {
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [creds, setCreds] = useState<Creds | undefined>(undefined);

  const onUsernameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreds({ ...creds, username: e.target.value });
  };

  const onPasswordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreds({ ...creds, password: e.target.value });
  };

  const onSaveClicked = () => {
    login(creds.username, creds.password)
      .then(async (data: AxiosResponse) => {
        const token = data?.data?.data?.access_token;
        addOrReplaceMultiAuthByEmail(creds.username, {
          token: token,
          isLoggedIn: false,
          expired: false,
          name: 'Unknown',
          cid: '',
          email: creds.username,
        });
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Account linked successfully.',
            variant: SnackbarVariant.SUCCESS,
          }),
        );

        try {
          // to get the new linked account company info we need to grab their company info with their token
          // this next block of code syncronously grabs the current token, stores the new token, grabs the company info, then restores the current token
          // hold current token
          const currentToken = JSON.parse(localStorage.getItem('mpAuth'));
          await dispatch(userActions.storeToken(token));
          await dispatch(getCompanyInformation());

          // restore current token
          await dispatch(userActions.storeToken(currentToken));
          await dispatch(getCompanyInformation());

          handleClose();
        } catch (error) {
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Linked Account Error: Please try again.',
              variant: SnackbarVariant.ERROR,
            }),
          );
        }
      })
      .catch(() => {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Login Error: Please check your credentials.',
            variant: SnackbarVariant.ERROR,
          }),
        );
      });
  };

  return (
    <Box>
      <RightSideTopbar
        title={'Add account'}
        handleClose={() => {
          handleClose();
        }}
        onSave={onSaveClicked}
        buttonDisabled={creds?.username === '' || creds?.password === ''}
      />
      <Box mx={2} my={2} bgcolor='white'>
        <AddCard title='Login Information'>
          <TextField
            id='newAccountUsername'
            name='newAccountUsername'
            label='Email'
            placeholder='Email'
            inputProps={{ autoComplete: 'off', form: { autoComplete: 'off' } }}
            variant='outlined'
            onChange={onUsernameChanged}
            value={creds?.username}
            margin='normal'
            fullWidth
            required
          />
          <TextField
            id='newAccountPassword'
            name='newAccountPassword'
            type={showPassword ? 'text' : 'password'}
            label='Password'
            placeholder='Password'
            inputProps={{ autoComplete: 'off', form: { autoComplete: 'off' } }}
            variant='outlined'
            onChange={onPasswordChanged}
            value={creds?.password}
            margin='normal'
            fullWidth
            required
          />
          <FormControlLabel
            sx={{ mx: 0 }}
            label='Show Password'
            control={
              <Checkbox
                checked={showPassword}
                onChange={(_event, checked) => setShowPassword(checked)}
              />
            }
          />
        </AddCard>
      </Box>
    </Box>
  );
};
