import { Close, DeleteOutlineOutlined } from '@mui/icons-material';
import {
  Card,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
} from '@mui/material';
import { TemplateDataType } from '@operto/communications-shared';
import { Text } from '@operto/ui';
import {
  getDaysHoursMinutes,
  getOffsetInMinutes,
  TriggerType,
  TriggerTypeOption,
} from 'hooks/useAutomate';
import useCommunications from 'hooks/useCommunications';
import useTranslation from 'hooks/useTranslation';
import React, { ChangeEvent, useState } from 'react';
import Counter from './Counter';
import ErrorMessageBanner from './ErrorMessageBanner';
import ListSelector from './ListSelector';

export default function AutomateWorkflowTriggerPanel({
  onDelete,
  onClose,
  trigger,
  onOffsetChange,
  onTypeChange,
  onTemplateChange,
  showErrorMessages,
}: AutomateWorkflowTriggerPanelProps) {
  const { t } = useTranslation();
  const { data: communications } = useCommunications();
  const [showDaysError, setShowDaysError] = useState(false);
  const [showHoursError, setShowHoursError] = useState(false);
  const [showMinutesError, setShowMinutesError] = useState(false);

  const { days, hours, minutes } = getDaysHoursMinutes(trigger.offset);

  const isNegative = trigger.type === TriggerTypeOption.BEFORE_CHECK_IN;
  const isBefore = trigger.type === TriggerTypeOption.BEFORE_CHECK_IN;
  const isAt = trigger.type === TriggerTypeOption.CHECK_IN;
  const disableDecreaseDays = days === 0;
  const disableDecreaseHours = hours === 0;
  const disableDecreaseMinutes = minutes === 0;
  const disableIncreaseDays = days >= MAX_DAYS;
  const disableIncreaseHours = hours >= MAX_HOURS;
  const disableIncreaseMinutes = minutes >= MAX_MINUTES;
  const daysErrorMessage = showDaysError ? `${t('up_to', { time: MAX_DAYS })} ${t('days')}` : '';
  const hoursErrorMessage = showHoursError
    ? `${t('up_to', { time: MAX_HOURS })} ${t('hours')}`
    : '';
  const minutesErrorMessage = showMinutesError
    ? `${t('up_to', { time: MAX_MINUTES })} ${t('minutes')}`
    : '';
  const hasScheduleError = showErrorMessages && !isAt && !days && !hours && !minutes;
  const hasTemplateError =
    showErrorMessages && !trigger.templates?.email?.[0] && !trigger.templates?.sms?.[0];
  const errorMessageSchedule = `${t('schedule')} ${t('is')} ${t('required')}`.toLowerCase();
  const errorMessageTemplate = `${t('template')} ${t('is')} ${t('required')}`.toLowerCase();
  const emailTemplateSelectorOptions =
    communications
      ?.filter(template => template.channelType === 'email')
      .map(template => ({
        label: template.name,
        id: template.id,
      })) ?? [];
  const smsTemplateSelectorOptions =
    communications
      ?.filter(template => template.channelType === 'sms')
      .map(template => ({
        label: template.name,
        id: template.id,
      })) ?? [];
  const emailTemplateSelectorValue = {
    id: trigger.templates?.email?.[0]?.id ?? '',
    label: trigger.templates?.email?.[0]?.name ?? '',
  };
  const smsTemplateSelectorValue = {
    id: trigger.templates?.sms?.[0]?.id ?? '',
    label: trigger.templates?.sms?.[0]?.name ?? '',
  };

  const handleRadioChange = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    onTypeChange(value === 'at' ? TriggerTypeOption.CHECK_IN : TriggerTypeOption.AFTER_CHECK_IN);
    onOffsetChange(0);
  };

  return (
    <Card elevation={1} sx={rootContainerStyles}>
      <Stack sx={titleContainerStyles}>
        <Text variant='h3-700' style={titleStyles}>
          {t('trigger')}
        </Text>

        <Tooltip title={t('delete')} slotProps={tooltipSlotProps}>
          <IconButton size='large' onClick={onDelete}>
            <DeleteOutlineOutlined />
          </IconButton>
        </Tooltip>

        <Tooltip title={t('close')} slotProps={tooltipSlotProps}>
          <IconButton size='large' onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </Stack>

      <Stack sx={configureStyles}>
        {hasScheduleError && <ErrorMessageBanner message={errorMessageSchedule} />}

        <Text variant='subtitle-lg-600'>
          {t(isBefore ? 'before_check_in' : 'at_or_after_check_in')}*
        </Text>

        {!isBefore && (
          <RadioGroup
            row
            value={isAt ? 'at' : 'after'}
            onChange={handleRadioChange}
            sx={{ padding: '8px' }}
          >
            <FormControlLabel sx={{ flex: 1 }} value='at' control={<Radio />} label={t('at')} />
            <FormControlLabel
              sx={{ flex: 1 }}
              value='after'
              control={<Radio />}
              label={t('after')}
            />
          </RadioGroup>
        )}

        {!isAt && (
          <>
            <Counter
              label={t('days')}
              onIncrement={() => {
                setShowDaysError(false);
                onOffsetChange(getOffsetInMinutes(days + 1, hours, minutes, isNegative));
              }}
              onDecrement={() => {
                setShowDaysError(false);
                onOffsetChange(getOffsetInMinutes(days - 1, hours, minutes, isNegative));
              }}
              onChange={value => {
                if (value > MAX_DAYS) {
                  onOffsetChange(getOffsetInMinutes(MAX_DAYS, hours, minutes, isNegative));
                  setShowDaysError(true);
                  return;
                }

                setShowDaysError(false);
                onOffsetChange(getOffsetInMinutes(value, hours, minutes, isNegative));
              }}
              value={days}
              disableDecrease={disableDecreaseDays}
              disableIncrease={disableIncreaseDays}
              helperText={daysErrorMessage}
            />

            <Counter
              label={t('hours')}
              onIncrement={() => {
                setShowHoursError(false);
                onOffsetChange(getOffsetInMinutes(days, hours + 1, minutes, isNegative));
              }}
              onDecrement={() => {
                setShowHoursError(false);
                onOffsetChange(getOffsetInMinutes(days, hours - 1, minutes, isNegative));
              }}
              onChange={value => {
                if (value > MAX_HOURS) {
                  onOffsetChange(getOffsetInMinutes(days, MAX_HOURS, minutes, isNegative));
                  setShowHoursError(true);
                  return;
                }

                setShowHoursError(false);
                onOffsetChange(getOffsetInMinutes(days, value, minutes, isNegative));
              }}
              value={hours}
              disableDecrease={disableDecreaseHours}
              disableIncrease={disableIncreaseHours}
              helperText={hoursErrorMessage}
            />

            <Counter
              label={t('minutes')}
              onIncrement={() => {
                setShowMinutesError(false);
                onOffsetChange(getOffsetInMinutes(days, hours, minutes + 1, isNegative));
              }}
              onDecrement={() => {
                setShowMinutesError(false);
                onOffsetChange(getOffsetInMinutes(days, hours, minutes - 1, isNegative));
              }}
              onChange={value => {
                if (value > MAX_MINUTES) {
                  onOffsetChange(getOffsetInMinutes(days, hours, MAX_MINUTES, isNegative));
                  setShowMinutesError(true);
                  return;
                }

                setShowMinutesError(false);
                onOffsetChange(getOffsetInMinutes(days, hours, value, isNegative));
              }}
              value={minutes}
              disableDecrease={disableDecreaseMinutes}
              disableIncrease={disableIncreaseMinutes}
              helperText={minutesErrorMessage}
            />
          </>
        )}

        <Divider />

        <Text variant='subtitle-lg-600'>{t('send_communications')}*</Text>

        {hasTemplateError && <ErrorMessageBanner message={errorMessageTemplate} />}

        <Text variant='h4-300'>{t('you_can_add_up_to_two_templates')}.</Text>

        <ListSelector
          label={t('email_template')}
          placeholder={t('select_email_template')}
          list={emailTemplateSelectorOptions}
          onChange={template =>
            onTemplateChange(
              'email',
              communications.find(c => c.id === template?.id),
            )
          }
          value={emailTemplateSelectorValue}
        />

        <ListSelector
          label={t('sms_template')}
          placeholder={t('select_sms_template')}
          list={smsTemplateSelectorOptions}
          onChange={template =>
            onTemplateChange(
              'sms',
              communications.find(c => c.id === template?.id),
            )
          }
          value={smsTemplateSelectorValue}
        />
      </Stack>
    </Card>
  );
}

type AutomateWorkflowTriggerPanelProps = {
  onDelete: () => void;
  onClose: () => void;
  trigger: {
    type: TriggerType;
    offset: number;
    templates?: { email?: TemplateDataType[]; sms?: TemplateDataType[] };
  };
  onOffsetChange: (offset: number) => void;
  onTypeChange: (type: TriggerType) => void;
  onTemplateChange: (channelType: string, template?: TemplateDataType) => void;
  showErrorMessages?: boolean;
};

export const MAX_DAYS = 364;
export const MAX_HOURS = 23;
export const MAX_MINUTES = 59;

const rootContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  minWidth: '500px',
  backgroundColor: '#fff',
  height: '100%',
  '&.MuiCard-root': {
    marginTop: '24px',
  },
};

const titleContainerStyles = {
  padding: '12px 12px 16px 24px',
  flexDirection: 'row',
};

const titleStyles = {
  flex: 1,
  alignSelf: 'flex-end',
};

const configureStyles = {
  padding: '24px',
  flexDirection: 'column',
  gap: '24px',
};

const tooltipSlotProps = {
  popper: { modifiers: [{ name: 'offset', options: { offset: [0, -8] } }] },
};
