import { validateCognitoToken } from 'api/tokenAPI';
import { logger } from 'lib/logger';
import { useEffect, useState } from 'react';

export default function useCognitoAuthFlow() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const handleTokenValidation = async (token: string | null) => {
      if (token) {
        try {
          const isValid = await validateCognitoToken(token);
          if (isValid) {
            setIsAuthenticated(true);
          }
        } catch (err) {
          logger.error('Caught an error when validating the cognito token', err);
        }
      }
    };

    const getParams = new URLSearchParams(window.location.search);
    const token = getParams.get('token');

    handleTokenValidation(token);
  }, []);

  return isAuthenticated;
}
