import * as React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { BaseContainer } from 'Style/Components/BaseComponents';
import styled from 'styled-components';

export enum RiskLevel {
  NONE,
  LOW,
  MEDIUM,
  HIGH,
}
export interface RiskDisplayProps {
  riskLevel: RiskLevel;
  fill?: string;
  mode?: string;
}

const RiskDisplay = ({ riskLevel, mode }: RiskDisplayProps) => {
  const color = mode ? (mode === 'dark' ? 'text.primary' : 'white') : 'white';

  const riskIcon = () => {
    switch (riskLevel) {
      case RiskLevel.LOW:
        return (
          <LowIcon>
            <CheckIcon />
          </LowIcon>
        );
      case RiskLevel.MEDIUM:
        return (
          <WarnIcon>
            <CheckIcon />
          </WarnIcon>
        );
      case RiskLevel.HIGH:
        return (
          <ErrorIcon>
            <PriorityHighIcon />
          </ErrorIcon>
        );
      default:
        return (
          <LowIcon>
            <CheckIcon />
          </LowIcon>
        );
    }
  };
  const riskText = () => {
    switch (riskLevel) {
      case RiskLevel.LOW:
        return 'Low Risk';
      case RiskLevel.MEDIUM:
        return 'Medium Risk';
      case RiskLevel.HIGH:
        return 'High Risk';
      default:
        return 'Low Risk';
    }
  };

  return (
    <MainContainer>
      {riskIcon()}
      <Typography sx={{ color: color, marginLeft: '10px' }} variant='h6'>
        {riskText()}
      </Typography>
    </MainContainer>
  );
};

const MainContainer = styled(BaseContainer)`
  display: flex;
  align-items: center;
  margin-top: 12px;
`;
const RiskIcon = styled(Avatar)`
  && {
    width: 30px;
    height: 30px;
  }
`;
const LowIcon = styled(RiskIcon)`
  && {
    background-color: ${props => props.theme.palette.success};
  }
`;
const WarnIcon = styled(RiskIcon)`
  && {
    background-color: ${props => props.theme.palette.warn};
  }
`;
const ErrorIcon = styled(RiskIcon)`
  && {
    background-color: ${props => props.theme.palette.error};
  }
`;

export default RiskDisplay;
