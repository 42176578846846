import RightSideTopbar from 'Common/Slidebar/components/RightSideTopbar';
import AvailableCleaningTable from 'Common/Tables/Properties/AvailableCleaningTable';
import * as React from 'react';
import { MainContentContainer } from 'ui-library/Styled/sidebar';

export interface PropertyListContainerProps {
  roomStatus: string;
  handleClose: () => void;
}

const PropertyListContainer = (props: PropertyListContainerProps) => {
  const { handleClose } = props;

  return (
    <MainContentContainer>
      <RightSideTopbar title='Available for Cleaning' handleClose={handleClose} />
      <AvailableCleaningTable />
    </MainContentContainer>
  );
};

export default PropertyListContainer;
