import { Box, Divider, Paper, SxProps, Theme } from '@mui/material';
import { useMedia } from '@operto/ui';
import * as React from 'react';
import { ReactNode } from 'react';

export type SmartCardType = 'device' | 'lock' | 'detail' | 'member' | 'backup' | 'default';

export interface DeviceWidgetProps {
  title?: string | JSX.Element;
  menu?: JSX.Element;
  children?: React.ReactNode;
  footer?: JSX.Element;
  header?: React.Component;
  open?: boolean;
  removeGutter?: boolean;
  isOnUnitDashboard?: boolean;
  smartCardType?: SmartCardType;
  isCarouselCard?: boolean;
  isMainActivity?: boolean;
  fill?: string;
  mode?: string;
}

const defaultCardFooterStyles: SxProps = {
  padding: '0px 24px 0px 24px',
};

const getDefaultCardBodyContainerStyles = (removeGutter: boolean): SxProps => {
  return {
    padding: removeGutter ? '0px' : '0px 24px 24px 24px',
  };
};

const getSmartCardContainerWidth = (
  isMobile: boolean,
  isOnUnitDashboard: boolean,
  isCarouselCard: boolean,
): string => {
  if (isOnUnitDashboard) {
    if (isMobile) {
      return '300px';
    }
    return '100%';
  }

  if (isMobile && isCarouselCard) {
    return '300px';
  }
  return '100%';
};

const getSmartCardContainerHeight = (
  isMobile: boolean,
  isOnUnitDashboard: boolean,
  isCarouselCard: boolean,
  expanded: boolean,
): string => {
  if (isOnUnitDashboard) {
    if (expanded) {
      return 'inherit';
    }
    if (isMobile) {
      return '224px';
    }

    return '100%';
  }

  if (isMobile && isCarouselCard) {
    return '100%';
  }
  return 'auto';
};

const getDefaultSmartCardContainerStyles = (
  isOnUnitDashboard: boolean,
  isMobile: boolean,
  isCarouselCard: boolean,
  isMainActivity: boolean,
  expanded: boolean,
): SxProps => {
  const smartCardWidth = getSmartCardContainerWidth(isMobile, isOnUnitDashboard, isCarouselCard);
  const smartCardHeight = getSmartCardContainerHeight(
    isMobile,
    isOnUnitDashboard,
    isCarouselCard,
    expanded,
  );
  return {
    width: smartCardWidth,
    height: smartCardHeight,
    marginRight:
      isOnUnitDashboard && isMobile ? '12px' : isMobile && isCarouselCard ? '12px' : '0px',
    marginLeft:
      isOnUnitDashboard && isMobile ? '12px' : isMobile && isCarouselCard ? '12px' : '0px',
    boxShadow: isMobile && isMainActivity ? 'none' : '1',
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'hidden',
  };
};

const getBodyContainerStyleByType = (
  isMobile: boolean,
  isOnUnitDashboard: boolean,
  expanded: boolean,
  smartCardType: SmartCardType,
  defaultCardBodyStyles: SxProps,
): SxProps => {
  const lockStatusCardBodyMobileStyle: SxProps = {
    padding: '8px 16px',
    height: expanded ? '100%' : '132px',
  };

  const backupCodesCardBodyMobileStyles: SxProps = {
    padding: '8px 16px',
    height: expanded ? '100%' : '132px',
  };

  const detailsCardBodyMobileStyles: SxProps = {
    padding: '16px 16px 0px 16px',
    height: expanded ? '100%' : '132px',
  };

  const membersCardBodyMobileStyles: SxProps = {
    padding: '16px 24px',
    height: expanded ? '100%' : '132px',
  };

  const deviceCardBodyMobileStyles: SxProps = {
    padding: '8px 16px 32px 16px',
    height: expanded ? '100%' : '132px',
  };

  const cardSpecficBodyStyles: Record<SmartCardType, SxProps> = {
    default: { ...defaultCardBodyStyles, height: '100%' },
    device: isMobile ? deviceCardBodyMobileStyles : defaultCardBodyStyles,
    lock: isMobile ? lockStatusCardBodyMobileStyle : { padding: '8px 16px' },
    detail: isMobile ? detailsCardBodyMobileStyles : defaultCardBodyStyles,
    member: isMobile ? membersCardBodyMobileStyles : defaultCardBodyStyles,
    backup: isMobile ? backupCodesCardBodyMobileStyles : defaultCardBodyStyles,
  };

  const bodyContainerStyles = isOnUnitDashboard
    ? cardSpecficBodyStyles[smartCardType]
    : defaultCardBodyStyles;

  return bodyContainerStyles;
};

const getFooterContainerStyleByType = (
  isMobile: boolean,
  isOnUnitDashboard: boolean,
  smartCardType: SmartCardType,
): SxProps => {
  const defaultCardFooterMobileStyles: SxProps = {
    padding: '12px 24px',
    height: '48px',
    gap: '10px',
  };

  const cardSpecficFooterStyles: Record<SmartCardType, SxProps> = {
    default: isMobile ? defaultCardFooterMobileStyles : defaultCardFooterStyles,
    device: isMobile ? defaultCardFooterMobileStyles : defaultCardFooterStyles,
    member: isMobile ? defaultCardFooterMobileStyles : defaultCardFooterStyles,
    detail: isMobile ? defaultCardFooterMobileStyles : defaultCardFooterStyles,
    lock: isMobile ? defaultCardFooterMobileStyles : defaultCardFooterStyles,
    backup: isMobile ? defaultCardFooterMobileStyles : defaultCardFooterStyles,
  };

  const footerContainerStyles = isOnUnitDashboard
    ? cardSpecficFooterStyles[smartCardType]
    : defaultCardFooterStyles;

  return footerContainerStyles;
};

const constructWidgetContainerStyles = (
  isMobile: boolean,
  isCarouselCard: boolean,
  isOnUnitDashboard: boolean,
  isMainActivity: boolean,
  expanded: boolean,
  removeGutter: boolean,
  smartCardType: SmartCardType,
  fill: string,
  mode: string,
  menu: ReactNode,
) => {
  const defaultCardBodyStyles = getDefaultCardBodyContainerStyles(removeGutter);

  const bodyContainerStyles = getBodyContainerStyleByType(
    isMobile,
    isOnUnitDashboard,
    expanded,
    smartCardType,
    defaultCardBodyStyles,
  );
  const footerContainerStyles = getFooterContainerStyleByType(
    isMobile,
    isOnUnitDashboard,
    smartCardType,
  );

  const headerContainerStyles: SxProps = {
    height: isMobile ? '44px' : 'auto',
    padding: Boolean(menu) ? '14px 10px 14px 18px' : '18px 18px 14px 18px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const widgetContainerStyles: SxProps<Theme> = {
    color: fill && mode !== 'light' ? 'text.white' : 'text.primary',
    backgroundColor: fill || 'background.paper',
  };

  const smartCardContainerStyles = getDefaultSmartCardContainerStyles(
    isOnUnitDashboard,
    isMobile,
    isCarouselCard,
    isMainActivity,
    expanded,
  );

  return {
    headerContainerStyles,
    widgetContainerStyles,
    bodyContainerStyles,
    footerContainerStyles,
    smartCardContainerStyles,
  };
};

const Widget = ({
  title,
  children,
  menu,
  footer,
  removeGutter,
  open: expanded = false,
  isOnUnitDashboard = false,
  smartCardType = 'default',
  isCarouselCard,
  isMainActivity,
  header,
  fill,
  mode,
}: DeviceWidgetProps) => {
  const { isMobile } = useMedia();

  const {
    smartCardContainerStyles,
    bodyContainerStyles,
    footerContainerStyles,
    headerContainerStyles,
    widgetContainerStyles,
  } = constructWidgetContainerStyles(
    isMobile,
    isCarouselCard,
    isOnUnitDashboard,
    isMainActivity,
    expanded,
    removeGutter,
    smartCardType,
    fill,
    mode,
    menu,
  );

  return (
    <Paper sx={smartCardContainerStyles}>
      <Box>
        <Box sx={widgetContainerStyles}>
          {(!isMobile || isMainActivity || isOnUnitDashboard) && (
            <>
              {title && !header && (
                <Box sx={headerContainerStyles}>
                  <div>{title}</div>
                  {menu}
                </Box>
              )}
              {header}
              <Divider />
            </>
          )}
          <Box sx={bodyContainerStyles}>{children}</Box>
          {footer && (
            <>
              <Divider />
              <Box sx={footerContainerStyles}>{footer}</Box>
            </>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default Widget;
