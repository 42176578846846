import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { Actions } from '../../types/actions';
import { DeviceDict, DeviceState, HistoricalDict, PropertyDeviceDict } from '../deviceType';

const initialListState: number[] = [];

const list: Reducer<number[]> = (state = initialListState, action) => {
  switch (action.type) {
    case Actions.upsertDevice:
      return Array.from(new Set([...state, action.device.id]));
    case Actions.hydrateDevices:
      return Array.from(new Set([...action.deviceList]));
    case Actions.deleteDevice:
      const returnState = state.filter((each: number) => each !== action.id);
      return returnState;
    default:
      return state;
  }
};

const initialDeviceState: DeviceDict = {};

const byId: Reducer<DeviceDict> = (state = initialDeviceState, action) => {
  switch (action.type) {
    case Actions.hydrateDevices:
      return action.deviceDict;
    case Actions.upsertDevice:
      return {
        ...state,
        [action.device.id]: action.device,
      };
    case Actions.updateLockStatus:
      return {
        ...state,
        [action.device_id]: {
          ...state[action.device_id],
          data: {
            ...state[action.device_id].data,
            is_locked: action.status.is_locked,
            is_online: action.status.is_online,
            battery: action.status.battery,
          },
        },
      };
    case Actions.deleteDevice:
      delete state[action.id];
      return state;
    default:
      return state;
  }
};

const initialPropertyDeviceState: PropertyDeviceDict = {};

const listByPropertyId: Reducer<PropertyDeviceDict> = (
  state = initialPropertyDeviceState,
  action,
) => {
  switch (action.type) {
    case Actions.hydrateDevices:
      return action.propertyDeviceDict;
    default:
      return state;
  }
};

const historical: Reducer<HistoricalDict> = (state = {}, action) => {
  switch (action.type) {
    case Actions.insertHistorical:
      return {
        ...state,
        [action.deviceId]: action.data,
      };
    default:
      return state;
  }
};

const reducer: Reducer<DeviceState> = combineReducers<DeviceState>({
  byId,
  list,
  listByPropertyId,
  historical,
});

export default reducer;
