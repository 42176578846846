// @ts-nocheck
import ArrowBackIosOutlined from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlined from '@mui/icons-material/ArrowForwardIosOutlined';
import { IconButton, Stack, Tab, Tabs } from '@mui/material';
import { Tag } from '@operto/tags-shared';
import { trpc } from '@operto/trpc-client';
import { FormContainer } from 'Common/FormContainer';
import { SlidebarWidth } from 'Common/Slidebar/RightSlidebar';
import NotificationsContainer from 'Pages/AddPeople/TabContainers/NotificationsContainer';
import ReviewMemberContainer from 'Pages/AddPeople/TabContainers/ReviewMemberContainer';
import MemberDetailsForm, {
  MemberDetailsFormHandle,
  NEW_MEMBER_ID_PLACEHOLDER,
} from 'Pages/People/MemberDetailsForm';
import { logger } from 'lib/logger';
import { getGroupsPaginated } from 'location/state/LocationsActions';
import { IMember, MemberSiteAccess } from 'member/memberType';
import { createMember } from 'member/state/memberActions';
import { getProperties } from 'property/state/propertyActions';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { toggleSlidebar, toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SlidebarType, SnackbarTypes, SnackbarVariant } from 'types/ui';
import { formFooterStyles } from 'ui-library/Styled/containers';
import { GroupsContainer } from './TabContainers/GroupsContainer';
import PermissionsContainer from './TabContainers/PermissionsContainer';
import TabLabel from './TabLabel';

export interface AddPeopleProps {
  handleClose: () => void;
  handleSliderWidth: (width: typeof SlidebarWidth) => void;
}

const TAB_ITEMS = {
  GENERAL: 0,
  NOTIFICATIONS: 1,
  PERMISSIONS: 2,
  GROUPS: 3,
  REVIEW: 4,
};

const AddPeople = ({ handleClose, handleSliderWidth }: AddPeopleProps) => {
  const dispatch = useAppDispatch();
  const memberDetailsRef = useRef<MemberDetailsFormHandle>();
  const [tabIdx, setTabIdx] = useState<number>(0);
  const [saving, setSaving] = useState(false);
  const enabledSubmit = tabIdx === TAB_ITEMS.REVIEW;

  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [updatedTags, setUpdatedTags] = useState<Tag[]>([]);
  const [memberData, setMemberData] = React.useState<IMember>({
    name: '',
    job_title: '',
    contact_number: '',
    email: '',
    role: 'basic-user',
    status: 'active',
    is_sms_notification: false,
    is_email_notification: false,
    member_type: 'cleaner',
    staff_type: 'employee',
    notification_settings: {
      'check-in': false,
      'check-out': false,
      'clean-start': false,
      'clean-complete': false,
      'device-issue': false,
      'environment-issue': false,
      'compliance-issue': false,
      'maintenance-complete': false,
      'maintenance-start': false,
      'access-code-failed-install': false,
    },
    teams: [],
    units: [],
    groups: [],
    group_ids: [],
    team_ids: [],
    code: Math.floor(Math.random() * 90000) + 10000,
    profile_picture_url: '',
    blackout_times: {},
    site_access_id: [MemberSiteAccess.PORTAL],
    is_dashboard_access: false,
  });

  const utils = trpc.useUtils();
  const updateTagsMutation = trpc.tags.updateTags.useMutation({
    onSuccess: () => {
      utils.refetch();
    },
  });

  // replace NEW_MEMBER_ID_PLACEHOLDER with the new member id after creation
  const updateMemberIdInTags = (tags: Tag[], memberId: number) => {
    return tags.map(tag => ({
      ...tag,
      memberIds: tag.memberIds.map((id: number) =>
        id === NEW_MEMBER_ID_PLACEHOLDER ? memberId : id,
      ),
    }));
  };

  const handleWidth = (tabIdx: number) => {
    if (tabIdx === TAB_ITEMS.GROUPS) {
      handleSliderWidth(SlidebarWidth.EIGHTY);
    } else {
      handleSliderWidth(SlidebarWidth.SIXTY);
    }
  };

  const onNextClick = () => {
    if (tabIdx === TAB_ITEMS.GENERAL) {
      memberDetailsRef.current?.submit();
      if (!memberDetailsRef.current?.isValid) {
        return;
      }
      setSelectedTags(memberDetailsRef.current?.selectedTags || []);
      setUpdatedTags(memberDetailsRef.current?.updatedTags || []);
    }
    setTabIdx(tabIdx + 1);
    handleWidth(tabIdx + 1);
  };

  const onDataChange = (field: string, value: ReactNode) => {
    setMemberData(prev => ({ ...prev, [field]: value }));
  };

  const onDataSave = async () => {
    let success = true;
    setSaving(true);

    try {
      const result = await dispatch(createMember(memberData));
      const memberId = result.id;
      const tagsToUpdate = updateMemberIdInTags(updatedTags, memberId);
      await updateTagsMutation.mutateAsync(tagsToUpdate);
    } catch (error) {
      success = false;
      logger.error(`Error adding new member: ${error}`);
    }

    setSaving(false);
    dispatch(toggleSlidebar(SlidebarType.CLOSE, ''));
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `Adding a new member was ${success ? 'successful' : 'not successful'}`,
        variant: success ? SnackbarVariant.SUCCESS : SnackbarVariant.ERROR,
      }),
    );
  };

  useEffect(() => {
    dispatch(getProperties());
    dispatch(getGroupsPaginated());
  }, [dispatch]);

  const renderHeader = () => {
    return (
      <Tabs
        value={tabIdx}
        onChange={(_, value) => {
          setTabIdx(value);
          handleWidth(value);
        }}
      >
        <Tab label={TabLabel(1, 'General Details', tabIdx === TAB_ITEMS.GENERAL, tabIdx)} />
        <Tab
          label={TabLabel(2, 'Notifications', tabIdx === TAB_ITEMS.NOTIFICATIONS, tabIdx)}
          disabled={tabIdx < TAB_ITEMS.NOTIFICATIONS}
        />
        <Tab
          label={TabLabel(3, 'Permissions', tabIdx === TAB_ITEMS.PERMISSIONS, tabIdx)}
          disabled={tabIdx < TAB_ITEMS.PERMISSIONS}
        />
        <Tab
          label={TabLabel(4, 'Groups', tabIdx === TAB_ITEMS.GROUPS, tabIdx)}
          disabled={tabIdx < TAB_ITEMS.GROUPS}
        />
        <Tab
          label={TabLabel(5, 'Finish', tabIdx === TAB_ITEMS.REVIEW, tabIdx)}
          disabled={tabIdx < TAB_ITEMS.REVIEW}
        />
      </Tabs>
    );
  };

  const renderFooter = () => {
    return (
      <Stack direction='row' alignItems='center' sx={formFooterStyles}>
        <IconButton
          color='primary'
          aria-label='previous-button'
          onClick={() => {
            setTabIdx(tabIdx - 1);
          }}
          disabled={tabIdx === TAB_ITEMS.GENERAL}
        >
          <ArrowBackIosOutlined />
        </IconButton>
        <IconButton
          color='primary'
          aria-label='next-button'
          onClick={() => {
            onNextClick();
          }}
          disabled={tabIdx === TAB_ITEMS.REVIEW}
          data-testid='next-button'
        >
          <ArrowForwardIosOutlined />
        </IconButton>
      </Stack>
    );
  };

  return (
    <FormContainer
      title='Add new member'
      renderHeader={renderHeader}
      renderFooter={renderFooter}
      onClose={handleClose}
      dirty={enabledSubmit}
      onSubmit={onDataSave}
      loading={saving}
    >
      {tabIdx === TAB_ITEMS.GENERAL && (
        <MemberDetailsForm
          isNewMember
          ref={memberDetailsRef}
          member={memberData}
          onSubmit={member => {
            setMemberData(member);
            setTabIdx(tabIdx + 1);
          }}
          memberTags={selectedTags}
        />
      )}
      {tabIdx === TAB_ITEMS.NOTIFICATIONS && (
        <NotificationsContainer data={memberData} onChange={onDataChange} />
      )}
      {tabIdx === TAB_ITEMS.PERMISSIONS && (
        <PermissionsContainer data={memberData} onChange={onDataChange} />
      )}
      {tabIdx === TAB_ITEMS.GROUPS && (
        <GroupsContainer member={memberData} onChange={onDataChange} />
      )}
      {tabIdx === TAB_ITEMS.REVIEW && <ReviewMemberContainer data={memberData} />}
    </FormContainer>
  );
};

export default AddPeople;
