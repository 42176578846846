import * as React from 'react';

import { Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';

export interface IconLabelProps {
  icon: React.ReactElement;
  label: string;

  iconColor?: string;
}

const IconLabel = ({ icon, label, iconColor }: IconLabelProps) => (
  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
    <Avatar sx={{ backgroundColor: iconColor, width: '30px', height: '30px' }}>{icon}</Avatar>
    <Typography variant='h3Light' sx={{ marginLeft: '10px', color: 'white' }}>
      {label}
    </Typography>
  </Box>
);

export default IconLabel;
