import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Menu, MenuItem, Stack, SxProps } from '@mui/material';
import CommonDoorAccessTable from 'code/CommonDoorAccessTable';
import { IntercomAccessCodeTable } from 'code/IntercomAccessCodeTable';
import { getPropertyIntercom } from 'device/state/intercomActions';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';

const associateEntryType = ['building-intercom', 'common-doors'] as const;
type AssociateEntryParam = (typeof associateEntryType)[number];

const getTitleFromAssociateEntryTypeParam = (tab: AssociateEntryParam) => {
  if (tab === 'common-doors') {
    return 'Common Doors';
  }

  return 'Building Intercom';
};

const toolbarStyles: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '104px',
};

const AssociateEntriesContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { propertyId: propertyIdStr, type } = useParams();
  const propertyId = parseInt(propertyIdStr);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchIntercom = async () => {
      setLoading(true);
      await dispatch(getPropertyIntercom(propertyId));
      setLoading(false);
    };
    fetchIntercom();
  }, [propertyId, dispatch]);

  const menu = (
    <Stack sx={toolbarStyles}>
      <Button
        color='primary'
        variant='contained'
        onClick={e => setAnchorEl(e.currentTarget)}
        endIcon={<ArrowDropDownIcon />}
        size={isMobile ? 'small' : 'medium'}
      >
        {getTitleFromAssociateEntryTypeParam(type as AssociateEntryParam)}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => handleTab(associateEntryType.indexOf('building-intercom'))}>
          {getTitleFromAssociateEntryTypeParam('building-intercom')}
        </MenuItem>
        <MenuItem onClick={() => handleTab(associateEntryType.indexOf('common-doors'))}>
          {getTitleFromAssociateEntryTypeParam('common-doors')}
        </MenuItem>
      </Menu>
    </Stack>
  );

  const handleTab = (tabIndex: number) => {
    setAnchorEl(null);
    navigate(`/units/${propertyId}/access/associate-entries/${associateEntryType[tabIndex]}`);
  };

  const renderTableFromType = () => {
    switch (type) {
      case 'common-doors':
        return <CommonDoorAccessTable propertyId={propertyId} loading={loading} />;
      default:
        return <IntercomAccessCodeTable propertyId={propertyId} loading={loading} />;
    }
  };

  return (
    <>
      {menu}
      {renderTableFromType()}
    </>
  );
};

export default AssociateEntriesContainer;
