import MembersTable from 'Common/Tables/People/MembersTable';
import { MemberFilterType } from 'member/memberType';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toggleSlidebar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SlidebarType } from 'types/ui';
import TabBar from 'ui-library/Components/tabBar/TabBar';

export const tabItems = [
  {
    label: 'All Members',
    value: MemberFilterType.ALL_MEMBERS,
    isDropDown: false,
  },
  { label: 'Staff', value: MemberFilterType.STAFF, isDropDown: false },
  {
    label: 'Contractor',
    value: MemberFilterType.CONTRACTOR,
    isDropDown: false,
  },
  {
    label: 'Inactive',
    value: MemberFilterType.INACTIVE,
    isDropDown: false,
  },
];

const Members = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [filterType, setFilterType] = useState(MemberFilterType.ALL_MEMBERS);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const handleFilterIndexChange = (index: number) => {
    setTabIndex(index);
    setFilterType(tabItems[index].value);
  };

  useEffect(() => {
    const path = location.pathname.split('/').slice(-1)[0];
    if (path === 'add') {
      dispatch(toggleSlidebar(SlidebarType.ADD_NEW_MEMBERS, ''));
    }
  }, [dispatch, location]);

  return (
    <>
      <TabBar tabItems={tabItems} tabIndex={tabIndex} onTabChange={handleFilterIndexChange} />
      <MembersTable title={tabItems[tabIndex].label} filterType={filterType} />
    </>
  );
};

export default Members;
