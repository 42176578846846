import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from 'redux/reducers';
import { ThresholdState } from 'threshold/thresholdType';

const getThresholds = (state: ApplicationState) => state.thresholds;

export const getThresholdsByPropertyId = (propertyId: number) =>
  createSelector(
    [getThresholds],
    (thresholds: ThresholdState) => thresholds && thresholds.thresholdsByPropertyId[propertyId],
  );
export const getThresholdsByDeviceId = (deviceId: number) =>
  createSelector(
    [getThresholds],
    (thresholds: ThresholdState) => thresholds && thresholds.thresholdsByDeviceId[deviceId],
  );
