import * as React from 'react';

import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { IMember } from 'member/memberType';

export interface ActionDropDownMenuProps {
  onEdit: (member: IMember) => void;
  onDisable: (id: number) => void;
  onEnable: (id: number) => void;
  selectedRow: IMember;
  showEdit?: boolean;
  showdDuplicate?: boolean;
  showDisable?: boolean;
  showArchive?: boolean;
  showDelete?: boolean;
  disabled?: boolean;
}

const ActionDropDownMenu = ({
  onEdit,
  onDisable,
  onEnable,
  selectedRow,
  showEdit,
  showdDuplicate,
  showDisable,
  showArchive,
  showDelete,
  disabled,
}: ActionDropDownMenuProps) => {
  const [actionsMenu, setActionsMenu] = React.useState<HTMLButtonElement | null>(null);

  const handleEdit = () => {
    setActionsMenu(null);
    onEdit(selectedRow);
  };

  const disableMember = () => {
    setActionsMenu(null);
    onDisable(selectedRow.id as number);
  };

  const enableMember = () => {
    setActionsMenu(null);
    onEnable(selectedRow.id as number);
  };
  return (
    <>
      <IconButton onClick={e => setActionsMenu(e.currentTarget)} size='large' disabled={disabled}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={actionsMenu}
        open={Boolean(actionsMenu)}
        onClose={() => setActionsMenu(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {showEdit && (
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        {showdDuplicate && (
          <MenuItem>
            <ListItemIcon>
              <FileCopyOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Duplicate</ListItemText>
          </MenuItem>
        )}
        <Divider />
        {showDisable ? (
          <MenuItem onClick={disableMember}>
            <ListItemIcon>
              <ArchiveIcon />
            </ListItemIcon>
            <ListItemText>Disable</ListItemText>
          </MenuItem>
        ) : (
          <MenuItem onClick={enableMember}>
            <ListItemIcon>
              <ArchiveIcon />
            </ListItemIcon>
            <ListItemText>Enable</ListItemText>
          </MenuItem>
        )}
        {showArchive && (
          <MenuItem>
            <ListItemIcon>
              <ArchiveIcon />
            </ListItemIcon>
            <ListItemText>Archive</ListItemText>
          </MenuItem>
        )}
        {showDelete && (
          <MenuItem onClick={() => setActionsMenu(null)}>
            <ListItemIcon>
              <DeleteOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ActionDropDownMenu;
