import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import { BaseContainer } from 'Style/Components/BaseComponents';
import { BatteryStatus } from 'device/deviceType';
import * as React from 'react';
import { RowCellTableTooltip } from '../table/TableTooltip';

export interface BatteryIconProps {
  status: BatteryStatus;
  disabled?: boolean;
  customColor?: string;
}

const batteryTooltipTexts: Record<BatteryStatus, string> = {
  [BatteryStatus.NOT_INSTALLED]: 'Not Installed',
  [BatteryStatus.CRITICAL]: 'Critical',
  [BatteryStatus.EMPTY]: 'Empty',
  [BatteryStatus.LOW]: 'Low',
  [BatteryStatus.MEDIUM]: 'Medium',
  [BatteryStatus.GOOD]: 'Good',
};

const BatteryIcon = ({ status, disabled = false, customColor }: BatteryIconProps) => {
  let batteryIcon = <BatteryAlertIcon />;
  let color = '';
  if (status === BatteryStatus.NOT_INSTALLED) {
    return <div />;
  }

  if (!disabled) {
    switch (status) {
      case BatteryStatus.MEDIUM:
        batteryIcon = <Battery5BarIcon />;
        color = 'text.primary';
        break;
      case BatteryStatus.LOW:
        batteryIcon = <Battery4BarIcon />;
        color = 'warning.main';
        break;
      case BatteryStatus.CRITICAL:
        batteryIcon = <Battery2BarIcon />;
        color = 'error.main';
        break;
      case BatteryStatus.EMPTY:
        batteryIcon = <BatteryAlertIcon />;
        color = 'error.main';
        break;
      default:
        batteryIcon = <Battery6BarIcon />;
        color = 'text.primary';
    }

    if (customColor) {
      color = customColor;
    }
  } else {
    color = 'text.disabled';
  }

  const toolTipText = batteryTooltipTexts[status];

  return (
    <BaseContainer style={{ display: 'flex', alignItems: 'center', color: color }}>
      {disabled ? (
        batteryIcon
      ) : (
        <RowCellTableTooltip title={toolTipText}>{batteryIcon}</RowCellTableTooltip>
      )}
    </BaseContainer>
  );
};

export default BatteryIcon;
