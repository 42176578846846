// @ts-nocheck
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SecurityDepositsResponse, SecurityDepositState } from './models';
import { parseSecurityDeposit, parseProductVariants } from './parser';
import { OpertoLogger } from 'Logger/logger';

export const getSecutiryDeposits = createAsyncThunk(
  'getSecutiryDeposits',
  async (
    data: {
      numPerPage: number;
      pageNum: number;
      reset?: boolean;
    },
    thunkAPI,
  ) => {
    try {
      const params = {
        numPerPage: data.numPerPage,
        pageNum: data.pageNum,
      };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/companies/products`, {
        params,
      });
      const { status, message } = response.data as SecurityDepositsResponse;
      if (status === 'success') {
        response.data.shouldReset = data?.reset;
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      OpertoLogger.Log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const patchSecurityDeposits = createAsyncThunk(
  'patchSecurityDeposits',
  async (
    data: {
      propertyIds: number[];
      price: number;
      productId: number;
    },
    thunkAPI,
  ) => {
    try {
      const params = {
        property_ids: data.propertyIds,
        price: data.price,
        product_id: data.productId,
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/companies/products`,
        params,
      );
      const { status, message } = response.data as SecurityDepositsResponse;
      if (status === 'success') {
        return response.data;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      OpertoLogger.Log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const addThunkCases = (builder: ActionReducerMapBuilder<SecurityDepositState>) => {
  builder.addCase(getSecutiryDeposits.fulfilled, (state, action) => {
    state.status = 'succeeded';
    if (action.payload?.shouldReset) {
      state.securityDeposit.data = [];
    }
    const newSecurityDeposits = parseSecurityDeposit(action.payload.data);

    const newCombined = Array.from(
      new Set([...state.securityDeposit.data, ...newSecurityDeposits.data]),
    );
    state.securityDeposit.data = newCombined;
    state.securityDeposit.meta = newSecurityDeposits.meta;
  });
  builder.addCase(getSecutiryDeposits.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getSecutiryDeposits.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(patchSecurityDeposits.fulfilled, (state, action) => {
    state.status = 'succeeded';
    const newSecurityDeposits = parseProductVariants(
      action.payload.data.records.product_variants.product_variants,
    );

    const combined = [...state.securityDeposit.data, ...newSecurityDeposits];

    // Update only data with new changes and avoid duplication
    const newCombined = Array.from(
      new Map(combined.map(combinedItem => [combinedItem.id, combinedItem])).values(),
    );
    state.securityDeposit.data = newCombined;
    state.message = action.payload.message;
  });
  builder.addCase(patchSecurityDeposits.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(patchSecurityDeposits.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
};
