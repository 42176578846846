import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { WorkflowDataType } from '@operto/communications-shared';
import React from 'react';

interface CommunicationsTemplateDeleteConfirmDialogWorkflowDetailsProps {
  workflows: WorkflowDataType[];
  topText: string;
  bottomText: string;
}

const CommunicationsTemplateDeleteConfirmDialogWorkflowDetails = ({
  workflows,
  topText,
  bottomText,
}: CommunicationsTemplateDeleteConfirmDialogWorkflowDetailsProps) => {
  return (
    <>
      <Typography sx={{ mt: 1, mb: 1 }}>{topText}</Typography>

      <List sx={{ listStyleType: 'disc' }}>
        {workflows.map(w => (
          <ListItem key={w.id} sx={{ padding: '0', margin: 0, pl: 3 }}>
            <ListItemText primary={w.name} sx={{ display: 'list-item', padding: 0, margin: 0 }} />
          </ListItem>
        ))}
      </List>

      <Typography sx={{ mt: 1 }}>{bottomText}</Typography>
    </>
  );
};

export default CommunicationsTemplateDeleteConfirmDialogWorkflowDetails;
