import { Widget } from '@typeform/embed-react';
import { getCurrentCompany } from 'company/state/companySelectors';
import { IMember } from 'member/memberType';
import { getSelf } from 'member/state/memberSelectors';
import * as React from 'react';
import { useAppSelector } from 'redux/hooks';

const ExpansionPage = () => {
  const company = useAppSelector(getCurrentCompany());
  const loggedInMember = useAppSelector(getSelf()) as IMember;

  return (
    <Widget
      id='Smo0LSEE'
      style={{ width: '100%', height: '90vh' }}
      className='my-form'
      hidden={{
        member_id: loggedInMember?.id.toString(),
        company_id: loggedInMember?.company_id.toString(),
        email: loggedInMember?.email,
        customer_name: loggedInMember?.name,
        company_name: company?.name,
      }}
    />
  );
};

export default ExpansionPage;
