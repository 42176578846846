import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, SxProps, Theme, Toolbar } from '@mui/material';
import { Text } from '@operto/ui';
import React, { ReactNode, useState } from 'react';
import ActionSnackbar from './Snackbar/ActionSnackbar';

type FormContainerHeaderProps = {
  title: string;
  submitButtonTitle?: string;
  loading?: boolean;
  dirty?: boolean;
  onClose: () => void;
  onSubmit?: () => void;
};

export const FormContainerHeader = ({
  title,
  submitButtonTitle = 'Save',
  loading = false,
  dirty = false,
  onClose,
  onSubmit,
}: FormContainerProps) => {
  const [confirmClose, setConfirmClose] = useState(false);

  const handleClose = () => {
    setConfirmClose(dirty);
    if (!dirty) {
      onClose?.();
    }
  };

  return (
    <>
      <Toolbar sx={toolbarStyles}>
        <IconButton size='large' edge='start' aria-label='close' onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Text variant='h3-700' style={titleStyles}>
          {title}
        </Text>
        {onSubmit && (
          <LoadingButton
            disabled={!dirty}
            loading={loading}
            onClick={onSubmit}
            variant='contained'
            color='primary'
            data-testid='form-container-submit-button'
            startIcon={<SaveIcon />}
          >
            {submitButtonTitle}
          </LoadingButton>
        )}
      </Toolbar>

      <ActionSnackbar
        onClose={onClose}
        open={confirmClose}
        setOpen={setConfirmClose}
        severity='error'
        actionButtonText='YES'
        snackbarMessage='You have unsaved changes that will be lost if you exit. Are you sure you want to exit?'
      />
    </>
  );
};

type FormContainerProps = FormContainerHeaderProps & {
  children?: ReactNode;
  renderHeader?: () => ReactNode; // content header
  renderFooter?: () => ReactNode;
};

export const FormContainer = ({
  children,
  renderHeader,
  renderFooter,
  ...rest
}: FormContainerProps) => {
  return (
    <>
      <FormContainerHeader {...rest} />
      {renderHeader?.()}
      <Box
        flex={1}
        p={3}
        overflow={'scroll'}
        bgcolor='background.default01'
        data-testid='form-container-content'
      >
        {children}
      </Box>
      {renderFooter?.()}
    </>
  );
};

const toolbarStyles: SxProps<Theme> = {
  position: 'sticky',
  top: '0px',
  zIndex: 2, // just enough to be on top of the content
  backgroundColor: 'background.paper',
};

const titleStyles: SxProps<Theme> = {
  mx: 1.5,
  flexGrow: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
};
