import LockIcon from '@mui/icons-material/LockOutlined';
import Chip from '@mui/material/Chip';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as React from 'react';

export interface LockOfflineProps {
  showAsChip?: boolean;
}

const LockOfflineIcon = ({ showAsChip = false }: LockOfflineProps) => {
  const theme = createTheme({
    components: {
      // Style sheet name ⚛️
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: 'error',
            color: 'text.white',
            '.MuiChip-icon': {
              color: 'text.white',
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {showAsChip ? (
        <Chip size='small' icon={<LockIcon />} label='Offline' color='error' />
      ) : (
        <LockIcon sx={{ color: 'error.main' }} />
      )}
    </ThemeProvider>
  );
};

export default LockOfflineIcon;
