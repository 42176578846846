import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import { Box, Button, CircularProgress } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { Text } from '@operto/ui';
import { companySelector } from 'company/state/companySelectors';
import { formatRelativeToTimeZone } from 'helper/date';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import { LockFilterType } from 'lock/lockType';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import {
  DeviceLockViewModel,
  UnitViewModel,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdGroupsQuery,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdLastSyncRequestedAtQuery,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdLocksQuery,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdUnitsQuery,
  usePostAccessCompatibilityAssignLockMutation,
  usePostAccessCompatibilityLegacyCompanyIdSyncLocksMutation,
  usePostAccessCompatibilityUnassignLocksMutation,
} from 'services/novaApi';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import { TableCell } from 'ui-library/Components/table/TableCell';
import LockAssignPropertyDropdown from './LockAssignPropertyDropdown';
import { LocksTitleBar } from './LocksTitleBar';
import { UnassignUnitDialog } from './UnassignUnitDialog';

export interface LocksTableProps {
  filterType: LockFilterType;
  menu?: React.ReactNode;
}

interface PagedResult<T> {
  data: T[];
  skip: number;
  take: number;
  total_count: number;
}

const INITIAL_LOCKS_STATE: PagedResult<DeviceLockViewModel> = {
  data: [],
  skip: 0,
  take: 100,
  total_count: 0,
};

const INITIAL_UNITS_STATE: PagedResult<UnitViewModel> = {
  data: [],
  skip: 0,
  take: 10,
  total_count: 0,
};

const LocksTable = ({ menu }: LocksTableProps) => {
  const company = useAppSelector(companySelector());
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const companyId = company?.id;

  // Pagination state for locks
  const [locksPagination, setLocksPagination] = useState({
    pageNum: INITIAL_LOCKS_STATE.skip,
    numPerPage: INITIAL_LOCKS_STATE.take,
    keyword: '',
  });

  // Pagination state for units
  const [unitsPagination, setUnitsPagination] = useState({
    pageNum: INITIAL_UNITS_STATE.skip,
    numPerPage: INITIAL_UNITS_STATE.take,
    keyword: '',
  });

  const [unitsData, setUnitsData] = useState<UnitViewModel[]>([]); // Store all loaded units
  const [locksData, setLocksData] = useState<DeviceLockViewModel[]>([]); // Store locks data locally

  const { t } = useTranslation();
  const { snackbar } = useSnackbar();

  const [assignLock] = usePostAccessCompatibilityAssignLockMutation();
  const [unassignLocks] = usePostAccessCompatibilityUnassignLocksMutation();

  const [assignLockTimeout, setAssignLockTimeout] = useState<NodeJS.Timeout | null>(null);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [locksBeingUnassigned, setLocksBeingUnassigned] = useState<number[]>([]);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedGroups, setSelectedGroups] = useState<number[]>([]); // Track selected group IDs

  // Fetching locks
  const {
    data: locks = INITIAL_LOCKS_STATE,
    isLoading: isLocksLoading,
    isFetching: isLocksFetching,
    refetch,
  } = useGetAccessCompatibilityOnboardingByLegacyCompanyIdLocksQuery({
    legacyCompanyId: companyId,
    skip: locksPagination.pageNum,
    take: locksPagination.numPerPage,
    keyword: locksPagination.keyword,
  });

  // Fetching units with pagination
  const {
    data: units = INITIAL_UNITS_STATE,
    isLoading: isUnitsLoading,
    isFetching: isUnitsFetching,
    refetch: refetchUnits,
  } = useGetAccessCompatibilityOnboardingByLegacyCompanyIdUnitsQuery({
    legacyCompanyId: companyId,
    skip: unitsPagination.pageNum,
    take: unitsPagination.numPerPage,
    keyword: unitsPagination.keyword,
    includeInGroups: selectedGroups.length ? selectedGroups : undefined,
  });

  const { data: groupsData } = useGetAccessCompatibilityOnboardingByLegacyCompanyIdGroupsQuery({
    legacyCompanyId: companyId,
  });

  const [doSync] = usePostAccessCompatibilityLegacyCompanyIdSyncLocksMutation();

  const { data, refetch: refetchLastSyncedAt } =
    useGetAccessCompatibilityOnboardingByLegacyCompanyIdLastSyncRequestedAtQuery({
      legacyCompanyId: companyId,
    });

  const [lastSyncedAt, setLastSyncedAt] =
    useState<string | null>(data?.LastSyncRequestedAt) ?? null;

  const handleSync = async () => {
    try {
      await doSync({ legacyCompanyId: companyId });
      await refetchLastSyncedAt();
      await refetch();
      snackbar(t('sync_success_snackbar_text'));
    } catch (error) {
      snackbar(t('sync_error_snackbar_text'));
    }
  };

  useEffect(() => {
    if (data?.LastSyncRequestedAt) {
      setLastSyncedAt(data.LastSyncRequestedAt);
    }
  }, [data, setLastSyncedAt]);

  useEffect(() => {
    refetchUnits();
  }, [selectedGroups, refetchUnits]);

  const handleLocksFetch = useCallback(
    (pageNum: number, numPerPage: number, searchValue?: string) => {
      setLocksPagination({ pageNum, numPerPage, keyword: searchValue || '' });
    },
    [],
  );

  const handleGroupSelect = (groupId: number) => {
    setSelectedGroups(
      prevGroups =>
        prevGroups.includes(groupId)
          ? prevGroups.filter(id => id !== groupId) // Remove group if it's already selected
          : [...prevGroups, groupId], // Add group if it's not selected
    );
  };

  // Infinite scroll: Load more units when user scrolls to bottom
  const loadMoreUnits = () => {
    if (!isUnitsLoading || !isUnitsFetching) {
      if (unitsData.length >= units.total_count || units.skip > units.total_count) {
        return;
      }

      setUnitsPagination(prev => ({
        ...prev,
        pageNum: prev.pageNum + prev.numPerPage,
      }));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setUnitsPagination(prev => ({
        ...prev,
        pageNum: INITIAL_UNITS_STATE.skip,
        keyword: value,
      }));
    }, 300),
    [],
  );

  const handleSearchChange = (value: string) => {
    setSearchKeyword(value);

    if (!value) {
      // Reset unitsData when search is cleared
      setUnitsData([]);
    }

    debouncedSearch(value);
  };

  useEffect(() => {
    if (units.data) {
      if (!unitsPagination.keyword) {
        // When not searching, append new units to existing ones
        setUnitsData(prevUnits => {
          const newUnitIds = new Set(units.data.map(unit => unit.unit_id));
          const filteredPrevUnits = prevUnits.filter(unit => !newUnitIds.has(unit.unit_id));
          return [...filteredPrevUnits, ...units.data];
        });
      } else {
        // When searching, replace all units with the search results
        setUnitsData(units.data);
      }
    }
  }, [units.data, unitsPagination.keyword]);

  useEffect(() => {
    if (locks.data) {
      setLocksData(locks.data);
    }
  }, [locks]);

  const handleAssignLock = (lockId: number, unitId: number, unitName: string) => {
    // Update the UI immediately
    setLocksData(prevLocks =>
      prevLocks.map(lock =>
        lock.legacy_lock_id === lockId ? { ...lock, unit_id: unitId, unit_name: unitName } : lock,
      ),
    );
    snackbar(`${t('unit_assigned_to')} ${unitName}`, undoAction(lockId, unitId), 10000);

    // Disable the assigned unit in the units list
    setUnitsData(prevUnits =>
      prevUnits.map(unit => (unit.unit_id === unitId ? { ...unit, is_lock_assigned: true } : unit)),
    );

    // Clear the existing timeout if any
    if (assignLockTimeout) {
      clearTimeout(assignLockTimeout);
    }

    // Set a new timeout to delay the API call by 10 seconds
    const timeoutId = setTimeout(async () => {
      try {
        await assignLock({
          assignLock: {
            legacyLockId: lockId,
            legacyPropertyId: unitId,
          },
        }).unwrap();
      } catch (error) {
        logger.error(error);
        setLocksData(prevLocks =>
          prevLocks.map(lock =>
            lock.legacy_lock_id === lockId ? { ...lock, unit_id: null, unit_name: null } : lock,
          ),
        );
        setUnitsData(prevUnits =>
          prevUnits.map(unit =>
            unit.unit_id === unitId ? { ...unit, is_lock_assigned: false } : unit,
          ),
        );
      }
    }, 10000);

    setAssignLockTimeout(timeoutId);
  };

  const handleUndo = (lockId: number, unitId: number) => {
    setLocksData(prevLocks =>
      prevLocks.map(lock =>
        lock.legacy_lock_id === lockId ? { ...lock, unit_id: null, unit_name: null } : lock,
      ),
    );

    // Disable the assigned unit in the units list
    setUnitsData(prevUnits =>
      prevUnits.map(unit =>
        unit.unit_id === unitId ? { ...unit, is_lock_assigned: false } : unit,
      ),
    );

    // Clear the timeout to prevent the API call
    if (assignLockTimeout) {
      clearTimeout(assignLockTimeout);
      setAssignLockTimeout(null); // Clear the reference to the timeout
    }
  };

  const undoAction = (lockId: number, unitId: number) => (
    <Button
      color='secondary'
      size='small'
      sx={{ color: 'white' }}
      onClick={() => handleUndo(lockId, unitId)}
    >
      {t('snackbar_undo_button')}
    </Button>
  );

  const renderAccountInfo = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    return (
      <TableCell title={row.provider_account_name} description={row.provider_account_user_email} />
    );
  };

  const renderLockName = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    return <TableCell title={row.lock_friendly_name} description={row.legacy_lock_id} />;
  };

  const renderPropertyName = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    const isUnassigning = locksBeingUnassigned.includes(row.legacy_lock_id);

    if (isUnassigning) {
      return (
        <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
          <CircularProgress size={24} />
        </Box>
      );
    }

    if (row.unit_id) {
      return <TableCell title={`${row.unit_id} - ${row.unit_name}`} />;
    }

    return (
      <LockAssignPropertyDropdown
        data-testid='lock-assign-property-dropdown'
        unitList={unitsData}
        onLoadMore={loadMoreUnits}
        hasMore={units.total_count > unitsData.length}
        isLoading={isUnitsLoading}
        isFetching={isUnitsFetching}
        onAssign={(unitId, unitName) => {
          if (row.legacy_lock_id) {
            handleAssignLock(row.legacy_lock_id, unitId, unitName);
          }
        }}
        searchKeyword={searchKeyword}
        onSearchChange={handleSearchChange}
        groupList={[...(groupsData?.groups || [])]}
        selectedGroups={selectedGroups}
        onGroupSelect={handleGroupSelect}
      />
    );
  };

  const renderConnectivity = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {row.is_online ? (
          <CloudDoneOutlinedIcon sx={{ color: 'rgb(46, 125, 50, 1)', marginRight: 1 }} />
        ) : (
          <CloudOffOutlinedIcon sx={{ color: 'rgba(211, 47, 47, 1)', marginRight: 1 }} />
        )}
        <TableCell
          title={row.is_online ? 'Online' : 'Offline'}
          sx={{
            color: row.is_online ? 'rgb(46, 125, 50, 1)' : 'rgba(211, 47, 47, 1)',
            marginRight: 1,
          }}
        />
      </Box>
    );
  };

  const columnsDef: GridColDef<DeviceLockViewModel>[] = [
    {
      field: 'provider_account_name',
      headerName: 'Account',
      flex: 1,
      renderCell: renderAccountInfo,
      sortable: false,
    },
    {
      field: 'lock_friendly_name',
      headerName: 'Lock',
      flex: 1,
      renderCell: renderLockName,
      sortable: false,
    },
    {
      field: 'unit_id',
      headerName: 'Unit',
      flex: 1,
      renderCell: renderPropertyName,
      sortable: false,
    },
    {
      field: 'is_online',
      headerName: 'Connectivity',
      flex: 1,
      renderCell: renderConnectivity,
      sortable: false,
    },
  ];

  // Cleanup timeout when component unmounts
  useEffect(() => {
    return () => {
      if (assignLockTimeout) {
        clearTimeout(assignLockTimeout);
      }
    };
  }, [assignLockTimeout]);

  const handleSelectionChange = (newSelection: number[]) => {
    setSelectedRows(newSelection);
  };

  const handleUnassignLocks = () => {
    setDialogOpen(true);
  };

  const handleCancelUnassign = () => {
    setDialogOpen(false);
  };

  const handleConfirmUnassign = async () => {
    setLocksBeingUnassigned(selectedRows);

    try {
      const lockIdsToUnassign = selectedRows
        .map(row => {
          const lockToUnassign = locksData.find(lock => lock.legacy_lock_id === row);
          return lockToUnassign ? lockToUnassign.legacy_lock_id : null;
        })
        .filter(id => id !== null);

      await unassignLocks({
        unassignLocks: { legacyLockIds: lockIdsToUnassign },
      }).unwrap();

      // Update locksData and unitsData after successful unassignment
      setLocksData(prevLocks =>
        prevLocks.map(lock =>
          lockIdsToUnassign.includes(lock.legacy_lock_id)
            ? { ...lock, unit_id: null, unit_name: null }
            : lock,
        ),
      );

      setUnitsData(prevUnits =>
        prevUnits.map(unit => {
          const isUnassigned = lockIdsToUnassign.some(
            lockId =>
              locksData.find(lock => lock.legacy_lock_id === lockId)?.unit_id === unit.unit_id,
          );

          return isUnassigned ? { ...unit, is_lock_assigned: false } : unit;
        }),
      );

      snackbar(
        selectedRows.length === 1
          ? t('single_unassign_snackbar_success_text')
          : `${selectedRows.length} ${t('multiple_unassign_snackbar_success_text')}`,
      );

      setSelectedRows([]);
    } catch (error) {
      logger.error(error);
    } finally {
      setLocksBeingUnassigned([]);
      setDialogOpen(false);
    }
  };

  // Update the toolbarActions
  const toolbarActions = (
    <>
      {menu}
      <Button
        size='medium'
        variant='contained'
        color='primary'
        name='unassign'
        data-testid='unassign-locks-button'
        onClick={handleUnassignLocks}
        sx={{ textTransform: 'none' }}
        disabled={selectedRows.length <= 0}
      >
        {t('unassign_locks_button')} ({selectedRows.length})
      </Button>
      <Button
        size='medium'
        variant='outlined'
        startIcon={<SyncIcon />}
        sx={{ ml: 2, textTransform: 'none', mr: 2 }}
        onClick={handleSync}
      >
        {t('sync_now_button')}
      </Button>
      <Text.BodySmallSemiBold>
        {lastSyncedAt
          ? `${t('last_synced')} ${formatRelativeToTimeZone(
              lastSyncedAt,
              browserTimezone ?? 'UTC',
            )}`
          : ''}
      </Text.BodySmallSemiBold>
    </>
  );

  return (
    <>
      <LocksTitleBar handleReloadOnChange={refetch} />

      <PaginatedTable
        enableToolbar
        title={toolbarActions}
        rows={locksData} // Use locally stored locksData for immediate UI updates
        rowCount={locks.total_count}
        loading={isLocksLoading || isLocksFetching || isLocksLoading}
        onFetch={handleLocksFetch}
        columns={columnsDef}
        getRowId={row => row.legacy_lock_id}
        sortingMode='server'
        checkboxSelection
        selectionModel={selectedRows}
        onSelectionModelChange={handleSelectionChange} // Add this to track row selection
        isRowSelectable={(params: GridRowParams) => !!params.row.unit_id}
      />

      <UnassignUnitDialog
        open={isDialogOpen}
        onCancel={handleCancelUnassign}
        onUnassign={handleConfirmUnassign}
        loading={locksBeingUnassigned.length > 0}
      />
    </>
  );
};

export default LocksTable;
