import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import * as React from 'react';
import { ICategory } from 'redux/guestportal';

export interface CategoriesDropdownProps {
  categoriesList: ICategory[];
  selectedCategory: ICategory;
  onChange: (e: SelectChangeEvent) => void;
}

const CategoriesDropdown = ({
  categoriesList,
  onChange,
  selectedCategory,
}: CategoriesDropdownProps) => {
  return (
    <Select
      style={{ width: '100%', marginTop: 30, marginBottom: 30 }}
      inputProps={{ style: { textAlign: 'start' } }}
      variant='outlined'
      disabled={categoriesList.length === 0}
      value={selectedCategory?.category_id ?? ''}
      onChange={onChange}
    >
      {categoriesList.map(c => (
        <MenuItem key={c.category} value={c.category_id}>
          {c.category}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CategoriesDropdown;
