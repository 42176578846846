import { AppDispatch } from 'redux/store';
import { Actions } from '../../types/actions';
import { SettingType } from '../../types/dashboard-config';

export const getSettings = () => (dispatch: AppDispatch) => {
  const dummyPayload: SettingType[] = [
    SettingType.checkinOverview,
    SettingType.todaysCheckins,
    SettingType.currentPropertyAlerts,
    // SettingType.pastPropertyAlerts,
    // SettingType.complianceGraph,
    SettingType.favorites,
    SettingType.masterActivityFeeds,
    // SettingType.makeAvailable,
    SettingType.multipleNetatmos,
  ];
  dispatch({
    type: Actions.hydrateSettings,
    settings: dummyPayload,
  });
};
