import { Box, List, SxProps, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useMedia } from '@operto/ui';
import { useStyles } from 'Common/Sidebar/DrawerComponentStyles';
import clsx from 'clsx';
import { ClientContext } from 'helper/streamChatHelper';
import { getChurnZeroInstance, trackEvent } from 'lib/analytics';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SideBarAccordionItem from './SideBarAccordionItem';
import SideBarHeader from './SideBarHeader';
import SideBarIcon from './SideBarIcon';
import SideBarItem from './SideBarItem';
import { IMenuItem, IMenuSection, ISideBarMenu, ISubItem } from './SideBarMenuTypes';

export const openCzSuccessCenter = () => {
  const ChurnZero = getChurnZeroInstance();
  ChurnZero.push(['open']);
};

const SideBarMenu: React.FC<ISideBarMenu> = ({ open, toggle, headerButton, sections }) => {
  const classes = useStyles();
  const { isDesktop } = useMedia();
  const {
    clientObject: { clientUnreadCount },
  } = useContext(ClientContext);

  const sectionTitle: SxProps = {
    margin: '16px',
    padding: '0 16px',
    display: open ? 'block' : 'none',
  };

  const listStyles: SxProps = {
    padding: open ? '0' : '24px 0 16px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  };

  const subHeader: SxProps = {
    display: open ? 'block' : 'none',
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const isAbsoluteURL = (url: string): boolean => {
    try {
      const parsedURL = new URL(url);
      return !!parsedURL.protocol;
    } catch (error) {
      return false;
    }
  };

  const linkTo = (e: React.MouseEvent, url: string | undefined) => {
    if (!url) return;

    if (isAbsoluteURL(url)) {
      window.open(url, '_blank', 'noopener noreferrer');
    } else {
      if (url === '/success-center') {
        openCzSuccessCenter();
        return;
      }
      if (url === '/messenger') {
        trackEvent({
          screen: 'DASHBOARD MENU',
          feature: 'MESSENGER',
          event: 'CLICKED',
          unreadCount: clientUnreadCount,
        });
      }

      navigate(url);
    }

    e.stopPropagation();
  };

  const handleClick = (e: React.MouseEvent, url: string) => {
    linkTo(e, url);
    if (!isDesktop) toggle();
  };

  return (
    <>
      <SideBarHeader open={open} toggleNav={toggle} headerButton={headerButton} />
      {sections &&
        sections.map((section: IMenuSection, index: number) => (
          <Box key={index} id={`section-${index}`}>
            {section.topLine && <Divider sx={{ margin: '12px 16px' }} />}
            <Box sx={sectionTitle}>
              <Typography variant='overline' sx={{ color: 'text.secondary' }}>
                {section.label}
              </Typography>
            </Box>

            <List sx={listStyles}>
              {section.items &&
                section.items.map((item: IMenuItem, index: number) => (
                  <Box key={index} id={`menuItem-${index}`}>
                    {item.subItems
                      ? item.isAccessible && (
                          <SideBarAccordionItem
                            onMenuClick={handleClick}
                            MenuItemLinks={item.subItems.filter(subItem => subItem.isAccessible)}
                            isExpanded={item.subItems.some(
                              (subItem: ISubItem) => pathname === subItem.url,
                            )}
                            summaryName={item.label}
                            open={open}
                            summaryIcon={item.icon}
                          >
                            {item.subItems.map(
                              (subItem: ISubItem, index: number) =>
                                subItem.isAccessible && (
                                  <Box key={index} id={`subItem-${index}`}>
                                    <SideBarItem
                                      active={pathname === subItem.url}
                                      onClick={e => {
                                        handleClick(e, subItem.url);
                                      }}
                                      open={open}
                                    >
                                      <Typography variant='body2'>{subItem.title}</Typography>
                                    </SideBarItem>
                                  </Box>
                                ),
                            )}
                          </SideBarAccordionItem>
                        )
                      : item.isAccessible && (
                          <SideBarItem
                            active={pathname === item.path}
                            onClick={e => {
                              handleClick(e, item.path);
                            }}
                            open={open}
                          >
                            <SideBarIcon
                              icon={item.icon}
                              className={clsx({ [classes.drawCloseIcon]: !open })}
                              active={pathname === item.path}
                            />
                            <Box sx={subHeader}>
                              <Typography variant='body2'>{item.label}</Typography>
                            </Box>
                          </SideBarItem>
                        )}
                  </Box>
                ))}
            </List>
          </Box>
        ))}
    </>
  );
};

export default SideBarMenu;
