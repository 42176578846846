import { MenuItem, TextField } from '@mui/material';
import * as React from 'react';

export interface SelectOffsetProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: unknown;
  label: string;
  id: string;
  validator?: React.ReactNode;
  error?: boolean;
}

const SelectOffset = (props: SelectOffsetProps) => {
  const { id, label, value, onChange, validator, error } = props;
  const start = -300;
  const interval = 15;
  const options: unknown[] = new Array(38).fill(undefined);

  return (
    <TextField
      required
      select
      fullWidth
      name={id}
      label={label}
      variant='outlined'
      InputLabelProps={{ shrink: true }}
      margin='normal'
      value={value}
      onChange={onChange}
      helperText={validator}
      error={error}
    >
      {options.map((index: number) => {
        const currentKey = start + interval * index;
        const suffix = currentKey > 0 ? 'after' : 'before';
        const hour = Math.ceil(Math.abs(currentKey) / 60);
        const min = Math.ceil(Math.abs(currentKey) % 60);
        const hourString = hour > 0 ? (hour > 1 ? `${hour} hrs` : `${hour} hr`) : '';
        const minString = min > 0 ? `${min} mins` : '';
        const label = currentKey === 0 ? 'No Offset' : `${hourString} ${minString} ${suffix}`;

        return (
          <MenuItem value={currentKey * 60} key={index}>
            {label}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default SelectOffset;
