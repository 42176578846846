// @ts-nocheck

import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { UnitGroup } from 'types/ui';
import { Actions } from '../../../types/actions';

const showUnitGroup: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleUnitGroup:
      return !state;
    case Actions.hideAddUnitToUnitGroup:
      return false;
    default:
      return state;
  }
};
const groupId: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.toggleUnitGroup:
      return action.groupId;
    default:
      return state;
  }
};

const unitGroup: Reducer<UnitGroup> = combineReducers<UnitGroup>({
  show: showUnitGroup,
  groupId,
});

export default unitGroup;
