export const DEFAULT_PAGINATED_PARAMS = {
  pageNum: 0,
  numPerPage: 100,
} as const;

export type PaginatedParams = {
  pageNum?: number;
  numPerPage?: number;
  [key: string]: string | number | undefined;
};

export type PaginatedData<T> = {
  pageNum: number;
  numPerPage: number;
  total_records: number;
  records: T[];
};

export type ResponseData<T> = {
  status: string;
  message: string;
  data: T;
};

/**
 * Unfortunately, we cant abort and do a new request in the same tick. We need to wait for the next tick.
 * for e.g. if we have a search bar and we want to abort the previous request and do a new request with the new search term.
 */
export const abortQueriesAsync = async (abort?: () => void) => {
  await new Promise<void>(resolve => {
    if (abort) {
      abort(); // NOTE: abort network request
    }

    setTimeout(resolve, 0);
  });
};
