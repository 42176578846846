import { OrderingPage } from 'Pages/GuestPortal/Ordering/OrderingPage';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TabBar from 'ui-library/Components/tabBar/TabBar';
import { GuidesDetailsCategoriesTab } from './Categories/GuidesDetailsCategoriesTab';
import { GuidesDetailsPagesTab } from './Pages/GuidesDetailsPagesTab';

export enum GuidesDetailsTabItem {
  Pages = 0,
  Categories = 1,
  Ordering = 2,
}

const tabItems = [
  {
    label: 'Pages',
    value: GuidesDetailsTabItem.Pages,
  },
  {
    label: 'Categories',
    value: GuidesDetailsTabItem.Categories,
  },
  {
    label: 'Ordering',
    value: GuidesDetailsTabItem.Ordering,
  },
];

const GuidesDetails = () => {
  const location = useLocation();
  const { propertyId: pidStr, guideId } = useParams();
  const propertyId = Number(pidStr);
  const navigate = useNavigate();

  const currentTab = location.pathname.includes('categories')
    ? GuidesDetailsTabItem.Categories
    : location.pathname.includes('pages')
    ? GuidesDetailsTabItem.Pages
    : GuidesDetailsTabItem.Ordering;

  const categoryTab = `/guest-portal/guides/${guideId}/categories`;
  const pageTab = `/guest-portal/guides/${guideId}/pages`;
  const orderingTab = `/guest-portal/guides/${guideId}/ordering`;

  const handleTabChange = (newValue: GuidesDetailsTabItem) => {
    if (propertyId) return;
    if (newValue === GuidesDetailsTabItem.Pages) {
      navigate(pageTab);
    } else if (newValue === GuidesDetailsTabItem.Ordering) {
      navigate(orderingTab);
    } else {
      navigate(categoryTab);
    }
  };

  return (
    <>
      {!propertyId && (
        <TabBar tabItems={tabItems} tabIndex={currentTab} onTabChange={handleTabChange} />
      )}
      {currentTab === GuidesDetailsTabItem.Pages && <GuidesDetailsPagesTab />}

      {!propertyId && (
        <>
          {currentTab === GuidesDetailsTabItem.Categories && <GuidesDetailsCategoriesTab />}
          {currentTab === GuidesDetailsTabItem.Ordering && <OrderingPage />}
        </>
      )}
    </>
  );
};

export default GuidesDetails;
