// @ts-nocheck - FIXME
import { Box, Grid, Typography } from '@mui/material';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { devicesByPropertyIdSelector } from 'device/state/deviceSelector';
import { camelize } from 'helper/helper';
import { Property } from 'property/propertyType';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useAppSelector } from 'redux/hooks';
import Widget from 'ui-library/Components/widget/Widget';

export interface UnitDetailsCardProps {
  property?: Property;
  isOnUnitDashboard?: boolean;
}

const UnitDetailsCard = ({ property, isOnUnitDashboard = false }: UnitDetailsCardProps) => {
  const devices = useAppSelector(devicesByPropertyIdSelector(property?.id));
  return (
    <Box sx={{ padding: isMobile ? '0' : 'auto' }}>
      <Widget
        removeGutter
        isOnUnitDashboard={isOnUnitDashboard}
        title='Unit Details'
        fill='#E8F0FE'
        mode='light'
      >
        <Box sx={mainContainerStyle}>
          <Grid container direction='row' spacing={0}>
            <Grid item xs={12}>
              {property?.name}
            </Grid>
            <Grid item xs={12}>
              {`ID: ${property?.id}`}
            </Grid>
            <Grid item xs={12} style={{ marginTop: '16px' }}>
              {property?.address?.address_line_1}
            </Grid>
            <Grid item xs={12}>
              {property?.address?.city}
            </Grid>
            {property?.timezone && (
              <Grid item xs={12} style={{ marginTop: '16px' }}>
                Local Time {formatInTimeZone(Date.now(), property?.timezone, 'hh:mm a')}{' '}
                {property?.timezone}
              </Grid>
            )}
            <Grid item xs={12} style={{ marginTop: '16px', marginBottom: '16px' }}>
              Devices
            </Grid>
            {devices &&
              devices?.map(device => {
                const deviceName = camelize(device.data?.name || device.name);
                const deviceType = device.type && camelize(device.type);
                return (
                  <Grid key={`${device?.type}-${deviceName}`} item xs={12}>
                    <Typography variant='body1'>{`${deviceType} : ${deviceName}`}</Typography>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Widget>
    </Box>
  );
};

const mainContainerStyle: SxProps = {
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '24px',
  color: 'text',
  transition: 'all 500ms ease-out',
};

export default UnitDetailsCard;
