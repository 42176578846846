import { useTrackScreen } from 'lib/analytics';
import { logger } from 'lib/logger';
import { getMembers } from 'member/state/memberActions';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useGetGroupsQuery } from 'redux/groups/api-slice';
import { useAppDispatch } from 'redux/hooks';

export const GroupsPage = () => {
  useTrackScreen({ screen: 'GROUPS' });
  const dispatch = useAppDispatch();
  const { error, isError } = useGetGroupsQuery();

  useEffect(() => {
    dispatch(getMembers());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      logger.error('Error fetching groups: ', error);
    }
  }, [error, isError]);

  return <Outlet />;
};
