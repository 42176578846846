import { OfferDetails, TransactionDetails } from './OrderDetailSidebar';
import { Order } from './OrdersTable';

interface OrderStatusParams {
  order: Order;
  offerDetails: OfferDetails;
  transactionDetails?: TransactionDetails;
  companyLogoLink: string;
  status: string;
  declinedReason?: string;
}

export function getOrderStatusEmail({
  order,
  offerDetails,
  transactionDetails,
  companyLogoLink,
  status,
  declinedReason,
}: OrderStatusParams): string {
  const GUEST_EMAIL_TEMPLATE_OPERTO_LOGO =
    'https://cdn.operto.com/tech/assets/images/OPERTO-LOGO-BLACK.png';
  const guestPortalURL = process.env.REACT_APP_GUEST_PORTAL_URL;
  const totalPrice = order.totalPrice.toFixed(2);
  const orderStatus = status;
  const orderLink = `${guestPortalURL}/orders/${order.id}?companyId=${order.companyId}`;
  const approvedOrPendingContent = `
    <p><strong>Instructions</strong></p>
    <p>${offerDetails.postPurchase?.instructions ?? '-'}</p>
    `;

  const declinedContent = `
      <p><strong>Declined message</strong></p>
      <p>${declinedReason ?? '-'}</p>
    `;

  const taxContent = `
    <tr class="price-item">
        <td align="left">Tax ${order.taxRate.toFixed(2)}%</td>
        <td align="right">$${order.taxPrice.toFixed(2)} USD</td>
    </tr>`;

  const paymentCardIcon = () => {
    const brand = transactionDetails?.paymentMethodBrand;
    if (!brand) return 'defaultPayment.png';

    const formattedBrand = brand
      .replace(/(?:^|_)(\w)/g, (_, c) => c.toUpperCase())
      .replace('ChinaUnionPay', 'ChinaUnion'); // Special case for ChinaUnionPay

    return `${formattedBrand}.png`;
  };

  return `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Order Confirmation</title>
    <style>
        body {
            font-family: Roboto;
        }
        .header {
            width: 600px;
            max-height: 324px;
            gap: 8px;
            text-align: center;
            padding: 24px 0px 24px 0px;
        }
        h1 {
            font-family: Roboto;
            font-weight: 400;
            size: 36px;
            line-height: 44px;
            margin: 0px;
            color: #000;
        }
        h2 {
            font-family: Roboto;
            font-weight: 400;
            size: 28px;
            line-height: 36px;
            margin: 0px;
            color: #000;
        }
        h5 {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-align: center;
            margin: 0px;
            color: #000;
        }
        p {
            margin: 0px;
            color: #000;
        }
        .logo {
            text-align: center;
            margin-bottom: 20px;
        }
        .logo img {
            width: 120px;
            height: 120px;
        }
        .container {
            max-width: 600px;
            margin: 0 auto;
            padding: 0px, 0px, 24px, 0px;
            gap: 24px;
            border: 0px, 0px, 1px, 0px;
        }
        .image-container {
            width: 600px;
            height: 350px;
            padding: 8px 24px 8px 24px;
            gap: 8px;
            box-sizing: border-box;
        }
        .image-container img {
            width: 552px;
            height: 334px;
            border-radius: 28px;
            object-fit: cover;
            display: block;
        }
        .button-container {
            text-align: center;
            padding: 20px 0px 20px 0px;
        }
        .button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #000;
            color: #fff;
            padding: 10px 24px;
            text-decoration: none;
            border-radius: 100px;
            min-width: 116px;
            height: 40px;
            font-family: Roboto;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            box-sizing: border-box;
        }
        .content {
            width: 100%;
            max-width: 600px;
            padding-top: 24px;
            border-top: 1px solid #ccc;
        }
        .content p {
            margin-bottom: 8px;
        }
        .total {
            gap: 8px;
        }
        .total-header {
            font-weight: 500;
            border-bottom: 1px solid #ccc;
            padding-bottom: 4px;
            color: #000;
        }
        .price-list {
            list-style-type: none;
            padding: 4px 0;
            border-bottom: 1px solid #ccc;
            margin: 0px;
        }
        .price-item {
            padding: 4px 0;
            color: #000;
        }
        .payment-method {
            padding-top: 4px;
            margin-bottom: 20px;
            padding-bottom: 8px;
        }
        .payment-details {
            display: flex;
            align-items: center;
            color: #000;
        }
        .card-logo {
            margin-right: 15px;
        }
        .card-logo img {
            width: 60px;
            height: 38px;
        }
        .card-info {
            font-family: Roboto;
        }
        .footer {
            width: 552px;
            max-height: 151px;
            margin: 0 auto;
        }
        .operto-logo {
            padding: 24px 0;
            text-align: center;
        }
        .operto-logo p {
            font-size: 11px;
            font-weight: 400;
            letter-spacing: 2px;
            margin: 0;
            color: #000;
        }
        .operto-logo img {
            width: 100px;
            height: 28px;
        }
        .legal-info {
            padding: 18px 0;
            text-align: center;
        }
        .legal-info p {
            font-family: Roboto;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            margin: 0;
            color: #000;
            padding: 18px 0;
            text-align: center;
        }
        .separator {
            display: inline-block;
            width: 4px;
            height: 4px;
            background-color: #666;
            border-radius: 50%;
            vertical-align: middle;
            margin: 0 8px;
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="header">
            <div class="logo">
                <img src="${companyLogoLink}" alt="">
            </div>
            <h1>${order.name}</h1>
            <h2>Order ${orderStatus}</h2>
            <h5>Order ID ${order.id}</h5>
        </div>
        <div class="image-container">
            <img src="${offerDetails.imageUrl}" alt="">
        </div>
        <div class="button-container">
            <a href="${orderLink}" class="button" style="color: #fff;">View order</a>
        </div>
        <div class="content">
            <p><strong>Booking</strong></p>
            <p>${offerDetails?.information.description ?? '-'}</p>
            ${orderStatus === 'rejected' ? declinedContent : approvedOrPendingContent}
            <div class="total">
                <table width="100%" cellpadding="0" cellspacing="0" border="0">
                    <tr>
                        <td class="total-header">
                            <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                <tr>
                                    <td align="left"><strong>Total</strong></td>
                                    <td align="right"><strong>$${totalPrice} USD</strong></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table class="price-list" width="100%" cellpadding="0" cellspacing="0" border="0">
                                <tr class="price-item">
                                    <td align="left">${
                                      order.quantitySelected ? order.quantitySelected : '1'
                                    } item</td>
                                    <td align="right">$${totalPrice} USD</td>
                                </tr>
                                ${order.taxRate ? taxContent : ''}

                            </table>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="payment-method">
                <p><strong>Payment method</strong></p>
                <div class="payment-details">
                <div class="card-logo">
                    <img src="https://cdn.operto-qa.com/upsells/payment-icons/${paymentCardIcon()}" alt="">
                </div>
                <div class="card-info">
                    <div class="card-number">${
                      transactionDetails?.paymentMethodBrand ?? '----'
                    } ****${transactionDetails?.paymentMethodLastFour ?? '----'}</div>
                    <div class="card-expiration">Expiration: ${
                      transactionDetails?.paymentMethodExpirationMonth ?? '--'
                    }/${transactionDetails?.paymentMethodExpirationYear ?? '----'}</div>
                </div>
                </div>
            </div>
        </div>
        <table class="footer" width="100%" cellpadding="0" cellspacing="0" border="0">
            <tr>
                <td class="operto-logo" align="center">
                    <p style="font-size: 11px; font-weight: 400; letter-spacing: 2px; margin: 0;">Powered by Operto</p>
                    <img src="${GUEST_EMAIL_TEMPLATE_OPERTO_LOGO}" alt="">
                </td>
            </tr>
            <tr>
                <td class="legal-info" align="center">
                    <p style="font-weight: bold; font-size: 14px; line-height: 20px; margin: 0;">
                        Terms of Sale
                        <span style="display: inline-block; width: 4px; height: 4px; border-radius: 50%; vertical-align: middle; margin: 0 8px;"></span>
                        Privacy Policy
                    </p>
                </td>
            </tr>
        </table>
    </div>
</body>
</html>
`;
}
