import { Grid } from '@mui/material';
import React from 'react';

type UpsellTemplateListProps = {
  children: React.ReactNode | React.ReactNode[];
};

const UpsellTemplatePage = ({ children }: UpsellTemplateListProps) => {
  const templateCards = Array.isArray(children) ? children : [children];

  return (
    <Grid container spacing={3} mb={3}>
      {templateCards.map((child, index) => (
        <Grid item xs={12} md={6} lg={4} xl={3} key={`${index}`}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

export default UpsellTemplatePage;
