import OrdersTable from 'Common/Tables/Orders/OrdersTable';
import React from 'react';
import OrderItemsTitlebar from './OrderItemsTitlebar';

const OrderItemsPage = () => {
  return (
    <>
      <OrderItemsTitlebar />
      <OrdersTable title='' />
    </>
  );
};

export default OrderItemsPage;
