import Add from '@mui/icons-material/Add';
import { Box, Button, PopoverOrigin, SxProps, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import CommunicationsNewMenu, { MenuOptionType } from 'Pages/Communications/CommunicationsNewMenu';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CreateTemplate = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const menuAnchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  };

  const menuTransformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'center',
  };

  const handleNewTemplateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleNewMenuClose = (selectedOption?: MenuOptionType) => {
    if (selectedOption) {
      navigate(`/communications/create/${selectedOption}`);
    }

    setMenuAnchorEl(null);
  };

  return (
    <Box data-testid='table-no-results-box' sx={tableNoResultsOutterBoxStyles}>
      <Box sx={InnerBoxStyles}>
        <Typography variant='h2'>{t('create_template_header')}</Typography>

        <Typography variant='body1' sx={{ marginY: '16px' }}>
          {t('create_template_body')}
        </Typography>

        <Box>
          <Button
            variant='contained'
            color='primary'
            startIcon={<Add />}
            sx={buttonStyles}
            onClick={handleNewTemplateClick}
          >
            {t('communication')}
          </Button>
        </Box>

        <CommunicationsNewMenu
          anchorEl={menuAnchorEl}
          open={menuOpen}
          handleClose={handleNewMenuClose}
          anchorOrigin={menuAnchorOrigin}
          transformOrigin={menuTransformOrigin}
        />
      </Box>
    </Box>
  );
};

const buttonStyles: SxProps = {
  textTransform: 'none',
  minWidth: '156px',
};

const tableNoResultsOutterBoxStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
};

const InnerBoxStyles: SxProps = {
  textAlign: 'center',
  maxWidth: '500px',
};

export default CreateTemplate;
