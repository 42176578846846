// @ts-nocheck
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import { cToF } from 'helper/helper';
import { getMembers } from 'member/state/memberActions';
import { getSelf } from 'member/state/memberSelectors';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import {
  updateThermostatSettingsEcobee,
  updateThermostatSettingsTadoAirConditioning,
  updateThermostatSettingsTadoHeating,
} from 'redux/actions/thermostats';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styled, { ThemeProvider } from 'styled-components';
import { ThermostatSetting } from 'types/thermostats';
import RadialBarRangeSlider from 'ui-library/Components/canvas/RadialBarRangeSlider';
import RadialBarSingleSlider from 'ui-library/Components/canvas/RadialBarSingleSlider';
import Widget from 'ui-library/Components/widget/Widget';

export interface EnergyWidgetProps {
  tempObject: ThermostatSetting;
  twoBars?: boolean;
  footerButtons?: boolean;
  title: string;
}

const EnergyWidget = ({ twoBars, footerButtons, title, tempObject }: EnergyWidgetProps) => {
  const { propertyId } = useParams();
  const dispatch = useAppDispatch();
  const userAccount = useAppSelector(getSelf());

  React.useEffect(() => {
    dispatch(getMembers());

    setTemperatureScale(userAccount?.is_celsius ? 'C' : 'F');
  }, [userAccount?.is_celsius, dispatch]);

  const [temperatureFrom, setTemperatureFrom] = React.useState(
    twoBars ? parseInt(tempObject.low) : parseInt(tempObject.high),
  );
  const [temperatureTo, setTemperatureTo] = React.useState(parseInt(tempObject.high));
  const [temperatureScale, setTemperatureScale] = React.useState('F');
  const [isPowerOn, setIsPowerOn] = React.useState(tempObject.power);
  const [isAcOn, setIsAcOn] = React.useState(tempObject.air_conditioning);

  const acVariant = isAcOn ? 'contained' : 'outlined';
  const heatVariant = isAcOn ? 'outlined' : 'contained';
  const powerOnVariant = isPowerOn ? 'contained' : 'outlined';
  const powerOffVariant = isPowerOn ? 'outlined' : 'contained';
  const temperatureFormatted = `${temperatureScale}°`;

  const MIN_TEMP_DIFFERENCE = 3;
  const marks = [
    { value: 0, label: `${temperatureScale === 'C' ? '0°C' : '32°F'}` },
    { value: 50, label: `${temperatureScale === 'C' ? '50°C' : '122°F'}` },
  ];

  const valueCOrF = (value: number) => {
    return temperatureScale === 'C' ? value : cToF(value);
  };

  const handleAC = () => {
    if (!isAcOn) {
      setIsAcOn(true);
      booleanChangingUpdate(true, isPowerOn);
    }
  };
  const handleHeat = () => {
    if (isAcOn) {
      setIsAcOn(false);
      booleanChangingUpdate(false, isPowerOn);
    }
  };
  const handlePowerOn = () => {
    if (!isPowerOn) {
      setIsPowerOn(true);
      booleanChangingUpdate(isAcOn, true);
    }
  };
  const handlePowerOff = () => {
    if (isPowerOn) {
      setIsPowerOn(false);
      booleanChangingUpdate(isAcOn, false);
    }
  };

  const changeTemp = (newValue: number[] | number) => {
    if (newValue[1]) {
      if (newValue[1] - newValue[0] >= MIN_TEMP_DIFFERENCE) {
        setTemperatureFrom(newValue[0]);
        setTemperatureTo(newValue[1]);
      } else if (newValue[1] - MIN_TEMP_DIFFERENCE > 0) {
        setTemperatureFrom(newValue[1] - MIN_TEMP_DIFFERENCE);
        setTemperatureTo(newValue[1]);
      } else {
        // if (toValue - minDiff) < 0, set fromValue to 0 and toValue to minDiff
        setTemperatureFrom(0);
        setTemperatureTo(MIN_TEMP_DIFFERENCE);
      }
    } else {
      setTemperatureFrom(newValue);
    }
  };

  const booleanChangingUpdate = (isAc: boolean, isPower: boolean) => {
    let updatedTempObj = {} as ThermostatSetting;
    updatedTempObj = {
      ...tempObject,
      air_conditioning: isAc,
      power: isPower,
    };
    callUpdateAction(updatedTempObj);
  };

  const tempChangingObject = (newTemp: number[]) => {
    let updatedTempObj = {} as ThermostatSetting;
    if (newTemp[1]) {
      updatedTempObj = {
        ...tempObject,
        high: newTemp[1].toString(),
        low: newTemp[0].toString(),
        air_conditioning: isAcOn,
        power: isPowerOn,
      };
    } else {
      updatedTempObj = {
        ...tempObject,
        high: newTemp.toString(),
        air_conditioning: isAcOn,
        power: isPowerOn,
      };
    }
    callUpdateAction(updatedTempObj);
  };

  const callUpdateAction = (thermostatObject: ThermostatSetting) => {
    switch (thermostatObject.type) {
      case 'ECOBEE': {
        dispatch(updateThermostatSettingsEcobee(thermostatObject, propertyId));
        break;
      }
      case 'Tado-Heating': {
        dispatch(updateThermostatSettingsTadoHeating(thermostatObject, propertyId));
        break;
      }
      case 'Tado-AirCondtioning': {
        dispatch(updateThermostatSettingsTadoAirConditioning(thermostatObject, propertyId));
        break;
      }
      default:
        dispatch(updateThermostatSettingsTadoHeating(thermostatObject, propertyId));
    }
  };
  const theme = {
    fill: 'background.paper',
    mode: 'light',
  };

  return (
    <WidgetContainer isMobile={isMobile}>
      <ThemeProvider theme={theme}>
        <Widget title={title} fill={'background.paper'} mode={'light'}>
          <ContainerGrid container spacing={1}>
            <RadialGrid item xs={12}>
              {twoBars && (
                <RadialBarRangeSlider
                  fromValue={temperatureFrom}
                  toValue={temperatureTo}
                  upperLeftTextOne={valueCOrF(temperatureFrom)}
                  upperLeftTextTwo={valueCOrF(temperatureTo)}
                  upperRightText={temperatureFormatted}
                  lowerText={
                    tempObject.thermostat_room_status === 'OCCUPIED' ? 'Arrival' : 'Vacant'
                  }
                  primaryColor='primary.main'
                  secondaryColor='primary.main'
                  baseColor='#EAEEF0'
                  onChange={changeTemp}
                  marks={marks}
                  onUpdate={tempChangingObject}
                  max={50}
                />
              )}
              {!twoBars && (
                <RadialBarSingleSlider
                  fromValue={temperatureFrom}
                  upperLeftTextOne={valueCOrF(temperatureFrom)}
                  upperRightText={temperatureFormatted}
                  lowerText={
                    tempObject.thermostat_room_status === 'OCCUPIED' ? 'Arrival' : 'Vacant'
                  }
                  primaryColor='primary.main'
                  baseColor='#EAEEF0'
                  onChange={changeTemp}
                  marks={marks}
                  onUpdate={tempChangingObject}
                  max={50}
                />
              )}
            </RadialGrid>
            {footerButtons && (
              <>
                <Grid item xs={12}>
                  <ButtonGroup color='primary' aria-label='outlined primary button group'>
                    <ToggleButton variant={acVariant} onClick={handleAC}>
                      AC ON
                    </ToggleButton>
                    <ToggleButton variant={heatVariant} onClick={handleHeat}>
                      HEAT ON
                    </ToggleButton>
                  </ButtonGroup>
                </Grid>
                <Grid item xs={12}>
                  <ButtonGroup color='primary' aria-label='outlined primary button group'>
                    <ToggleButton variant={powerOnVariant} onClick={handlePowerOn}>
                      POWER ON
                    </ToggleButton>
                    <ToggleButton variant={powerOffVariant} onClick={handlePowerOff}>
                      POWER OFF
                    </ToggleButton>
                  </ButtonGroup>
                </Grid>
              </>
            )}
          </ContainerGrid>
        </Widget>
      </ThemeProvider>
    </WidgetContainer>
  );
};

const RadialGrid = styled(Grid)`
  && {
    width: 270px;
  }
`;
const ContainerGrid = styled(Grid)`
  && {
    .MuiGrid-spacing-xs-1 > .MuiGrid-item {
      padding: 6px;
    }
  }
`;
const ToggleButton = styled(Button)`
  && {
    height: 36px;
    width: 111px;
    font-size: 14px;
    padding: 0;
  }
`;

const WidgetContainer = styled.div<{ isMobile: boolean }>`
  height: 350px;
  width: 270px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${props => (props.isMobile ? '100px' : '120px')};
  margin-top: ${props => (props.isMobile ? '10px' : '30px')};
`;

export default EnergyWidget;
