import React, { useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Tab, Tabs } from '@mui/material';
import RightSideTopbar from 'Common/Slidebar/components/RightSideTopbar';
import { IMember, MemberFilterType } from 'member/memberType';
import MemberUnitsContainer from 'member/MemberUnitsContainer';
import { getMembersByCompany, getPropertyMembers } from 'member/state/memberActions';
import {
  groupIdForMembersSelector,
  memberMetaSelector,
  membersByPropertyId,
  membersByTypeSelector,
  memberSelector,
} from 'member/state/memberSelectors';
import { useLocation } from 'react-router-dom';
import { addGroupsToUnit, removeGroupsFromUnit } from 'redux/actions/properties';
import { toggleSlidebar as toggleSlidebarAction, toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SlidebarType, SnackbarTypes, SnackbarVariant } from 'types/ui';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { ActionButton, MainContentContainer } from 'ui-library/Styled/sidebar';
import { TabWrapper } from 'ui-library/Styled/wrappers';

const MemberFilterList: MemberFilterType[] = [
  MemberFilterType.ALL_MEMBERS,
  MemberFilterType.STAFF,
  MemberFilterType.CONTRACTOR,
  MemberFilterType.INACTIVE,
];
export interface MemberUnitsSlideBarProps {
  handleClose: () => void;
}

const MemberUnitsSlideBar = ({ handleClose }: MemberUnitsSlideBarProps) => {
  // const propertyId = Number(useParams<{ propertyId: string }>().propertyId);

  // can't get propertyId as params because it's not passed with :params format. used useLocation instead.
  const location = useLocation();
  const path = location.pathname;
  const pathArr = path.split('/');
  const propertyId = pathArr.includes('units')
    ? pathArr[2]
      ? parseInt(pathArr[2], 10)
      : 0
    : pathArr[3]
    ? parseInt(pathArr[3], 10)
    : 0;

  const [tabIdx, setTabIdx] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getMembersByCompany({
        filterType: MemberFilterList[tabIdx],
        pageNum: 0,
        numPerPage: 50,
      }),
    )
      // @ts-ignore
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [tabIdx, dispatch]);

  const members = useAppSelector(membersByTypeSelector(MemberFilterList[tabIdx]));
  const meta = useAppSelector(memberMetaSelector(MemberFilterList[tabIdx]));
  const propertyMembers = useAppSelector(membersByPropertyId(propertyId)) as IMember[];
  const companyMembers = useAppSelector(memberSelector()) as IMember[];
  const companyMembersIds = companyMembers?.map(val => val.id);
  const propertyMembersIds = propertyMembers?.map(val => val.id);

  const unSelectedPropertyMembersIds = companyMembersIds.filter(
    memberId => !propertyMembersIds.includes(memberId),
  );

  const [saveData, setSaveData] = React.useState({
    check: propertyMembersIds,
    uncheck: [],
    newChecked: [],
    newUnChecked: [],
  });

  const removeGroupIds = useAppSelector(
    groupIdForMembersSelector(saveData.newUnChecked),
  ) as number[];
  const newGroupIds = useAppSelector(groupIdForMembersSelector(saveData.newChecked));
  const onSave = () => {
    // @ts-ignore
    dispatch(addGroupsToUnit(newGroupIds, propertyId)).then(() => {
      dispatch(
        getMembersByCompany({
          filterType: MemberFilterList[tabIdx],
          pageNum: 0,
          numPerPage: 50,
        }),
      );
      dispatch(removeGroupsFromUnit(removeGroupIds, propertyId))
        // @ts-ignore
        .then(() => {
          dispatch(toggleSlidebarAction(SlidebarType.CLOSE, ''));
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: pathArr.includes('units')
                ? 'Assigning members to the unit was successful.'
                : `Assigning members to roomtype was successful.`,
              variant: SnackbarVariant.SUCCESS,
            }),
          );
          dispatch(getPropertyMembers(propertyId, true));
          dispatch(
            getMembersByCompany({
              filterType: MemberFilterList[tabIdx],
              pageNum: 0,
              numPerPage: 50,
            }),
          );
        })
        .catch(() => {
          dispatch(toggleSlidebarAction(SlidebarType.CLOSE, ''));
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: pathArr.includes('units')
                ? 'Assigning members to the unit was not successful.'
                : 'Assigning members to the roomtype was not successful.',
              variant: SnackbarVariant.ERROR,
            }),
          );
          setIsLoading(true);
          dispatch(getPropertyMembers(propertyId, true))
            // @ts-ignore
            .then(() => {
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        });
    });
  };
  const handleChange = (event: React.ChangeEvent, newValue: number) => {
    setTabIdx(newValue);
  };

  return (
    <MainContentContainer>
      <RightSideTopbar
        title={
          pathArr.includes('units')
            ? 'Assign Members to Unit'
            : `Assign Members to roomtype ${pathArr[2]}`
        }
        handleClose={handleClose}
        buttonComponent={
          <ActionButton
            variant='contained'
            color='primary'
            startIcon={<AddIcon />}
            onClick={onSave}
          >
            ASSIGN
          </ActionButton>
        }
      />
      <TabWrapper>
        <Tabs value={tabIdx} onChange={handleChange}>
          <Tab label='All Members' />
          <Tab label='Staff' />
          <Tab label='Contractors' />
          <Tab label='Inactive' />
        </Tabs>
      </TabWrapper>
      <LoadingContainer loading={isLoading}>
        <MemberUnitsContainer
          members={members}
          meta={meta}
          data={saveData}
          checkedSelection={propertyMembersIds}
          uncheckedSelection={unSelectedPropertyMembersIds}
          onChange={setSaveData}
          loading={isLoading}
        />
      </LoadingContainer>
    </MainContentContainer>
  );
};

export default MemberUnitsSlideBar;
