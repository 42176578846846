import { unwrapResult } from '@reduxjs/toolkit';
import { OpertoLogger } from 'Logger/logger';
import { useCallback } from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { clearStatus, selectSecurityDeposit } from './slice';
import { getSecutiryDeposits, patchSecurityDeposits } from './thunks';

export const useSecurityDeposit = () => {
  const dispatch = useAppDispatch();

  const { message, status, securityDeposit } = useAppSelector(selectSecurityDeposit);

  // select product id according to property id
  const selectedProductId = (propertyId: number) => {
    const product = securityDeposit.data.find(data => data?.id === propertyId);
    return product.productId;
  };

  const fetchSecurityDeposit = useCallback(
    async (pageNum: number, numPerPage: number, reset?: boolean) => {
      dispatch(getSecutiryDeposits({ numPerPage, pageNum, reset }));
    },
    [dispatch],
  );

  const updateSecurityDeposit = async (propertyIds: number[], price: number) => {
    try {
      const productId = selectedProductId(propertyIds.find(Boolean));
      const resultAction = await dispatch(patchSecurityDeposits({ propertyIds, price, productId }));
      unwrapResult(resultAction);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Your security deposit amount has been updated',
          variant: SnackbarVariant.SUCCESS,
        }),
      );
      dispatch(clearStatus());

      return true;
    } catch (e) {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Your security deposit amount cannot be updated',
          variant: SnackbarVariant.ERROR,
        }),
      );
      OpertoLogger.Log(message);
      dispatch(clearStatus());
      return false;
    }
  };

  return {
    fetchSecurityDeposit,
    securityDeposit,
    status,
    updateSecurityDeposit,
  };
};
