import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import React from 'react';

export interface ActionCellProps {
  handleClick?: () => void;
}

const ActionCell = ({ handleClick }: ActionCellProps) => {
  return (
    <IconButton size='small' onClick={handleClick}>
      <MoreVertIcon />
    </IconButton>
  );
};

export default ActionCell;
