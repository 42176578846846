export enum SnackBarInfoType {
  ACCESS_CODE_SETTING_SUCCESS,
  ACCESS_CODE_SETTING_FAIL,
  TEST_ACCOUNT_SETTING_SUCCESS,
  TEST_ACCOUNT_SETTING_FAIL,
  TEMPERATURE_SETTING_SUCCESS,
  TEMPERATURE_SETTING_FAIL,
}

export const getSnackMessage = (
  snackBarInfoType: SnackBarInfoType,
  data: Record<string, unknown> = null,
) => {
  switch (snackBarInfoType) {
    case SnackBarInfoType.ACCESS_CODE_SETTING_SUCCESS:
      return `The access code format for ${data?.lockType} has been successfully saved.`;
    case SnackBarInfoType.ACCESS_CODE_SETTING_FAIL:
      return `The access code format entered is invalid. Please review and re-enter.`;
    case SnackBarInfoType.TEST_ACCOUNT_SETTING_SUCCESS:
      return `Test Account status successfully ${data.isActive ? 'enabled' : 'disabled'}.`;
    case SnackBarInfoType.TEST_ACCOUNT_SETTING_FAIL:
      return `Test Account status could not be set. Please try again.`;
    case SnackBarInfoType.TEMPERATURE_SETTING_SUCCESS:
      return `Your temperature format has been set to ${data.temperature}.`;
    case SnackBarInfoType.TEMPERATURE_SETTING_FAIL:
      return `Your temperature format failed to save. Please try again.`;

    default:
      return 'Missing Snackbar Message';
  }
};
