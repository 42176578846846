import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import ProfileIcon from 'ui-library/Components/icon/ProfileIcon';

export interface MemberMenuItemProps {
  fullName?: string;
}

const MemberMenuItem = ({ fullName }: MemberMenuItemProps) => (
  <MenuItem sx={{ padding: '0px' }}>
    <ListItemIcon sx={{ marginRight: '10px' }}>
      <ProfileIcon fullName={fullName} />
    </ListItemIcon>
    <Typography
      variant='h4'
      sx={{ color: 'text.white', wordWrap: 'break-word', whiteSpace: 'initial' }}
    >
      {fullName}
    </Typography>
  </MenuItem>
);

export default MemberMenuItem;
