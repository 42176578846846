import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import { FilterType, GuestCounts, GuestDict, GuestState } from 'guest/guestType';
import { Actions } from 'types/actions';

const initialListState: number[] = [];

const list: Reducer<number[]> = (state = initialListState, action) => {
  switch (action.type) {
    case Actions.hydrateGuests:
      return Array.from(new Set([...action.guestList]));
    case Actions.insertGuests:
      return Array.from(new Set([...state, ...action.guestList]));
    case Actions.upsertGuest:
      return Array.from(new Set([...state, action.guest.id]));
    case Actions.deleteGuest:
      return state.filter((each: number) => each !== action.id);
    default:
      return state;
  }
};

const initialGuestState: GuestDict = {};

const byId: Reducer<GuestDict> = (state = initialGuestState, action) => {
  switch (action.type) {
    case Actions.hydrateGuests:
      return action.guestDict;
    case Actions.insertGuests:
      return { ...state, ...action.guestDict };
    case Actions.upsertGuest:
      return {
        ...state,
        [action.guest.id]: action.guest,
      };
    case Actions.cancelPmsReservation:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          type: FilterType.BLOCKED,
        },
      };
    case Actions.grantPmsReservation:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          type: FilterType.ALL_GUESTS,
        },
      };
    default:
      return state;
  }
};

const initalCounts: GuestCounts = {
  currentFilter: FilterType.ALL_GUESTS,
  isLoading: false,
  [FilterType.ALL_GUESTS]: { pageNum: 0, numPerPage: 10, total_records: 0 },
  [FilterType.PRE_STAY]: { pageNum: 0, numPerPage: 10, total_records: 0 },
  [FilterType.POST_STAY]: { pageNum: 0, numPerPage: 10, total_records: 0 },
  [FilterType.IN_STAY]: { pageNum: 0, numPerPage: 10, total_records: 0 },
  [FilterType.BLOCKED]: { pageNum: 0, numPerPage: 10, total_records: 0 },
};

const meta: Reducer<GuestCounts> = (state = initalCounts, action: AnyAction) => {
  switch (action.type) {
    case Actions.updateGuestsCounts:
      return {
        ...state,
        [action.guestType]: action.meta,
      };
    case Actions.updateGuestsMeta:
      return {
        ...state,
        currentFilter: action.guestType ?? state.currentFilter,
        isLoading: action.isLoading ?? state.isLoading,
        [action.guestType]: action.meta ?? state[action.guestType as FilterType],
      };
    default:
      return state;
  }
};

const reducer: Reducer<GuestState> = combineReducers<GuestState>({
  byId,
  list,
  meta,
});

export default reducer;
