import * as React from 'react';

import { Box, Button, Paper, Typography } from '@mui/material';

export interface EmptyStateContainerProps {
  showEmpty?: boolean;
  iconUrl?: string;
  message?: string;
  buttonText?: string;
  onClick?: () => void;
}

const EmptyStateContainer = ({
  message,
  buttonText,
  iconUrl,
  onClick,
}: EmptyStateContainerProps) => (
  <Box sx={{ width: '100%' }}>
    <Paper
      sx={{
        width: '100%',
        minHeight: '500px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={iconUrl} width='100px' style={{ marginBottom: '20px' }} />
      <Typography sx={{ marginBottom: '20px' }}>{message}</Typography>
      {buttonText && (
        <Button onClick={onClick} variant='contained' color='primary'>
          {buttonText}
        </Button>
      )}
    </Paper>
  </Box>
);

export default EmptyStateContainer;
