import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useCallback } from 'react';
import { useSecurityDeposit } from 'redux/security-deposit';
import { PaginatedTable, PaginatedTableProps } from 'ui-library/Components/table/PaginatedTable';

export type PaymentsTableProps = Pick<
  PaginatedTableProps,
  'selectionModel' | 'onSelectionModelChange'
>;

const renderSecurityDeposit = (params: GridRenderCellParams) => {
  const deposit = params.row.securityDeposit;
  return deposit === 0 ? <>N/A</> : <>$ {deposit}</>;
};

const columns: GridColDef[] = [
  { field: 'unit', headerName: 'Unit', flex: 1 },
  { field: 'id', headerName: 'Unit Id', flex: 1 },
  {
    field: 'securityDeposit',
    headerName: 'Security Deposit',
    renderCell: renderSecurityDeposit,
    flex: 1,
  },
  // hide room_type column for now. We should show this column after based on a room type flag
  // { field: 'room_type', headerName: 'Room Type', flex: 1 },
];

const PaymentsTable = (props: PaymentsTableProps) => {
  const {
    fetchSecurityDeposit,
    status,
    securityDeposit: { meta, data },
  } = useSecurityDeposit();
  const isLoading = status === 'loading';

  const handleFetch = useCallback(
    (serverPageNum: number, limit: number) => {
      fetchSecurityDeposit(serverPageNum, limit);
    },
    [fetchSecurityDeposit],
  );

  return (
    <PaginatedTable
      loading={isLoading}
      rows={data}
      rowCount={meta?.total_records}
      columns={columns}
      checkboxSelection
      onFetch={handleFetch}
      {...props}
    />
  );
};

export default PaymentsTable;
