import * as React from 'react';

import Battery20Icon from '@mui/icons-material/Battery20Rounded';
import Battery30Icon from '@mui/icons-material/Battery30Rounded';
import BatteryWarningIcon from '@mui/icons-material/BatteryAlert';
import Battery100Icon from '@mui/icons-material/BatteryFullRounded';
import CodeIcon from '@mui/icons-material/DialpadRounded';
import ReservationIcon from '@mui/icons-material/EventRounded';
import RoomStatusIcon from '@mui/icons-material/HotelRounded';
import LockIcon from '@mui/icons-material/LockOpenRounded';
import StatusIcon from '@mui/icons-material/ToggleOffRounded';
import RiskAlertIcon from '@mui/icons-material/WarningRounded';
import ThermostatIcon from '@mui/icons-material/WbSunnyRounded';
import { SxProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { ActivityCodes, IEvent } from 'event/eventType';
import ProfileIcon from 'ui-library/Components/icon/ProfileIcon';

export interface IconForCodeProps {
  codeType: ActivityCodes;
  payload: IEvent;
}

const IconForCode = ({ codeType, payload }: IconForCodeProps) => {
  switch (codeType) {
    case ActivityCodes.CODE_USED:
    case ActivityCodes.CODE_ACCESS:
    case ActivityCodes.CODE_CREATED:
    case ActivityCodes.CODE_QUEUED:
    case ActivityCodes.CODE_CHECKIN:
    case ActivityCodes.CODE_CHECKOUT_QUEUED:
    case ActivityCodes.CODE_CHECKOUT_CREATED:
      return payload?.data?.AccessCodeData?.name ? (
        <ProfileIcon fullName={payload?.data?.AccessCodeData?.name} />
      ) : (
        <Avatar sx={normalStyles}>
          <CodeIcon />
        </Avatar>
      );
    case ActivityCodes.CODE_FAILED:
    case ActivityCodes.CODE_CHECKOUT_DELETED:
    case ActivityCodes.CODE_CHECKOUT_FAILED:
    case ActivityCodes.CODE_DELETED:
      return (
        <Avatar sx={alertStyles}>
          <CodeIcon />
        </Avatar>
      );
    case ActivityCodes.ROOM_STATUS_CLEANING_COMPLETE:
    case ActivityCodes.ROOM_STATUS_CLEANING_START:
    case ActivityCodes.ROOM_STATUS_CLEANING_STARTED:
    case ActivityCodes.ROOM_STATUS_CLEANING_ENDING:
    case ActivityCodes.ROOM_STATUS_GUEST_CHECK_IN:
    case ActivityCodes.ROOM_STATUS_GUEST_CHECK_OUT:
    case ActivityCodes.ROOM_STATUS_GUEST_CHECK_OUT_EXPIRY:
    case ActivityCodes.ROOM_STATUS_RESET_CLEAN_AVAILABLE:
      return (
        <Avatar sx={normalStyles}>
          <RoomStatusIcon />
        </Avatar>
      );
    case ActivityCodes.PROPERTY_STATUS:
      return (
        <Avatar sx={normalStyles}>
          <StatusIcon />
        </Avatar>
      );
    case ActivityCodes.LOCK_MANUALLY_LOCKED:
    case ActivityCodes.LOCK_BOLT_LOCKED:
    case ActivityCodes.LOCK_AUTO_LOCKED:
    case ActivityCodes.LOCK_KEYPAD_LOCKED:
    case ActivityCodes.LOCK_TAMPERED:
    case ActivityCodes.LOCK_ONLINE:
    case ActivityCodes.LOCK_ACTIVITY:
      return (
        <Avatar sx={normalStyles}>
          <LockIcon />
        </Avatar>
      );
    case ActivityCodes.LOCK_UNLOCKED_MANUALLY:
      return (
        <Avatar sx={alertStyles}>
          <LockIcon />
        </Avatar>
      );
    case ActivityCodes.BATTERY:
    case ActivityCodes.BATTERY_OK:
      return (
        <Avatar sx={normalStyles}>
          <Battery100Icon />
        </Avatar>
      );
    case ActivityCodes.BATTERY_WARNING:
      return (
        <Avatar sx={alertStyles}>
          <Battery30Icon />
        </Avatar>
      );
    case ActivityCodes.BATTERY_CRITICAL:
      return (
        <Avatar sx={alertStyles}>
          <Battery20Icon />
        </Avatar>
      );
    case ActivityCodes.BATTERY_FATAL:
      return (
        <Avatar sx={alertStyles}>
          <BatteryWarningIcon />
        </Avatar>
      );
    case ActivityCodes.THERMOSTAT_OCCUPIED_SETTING_SUCCESS:
    case ActivityCodes.THERMOSTAT_VACANT_SETTING_SUCCESS:
    case ActivityCodes.THERMOSTAT_SETBACK_SUCCESS:
      return (
        <Avatar sx={normalStyles}>
          <ThermostatIcon />
        </Avatar>
      );
    case ActivityCodes.RISK_ALERT_COMPLIANCE:
    case ActivityCodes.RISK_ALERT_ENVIRONMENT:
      return (
        <Avatar sx={alertStyles}>
          <RiskAlertIcon />
        </Avatar>
      );
    case ActivityCodes.RESERVATION_CHECKOUT:
    default:
      return (
        <Avatar sx={normalStyles}>
          <ReservationIcon />
        </Avatar>
      );
  }
};

const alertStyles: SxProps = {
  backgroundColor: 'error.main',
  color: 'white',
  '& svg': {
    color: 'white',
  },
  width: '36px',
  height: '36px',
  fontSize: '1rem',
  '.MuiSvgIcon-root': {
    width: '0.8em',
    height: '0.8em',
  },
};

const normalStyles: SxProps = {
  backgroundColor: 'info.main',
  color: 'white',
  '& svg': {
    color: 'white',
  },
  width: '36px',
  height: '36px',
  fontSize: '1rem',
  '.MuiSvgIcon-root': {
    width: '0.8em',
    height: '0.8em',
  },
};

export default IconForCode;
