import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import { EventCounts, EventDict, EventState, FilterType } from 'event/eventType';
import { Actions } from '../../types/actions';

export const initialEventState: EventDict = {};

const byId: Reducer<EventDict> = (state = initialEventState, action) => {
  switch (action.type) {
    case Actions.insertEvent:
      return {
        ...state,
        [action.event.id]: action.event,
      };
    case Actions.hydrateEvents:
      return action.eventDict;
    case Actions.bulkAddEvents:
      return { ...state, ...action.eventDict };
    default:
      return state;
  }
};

const list: Reducer<number[]> = (state = [], action: AnyAction) => {
  switch (action.type) {
    case Actions.insertEvent:
      return [...state, action.event.id];
    case Actions.hydrateEvents:
      return action.eventList;
    case Actions.bulkAddEvents:
      return [...state, ...action.eventList];
    default:
      return state;
  }
};

export const initalCounts: EventCounts = {
  [FilterType.ALL_ACTIVITIES]: {
    pageNum: 0,
    numPerPage: 20,
    totalFetched: 0,
  },
  [FilterType.TEAM]: { pageNum: 0, numPerPage: 20, totalFetched: 0 },
  [FilterType.ACCESS]: { pageNum: 0, numPerPage: 20, totalFetched: 0 },
  [FilterType.DEVICES]: { pageNum: 0, numPerPage: 20, totalFetched: 0 },
  [FilterType.GUESTS]: { pageNum: 0, numPerPage: 20, totalFetched: 0 },
  [FilterType.ALERTS]: { pageNum: 0, numPerPage: 20, totalFetched: 0 },
};

// This is shared between all events and events by property id, should be fine for now but might want to handle them separately
const meta: Reducer<EventCounts> = (state = initalCounts, action: AnyAction) => {
  switch (action.type) {
    case Actions.updateEventsCounts:
      return {
        ...state,
        [action.activityType]: action.meta,
      };
    default:
      return state;
  }
};

const reducer: Reducer<EventState> = combineReducers<EventState>({
  byId,
  list,
  meta,
});

export default reducer;
