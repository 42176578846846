import { Button, SxProps, Typography } from '@mui/material';
import { completeMemberRegistration } from 'api/userAPI';
import GlobalSnackbar from 'Common/Snackbar/GlobalSnackbar';
import { logger } from 'lib/logger';
import { PasswordData } from 'Pages/Account/TabContainers/ChangePasswordForm';
import AccessContainer from 'Pages/Login/AccessContainer';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { BaseContainer } from 'Style/Components/BaseComponents';
import styled from 'styled-components';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { FormTextField } from 'ui-library/Components/input/FormTextField';

declare const window: {
  location: {
    search: string | string[][] | Record<string, string> | URLSearchParams;
  };
};

const SetPasswordPage = () => {
  const {
    handleSubmit,
    control,
    watch,
    setError,
    formState: { isValid },
  } = useForm<PasswordData>({
    mode: 'all',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const validate = (password: string) => {
    if (password.length < 8 || password.length > 32) {
      return 'Invalid password: Password should be min 8 characters and max 32 characters';
    }

    if (password.match(/[A-Z]/g) === null) {
      return 'Invalid password: Password should contain at least one Capital Letter';
    }

    if (password.match(/[a-z]/g) === null) {
      return 'Invalid password: Password should contain at least one small Letter';
    }

    if (password.match(/\d/g) === null) {
      return 'Invalid password: Password should contain at least one number';
    }

    if (password.match(/[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]/g) === null) {
      return 'Invalid password: Password should contain at least one special character';
    }

    if (watch('password').length && watch('password') !== password) {
      return 'Invalid password: Password mismatch';
    }

    return true;
  };

  const onSubmit = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenEncoded = urlParams.get('sid') || '';

    try {
      await completeMemberRegistration(
        urlParams.get('email')?.replace(' ', '+') || '',
        tokenEncoded,
        watch('password'),
        watch('confirmPassword'),
      );

      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Your password has been saved. Redirecting you to login page... ',
          variant: SnackbarVariant.SUCCESS,
        }),
      );

      setTimeout(() => {
        const url = '/login';
        navigate(url);
      }, 3000);
    } catch (e) {
      setError('password', {
        type: 'manual',
        message: 'Failed to submit the form. Please try again later.',
      });
      logger.error(e);
    }
  };

  return (
    <AccessContainer>
      <ContentContainer>
        <GlobalSnackbar />
        <Typography variant='h2' sx={headerTextStyle} align='center'>
          Set Password
        </Typography>

        <FormTextField
          rules={{ validate }}
          field='password'
          type='password'
          label={'Password'}
          control={control}
          data-testid='password'
          sx={inputFieldStyle}
        />
        <FormTextField
          rules={{ validate }}
          field='confirmPassword'
          type='password'
          label={'Confirm Password'}
          control={control}
          data-testid='confirmPassword'
          sx={inputFieldStyle}
        />
        <Button
          color='primary'
          variant='contained'
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
          sx={submitButtonStyle}
        >
          Submit
        </Button>
      </ContentContainer>
    </AccessContainer>
  );
};

const headerTextStyle: SxProps = {
  '&&': {
    padding: '0px',
  },
};

const inputFieldStyle: SxProps = {
  marginTop: '5px !important',
};

const submitButtonStyle: SxProps = {
  '&&': {
    marginTop: '8px',
  },
};

const ContentContainer = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export default SetPasswordPage;
