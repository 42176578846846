import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { ActivateUnits } from '../../../types/ui';
import { Actions } from '../../../types/actions';

const showActivateUnitModal: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.activateUnits:
      return true;
    case Actions.hideActivateUnits:
      return false;
    default:
      return state;
  }
};

const howManyUnitsToActivate: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.activateUnits:
      return action.units;
    default:
      return state;
  }
};

const activateUnits: Reducer<ActivateUnits> = combineReducers<ActivateUnits>({
  show: showActivateUnitModal,
  numberOfUnits: howManyUnitsToActivate,
});

export default activateUnits;
