import * as React from 'react';

import { SxProps, Typography } from '@mui/material';
import FilledInput from '@mui/material/FilledInput';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';

export enum ThresholdInputs {
  FirstThreshold,
  SecondThreshold,
}
export interface ThresholdSettingProps {
  labels: string[];
  suffix?: string;
  values: number[];
  mode?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, field: ThresholdInputs) => void;
  onSave: (field: ThresholdInputs) => void;
}

const ThresholdSetting = ({
  labels,
  suffix,
  values = [0, 0],
  mode,
  onChange,
  onSave,
}: ThresholdSettingProps) => (
  <Box sx={containerStyle}>
    <Grid container spacing={3}>
      <Grid item lg={6}>
        <FilledInput
          sx={{ fontSize: '28px', color: mode === 'dark' ? 'text.primary' : 'white' }}
          data-testid='threshold-input-one'
          value={values[0]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange?.(e, ThresholdInputs.FirstThreshold);
          }}
          onBlur={() => {
            onSave?.(ThresholdInputs.FirstThreshold);
          }}
          type='number'
          onFocus={e => {
            e.target.select();
          }}
          endAdornment={
            <Typography sx={{ color: mode === 'dark' ? 'text.primary' : 'white' }}>
              {suffix}
            </Typography>
          }
        />
        <FormHelperText sx={{ color: mode === 'dark' ? 'text.primary' : 'white' }}>
          {labels[0]}
        </FormHelperText>
      </Grid>
      <Grid item lg={6}>
        <FilledInput
          sx={{ fontSize: '28px', color: mode === 'dark' ? 'text.primary' : 'white' }}
          data-testid='threshold-input-two'
          type='number'
          value={values[1]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange?.(e, ThresholdInputs.SecondThreshold);
          }}
          onBlur={() => {
            onSave?.(ThresholdInputs.SecondThreshold);
          }}
          onFocus={e => {
            e.target.select();
          }}
          endAdornment={
            <Typography sx={{ color: mode === 'dark' ? 'text.primary' : 'white' }}>
              {suffix}
            </Typography>
          }
        />
        <FormHelperText sx={{ color: mode === 'dark' ? 'text.primary' : 'white' }}>
          {labels[1]}
        </FormHelperText>
      </Grid>
    </Grid>
  </Box>
);

const containerStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  color: 'text',
  transition: 'all 500ms ease-out',
};

export default ThresholdSetting;
