import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { Actions } from '../../types/actions';
import { FilterType, GroupCounts, GroupDict, GroupState, GroupType } from '../../types/groups';

const initialListState: number[] = [];

const list: Reducer<number[]> = (state = initialListState, action) => {
  switch (action.type) {
    case Actions.upsertGroup:
      return Array.from(new Set([...state, action.group.id]));
    case Actions.hydrateGroups:
      return action.groupList;
    case Actions.insertGroups:
      return Array.from(new Set([...state, ...action.groupList]));
    case Actions.deleteGroup:
      return state.filter((each: number) => each !== action.id);
    default:
      return state;
  }
};

const initialGroupState: GroupDict = {};

const byId: Reducer<GroupDict> = (state = initialGroupState, action) => {
  switch (action.type) {
    case Actions.upsertGroup:
    case Actions.updateGroup:
      return {
        ...state,
        [action.group.id]: action.group,
      };
    case Actions.addTeamGroup:
      return {
        ...state,
        [action.group.id]: action.group,
      };
    case Actions.hydrateGroups:
      return action.groupDict;
    case Actions.insertGroups:
      return { ...state, ...action.groupDict };
    case Actions.deleteGroup:
      delete state[action.id];
      return state;
    case Actions.updateGroupAttachedUnits:
      return {
        ...state,
        [action.groupId]: {
          ...state[action.groupId],
          properties: [...action.unitIds, ...state[action.groupId].properties],
        },
      };
    case Actions.replaceGroupAttachedUnits:
      return {
        ...state,
        [action.groupId]: {
          ...state[action.groupId],
          properties: action.unitIds,
        },
      };
    default:
      return state;
  }
};

const initialMeta: GroupCounts = {
  [FilterType.PROPERTY]: {
    pageNum: 0,
    numPerPage: 50,
    total_records: 0,
  },
  [FilterType.ACCESS_GROUP]: {
    pageNum: 0,
    numPerPage: 50,
    total_records: 0,
  },
  [FilterType.ALL]: {
    pageNum: 0,
    numPerPage: 50,
    total_records: 0,
  },
};

const meta: Reducer<GroupCounts> = (state = initialMeta, action) => {
  switch (action.type) {
    case Actions.updateGroupCounts:
      return { ...state, [action.filterType]: action.meta };
    default:
      return state;
  }
};

const initialGroupTypeState: GroupType = { type: FilterType.PROPERTY };
const groupType: Reducer<GroupType> = (state = initialGroupTypeState, action) => {
  switch (action.type) {
    case Actions.updateGroupType:
      return { ...state, type: action.groupType };
    default:
      return state;
  }
};

const reducer: Reducer<GroupState> = combineReducers<GroupState>({
  byId,
  list,
  meta,
  groupType,
});

export default reducer;
