import { Box, Divider } from '@mui/material';
import { Loading } from 'Common/Loading';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { INITIAL_STATE, useGetGroupsQuery } from 'redux/groups/api-slice';
import { Group } from 'redux/groups/types';
import { useAppSelector } from 'redux/hooks';
import { MEMBERS_ROLES } from 'redux/members/types';
import { currentUserSelector } from 'user/state/userSelectors';
import { SplitView, SplitViewItemType } from '../../../Common/SplitView/SplitView';
import { GroupCell } from '../GroupCell';
import { GroupDetails } from './GroupDetails';

const groupCardCell = (group: Group, disableMenu: boolean) => {
  return (
    <Box key={group.id} sx={groupCellContainerStyle}>
      <GroupCell group={group} disableMenu={disableMenu} />
      <Divider />
    </Box>
  );
};

export const GroupSplitView = () => {
  const { data = INITIAL_STATE, isLoading } = useGetGroupsQuery();
  const navigate = useNavigate();
  const { groupId } = useParams<{ groupId: string }>();
  const user = useAppSelector(currentUserSelector());
  const disableCellMenu =
    user?.role === MEMBERS_ROLES.REGIONAL_MANAGER || user?.role === MEMBERS_ROLES.BASIC_USER;

  const onGroupCellClick = (group: Group) => {
    navigate(`/groups/${group.id}/units`);
  };

  const onCloseSplitView = () => {
    navigate('/groups');
  };

  const renderSplitView = () => {
    if (isLoading) {
      return <Loading />;
    }

    let selectedGroup: Group;
    const itemList: SplitViewItemType[] = [];
    data.groups.forEach(group => {
      if (group.id.toString() === groupId) {
        selectedGroup = group;
      }

      itemList.push({
        listItem: groupCardCell(group, disableCellMenu),
        splitViewData: group,
      });
    });

    return (
      <SplitView
        itemList={itemList}
        detailComponent={selectedGroup && <GroupDetails group={selectedGroup} />}
        selectedItemId={groupId}
        onClose={onCloseSplitView}
        onItemSelected={onGroupCellClick}
      />
    );
  };

  return renderSplitView();
};

const groupCellContainerStyle = {
  '&:hover': {
    cursor: 'pointer',
  },
};
