import { SxProps, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import * as React from 'react';

export interface AdderCardProps {
  noPaddingContent?: boolean;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  action?: React.ReactNode;
  children?: React.ReactNode;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
  onSaveClick?: () => void;
  footerChildren?: React.ReactNode;
  showFooter?: boolean;
  bodyHeight?: number;
  headerChildren?: React.Component | Element;
  dimmed?: boolean;
  cardStyle?: SxProps;
}

const AddCard = ({
  noPaddingContent,
  action,
  title,
  subtitle,
  onNextClick,
  onPreviousClick,
  onSaveClick,
  children,
  showFooter: footer,
  footerChildren,
  bodyHeight,
  headerChildren,
  dimmed,
  cardStyle,
}: AdderCardProps) => (
  <Card
    sx={{
      ...cardStyle,
      width: '100%',
      borderRadius: '10px',
      border: '0.5px solid #d4d4d4',
      marginBottom: '20px',
    }}
  >
    <>
      {headerChildren && headerChildren}
      {title && (
        <CardHeader
          sx={{
            textAlign: 'start',
            borderBottom: '0.5px solid #d4d4d4',
            '& .MuiCardHeader-action': {
              margin: 'auto',
            },
            '& .MuiCardHeader-content': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            },
          }}
          title={<Typography variant='subtitle1'>{title}</Typography>}
          subheader={subtitle && <Typography variant='subtitle2'>{subtitle}</Typography>}
          action={action}
        />
      )}

      <CardContent
        sx={{
          padding: noPaddingContent ? '0px' : '24px',
          borderRadius: 'none !important',
          fontSize: '14px',
          minHeight: bodyHeight && `${bodyHeight}px`,
          opacity: dimmed ? 0.3 : 1,
        }}
      >
        {children}
      </CardContent>
      {footer && (
        <CardActions sx={{ ...FooterContainerSxProps, opacity: dimmed ? 0.3 : 1 }}>
          {onPreviousClick && (
            // @ts-ignore
            <Button onClick={onPreviousClick} variant='contained' color='primary'>
              Previous
            </Button>
          )}
          {onNextClick && (
            // @ts-ignore
            <Button onClick={onNextClick} variant='contained' color='primary'>
              Next
            </Button>
          )}
          {onSaveClick && (
            // @ts-ignore
            <Button onClick={onSaveClick} variant='contained' color='primary'>
              Save
            </Button>
          )}
        </CardActions>
      )}
      {footerChildren && (
        <CardActions sx={{ ...FooterContainerSxProps, opacity: dimmed ? 0.3 : 1 }}>
          {footerChildren}
        </CardActions>
      )}
    </>
  </Card>
);

const FooterContainerSxProps: SxProps = {
  borderTop: '0.5px solid #d4d4d4',
  padding: '12px 18px',
  display: 'flex',
  justifyContent: 'end',
};

export default AddCard;
