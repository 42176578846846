import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { MoreReservations } from '../../../types/ui';
import { Actions } from '../../../types/actions';

const show: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.hideViewMoreReservations:
      return false;
    case Actions.showViewMoreReservations:
      return !state;
    default:
      return state;
  }
};
const reservationIds: Reducer<number[]> = (state = [], action) => {
  switch (action.type) {
    case Actions.showViewMoreReservations:
      return action.reservationIds;
    default:
      return state;
  }
};
const title: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.showViewMoreReservations:
      return action.title;
    default:
      return state;
  }
};
const showCodeStatus: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.showViewMoreReservations:
      return action.showCodeStatus;
    default:
      return state;
  }
};

const moreReservations: Reducer<MoreReservations> = combineReducers<MoreReservations>({
  show,
  reservationIds,
  title,
  showCodeStatus,
});

export default moreReservations;
