import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { IntercomDict, IntercomState } from 'intercom/intercomType';
import { Actions } from '../../types/actions';

const initialEventState: IntercomDict = {};

const byPropertyIds: Reducer<IntercomDict> = (state = initialEventState, action) => {
  switch (action.type) {
    case Actions.upsertIntercom:
      return {
        ...state,
        [action.propertyId]: action.intercom,
      };
    default:
      return state;
  }
};

const reducer: Reducer<IntercomState> = combineReducers<IntercomState>({
  byPropertyIds,
});

export default reducer;
