import { Stack, Typography } from '@mui/material';
import { TemplateDataType, TriggerDataType, WorkflowDataType } from '@operto/communications-shared';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import { Loading } from 'Common/Loading';
import { TriggerType, TriggerTypeOption, useAutomate } from 'hooks/useAutomate';
import useCommunications from 'hooks/useCommunications';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { INITIAL_STATE, useGetUnitsQuery } from 'redux/units/api-slice';
import { UnitFilterType } from 'redux/units/types';
import AutomateDeleteConfirmDialog from '../AutomateDeleteConfirmDialog';
import AutomateWorkflowDiagram from './AutomateWorkflowDiagram';
import AutomateWorkflowTitlebar from './AutomateWorkflowTitlebar';
import AutomateWorkflowTriggerPanel from './AutomateWorkflowTriggerPanel';
import AutomateWorkflowUnits from './AutomateWorkflowUnits';

export default function AutomateWorkflowPage() {
  const { workflowId } = useParams();
  const {
    workflows,
    createWorkflow,
    updateWorkflow,
    automateRefetch,
    isLoading: isAutomateLoading,
  } = useAutomate();
  const { data: templates, isLoading: isTemplatesLoading } = useCommunications();
  const { t } = useTranslation();
  type translationKeyType = Parameters<typeof t>[0];
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data: units = INITIAL_STATE, isFetching: unitsFetching } = useGetUnitsQuery({
    filterType: UnitFilterType.ALL_UNITS,
    numPerPage: 100000,
  });

  const [workflow, setWorkflow] = useState<WorkflowDataType>({
    name: initialName,
    enabled: false,
    conditions: {
      selectedProperties: [],
    },
    triggers: [],
  });

  const [errorName, setErrorName] = useState<string>();
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [assignToUnitsError, setAssignToUnitsError] = useState(false);
  const [selectedTrigger, setSelectedTrigger] = useState<number>();
  const [errorDialog, setErrorDialog] = useState<{
    titleKey: translationKeyType;
    bodyKey: translationKeyType;
  }>();
  const [showTriggerError, setShowTriggerError] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const isCreate = pathname.includes('create');
  const invalidWorkflow = !!errorName;
  const selectedUnits = (workflow.conditions?.selectedProperties || []) as number[];
  const triggerCount = (workflow.triggers?.length || 0) as number;
  const hasTriggersNoSchedule = workflow.triggers.some(
    (trigger: TriggerDataType) =>
      trigger.type !== TriggerTypeOption.CHECK_IN && trigger.offset === 0,
  );
  const hasTriggersNoTemplate = workflow.triggers.some(
    (trigger: TriggerDataType) =>
      trigger.templates.sms.length === 0 && trigger.templates.email.length === 0,
  );
  const hasInvalidTriggers = hasTriggersNoSchedule || hasTriggersNoTemplate || !triggerCount;
  const isLoading = isAutomateLoading || isTemplatesLoading;

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteSuccess = () => {
    setIsDeleteDialogOpen(false);
    navigate('/automate');
    snackbar(t('deleted'));
  };

  const handleDeleteFailure = (error: Error) => {
    logger.error(error);
  };

  const validateNameChange = useCallback(
    (name: string) => {
      const trimmedName = name.trim();

      if (workflows?.some(c => c.name.trim() === trimmedName && c.id !== workflow.id)) {
        setErrorName(t('name_taken'));
        return false;
      }

      if (trimmedName.length === 0) {
        setErrorName(t('name_required'));
        return false;
      }

      if (trimmedName.length > MAX_NAME_LENGTH) {
        setErrorName(`${trimmedName.length}/${MAX_NAME_LENGTH}`);
        return false;
      }

      setErrorName(undefined);
      return true;
    },
    [workflows, workflow.id, t],
  );

  const handleSaveConfirm = async () => {
    // if failed validation, show errors and do not proceed
    if (!validateNameChange(workflow.name) || invalidWorkflow) {
      return;
    }

    setIsSaving(true);

    try {
      const newWorkflow = {
        ...workflow,
        triggers: workflow.triggers.map((trigger: TriggerDataType) => ({
          ...trigger,
          templates: {
            sms: trigger.templates.sms.map((template: TemplateDataType) => template.id),
            email: trigger.templates.email.map((template: TemplateDataType) => template.id),
          },
        })),
      };

      const newRecord = await (isCreate ? createWorkflow : updateWorkflow)(newWorkflow);

      await automateRefetch();
      navigate(`/automate/edit/${workflow.id || newRecord.id}`);

      snackbar(t('changes_saved'));

      setIsSaving(false);
      setEditMode(false);
    } catch (error) {
      logger.debug(error);
      snackbar(t('error_saving'));
    }
  };

  const handleEnableWorkflowClick = async () => {
    if (selectedUnits.length === 0) {
      setAssignToUnitsError(true);
      return;
    }

    if (hasInvalidTriggers) {
      if (triggerCount) {
        setShowTriggerError(workflow.triggers.map((_: unknown, i: number) => i));

        setErrorDialog({
          titleKey: 'required_fields_are_missing',
          bodyKey: 'please_update_your_flow_to_run_the_template',
        });

        return;
      }

      setErrorDialog({
        titleKey: 'event_trigger_required',
        bodyKey: 'at_least_one_trigger_to_activate',
      });

      return;
    }

    setShowTriggerError([]);
    setAssignToUnitsError(false);
    setWorkflow((workflow: WorkflowDataType) => ({ ...workflow, enabled: !workflow.enabled }));
  };

  const handleSelectingUnits = useCallback((units: number[]) => {
    setWorkflow((workflow: WorkflowDataType) => ({
      ...workflow,
      enabled: false,
      conditions: {
        selectedProperties: units,
      },
    }));

    setAssignToUnitsError(false);
  }, []);

  const handleClickBefore = () => {
    setWorkflow((workflow: WorkflowDataType) => {
      setSelectedTrigger(workflow?.triggers?.length ?? 0);

      return {
        ...workflow,
        enabled: false,
        triggers: [
          ...workflow.triggers,
          {
            type: TriggerTypeOption.BEFORE_CHECK_IN,
            offset: 0,
            templates: {
              sms: [],
              email: [],
            },
          },
        ],
      };
    });
  };

  const handleClickedAfter = () => {
    setWorkflow((workflow: WorkflowDataType) => {
      setSelectedTrigger(workflow?.triggers?.length ?? 0);

      return {
        ...workflow,
        enabled: false,
        triggers: [
          ...workflow.triggers,
          {
            type: 'CHECK_IN',
            offset: 0,
            templates: {
              sms: [],
              email: [],
            },
          },
        ],
      };
    });
  };

  const handleDeleteTrigger = () => {
    setWorkflow((workflow: WorkflowDataType) => {
      const newTriggers = [...workflow.triggers];
      newTriggers.splice(selectedTrigger, 1);

      return {
        ...workflow,
        enabled: false,
        triggers: newTriggers,
      };
    });

    setShowTriggerError(showTriggerError.filter((i: number) => i !== selectedTrigger));
    setSelectedTrigger(undefined);
  };

  const handleOffsetChange = (offset: number) => {
    setWorkflow((workflow: WorkflowDataType) => {
      const newTriggers = [...workflow.triggers];
      newTriggers[selectedTrigger].offset = offset;

      return {
        ...workflow,
        enabled: false,
        triggers: newTriggers,
      };
    });
  };

  const handleTemplateChange = (channelType: string, template?: TemplateDataType) => {
    setWorkflow((workflow: WorkflowDataType) => {
      const newTriggers = [...workflow.triggers];

      if (channelType === 'email') {
        newTriggers[selectedTrigger].templates.email = template ? [template] : [];
      }

      if (channelType === 'sms') {
        newTriggers[selectedTrigger].templates.sms = template ? [template] : [];
      }

      return {
        ...workflow,
        enabled: false,
        triggers: newTriggers,
      };
    });
  };

  const handleTypeChange = (newType: TriggerType) => {
    setWorkflow((workflow: WorkflowDataType) => {
      const newTriggers = [...workflow.triggers];
      newTriggers[selectedTrigger].type = newType;

      return {
        ...workflow,
        enabled: false,
        triggers: newTriggers,
      };
    });
  };

  useEffect(() => {
    // Set selected units with all units as default
    if (isCreate && units.units.length > 0) {
      handleSelectingUnits(units.units.map(unit => unit.id));
    }
  }, [units, handleSelectingUnits, isCreate]);

  useEffect(() => {
    const loadWorkflow = async () => {
      if (!isCreate && templates && workflows && workflowId && !workflow.id) {
        const currentWorkflow = workflows?.find(
          (workflow: WorkflowDataType) => workflow.id === workflowId,
        );

        setWorkflow((workflow: WorkflowDataType) => ({
          ...workflow,
          ...(currentWorkflow || {}),
          triggers: currentWorkflow.triggers.map((trigger: TriggerDataType) => ({
            ...trigger,
            templates: {
              sms:
                trigger.templates?.sms?.map((templateId: string) =>
                  templates.find(t => t.id === templateId),
                ) || [],
              email:
                trigger.templates?.email?.map((templateId: string) =>
                  templates.find(t => t.id === templateId),
                ) || [],
            },
          })),
        }));
      }
    };

    loadWorkflow();
  }, [templates, isCreate, workflowId, workflows, workflow.id]);

  if (!isCreate && isLoading) {
    return <Loading />;
  }

  return (
    <>
      <AutomateWorkflowTitlebar
        title={workflow.name}
        onTitleChange={name => {
          validateNameChange(name);
          setWorkflow({ ...workflow, name, enabled: false });
        }}
        onSaveClick={handleSaveConfirm}
        onDeleteClick={handleDeleteClick}
        deleteDisabled={!workflow.id}
        helperText={errorName}
        editMode={editMode}
        setEditMode={setEditMode}
        onEnableWorkflowClick={handleEnableWorkflowClick}
        isSaving={isSaving}
        isWorkflowEnabled={workflow.enabled && !hasInvalidTriggers}
      />

      <AutomateWorkflowUnits
        selectedUnits={selectedUnits}
        setSelectedUnits={handleSelectingUnits}
        assignToUnitsError={assignToUnitsError}
        units={units}
        unitsFetching={unitsFetching}
      />

      <Stack sx={{ flexDirection: 'row', gap: '24px' }}>
        <AutomateWorkflowDiagram
          triggers={workflow.triggers}
          onClickBefore={handleClickBefore}
          onClickAfter={handleClickedAfter}
          hideAdd={triggerCount >= MAX_TRIGGERS}
          selectedCard={selectedTrigger}
          onCardClick={offset => setSelectedTrigger(offset)}
          showTriggerError={showTriggerError}
        />

        {selectedTrigger !== undefined && (
          <AutomateWorkflowTriggerPanel
            onClose={() => setSelectedTrigger(undefined)}
            onDelete={handleDeleteTrigger}
            trigger={
              workflow.triggers.find((_: unknown, i: number) => i === selectedTrigger) || [
                {
                  offset: 0,
                },
              ]
            }
            onOffsetChange={handleOffsetChange}
            onTypeChange={handleTypeChange}
            onTemplateChange={handleTemplateChange}
            showErrorMessages={showTriggerError.some((i: number) => i === selectedTrigger)}
          />
        )}
      </Stack>

      <ConfirmDialog
        open={!!errorDialog}
        onSubmit={() => setErrorDialog(undefined)}
        title={
          <Typography variant='h3-700' sx={{ padding: '16px 24px' }}>
            {errorDialog ? t(errorDialog?.titleKey) : ''}
          </Typography>
        }
        submitButtonText={'OK'}
        rootStyles={{ '& div[role=dialog]': { maxWidth: '444px', borderRadius: '10px' } }}
        contentStyles={{ padding: '8px 24px' }}
        actionsStyles={{ padding: '8px 16px 16px 8px' }}
      >
        <Typography variant='body-lg-400'>{errorDialog ? t(errorDialog?.bodyKey) : ''}</Typography>
      </ConfirmDialog>

      {isDeleteDialogOpen && (
        <AutomateDeleteConfirmDialog
          workflowId={workflowId}
          onClose={handleCloseDialog}
          onSuccess={handleDeleteSuccess}
          onFailure={handleDeleteFailure}
        />
      )}
    </>
  );
}

const initialName = 'Untitled flow';
const MAX_NAME_LENGTH = 50;
const MAX_TRIGGERS = 2;
