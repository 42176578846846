import { Divider, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useCallback, useState } from 'react';
import { Group } from 'redux/groups/types';
import { SelectionTable, SelectionTableProps } from 'ui-library/Components/table/SelectionTable';
import { TableCell } from 'ui-library/Components/table/TableCell';

export const renderGroupName = ({ row }: GridRenderCellParams<unknown, Group>) => {
  return <TableCell title={row.name} testId={`group-cell-${row.id}`} />;
};

export const renderNumberOfUnits = ({ row }: GridRenderCellParams<unknown, Group>) => {
  return <TableCell title={`${row.propertyIds.length} units`} />;
};

const columnsDef: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Groups',
    flex: 1,
    renderCell: renderGroupName,
    sortable: false,
  },
];

type GroupsSelectionTableProps = Omit<SelectionTableProps, 'rows' | 'columns' | 'onFetch'> & {
  rows: Group[];
  columns?: GridColDef[];
  tableInfo?: string;
};

export const GroupsSelectionTable = ({
  rows: groups,
  tableInfo,
  ...rest
}: GroupsSelectionTableProps) => {
  const [searchValue, setSearchValue] = useState<string>();
  const filteredList = groups.filter(
    group => !searchValue || group.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const renderTableInfo = () => {
    return (
      <>
        <Typography variant='subtitle-lg-600' sx={{ padding: '16px' }}>
          {tableInfo}
        </Typography>
        <Divider />
      </>
    );
  };

  const handleFetch = useCallback((_pageNum: number, _numPerPage: number, searchValue?: string) => {
    setSearchValue(searchValue);
  }, []);

  return (
    <SelectionTable
      searchLabel='Enter group name'
      rows={filteredList}
      columns={columnsDef}
      onFetch={handleFetch}
      sortModel={[{ field: 'name', sort: 'asc' }]}
      sx={{ height: '100%' }}
      renderTableInfo={tableInfo && renderTableInfo()}
      {...rest}
    />
  );
};
