import { MenuItem } from '@mui/material';
import { getCurrentMember } from 'member/state/memberActions';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ISection, useGuestPortal } from 'redux/guestportal';
import { useAppDispatch } from 'redux/hooks';
import { PageHeader, PageHeaderSelect } from 'ui-library/Styled/containers';
import GuidesDetails from './GuidesDetails';
import GuidesTable from './GuidesTable';

export enum FilterType {
  INDEX,
  PAGES,
  CATEGORIES,
  ORDERING,
}

interface GuidesProps {
  filter?: FilterType;
}

export const GuidesTabPage = ({ filter = FilterType.INDEX }: GuidesProps) => {
  const { propertyId: pidStr, guideId } = useParams();
  const propertyId = Number(pidStr);
  const { sectionsList } = useGuestPortal();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCurrentMember());
  }, [propertyId, dispatch]);

  if (filter === FilterType.INDEX) {
    return <GuidesTable />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderSelect
          value={guideId || ''}
          onChange={event => {
            const sectionId = event.target.value as string;
            if (propertyId) {
              navigate(`/units/${propertyId}/guest-portal/guides/${sectionId}/pages`);
            } else {
              navigate(`/guest-portal/guides/${sectionId}/pages`);
            }
          }}
        >
          {sectionsList?.map((sectionsList: ISection, index: number) => (
            <MenuItem value={sectionsList.section_id} key={index}>
              {sectionsList.section}
            </MenuItem>
          ))}
        </PageHeaderSelect>
      </PageHeader>
      <GuidesDetails />
    </>
  );
};

export default GuidesTabPage;
