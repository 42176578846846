import { Link, Typography } from '@mui/material';
import InfoDialog from 'Common/Dialog/InfoDialog';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

export interface CommunicationsTemplateInfoDialogProps {
  open: boolean;
  onClose: () => void;
  anchorEl: null | HTMLElement;
}

const CommunicationsTemplateInfoDialog = ({
  open,
  onClose,
  anchorEl,
}: CommunicationsTemplateInfoDialogProps) => {
  const { t } = useTranslation();

  const paragraphOneText = (
    <Typography variant='body1'>{t('communications_info_dialog_paragraph_one_text')}</Typography>
  );

  const guideUrl = 'https://support.operto.com/s/article/Communications';
  const guideLink = (
    <Link href={guideUrl} target='_blank' rel='noopener'>
      {t('guide')}
    </Link>
  );

  const paragraphTwoText = (
    <Typography component='div' variant='body1'>
      {t('communications_info_dialog_paragraph_two_text', { guideLink: guideLink })}
    </Typography>
  );

  return (
    <InfoDialog
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      title={t('templates')}
      paragraphOneTitle={t('language_options')}
      paragraphOneText={paragraphOneText}
      paragraphTwoTitle={t('variables')}
      paragraphTwoText={paragraphTwoText}
    />
  );
};

export default CommunicationsTemplateInfoDialog;
