import { Box } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { useTopBarStyles } from './useTopBarStyles';

export interface PageTitleBarProps {
  children?: React.ReactNode;
}

const PageTitleBar = ({ children }: PageTitleBarProps) => {
  const { toolbarStyles } = useTopBarStyles();

  if (!children) {
    return null;
  }

  return (
    <Toolbar sx={toolbarStyles} disableGutters>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', width: '100%' }}>
          {children}
        </Box>
      </Box>
    </Toolbar>
  );
};

export default PageTitleBar;
