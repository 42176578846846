import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App/App';
import './index.css';
import * as serviceWorker from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // we should add strict here for react 18 but we are not ready, it is throwing exception in MUI - [JO]
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
