import take from 'lodash/take';
import { createSelector } from 'reselect';
import {
  getNoiseStatus,
  getOccupancyStatus,
} from '../../Common/Tables/Properties/PropertyDataHelpers';
import {
  Property,
  PropertyFilterType,
  PropertyGroupType,
  PropertyState,
} from '../../property/propertyType';
import { ApplicationState } from '../../redux/reducers';
import { currentUserSelector } from '../../user/state/userSelectors';

export const getProperties = (state: ApplicationState) => state.properties;
const getDevices = (state: ApplicationState) => state.devices;
const getActiveAlerts = (state: ApplicationState) => state.activeAlerts;
const getCommonProperties = (state: ApplicationState) =>
  state.properties.meta[PropertyFilterType.ALL_PROPERTIES].ids
    .map(id => state.properties.byId[id])
    .filter(unit => unit.room_status !== 'requires-activation' && unit.lock_enabled);

export const getPropertiesListSelector = () =>
  createSelector([getProperties], properties => {
    return properties.meta[PropertyFilterType.ALL_PROPERTIES].ids;
  });

export const getPropertiesByFilter = ({
  filterType,
  groupType,
  locationType,
}: {
  filterType?: PropertyFilterType;
  groupType?: PropertyGroupType;
  locationType?: 'accessGroup';
}) => {
  return createSelector(
    [getProperties, getDevices, getActiveAlerts, currentUserSelector()],
    (properties, devices, activeAlerts, currentUser) => {
      const propertiesWReadings: Property[] = [];
      properties.meta[filterType].ids.forEach((id: number) => {
        const property = { ...properties.byId[id] };
        if (locationType && locationType !== property.locationType) {
          return;
        }

        if (
          groupType &&
          groupType === PropertyGroupType.GUEST &&
          property.group_type !== groupType
        ) {
          return;
        }

        if (
          filterType &&
          filterType === PropertyFilterType.ALL_PROPERTIES &&
          !property.lock_enabled
        ) {
          return;
        }

        if (
          filterType &&
          filterType === PropertyFilterType.ALERT &&
          property.room_status !== 'risky'
        ) {
          return;
        }

        if (property.type !== filterType) {
          return;
        }

        // properties that passes the filters
        const deviceId = devices.listByPropertyId[property.id]?.[0];
        if (deviceId) {
          const device = devices.byId[deviceId];
          property.temperature = device.data.temperature as number;

          // @ts-ignore: revisit this, what is active vs merge alerts mean?
          property.noiseLevel = getNoiseStatus(activeAlerts, property, device);

          // @ts-ignore: revisit this, what is active vs merge alerts mean?
          property.occupancyLevel = getOccupancyStatus(activeAlerts, property);
        }

        // @ts-ignore: TODO: add this is_celsius to Property type

        property.is_celsius = currentUser?.is_celsius;
        propertiesWReadings.push(property);
      });

      return {
        properties: propertiesWReadings,
        isLoading: properties.meta.isLoading,
        totalProperties: properties.meta[filterType].total_records,
      };
    },
  );
};

export const getPropertiesWithFilter = (type: PropertyFilterType) =>
  createSelector(
    [getPropertiesWithReadings(), getCommonProperties],
    (propertiesWithReadings, commonProperties) => {
      switch (type) {
        case PropertyFilterType.ALL_PROPERTIES:
          return propertiesWithReadings.filter(
            (property: Property) => property.lock_enabled === true,
          );
        case PropertyFilterType.ACTIVE_TODAY:
          return propertiesWithReadings.filter(
            (property: Property) => property.type === PropertyFilterType.ACTIVE_TODAY,
          );
        case PropertyFilterType.VACANT:
          return propertiesWithReadings.filter(
            (property: Property) => property.type === PropertyFilterType.VACANT,
          );
        case PropertyFilterType.UNACTIVATED:
          return propertiesWithReadings.filter(
            (property: Property) => property.type === PropertyFilterType.UNACTIVATED,
          );
        case PropertyFilterType.COMMON:
          return commonProperties;
        case PropertyFilterType.ALERT:
          return propertiesWithReadings.filter(
            (property: Property) => property.room_status === 'risky',
          );
        case PropertyFilterType.ROOM_TYPE:
          return propertiesWithReadings
            .filter((property: Property) => property.property_type === PropertyFilterType.ROOM_TYPE)
            .map(roomType => {
              return {
                ...roomType,
                id: roomType.id,
                room_type: roomType.room_type,
                description: roomType.display_name,
              };
            });
        case PropertyFilterType.PUBLIC:
          return propertiesWithReadings.filter(
            (property: Property) => property.group_type === 'public',
          );
        case PropertyFilterType.ACCESS_GROUP:
          return propertiesWithReadings.filter(
            (property: Property) => property.locationType === 'accessGroup',
          );
        default:
          return propertiesWithReadings;
      }
    },
  );

export const getPropertiesWithReadings = () => {
  return createSelector(
    [getProperties, getDevices, getActiveAlerts, currentUserSelector()],
    (properties, devices, activeAlerts, currentUser) => {
      const propertiesWReadings: Property[] = [];
      properties.meta[PropertyFilterType.ALL_PROPERTIES].ids.map((propertyId: number) => {
        const property = { ...properties.byId[propertyId] };
        const deviceId = devices.listByPropertyId[propertyId]?.[0];
        if (deviceId) {
          const device = devices.byId[deviceId];
          property.temperature = device.data.temperature as number;

          // @ts-ignore: revisit this, what is active vs merge alerts mean?
          property.noiseLevel = getNoiseStatus(activeAlerts, property, device);

          // @ts-ignore: revisit this, what is active vs merge alerts mean?
          property.occupancyLevel = getOccupancyStatus(activeAlerts, property);
        }

        // @ts-ignore: TODO: add this is_celsius to Property type
        property.is_celsius = currentUser?.is_celsius;
        propertiesWReadings.push(property);
      });
      return propertiesWReadings;
    },
  );
};

export const getProperty = (propertyId: number) => {
  return createSelector(
    [getProperties],
    (properties: PropertyState) => properties?.byId[propertyId],
  );
};

export const getPropertiesFromIds = (propertyIds: number[]) => {
  return createSelector([getProperties], (properties: PropertyState) => {
    return propertyIds.map(propertyId => properties?.byId[propertyId]);
  });
};

export const getPropertiesByRoomStatus = (roomStatus: string, limit = 1000) =>
  createSelector([getProperties], (properties: PropertyState) =>
    take(
      properties?.meta[PropertyFilterType.ALL_PROPERTIES].ids
        .map(propertyId => properties.byId[propertyId])
        .filter(property => property.room_status === roomStatus),
      limit,
    ),
  );

export const FavouriteSelector = () =>
  createSelector([getProperties], (properties: PropertyState) =>
    properties?.meta[PropertyFilterType.FAVOURITES].ids
      .map(propertyId => properties?.byId[propertyId])
      .filter((property: Property) => property.isFavourite === true),
  );
export const isFavouriteProperty = (propertyId: number) =>
  createSelector([getProperties], (properties: PropertyState) =>
    properties?.meta[PropertyFilterType.FAVOURITES].ids.includes(propertyId),
  );

export const PropertyMetaSelector = (type: PropertyFilterType) =>
  createSelector([getProperties], (properties: PropertyState) => properties.meta[type]);
// export const getMemberFavoriteProperties = createSelector(
//   [getFavoriteProperties],
//   (favoriteProperties: FavoritePropertyState, properties: PropertyDict) => {
//     console.log(favoriteProperties);
//     const allFavoriteProperties = favoriteProperties.list
//       .map((id: number) => properties?.[id] && properties)
//       .filter((each: Property) => each);
//     return allFavoriteProperties;
//   },
// );

export const getAllPropertiesSelector = () =>
  createSelector([getProperties], (properties: PropertyState) =>
    properties?.meta[PropertyFilterType.ALL_PROPERTIES].ids
      .map((each: number) => properties.byId[each])
      .filter((each: Property) => each.lock_enabled && each.room_status !== 'requires-activation'),
  );

export const getAllPropertiesIdsWithNamesSelector = () =>
  createSelector([getProperties], (properties: PropertyState) =>
    properties?.meta[PropertyFilterType.ALL_PROPERTIES].ids.map((propertyId: number) => ({
      id: propertyId,
      name: properties.byId[propertyId].name,
    })),
  );

export default getProperty;
