import { CheckCircle, FlagCircle, RotateRight, SlowMotionVideo } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@operto/ui';
import React from 'react';
import { VERIFICATION_STATUS_TYPE, VerificationStatusText } from 'reservation/reservationType';

const AccessCodeColorMapping = {
  [VERIFICATION_STATUS_TYPE.PENDING]: {
    icon: (
      <RotateRight
        sx={{
          width: '20px',
          height: '20px',
          color: '#6554C0',
        }}
      />
    ),
  },
  [VERIFICATION_STATUS_TYPE.FLAGGED]: {
    icon: (
      <FlagCircle
        sx={{
          width: '20px',
          height: '20px',
          color: '#EB6B6B',
        }}
      />
    ),
  },
  [VERIFICATION_STATUS_TYPE.MANUALLY_FLAGGED]: {
    icon: (
      <RotateRight
        sx={{
          width: '20px',
          height: '20px',
          color: '#206A83',
        }}
      />
    ),
  },
  [VERIFICATION_STATUS_TYPE.CONFIRMED_BY_FLOW]: {
    icon: (
      <SlowMotionVideo
        sx={{
          width: '20px',
          height: '20px',
          color: '#717DA1',
        }}
      />
    ),
  },
  [VERIFICATION_STATUS_TYPE.MANUALLY_CONFIRMED]: {
    icon: (
      <CheckCircle
        sx={{
          width: '20px',
          height: '20px',
          color: '#20AF84',
        }}
      />
    ),
  },
  [VERIFICATION_STATUS_TYPE.CONFIRMED]: {
    icon: (
      <CheckCircle
        sx={{
          width: '20px',
          height: '20px',
          color: '#20AF84',
        }}
      />
    ),
  },
  [VERIFICATION_STATUS_TYPE.IN_REVIEW]: {
    icon: (
      <SlowMotionVideo
        sx={{
          width: '20px',
          height: '20px',
          color: '#717DA1',
        }}
      />
    ),
  },
} as {
  [K in VERIFICATION_STATUS_TYPE]: {
    color: string;
    icon: React.ReactNode;
  };
};

export const VerificationStatusView = (params: GridRenderCellParams) => {
  const status: VERIFICATION_STATUS_TYPE = params.row.verification_status;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '4px',
        padding: '4px 0px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        width={20}
        height={20}
        alignItems='center'
        display='flex'
        padding='2px'
        marginRight='4px'
      >
        {AccessCodeColorMapping[status]?.icon ?? (
          <SlowMotionVideo
            sx={{
              width: '20px',
              height: '20px',
              color: '#717DA1',
            }}
          />
        )}
      </Box>
      <Stack direction='column'>
        <Text.BodySmall>{VerificationStatusText[status] ?? 'N/A'}</Text.BodySmall>
        {status?.includes('manually') && (
          <Typography sx={{ fontSize: '10px', lineHeight: '143%', fontWeight: 400 }}>
            (Manually)
          </Typography>
        )}
      </Stack>
    </Box>
  );
};
