import { Box, SxProps, Theme } from '@mui/material';
import { Text } from '@operto/ui';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
  ConnectDocuments,
  ConnectPayments,
  ConnectPayouts,
} from '@stripe/react-connect-js';
import { logger } from 'lib/logger';
import React from 'react';

const api = process.env.REACT_APP_NEO_REST_API;
const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const fetchClientSecret = async () => {
  const response = await fetch(`${api}/payments/account-session/acct_1P1y79GTY7x9yBGm`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const responseData = await response.json();
  let secret: string = JSON.stringify(responseData.data);
  secret = secret.replaceAll('"', '');
  logger.info('responseData: ' + secret);
  return secret;
};

const TransactionsPage = () => {
  const connectInstance = loadConnectAndInitialize({
    publishableKey: publishableKey,
    fetchClientSecret: fetchClientSecret,
    appearance: {
      variables: {
        colorPrimary: '#228403', //optional appearance param,
      },
    },
  });

  return (
    <>
      <ConnectComponentsProvider connectInstance={connectInstance}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Text variant='h3-700' style={topTitleStyle}>
            {'Connect Payments'}
          </Text>
          <ConnectPayments />

          <Text variant='h3-700' style={titleStyles}>
            {'Connect Payouts'}
          </Text>
          <ConnectPayouts />

          <Text variant='h3-700' style={titleStyles}>
            {'Connect Account Onboarding'}
          </Text>
          <ConnectAccountOnboarding onExit={null} />

          <Text variant='h3-700' style={titleStyles}>
            {'Connect Documents'}
          </Text>
          <ConnectDocuments />
        </Box>
      </ConnectComponentsProvider>
    </>
  );
};

const titleStyles: SxProps<Theme> = {
  mx: 1.5,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  marginTop: 8,
  WebkitBoxOrient: 'vertical',
};

const topTitleStyle: SxProps<Theme> = {
  mx: 1.5,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
};

export default TransactionsPage;
