import ErrorIcon from '@mui/icons-material/Error';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Avatar, Box, CircularProgress, Grid, Popover, SxProps } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import LockFooter from 'device/SmartCards/LockFooter';
import { BatteryStatus, IDevice, ILock } from 'device/deviceType';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import BatteryIcon from 'ui-library/Components/icon/BatteryIcon';
import Widget from 'ui-library/Components/widget/Widget';
import ShowMoreToggle from 'ui-library/Components/widget/content/ShowMoreToggle';
import { BigSwitch } from 'ui-library/Styled/controls';

export interface LockCardProps {
  propertyId: number;
  device: IDevice;
  isLockUnlockDisabled: boolean;
  isOnUnitDashboard?: boolean;
}

const LockCard = ({
  propertyId,
  device,
  isLockUnlockDisabled,
  isOnUnitDashboard = false,
}: LockCardProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openDetails, setOpenDetails] = React.useState(false);
  const [bgColor, setBgColor] = React.useState('#0E33CC');
  const [pending, setPending] = React.useState(false);
  const [promptOpen, setPromptOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const lockDevice = device.data as ILock;

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl) && isLockUnlockDisabled;

  const viewMoreDetails = () => {
    navigate(`/units/${propertyId}/access`);
  };

  const handleSwitchClicked = () => {
    setPromptOpen(true);
  };
  const handleOnClosePrompt = () => {
    setPromptOpen(false);
  };

  const handleAPICall = () => {
    setPending(true);
  };

  const handleAPICallBack = (isSuccess: boolean) => {
    setPending(false);
    const statusMessage = isSuccess ? 'was successful!' : 'has failed!';
    // This is the previous state we need to check not the updated one from the server
    const lockingMessage = lockDevice.is_locked ? 'Unlocking' : 'Locking';
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `${lockingMessage} ${statusMessage}`,
        variant: isSuccess ? SnackbarVariant.SUCCESS : SnackbarVariant.ERROR,
      }),
    );
  };
  const isOfflineLock = lockDevice?.provider?.includes('igloo');
  const isOnline = React.useMemo(() => {
    return lockDevice.is_lock_online;
  }, [lockDevice]);

  React.useEffect(() => {
    const batteryStatus: BatteryStatus = lockDevice.battery_status;
    const keypadStatus: BatteryStatus = lockDevice.battery_keypad_status;
    let color = '#0E33CC';
    if (!isOnline) {
      color = 'error.main';
      setOpenDetails(false);
    } else if (
      batteryStatus === BatteryStatus.CRITICAL ||
      batteryStatus === BatteryStatus.EMPTY ||
      keypadStatus === BatteryStatus.CRITICAL ||
      keypadStatus === BatteryStatus.EMPTY
    ) {
      color = 'error.main';
      setOpenDetails(true);
    } else if (batteryStatus === BatteryStatus.LOW || keypadStatus === BatteryStatus.LOW) {
      color = 'warning.main';
      setOpenDetails(true);
    }
    setBgColor(color);
  }, [lockDevice.battery_status, lockDevice.battery_keypad_status, isOnline]);

  return (
    <Widget
      removeGutter
      title='Lock Status'
      isCarouselCard
      isOnUnitDashboard={isOnUnitDashboard}
      open={openDetails}
      smartCardType='lock'
      footer={
        <LockFooter
          data-testid='lock-device-confirmation'
          device={lockDevice}
          openPrompt={!isLockUnlockDisabled && promptOpen}
          onClosePrompt={handleOnClosePrompt}
          onDetailsClick={viewMoreDetails}
          onAPICall={handleAPICall}
          onAPICallBack={handleAPICallBack}
        />
      }
      fill={bgColor}
    >
      <>
        <ShowMoreToggle
          open={openDetails}
          onClick={() => {
            setOpenDetails(!openDetails);
          }}
          fill={bgColor}
        />
        <Box
          sx={lockContainerStyles}
          aria-haspopup='true'
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Popover
            sx={{
              padding: '30px',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
            role=''
          >
            <Grid
              container
              justifyContent='center'
              sx={{ height: '89px', width: '360px', padding: '15px', marginBottom: '15px' }}
            >
              <ErrorIcon sx={errorIconStyles} />
              <Typography
                sx={{
                  height: '60px',
                  width: '292px',
                }}
              >
                Your permission settings do not allow access to this function. Please contact your
                administrator.
              </Typography>
            </Grid>
          </Popover>
          <div>
            <BigSwitch
              data-testid='lock-switch'
              disabled={pending || isLockUnlockDisabled || isOfflineLock || !isOnline}
              checked={lockDevice?.is_locked && isOnline}
              onClick={handleSwitchClicked}
            />
          </div>

          {pending ? (
            <PendingContainer currentLockState={lockDevice?.is_locked} />
          ) : (
            <LoadedLockContainer device={lockDevice} />
          )}
        </Box>
        {!isOfflineLock && (
          <Collapse in={openDetails}>
            <Box sx={{ paddingLeft: '20px' }}>
              {lockDevice?.battery_status !== BatteryStatus.NOT_INSTALLED && (
                <Box sx={deviceRowStyles}>
                  <BatteryIcon
                    disabled={!isOnline}
                    status={lockDevice?.battery_status}
                    customColor='text.white'
                  />
                  <Typography variant='body2' sx={{ textTransform: 'capitalize' }}>
                    Lock battery {lockDevice?.battery_status || 'N/A'}
                  </Typography>
                </Box>
              )}
              {lockDevice?.battery_keypad_status !== BatteryStatus.NOT_INSTALLED && (
                <Box sx={deviceRowStyles}>
                  <BatteryIcon
                    disabled={!isOnline}
                    status={lockDevice?.battery_keypad_status}
                    customColor='text.white'
                  />
                  <Typography variant='body2' sx={{ textTransform: 'capitalize' }}>
                    Keypad battery {lockDevice?.battery_keypad_status || 'N/A'}
                  </Typography>
                </Box>
              )}
            </Box>
          </Collapse>
        )}
      </>
    </Widget>
  );
};

const deviceRowStyles: SxProps = {
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: '16px',
};

const PendingContainer = ({ currentLockState }: { currentLockState: boolean }) => (
  <Box sx={lockStatusContainerStyles}>
    <CircularProgress
      sx={{
        width: '28px !important',
        height: '28px !important',
        marginRight: '10px',
      }}
    />
    <Typography sx={{ color: 'white' }} variant='h6'>
      {currentLockState ? 'Unlocking...' : 'Locking...'}
    </Typography>
  </Box>
);

const LoadedLockContainer = ({ device }: { device: ILock }) => (
  <Box sx={lockStatusContainerStyles}>
    {device?.is_lock_online && (
      <Avatar sx={lockAvatarStyles}>
        {device?.is_locked ? <LockIcon fontSize='small' /> : <LockOpenIcon fontSize='small' />}
      </Avatar>
    )}
    {!device?.is_lock_online && (
      <Avatar sx={lockAvatarStyles}>
        <ErrorIcon fontSize='small' />
      </Avatar>
    )}
    {device?.is_lock_online && (
      <Typography sx={{ color: 'white' }} variant='h6'>
        {device?.is_locked ? 'Locked' : 'Unlocked'}
      </Typography>
    )}
    {!device?.is_lock_online && (
      <Typography sx={{ color: 'white' }} variant='h6'>
        Offline
      </Typography>
    )}
  </Box>
);

const errorIconStyles: SxProps = {
  height: '20px',
  width: '20px',
  fill: 'primary',
  marginRight: '14px',
};

const lockContainerStyles: SxProps = {
  color: 'text',
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  transition: 'all 500ms ease-out',
};

const lockAvatarStyles: SxProps = {
  width: '28px',
  height: '28px',
  marginRight: '10px',
  backgroundColor: 'rgba(0, 0, 0, 0.38)',
  '.MuiSvgIcon-root': {
    width: '15px',
    height: '15px',
  },
};

const lockStatusContainerStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
};

export default LockCard;
