import { createSelector } from '@reduxjs/toolkit';
import { subHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import differenceInHours from 'date-fns/differenceInHours';
import { dateStringToUTCFormat } from 'helper/date';
import { ApplicationState } from 'redux/reducers';
import { EnviromentalDataPoint } from '../../types/netatmo';

const getHistorical = (state: ApplicationState) => state.devices.historical;
const getProperties = (state: ApplicationState) => state.properties;

const sortDevice = (device: EnviromentalDataPoint[]) => {
  if (device) {
    return device.sort((a: EnviromentalDataPoint, b: EnviromentalDataPoint) => {
      return a.created_at < b.created_at ? -1 : 1;
    });
  }
  return null;
};

const historicalDeviceSelector = (hours: number, deviceId: number, timezone: string) =>
  createSelector(
    [getHistorical, getProperties],

    (historicalDataByDeviceId: Record<string, unknown>): unknown => {
      const nowInBrowserTZ = new Date();
      const dateToCompare = subHours(utcToZonedTime(nowInBrowserTZ, timezone), hours);
      const deviceSpecficHistoricalData = historicalDataByDeviceId[deviceId];
      const sortedDeviceSpecficHistoricalData = sortDevice(
        deviceSpecficHistoricalData as EnviromentalDataPoint[],
      );
      if (sortedDeviceSpecficHistoricalData) {
        const sortedAndMapped = sortedDeviceSpecficHistoricalData.reduce((acc, point) => {
          const createdAtTimezonedTimestamp = new Date(
            dateStringToUTCFormat(`${point.created_at}`),
          );
          const difference = differenceInHours(createdAtTimezonedTimestamp, dateToCompare);

          if (difference < hours && difference > 0) {
            acc.push({
              ...point,
              created_at: createdAtTimezonedTimestamp.valueOf() / 1000,
            });
          }

          return acc;
        }, []);
        return sortedAndMapped;
      }
      return [];
    },
  );
export default historicalDeviceSelector;
