import { GridCellParams, GridColumns } from '@mui/x-data-grid-pro';
import { Text } from '@operto/ui';
import {
  AccessCodeTableCell,
  accessCodeColumn,
  mapInstalledAtToStatus,
} from 'Common/Tables/Common/AccessCodeTableCell';
import { utcToTimeZone } from 'helper/date';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useAppSelector } from 'redux/hooks';
import { SearchToolbarProps } from 'ui-library/Components/table/SearchToolbar';
import { Table } from 'ui-library/Components/table/Table';
import { AccessCodeTypes, ICode } from './codeType';
import memberCodesSelector from './state/memberCodesSelector';

export interface TeamMemberCodesTableProps {
  propertyId: number;
  searchToolbarProps?: SearchToolbarProps;
  loading?: boolean;
}

const accessCodeColDef = accessCodeColumn({
  code: 'access_code',
  status: 'installed_at',
});

const TeamMemberCodesTable = ({
  propertyId,
  searchToolbarProps,
  loading,
}: TeamMemberCodesTableProps) => {
  const codes = useAppSelector(memberCodesSelector(propertyId));

  const filteredData = searchToolbarProps?.value
    ? codes.filter(
        code =>
          code.access_code?.toLowerCase().includes(searchToolbarProps?.value) ||
          code.name?.toLowerCase().includes(searchToolbarProps?.value),
      )
    : codes;

  const accessCodeTypeId = (id: number) => {
    switch (id) {
      case AccessCodeTypes.CleaningStarted ||
        AccessCodeTypes.CleaningCompleted ||
        AccessCodeTypes.Cleaner:
        return 'Cleaner';
      case AccessCodeTypes.Manager:
        return 'Manager';
      case AccessCodeTypes.Maintenance:
        return 'Maintenance';
      default:
        return 'Service';
    }
  };

  const duration = (code: ICode) => {
    if (code.valid_from && code.valid_until) {
      const timezone = code.timezone ? code.timezone : 'America/Vancouver';
      const validFrom = utcToTimeZone(code.valid_from, timezone, 'yyyy-MM-dd');
      const validUntil = utcToTimeZone(code.valid_until, timezone, 'yyyy-MM-dd');

      return (
        <Text.BodySmall>
          {validFrom} &rarr; {validUntil}
        </Text.BodySmall>
      );
    }
    return <Text.BodySmall>No Expiry</Text.BodySmall>;
  };

  const renderMemberName = (params: GridCellParams) => params.row.name;
  const renderCodeType = (params: GridCellParams) =>
    accessCodeTypeId(params.row.access_code_type_id);
  const renderDuration = (params: GridCellParams) => duration(params.row);
  const renderExitCode = (params: GridCellParams) => {
    return (
      <AccessCodeTableCell
        code={params.row.exit_code}
        status={mapInstalledAtToStatus(params.row.installed_at)}
      />
    );
  };

  const columnsDef: GridColumns = [
    {
      field: 'name',
      headerName: 'Team Member Name',
      renderCell: renderMemberName,
      flex: 1,
    },
    {
      field: 'access_code_type_id',
      headerName: 'Code Type',
      renderCell: renderCodeType,
      flex: 1,
    },
    {
      field: 'duration',
      headerName: 'Duration',
      flex: 1,
      renderCell: renderDuration,
      sortable: false,
    },
    accessCodeColDef,
    {
      field: 'exitCode',
      headerName: 'Service Complete Code',
      flex: 1,
      renderCell: renderExitCode,
      sortable: false,
    },
  ];

  const columnsDefForMobile: GridColumns = [
    {
      field: 'name',
      headerName: 'Team Member Name',
      renderCell: renderMemberName,
      flex: 1,
    },
    accessCodeColDef,
  ];

  return (
    <Table
      pagination
      loading={loading}
      sx={{ height: '400px' }}
      rows={filteredData}
      columns={isMobile ? columnsDefForMobile : columnsDef}
      searchToolbarProps={searchToolbarProps}
      disableSelectionOnClick
    />
  );
};

export default TeamMemberCodesTable;
