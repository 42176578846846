import BackupCodeCard from 'code/SmartCards/BackupCodeCard';
import backupCodesSelector from 'code/state/backupCodesSelector';
import { getPropertyAccessCodes } from 'code/state/codeAction';
import { getCompanyInformation, getMessengerSetting } from 'company/state/companyAction';
import HumidityCard from 'device/SmartCards/HumidityCard';
import LockCard from 'device/SmartCards/LockCard';
import NetatmoNoiseCard from 'device/SmartCards/NetatmoNoiseCard';
import NoiseAwareCard from 'device/SmartCards/NoiseAwareCard';
import OccupancyCard from 'device/SmartCards/OccupancyCard';
import TemperatureCard from 'device/SmartCards/TemperatureCard';
import { INetatmo, INoiseAware } from 'device/deviceType';
import UnitDetailsCard from 'property/SmartCards/UnitDetailsCard';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { UnitDashboardContainerProps } from '../UnitDashboardContainer';

const UnitDashboardDevices = ({ propertyId, property, devices }: UnitDashboardContainerProps) => {
  const [devicesCardLoading, setDevicesCardLoading] = React.useState(false);

  // Properties, devices, codes
  const lockDevice = devices.find(device => device.type === 'smart-lock');
  const isLockUnlockDisabled = property?.disable_lock_unlock || property?.smw_enabled;
  const backupCodes = useAppSelector(backupCodesSelector(propertyId));

  const thermostatDevices = devices.filter(device => device.data.temperature) as INetatmo[];
  const humidityDevices = devices.filter(device => device.data.humidity) as INetatmo[];
  const noiseDevices = devices.filter(device => ['Netatmo'].includes(device.vendor)) as INetatmo[];
  const noiseAwareDevices = devices.filter(device =>
    ['NoiseAware'].includes(device.vendor),
  ) as INoiseAware[];
  const occupancyDevices = devices.filter(device => device.data.co2) as INetatmo[];
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCompanyInformation());
    dispatch(getMessengerSetting());
    dispatch(getPropertyAccessCodes(propertyId));
  }, [dispatch, propertyId]);

  useEffect(() => {
    const isLoading = devices.length === 0;
    setDevicesCardLoading(isLoading);
  }, [devices]);

  return (
    <>
      {lockDevice && (
        <LoadingContainer loading={devicesCardLoading}>
          <LockCard
            device={lockDevice}
            propertyId={propertyId}
            isLockUnlockDisabled={isLockUnlockDisabled}
            isOnUnitDashboard
          />
        </LoadingContainer>
      )}
      {backupCodes.length > 0 && (
        <BackupCodeCard propertyId={propertyId} codes={backupCodes.slice(0, 3)} isOnUnitDashboard />
      )}
      <UnitDetailsCard property={property} isOnUnitDashboard />
      {noiseDevices?.length > 0 && (
        <LoadingContainer loading={devicesCardLoading}>
          <NetatmoNoiseCard isOnUnitDashboard />
        </LoadingContainer>
      )}
      {noiseAwareDevices?.length > 0 && (
        <LoadingContainer loading={devicesCardLoading}>
          <NoiseAwareCard isOnUnitDashboard />
        </LoadingContainer>
      )}
      {thermostatDevices?.length > 0 && (
        <LoadingContainer loading={devicesCardLoading}>
          <TemperatureCard isOnUnitDashboard />
        </LoadingContainer>
      )}
      {occupancyDevices?.length > 0 && (
        <LoadingContainer loading={devicesCardLoading}>
          <OccupancyCard isOnUnitDashboard />
        </LoadingContainer>
      )}
      {humidityDevices?.length > 0 && (
        <LoadingContainer loading={devicesCardLoading}>
          <HumidityCard isOnUnitDashboard />
        </LoadingContainer>
      )}
    </>
  );
};

export default UnitDashboardDevices;
