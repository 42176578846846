import { ArrowDropDown } from '@mui/icons-material';
import { Box, InputAdornment, Menu, TextField } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { theme } from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import { NamedChip } from 'Pages/Groups/Common/NamedChip';
import { renderUnits, UnitsSelectionTable } from 'Pages/Groups/Common/UnitsSelectionTable';
import { pinnedComparator } from 'Pages/Groups/EditGroup/group-utils';
import React, { useState } from 'react';
import { Unit, UnitsState } from 'redux/units/types';
import { Group } from 'types/groups';
import { groupNameChipStyle } from 'ui-library/Components/table/table-styles';

export default function AutomateWorkflowUnits({
  selectedUnits,
  setSelectedUnits,
  assignToUnitsError,
  units,
  unitsFetching = false,
}: AutomateWorkflowUnitsProps) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleSelectedUnits = (selectedUnitIds: number[]) => {
    setSelectedUnits(selectedUnitIds);
  };

  const getSelectedUnitsLabel = (selectedUnits: number[], units: Unit[]) => {
    if (selectedUnits.length === 0) {
      return t('select_units');
    }

    return selectedUnits.length === units.length
      ? `${t('all')} ${units.length} ${t('units').toLowerCase()}`
      : selectedUnits.length + ' ' + t(selectedUnits.length === 1 ? 'unit' : 'units');
  };

  return (
    <Box sx={rootContainerStyles}>
      <TextField
        fullWidth
        value={getSelectedUnitsLabel(selectedUnits, units.units)}
        label={t('assign_to_units') + ' *'}
        InputLabelProps={{ shrink: true }}
        sx={[textFieldStyle, assignToUnitsError && textFieldErrorStyles]}
        FormHelperTextProps={{
          sx: { ...(assignToUnitsError ? helperTextStyles : {}) },
        }}
        onClick={e => setAnchorEl(e.currentTarget)}
        helperText={assignToUnitsError ? t('unit_is_required') : ''}
        InputProps={{
          readOnly: true,
          sx: { cursor: 'default' },
          endAdornment: (
            <InputAdornment position='end'>
              <ArrowDropDown />
            </InputAdornment>
          ),
        }}
      />

      <Menu
        id='new-communication-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={menuPaperProps}
        MenuListProps={{ sx: { padding: 0, flex: 1, '&>div': { paddingTop: '5px' } } }}
      >
        <UnitsSelectionTable
          hideFooter
          rows={units.units}
          searchLabel={t('search')}
          loading={unitsFetching}
          columns={createColumnDef(selectedUnits)}
          onSelectionChange={unitIds => handleSelectedUnits(unitIds)}
          selectionModel={selectedUnits}
          pagination={false}
        />
      </Menu>
    </Box>
  );
}

type AutomateWorkflowUnitsProps = {
  selectedUnits: number[];
  setSelectedUnits: (selectedUnitIds: number[]) => void;
  assignToUnitsError?: boolean;
  units: UnitsState;
  unitsFetching?: boolean;
};

const rootContainerStyles = {
  width: '100%',
  padding: '24px',
  backgroundColor: '#fff',
  borderRadius: '16px',
};

const textFieldStyle = {
  '&.MuiFormControl-root': {
    margin: 0,
  },
};

const menuPaperProps = {
  sx: {
    display: 'flex',
    height: 'calc(100vh - 300px)',
    width: '600px',
    padding: '16px',
    borderRadius: '16px',
    '&>ul>div>div': { display: 'flex', flexDirection: 'column', gap: '16px' },
    '&>ul>div>div>div': { margin: 0 },
  },
};

const helperTextStyles = { color: theme.palette.error.main };

const textFieldErrorStyles = {
  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
      borderWidth: '2px',
    },
    '& .MuiInputLabel-outlined': {
      borderColor: theme.palette.error.main,
      borderWidth: '2px',
      '&.Mui-focused': {
        borderColor: theme.palette.error.main,
        borderWidth: '2px',
      },
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
        borderWidth: '2px',
      },
    },
    '&:hover:not(.Mui-focused)': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
        borderWidth: '2px',
      },
    },
  },
  '& .MuiInputLabel-outlined': {
    color: theme.palette.error.main,
    '&.Mui-focused': {
      color: theme.palette.error.main,
    },
  },
};

const createColumnDef = (selectedUnits: number[]): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: selectedUnits.length === 0 ? 'Units' : `${selectedUnits.length} selected`,
      flex: 1,
      valueGetter: params => params.row?.isPinned,
      renderCell: renderUnits,
      sortable: false,
      sortComparator: pinnedComparator,
    },
    {
      field: 'groups',
      headerName: 'Group',
      width: 300,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <NamedChip
          names={params.row.groups?.map((group: Group) => group.name)}
          sx={groupNameChipStyle}
        />
      ),
    },
  ];
};
