import axios from 'axios';

const coreApi = process.env.REACT_APP_API_URL;

export const getCurrentData = () => axios.get(`${coreApi}/v2/airquality/recent/member`);

export const getThresholds = (propertyId: string) =>
  axios.get(`${coreApi}/v2/properties/${propertyId}/alerts`);

export const patchThreshold = (id: number, value: number) =>
  axios.put(`${coreApi}/v2/airquality/threshold/${id}`, {
    value: Number(value),
  });
export const patchNoiseAwareThreshold = (id: number, value: number) =>
  axios.put(`${coreApi}/v2/airquality/threshold/${id}`, {
    value,
  });

export const getHistoricalData = (deviceId: string) =>
  axios.get(`${coreApi}/v2/airquality/${deviceId}/historical-data`);
