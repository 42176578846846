import { Typography, Box, Avatar } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from 'react';

const TabLabel = (number: number, label: string, selected: boolean, tabIdx: number) => {
  return (
    <Box display='inline-flex' alignItems='center'>
      {tabIdx + 1 <= number && (
        <Avatar
          sx={
            selected
              ? {
                  width: theme => theme.spacing(3),
                  height: theme => theme.spacing(3),
                  fontSize: 'inherit',
                  color: theme => theme.palette.getContrastText(theme.palette.primary.main),
                  backgroundColor: theme => theme.palette.primary.main,
                }
              : {
                  width: theme => theme.spacing(3),
                  height: theme => theme.spacing(3),
                  fontSize: 'inherit',
                }
          }
        >
          {number}
        </Avatar>
      )}
      {tabIdx + 1 > number && <CheckCircleIcon />}

      <Typography
        variant='body2'
        sx={
          selected
            ? {
                textTransform: 'none',
                margin: theme => theme.spacing(1),
                color: theme => theme.palette.primary.main,
              }
            : { textTransform: 'none', margin: theme => theme.spacing(1) }
        }
      >
        {label}
      </Typography>
    </Box>
  );
};

export default TabLabel;
