import { SxProps } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { paletteStyles } from '@operto/ui';
import * as React from 'react';

export interface BottomSlidebarProps {
  anchor: 'left' | 'right' | 'bottom' | 'top' | undefined;
  open: boolean;
  setOpen: (open: boolean) => void;
  element: React.ReactElement;
  sx?: SxProps;
  testId?: string;
  onRenderComplete?: () => void;
}

const BottomSlidebar = ({
  anchor,
  open,
  setOpen,
  element,
  sx,
  testId,
  onRenderComplete,
}: BottomSlidebarProps) => {
  return (
    <Drawer
      PaperProps={{ sx: sx ?? drawerStyles }}
      anchor={anchor}
      open={open}
      onClose={() => {
        setOpen(!open);
      }}
      data-testid={testId}
      SlideProps={{ addEndListener: onRenderComplete }}
    >
      {element}
    </Drawer>
  );
};

const drawerStyles: SxProps = {
  backgroundColor: paletteStyles.palette.background.default,
  minHeight: '120px',
  borderRadius: '20px 20px 0 0',
  padding: '20px',
  zIndex: 20,
};

export default BottomSlidebar;
