import { Property } from 'property/propertyType';
import { MergedAlert, MergedAlerts } from 'redux/selectors/mergedActiveAlerts';
import { AlertType } from 'types/activeAlerts';
import { IIntercom, ILock, INetatmo } from 'types/properties';

export const NOISE_STATUSES = {
  FETCHING: 0,
  LOW_RISK: 1,
  MED_RISK: 2,
  HIGH_RISK: 3,
  DEFAULT: 4,
};
export type TNoiseStatuses = (typeof NOISE_STATUSES)[keyof typeof NOISE_STATUSES];

export const LOCK_STATUSES = {
  LOCKED: 0,
  UNLOCKED: 1,
  DEFAULT: 2,
};
export type TLockStatuses = (typeof LOCK_STATUSES)[keyof typeof LOCK_STATUSES];

export const OCCUPANCY_STATUSES = {
  FETCHING: 0,
  LOW_RISK: 1,
  MED_RISK: 2,
  HIGH_RISK: 3,
  DEFAULT: 4,
};
export type TOccupancyStatuses = (typeof OCCUPANCY_STATUSES)[keyof typeof OCCUPANCY_STATUSES];

export const getNoiseStatus = (mergedAlerts: MergedAlerts, property: Property): TNoiseStatuses => {
  if (!mergedAlerts) {
    return NOISE_STATUSES.FETCHING;
  }
  const propertyAlerts: MergedAlert[] = mergedAlerts.byId[property.id] || [];
  const noiseAlert = propertyAlerts.find(
    alert =>
      alert?.alert_type_id &&
      [
        AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_HIGH_ID,
        AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_MED_ID,
        AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_LOW_ID,
      ].indexOf(alert.alert_type_id) !== -1,
  );

  switch (noiseAlert && noiseAlert.alert_type_id) {
    case AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_HIGH_ID:
      return NOISE_STATUSES.HIGH_RISK;
    case AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_MED_ID:
      return NOISE_STATUSES.MED_RISK;
    case AlertType.ALERT_COMPLIANCE_ISSUE_NOISE_LOW_ID:
      return NOISE_STATUSES.LOW_RISK;
    default:
      return NOISE_STATUSES.DEFAULT;
  }
};

export const getTemperature = (property: Property): string => {
  let temp = '--';
  const measurementUnit = property.is_celsius ? 'C' : 'F';
  if (property?.temperature !== undefined) {
    temp = property.is_celsius
      ? Number(property.temperature).toFixed(0)
      : ((property.temperature * 9) / 5 + 32).toFixed(0);
  }

  return `${temp} ${measurementUnit}`;
};

export const locked = (property: Property): TLockStatuses => {
  if (property && property.devices) {
    const devices = property && property.devices;
    const locks = getLocks(devices as ILock[]);
    if (locks && locks[0]) {
      if (locks[0].is_locked) {
        return LOCK_STATUSES.LOCKED;
      }
      return LOCK_STATUSES.UNLOCKED;
    }
  }
  return LOCK_STATUSES.DEFAULT;
};

export const getOccupancyStatus = (
  mergedAlerts: MergedAlerts,
  property: Property,
): TOccupancyStatuses => {
  if (!mergedAlerts) {
    return OCCUPANCY_STATUSES.FETCHING;
  }
  const propertyAlerts: MergedAlert[] = mergedAlerts.byId[property?.id] || [];
  const co2Alert = propertyAlerts.find(
    alert =>
      alert &&
      alert.alert_type_id &&
      [
        AlertType.ALERT_COMPLIANCE_ISSUE_CO2_HIGH_ID,
        AlertType.ALERT_COMPLIANCE_ISSUE_CO2_MED_ID,
        AlertType.ALERT_COMPLIANCE_ISSUE_CO2_LOW_ID,
      ].indexOf(alert.alert_type_id) !== -1,
  );
  const noAlert = OCCUPANCY_STATUSES.DEFAULT;

  switch (co2Alert && co2Alert.alert_type_id) {
    case AlertType.ALERT_COMPLIANCE_ISSUE_CO2_HIGH_ID:
      return OCCUPANCY_STATUSES.HIGH_RISK;
    case AlertType.ALERT_COMPLIANCE_ISSUE_CO2_MED_ID:
      return OCCUPANCY_STATUSES.MED_RISK;
    case AlertType.ALERT_COMPLIANCE_ISSUE_CO2_LOW_ID:
      return OCCUPANCY_STATUSES.LOW_RISK;
    default:
      return noAlert;
  }
};

export const getLocks = (devices: (ILock | IIntercom | INetatmo)[]) => {
  return devices.filter((device: ILock | IIntercom | INetatmo) => isLock(device)) as ILock[];
};

export const isLock = (device: ILock | IIntercom | INetatmo): device is ILock => {
  return ('is_locked' as keyof ILock) in device;
};
