import { OpertoLogger } from 'Logger/logger';
import { updateAccountHolderPassword } from 'api/memberAPI';
import { ICompany } from 'company/companyType';
import { updateCompany } from 'company/state/companyAction';
import { logger } from 'lib/logger';
import { IMember } from 'member/memberType';
import React from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { ChangePasswordForm, PasswordData } from './ChangePasswordForm';
import { CompanyForm } from './CompanyForm';

export interface BusinessInfoContainerProps {
  company: ICompany;
  member: IMember;
}

const BusinessInfoContainer = ({ company, member }: BusinessInfoContainerProps) => {
  const dispatch = useAppDispatch();

  const handleSaveBusinessInfo = async (data: ICompany) => {
    let payload;

    try {
      await dispatch(updateCompany(data));
      payload = {
        message: 'Company information updated successfully',
        variant: SnackbarVariant.SUCCESS,
      };
    } catch (error) {
      logger.error(error);
      payload = {
        message: 'An error occured while saving the company information',
        variant: SnackbarVariant.ERROR,
      };
    }

    dispatch(toggleSnackbar(SnackbarTypes.OPEN, payload));
  };

  const handleSaveNewPassword = async (data: PasswordData) => {
    let payload;

    try {
      const response = await updateAccountHolderPassword(member.id, data);
      payload = {
        message: response?.data?.message,
        variant: SnackbarVariant.SUCCESS,
      };
    } catch (error) {
      payload = {
        message: error?.message,
        variant: SnackbarVariant.ERROR,
      };
      OpertoLogger.Log(error);
    }

    dispatch(toggleSnackbar(SnackbarTypes.OPEN, payload));
  };

  return (
    <>
      <CompanyForm company={company} onSubmit={handleSaveBusinessInfo} />
      <ChangePasswordForm onSubmit={handleSaveNewPassword} />
    </>
  );
};

export default BusinessInfoContainer;
