import { Dispatch } from '@reduxjs/toolkit';
import * as api from 'api/guestApi';
import { logger } from 'lib/logger';
import { ApplicationState } from 'redux/reducers';
import { Actions } from 'types/actions';
import { FilterType, GuestDict, GuestsFilterKeys, IGuest } from '../guestType';

const DEFAULT_PARAMS = {
  type: FilterType.ALL_GUESTS,
  urlSearchParams: new URLSearchParams({
    pageNum: '0',
    numPerPage: '50',
  }),
};

export type GetGuestsParams = {
  propertyId?: string | number;
  filterType?: FilterType;
  urlSearchParams?: URLSearchParams;
};

export const getGuestsByPropertyAction = (
  propertyId: number,
  filterType = DEFAULT_PARAMS.type,
  urlSearchParams = DEFAULT_PARAMS.urlSearchParams,
) => {
  return getGuests({ propertyId, filterType, urlSearchParams });
};

export const getGuests =
  ({ propertyId, filterType, urlSearchParams }: GetGuestsParams = DEFAULT_PARAMS) =>
  async (dispatch: Dispatch, getState: () => ApplicationState) => {
    if (filterType !== FilterType.ALL_GUESTS) {
      urlSearchParams.set(GuestsFilterKeys.TYPE, filterType);
    }
    try {
      // save our current filter
      dispatch({
        type: Actions.updateGuestsMeta,
        guestType: filterType,
        isLoading: true,
      });

      const result = await api.getGuests({ propertyId, urlSearchParams });
      const { currentFilter } = getState().guests.meta;
      if (currentFilter !== filterType) {
        // ignoring API response that is not our current filter, we dont want to override current filter
        return;
      }

      // process correct filter
      const guests = result.data.data;
      const guestList: number[] = [];
      const guestDict: GuestDict = {};
      if (guests.total_records && guests.records.guests.length > 0) {
        guests.records.guests.forEach((guest: IGuest) => {
          guest.id = guest.reservation_id;
          guestList.push(guest.reservation_id);
          guestDict[guest.reservation_id] = guest;
          guestDict[guest.reservation_id].type = filterType;
        });
      }

      dispatch({
        type: guests.pageNum > 0 ? Actions.insertGuests : Actions.hydrateGuests,
        guestList,
        guestDict,
      });

      dispatch({
        type: Actions.updateGuestsMeta,
        guestType: filterType,
        isLoading: false,
        meta: {
          total_records: guests.total_records,
          numPerPage: guests.numPerPage,
          pageNum: guests.pageNum,
        },
      });
    } catch (error) {
      dispatch({
        type: Actions.updateGuestsMeta,
        guestType: filterType,
        isLoading: false,
      });

      logger.error('get guests by company failed, error: ', error);
    }
  };

export const getGuestsFilterOptions = async () => {
  const response = await api.getGuestsFilterOptions();
  return response.data.data;
};
