import PeopleIcon from '@mui/icons-material/People';
import * as React from 'react';

export interface OccupancyIconProps {
  customColor?: string;
}

const OccupancyIcon = ({ customColor = 'error.main' }: OccupancyIconProps) => {
  const icon = <PeopleIcon sx={{ color: customColor }} />;
  return icon;
};

export default OccupancyIcon;
