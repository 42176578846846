// @ts-nocheck

import { Avatar, Box, Grid, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';
import styled from 'styled-components';
import { AddCard } from '@operto/ui-library';
import { Property } from 'property/propertyType';

function ReviewEntry(props: boolean) {
  return (
    <div style={{ width: '100%', display: 'flex' }}>
      <Box component='div' display='inline' m={1} width={30} bgcolor='background.paper'>
        {/* // {This needs to be refined further } */}
        {props.checked ? (
          <CheckmarkAvatar>
            <CheckIcon fontSize='small' />
          </CheckmarkAvatar>
        ) : (
          <></>
        )}
      </Box>
      <Box component='div' display='inline' m={1} width={150} bgcolor='background.paper'>
        <Typography variant='body2'>{props.label}</Typography>
      </Box>
      <Box component='div' display='inline' m={1} bgcolor='background.paper'>
        <Typography variant='body2'>{props.value}</Typography>
      </Box>
    </div>
  );
}

export interface IReviewContainerProps {
  data?: Property;
}

const ReviewContainer = (props: IReviewContainerProps) => {
  const { data } = props;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <AddCard title='Property Details'>
          <Grid container direction='column'>
            <Typography align='left'>Set Property Details</Typography>
            <Grid container direction='row' spacing={4}>
              <Grid item md={6} xs={12}>
                <ReviewEntry label='Property Name:' value={data?.name} checked />
                <ReviewEntry label='Address:' value={data?.address} checked />
                <ReviewEntry label='City:' value={data?.city} checked />
                <ReviewEntry label='Timezone:' value={data?.timezone} checked />
              </Grid>
            </Grid>
          </Grid>
        </AddCard>
      </Grid>

      <Grid item xs={12}>
        <AddCard title='Property Type'>
          <Grid container direction='column'>
            <Typography align='left'>Set property type</Typography>
            <Grid container direction='row' spacing={4}>
              <Grid item md={6} xs={12}>
                <ReviewEntry label='Property Type:' value={data?.property_type} checked />
              </Grid>
            </Grid>
          </Grid>
        </AddCard>
      </Grid>

      <Grid item xs={12}>
        <AddCard title='Reservation Offsets'>
          <Grid container direction='column'>
            <Typography align='left'>Set property reservation offsets</Typography>
            <Grid container direction='row' spacing={4}>
              <Grid item md={6} xs={12}>
                <ReviewEntry label='Check-in Offset:' value={data?.checkin_offset} checked />
                <ReviewEntry label='Check-out Offset:' value={data?.checkout_offset} checked />
              </Grid>
            </Grid>
          </Grid>
        </AddCard>
      </Grid>

      <Grid item xs={12}>
        <AddCard title='Thermostat Offsets'>
          <Grid container direction='column'>
            <Typography align='left'>Set thermostat reservation offsets</Typography>
            <Grid container direction='row' spacing={4}>
              <Grid item md={6} xs={12}>
                <ReviewEntry
                  label='Thermostat Check-in Offset:'
                  value={data?.thermostat_checkin_offset}
                  checked
                />
                <ReviewEntry
                  label='Thermostat Check-out Offset:'
                  value={data?.thermostat_checkout_offset}
                  checked
                />
              </Grid>
            </Grid>
          </Grid>
        </AddCard>
      </Grid>

      <Grid item xs={12}>
        <AddCard title='Property Features'>
          <Grid container direction='column'>
            <Typography align='left'>Select property features</Typography>
            <Grid container direction='row' spacing={4}>
              <Grid item md={6} xs={12}>
                <ReviewEntry
                  label='Property Enabled'
                  value={data?.property_enabled}
                  checked={data?.property_enabled}
                />
                <ReviewEntry
                  label='Backup Code Enabled'
                  value={data?.backup_code_enabled}
                  checked={data?.backup_code_enabled}
                />
                <ReviewEntry
                  label='Blackout Notify Enabled'
                  value={data?.blackout_notify_enabled}
                  checked={data?.blackout_notify_enabled}
                />
                <ReviewEntry
                  label='Lock Enabled'
                  value={data?.lock_enabled}
                  checked={data?.lock_enabled}
                />
                <ReviewEntry
                  label='Environment Enabled'
                  value={data?.environment_enabled}
                  checked={data?.environment_enabled}
                />
                <ReviewEntry
                  label='Checkout Code Enabled'
                  value={data?.checkout_code_enabled}
                  checked={data?.checkout_code_enabled}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <ReviewEntry
                  label='Battery Alert Enabled'
                  value={data?.battery_alert_enabled}
                  checked={data?.battery_alert_enabled}
                />
                <ReviewEntry
                  label='Thermostat Enabled'
                  value={data?.thermostat_enabled}
                  checked={data?.thermostat_enabled}
                />
                <ReviewEntry
                  label='Intercom Enabled'
                  value={data?.intercom_enabled}
                  checked={data?.intercom_enabled}
                />
                <ReviewEntry
                  label='Member Code Enabled'
                  value={data?.member_code_enabled}
                  checked={data?.member_code_enabled}
                />
                <ReviewEntry
                  label='Lock Alert Enabled'
                  value={data?.lock_alert_enabled}
                  checked={data?.lock_alert_enabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </AddCard>
      </Grid>
    </Grid>
  );
};

const CheckmarkAvatar = styled(Avatar)`
  && {
    width: 20px;
    height: 20px;
    color: ${props => props.theme.textColorWhite};
    background-color: #2196f3;
  }
`;

export default ReviewContainer;
