import Drawer from '@mui/material/Drawer';
import { useMedia } from '@operto/ui';
import clsx from 'clsx';
import { isProduction } from 'lib/constants';
import React from 'react';
import CurrentBranch from './CurrentBranch';
import { useStyles } from './DrawerComponentStyles';
import SideBarMenu from './SideBarMenu';
import { SideBarMenuData } from './SideBarMenuData';
import { ISideBarMenu } from './SideBarMenuTypes';

export interface SideBarProps {
  open: boolean;
  toggle: () => void;
  menuType: MenuType;
}

export enum MenuType {
  SETTINGS = 'settings',
  MAIN = 'main',
}

const Sidebar = ({ open, toggle, menuType }: SideBarProps) => {
  const { isDesktop, isMobile } = useMedia();
  const classes = useStyles(isDesktop);
  const { headerButton, settingsSection, mainSection, addonsSection, supportSection } =
    SideBarMenuData();

  const renderMenuContent = () => {
    switch (menuType) {
      case MenuType.SETTINGS:
        return <SideBarMenu {...sideBarSettingsMenuProps} />;
      default:
        return <SideBarMenu {...sideBarMainMenuProps} />;
    }
  };

  const sideBarSettingsMenuProps: ISideBarMenu = {
    open: open,
    toggle: toggle,
    headerButton: headerButton,
    sections: [settingsSection],
  };

  const sideBarMainMenuProps: ISideBarMenu = {
    open: open,
    toggle: toggle,
    sections: [mainSection, addonsSection, supportSection],
  };

  return (
    <Drawer
      id='operto-sidebar-header'
      variant={isDesktop ? 'permanent' : 'temporary'}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
        [classes.drawerCloseForMobile]: !open && isMobile,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
          [classes.drawerCloseForMobile]: !open && isMobile,
        }),
      }}
      PaperProps={{
        sx: {
          borderRadius: 0,
          borderRight: '1px solid #E5E5E5',
          backgroundColor: 'background.paper',
        },
      }}
      ModalProps={{ keepMounted: true }}
      onClose={toggle}
      open={open}
    >
      {renderMenuContent()}
      {!isProduction && <CurrentBranch />}
    </Drawer>
  );
};

export default Sidebar;
