import { Box } from '@mui/material';
import * as React from 'react';

import { useRef } from 'react';
import { BaseContainer } from 'Style/Components/BaseComponents';

export interface OptionsObjType {
  value: number;
  upperRightText: string;
  lowerText: string;
  upperLeftTextOne?: string;
  upperLeftTextTwo?: string;
}
export interface RadialBarProps {
  optionsOne: OptionsObjType;
  optionsTwo?: OptionsObjType;
  primaryColor: string;
  secondaryColor?: string;
  baseColor: string;
}

const RadialBar = ({
  optionsOne,
  optionsTwo,
  primaryColor,
  secondaryColor,
  baseColor,
}: RadialBarProps) => {
  const [value, setValue] = React.useState(optionsOne.value);
  const [secondValue, setSecondValue] = React.useState(optionsTwo?.value);

  const [isLoaded, setIsLoaded] = React.useState(false);

  const blueCanvasRef = useRef<HTMLCanvasElement>(null);
  const barCanvasRef = useRef<HTMLCanvasElement>(null);
  const centerCircleCanvasRef = useRef<HTMLCanvasElement>(null);

  const blueCanvas: HTMLCanvasElement = blueCanvasRef.current;
  const barCanvas: HTMLCanvasElement = barCanvasRef.current;
  const centerCircleCanvas: HTMLCanvasElement = centerCircleCanvasRef.current;

  let blueBarContext: CanvasRenderingContext2D = null;
  let barCanvasContext: CanvasRenderingContext2D = null;
  let centerCircleContext: CanvasRenderingContext2D = null;
  let centX: number = null;
  let centY: number = null;

  const degToRad = (angle: number) => {
    return (angle * Math.PI) / 180;
  };

  const radToDeg = (angle: number) => {
    return (angle * 180) / Math.PI;
  };

  const startingPoint = degToRad(135);

  const innerArcOrCircle = (
    canvas: CanvasRenderingContext2D,
    color: string,
    radius: number,
    isCircle: boolean,
    startingLoc: number,
    endingLoc: number,
  ) => {
    canvas.save();
    canvas.beginPath();
    canvas.strokeStyle = color;
    if (!isCircle) {
      canvas.lineWidth = 10;
      canvas.lineCap = 'butt';
    }
    canvas.arc(centX, centY, radius, startingLoc, endingLoc, false);
    canvas.stroke();
    canvas.restore();
  };

  // Draw a blue outer ring
  const outterCircle = (angle: number, color: string, radius: number) => {
    angle = (angle / 100) * 270;

    blueBarContext.save();
    blueBarContext.beginPath();
    blueBarContext.strokeStyle = color;
    blueBarContext.lineWidth = 10;
    blueBarContext.lineCap = 'butt';
    blueBarContext.arc(
      centX,
      centY,
      radius,
      startingPoint,
      startingPoint + degToRad(angle / 2),
      false,
    );
    blueBarContext.stroke();
    blueBarContext.restore();

    blueBarContext.save();
    blueBarContext.beginPath();
    blueBarContext.strokeStyle = color;
    blueBarContext.lineWidth = 10;
    blueBarContext.lineCap = 'round';
    let blueLineEnder = -2;
    if (angle === 270) {
      blueLineEnder = 0;
      blueBarContext.lineCap = 'butt';
      barCanvasContext.lineCap = 'butt';
    }
    blueBarContext.arc(
      centX,
      centY,
      radius,
      startingPoint + degToRad(angle / 2),
      startingPoint + degToRad(angle + blueLineEnder),
      false,
    );
    blueBarContext.stroke();
    blueBarContext.restore();
  };

  // Percentage Text Drawing
  const text = (valueText: string, upperRightText: string, lowerText: string) => {
    let fontSize = 30;
    let valueXChanger = -5;
    let unitXChanger = 20;
    let unitYChanger = -5;
    if (optionsOne.value >= 76 && upperRightText === 'F°') {
      unitXChanger += 9;
    }
    if (optionsTwo) {
      valueXChanger = -15;
      unitXChanger = 25;
      unitYChanger = -3;
      fontSize = 25;
      if (optionsTwo?.value >= 76 && upperRightText === 'F°') {
        unitXChanger += 9;
      }
      if (optionsOne.value >= 76 && upperRightText === 'F°') {
        unitXChanger += 9;
      }
    }
    blueBarContext.save();
    blueBarContext.fillStyle = '#000000';
    blueBarContext.font = `${fontSize}px Arial`;
    blueBarContext.textAlign = 'center';
    blueBarContext.textBaseline = 'middle';
    blueBarContext.fillText(valueText, centX + valueXChanger, centY);

    blueBarContext.save();
    blueBarContext.fillStyle = '#000000';
    blueBarContext.font = '15px Arial';
    blueBarContext.textAlign = 'center';
    blueBarContext.textBaseline = 'middle';
    blueBarContext.fillText(upperRightText, centX + unitXChanger, centY + unitYChanger);

    blueBarContext.save();
    blueBarContext.fillStyle = '#000000';
    blueBarContext.font = '13px Arial';
    blueBarContext.textAlign = 'center';
    blueBarContext.textBaseline = 'middle';
    blueBarContext.fillText(lowerText, centX, centY + 20);
    blueBarContext.restore();
  };

  const ticker = (fromRadius: number, toRadius: number) => {
    for (let angle: number = degToRad(315); angle >= degToRad(45); angle -= degToRad(15)) {
      let largeTicker = 0;

      if (Math.trunc(radToDeg(angle)) % 90 === 0) {
        largeTicker = 4;
      }
      const y = Math.cos(angle) * fromRadius + centY;
      const x = Math.sin(angle) * fromRadius + centX;
      const yTo = Math.cos(angle) * (toRadius + largeTicker) + centY;
      const xTo = Math.sin(angle) * (toRadius + largeTicker) + centX;

      blueBarContext.beginPath();
      blueBarContext.globalAlpha = 0.5;
      blueBarContext.lineWidth = 2;
      blueBarContext.strokeStyle = '#BEC4CB';
      blueBarContext.fillStyle = '#BEC4CB';
      blueBarContext.moveTo(x, y);
      blueBarContext.lineTo(xTo, yTo);
      blueBarContext.stroke();
    }
  };
  React.useEffect(() => {
    if (optionsOne.value > 100) {
      setValue(100);
    } else if (optionsOne.value < 3) {
      setValue(3);
    } else {
      setValue(optionsOne.value);
    }
    if (optionsTwo !== undefined && optionsTwo.value > 100) {
      setSecondValue(100);
    } else if (optionsTwo !== undefined && optionsTwo.value < 3) {
      setSecondValue(3);
    } else {
      setSecondValue(optionsTwo?.value);
    }
    setIsLoaded(!!isLoaded);
  }, [optionsOne, optionsTwo, isLoaded]);

  if (blueCanvas && barCanvas && centerCircleCanvas) {
    const dpr = window.devicePixelRatio;
    const rect = blueCanvas.getBoundingClientRect();
    blueCanvas.width = dpr * rect.width;
    blueCanvas.height = dpr * rect.height;

    const rect1 = barCanvas.getBoundingClientRect();
    barCanvas.width = dpr * rect1.width;
    barCanvas.height = dpr * rect1.height;

    const rect2 = centerCircleCanvas.getBoundingClientRect();
    centerCircleCanvas.width = dpr * rect2.width;
    centerCircleCanvas.height = dpr * rect2.height;

    blueBarContext = blueCanvas.getContext('2d'); // Get the drawing environment, specified as 2D
    barCanvasContext = barCanvas.getContext('2d'); // Get the drawing environment, specified as 2D
    centerCircleContext = centerCircleCanvas.getContext('2d'); // Get the drawing environment, specified as
    centX = barCanvas.width / 2 / dpr; // Canvas central point X-axis coordinates
    centY = barCanvas.height / 2 / dpr; // Canvas center point y axis coordinates
    blueBarContext.scale(dpr, dpr);
    barCanvasContext.scale(dpr, dpr);
    centerCircleContext.scale(dpr, dpr);

    innerArcOrCircle(centerCircleContext, '#FFFFFF00', 67, true, 0, 2 * Math.PI);

    if (optionsTwo && secondaryColor) {
      // FIRST ARC
      text(
        `${optionsOne.upperLeftTextOne.toString()}/${optionsTwo.upperLeftTextTwo.toString()}`,
        optionsOne.upperRightText,
        '',
      );
      outterCircle(value, primaryColor, 70);
      innerArcOrCircle(
        barCanvasContext,
        baseColor,
        70,
        false,
        startingPoint,
        startingPoint + degToRad(270),
      );
      // SECOND ARC
      outterCircle(secondValue, secondaryColor, 82);
      innerArcOrCircle(
        barCanvasContext,
        baseColor,
        82,
        false,
        startingPoint,
        startingPoint + degToRad(270),
      );
      ticker(90, 95);
    } else {
      text(optionsOne.upperLeftTextOne, optionsOne.upperRightText, optionsOne.lowerText);
      outterCircle(value, primaryColor, 70);
      innerArcOrCircle(
        barCanvasContext,
        baseColor,
        70,
        false,
        startingPoint,
        startingPoint + degToRad(270),
      );
      ticker(80, 85);
    }
  }
  return (
    <BaseContainer style={{ position: 'relative', width: '230px', height: '210px' }}>
      <Box sx={{ marginTop: '30px', marginLeft: '10px', marginBottom: '0' }}>
        <canvas style={CanvasStyle} id='barCanvas' ref={barCanvasRef}></canvas>
        <canvas id='blueCanvas' ref={blueCanvasRef} style={CanvasStyle}></canvas>
        <canvas
          style={{
            position: 'absolute',
            borderRadius: '400px',
            boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.28)',
            width: '130px',
            height: '130px',
            marginLeft: '31px',
            marginTop: '31px',
          }}
          id='centerCircleCanvas'
          ref={centerCircleCanvasRef}
        ></canvas>
      </Box>
    </BaseContainer>
  );
};

const CanvasStyle: React.CSSProperties = {
  position: 'absolute',
  width: '192px',
  height: '192px',
};

export default RadialBar;
