import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { Text } from '@operto/ui';
import { formatRelativeToTimeZone } from 'helper/date';
import getProperty from 'property/state/propertySelectors';
import {
  IPropertyAlert,
  IPropertyAlertOverview,
  PropertyAlertMeta,
} from 'propertyAlerts/propertyAlertType';
import { getPropertiesAlerts } from 'propertyAlerts/state/propertyAlertActions';
import {
  propertyAlertsMetaSelector,
  propertyAlertsSelector,
} from 'propertyAlerts/state/propertyAlertSelectors';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PaginatedTable, PaginatedTableProps } from 'ui-library/Components/table/PaginatedTable';
import LinkCell from './Components/LinkCell';
import MultipleAlertRenderer from './MultipleAlertRenderer';
import SingleAlertRenderer from './SingleAlertRenderer';

const useStyles = makeStyles({
  singleAlertRow: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '24px',
    overflow: 'hidden',
  },
  multiAlertRow: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff5f5',
    padding: '15px',
    borderBottom: 'solid 1px #e0d7d7',
  },
  firstColumn: {
    minWidth: '200px',
    maxWidth: '200px',
  },
});

const TimeAgoTableCell = ({ alert }: { alert: IPropertyAlert }) => {
  const property = useAppSelector(getProperty(alert.property_id));
  const timezone = property?.timezone;
  return <Text.BodySmall>{formatRelativeToTimeZone(alert.time_ago, timezone)}</Text.BodySmall>;
};

const PropertyAlertsTable = (props: Omit<PaginatedTableProps, 'columns' | 'rows' | 'onFetch'>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchString, setSearchString] = useState('');
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const propertyAlerts: IPropertyAlertOverview[] = useAppSelector(
    propertyAlertsSelector(searchString),
  );
  const propertyAlertsMeta: PropertyAlertMeta = useSelector(propertyAlertsMetaSelector());

  const columns = (): GridColDef<IPropertyAlertOverview>[] => [
    {
      field: 'property_name',
      headerName: 'Unit Name',
      width: 210,
      renderCell: unitNameRenderer,
    },
    {
      field: 'alert',
      headerName: 'Alert',
      width: 180,
      sortable: false,
      renderCell: alertColumnRenderer,
    },
    {
      field: 'time_ago',
      headerName: 'Last Activity',
      width: 180,
      sortable: false,
      renderCell: lastActivityRenderer,
    },
  ];

  const handleFetch = useCallback(
    async (pageNum: number, numPerPage: number, keyword?: string) => {
      setSearchString(keyword);

      setIsLoading(true);
      await dispatch(getPropertiesAlerts({ pageNum, numPerPage, keyword }));
      setIsLoading(false);
    },
    [dispatch],
  );

  const alertColumnRenderer = (params: GridRenderCellParams<unknown, IPropertyAlertOverview>) => {
    if (params.row.alerts && params.row.alerts.length > 1) {
      return (
        <MultipleAlertRenderer
          rowData={params.row}
          key={`${params.row?.property_id}_${params.row?.property_name}`}
        />
      );
    }
    if (params.row.alerts && params.row.alerts.length === 1) {
      return <SingleAlertRenderer rowData={params.row.alerts[0]} />;
    } else {
      return <div style={{ textTransform: 'capitalize' }}>-</div>;
    }
  };

  const lastActivityRenderer = (params: GridRenderCellParams) => {
    return <TimeAgoTableCell alert={params.row.alerts[0]} />;
  };

  const getDetailPanelContent = ({ row }: GridRowParams) => {
    return (
      <Grid container>
        {row.alerts.map((row: IPropertyAlert, index: React.Key) => {
          return (
            <Grid container className={classes.multiAlertRow} key={index}>
              <Grid item xs={3} className={classes.firstColumn}>
                -
              </Grid>
              <Grid item xs={4} className={classes.singleAlertRow}>
                <SingleAlertRenderer rowData={row} />
              </Grid>
              <Grid item xs={3}>
                <TimeAgoTableCell alert={row} />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const getDetailPanelHeight = ({ row }: GridRowParams) => {
    if (row.alerts) {
      return row.alerts.length * 55;
    }
  };

  const unitNameRenderer = (params: GridRenderCellParams) => {
    return <LinkCell text={params.row.property_name} url={`units/${params.row.property_id}`} />;
  };

  return (
    <PaginatedTable
      enableToolbar
      loading={isLoading}
      rows={propertyAlerts}
      rowCount={propertyAlertsMeta?.total_records}
      columns={columns()}
      onFetch={handleFetch}
      getRowId={row => row.property_id}
      getDetailPanelHeight={getDetailPanelHeight}
      getDetailPanelContent={getDetailPanelContent}
      {...props}
    />
  );
};

export default PropertyAlertsTable;
