import MenuVerticalIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import * as React from 'react';

import { BaseContainer } from 'Style/Components/BaseComponents';
import { SmartCardIconButton } from 'ui-library/Styled/smartcard';

import { Variable } from '@operto/variables-shared';

const VARIABLE_ACIVATED_STATE_TEXT = 'Deactivate';
const VARIABLE_DEACTIVATED_STATE_TEXT = 'Activate';

export interface VariableTableActionsProps {
  onEdit: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  onExport: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  onChangeStatus: (variableStatusToUpdate: boolean) => void;
  onDelete: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  onDuplicate: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  variable: Variable;
}

const VariableTableActions = ({
  onEdit,
  onExport,
  onChangeStatus,
  onDelete,
  onDuplicate,
  variable,
}: VariableTableActionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <BaseContainer>
      <SmartCardIconButton data-testid='open-more-button' $show size='small' onClick={handleClick}>
        <MenuVerticalIcon />
      </SmartCardIconButton>
      <Menu
        data-testid='more-actions-menu'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <MenuItem
          onClick={e => {
            onEdit(e);
            handleClose();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={e => {
            onDuplicate(e);
            handleClose();
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem onClick={onExport}>Export</MenuItem>
        <MenuItem
          data-testid='custom-var-table-activate-action'
          onClick={() => {
            onChangeStatus(!variable?.enabled);
            handleClose();
          }}
        >
          {variable?.enabled ? VARIABLE_ACIVATED_STATE_TEXT : VARIABLE_DEACTIVATED_STATE_TEXT}
        </MenuItem>
        <MenuItem
          data-testid='custom-var-table-delete-action'
          onClick={e => {
            onDelete(e);
            handleClose();
          }}
          disabled={variable?.enabled}
        >
          Delete
        </MenuItem>
      </Menu>
    </BaseContainer>
  );
};

export default VariableTableActions;
