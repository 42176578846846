import uniqBy from 'lodash/uniqBy';
import { RTKQapi } from 'redux/rtkq-api';
import {
  DEFAULT_PAGINATED_PARAMS,
  PaginatedData,
  PaginatedParams,
  ResponseData,
} from 'redux/rtkq-utils';
import { parseUnits } from './parsers';
import { UnitFilterType, UnitJSON, UnitsState } from './types';

export const UNIT_TAG = 'Unit';
export const UNIT_LIST_ID = 'LIST';

export const PARAM_LOCK_ENABLED = 'lock_enabled';
export const PARAM_ACTIVE_TODAY = 'active_today';
export const PARAM_FAVOURITES = 'favourites';
export const PARAM_ROOM_STATUS = 'room_status';
export const PARAM_TYPE = 'type';
export const PARAM_GROUP_TYPE = 'group_type';
export const PARAM_LOCATION_TYPE = 'locationType';

export const DEFAULT_GET_PARAMS = {
  ...DEFAULT_PAGINATED_PARAMS,
  [PARAM_LOCK_ENABLED]: 'yes',
} as const;

export const INITIAL_STATE: UnitsState = {
  units: [],
  totalUnits: 0,
  pageNum: 0,
  numPerPage: 0,
};

export type GetQueryParams = PaginatedParams & {
  filterType: UnitFilterType;
};

const unitsApiWithTag = RTKQapi.enhanceEndpoints({ addTagTypes: [UNIT_TAG] });

export const unitsApi = unitsApiWithTag.injectEndpoints({
  endpoints: builder => ({
    getUnits: builder.query<UnitsState, void | GetQueryParams>({
      query: args => {
        let params: Record<string, string | number> = DEFAULT_GET_PARAMS;
        if (args) {
          const { filterType, ...rest } = args;
          params = { ...params, ...rest };
          if (filterType === UnitFilterType.UNACTIVATED) {
            params[PARAM_LOCK_ENABLED] = 'no';
          }

          if (filterType === UnitFilterType.VACANT) {
            params[PARAM_LOCK_ENABLED] = 'yes';
            params[PARAM_ROOM_STATUS] = 'clean-available';
          }

          if (filterType === UnitFilterType.CLEANING) {
            params[PARAM_ROOM_STATUS] = 'guest-checking-out';
          }

          if (filterType === UnitFilterType.ACTIVE) {
            params[PARAM_LOCK_ENABLED] = 'yes';
            params[PARAM_ACTIVE_TODAY] = 'yes';
          }

          if (filterType === UnitFilterType.FAVOURITES) {
            params[PARAM_FAVOURITES] = 'yes';
          }

          if (filterType === UnitFilterType.COMMON) {
            params[PARAM_TYPE] = UnitFilterType.COMMON;
          }

          if (filterType === UnitFilterType.ROOM_TYPE) {
            params[PARAM_TYPE] = UnitFilterType.ROOM_TYPE;
          }

          if (filterType === UnitFilterType.PUBLIC) {
            params[PARAM_LOCK_ENABLED] = 'yes';
            params[PARAM_GROUP_TYPE] = UnitFilterType.PUBLIC;
          }

          if (filterType === UnitFilterType.ACCESS_GROUP) {
            params[PARAM_LOCATION_TYPE] = UnitFilterType.ACCESS_GROUP;
          }
        }
        return {
          url: 'v2/companies/properties',
          params,
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const args = { ...queryArgs } as PaginatedParams;

        // exclude pagination from cache key
        delete args['pageNum'];
        delete args['numPerPage'];

        return `${endpointName}(${JSON.stringify(args)})`;
      },
      merge: (state: UnitsState, incoming: UnitsState) => {
        return {
          ...incoming,
          units:
            incoming.pageNum === 0
              ? incoming.units
              : uniqBy([...state.units, ...incoming.units], 'id'),
        };
      },
      providesTags: (state: UnitsState = INITIAL_STATE) => {
        return [
          ...state.units.map(({ id }) => ({ type: UNIT_TAG, id } as const)),
          { type: UNIT_TAG, id: UNIT_LIST_ID },
        ];
      },
      transformResponse: ({
        data: { records, pageNum, numPerPage, total_records },
      }: ResponseData<PaginatedData<UnitJSON>>) => ({
        units: parseUnits(records),
        pageNum,
        numPerPage,
        totalUnits: total_records,
      }),
    }),
  }),
});

export const { useGetUnitsQuery, useLazyGetUnitsQuery } = unitsApi;
