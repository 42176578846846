import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { Actions } from 'types/actions';
import {
  GuestNameMap,
  GuestVerificationByReservationId,
  ReservationDict,
  ReservationState,
} from '../reservationType';

export const initialListState: number[] = [];

const list: Reducer<number[]> = (state = initialListState, action) => {
  switch (action.type) {
    case Actions.addTeamReservation:
    case Actions.upsertReservation:
      return Array.from(new Set([...state, action.reservation.id]));
    case Actions.insertReservation:
      return Array.from(new Set([...state, action.reservation.id]));
    case Actions.hydrateReservation:
      return Array.from(new Set([...state, action.reservation.id]));
    case Actions.hydrateReservations:
      return action.reservationIds;
    case Actions.addReservations:
      return Array.from(new Set([...state, ...action.reservationIds]));
    case Actions.deleteReservation:
      return state.filter((each: number) => each !== action.id);
    case Actions.cancelPmsReservation:
    default:
      return state;
  }
};

export const initialReservationState: ReservationDict = {};

const byId: Reducer<ReservationDict> = (state = initialReservationState, action) => {
  switch (action.type) {
    case Actions.upsertReservation:
    case Actions.insertReservation:
    case Actions.hydrateReservation:
      return {
        ...state,
        [action.reservation.id]: action.reservation,
      };
    case Actions.addTeamReservation:
    case Actions.addReservations:
      return { ...state, ...action.reservationsById };

    case Actions.hydrateReservations:
      return action.reservationsById;
    case Actions.cancelReservation:
      return {
        ...state,
        [action.reservationId]: {
          ...state[action.reservationId],
          status: 'canceled',
        },
      };
    case Actions.cancelPmsReservation:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          status: 'blocked',
        },
      };
    case Actions.grantPmsReservation:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          status: 'confirmed',
        },
      };
    case Actions.deleteReservation:
      const copy = { ...state };
      delete copy[action.id];
      return copy;
    default:
      return state;
  }
};

const guestNames: Reducer<GuestNameMap> = (state = {}, action) => {
  switch (action.type) {
    case Actions.upsertGuestName:
      return {
        ...state,
        ...action.guestIdToName,
      };
    default:
      return state;
  }
};

export const initialGuestVerificationState: GuestVerificationByReservationId = {};

const guestVerification: Reducer = (state = initialGuestVerificationState, action) => {
  switch (action.type) {
    case Actions.beginGuestVerificationUpdate:
      return {
        ...state,
        [action.reservationId]: {
          reservationId: action.reservationId,
          guestVerificationUpdateStatus: 'updating',
          updatedAt: action.reservationLastUpdated,
        },
      };
    case Actions.guestVerificationUpdateSuccess:
      return {
        ...state,
        [action.reservationId]: {
          reservationId: action.reservationId,
          guestVerificationUpdateStatus: 'success',
          updatedAt: action.reservationLastUpdated,
        },
      };
    case Actions.guestVerificationUpdateFailed:
      return {
        ...state,
        [action.reservationId]: {
          reservationId: action.reservationId,
          guestVerificationUpdateStatus: 'failed',
          updatedAt: action.reservationLastUpdated,
        },
      };
    case Actions.endGuestVerificationUpdate:
      return {
        ...state,
        [action.reservationId]: {
          reservationId: action.reservationId,
          guestVerificationUpdateStatus: '',
          updatedAt: action.reservationLastUpdated,
        },
      };
    default:
      return state;
  }
};

const reducer: Reducer<ReservationState> = combineReducers<ReservationState>({
  byId,
  list,
  guestNames,
  guestVerification,
});

export default reducer;
