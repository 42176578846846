import { ChevronRightOutlined } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useMedia } from '@operto/ui';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { Channel as TChannel } from 'stream-chat';
import {
  Channel,
  MessageInput,
  MessageList,
  Thread,
  Window,
  MessageToSend,
  useChannelActionContext,
} from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { userSelector } from 'user/state/userSelectors';
import CustomChannelHeader from './CustomChannelHeader';
import MessagePeopleListChatContainer from './MessagePeopleListChatContainer';
import { MessageFilterIndex } from './MessagingPage';

interface IChatMessagesProps {
  currentChannel: TChannel<DefaultStreamChatGenerics>;
  setCurrentChannel: (channel: TChannel<DefaultStreamChatGenerics>) => void;
  handleNewChat: (onNewChat: boolean) => void;
  handleChannelOnClick: (channel: TChannel) => void;
  isNewChat: boolean;
  filterIndex: MessageFilterIndex;
  isMessengerEnabled: boolean;
}

interface CustomMessageInputProps {
  isMessengerEnabled: boolean;
}

const CustomMessageInput = (props: CustomMessageInputProps) => {
  const { sendMessage } = useChannelActionContext();

  const overrideSubmitHandler = (message: MessageToSend) => {
    sendMessage(message, { target_channel_type: 'sms' }); // or 'whatsapp' in the future
  };

  return props.isMessengerEnabled ? (
    <MessageInput overrideSubmitHandler={overrideSubmitHandler} />
  ) : null;
};

const ChatMessages = ({
  currentChannel,
  setCurrentChannel,
  handleNewChat,
  handleChannelOnClick,
  isNewChat,
  filterIndex,
  isMessengerEnabled,
}: IChatMessagesProps) => {
  const { isDesktop, isTablet, isMobile } = useMedia();
  const displayChat = isDesktop || ((isTablet || isMobile) && currentChannel);
  const [showChevronTabletIcon, setShowChevronTabletIcon] = useState(false);
  const loggedInMember = useAppSelector(userSelector());

  useEffect(() => {
    if (currentChannel) {
      isTablet && setShowChevronTabletIcon(true);
    }
  }, [currentChannel, isTablet]);

  const handleChevronClick = () => {
    setCurrentChannel(undefined);
  };
  const renderChevronIcon = () => {
    const handleChevronTabletClick = () => {
      setShowChevronTabletIcon(false);
      handleChevronClick();
    };

    return (
      <>
        {showChevronTabletIcon && (
          <IconButton
            sx={{
              width: '48px',
              height: '48px',
            }}
          >
            <ChevronRightOutlined
              sx={{
                width: '100%',
                height: '100%',
                borderRadius: 32,
                boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.5)',
                background: 'white',
              }}
              onClick={handleChevronTabletClick}
            />
          </IconButton>
        )}
      </>
    );
  };

  return (
    <Box
      sx={{
        display: displayChat ? 'flex' : 'none',
        flex: '1 0 0',
        position: 'relative',
      }}
    >
      {showChevronTabletIcon && (
        <Box sx={{ position: 'absolute', top: 13, left: -25, zIndex: 'tooltip' }}>
          {renderChevronIcon()}
        </Box>
      )}
      {isNewChat ? (
        <MessagePeopleListChatContainer
          onNewChat={handleNewChat}
          peopleType={filterIndex}
          user={loggedInMember}
          lastClickedOnChannel={handleChannelOnClick}
        />
      ) : (
        <Channel>
          <Window>
            <CustomChannelHeader
              onChevronClick={handleChevronClick}
              showFavouriteIcon={filterIndex === MessageFilterIndex.GUESTS}
              showUnitName={filterIndex === MessageFilterIndex.GUESTS}
            />
            <MessageList />
            <CustomMessageInput isMessengerEnabled={isMessengerEnabled} />
          </Window>
          <Thread />
        </Channel>
      )}
    </Box>
  );
};

export default ChatMessages;
