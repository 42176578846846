export enum ConfirmFunctions {
  DeleteGroup = 'deleteGroup',
  DeleteTeam = 'deleteTeam',
  CancelReservation = 'cancelReservation',
  CancelPmsReservation = 'cancelPmsReservation',
  UnblockPmsReservation = 'unblockPMSReservation',
  VerifyGuest = 'VerifyGuest',
  DisableCloudbeds = 'disableCloudbeds',
  DisableGuesty = 'disableGuesty',
  DisableMyVr = 'disableMyVr',
  DisableHostAway = 'disableHostAway',
  DisableMews = 'disableMews',
  RemoveMemberFromUnit = 'removeMemberFromUnit',
  RemoveGroupFromUnit = 'removeGroupFromUnit',
  LockProperty = 'LockProperty',
  MobileLockProperty = 'MobileLockProperty',
  UnlockProperty = 'UnlockProperty',
  MobileUnlockProperty = 'MobileUnlockProperty',
  RefreshMemberCode = 'RefreshMemberCode',
  DeleteBackupCode = 'DeleteBackupCode',
  MakeAvailable = 'MakeAvailable',
  default = '',
  RemoveVerifyGuest = 'RemoveVerifyGuest',
}

export default ConfirmFunctions;