import { SxProps } from '@mui/material';
import { DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import * as React from 'react';

type DatePickerOptionProps = {
  onChange: (value: DateRange<Date>) => void;
  defaultValue?: DateRange<Date>;
  numberOfCalendars?: 1 | 2 | 3; // default is 2
};

export const DatePickerOption = ({
  onChange,
  defaultValue,
  numberOfCalendars,
}: DatePickerOptionProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangeCalendar
        calendars={numberOfCalendars}
        onChange={onChange}
        value={defaultValue}
        sx={datePickerStyle}
      />
    </LocalizationProvider>
  );
};

const datePickerStyle: SxProps = {
  '.css-jef1b6-MuiDateRangeCalendar-container:not(:last-of-type)': {
    borderRight: 'none',
  },
  '.MuiDateRangeCalendar-monthContainer': {
    width: '100%',
  },
  '.MuiDayCalendar-header.css-i5q14k-MuiDayCalendar-header': {
    justifyContent: 'space-around',
  },
  '.css-flbe84-MuiDayCalendar-weekContainer': {
    justifyContent: 'space-around',
  },
  '.MuiDayCalendar-weekContainer > div': {
    flex: '1',
    textAlign: 'center',
  },
  '.MuiDateRangePickerDay-rangeIntervalDayHighlightStart': {
    background: 'linear-gradient(to right, white 50%, rgba(21, 94, 255, 0.12) 50% 100%)',
  },
  '.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd': {
    background: 'linear-gradient(to left, white 50%, rgba(21, 94, 255, 0.12) 50% 100%)',
  },
  '.MuiDateRangePickerDay-rangeIntervalDayHighlightStart.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd':
    {
      background: 'none',
    },
};
