import { Stack, TextField } from '@mui/material';
import { Text } from '@operto/ui';
import { ConfirmDialog, ConfirmDialogProps } from 'Common/Dialog/ConfirmDialog';
import React, { useEffect, useState } from 'react';
import { Group } from 'redux/groups/types';

type DeleteGroupDialogProps = Pick<ConfirmDialogProps, 'open' | 'loading'> & {
  group: Group;
  onCancel: () => void;
  onDelete: () => void;
};

export const DeleteGroupDialog = ({
  group,
  open,
  onCancel,
  onDelete,
  ...rest
}: DeleteGroupDialogProps) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    return () => {
      if (!open) {
        setValue('');
      }
    };
  }, [open]);

  return (
    <ConfirmDialog
      open={open}
      title={`Delete group '${group.name}'`}
      onClose={onCancel}
      submitButtonText='Delete'
      submitButtonColor='error'
      onSubmit={onDelete}
      disabled={value !== 'Delete'}
      {...rest}
    >
      <Stack spacing={4}>
        <Text.BodyLarge>{`Are you sure you want to delete the group '${group.name}'? This action will disconnect members from the unit, potentially limiting unit access. Access codes will be removed from the lock unless the same member and unit are part of another group. Access code removal may take some time based on lock status.`}</Text.BodyLarge>
        <Text.BodyLarge>{"Type 'Delete' to confirm"}</Text.BodyLarge>
        <TextField
          placeholder='Delete'
          value={value}
          onChange={e => setValue(e.target.value)}
          sx={{ width: 220 }}
        />
      </Stack>
    </ConfirmDialog>
  );
};
