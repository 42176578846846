import { Container, SxProps } from '@mui/material';
import { Text } from '@operto/ui';
import { validateGroupNameHelper } from 'helper/groupNameHelper';
import React from 'react';
import { Control } from 'react-hook-form';
import { INITIAL_STATE, useGetGroupsQuery } from 'redux/groups/api-slice';
import { Group } from 'redux/groups/types';
import { FormTextField } from 'ui-library/Components/input/FormTextField';
import { validateTextLength } from 'ui-library/Helpers/textHelper';

type GroupsFormTabGeneralProps = {
  control: Control<Group>;
};

export const GroupsFormTabGeneral = ({ control }: GroupsFormTabGeneralProps) => {
  const { data = INITIAL_STATE } = useGetGroupsQuery();

  return (
    <Container sx={fieldsContainerStyle}>
      <Text.BodyLarge>Set this group&apos;s general details</Text.BodyLarge>
      <FormTextField
        rules={{
          required: {
            value: true,
            message: 'Group name is required',
          },
          validate: (text: string) => validateGroupNameHelper(text, data),
        }}
        field='name'
        type='text'
        label={'Group Name'}
        control={control}
        helperText={'Max 32 characters limit'}
        sx={inputFieldStyle}
        data-testid='group-name'
      />
      <FormTextField
        rules={{
          validate: input => validateTextLength(input, 64),
        }}
        field='description'
        type='text'
        label={'Description'}
        control={control}
        helperText={'Max 64 characters limit'}
        sx={inputFieldStyle}
        data-testid='group-description'
      />
    </Container>
  );
};

const inputFieldStyle: SxProps = {
  marginTop: '16px',
};

const fieldsContainerStyle: SxProps = {
  height: 'fit-content',
  background: 'white',
  borderRadius: '15px',
  padding: '16px',
};
