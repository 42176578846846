import { Box, DialogTitle, Popover, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

export interface InfoDialogProps {
  open: boolean;
  onClose: () => void;
  anchorEl: null | HTMLElement;
  title: string;
  paragraphOneTitle: string;
  paragraphOneText: ReactNode;
  paragraphTwoTitle: string;
  paragraphTwoText: ReactNode;
}

const InfoDialog = ({
  open,
  onClose,
  anchorEl,
  title,
  paragraphOneTitle,
  paragraphOneText,
  paragraphTwoTitle,
  paragraphTwoText,
}: InfoDialogProps) => {
  const rootStyles = { maxWidth: '550px', borderRadius: '10px' };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        sx: rootStyles,
      }}
    >
      <DialogTitle>{title}</DialogTitle>

      <Box
        sx={{
          padding: '0 24px 24px 24px',
        }}
      >
        <Box>
          <Typography variant='subtitle1'>{paragraphOneTitle}</Typography>
          {paragraphOneText}
        </Box>
        <Box sx={{ height: '24px' }} />
        <Box>
          <Typography variant='subtitle1'>{paragraphTwoTitle}</Typography>
          {paragraphTwoText}
        </Box>
      </Box>
    </Popover>
  );
};

export default InfoDialog;
