import { Typography } from '@mui/material';
import * as React from 'react';
import ConfirmDialog from './ConfirmDialog';

export interface DisableMessengerDialogProps {
  open: boolean;
  onClose: () => void;
  goToPages: () => void;
}

const DisableMessengerDialog = ({ onClose, open, goToPages }: DisableMessengerDialogProps) => {
  return (
    <ConfirmDialog
      open={open}
      title='We are unable to process this action at this time.'
      onClose={onClose}
      submitButtonText='GO TO PAGES'
      onSubmit={() => {
        onClose();
        goToPages();
      }}
    >
      <Typography variant='body1'>
        You are currently using Smart Messenger buttons in your Guides pages and cannot disable
        Smart Messenger at this time. Please update the affected pages before disabling.
      </Typography>
    </ConfirmDialog>
  );
};

export default DisableMessengerDialog;
