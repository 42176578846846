import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { CodeDetail } from '../../../types/ui';
import { Actions } from '../../../types/actions';

const show: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.showCodeDetailView:
      return true;
    case Actions.hideCodeDetailView:
      return false;
    default:
      return state;
  }
};
const id: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.showCodeDetailView:
      return action.codeId;
    default:
      return state;
  }
};

const teamMemberModal: Reducer<CodeDetail> = combineReducers<CodeDetail>({
  show,
  id,
});

export default teamMemberModal;
