import { Tooltip, TooltipProps } from '@mui/material';
import React from 'react';
import { isDesktop } from 'react-device-detect';

export type TableTooltipProps = TooltipProps & {
  isColumnNameCell?: boolean;
};

export const TABLE_TOOLTIP_COLUMN_CELL_VERTICAL_OFFSET = -29;
export const TABLE_TOOLTIP_ROW_CELL_VERTICAL_OFFSET = -13;

const TableTooltip = ({
  children,
  isColumnNameCell = false,
  ...tooltipProps
}: TableTooltipProps) => {
  const popperProps = {
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [
              0,
              isColumnNameCell
                ? TABLE_TOOLTIP_COLUMN_CELL_VERTICAL_OFFSET
                : TABLE_TOOLTIP_ROW_CELL_VERTICAL_OFFSET,
            ],
          },
        },
      ],
    },
  };

  const tableTooltip = (
    <Tooltip placement='bottom' PopperProps={popperProps} {...tooltipProps}>
      {children}
    </Tooltip>
  );

  return isDesktop ? tableTooltip : children;
};

export const ColumnTitleTableTooltip = (tooltipProps: TooltipProps) => (
  <TableTooltip isColumnNameCell {...tooltipProps} />
);

export const RowCellTableTooltip = (tooltipProps: TooltipProps) => (
  <TableTooltip isColumnNameCell={false} {...tooltipProps} />
);

export default TableTooltip;
