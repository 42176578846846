import { Chip, ChipProps } from '@mui/material';
import React from 'react';

type StatusChipProps = ChipProps & {
  textColor?: string;
  backgroundColor?: string;
};

export const StatusChip = ({ textColor, backgroundColor, sx, ...rest }: StatusChipProps) => {
  return (
    <Chip
      size='small'
      sx={{
        fontSize: 10,
        fontWeight: 700,
        color: textColor,
        borderRadius: '4px',
        '& .MuiChip-icon': { color: textColor },
        backgroundColor,
        ...sx,
      }}
      {...rest}
    />
  );
};
