import { Typography } from '@mui/material';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IPage } from 'redux/guestportal';
import { useGuidesPages } from 'redux/guestportal/hooks/useGuidesPages';
import { GuidesActionsType } from '../../types';
import GuidesDetailsPagesForm from './GuidesDetailsPagesForm';
import { GuidesDetailsPagesTable } from './GuidesDetailsPagesTable';

export const GuidesDetailsPagesTab = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedPage, setSelectedPage] = useState<IPage | undefined>(undefined);
  const { page, save, remove } = useGuidesPages();

  const handleAction = (action: GuidesActionsType, page?: IPage) => {
    switch (action) {
      case 'add':
        navigate(`${pathname}/add`);
        break;

      case 'edit':
        navigate(`${pathname}/${page?.content_id}`);
        break;

      case 'delete':
        setSelectedPage(page);
        setOpenDialog(true);
        break;

      case 'feature':
        save({ ...page, featured: !page.featured });
        break;

      case 'publish':
        save({ ...page, published: !page.published });
        break;
    }
  };

  return (
    <>
      <ConfirmDialog
        open={openDialog}
        title='Delete Page'
        submitButtonColor='error'
        onSubmit={() => {
          remove(selectedPage);
          setOpenDialog(false);
        }}
        onClose={() => setOpenDialog(false)}
      >
        <Typography variant='body1'>Are you sure you want to delete the following Page?</Typography>
        <Typography variant='body2' marginTop={2}>{`"${selectedPage?.title}"`}</Typography>
      </ConfirmDialog>

      <GuidesDetailsPagesTable onAction={handleAction} />

      <GuidesDetailsPagesForm page={page} onSave={save} />
    </>
  );
};

export default GuidesDetailsPagesTab;
