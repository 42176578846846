import { Unit, UnitJSON } from './types';

export const toJSON = (unit: Unit): Omit<UnitJSON, 'id'> => {
  return {
    name: unit.name,
    company_id: unit.companyId,
    lock_enabled: unit.lockEnabled,
    property_enabled: unit.propertyEnabled,
    property_type: unit.propertyType,
    groups: unit.groups,
  };
};

const parseUnit = (response: UnitJSON): Unit => {
  return {
    id: response.id,
    name: response.name,
    companyId: response.company_id,
    lockEnabled: response.lock_enabled,
    propertyEnabled: response.property_enabled,
    propertyType: response.property_type,
    groups: response.groups,
  };
};

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
export const parseUnits = (response: UnitJSON[]) => {
  const sorted = response.sort((a, b) => collator.compare(a.name, b.name));
  return sorted.map(parseUnit);
};
