import axios from 'axios';

const api = process.env.REACT_APP_API_URL;

export const getGroupsPaginated = (
  locationType?: string,
  pageNum?: number | undefined,
  numPerPage?: number | undefined,
  includesPrivate?: boolean,
  keyword?: string,
) => {
  const params: Record<string, unknown> = { pageNum, numPerPage, includesPrivate };

  if (keyword) {
    params.keyword = keyword;
  }
  if (locationType) {
    params.locationType = locationType;
  }

  return axios.get(`${api}/v3/groups`, {
    params,
  });
};

export const addUnitToUnitGroup = (property_ids: number[], groupId: number) =>
  axios.post(`${api}/v2/groups/${groupId}/properties`, {
    property_ids,
  });

export const addUnitsToUnitGroup = (property_ids: number[], groupId: number) =>
  axios.post(`${api}/v2/groups/${groupId}/properties`, {
    property_ids,
  });

export const removeUnitFromUnitGroup = (propertyId: number, groupId: number) =>
  axios.delete(`${api}/v2/groups/${groupId}/properties/${propertyId}`);

export const publicAccessOn = (groupId: number) => {
  return axios.post(`${api}/v3/groups/${groupId}/public_access`);
};

export const lockPublicAccess = (groupId: number) => {
  return axios.post(`${api}/v3/groups/${groupId}/lock`);
};
