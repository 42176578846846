import { MenuItem, SxProps } from '@mui/material';
import React from 'react';

interface SideBarItemProps {
  active: boolean;
  open: boolean;
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  children: React.ReactNode;
}

const SideBarItem: React.FC<SideBarItemProps> = ({ active, open, onClick, children }) => {
  const menuItemStyle: SxProps = {
    borderRadius: '36px',
    backgroundColor: active ? 'primary.main' : 'transparent',
    color: active ? 'text.white' : 'inherit',
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: open ? 'flex-start' : 'center',
    marginLeft: open ? '16px' : 0,
    marginRight: open ? '16px' : 0,
  };

  return (
    <MenuItem
      sx={{
        ...menuItemStyle,
        '&:hover': {
          backgroundColor: 'primary.p8',
          color: 'text.primary',
        },
        '&:hover span': {
          color: 'primary.main',
          fill: 'primary.main',
        },
      }}
      onClick={onClick}
      data-testid='sideBarItem'
    >
      {children}
    </MenuItem>
  );
};

export default SideBarItem;
