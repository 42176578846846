import { combineReducers, Reducer } from '@reduxjs/toolkit';
import {
  DashboardState,
  ICheckInStats,
  ICheckOutStats,
  IPropertyAlertsStats,
} from 'dashboard/dashboardTypes';
import { Actions } from 'types/actions';

const initialCheckOutStats: ICheckOutStats = {
  total: 0,
  total_check_out: 0,
  reservations: [],
};

const checkOutStats: Reducer<ICheckOutStats> = (state = initialCheckOutStats, action) => {
  switch (action.type) {
    case Actions.hydrateCheckOutStats:
      return action.stats;
    default:
      return state;
  }
};

const initialCheckInStats: ICheckInStats = {
  total: 0,
  total_check_in: 0,
  reservations: [],
};

const checkInStats: Reducer<ICheckInStats> = (state = initialCheckInStats, action) => {
  switch (action.type) {
    case Actions.hydrateCheckInStats:
      return action.stats;
    default:
      return state;
  }
};

const initialPropertyAlertsStats: IPropertyAlertsStats = {
  total_alerts: 0,
  total_low_alerts: 0,
  total_high_alerts: 0,
};

const propertyAlertsStats: Reducer<IPropertyAlertsStats> = (
  state = initialPropertyAlertsStats,
  action,
) => {
  switch (action.type) {
    case Actions.hydratePropertyAlertsStats:
      return action.stats;
    default:
      return state;
  }
};

const reducer: Reducer<DashboardState> = combineReducers<DashboardState>({
  check_in_stats: checkInStats,
  check_out_stats: checkOutStats,
  property_alerts_stats: propertyAlertsStats,
});

export default reducer;
