import MenuVerticalIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, SxProps } from '@mui/material';
import Menu from '@mui/material/Menu';
import * as React from 'react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import DeleteOfferDialog from './DeleteOfferDialog';
import OfferActionMenuItems from './OfferActionMenuItems';

export interface OfferDropdownDrawerProps {
  companyId: number;
  offerId: string;
  offerName: string;
  onEdit?: () => void;
  onClose?: () => void;
  isFillLight?: boolean;
}

const OfferDropdownDrawer = ({
  companyId,
  offerId,
  offerName,
  onEdit,
  onClose,
  isFillLight,
}: OfferDropdownDrawerProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openActionBar, setOpenActionBar] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const iconButton: SxProps = {
    color: isFillLight ? 'text.white' : 'text.primary',
  };

  const onDeleteClick = () => {
    setOpenDelete(true);
  };

  const onEditClick = () => {
    onEdit();
  };

  const offerActionMenuItemsProperties = {
    onEditClick,
    onDeleteClick,
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeDialog = () => {
    setOpenDelete(false);
    handleClose();
    onClose();
  };

  return (
    <Box sx={dropDownDrawerContainer}>
      <DeleteOfferDialog
        companyId={companyId}
        offerId={offerId}
        offerName={offerName}
        open={openDelete}
        onDelete={closeDialog}
        onClose={() => setOpenDelete(false)}
      />
      <IconButton
        sx={iconButton}
        size='small'
        onClick={isMobile ? () => setOpenActionBar(!openActionBar) : handleClick}
        data-testid='offerDropdownButton'
      >
        <MenuVerticalIcon />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <OfferActionMenuItems {...offerActionMenuItemsProperties} />
      </Menu>
    </Box>
  );
};

const dropDownDrawerContainer: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export default OfferDropdownDrawer;
