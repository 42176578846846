import { Box, Tab, Tabs } from '@mui/material';
import { SideDrawer } from 'Common/Drawer/SideDrawer';
import RightSideTopbar from 'Common/Slidebar/components/RightSideTopbar';
import GPPreviewContainer from 'Pages/GuestPortal/Preview/GPPreviewContainer';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICategory } from 'redux/guestportal';
import TabTransition from 'ui-library/Components/transitions/TabTransition';
import {
  GuidesDetailsCategoriesFormTabEdit,
  GuidesDetailsCategoriesFormTabSettings,
} from './GuidesDetailsCategoriesFormTabSettings';

enum GuidesDetailsCategoriesFormTabItem {
  Edit = 0,
  Settings,
  Preview,
}

type GuidesDetailsCategoriesFormContainerProps = {
  activeTab: GuidesDetailsCategoriesFormTabItem;
  onTabClick: (tab: GuidesDetailsCategoriesFormTabItem) => void;
  children?: React.ReactNode;
  onClose: () => void;
  onSave?: (category: ICategory) => void;
  category: ICategory;
};

const GuidesDetailsCategoriesFormContainer = (props: GuidesDetailsCategoriesFormContainerProps) => {
  const { category, children, onSave, activeTab, onTabClick, onClose } = props;
  if (category === undefined) {
    return null;
  }

  // TODO: UNCATEGORIZED
  return (
    <>
      <RightSideTopbar
        title={category?.category?.length > 0 ? category?.category : 'New Category Title'}
        handleClose={onClose}
        page={category}
        onSave={onSave}
        buttonDisabled={category.category.toLowerCase().includes('uncategorized')}
      />
      <Tabs
        value={activeTab}
        onChange={(event: React.ChangeEvent, newValue: number) => onTabClick(newValue)}
        sx={{ backgroundColor: 'background.default' }}
      >
        <Tab label='Edit' />
        <Tab label='Settings' />
        {category.category_id && <Tab label='Preview' />}
      </Tabs>
      <Box p={1.5} display='flex'>
        {children}
      </Box>
    </>
  );
};

type GuidesDetailsCategoriesFormProps = {
  category: ICategory;
  onSave: (category: ICategory) => void;
};

export const GuidesDetailsCategoriesForm = ({
  category,
  onSave,
}: GuidesDetailsCategoriesFormProps) => {
  const navigate = useNavigate();
  const [categoryCopy, setCategoryCopy] = useState<ICategory>(category);
  const [activeTab, setActiveTab] = useState<GuidesDetailsCategoriesFormTabItem>(
    GuidesDetailsCategoriesFormTabItem.Edit,
  );

  useEffect(() => {
    if (!categoryCopy) {
      setCategoryCopy(category);
    }

    return () => {
      if (!category) {
        setCategoryCopy(undefined);
      }
    };
  }, [category, categoryCopy]);

  return (
    <SideDrawer open={category !== undefined} onClose={() => navigate(-1)}>
      <GuidesDetailsCategoriesFormContainer
        category={categoryCopy}
        activeTab={activeTab}
        onTabClick={tab => setActiveTab(tab)}
        onSave={onSave}
        onClose={() => navigate(-1)}
      >
        <TabTransition show={activeTab === GuidesDetailsCategoriesFormTabItem.Edit}>
          <GuidesDetailsCategoriesFormTabEdit
            category={categoryCopy}
            onCategoryChange={setCategoryCopy}
          />
        </TabTransition>
        <TabTransition show={activeTab === GuidesDetailsCategoriesFormTabItem.Settings}>
          <GuidesDetailsCategoriesFormTabSettings
            category={categoryCopy}
            onCategoryChange={setCategoryCopy}
          />
        </TabTransition>
        <TabTransition show={activeTab === GuidesDetailsCategoriesFormTabItem.Preview}>
          <GPPreviewContainer />
        </TabTransition>
      </GuidesDetailsCategoriesFormContainer>
    </SideDrawer>
  );
};

export default GuidesDetailsCategoriesForm;
