import { BaseContainer } from 'Style/Components/BaseComponents';
import styled from 'styled-components';

export const TabWrapper = styled(BaseContainer)<{ isRightSideBar?: boolean; isDesktop?: boolean }>`
  background-color: 'transparent';
  position: 'static';
  top: ${props => props.isRightSideBar && '65px'};
  width: 100%;
`;

export const TabContentWrapper = styled(BaseContainer)<{ isMobile: boolean }>`
  padding: ${props => (props.isMobile ? '24px 0px' : '30px')};
`;
