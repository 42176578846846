import { createSelector } from '@reduxjs/toolkit';
import isAfter from 'date-fns/isAfter';
import { EventState, FilterType, IEvent } from 'event/eventType';
import { utcStringToDate } from 'helper/date';
import take from 'lodash/take';
import { ApplicationState } from 'redux/reducers';
import { Activity, ActivityState } from 'shared/state/sharedTypes';

const getEvents = (state: ApplicationState) => state.events;

const filterByType = () => createSelector([getEvents], (events: EventState) => events);

export const SearchEventsSelector = (keyword: string, type: FilterType) =>
  createSelector([filterByType()], (filteredEvents: EventState) => {
    return Object.values(filteredEvents.byId)
      .filter(
        event =>
          findInMessageAndTitle(event.message, event.title, keyword) && type === event.activityType,
      )
      .sort((a, b) =>
        isAfter(utcStringToDate(a.created_at), utcStringToDate(b.created_at)) ? -1 : 1,
      );
  });

export const SearchEventsByPIDSelector = (keyword: string, type: FilterType, propertyId: number) =>
  createSelector([filterByType()], (filteredEvents: EventState) =>
    filteredEvents.list
      .map((eventId: number) => filteredEvents.byId[eventId])
      .filter(
        (event: IEvent) =>
          findInMessageAndTitle(event.message, event.title, keyword) &&
          type === event.activityType &&
          event.property_id === propertyId,
      )
      .sort((a: IEvent, b: IEvent) => b.id - a.id),
  );

const findInMessageAndTitle = (message: string, title: string, keyword: string) => {
  const noCaseKeyword = keyword?.toLowerCase();
  const messageResult = message?.toLowerCase().includes(noCaseKeyword);
  const titleResult = title?.toLowerCase().includes(noCaseKeyword);
  return messageResult || titleResult;
};

export const EventsTotalCountSelector = (type: FilterType) =>
  createSelector([getEvents], (events: EventState) => events.meta[type]);

export const recentEventsSelector = (propertyId: number, limit = 10) =>
  createSelector([getEvents], (activities: ActivityState) => {
    const activitiesList: Activity[] = Object.keys(activities.byId).map(
      (key: string) => activities.byId[Number(key)],
    );

    const list: Activity[] = take(
      activitiesList
        .filter((activity: Activity) => activity.property_id === propertyId)
        .sort((a: Activity, b: Activity) => (a.created_at < b.created_at ? 1 : -1)),
      limit,
    );
    return list;
  });
