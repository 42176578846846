import { combineReducers, Reducer } from '@reduxjs/toolkit';
import {
  CommonPropertyDict,
  CommonPropertyState,
  CommonPropertyLinkedPropertiesDict,
  CommonPropertyAssociation,
} from '../../types/commonProperties';
import { Actions } from '../../types/actions';

const initialUnitState: number[] = [];

const listProperties: Reducer<number[]> = (state = initialUnitState, action) => {
  switch (action.type) {
    case Actions.hydrateCommonProperties:
      return action.commonPropertiesIds;
    default:
      return state;
  }
};

const initialUnitById: CommonPropertyDict = {};

const propertiesById: Reducer<CommonPropertyDict> = (state = initialUnitById, action) => {
  switch (action.type) {
    case Actions.hydrateCommonProperties:
      return action.commonPropertiesById;
    default:
      return state;
  }
};

const initialAddionalUnitState: number[] = [];

const listAdditionalProperties: Reducer<number[]> = (state = initialAddionalUnitState, action) => {
  switch (action.type) {
    // case Actions.hydrateCommonProperties:
    //   return action.commonPropertiesIds;
    default:
      return state;
  }
};

const initialAdditionalUnitById: CommonPropertyDict = {};

const additionalPropertiesById: Reducer<CommonPropertyDict> = (
  state = initialAdditionalUnitById,
  action,
) => {
  switch (action.type) {
    // case Actions.hydrateProperties:
    //   return action.additionalPropertiesById;
    default:
      return state;
  }
};

const initialLinkedUnitsByMasterId: CommonPropertyLinkedPropertiesDict = {};

const linkedPropertiesByMasterId: Reducer<CommonPropertyLinkedPropertiesDict> = (
  state = initialLinkedUnitsByMasterId,
  action,
) => {
  switch (action.type) {
    case Actions.commonPropertyLinks:
      return {
        ...state,
        [action.propertyId]: action.linkedPropertyIds.map(
          (linkedProperty: CommonPropertyAssociation) => linkedProperty.common_property_id,
        ),
      };
    default:
      return state;
  }
};
const reducer: Reducer<CommonPropertyState> = combineReducers<CommonPropertyState>({
  byId: propertiesById,
  list: listProperties,
  additionalPropertiesbyId: additionalPropertiesById,
  additionalPropertiesList: listAdditionalProperties,
  linkedPropertiesByMasterId,
});

export default reducer;
