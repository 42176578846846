import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@mui/icons-material';
import { Button, IconButton, Stack, Tab, Tabs } from '@mui/material';
import { Tag, getTagsByMemberId } from '@operto/tags-shared';
import { trpc } from '@operto/trpc-client';
import { FormContainer } from 'Common/FormContainer';
import NotificationsContainer from 'Pages/EditPeople/TabContainers/NotificationsContainer';
import MemberDetailsForm, { MemberDetailsFormHandle } from 'Pages/People/MemberDetailsForm';
import { logger } from 'lib/logger';
import { getGroups, getGroupsPaginated } from 'location/state/LocationsActions';
import { IMember } from 'member/memberType';
import { refreshMemberCode, updateMember } from 'member/state/memberActions';
import { memberByIdSelector } from 'member/state/memberSelectors';
import React, { useEffect, useRef, useState } from 'react';
import { toggleSlidebar, toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SlidebarType, SnackbarTypes, SnackbarVariant } from 'types/ui';
import { formFooterStyles } from 'ui-library/Styled/containers';
import { userSelector } from 'user/state/userSelectors';
import { UserState } from 'user/userTypes';
import { roleHasPermission } from '../../helper/helper';
import AccessCodesContainer from './TabContainers/AccessCodesContainer';
import { GroupsContainer } from './TabContainers/GroupsContainer';
import PermissionsContainer from './TabContainers/PermissionsContainer';
import TabLabel from './TabLabel';

export interface EditPeopleProps {
  memberId: number;
  handleClose?: () => void;
  unitOnly?: boolean;
  tabNum?: number;
}

const TAB_ITEMS = {
  GENERAL: 0,
  NOTIFICATIONS: 1,
  PERMISSIONS: 2,
  GROUPS: 3,
  ACCESSCODES: 4,
};

export const memberContainerSource = 'member';

const EditPeople = ({ memberId, unitOnly, tabNum, handleClose }: EditPeopleProps) => {
  const memberDetailsRef = useRef<MemberDetailsFormHandle>();
  const dispatch = useAppDispatch();
  const member = useAppSelector(memberByIdSelector(memberId)) as IMember;
  const loggedInMember: UserState = useAppSelector(userSelector());
  const [memberCopy, setMemberCopy] = useState<IMember>(member);
  const [tabIdx, setTabIdx] = useState(tabNum ? tabNum : unitOnly ? TAB_ITEMS.GROUPS : 0);
  const [saving, setSaving] = useState(false);

  const { data: companyTags = [], isLoading: isGetTagsLoading } = trpc.tags.getTags.useQuery();
  const [memberTagsCopy, setMemberTagsCopy] = useState<Tag[]>([]);
  const [updatedTags, setUpdatedTags] = useState<Tag[]>([]);

  const utils = trpc.useUtils();
  const updateTagsMutation = trpc.tags.updateTags.useMutation({
    onSuccess: () => {
      utils.tags.getTags.refetch();
    },
  });

  const hasPermission = roleHasPermission(
    loggedInMember.user.id,
    loggedInMember.role,
    member.id,
    member.role,
  );

  const onNextClick = () => {
    if (tabIdx === TAB_ITEMS.GENERAL) {
      memberDetailsRef.current?.submit();
      if (!memberDetailsRef.current?.isValid) {
        return;
      }
      setMemberTagsCopy(memberDetailsRef.current?.selectedTags || []);
      setUpdatedTags(memberDetailsRef.current?.updatedTags || []);
    }
    setTabIdx((tabIdx as number) + 1);
  };

  const onDataChange = (field: string, value: unknown) => {
    setMemberCopy(prev => ({ ...prev, [field]: value }));
  };

  const onDataSave = async () => {
    let success = true;
    setSaving(true);

    try {
      if (tabIdx === TAB_ITEMS.GENERAL) {
        memberDetailsRef.current?.save();
        return;
      }
      await dispatch(updateMember(memberCopy));
      await updateTagsMutation.mutateAsync(updatedTags);
    } catch (error) {
      success = false;
      logger.error(`Error updating member: ${error}`);
    }

    setSaving(false);
    dispatch(toggleSlidebar(SlidebarType.CLOSE, ''));
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `Updating the member was ${success ? 'successful' : 'not successful'}`,
        variant: success ? SnackbarVariant.SUCCESS : SnackbarVariant.ERROR,
      }),
    );
  };

  const onMemberDetailsTagsSave = async () => {
    let success = true;

    try {
      const updatedTags = memberDetailsRef.current.updatedTags;
      setUpdatedTags(updatedTags);
      await updateTagsMutation.mutateAsync(updatedTags);
    } catch (error) {
      success = false;
      logger.error(`Error updating member: ${error}`);
    }

    dispatch(toggleSlidebar(SlidebarType.CLOSE, ''));
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `Updating the member was ${success ? 'successful' : 'not successful'}`,
        variant: success ? SnackbarVariant.SUCCESS : SnackbarVariant.ERROR,
      }),
    );
  };

  const refreshCode = () => {
    dispatch(refreshMemberCode(memberId));
  };

  useEffect(() => {
    setMemberCopy(member);
  }, [member]);

  useEffect(() => {
    dispatch(getGroupsPaginated());
    dispatch(getGroups());
  }, [dispatch]);

  useEffect(() => {
    if (!isGetTagsLoading) {
      const memberTags = getTagsByMemberId(memberId, companyTags);
      setMemberTagsCopy(memberTags);
    }
  }, [companyTags, memberId, isGetTagsLoading]);

  const renderHeader = () => {
    return (
      <Tabs
        value={tabIdx}
        onChange={(_, value) => {
          setTabIdx(value);
        }}
      >
        <Tab label={TabLabel(1, 'General Details', tabIdx === TAB_ITEMS.GENERAL, tabIdx)} />
        <Tab label={TabLabel(2, 'Notifications', tabIdx === TAB_ITEMS.NOTIFICATIONS, tabIdx)} />
        <Tab label={TabLabel(3, 'Permissions', tabIdx === TAB_ITEMS.PERMISSIONS, tabIdx)} />
        <Tab label={TabLabel(4, 'Groups', tabIdx === TAB_ITEMS.GROUPS, tabIdx)} />
        <Tab label={TabLabel(5, 'Access Codes', tabIdx === TAB_ITEMS.ACCESSCODES, tabIdx)} />
      </Tabs>
    );
  };

  const renderFooter = () => {
    return (
      <Stack direction='row' alignItems='center' sx={formFooterStyles}>
        <IconButton
          color='primary'
          aria-label='previous-button'
          onClick={() => {
            setTabIdx(tabIdx - 1);
          }}
          disabled={tabIdx === TAB_ITEMS.GENERAL}
        >
          <ArrowBackIosOutlined />
        </IconButton>
        {tabIdx === TAB_ITEMS.ACCESSCODES && !unitOnly ? (
          <Button onClick={refreshCode} type='submit' variant='contained' disabled={!hasPermission}>
            Refresh Access Code
          </Button>
        ) : (
          <IconButton
            color='primary'
            aria-label='next-button'
            onClick={() => {
              onNextClick();
            }}
            disabled={tabIdx === TAB_ITEMS.ACCESSCODES}
          >
            <ArrowForwardIosOutlined />
          </IconButton>
        )}
      </Stack>
    );
  };

  return (
    <FormContainer
      title={memberCopy?.name || 'Edit member'}
      renderHeader={renderHeader}
      renderFooter={renderFooter}
      onClose={handleClose}
      dirty={hasPermission}
      onSubmit={onDataSave}
      loading={saving}
    >
      {tabIdx === TAB_ITEMS.GENERAL && (
        <MemberDetailsForm
          isNewMember={false}
          ref={memberDetailsRef}
          member={memberCopy}
          onSubmit={member => {
            setMemberCopy(prev => ({ ...prev, ...member }));
            setTabIdx(tabIdx + 1);
          }}
          memberTags={memberTagsCopy}
          onSave={async member => {
            const updatedMember = { ...memberCopy, ...member };
            setMemberCopy(updatedMember);
            dispatch(updateMember(updatedMember));
            onMemberDetailsTagsSave();
          }}
        />
      )}
      {tabIdx === TAB_ITEMS.NOTIFICATIONS && (
        <NotificationsContainer data={memberCopy} onChange={onDataChange} />
      )}
      {tabIdx === TAB_ITEMS.PERMISSIONS && (
        <PermissionsContainer data={memberCopy} onChange={onDataChange} />
      )}
      {tabIdx === TAB_ITEMS.GROUPS && (
        <GroupsContainer member={memberCopy} onChange={onDataChange} />
      )}
      {tabIdx === TAB_ITEMS.ACCESSCODES && <AccessCodesContainer data={memberCopy} />}
    </FormContainer>
  );
};

export default EditPeople;
