import { SxProps, Theme, Toolbar, ToolbarProps } from '@mui/material';
import React from 'react';

export type TableHeaderProps = ToolbarProps;

export const TableHeader = ({ children, ...rest }: TableHeaderProps) => {
  return (
    <Toolbar sx={toolbarStyles} {...rest}>
      {children}
    </Toolbar>
  );
};

const toolbarStyles: SxProps<Theme> = {
  backgroundColor: 'background.paper',
  borderBottom: theme => `1px solid ${theme.palette.divider}`,
};
