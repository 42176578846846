import axios from 'axios';

const api = process.env.REACT_APP_API_URL;

export const createChannel = (reservationId: number) =>
  axios.post(`${api}/v2/two-way/guests`, {
    reservation_id: reservationId,
  });

export const notifyGuest = (reservationId: string, hostMessage: string) =>
  axios.post(`${api}/v2/two-way/guests/chat-notify`, {
    reservation_id: reservationId,
    host_message: hostMessage,
  });

export const createChannelMembers = (memberIds: number[]) =>
  axios.post(`${api}/v2/two-way/members`, {
    member_ids: memberIds,
  });

export const updateFavourite = (channelId: string, favourite: boolean) =>
  axios.put(`${api}/v2/two-way/guests/${channelId}`, {
    favourite: favourite,
  });

export const updateProperty = (
  channelId: string,
  reservationId: string,
  channelPropertyId: string,
) =>
  axios.patch(`${api}/v2/two-way/guests/property/${channelId}`, {
    reservation_id: reservationId,
    channel_property_id: channelPropertyId,
  });
