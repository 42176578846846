import React from 'react';
import { isAdmin } from 'redux/actions/ui';
import {
  IntegrationsCard,
  IntegrationsCardActionType,
  IntegrationsCardProps,
} from './IntegrationsCard';

export type PMSIntegrationsCardActionType = IntegrationsCardActionType | 'RE-SYNC' | 'RE-CONNECT';

type PMSIntegrationsCardProps = Omit<IntegrationsCardProps, 'onActionClick'> & {
  onActionClick: (action: PMSIntegrationsCardActionType) => void;
};

export const PMSIntegrationsCard = ({ connected, enabled, ...rest }: PMSIntegrationsCardProps) => {
  const getActionButtonText = () => {
    if (!connected) {
      return undefined; // default to 'CONNECT' or 'EDIT' from IntegrationsCard
    }

    if (enabled) {
      return 'RE-SYNC';
    }

    if (!isAdmin()) {
      return 'RE-CONNECT';
    }

    return undefined; // default to 'EDIT' from IntegrationsCard
  };

  return (
    <IntegrationsCard
      connected={connected}
      enabled={enabled}
      actionTitle={getActionButtonText()}
      {...rest}
    />
  );
};
