import { createSelector } from '@reduxjs/toolkit';
import { AccessCodeTypes, CodeState, ICode } from 'code/codeType';
import { ApplicationState } from '../reducers';

const getCodes = (state: ApplicationState) => state.codes;
const getPropertyId = (state: ApplicationState) => state.ui.propertyDetailId;

const matchMembersStartAndComplete = createSelector(
  [getCodes, getPropertyId],
  (codes: CodeState, propertyId: number) => {
    // Filter cleaner and maintenance codes from all access codes
    const filteredCodes: ICode[] = Object.values(codes.byId)
      .filter(
        (code: ICode) =>
          Number(code.property_id) === Number(propertyId) &&
          [
            AccessCodeTypes.CleaningStarted,
            AccessCodeTypes.CleaningCompleted,
            AccessCodeTypes.Maintenance,
            AccessCodeTypes.MaintenanceComplete,
          ].includes(code.access_code_type_id),
      )
      .sort((a, b) => new Date(a.valid_from).valueOf() - new Date(b.valid_from).valueOf());

    // Combine member start and complete codes into one row
    const reducedCodes: ICode[] = filteredCodes.reduce(
      (membersCodes: ICode[], currentCode: ICode) => {
        if (
          currentCode.access_code_type_id === AccessCodeTypes.CleaningCompleted ||
          currentCode.access_code_type_id === AccessCodeTypes.MaintenanceComplete
        ) {
          const matchingMember: number = membersCodes.findIndex(
            (code: ICode) => code.member_id === currentCode.member_id,
          );
          // If there is an existing row for the member, add to the exit_code
          if (matchingMember !== -1) {
            membersCodes[matchingMember] = {
              ...membersCodes[matchingMember],
              exit_code: currentCode.access_code,
            };
          } else {
            membersCodes.push(currentCode);
          }
        } else {
          membersCodes.push(currentCode);
        }
        return membersCodes;
      },
      [],
    );

    return reducedCodes;
  },
);

export default matchMembersStartAndComplete;
