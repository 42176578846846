import { createGuestsOptions } from 'Pages/Guests/GuestsFilterData';
import { logger } from 'lib/logger';
import { useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import { CHECK_IN, CHECK_OUT, GuestsFilterOptionResponse } from 'types/filter-type';
import { userSelector } from 'user/state/userSelectors';
import { UserState } from 'user/userTypes';
import { getGuestsFilterOptions } from '../guest/state/guestActions';
import { useFilterLocalStorage } from './useFilterLocalStorage';
import { useSyncFilters } from './useSyncFilters';

const activeFilterKeys = [
  'type',
  'access_code_status',
  'verification_status',
  'security_deposit_status',
  'access_approval',
  'check_in',
  'check_out',
];

export default function useGuestsFilters(propertyView: boolean) {
  const loggedInMember: UserState = useAppSelector(userSelector());
  const { getFilters, setFilters } = useFilterLocalStorage();
  const key = propertyView ? 'unit_guests' : 'guests';
  const { syncStatus, searchParams, filterGroups, setSyncStatus, syncFilters, addFilterGroups } =
    useSyncFilters({
      key: `${loggedInMember.user.id}_${key}`,
      persistFilters: setFilters,
      hydrateFilters: getFilters,
    });

  const onFilterChange = (params: URLSearchParams) => {
    syncFilters(params);
  };

  const onFilterClear = (key: string) => {
    const isDefaultKey = activeFilterKeys.includes(key);
    if (isDefaultKey) {
      if (key === CHECK_IN || key === CHECK_OUT) {
        searchParams.delete(`${key}_from`);
        searchParams.delete(`${key}_until`);
      } else {
        searchParams.delete(key);
      }
    }
    syncFilters(searchParams);
  };

  const isClearableFilters = (key: string) => {
    const isDefaultKey = activeFilterKeys.includes(key);
    if (isDefaultKey) {
      if (key === CHECK_IN || key === CHECK_OUT) {
        return (
          searchParams.get(`${key}_from`)?.length > 0 ||
          searchParams.get(`${key}_until`)?.length > 0
        );
      } else {
        return searchParams.get(key)?.length > 0;
      }
    }
    return false;
  };

  const updateTotalFilterLabelMobile = () => {
    const numOfAppliedFilter = Array.from(searchParams).filter(
      param => param[0] !== 'check_in_from' && param[0] !== 'check_out_from',
    ).length;
    return numOfAppliedFilter > 0 ? numOfAppliedFilter : '';
  };

  const updateChipLabel = (groupKey: string) => {
    let count = 0;
    let updatedLabel = '';
    const filterValue = searchParams.get(groupKey)?.split(',') ?? [];

    // find label based on filter value
    const selectedFilterGroup = filterGroups.find(
      filterGroup => filterGroup.groupName === groupKey,
    );

    if (groupKey === CHECK_IN || groupKey === CHECK_OUT) {
      const startValue = searchParams.get(`${groupKey}_from`);
      const endValue = searchParams.get(`${groupKey}_until`);

      if (startValue) {
        updatedLabel = startValue;
      }

      if (endValue && startValue !== endValue) {
        updatedLabel += ` to ${endValue}`;
      }
    } else {
      const selectedFilter = selectedFilterGroup?.options.find(
        option => option.name === filterValue[0],
      );

      if (filterValue.length != 0) {
        updatedLabel = selectedFilter.label;
      } else {
        updatedLabel = updatedLabel;
      }

      if (filterValue.length - 1 > 0) {
        count = filterValue.length - 1;
      } else {
        count = 0;
      }
    }

    return updatedLabel !== '' ? `${updatedLabel} ${count > 0 ? `+${count}` : ''}` : '';
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response: GuestsFilterOptionResponse = await getGuestsFilterOptions();
        const createdFilterData = createGuestsOptions(response);
        addFilterGroups(createdFilterData);
      } catch (error) {
        logger.error('Fetch Guests filter options failed, error: ', error);
        addFilterGroups([]);
      }
    }

    if (syncStatus === 'unsynched') {
      setSyncStatus('synching');
      if (!propertyView) {
        fetchData();
      } else {
        addFilterGroups([]);
      }
    }
  }, [addFilterGroups, propertyView, searchParams, setSyncStatus, syncStatus]);

  return {
    isSynched: syncStatus === 'synched',
    filterGroups,
    searchParams,
    onFilterChange,
    onFilterClear,
    isClearableFilters,
    updateChipLabel,
    updateTotalFilterLabelMobile,
  };
}
