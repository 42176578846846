import { Reducer, combineReducers } from '@reduxjs/toolkit';
import { Actions } from '../../types/actions';
import { PastAlertState, PastAlertDict } from '../../types/pastAlerts';

const initialPastAlertState: PastAlertDict = {};

const byId: Reducer<PastAlertDict> = (state = initialPastAlertState, action) => {
  switch (action.type) {
    case Actions.storeCompanyPastAlerts:
      return action.pastAlertsDict;
    default:
      return state;
  }
};
const propertyList: Reducer<number[]> = (state = [], action) => {
  switch (action.type) {
    case Actions.storeCompanyPastAlerts:
      return action.propertyIds;
    default:
      return state;
  }
};

const reducer: Reducer<PastAlertState> = combineReducers<PastAlertState>({
  list: propertyList,
  byId,
});

export default reducer;
