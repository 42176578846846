import { IThreshold } from './../threshold/thresholdType';
export enum AlertType {
  ALERT_ENVIRONMENT_ISSUE_ID = 200,
  ALERT_ENVIRONMENT_ISSUE_TEMPERATURE_LOW_ID = 201,
  ALERT_ENVIRONMENT_ISSUE_TEMPERATURE_MED_ID = 202,
  ALERT_ENVIRONMENT_ISSUE_TEMPERATURE_HIGH_ID = 203,
  ALERT_ENVIRONMENT_ISSUE_HUMIDITY_LOW_ID = 204,
  ALERT_ENVIRONMENT_ISSUE_HUMIDITY_MED_ID = 205,
  ALERT_ENVIRONMENT_ISSUE_HUMIDITY_HIGH_ID = 206,
  ALERT_COMPLIANCE_ISSUE_ID = 300,
  ALERT_COMPLIANCE_ISSUE_NOISE_LOW_ID = 301,
  ALERT_COMPLIANCE_ISSUE_NOISE_MED_ID = 302,
  ALERT_COMPLIANCE_ISSUE_NOISE_HIGH_ID = 303,
  ALERT_COMPLIANCE_ISSUE_NOISE_QUIET_ID = 307,
  ALERT_COMPLIANCE_ISSUE_NOISE_REG_ID = 308,
  ALERT_COMPLIANCE_ISSUE_CO2_LOW_ID = 304,
  ALERT_COMPLIANCE_ISSUE_CO2_MED_ID = 305,
  ALERT_COMPLIANCE_ISSUE_CO2_HIGH_ID = 306,
  ALERT_COMPLIANCE_ISSUE_CO2_OCCUPANCY = 501,
}

export enum IMetricType {
  TEMPERATURE = 1,
  HUMIDITY = 2,
  CO2 = 3,
  NOISE = 4,
  RESERVATION = 5,
  RESERVATION_CHECKIN = 6,
  RESERVATION_CHECKOUT = 7,
  CLEANING_IN_PROGRESS = 8,
  SCHEDULE = 9,
  CO2_SETBACK_THRESHOLD = 10,
}

export interface IAlert {
  metric_type: IMetricType;
  alert_type_id: AlertType;
  thresholds: IThreshold[];
  // This makes no sense...
  devices: string[];
}

export interface ActiveAlert {
  alert_id: number;
  alert_type_id: number;
  description: string;
  actioned_at: string;
  started_at: string;
  ended_at?: string;
  device_id: number;
}
export interface ActiveAlertDict {
  [Key: number]: ActiveAlert[];
}
export interface ActiveAlertState {
  list: number[];
  byId: ActiveAlertDict;
}
