import { Add, ExpandMore, SendOutlined } from '@mui/icons-material';
import {
  TitlebarBreadCrumb,
  TitlebarBreadCrumbChild,
  TitlebarButtonExpanded,
  TitlebarRoot,
  TitlebarTitleIcon,
} from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CommunicationsNewMenu, { MenuOptionType } from './CommunicationsNewMenu';

export default function CommunicationsTitlebar({
  displayAddButton,
}: {
  displayAddButton?: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const crumbs: TitlebarBreadCrumbChild[] = [
    {
      name: t('home'),
      href: '/',
    },
    {
      name: t('communications'),
    },
  ];

  const handleNewMenuClose = (selectedOption?: MenuOptionType) => {
    if (selectedOption) {
      navigate(`/communications/create/${selectedOption}`);
    }

    setAnchorEl(null);
  };

  return (
    <TitlebarRoot>
      <TitlebarTitleIcon>
        <SendOutlined />
      </TitlebarTitleIcon>

      <TitlebarBreadCrumb crumbs={crumbs} onClick={crumb => navigate(crumb.href)} />

      {displayAddButton && (
        <TitlebarButtonExpanded onClick={e => setAnchorEl(e.currentTarget)}>
          <Add />
          {t('communication')}
          <ExpandMore />
        </TitlebarButtonExpanded>
      )}

      <CommunicationsNewMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        handleClose={handleNewMenuClose}
      />
    </TitlebarRoot>
  );
}
