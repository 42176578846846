import axios from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';

const api = process.env.REACT_APP_API_URL;

export const validateCognitoToken = async (token?: string) => {
  if (token?.length === 0) {
    return false;
  }

  const decode: JwtPayload = jwtDecode(token);
  if (!decode.iss.includes('amazonaws')) {
    return false;
  }

  const headers = { Authorization: `Bearer ${token}` };
  const response = await axios.post(`${api}/auth/cognito/validate`, {}, { headers });
  return response.status === 200; // actual api response is success or not.
};
