import { Reducer } from '@reduxjs/toolkit';
import { Actions } from '../../../types/actions';

const metricSettings: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleTempSetting:
      return !state;
    case Actions.setTempSetting:
      return action.value;
    default:
      return state;
  }
};

export default metricSettings;
