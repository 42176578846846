import * as React from 'react';

import { DragLayerMonitorProps } from '@minoru/react-dnd-treeview/dist/types';
import { Box, Card, Typography } from '@mui/material';

export interface SortPreviewCardProps {
  monitorProps: DragLayerMonitorProps<{
    numOfChildren: number;
    type: string;
  }>;
  expandable: boolean;
  icon: React.ReactNode;
}

const SortPreviewCard = ({ monitorProps, expandable, icon }: SortPreviewCardProps) => {
  const { item } = monitorProps;

  return (
    <Box sx={{ maxWidth: '300px', opacity: '0.6' }}>
      <Card
        sx={{
          backgroundColor: expandable ? '#F6F2E9' : undefined,
          minHeight: '16px',
          padding: '14px',
          borderRadius: '4px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ color: 'rgba(0, 0, 0, 0.18)', marginRight: '14px' }}>{icon}</Box>
            <Typography variant='body2'>{item?.text}</Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default SortPreviewCard;
