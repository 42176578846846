import { combineReducers, Reducer } from '@reduxjs/toolkit';
import {
  MemberCounts,
  MemberDict,
  MemberFilterType,
  MemberState,
  RecordTrack,
} from 'member/memberType';
import { Actions } from 'types/actions';

export const initialMemberState: MemberDict = {};

//new action type, new action, new reducer

const byId: Reducer<MemberDict> = (state = initialMemberState, action) => {
  switch (action.type) {
    case Actions.addMembers:
      return {
        ...state,
        ...action.membersById,
      };
    case Actions.upsertMember:
    case Actions.insertMember:
      return {
        ...state,
        [action.member.id]: action.member,
      };
    case Actions.upsertMembers:
    case Actions.insertMembers:
      return {
        ...state,
        ...action.membersById,
      };
    case Actions.addTeamMember:
    case Actions.updateMembers:
      return {
        ...state,
        [action.member.id]: action.member,
      };
    case Actions.addPropertiesToMember:
      return {
        ...state,
        [action.memberId]: {
          ...state[action.memberId],
          properties: action.propertyIds,
        },
      };
    case Actions.hydrateMembers:
      return {
        ...state,
        ...action.membersById,
      };
    case Actions.enableMember:
      return {
        ...state,
        [action.memberId]: {
          ...state[action.memberId],
          status: 'active',
        },
      };
    case Actions.disableMember:
      return {
        ...state,
        [action.memberId]: {
          ...state[action.memberId],
          status: 'inactive',
        },
      };
    case Actions.deleteMember:
      return state;
    // for now
    default:
      return state;
  }
};

const initialRecordTrack: RecordTrack = {
  ids: [],
  pageNum: 0,
  numPerPage: 10,
  total_records: 0,
};

export const initialMeta: MemberCounts = {
  currentFilter: MemberFilterType.ALL_MEMBERS,
  isLoading: false,
  [MemberFilterType.ALL_MEMBERS]: initialRecordTrack,
  [MemberFilterType.CONTRACTOR]: initialRecordTrack,
  [MemberFilterType.INACTIVE]: initialRecordTrack,
  [MemberFilterType.STAFF]: initialRecordTrack,
  [MemberFilterType.EXCLUDE_GROUP]: initialRecordTrack,
  [MemberFilterType.GROUP]: initialRecordTrack,
};

const meta: Reducer<MemberCounts> = (state = initialMeta, action) => {
  switch (action.type) {
    case Actions.updateMemberCounts:
      const filterType: MemberFilterType = action.filterType;
      let updatedMetaForFilterType: RecordTrack = state[filterType];

      if (action.meta) {
        const ids =
          action.meta.pageNum === 0
            ? action.meta.ids
            : [...state[filterType].ids, ...action.meta.ids];

        updatedMetaForFilterType = {
          ...state[filterType],
          ...action.meta,
          ids: Array.from(new Set(ids)),
        };
      }

      return {
        ...state,
        currentFilter: action.filterType ?? state.currentFilter,
        isLoading: action.isLoading ?? state.isLoading,
        [action.filterType]: updatedMetaForFilterType,
      };
    default:
      return state;
  }
};

const reducer: Reducer<MemberState> = combineReducers<MemberState>({
  byId,
  meta,
});

export default reducer;
