import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from 'redux/reducers';
import { IUser, UserState } from 'user/userTypes';

const getMembers = (state: ApplicationState) => state.members;
const getUser = (state: ApplicationState) => state.user.user;
const getUserMeta = (state: ApplicationState) => state.user;

export const currentUserSelector = () =>
  createSelector([getMembers, getUser], (members, user) => members?.byId[user.id]);

export const userStateSelector = () => createSelector([getUserMeta], (user: UserState) => user);

export const showAccessCodeSelector = () =>
  createSelector([getUserMeta], (user: UserState) => user.features?.incident_payment_enabled);

export const currentUserIdSelector = () => createSelector([getUser], (user: IUser) => user.id);

export const userSelector = () => createSelector([getUserMeta], (user: UserState) => user);

// export const userPermissionSelector = () =>
//   createSelector(
//     [getMembers, getUser],
//     (members: MemberState, user: UserState) => members?.byId[user.id]?.role !== 'basic-user',
//   );

export const userPermissionSelector = () =>
  createSelector([userStateSelector()], (user: UserState) => user.role !== 'basic-user');
