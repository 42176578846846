import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, IconButton, Stack, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SettingCard } from '@operto/ui-library';
import { generateSmwCredentials } from 'api/smw';
import { AxiosResponse } from 'axios';
import { getCompanyId } from 'company/state/companySelectors';
import { logger } from 'lib/logger';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSnackbar } from 'redux/actions/ui';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { IntegrationsFormProps } from '../IntegrationsSideDrawer';

export type SMWCreds = {
  apiId: string;
  apiKey: string;
};

type YourKeyPlusIntegrationsFormProps = IntegrationsFormProps & {
  loading?: boolean;
  smwCreds: SMWCreds;
  onChange: (smwCreds: SMWCreds) => void;
};

export const YourKeyPlusIntegrationForm = ({
  loading,
  smwCreds,
  onChange,
}: YourKeyPlusIntegrationsFormProps) => {
  const dispatch = useDispatch();
  const companyId = useSelector(getCompanyId());

  const displaySuccess = (message: string) => {
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message,
        variant: SnackbarVariant.SUCCESS,
      }),
    );
  };

  const displayError = (message: string) => {
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message,
        variant: SnackbarVariant.ERROR,
      }),
    );
  };

  const generateCredentials = async () => {
    try {
      const response = await generateSmwCredentials(companyId);
      processResponse(response);
    } catch (error) {
      logger.error(error);
      displayError('An error occurred while generating credentials');
    }
  };

  const processResponse = (response: AxiosResponse) => {
    if (response?.status !== 200) {
      displayError('An error occurred while generating credentials');
      return;
    }

    const { api_id: apiId, api_key: apiKey } = response.data;
    onChange({ apiId, apiKey });
    displaySuccess('Successfully generated');
  };

  const onCopy = () => displaySuccess('Copied Successfully!');

  return (
    <Stack m={1}>
      <SettingCard>
        <LoadingContainer loading={loading}>
          <TextField
            disabled
            label='API ID'
            variant='outlined'
            value={mask(smwCreds.apiId)}
            InputProps={{
              sx: { backgroundColor: grey[100] },
              endAdornment: (
                <CopyToClipboard onCopy={onCopy} text={smwCreds.apiId}>
                  <IconButton size='small' color='primary'>
                    <ContentCopyIcon />
                  </IconButton>
                </CopyToClipboard>
              ),
            }}
            sx={{
              mx: 0,
              width: '100%',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          />
          <TextField
            disabled
            variant='outlined'
            label='API Key'
            value={mask(smwCreds.apiKey)}
            InputProps={{
              sx: { backgroundColor: grey[100] },
              endAdornment: (
                <CopyToClipboard onCopy={onCopy} text={smwCreds.apiKey}>
                  <IconButton size='small' color='primary'>
                    <ContentCopyIcon />
                  </IconButton>
                </CopyToClipboard>
              ),
            }}
            sx={{
              mx: 0,
              width: '100%',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          />
          <Button size='medium' onClick={generateCredentials}>
            {smwCreds.apiId && smwCreds.apiKey ? 'Re-Generate' : 'Generate'}
          </Button>
        </LoadingContainer>
      </SettingCard>
    </Stack>
  );
};

const mask = (str: string) => (str ? str.slice(-4).padStart(16, '\u25cf') : str);
