import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, Link, Stack, SxProps, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Tag } from '@operto/tags-shared';
import { NamedChip } from 'Pages/Groups/Common/NamedChip';
import {
  computeCurrentListItems,
  computeItemsToMove,
  getPinnedRowClassName,
  pinnedComparator,
} from 'Pages/Groups/EditGroup/group-utils';
import { UnitsSelectionTable, renderUnits } from 'Pages/Marketplace/Common/UnitSelectionTable';
import { UpsellFields } from 'Pages/Marketplace/UpsellPage';
import React, { useEffect, useState } from 'react';
import { Group } from 'redux/groups/types';
import { Unit, UnitFilterType } from 'redux/units/types';
import {
  addButtonStyle,
  addRemoveButtonContainerStyle,
  groupNameChipStyle,
  itemsTableStyle,
  removeButtonStyle,
  tableContainerStyle,
} from 'ui-library/Components/table/table-styles';

interface AssignUnitSectionProps {
  unitAssigned: number[];
  isLoading: boolean;
  isFetching: boolean;
  units: Unit[];
  groups: Group[];
  updateUnitFields: (field: keyof UpsellFields['unitAssigned'], value: number[]) => void;
  groupsAssigned: number[];
  updateGroupsFields: (field: keyof UpsellFields['groupsAssigned'], value: number[]) => void;
}

const createColumnDef = (selectedUnits: number[]): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: selectedUnits.length === 0 ? 'Units' : `${selectedUnits.length} selected`,
      flex: 1,
      valueGetter: params => params.row?.isPinned,
      renderCell: renderUnits,
      sortable: false,
      sortComparator: pinnedComparator,
    },
    {
      field: 'groups',
      headerName: 'Group',
      width: 300,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <NamedChip
          names={params.row.groups?.map((group: Group) => group.name)}
          sx={groupNameChipStyle}
        />
      ),
    },
  ];
};

export const AssignUnitSection = ({
  isLoading,
  isFetching,
  unitAssigned,
  units,
  groups,
  updateUnitFields,
  groupsAssigned,
  updateGroupsFields,
}: AssignUnitSectionProps) => {
  const [unitsList, setUnitsList] = useState<Unit[]>([]);
  const [selectedUnitsInGroup, setSelectedUnitsInGroup] = useState<number[]>([]);
  const [selectedUnitsNotInGroup, setSelectedUnitsNotInGroup] = useState<number[]>([]);
  const [pinnedUnitsInGroup, setPinnedUnitsInGroup] = useState<Unit[]>([]);
  const [pinnedUnitsNotInGroup, setPinnedUnitsNotInGroup] = useState<Unit[]>([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [filterAssignedGroups, setFilterAssignedGroups] = useState<Group[]>([]);

  useEffect(() => {
    if (units && groupsAssigned.length > 0) {
      const groupIds = groupsAssigned.map((group: Tag) => group.id);
      const filteredUnits = units.filter(unit => {
        return unit.groups.some(group => groupIds.includes(group.id));
      });
      setUnitsList(filteredUnits);
    } else {
      setUnitsList(units);
    }
  }, [units, groupsAssigned]);

  useEffect(() => {
    if (units?.length && unitsList && unitAssigned) {
      const assignedUnitIds = unitAssigned.map(Number) || [];
      const unitsInGroup = units.filter((unit: Unit) => assignedUnitIds.includes(unit.id));
      const unitsNotInGroup = unitsList.filter((unit: Unit) => !assignedUnitIds.includes(unit.id));
      setPinnedUnitsInGroup(unitsInGroup);
      setPinnedUnitsNotInGroup(unitsNotInGroup);
    }
  }, [units, unitsList, unitAssigned]);

  useEffect(() => {
    if ((groups?.length && unitAssigned?.length, units?.length)) {
      const filteredAssignedUnits = units
        .filter(unit => unitAssigned.includes(unit.id))
        .map(unit => unit.groups);
      const uniqAssignedGroups = filteredAssignedUnits
        .reduce((acc, val) => acc.concat(val), [])
        .filter((group, index, self) => self.findIndex(t => t.id === group.id) === index)
        .map(group => groups.find(g => g.id === group.id));
      setFilterAssignedGroups(uniqAssignedGroups);
    }
  }, [unitAssigned, units, groups]);

  useEffect(() => {
    if (selectedGroup?.length && pinnedUnitsInGroup?.length) {
      const assignedUnitIds = selectedGroup?.map(item => item.id) || [];
      const unitsInGroup = pinnedUnitsInGroup?.filter((unit: Unit) =>
        unit.groups.some(group => assignedUnitIds.includes(group.id)),
      );
      setPinnedUnitsInGroup(unitsInGroup);
    } else if (units?.length && unitAssigned?.length) {
      const assignedUnitIds = unitAssigned.map(Number) || [];
      const unitsInGroup = units.filter((unit: Unit) => assignedUnitIds.includes(unit.id));
      setPinnedUnitsInGroup(unitsInGroup);
    } else {
      setPinnedUnitsInGroup([]);
    }
  }, [selectedGroup, units, unitAssigned, pinnedUnitsInGroup]);

  const handleAdd = () => {
    const unitsToMove: Unit[] = computeItemsToMove(pinnedUnitsNotInGroup, selectedUnitsNotInGroup);

    updateUnitFields('units', [...unitAssigned, ...unitsToMove.map(unit => unit.id)]);

    setPinnedUnitsNotInGroup(prevPinnedUnitsNotInGroup =>
      prevPinnedUnitsNotInGroup.filter(unit => !selectedUnitsNotInGroup.includes(unit.id)),
    );

    setSelectedUnitsNotInGroup([]);
  };

  const handleRemove = () => {
    const unitsToMove: Unit[] = computeItemsToMove(pinnedUnitsInGroup, selectedUnitsInGroup);

    setPinnedUnitsNotInGroup(prevPinnedUnitsNotInGroup => [
      ...prevPinnedUnitsNotInGroup,
      ...unitsToMove,
    ]);

    updateUnitFields(
      'units',
      unitAssigned.filter((unitId: number) => !selectedUnitsInGroup.includes(unitId)),
    );

    setSelectedUnitsInGroup([]);
  };

  const handleSelectedUnits = (selectedUnitIds: number[], unitType: UnitFilterType) => {
    if (unitType === UnitFilterType.GROUP) {
      setSelectedUnitsInGroup(selectedUnitIds);
    } else {
      setSelectedUnitsNotInGroup(selectedUnitIds);
    }
  };

  const handleTitleInfo = (tableType: UnitFilterType) => {
    if (tableType === UnitFilterType.GROUP) {
      return `${pinnedUnitsInGroup.length} units assigned`;
    } else {
      return `${pinnedUnitsNotInGroup.length} units NOT assigned`;
    }
  };

  return (
    <>
      <Box sx={textDescriptionContiner}>
        <Typography>
          Use the{' '}
          <Link href='/groups' color='primary' fontWeight={600} underline='hover'>
            Groups
          </Link>{' '}
          page to categorize your upsells. For example, create a &quot;Pet-friendly units&quot;
          group to target specific opportunities.
        </Typography>
      </Box>
      <Stack direction='row' height='100%' width='100%' sx={groupSelectionTableContainerStyle}>
        <Box sx={tableContainerStyle} data-testid='in-group-selection-table'>
          <UnitsSelectionTable
            loading={isLoading || isFetching}
            groups={filterAssignedGroups}
            groupsAssigned={selectedGroup}
            selectionModel={selectedUnitsInGroup}
            updateGroupsFields={setSelectedGroup}
            onSelectionChange={unitIds => handleSelectedUnits(unitIds, UnitFilterType.GROUP)}
            rows={computeCurrentListItems(
              pinnedUnitsInGroup,
              pinnedUnitsInGroup,
              pinnedUnitsNotInGroup,
            )}
            getRowClassName={getPinnedRowClassName}
            columns={createColumnDef(selectedUnitsInGroup)}
            tableInfo={handleTitleInfo(UnitFilterType.GROUP)}
            isRowSelectable={() => !(selectedUnitsNotInGroup.length > 0)}
            disableSearch={selectedUnitsNotInGroup.length > 0}
            sortingMode='server'
            sx={itemsTableStyle}
          />
        </Box>
        <Box sx={addRemoveButtonContainerStyle}>
          <Button
            startIcon={<KeyboardArrowLeftIcon />}
            sx={addButtonStyle(selectedUnitsNotInGroup.length > 0)}
            onClick={handleAdd}
            disabled={selectedUnitsNotInGroup.length === 0}
          >
            Add
          </Button>
          <Button
            endIcon={<KeyboardArrowRightIcon />}
            sx={removeButtonStyle(selectedUnitsInGroup.length > 0)}
            onClick={handleRemove}
            disabled={selectedUnitsInGroup.length === 0}
          >
            Remove
          </Button>
        </Box>
        <Box sx={tableContainerStyle} data-testid='not-in-group-selection-table'>
          <UnitsSelectionTable
            loading={isLoading || isFetching}
            groups={groups}
            groupsAssigned={groupsAssigned}
            updateGroupsFields={(groups: Tag[]) => updateGroupsFields('groups', groups)}
            selectionModel={selectedUnitsNotInGroup}
            onSelectionChange={unitIds =>
              handleSelectedUnits(unitIds, UnitFilterType.EXCLUDE_GROUP)
            }
            rows={computeCurrentListItems(
              pinnedUnitsNotInGroup,
              pinnedUnitsNotInGroup,
              pinnedUnitsInGroup,
            )}
            getRowClassName={getPinnedRowClassName}
            columns={createColumnDef(selectedUnitsNotInGroup)}
            tableInfo={handleTitleInfo(UnitFilterType.EXCLUDE_GROUP)}
            isRowSelectable={() => !(selectedUnitsInGroup.length > 0)}
            disableSearch={selectedUnitsInGroup.length > 0}
            sortingMode='server'
            sx={itemsTableStyle}
          />
        </Box>
      </Stack>
    </>
  );
};

const groupSelectionTableContainerStyle: SxProps = {
  height: 'calc(100vh - 220px)',
};

const textDescriptionContiner: SxProps = {
  marginBottom: 3,
  marginTop: 2,
  width: '100%',
};
