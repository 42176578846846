import { NotifiableError } from '@bugsnag/js';
import * as groupApi from 'api/groupAPI';
import * as api from 'api/groups';
import { AxiosResponse } from 'axios';
import { getCodes } from 'code/state/codeAction';
import { OpertoLogger } from 'Logger/logger';
import { toggleSlidebar, toggleSnackbar } from 'redux/actions/ui';
import { AppDispatch } from 'redux/store';
import { Actions } from 'types/actions';
import { FilterType, Group, GroupDict, PublicAccessState } from 'types/groups';
import { SlidebarType, SnackbarTypes, SnackbarVariant } from 'types/ui';

export const getGroups = () => async (dispatch: AppDispatch) => {
  try {
    const data = await api.getGroups();
    const { groups } = data.data.data;
    const groupList: number[] = [];
    const groupDict: GroupDict = {};
    groups?.forEach((each: Group) => {
      groupList.push(each.id);
      groupDict[each.id] = each;
    });
    dispatch({
      type: Actions.hydrateGroups,
      groupList,
      groupDict,
    });
  } catch (err) {
    OpertoLogger.Log(err);
  }
};

export const getGroupsPaginated =
  ({
    locationType,
    pageNum = 0,
    numPerPage = 50,
    includesPrivate = false,
    keyword,
  }: {
    locationType?: FilterType;
    pageNum?: number;
    numPerPage?: number;
    includesPrivate?: boolean;
    keyword?: string;
  } = {}) =>
  async (dispatch: AppDispatch) => {
    try {
      const data = await groupApi.getGroupsPaginated(
        locationType,
        pageNum,
        numPerPage,
        includesPrivate,
        keyword,
      );
      const filterType = locationType ? locationType : FilterType.ALL;
      const groups = data.data.data;
      if (groups.total_records && groups.records.length > 0) {
        const groupList: number[] = [];
        const groupDict: GroupDict = {};
        groups.records.forEach((each: Group) => {
          groupList.push(each.id);
          groupDict[each.id] = each;
        });
        dispatch({
          type: pageNum > 0 ? Actions.insertGroups : Actions.hydrateGroups,
          groupList,
          groupDict,
        });
        dispatch({
          type: Actions.updateGroupCounts,
          meta: {
            total_records: groups?.total_records,
            numPerPage: groups.numPerPage,
            pageNum: groups.pageNum,
          },
          filterType,
        });
      }
    } catch (err) {
      OpertoLogger.Log(err);
    }
  };

export const createGroup =
  (
    name: string,
    description: string,
    property_ids: number[],
    days: Record<string, unknown>,
    hours: Record<string, unknown>,
    accessGroup: boolean,
    group_type: string = null,
  ) =>
  async (dispatch: AppDispatch) => {
    if (group_type === 'member') {
      group_type = null;
    }
    let groupData = {};
    if (accessGroup) {
      groupData = {
        name,
        description,
        property_ids,
        time_frame: {
          days,
          hours,
        },
        group_type,
      };
    } else {
      groupData = {
        name,
        description,
        property_ids,
        group_type: 'location',
      };
    }
    try {
      const data_1 = await api.createGroup(groupData);
      const group = data_1.data.data;
      dispatch({
        type: Actions.upsertGroup,
        group,
      });
      dispatch(toggleSlidebar(SlidebarType.CLOSE, ''));
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Adding a new location was successful.',
          variant: SnackbarVariant.SUCCESS,
        }),
      );
    } catch (err) {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Adding a new location was not successful.',
          variant: SnackbarVariant.ERROR,
        }),
      );
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: `ERROR: ${err.message}`,
          variant: SnackbarVariant.ERROR,
        }),
      );
      OpertoLogger.Log(err);
    }
  };

export const updateGroup =
  (newGroup: Record<string, unknown> & { type: string }) => (dispatch: AppDispatch) => {
    const addAndRemoveUnitFromGroup = (group: Group, unitIds: number[], groupId: number) => {
      return new Promise((resolve, reject) => {
        if (unitIds?.length < group.properties.length) {
          const difference = group.properties?.filter(
            property => unitIds?.indexOf(property.id) === -1,
          );
          difference.forEach(unitId =>
            api
              .removeUnitFromGroup(unitId.id, groupId, newGroup.type)
              .then((data: AxiosResponse) => {
                resolve(data);
              })
              .catch((err: NotifiableError) => {
                reject(err);
              }),
          );
        } else {
          api
            .addUnitToGroup(unitIds, groupId, newGroup.type)
            .then((data: AxiosResponse) => {
              resolve(data);
            })
            .catch((err: AxiosResponse) => {
              reject(err);
            });
        }
      });
    };

    api
      .updateGroup(newGroup)
      .then((data: AxiosResponse) => {
        const group = data.data.data;
        const unitIds = newGroup.properties as number[];
        const groupId = group.id;

        Promise.all([addAndRemoveUnitFromGroup(group, unitIds, groupId)])
          .then(() => {
            dispatch({
              type: Actions.updateGroup,
              group,
            });
            dispatch(toggleSlidebar(SlidebarType.CLOSE, ''));

            dispatch(
              toggleSnackbar(SnackbarTypes.OPEN, {
                message: 'Updating the location was successful.',
                variant: SnackbarVariant.SUCCESS,
              }),
            );
          })
          .catch(err => {
            dispatch(
              toggleSnackbar(SnackbarTypes.OPEN, {
                message: 'Updating the location was not successful.',
                variant: SnackbarVariant.ERROR,
              }),
            );
            OpertoLogger.Log(err);
          });
      })
      .catch((err: NotifiableError) => {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Updating the location was not successful.',
            variant: SnackbarVariant.ERROR,
          }),
        );
        OpertoLogger.Log(err);
      });
  };
export const deleteGroup = (id: number) => (dispatch: AppDispatch) => {
  api
    .deleteGroup(id)
    .then(() => {
      dispatch({
        type: Actions.deleteGroup,
        id,
      });
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Deleting the location was successful.',
          variant: SnackbarVariant.SUCCESS,
        }),
      );
    })
    .catch((err: Error) => {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Deleting the location was not successful.',
          variant: SnackbarVariant.ERROR,
        }),
      );
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: `ERROR: ${err.message}`,
          variant: SnackbarVariant.ERROR,
        }),
      );
      OpertoLogger.Log(err);
    });
};

// TOTALLY NEEDS TO BE OPTIMIZED. ERROR IS WRONG. LOOPING IS WRONG. the thing IS WRONG
export const addUnitsToUnitGroup =
  (unitIds: number[], groupId: number) => (dispatch: AppDispatch) =>
    api
      .addUnitToUnitGroup(unitIds, groupId)
      .then(() => {
        dispatch({
          type: Actions.hideAddUnitToUnitGroup,
        });
        dispatch({
          type: Actions.hideAddGroupsToMember,
        });

        dispatch({
          type: Actions.updateGroupAttachedUnits,
          unitIds,
          groupId,
        });
        dispatch(getCodes());
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });

// export const removeUnitFromGroup = (unitId: number) => (dispatch: AppDispatch, getState: ()=>ApplicationState) => {
//   const { groupId } = getState().ui.unitGroup;
//   api
//     .removeUnitFromGroup(unitId, groupId)
//     .then((data: any) => {
//       const propertyIds: number[] = data.data.data.map((each: any) => each.id);
//       dispatch({
//         type: Actions.replaceGroupAttachedUnits,
//         unitIds: propertyIds,
//         groupId,
//       });
//       dispatch(getCodes());
//     })
//     .catch((err: any) => {
//       OpertoLogger.Log(err);
//     });
// };

// TOTALLY NEEDS TO BE OPTIMIZED. ERROR IS WRONG. LOOPING IS WRONG. the thing IS WRONG
export const removeUnitFromCurrentPropertyGroup =
  (unitId: number, groupId: number) => (dispatch: AppDispatch) =>
    api
      .removeUnitFromGroup(unitId, groupId)
      .then((data: AxiosResponse) => {
        const propertyIds: number[] = data.data.data;
        dispatch({
          type: 'REPLACE_GROUPS_ATTACHED_UNITS',
          propertyIds,
          groupId,
        });
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });

export const togglePublicAccess = (group: Group, checked: boolean) => (dispatch: AppDispatch) => {
  if (checked) {
    groupApi
      .lockPublicAccess(group.id)
      .then((data: AxiosResponse) => {
        if (data.data.status === 'success') {
          group.public_access.state = PublicAccessState.Off;
        }
        dispatch({
          type: Actions.updateGroup,
          group,
        });
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  } else {
    groupApi
      .publicAccessOn(group.id)
      .then((data: AxiosResponse) => {
        if (data.data.status === 'failure') {
          group.public_access.locked = data.data.data.locked;
          group.public_access.unlocked = data.data.data.unlocked;
          group.public_access.state = PublicAccessState.Warning;
        } else if (data.data.data.status === 'success') {
          group.public_access_state = PublicAccessState.On;
        }
        dispatch({
          type: Actions.updateGroup,
          group,
        });
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  }
};
