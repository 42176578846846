import RightSideTopbar from 'Common/Slidebar/components/RightSideTopbar';
import ReservationsListTable from 'Common/Tables/Reservations/ReservationsListTable';
import * as React from 'react';

import { MainContentContainer } from 'ui-library/Styled/sidebar';

export interface ReservationListContainerProps {
  roomStatus: string;
  handleClose: () => void;
}

const ReservationListContainer = (props: ReservationListContainerProps) => {
  const { roomStatus, handleClose } = props;

  const listType = roomStatus === 'guest-checking-in' ? 'check-in' : 'check-out';

  return (
    <MainContentContainer>
      <RightSideTopbar title={`Guest ${listType} list`} handleClose={handleClose} />
      <ReservationsListTable roomStatus={roomStatus} />
    </MainContentContainer>
  );
};

export default ReservationListContainer;
