// @ts-nocheck

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import { AddCard } from '@operto/ui-library';
import { IMember } from 'member/memberType';
import React from 'react';
import { FormHeader } from 'ui-library/Styled/sidebar';

interface NotificationsContainerProps {
  onChange: () => void;
  data: IMember;
}
const NotificationsContainer = ({ onChange, data }: NotificationsContainerProps) => {
  const valueChanger = (e: React.ChangeEvent<HTMLInputElement>) => {
    const notificationName = e.target.name;
    const notificationValue = e.target.checked;
    const notificationObj = data.notification_settings;
    notificationObj[notificationName] = notificationValue;
    onChange('notification_settings', notificationObj);
  };

  const smsEmailNotificationHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const notificationName = e.target.name;
    const notificationValue = e.target.checked;
    onChange(notificationName, notificationValue);
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <AddCard title='Notification Channels'>
            <Grid container direction='column'>
              <FormHeader>
                Select how you would like to be notified of system and activity notifications.
              </FormHeader>
              <Grid container direction='column'>
                <FormControlLabel
                  control={
                    <Switch
                      name='is_email_notification'
                      color='primary'
                      onChange={smsEmailNotificationHandler}
                      checked={data.is_email_notification}
                      data-testid='email_switch'
                    />
                  }
                  label='Email'
                />
                <FormControlLabel
                  control={
                    <Switch
                      name='is_sms_notification'
                      color='primary'
                      onChange={smsEmailNotificationHandler}
                      checked={data.is_sms_notification}
                      data-testid='sms_switch'
                    />
                  }
                  label='SMS'
                />
              </Grid>
            </Grid>
          </AddCard>
        </Grid>

        <Grid item md={6} xs={12}>
          <AddCard title='Service Notifications'>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={valueChanger}
                  checked={data.notification_settings?.['check-in']}
                  name='check-in'
                  data-testid='checkin_checkbox'
                />
              }
              label='Guest Check In'
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={valueChanger}
                  checked={data.notification_settings?.['check-out']}
                  name='check-out'
                  data-testid='checkout_checkbox'
                />
              }
              label='Guest Checkout'
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={valueChanger}
                  checked={data.notification_settings?.['clean-start']}
                  name='clean-start'
                  data-testid='cleanstart_checkbox'
                />
              }
              label='Cleaning Started'
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={valueChanger}
                  checked={data.notification_settings?.['clean-complete']}
                  name='clean-complete'
                  data-testid='cleancomplete_checkbox'
                />
              }
              label='Cleaning Completed'
            />
          </AddCard>
        </Grid>
        <Grid item xs={12}>
          <AddCard title='Property Alerts'>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={valueChanger}
                  checked={data.notification_settings?.['device-issue']}
                  name='device-issue'
                  data-testid='deviceissue_checkbox'
                />
              }
              label='Lock or connected device issues'
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={valueChanger}
                  checked={data.notification_settings?.['environment-issue']}
                  name='environment-issue'
                  data-testid='environmentissue_checkbox'
                />
              }
              label='Environmental issues (temp/energy/etc)'
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={valueChanger}
                  checked={data.notification_settings?.['compliance-issue']}
                  name='compliance-issue'
                  data-testid='complianceissue_checkbox'
                />
              }
              label='Guest compliance issues (noise/occupancy)'
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={valueChanger}
                  checked={data.notification_settings?.['access-code-failed-install']}
                  name='access-code-failed-install'
                  data-testid='accesscodefailedinstall_checkbox'
                />
              }
              label='Code Install Issues'
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={valueChanger}
                  checked={data.notification_settings?.['maintenance-complete']}
                  name='maintenance-complete'
                  data-testid='maintenancecomplete_checkbox'
                />
              }
              label='Maintenance Completed'
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={valueChanger}
                  checked={data.notification_settings?.['maintenance-start']}
                  name='maintenance-start'
                  data-testid='maintenancestart_checkbox'
                />
              }
              label='Maintenance Started'
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={valueChanger}
                  checked={data.notification_settings?.['chat-notify']}
                  name='chat-notify'
                  data-testid='chatnotify_checkbox'
                />
              }
              label='Chat Notify'
            />
          </AddCard>
        </Grid>
      </Grid>
    </>
  );
};

export default NotificationsContainer;
