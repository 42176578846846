import Add from '@mui/icons-material/Add';
import { Box, Button, Link, PopoverOrigin, Stack, SxProps, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CommunicationsBlankPagePanelCards from './CommunicationsBlankPagePanelCards';
import CommunicationsNewMenu, { MenuOptionType } from './CommunicationsNewMenu';

export default function CommunicationsBlankPagePanel() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const bodyMessageArray = t('template_blank_page_body').split('{automate}');

  const menuAnchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  };

  const menuTransformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'center',
  };

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const handleAutomateClick = () => {
    navigate('/automate');
  };

  const handleNewTemplateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleNewMenuClose = (selectedOption?: MenuOptionType) => {
    if (selectedOption) {
      navigate(`/communications/create/${selectedOption}`);
    }

    setMenuAnchorEl(null);
  };

  return (
    <Stack sx={rootStyles}>
      <Stack sx={innerStyles}>
        <Typography variant='h2'>{t('template_blank_page_title')}</Typography>

        <Typography variant='body-lg-400'>
          {bodyMessageArray[0]}
          <Typography variant='body-lg-600' component={'span'}>
            <Link onClick={handleAutomateClick} sx={linkStyles} underline='none'>
              {t('automate')}
            </Link>
          </Typography>
          {bodyMessageArray[1]}
        </Typography>

        <Box>
          <Button
            variant='contained'
            color='primary'
            startIcon={<Add />}
            sx={buttonStyles}
            onClick={handleNewTemplateClick}
          >
            {t('communication')}
          </Button>
        </Box>

        <CommunicationsNewMenu
          anchorEl={menuAnchorEl}
          open={menuOpen}
          handleClose={handleNewMenuClose}
          anchorOrigin={menuAnchorOrigin}
          transformOrigin={menuTransformOrigin}
        />
      </Stack>

      <CommunicationsBlankPagePanelCards handleNewMenuClose={handleNewMenuClose} />
    </Stack>
  );
}

const rootStyles: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '776px',
  gap: '50px',
  padding: '24px',
};

const innerStyles: SxProps = {
  maxWidth: '500px',
  gap: '16px',
  textAlign: 'center',
};

const buttonStyles: SxProps = {
  textTransform: 'none',
  minWidth: '156px',
  borderRadius: '6px',
};

const linkStyles: SxProps = {
  cursor: 'pointer',
};
