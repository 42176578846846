import { createSelector } from '@reduxjs/toolkit';
import { AccessCodeTypes, CodeState, ICode } from 'code/codeType';
import { PropertyState } from 'property/propertyType';
import { ApplicationState } from 'redux/reducers';

const getCodes = (state: ApplicationState) => state.codes;
const getProperties = (state: ApplicationState) => state.properties;

export const getCodeSelector = (codeId: number) =>
  createSelector([getCodes], (codes: CodeState) => codes.byId[codeId]);

export const backupCodesSelector = (propertyId: number) =>
  createSelector([getCodes, getProperties], (codes: CodeState, properties: PropertyState) => {
    const backupCodes: ICode[] = codes.list
      .map((each: number) => codes.byId[each])
      .filter(
        (code: ICode) =>
          code?.property_id === propertyId &&
          code.access_code_type_id === AccessCodeTypes.BackupCode &&
          (code.installed_at !== null ||
            (properties?.byId[propertyId]?.lock_enabled &&
              properties?.byId[propertyId]?.property_enabled === false)),
      );

    return backupCodes;
  });
export default backupCodesSelector;
