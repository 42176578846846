import {
  DragLayerMonitorProps,
  DropOptions,
  getBackendOptions,
  MultiBackend,
  NodeModel,
  RenderParams,
  Tree,
} from '@minoru/react-dnd-treeview';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FileIcon from '@mui/icons-material/DescriptionOutlined';
import FolderIcon from '@mui/icons-material/FolderOpenOutlined';
import { Box } from '@mui/material';
import { SettingCard } from '@operto/ui-library';
import { UNCATEGORIZED_ID } from 'helper/helper';
import { GuidesDetailsCategoriesForm } from 'Pages/GuestPortal/Guides/GuidesDetails/Categories/GuidesDetailsCategoriesForm';
import GuidesDetailsPagesForm from 'Pages/GuestPortal/Guides/GuidesDetails/Pages/GuidesDetailsPagesForm';
import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import { toggleSnackbar } from 'redux/actions/ui';
import { ICategory, IPage, Order, useGuestPortal, useGuestPortalStatus } from 'redux/guestportal';
import { useGuidesCategories } from 'redux/guestportal/hooks/useGuidesCategories';
import { useGuidesPages } from 'redux/guestportal/hooks/useGuidesPages';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import SortCard from 'ui-library/Components/card/SortCard';
import SortPreviewCard from 'ui-library/Components/card/SortPreviewCard';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import ActionSection from './ActionSection';
import PlaceholderLine from './PlaceholderLine';

const TreeItems = {
  PAGE: 'content',
  CATEGORY: 'category',
} as const;

type TreeItemsType = (typeof TreeItems)[keyof typeof TreeItems];

type TreeItemData = {
  type: TreeItemsType;
  numOfChildren: number;
};

type TreeItem = {
  id: string;
  parent: string | number;
  droppable: boolean;
  text: string;
  data: TreeItemData;
};

export const OrderingPage = () => {
  const { categoriesList, pagesList, categories, pages } = useGuestPortal();
  const { isLoading } = useGuestPortalStatus();
  const { page, save: savePage, move, sort: sortPages } = useGuidesPages();
  const { category, save: saveCategory, sort: sortCategories } = useGuidesCategories();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { guideId } = useParams();
  const [orderingInProgess, setOrderingInProgess] = useState(false);

  const treeItems = useMemo(() => {
    // TODO: UNCATEGORIZED
    // This ordering should come from the BE

    const result: TreeItem[] = [];
    categoriesList.forEach(c => {
      if (c.category_id === UNCATEGORIZED_ID) {
        return;
      }

      result.push({
        id: c.category_id,
        parent: 0,
        droppable: true,
        text: `${c.category} - ${c.number_of_contents}`,
        data: { type: 'category', numOfChildren: c.number_of_contents },
      });
    });

    pagesList.forEach(p => {
      result.push({
        id: p.content_id,
        parent: p.category_id === UNCATEGORIZED_ID ? 0 : p.category_id,
        droppable: false,
        text: p.title,
        data: { type: 'content', numOfChildren: 0 },
      });
    });

    return result;
  }, [categoriesList, pagesList]);

  const handleDrop = async (
    newTreeData: TreeItem[],
    options: DropOptions<{ numOfChildren: number; type: string }>,
  ) => {
    setOrderingInProgess(true);

    // dropTargetId = 0 means the root of the tree
    const { dragSource, dropTargetId, dropTarget } = options;

    // assumption: category is always before pages, and starting index 0
    const categoryMaxIndex =
      newTreeData.filter(data => data?.data?.type === TreeItems.CATEGORY).length - 1;

    // pages index starts after the last category index
    const pageMinIndex = categoryMaxIndex + 1;
    const pageMaxIndex =
      newTreeData.filter(data => data?.data?.type === TreeItems.PAGE).length - 1 + pageMinIndex;

    // dropped at index:
    const dropIndex = newTreeData.findIndex(data => data.id === dragSource.id);

    const isSourceCategory = dragSource?.data?.type === TreeItems.CATEGORY;

    const isTargetInCategoryArea = dropIndex <= categoryMaxIndex;
    const isTargetInPageArea = dropIndex >= pageMinIndex && dropIndex <= pageMaxIndex;

    if (isSourceCategory) {
      if (dropTargetId !== 0) {
        // category is dragged into another category
        handleUserError(
          'Categories can’t be moved into other categories.  Please drag between content blocks to re-order.',
        );
      } else if (!isTargetInCategoryArea) {
        // category is dragged to page area below all categories
        handleUserError('Please place categories above the uncategorized pages.');
      } else {
        await handleCategoryMove(newTreeData);
      }
    } else {
      if (!isTargetInPageArea && !dropTarget) {
        // page is dragged to category area. note that inside a category is considered category area
        // the difference between inside a category and root area of categories is that dropTarget in root is undefined
        handleUserError('Please place uncategorized pages below the categories.');
      } else {
        await handlePageMove(newTreeData, options);
      }
    }

    setOrderingInProgess(false);
  };

  const handlePageMove = async (
    newTreeData: TreeItem[],
    { dragSource, dropTarget }: DropOptions<{ numOfChildren: number; type: string }>,
  ) => {
    const newCategoryId = (dropTarget ? dropTarget.id : UNCATEGORIZED_ID) as string;
    const draggedPage = pages.byId[dragSource.id];

    if (draggedPage.category_id !== newCategoryId) {
      await move(newCategoryId, draggedPage);
    }

    const pageTreeItems = newTreeData.filter(data => data?.data?.type === TreeItems.PAGE);
    const newOrder: Order[] = pageTreeItems.map((treeData: TreeItem) => ({
      id: treeData.id,
      type: TreeItems.PAGE,
    }));
    await sortPages(newOrder);
  };

  const handleCategoryMove = async (newTreeData: TreeItem[]) => {
    const categoryTreeItems = newTreeData.filter(data => data?.data?.type === TreeItems.CATEGORY);
    const newOrder: Order[] = categoryTreeItems.map((treeData: TreeItem) => ({
      id: treeData.id,
      type: TreeItems.CATEGORY,
    }));
    await sortCategories(newOrder);
  };

  const handleUserError = (errorMessage: string) => {
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: errorMessage,
        variant: SnackbarVariant.ERROR,
      }),
    );
  };

  const handleEditPressed = (e: MouseEvent, page: IPage, category: ICategory) => {
    e.stopPropagation();
    if (category) {
      navigate(`/guest-portal/guides/${guideId}/categories/${category.category_id}`);
    }

    if (page) {
      navigate(`/guest-portal/guides/${guideId}/pages/${page.content_id}`);
    }
  };

  const handleCanDrop = (_: NodeModel<TreeItem>[], { dragSource, dropTargetId }: DropOptions) => {
    if (dragSource?.parent === dropTargetId) {
      return true;
    }
  };

  const renderSortCard = (
    tree: NodeModel<TreeItemData>,
    { depth, isOpen, onToggle }: RenderParams,
  ) => {
    const isCategory = tree.data.type === TreeItems.CATEGORY;
    const page = pages.byId[tree.id];
    const category = categories.byId[tree.id];

    return (
      <SortCard
        key={tree?.id}
        numOfChildren={tree?.data?.numOfChildren}
        isExpanded={isOpen}
        depth={depth}
        icon={isCategory ? <FolderIcon /> : <FileIcon />}
        node={tree}
        title={tree.text}
        onClick={onToggle}
        expandable={isCategory}
        actionsSection={
          <Box display='flex' alignItems='center'>
            <ActionSection page={page} category={category} onEditPressed={handleEditPressed} />
            {isCategory && isOpen && <ArrowDropUpIcon />}
            {isCategory && !isOpen && <ArrowDropDownIcon />}
          </Box>
        }
      />
    );
  };

  const renderSortPreviewCard = (monitorProps: DragLayerMonitorProps<TreeItemData>) => {
    const { item } = monitorProps;
    const expandable = item?.data.type === TreeItems.CATEGORY;
    const icon = expandable ? <FolderIcon /> : <FileIcon />;
    return <SortPreviewCard monitorProps={monitorProps} expandable={expandable} icon={icon} />;
  };

  useEffect(() => {
    const elements = Array.from(document.getElementsByClassName('File'));
    elements.forEach((element: HTMLElement) => {
      const categoryElements = element.getElementsByClassName('Category');
      if (categoryElements.length > 0) {
        element.style.backgroundColor = '#F8F7F7';
      }
    });
  }, []);

  return (
    <>
      <SettingCard title='Drag the items into the order you prefer.'>
        <LoadingContainer loading={orderingInProgess || isLoading}>
          <DndProvider backend={MultiBackend} options={getBackendOptions()}>
            <Box sx={treeStyles}>
              <Tree
                tree={treeItems}
                rootId={0}
                classes={{ container: 'Folder', listItem: 'File' }}
                onDrop={handleDrop}
                insertDroppableFirst={false}
                sort={false}
                placeholderRender={(_node, { depth }) => <PlaceholderLine depth={depth} />}
                canDrop={handleCanDrop}
                dragPreviewRender={renderSortPreviewCard}
                render={renderSortCard}
              />
            </Box>
          </DndProvider>
        </LoadingContainer>
      </SettingCard>

      <GuidesDetailsPagesForm page={page} onSave={savePage} />
      <GuidesDetailsCategoriesForm category={category} onSave={saveCategory} />
    </>
  );
};

const treeStyles = {
  minHeight: 'calc(100vh - 235px)',
  li: {
    'list-style-type': 'none',
    'max-width': '600px',
  },
  ul: {
    'padding-top': '20px',
  },
  '.Folder:last-child': {
    'padding-bottom': '20px',
    'margin-bottom': '14px',
  },
};
