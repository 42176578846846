import { Button, Grid } from '@mui/material';
import { AddCard } from '@operto/ui-library';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethod } from '@stripe/stripe-js';
import { StripeElements } from '@stripe/stripe-js/types/stripe-js/elements-group';
import * as React from 'react';
import { useState } from 'react';
import { updatePaymentMethod } from 'redux/actions/paymentMethod';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import styled from 'styled-components';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';

export default function PaymentUpdateForm() {
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    const result = await stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements: elements as unknown as StripeElements,
      redirect: 'if_required',
    });

    setLoading(false);

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (result.error) {
      if (result.error.message) {
        setErrorMessage(result.error.message);
      } else {
        setErrorMessage('An unexpected error occured.');
      }
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Adding a credit card was not successful. Refresh the page and try again.',
          variant: SnackbarVariant.ERROR,
        }),
      );
    } else {
      try {
        let paymentMethodId: string | null = null;
        // set the latest card as a default payment method
        if (typeof result.setupIntent.payment_method === 'string') {
          paymentMethodId = result.setupIntent.payment_method;
        } else if (typeof result.setupIntent.payment_method === 'object') {
          const paymentMethodObj: PaymentMethod = result.setupIntent
            .payment_method as PaymentMethod;
          paymentMethodId = paymentMethodObj.id;
        }
        if (paymentMethodId !== null) {
          dispatch(updatePaymentMethod(paymentMethodId)).then(() => {
            setErrorMessage(null);
            dispatch(
              toggleSnackbar(SnackbarTypes.OPEN, {
                message: 'Adding a credit card was successful.',
                variant: SnackbarVariant.SUCCESS,
              }),
            );
          });
        }
      } catch (error) {
        setErrorMessage(null);
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Although a card was added, it was not set up as a default card successfully.',
            variant: SnackbarVariant.ERROR,
          }),
        );
      }
    }
  };

  return (
    <LoadingContainer loading={loading}>
      <CardHolderGrid container>
        <AddCard
          title='Business Info'
          footerChildren={
            <SubmitButton variant='contained' color='primary' onClick={handleSubmit}>
              Save
            </SubmitButton>
          }
        >
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <PaymentElement id='payment-element' />
        </AddCard>
      </CardHolderGrid>
    </LoadingContainer>
  );
}

const SubmitButton = styled(Button)``;

const CardHolderGrid = styled(Grid)`
  margin-bottom: 24px;
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.palette.error};
  margin-bottom: 12px;
`;
