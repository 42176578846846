import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { CheckInsDict, SharedState, ActivityDict, ActivityState } from 'shared/state/sharedTypes';
import { Actions } from '../../types/actions';

const initialShareState: CheckInsDict = {};
const checkIns: Reducer<CheckInsDict> = (state = initialShareState, action) => {
  switch (action.type) {
    case Actions.hydrateCheckins:
      return action.checkIns;
    default:
      return state;
  }
};

const initialActivityState: ActivityDict = {};
const initialActivityListState: number[] = [];

const listActivities: Reducer<number[]> = (state = initialActivityListState, action) => {
  switch (action.type) {
    case Actions.hydrateActivities:
      return action.activityIds;
    default:
      return state;
  }
};
const activitiesById: Reducer<ActivityDict> = (state = initialActivityState, action) => {
  switch (action.type) {
    case Actions.hydrateActivities:
      return action.activitiesById;
    default:
      return state;
  }
};

export const activityReducer: Reducer<ActivityState> = combineReducers<ActivityState>({
  byId: activitiesById,
  list: listActivities,
});

const reducer: Reducer<SharedState> = combineReducers<SharedState>({
  checkIns,
});

export default reducer;
