// @ts-nocheck
import { Box } from '@mui/material';
import { useMedia, Widget } from '@operto/ui';
import { ICode } from 'code/codeType';
import { isAfter, isBefore } from 'date-fns';
import { utcStringToDate } from 'helper/date';
import { Property } from 'property/propertyType';
import * as React from 'react';
import { toggleSlidebar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IReservation } from 'reservation/reservationType';
import { getFilteredReservations } from 'reservation/state/reservationSelectors';
import { SlidebarType } from 'types/ui';
import ReservationContentList from './ReservationContentList';
import ReservationContentMain from './ReservationContentMain';
import ReservationFooter from './ReservationFooter';
import ReservationHeader, { ReservationTabs } from './ReservationHeader';

export interface ReservationCardProps {
  reservation?: IReservation;
  guestCode?: ICode;
  loading?: boolean;
  propertyId: number;
  timezone: string;
  property: Property;
}

const ReservationCard = ({
  reservation,
  guestCode,
  loading,
  propertyId,
  timezone,
  property,
}: ReservationCardProps) => {
  const dispatch = useAppDispatch();
  const [currentTab, setCurrentTab] = React.useState<ReservationTabs>(ReservationTabs.NEXT_GUEST);
  const reservations = useAppSelector(
    getFilteredReservations(propertyId, 4, guestCode?.valid_until),
  );
  const uniqReservations = reservations.filter((r: IReservation) => r.id !== reservation.id);
  const [isCurrentReservation, setIsCurrentReservation] = React.useState(false);
  const { isMobile } = useMedia();

  const handleEditReservation = () => {
    dispatch(
      toggleSlidebar(SlidebarType.EDIT_RESERVATIONS, {
        reservationId: reservation.id,
      }),
    );
  };

  const handleAddReservation = () => {
    dispatch(toggleSlidebar(SlidebarType.ADD_NEW_RESERVATION, {}));
  };

  React.useEffect(() => {
    if (reservation?.check_out && reservation?.check_in) {
      let validUntilDate = reservation?.check_out;
      let validFromDate = reservation?.check_in;

      // default to reservation check out time if no valid_until time is set
      if (guestCode?.valid_until) {
        validUntilDate = guestCode?.valid_until;
      }
      if (guestCode?.valid_from) {
        validFromDate = guestCode?.valid_from;
      }

      const now = new Date();
      setIsCurrentReservation(
        isBefore(utcStringToDate(validFromDate), now) &&
          isAfter(utcStringToDate(validUntilDate), now),
      );
    }
  }, [
    reservation?.check_out,
    reservation?.check_in,
    timezone,
    guestCode?.valid_until,
    guestCode?.valid_from,
  ]);

  const onTabChange = (event: React.ChangeEvent, value: string) => {
    setCurrentTab(value);
  };

  let headerTitle = '';
  if (reservation) {
    headerTitle = isCurrentReservation ? 'Current Guest' : 'Next Guest';
  }

  return (
    <Box sx={{ padding: isMobile && '16px', position: 'static' }}>
      <Widget
        title={headerTitle}
        header={
          reservation && (
            <ReservationHeader
              showUpcoming={uniqReservations?.length > 0}
              title={headerTitle}
              isCurrent={isCurrentReservation}
              value={currentTab}
              onChange={onTabChange}
            />
          )
        }
        footer={
          <ReservationFooter
            propertyId={propertyId}
            hideButtons={currentTab === ReservationTabs.NEXT_GUEST}
            onEditClick={handleEditReservation}
            onCreateClick={handleAddReservation}
            reservation={reservation}
          />
        }
        fill='primary.main'
        isMainActivity={true}
      >
        {currentTab === ReservationTabs.NEXT_GUEST ? (
          <ReservationContentMain
            onCreate={handleAddReservation}
            reservation={reservation}
            property={property}
            guestCode={guestCode}
            timezone={timezone}
            loading={loading}
          />
        ) : (
          <ReservationContentList
            reservations={uniqReservations}
            guestCode={guestCode}
            timezone={timezone}
          />
        )}
      </Widget>
    </Box>
  );
};

export default ReservationCard;
