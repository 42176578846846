import {
  Button,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import styled from 'styled-components';

export const FooterContainer = styled.div<{ justify?: string }>`
  display: flex;
  justify-content: ${props => (props.justify ? props.justify : 'flex-end')};
  color: ${props => (props.theme.fill ? props.theme.textColorWhite : props.theme.textColor)};
  align-items: center;
  height: 100%;
`;

export const MainLabel = styled(Typography)<{ fill?: string; mode?: string }>`
  && {
    color: ${props =>
      props.theme.fill && props.theme.mode !== 'light'
        ? props.theme.textColorWhite
        : props.theme.textColor};
  }
`;

export const SmartCardButton = styled(Button)`
  && {
    color: ${props =>
      props.theme.fill && props.theme.mode !== 'light'
        ? props.theme.textColorWhite
        : props.theme.textColor};
  }
`;

export const SmartCardIconButton = styled(IconButton)<{ $show?: boolean }>`
  && {
    color: ${props =>
      props.theme.fill && props.theme.mode !== 'light'
        ? props.theme.textColorWhite
        : props.theme.textColor};
    opacity: ${props => (props.$show ? 100 : 0)};
  }
`;

export const MiniListItem = styled(ListItemButton)`
  && {
    display: flex;
    align-items: center;
    .MuiTypography-root {
      line-height: 1;
    }
    padding-left: 0;
    padding-right: 0;
  }
`;

export const MiniListLabel = styled(MainLabel)`
  && {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
export const MiniListIcon = styled(ListItemIcon)`
  && {
    min-width: 28px;
    .MuiAvatar-root {
      width: 18px;
      height: 18px;
      font-size: 14px;
    }
  }
`;

export const TitleDropdown = styled(Select)<{ mode?: string }>`
  && {
    .MuiSelect-root {
      background: transparent;
    }
    font-size: 1rem;
    color: ${props =>
      props.mode !== 'light' ? props.theme.textColorWhite : props.theme.textColors};
    &:after,
    &:before {
      border-bottom: ${props =>
        props.theme.fill && props.mode !== 'light' && '2px solid white !important'};
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
    .MuiSelect-icon {
      color: ${props => props.theme.textColorWhite};
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${props =>
    props.theme.fill && props.theme.mode !== 'light'
      ? props.theme.textColorWhite
      : props.theme.textColor};
  .MuiSvgIcon-root {
    margin-right: 4px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  max-width: 250px;
`;

export const SmartCardBase = styled(Paper)<{
  $isMobile?: boolean;
  $isCarouselCard?: boolean;
  $isMainActivity?: boolean;
}>`
  && {
    width: ${props => (props.$isMobile && props.$isCarouselCard ? '300px' : '100%')};
    height: ${props => props.$isMobile && props.$isCarouselCard && '100%'};
    margin-right: ${props => props.$isMobile && props.$isCarouselCard && '12px'};
    &:nth-child(1) {
      ${props => props.$isMobile && props.$isCarouselCard && 'margin-left: 12px'};
    }
    box-shadow: ${props => props.$isMobile && props.$isMainActivity && 'none'};
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
  }
`;
