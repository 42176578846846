import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import uniqBy from 'lodash/uniqBy';
import { MEMBER_LIST_ID, MEMBER_TAG } from 'redux/members/api-slice';
import { RTKQapi } from 'redux/rtkq-api';
import {
  DEFAULT_PAGINATED_PARAMS,
  PaginatedData,
  PaginatedParams,
  ResponseData,
} from 'redux/rtkq-utils';
import { UNIT_LIST_ID, UNIT_TAG, unitsApi } from 'redux/units/api-slice';
import { UnitFilterType, UnitsState } from 'redux/units/types';
import { parseGroups, toJSON } from './parsers';
import type { Group, GroupJSON, GroupsState } from './types';

export const GROUP_TAG = 'Group';
export const GROUP_LIST_ID = 'LIST';

const DEFAULT_GET_PARAMS = {
  ...DEFAULT_PAGINATED_PARAMS,
  includesPrivate: 'false',
  numPerPage: 100000, // NOTE: no pagination in UI yet, ask for ridiculous amount
} as const;

export const INITIAL_STATE: GroupsState = {
  groups: [],
  pageNum: 0,
  numPerPage: 100,
  totalGroups: 0,
};

export type GetQueryParams = PaginatedParams;

export type UpdateDetailsQueryParams = {
  id: number;
  name?: string;
  description?: string;
};

const groupsApiWithTag = RTKQapi.enhanceEndpoints({
  addTagTypes: [GROUP_TAG, UNIT_TAG, MEMBER_TAG],
});

export const groupsApi = groupsApiWithTag.injectEndpoints({
  endpoints: builder => ({
    getGroups: builder.query<GroupsState, void | GetQueryParams>({
      query: args => {
        let params: Record<string, string | number> = DEFAULT_GET_PARAMS;
        if (args) {
          params = { ...params, ...args };
        }

        return {
          url: 'v3/groups',
          params,
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const args = { ...queryArgs } as PaginatedParams;

        // exclude pagination from cache key
        delete args['pageNum'];
        delete args['numPerPage'];

        return `${endpointName}(${JSON.stringify(args)})`;
      },
      merge: (state: GroupsState, incoming: GroupsState) => {
        return {
          ...incoming,
          groups:
            incoming.pageNum === 0
              ? incoming.groups
              : uniqBy([...state.groups, ...incoming.groups], 'id'),
        };
      },
      providesTags: (state: GroupsState = INITIAL_STATE) => {
        return [
          ...state.groups.map(({ id }) => ({ type: GROUP_TAG, id } as const)),
          { type: GROUP_TAG, id: GROUP_LIST_ID },
        ];
      },
      transformResponse: ({
        data: { records, pageNum, numPerPage, total_records },
      }: ResponseData<PaginatedData<GroupJSON>>) => ({
        groups: parseGroups(records),
        pageNum,
        numPerPage,
        totalGroups: total_records,
      }),
    }),
    addGroup: builder.mutation<ResponseData<GroupJSON>, Group>({
      query: group => ({
        url: 'v2/groups',
        method: 'POST',
        body: toJSON(group),
      }),
      invalidatesTags: [{ type: GROUP_TAG, id: GROUP_LIST_ID }],
    }),
    deleteGroup: builder.mutation<ResponseData<GroupJSON[]>, Group>({
      query: group => ({
        url: `v2/groups/${group.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: GROUP_TAG, id }],
    }),
    updateGroupProperties: builder.mutation<Group, { groupId: number; propertyIds: number[] }>({
      query: ({ groupId, propertyIds }) => ({
        url: `v2/groups/${groupId}/properties`,
        method: 'PATCH',
        body: { property_ids: propertyIds },
      }),
      invalidatesTags: (result, error, { groupId }) => [
        { type: GROUP_TAG, id: groupId },
        { type: UNIT_TAG, id: UNIT_LIST_ID },
      ],
    }),
    updateGroupMembers: builder.mutation<Group, { groupId: number; memberIds: number[] }>({
      query: ({ groupId, memberIds }) => ({
        url: `v2/groups/${groupId}/members`,
        method: 'PATCH',
        body: { member_ids: memberIds },
      }),
      invalidatesTags: (result, error, { groupId }) => [
        { type: GROUP_TAG, id: groupId },
        { type: MEMBER_TAG, id: MEMBER_LIST_ID },
      ],
    }),
    updateGroupDetails: builder.mutation<ResponseData<GroupJSON>, UpdateDetailsQueryParams>({
      query: groupDetails => ({
        url: `v2/groups/${groupDetails.id}`,
        method: 'PATCH',
        body: groupDetails,
      }),

      // optimistic updates
      onQueryStarted(groupDetails, { dispatch, queryFulfilled }) {
        // update group in groups list
        const groupsPatchResult = dispatch(
          groupsApi.util.updateQueryData('getGroups', undefined, (draft: GroupsState) => {
            draft.groups = draft.groups.map(group =>
              group.id === groupDetails.id ? { ...group, ...groupDetails } : group,
            );
          }),
        );

        // update group in units list if group name changes
        let unitsPatchResult: PatchCollection;
        if (groupDetails.name) {
          unitsPatchResult = dispatch(
            unitsApi.util.updateQueryData(
              'getUnits',
              {
                filterType: UnitFilterType.GROUP,
                numPerPage: 100000,
                [UnitFilterType.GROUP]: groupDetails.id,
              },
              (draft: UnitsState) => {
                draft.units = draft.units.map(unit => {
                  const updatedGroups = unit.groups.map(group =>
                    group.id === groupDetails.id ? { ...group, name: groupDetails.name } : group,
                  );
                  return { ...unit, groups: updatedGroups };
                });
              },
            ),
          );
        }

        queryFulfilled.catch(() => {
          groupsPatchResult.undo();
          unitsPatchResult?.undo();
        });
      },
      invalidatesTags: (result, error, { id }) => [{ type: GROUP_TAG, id }],
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useAddGroupMutation,
  useUpdateGroupPropertiesMutation,
  useUpdateGroupMembersMutation,
  useDeleteGroupMutation,
  useUpdateGroupDetailsMutation,
} = groupsApi;
