import { NeoRestRTKQapi } from 'redux/rtkq-api';

const enhancedApi = NeoRestRTKQapi.enhanceEndpoints({ addTagTypes: ['GuestService'] });

export const GuestServiceApi = enhancedApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getGuest: builder.query({
      query: guestId => ({
        url: `/guests/${guestId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetGuestQuery } = GuestServiceApi;
