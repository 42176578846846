import * as React from 'react';

import HelpIcon from '@mui/icons-material/Help';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { FooterContainer } from 'ui-library/Styled/smartcard';

export interface OccupancyFooterProps {
  onDetailsClick: () => void;
}

const OccupancyFooter = ({ onDetailsClick }: OccupancyFooterProps) => (
  <FooterContainer justify='space-between'>
    <Button style={{ color: 'white' }} onClick={onDetailsClick}>
      More Details
    </Button>
    <IconButton sx={{ color: 'text', opacity: '0' }}>
      <HelpIcon />
    </IconButton>
  </FooterContainer>
);

export default OccupancyFooter;
