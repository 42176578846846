import { SxProps } from '@mui/material';
import { SearchTextField } from 'Common/TextField/SearchTextField';
import React, { ReactNode, useRef } from 'react';
import {
  PaginatedTable,
  PaginatedTableHandle,
  PaginatedTableProps,
} from 'ui-library/Components/table/PaginatedTable';

export type SelectionTableProps = PaginatedTableProps & {
  searchLabel?: string;
  onSelectionChange: (ids: number[]) => void;
  renderTableInfo?: ReactNode;
  disableSearch?: boolean;
};

export const SelectionTable = ({
  searchLabel = 'Search',
  onSelectionChange,
  renderTableInfo,
  disableSearch,
  ...rest
}: SelectionTableProps) => {
  const paginatedTableRef = useRef<PaginatedTableHandle>(null);
  const renderHeader = () => {
    return (
      <>
        {renderTableInfo}
        <SearchTextField
          size='medium'
          label={searchLabel}
          sx={searchTextFieldStyle}
          value={paginatedTableRef.current?.searchValue ?? ''}
          onChange={paginatedTableRef.current?.handleSearch}
          disabled={disableSearch}
        />
      </>
    );
  };

  return (
    <PaginatedTable
      ref={paginatedTableRef}
      checkboxSelection
      keepNonExistentRowsSelected
      onSelectionModelChange={onSelectionChange}
      renderHeader={renderHeader}
      {...rest}
    />
  );
};

export const searchTextFieldStyle: SxProps = {
  width: 'auto',
  margin: 2,
  '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
    transform: `translate(40px, 1.0rem)`,
  },
};
