import { Add } from '@mui/icons-material';
import { Box, Button, SxProps, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CreateWorkflow = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleCreateTemplate = () => {
    navigate('/automate/create');
  };

  return (
    <Box data-testid='table-no-results-box' sx={tableNoResultsOutterBoxStyles}>
      <Box sx={InnerBoxStyles}>
        <Typography variant='h2'>{t('create_workflow_header')}</Typography>

        <Typography variant='body1' sx={{ marginY: '16px' }}>
          {t('create_workflow_body')}
        </Typography>

        <Box>
          <Button
            variant='contained'
            color='primary'
            startIcon={<Add />}
            sx={buttonStyles}
            onClick={handleCreateTemplate}
          >
            {t('new_workflow')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const buttonStyles: SxProps = {
  textTransform: 'none',
};

const tableNoResultsOutterBoxStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
};

const InnerBoxStyles: SxProps = {
  textAlign: 'center',
  maxWidth: '500px',
};

export default CreateWorkflow;
