import { createSelector } from '@reduxjs/toolkit';
import { PropertyFilterType, PropertyState } from 'property/propertyType';
import { ApplicationState } from 'redux/reducers';
import { getProperties } from './propertySelectors';

const getLinkedPropertiesByMasterId = (state: ApplicationState) =>
  state.commonProperties.linkedPropertiesByMasterId;

export const commonPropertiesSelector = (propertyId: number) =>
  createSelector(
    [getProperties, getLinkedPropertiesByMasterId],
    (commonProperties: PropertyState, linkedPropertiesByMasterId: Record<number, number[]>) =>
      commonProperties.meta[PropertyFilterType.COMMON].ids.map((pId: number) => ({
        ...commonProperties.byId[pId],
        linked: linkedPropertiesByMasterId[propertyId]?.includes(pId),
      })),
  );
