import * as React from 'react';

import { Box, List } from '@mui/material';
import { IReservation } from 'reservation/reservationType';
import ContentListItem from './ContentListItem';

export interface ReservationContentListProps {
  reservations: IReservation[];
  timezone: string;
}

const ReservationContentList = ({ reservations, timezone }: ReservationContentListProps) => (
  <Box sx={{ paddingTop: '32px', height: '400px', overflow: 'auto' }}>
    <List sx={{ padding: '0px' }}>
      {reservations?.map(reservation => (
        <ContentListItem key={reservation.id} reservation={reservation} timezone={timezone} />
      ))}
    </List>
  </Box>
);

export default ReservationContentList;
