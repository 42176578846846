import { Reducer, combineReducers } from '@reduxjs/toolkit';
import { Actions } from '../../types/actions';
import { ConfigState, SettingType } from '../../types/dashboard-config';

const initialListState: SettingType[] = [];

const list: Reducer<SettingType[]> = (state = initialListState, action) => {
  switch (action.type) {
    case Actions.hydrateSettings:
      return action.settings;
    default:
      return state;
  }
};

const reducer: Reducer<ConfigState> = combineReducers<ConfigState>({
  list,
});

export default reducer;
