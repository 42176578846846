import { ProviderTypes } from '../../company/companyType';

// NOTE: it is not one to one mapping with the server response.
export type GuestPortal = {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  message: string | null;
  guides?: Guide[];
  address?: PropertyAddress;
  host?: Host;
  customText?: CustomText;
  theme?: Theme;
  settings?: Settings;
  gpLink?: GPLink;
  assignedProperties?: AssignedPropertyState;
  sections?: SectionState;
  pages?: PagesState;
  categories?: CategoriesState;
  featured?: FeaturedState;
  entranceInstructions?: IEntranceInstructions;
};

export type GuestPortalResponse = {
  status: string;
  message: string;
  data: unknown;
};

export type GuideCategoryPage = {
  id: string;
  title: string;
  subtitle: string;
  description: string;
  lastEditedBy: string;
  lastEditedAt: number;
  url: string;
  videoURL: string;
  imagesURL: string[];
  published: boolean;
  featured: boolean;
  scheduled: boolean;
  scheduledStart: number;
  scheduledEnd: number;
  guideName?: string;
  button: {
    enabled: boolean;
    color: string;
    title: string;
    link: string;
    messengerEnabled: boolean;
    messengerDescription: string;
  };
  category?: string; // for table display
  hasGuestPortal?: boolean;
};

export type GuideCategory = {
  id: string;
  name: string;
  lastEditedBy: string;
  lastEditedAt: number;
  guideName?: string;
  published: boolean;
  featured: boolean;
  scheduled: boolean;
  scheduledStart: number;
  scheduledEnd: number;
  pages: GuideCategoryPage[];
};

export type Guide = {
  id: string;
  name: string;
  color: string;
  imageURL: string;
  createdBy: string;
  lastEditedBy: string;
  lastEditedAt: number;
  published: boolean;
  featured: boolean;
  scheduled: boolean;
  scheduledStart: number;
  scheduledEnd: number;
  categories: GuideCategory[];
  featuredIds: string[];
};

export type Host = {
  enabled?: boolean;
  name: string;
  email: string;
  phone: string;
  imageURL: string;
  propertyId?: string;
};

export type ActionButton = {
  title: string;
  action: 'tel' | 'email' | 'url';
  value: string;
  color: string;
};

export type CustomText = {
  isCustom: boolean;
  welcomeTitle: string;
  welcomeMessage: string;
  goodbyeTitle: string;
  goodbyeMessage: string;
  termsAndCondition: string;
  qrCodeWelcomeTitle?: string;
  qrCodeWelcomeMessage?: string;
  qrCodeWelcomeButtons?: ActionButton[];
  qrCodeCheckinMessage?: string;
  qrCodeCheckinButtons?: ActionButton[];
  propertyId?: string;
  termsData: {
    message: string;
    enabled: boolean;
    signatureEnabled: boolean;
    isCustom: boolean;
  };
};

export type PropertyAddress = {
  isCustom: boolean;
  name: string;
  city: string;
  state: string;
  address: string;
  country: string;
  imageURL: string;
  propertyId?: string;
};

export type Theme = {
  primaryColor: string;
  companyLogo: string;
  welcomeImagesEnabled: boolean;
};

export type GPLink = {
  name: string;
  url: string;
  check_in: string;
  check_out: string;
};

export type Settings = {
  locale: string;
  localeDetectionEnabled: boolean;
  nearmeEnabled: boolean;
  challengeDate: boolean;
  challengeLastName: boolean;
  guestVerification: boolean;
  termsRequired: boolean;
  contactRequired: boolean;
  termsEnabled: boolean;
  welcomeRequired: boolean;
  checkinEarlyEnabled: boolean;
  reservationDisplayEnabled: boolean;
  chatEnabled?: boolean;
  checkinQrEnabled: boolean;
  featureEntranceInstructions?: boolean;
  entranceInstructionsEnabled?: boolean;
  verificationProviders?: Array<ProviderTypes>;
};

export type IEntranceInstructions = {
  entrance_instructions_button_label: string;
  entrance_instructions_title: string;
  entrance_instructions_details: string;
  entrance_instructions_enabled: { propertyId: number; enabled: boolean }[];
} | null;

export type IEntranceInstructionsProperty = {
  propertyId: number;
  name: string;
  guest_portal_initialized?: boolean;
  entrance_instructions_enabled?: boolean;
};

export type GPProperty = {
  propertyId: number;
  name: string;
  location: string;
  verified: boolean;
  checkedIn: boolean;
  section_id?: string;
  guest_portal_status: 'active' | 'inactive';
  guest_portal_initialized: boolean;
  smw_enabled: boolean;
  guest_verification_enabled: boolean;
  entrance_instructions_enabled: boolean;
};

export type Order = {
  id: string;
  type: 'section' | 'category' | 'content';
};

export type Meta = {
  pageNum: number;
  numPerPage: number;
  total_records: number;
};
export type ISection = {
  section: string;
  featured: boolean;
  published: boolean;
  created_at: number;
  created_by: string;
  section_id?: string;
  last_edit_by: string;
  last_edit_at: number;
  section_color: string;
  section_cover: string;
  scheduled: boolean;
  scheduled_start: number;
  scheduled_end: number;
};

export type SectionById = {
  [Key: string]: ISection;
};

export type SectionData = {
  pageNum: number;
  numPerPage: number;
  total_records: number;
  records: ISection[];
};

export type SectionState = {
  byId: SectionById;
  order: Order[];
};

export type GPPropertyById = {
  [Key: string]: GPProperty;
};

export type AssignedPropertyState = {
  byId: GPPropertyById;
  list: number[];
  meta: Meta;
};
export type PageButton = {
  color: string;
  title: string;
  enabled: boolean;
  subject: string;
  hyperlink: string;
  autoreply_message: string;
  messenger_enabled: boolean;
};

export type IPage = {
  category: string;
  category_id: string;
  url: string;
  email: string;
  phone: string;
  title: string;
  subtitle: string;
  address: string;
  enabled: boolean;
  latitude: string;
  longitude: string;
  images_url: string[];
  section_id: string;
  scheduled: boolean;
  video_url: string;
  content_id?: string;
  description: string;
  created_at: number;
  created_by: string;
  last_edit_at: number;
  last_edit_by: string;
  scheduled_end: number;
  scheduled_start: number;
  featured: boolean;
  published: boolean;
  button: PageButton;
  oldCategoryId?: string;
  number_of_contents?: number;
};

export type PagesData = {
  pageNum: number;
  numPerPage: number;
  total_records: number;
  records: IPage[];
};

export type PagesById = {
  [Key: string]: IPage;
};
export type PagesState = {
  byId: PagesById;
  order: Order[];
};

export type ICategory = {
  category_id?: string;
  category: string;
  published: boolean;
  scheduled: boolean;
  created_at: number;
  created_by: string;
  last_edit_at: number;
  last_edit_by: string;
  scheduled_end: number;
  scheduled_start: number;
  featured: boolean;
  category_cover: string;
  section_id?: string;
  number_of_contents?: number;
  title?: string;
};

export type CategoriesData = {
  pageNum: number;
  numPerPage: number;
  total_records: number;
  records: ICategory[];
};

export type CategoriesById = {
  [Key: string]: ICategory;
};
export type CategoriesState = {
  byId: CategoriesById;
  order: Order[];
};

export type IFeatured = {
  section_id: string;
  category_id: string;
  content_id: string;
  name: string;
  type: 'section' | 'category' | 'content';
  guide_name: string;
  last_edited_at: number;
  published: boolean;
  data: ICategory | ISection | IPage;
};

export type FeaturedState = {
  byId: Record<string, IFeatured>;
  order: Order[];
};

export enum ImageUploadType {
  DASH_LOGO = 'dashboard-logo',
  COMP_LOGO = 'company-logo',
  BACKGROUND_LOGO = 'background-dashboard',
  HOST_AVATAR = 'host-avatar',
}

export enum GuestPortalLoginTypes {
  LASTNAME = 'Last Name',
  DATE = 'Date',
  BOTH = 'Both',
  NONE = 'None',
}
