import { Box, SwipeableDrawer as SwipeableDrawerMUI, SxProps } from '@mui/material';
import { useMedia } from '@operto/ui';
import React, { ReactNode, useEffect, useState } from 'react';

type Anchor = 'left' | 'right' | 'top' | 'bottom';

interface ISwipeableDrawerProps {
  anchor: Anchor;
  children: ReactNode;
  onClose?: () => void;
}
const SwipeableDrawer = ({ anchor, children, onClose }: ISwipeableDrawerProps) => {
  const [drawerAnchor, setDrawerAnchor] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { isDesktop, isTablet } = useMedia();

  useEffect(() => {
    setDrawerAnchor(prevState => ({ ...prevState, [anchor]: true }));
  }, [anchor]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setDrawerAnchor({ ...drawerAnchor, [anchor]: open });
    };

  const onCloseDrawer = () => {
    toggleDrawer(anchor, false);
    onClose && onClose();
  };

  const grabberContainerStyles: SxProps = {
    width: '100%',
    height: '100%',
    paddingTop: isTablet ? '8px' : '13px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline-flex',
  };

  const grabberStyles: SxProps = {
    width: '38px',
    height: '4px',
    background: '#E2E2E2',
    borderRadius: 100,
  };

  return (
    <SwipeableDrawerMUI
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'white',
        },
      }}
      anchor={anchor}
      open={drawerAnchor[anchor]}
      onClose={onCloseDrawer}
      onOpen={toggleDrawer(anchor, true)}
    >
      {!isDesktop && (
        <Box sx={grabberContainerStyles}>
          <Box sx={grabberStyles} />
        </Box>
      )}
      {children}
    </SwipeableDrawerMUI>
  );
};

export default SwipeableDrawer;
