import { Box, Grid, Paper, Stack, SxProps, TextField, Typography } from '@mui/material';
import { paletteStyles } from '@operto/ui';
import { SettingCard } from '@operto/ui-library';
import InsertVariablesMenu from 'Pages/Variables/Common/InsertVariablesMenu';
import React, { useRef, useState } from 'react';
import { IEntranceInstructions } from 'redux/guestportal';
import ScriptDropDownMenu from 'ui-library/Components/drop-down/ScriptDropDownMenu';
import { TextInsert } from 'ui-library/Helpers/textHelper';

export enum EditElements {
  None = 0,
  Media,
  Title,
  Description,
  Button,
}

const NO_TRANSLATE_REGEX = /\[\[|\]\]/g;
const VARIABLE_WITH_TEXT_REGEX = /\*{{.*?}}\*\s*/;
const VARIABLE_WITHOUT_TEXT_REGEX = /\*{{.*?}}\*/;

const noTranslateCleaner = (text: string) => {
  if (!text) return '';
  return text.replace(NO_TRANSLATE_REGEX, '');
};

type PreviewCardProps = EntranceInstructionsFormTabInstructionsProps & {
  onElementClick: (element: EditElements) => void;
};

const addEntranceInstruction =
  'Add an instruction message on the right panel. Guests should refer to this message for directions to the entrance.';

const PreviewCard = (props: PreviewCardProps) => {
  const { onElementClick, entranceInstructions } = props;
  return (
    <Paper sx={{ px: 2, py: 3, textAlign: 'center' }}>
      <Box
        sx={{ padding: 0 }}
        onClick={() => onElementClick(EditElements.Title)}
        style={{ marginTop: 30 }}
        data-testid='media-edit'
      >
        <Typography variant='h3' sx={{ mb: 6, color: paletteStyles.palette.text.primary }}>
          {entranceInstructions?.entrance_instructions_title.length > 0
            ? entranceInstructions?.entrance_instructions_title
            : 'Instruction title here'}
        </Typography>
        <img src='/img/guest-portal/entrance-instructions-illustration.svg' />
        <Typography
          variant='body1'
          sx={{ mt: 3, mb: 20, color: paletteStyles.palette.text.primary }}
        >
          {entranceInstructions?.entrance_instructions_details.length > 0
            ? entranceInstructions?.entrance_instructions_details
            : addEntranceInstruction}
        </Typography>
      </Box>
    </Paper>
  );
};

const EditElementsTitleCard = ({
  entranceInstructions,
  onEntranceInstructionsChange,
}: EntranceInstructionsFormTabInstructionsProps) => {
  const [buttonLabelInputAnchorEl, setButtonLabelInputAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [currentButtonLabelCursorPos, setCurrentButtonLabelCursorPos] = useState(0);
  const buttonLabelInputRef = useRef<HTMLInputElement>(null);

  const [titleInputAnchorEl, setTitleInputAnchorEl] = useState<null | HTMLElement>(null);
  const [currentTitleCursorPos, setCurrentTitleCursorPos] = useState(0);
  const titleInputRef = useRef<HTMLInputElement>(null);

  const [subtitleInputAnchorEl, setSubtitleInputAnchorEl] = useState<null | HTMLElement>(null);
  const [currentSubtitleCursorPos, setCurrentsubTitleCursorPos] = useState(0);
  const detailsInputRef = useRef<HTMLInputElement>(null);

  const onEntranceInstructionsTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitleInputAnchorEl(e.currentTarget);
    onEntranceInstructionsChange?.({
      ...entranceInstructions,
      entrance_instructions_title: e.target.value,
    });
  };

  return (
    <Box>
      <SettingCard
        data-testid='button-label-card'
        title='Button label'
        subtitle='Enter the button label below. This button is located on the guest portal and allows guests to view the guide instructions.'
      >
        <Stack direction='row' alignItems='space-between' spacing={1} sx={{ marginTop: '16px' }}>
          <Box sx={entranceButtonBoxStyle} data-testid='button-label-preview-div'>
            <Typography data-testid='button-label-preview' sx={entranceButtonTextStyle}>
              {entranceInstructions?.entrance_instructions_button_label !== '' &&
              entranceInstructions?.entrance_instructions_button_label
                ? noTranslateCleaner(entranceInstructions?.entrance_instructions_button_label)
                : 'How to access'}
            </Typography>
          </Box>
          <TextField
            data-testid='button-label-input'
            inputRef={buttonLabelInputRef}
            style={{ margin: 0 }}
            label='Enter button label'
            placeholder='How to access'
            onBlur={e => {
              setCurrentButtonLabelCursorPos(buttonLabelInputRef?.current?.selectionEnd);
              setTitleInputAnchorEl(e.currentTarget);
            }}
            onClick={e => {
              setCurrentButtonLabelCursorPos(buttonLabelInputRef?.current?.selectionEnd);
              setTitleInputAnchorEl(e.currentTarget);
            }}
            variant='outlined'
            fullWidth
            value={entranceInstructions?.entrance_instructions_button_label ?? 'How to access'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              // if we have variables, we shouldnt allow other texts outside of the variable name
              setButtonLabelInputAnchorEl(e.currentTarget);
              if (VARIABLE_WITH_TEXT_REGEX.test(e.target.value)) {
                onEntranceInstructionsChange?.({
                  ...entranceInstructions,
                  entrance_instructions_button_label: e.target.value.match(
                    VARIABLE_WITHOUT_TEXT_REGEX,
                  )[0],
                });
              } else {
                // get rid of [[ and ]] and then count the characters
                if (noTranslateCleaner(e.target.value).length > 16) return;

                onEntranceInstructionsChange?.({
                  ...entranceInstructions,
                  entrance_instructions_button_label: e.target.value,
                });
              }
            }}
            helperText='Max 16 characters'
          />
          <Box>
            <ScriptDropDownMenu
              onTextInsert={(text: string) => {
                // if we have variables, we shouldnt allow other texts outside of the variable name
                onEntranceInstructionsChange?.({
                  ...entranceInstructions,
                  entrance_instructions_button_label: TextInsert(
                    entranceInstructions.entrance_instructions_button_label,
                    text,
                    currentButtonLabelCursorPos,
                  ),
                });
              }}
              addVariable
            />
          </Box>
          <InsertVariablesMenu
            anchorEl={buttonLabelInputAnchorEl ?? buttonLabelInputRef?.current}
            targetText={entranceInstructions.entrance_instructions_button_label}
            onVariableSelectedUpdate={(newText: string) =>
              onEntranceInstructionsChange?.({
                ...entranceInstructions,
                entrance_instructions_button_label: newText.match(VARIABLE_WITHOUT_TEXT_REGEX)[0],
              })
            }
            currentCursorPosition={currentButtonLabelCursorPos}
          />
        </Stack>
      </SettingCard>
      <SettingCard title='Title'>
        <Stack direction='row' alignItems='flex-start' spacing={1}>
          <TextField
            inputRef={titleInputRef}
            style={{ margin: 0 }}
            label='Enter title'
            placeholder='Enter title'
            onBlur={e => {
              setCurrentTitleCursorPos(titleInputRef?.current?.selectionEnd);
              setTitleInputAnchorEl(e.currentTarget);
            }}
            onClick={e => {
              setCurrentTitleCursorPos(titleInputRef?.current?.selectionEnd);
              setTitleInputAnchorEl(e.currentTarget);
            }}
            variant='outlined'
            fullWidth
            value={entranceInstructions?.entrance_instructions_title ?? ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onEntranceInstructionsTitleChange(e);
            }}
          />
          <ScriptDropDownMenu
            onTextInsert={(text: string) => {
              onEntranceInstructionsChange?.({
                ...entranceInstructions,
                entrance_instructions_title: TextInsert(
                  entranceInstructions.entrance_instructions_title,
                  text,
                  currentTitleCursorPos,
                ),
              });
            }}
            addVariable
          />
          <InsertVariablesMenu
            anchorEl={titleInputAnchorEl ?? titleInputRef?.current}
            targetText={entranceInstructions.entrance_instructions_title}
            onVariableSelectedUpdate={(newText: string) =>
              onEntranceInstructionsChange?.({
                ...entranceInstructions,
                entrance_instructions_title: newText,
              })
            }
            currentCursorPosition={currentTitleCursorPos}
          />
        </Stack>
      </SettingCard>
      <SettingCard title='Instruction'>
        <Stack direction='row' alignItems='flex-start' spacing={1}>
          <TextField
            style={{ margin: 0 }}
            label='Enter instructions or use variables'
            placeholder='Enter instructions or use variables'
            variant='outlined'
            fullWidth
            multiline
            rows={8}
            inputRef={detailsInputRef}
            onBlur={e => {
              setSubtitleInputAnchorEl(e.currentTarget);
              setCurrentsubTitleCursorPos(detailsInputRef?.current?.selectionEnd);
            }}
            value={entranceInstructions?.entrance_instructions_details ?? ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSubtitleInputAnchorEl(e.currentTarget);
              onEntranceInstructionsChange?.({
                ...entranceInstructions,
                entrance_instructions_details: e.target.value,
              });
            }}
            onClick={e => {
              setSubtitleInputAnchorEl(e.currentTarget);
              setCurrentsubTitleCursorPos(detailsInputRef?.current?.selectionEnd);
            }}
          />
          <ScriptDropDownMenu
            onTextInsert={(text: string) => {
              onEntranceInstructionsChange?.({
                ...entranceInstructions,
                entrance_instructions_details: TextInsert(
                  entranceInstructions.entrance_instructions_details,
                  text,
                  currentSubtitleCursorPos,
                ),
              });
            }}
            addVariable
          />
          <InsertVariablesMenu
            anchorEl={subtitleInputAnchorEl ?? detailsInputRef?.current}
            targetText={entranceInstructions.entrance_instructions_details}
            onVariableSelectedUpdate={(newText: string) =>
              onEntranceInstructionsChange?.({
                ...entranceInstructions,
                entrance_instructions_details: newText,
              })
            }
            currentCursorPosition={currentSubtitleCursorPos}
          />
        </Stack>
      </SettingCard>
    </Box>
  );
};

type EntranceInstructionsFormTabInstructionsProps = {
  entranceInstructions: IEntranceInstructions;
  onEntranceInstructionsChange?: (entranceInstructions: IEntranceInstructions) => void;
};

const EntranceInstructionsFormTabInstructions = (
  props: EntranceInstructionsFormTabInstructionsProps,
) => {
  const [currentEl, setCurrentEl] = useState(EditElements.Title);
  return (
    <Grid
      container
      justifyContent='space-between'
      style={{ width: '100%', minWidth: '1139px', padding: '24px' }}
    >
      <Grid item style={{ width: '375px' }}>
        <PreviewCard {...props} onElementClick={setCurrentEl} />
      </Grid>
      <Grid item style={{ width: '65%', paddingLeft: '24px' }}>
        {currentEl === EditElements.Title && <EditElementsTitleCard {...props} />}
      </Grid>
    </Grid>
  );
};

const entranceButtonBoxStyle: SxProps = {
  width: '168px',
  maxWidth: '168px',
  height: '48px',
  alignSelf: 'top',
  textTransform: 'none',
  padding: '0px 16px 0px 16px',
  borderRadius: '8px',
  margin: '0px 16px 0px 16px',
  backgroundColor: 'primary.main',
  display: 'flex',
  flexDirection: 'row',
};
const entranceButtonTextStyle: SxProps = {
  fontWeight: 700,
  fontSize: '14px',
  letterSpacing: '0.4px',
  textAlign: 'center',
  width: '136px',
  color: 'white',
  alignSelf: 'center',
  wordWrap: 'break-word',
};

export default EntranceInstructionsFormTabInstructions;
