import { getActiveAlerts, getCompanyInformation } from 'company/state/companyAction';
import PropertyTable from 'property/propertiesTable';
import { PropertyFilterType } from 'property/propertyType';
import React from 'react';
import { useAppDispatch } from 'redux/hooks';
import TabBar from 'ui-library/Components/tabBar/TabBar';

const upperTabItems = [
  {
    label: 'Active',
    value: PropertyFilterType.ALL_PROPERTIES,
    isDropDown: false,
  },
  {
    label: 'Inactive',
    value: PropertyFilterType.UNACTIVATED,
    isDropDown: false,
  },
];

const UnitsPage = () => {
  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex] = React.useState(0);
  const [filterType, setFilterType] = React.useState(PropertyFilterType.ALL_PROPERTIES);

  React.useEffect(() => {
    dispatch(getCompanyInformation());
    dispatch(getActiveAlerts());
  }, [dispatch]);

  const handleTabIndexChange = (value: number) => {
    setTabIndex(value);
    setFilterType(upperTabItems[value].value);
  };

  return (
    <>
      <TabBar tabItems={upperTabItems} tabIndex={tabIndex} onTabChange={handleTabIndexChange} />
      <PropertyTable filterType={filterType} />
    </>
  );
};

export default UnitsPage;
