import axios from 'axios';

const api = process.env.REACT_APP_API_URL;

// TODO use put?
export const updateReservationAccessTime = (codeId: string, startTime: string, endTime: string) =>
  axios.put(`${api}/v2/company/accesscodes/${codeId}`, {
    valid_from: startTime,
    valid_until: endTime,
  });

export const getCodes = () => axios.get(`${api}/v2/company/accesscodes`);
