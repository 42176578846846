import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import AddCard from 'ui-library/Components/card/AdderCard';
import { FormTextField } from 'ui-library/Components/input/FormTextField';
import { ActionButton } from 'ui-library/Styled/sidebar';

export type PasswordData = {
  password: string;
  confirmPassword: string;
};

export type ChangePasswordFormProps = {
  onSubmit: (data: PasswordData) => void;
};

export const ChangePasswordForm = ({ onSubmit }: ChangePasswordFormProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const { handleSubmit, control, watch } = useForm<PasswordData>({
    mode: 'all',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const validate = (password: string) => {
    if (password.length < 8 || password.length > 32) {
      return 'Invalid password: Password should be min 8 characters and max 32 characters';
    }

    if (password.match(/[A-Z]/g) === null) {
      return 'Invalid password: Password should contain at least one Capital Letter';
    }

    if (password.match(/[a-z]/g) === null) {
      return 'Invalid password: Password should contain at least one small Letter';
    }

    if (password.match(/\d/g) === null) {
      return 'Invalid password: Password should contain at least one number';
    }

    if (password.match(/[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]/g) === null) {
      return 'Invalid password: Password should contain at least one special character';
    }

    if (watch('password').length && watch('password') !== password) {
      return 'Invalid password: Password mismatch';
    }

    return true;
  };

  return (
    <AddCard
      title={'Change Password'}
      footerChildren={
        <ActionButton
          testId='save-new-password'
          title='Save'
          variant='contained'
          color='primary'
          onClick={handleSubmit(onSubmit)}
        >
          Save New Password
        </ActionButton>
      }
    >
      <FormTextField
        rules={{ validate }}
        field='password'
        type={showPassword ? 'text' : 'password'}
        label={'Password'}
        control={control}
      />
      <FormTextField
        rules={{ validate }}
        field='confirmPassword'
        type={showPassword ? 'text' : 'password'}
        label={'Confirm Password'}
        control={control}
      />
      <FormControlLabel
        sx={{ mx: 0 }}
        label='Show Password'
        control={
          <Checkbox
            checked={showPassword}
            onChange={(_event, checked) => setShowPassword(checked)}
          />
        }
      />
    </AddCard>
  );
};

export default ChangePasswordForm;
