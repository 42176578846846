import * as React from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Button } from '@mui/material';

export interface ShowMoreToggleProps {
  open: boolean;
  onClick: () => void;
  fill?: string;
  mode?: string;
}

const ShowMoreToggle = ({ open, mode, onClick }: ShowMoreToggleProps) => (
  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
    <Button
      sx={{
        fontSize: '12px',
        color: mode === 'dark' ? 'text.primary' : 'white',
        textTransform: 'none',
        padding: 0,
      }}
      size='small'
      onClick={onClick}
      endIcon={
        open ? (
          <ArrowDropUpIcon sx={{ height: '24px', width: '24px' }} />
        ) : (
          <ArrowDropDownIcon sx={{ height: '24px', width: '24px' }} />
        )
      }
    >
      {open ? 'Show less' : 'Show more'}
    </Button>
  </Box>
);

export default ShowMoreToggle;
