import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from 'redux/reducers';
import { FilterType, Group, GroupDict, GroupState, GroupType } from 'types/groups';

const getAllLocations = (state: ApplicationState) => state.groups?.byId;
const getLocationsState = (state: ApplicationState) => state.groups;

export const getGroupType = (state: ApplicationState) => state.groups.groupType;

export const groupTypeSelector = () => createSelector([getGroupType], (type: GroupType) => type);

export const getAllLocationsPaginated = () =>
  createSelector([getAllLocations], (allLocations: GroupDict) => {
    const locations = Object.keys(allLocations).map(
      (locationId: string) => allLocations[Number(locationId)],
    );
    return locations;
  });

export const locationsMetaSelector = (type?: FilterType) =>
  createSelector([getLocationsState], (locations: GroupState) => locations.meta[type]);

export const getAllLocationsPaginatedWithFilter = (filter: string) =>
  createSelector([getAllLocationsPaginated()], allLocations => {
    switch (filter) {
      case FilterType.PROPERTY:
        return allLocations.filter((location: Group) => !location.time_frame);
      case FilterType.ACCESS_GROUP:
        return allLocations.filter(
          (location: Group) => location.group_type !== 'private' && location.time_frame,
        );
      default:
        return getAllLocationsPaginated();
    }
  });

export const getLocations = () =>
  createSelector([getAllLocations], (allLocations: GroupDict) => {
    const locations = Object.keys(allLocations)
      .map((locationId: string) => allLocations[Number(locationId)])
      .filter(
        (eachLocation: Group) => eachLocation.group_type !== 'private' && !eachLocation.time_frame,
      );
    return locations;
  });

export const getMemberLocations = () =>
  createSelector([getAllLocations], (allLocations: GroupDict) => {
    const accessGroups = Object.keys(allLocations)
      .map((locationId: string) => allLocations[Number(locationId)])
      .filter((eachLocation: Group) => eachLocation.group_type === null);
    return accessGroups;
  });

export const locationsByIdSelector = (locationId: number) =>
  createSelector([getAllLocations], (locations: GroupDict) => {
    return locations[locationId];
  });
