import { CircularProgress, Stack } from '@mui/material';
import * as React from 'react';

export const Loading = () => {
  return (
    <Stack alignItems='center' justifyContent='center' height='100%'>
      <CircularProgress />
    </Stack>
  );
};
