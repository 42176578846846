import { EditOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useCallback, useState } from 'react';
import { Group } from 'redux/groups/types';
import { INITIAL_STATE, useGetUnitsQuery } from 'redux/units/api-slice';
import { UnitFilterType } from 'redux/units/types';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import { TableCell } from 'ui-library/Components/table/TableCell';
import {
  editButtonStyle,
  groupNameChipStyle,
  paginatedTableStyle,
  searchToolbarStyle,
} from 'ui-library/Components/table/table-styles';
import { NamedChip } from '../Common/NamedChip';
import { GroupUnitsEditForm } from '../EditGroup/GroupUnitsEditForm';

const unitsColumnsDef = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <TableCell title={params.row.name} description={params.row.email} />
    ),
  },
  {
    field: 'groups',
    headerName: 'Group',
    width: 300,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <NamedChip
        sx={groupNameChipStyle}
        names={params.row.groups?.map((group: Group) => group.name)}
      />
    ),
  },
];

type GroupDetailsTableProps = {
  group: Group;
};

export const GroupUnitsTable = ({ group }: GroupDetailsTableProps) => {
  const {
    data: { units } = INITIAL_STATE,
    isLoading,
    isFetching,
  } = useGetUnitsQuery({
    filterType: UnitFilterType.GROUP,
    numPerPage: 100000,
    [UnitFilterType.GROUP]: group?.id,
  });
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>();
  const filteredList = units.filter(
    units => !searchValue || units.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const onEditClick = () => {
    setIsEditFormOpen(true);
  };

  const onClose = () => {
    setIsEditFormOpen(false);
  };

  const renderEditButton = () => {
    return (
      <Button sx={editButtonStyle} startIcon={<EditOutlined />} onClick={onEditClick}>
        Edit
      </Button>
    );
  };

  const handleFetch = useCallback((_pageNum: number, _numPerPage: number, searchValue?: string) => {
    setSearchValue(searchValue);
  }, []);

  return (
    <>
      <PaginatedTable
        enableToolbar
        title={renderEditButton()}
        loading={isLoading || isFetching}
        rows={filteredList}
        columns={unitsColumnsDef}
        onFetch={handleFetch}
        sortingMode='server'
        searchToolbarSx={searchToolbarStyle}
        sx={paginatedTableStyle}
      />
      <GroupUnitsEditForm group={group} onClose={onClose} open={isEditFormOpen} />
    </>
  );
};
