import CheckIcon from '@mui/icons-material/Check';
import { Stack, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { OpertoTheme } from 'App/OpertoTheme';
import { ICheckOutStats } from 'dashboard/dashboardTypes';
import { dashboardStatSelector } from 'dashboard/state/dashboardSelectors';
import { utcToTimeZone } from 'helper/date';
import * as React from 'react';
import { toggleSlidebar as toggleSlidebarAction } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IReservation } from 'reservation/reservationType';
import { SlidebarType } from 'types/ui';
import ProfileIcon from 'ui-library/Components/icon/ProfileIcon';
import Widget from 'ui-library/Components/widget/Widget';
import IconLabel from 'ui-library/Components/widget/content/IconLabel';
import ShowMoreToggle from 'ui-library/Components/widget/content/ShowMoreToggle';
import { MainLabel, MiniListIcon, MiniListItem, MiniListLabel } from 'ui-library/Styled/smartcard';
import CheckOutFooter from './CheckOutFooter';

export interface CheckOutCardProps {
  isCarouselCard: boolean;
}

const CheckOutCard = (props: CheckOutCardProps) => {
  const dispatch = useAppDispatch();
  const checkOutStats = useAppSelector(dashboardStatSelector('check_out_stats')) as ICheckOutStats;
  const reservations: IReservation[] = checkOutStats?.reservations
    .filter((reservation: IReservation) => reservation.room_status === 'guest-checking-out')
    .slice(0, 3);
  const [openDetails, setOpenDetails] = React.useState(false);
  const handleViewAll = () => {
    dispatch(toggleSlidebarAction(SlidebarType.CHECK_OUT_RESERVATIONS, ''));
  };

  return (
    <Widget
      title='Guests check-out'
      isMainActivity={false}
      footer={<CheckOutFooter onClick={handleViewAll} />}
      isCarouselCard={props.isCarouselCard}
      fill={OpertoTheme.smartCards.bg2}
    >
      <>
        <ShowMoreToggle
          open={openDetails}
          onClick={() => {
            setOpenDetails(!openDetails);
          }}
          fill={OpertoTheme.smartCards.bg2}
        />

        <Typography variant='h2Light'>
          {`${checkOutStats?.total_check_out || 0} of ${checkOutStats?.total || 0}`}
        </Typography>

        <IconLabel icon={<CheckIcon />} label='Checked-Out' iconColor='success.light' />

        <Collapse in={openDetails}>
          <List dense>
            {reservations.slice(0, 3).map((reservation, index) => (
              <MiniListItem key={`${reservation.id}_${index}`}>
                <MiniListIcon>
                  <ProfileIcon fullName={reservation?.guest_name?.charAt(0)} />
                </MiniListIcon>
                <Stack sx={{ flexDirection: 'column' }}>
                  <MiniListLabel variant='body2'>{reservation.guest_name}</MiniListLabel>
                  <MainLabel variant='caption'>
                    {utcToTimeZone(reservation?.check_out, reservation?.timezone, 'MM/dd/yy')}
                  </MainLabel>
                </Stack>
              </MiniListItem>
            ))}
          </List>
        </Collapse>
      </>
    </Widget>
  );
};

export default CheckOutCard;
