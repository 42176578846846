import { NotifiableError } from '@bugsnag/js';
import { AxiosResponse } from 'axios';
import { OpertoLogger } from 'Logger/logger';
import { Dispatch } from 'redux';
import { AppDispatch } from 'redux/store';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import * as api from '../../api/thermostats';
import { ThermostatSetting } from '../../types/thermostats';
import { ApplicationState } from '../reducers';
import { toggleSnackbar } from './ui';

export const getThermostatSettings = (propertyId: number) => (dispatch: AppDispatch) => {
  dispatch({
    type: 'CLEAR_THERMOSTAT_SETTING_FOR_PROPERTY',
    propertyId,
  });
  dispatch(getThermostatSettingsEcobee(propertyId));
  dispatch(getThermostatSettingsTadoHeating(propertyId));
  dispatch(getThermostatSettingsTadoAirConditioning(propertyId));
};

const getThermostatSettingsEcobee = (propertyId: number) => (dispatch: Dispatch) => {
  api
    .getTempSettingByPropertyIdEcobee(propertyId)
    .then((data: AxiosResponse) => {
      const thermostatData: ThermostatSetting[] = data.data.data.map(
        (each: Record<string, unknown>) => ({
          type: 'ECOBEE',
          ...each,
        }),
      );
      dispatch({
        type: 'INSERT_THERMOSTAT_SETTINGS',
        settings: thermostatData,
        propertyId,
      });
    })
    .catch((err: NotifiableError) => {
      OpertoLogger.Log(err);
    });
};

const getThermostatSettingsTadoHeating = (propertyId: number) => (dispatch: Dispatch) => {
  api
    .getTempSettingByPropertyIdTadoHeating(propertyId)
    .then((data: AxiosResponse) => {
      const thermostatData: ThermostatSetting[] = data.data.data.map(
        (each: Record<string, unknown>) => ({
          type: 'Tado-Heating',
          ...each,
        }),
      );
      dispatch({
        type: 'INSERT_THERMOSTAT_SETTINGS_HEATING',
        settings: thermostatData,
        propertyId,
      });
    })
    .catch((err: NotifiableError) => {
      OpertoLogger.Log(err);
    });
};

const getThermostatSettingsTadoAirConditioning = (propertyId: number) => (dispatch: Dispatch) => {
  api
    .getTempSettingByPropertyIdTadoAirConditioning(propertyId)
    .then((data: AxiosResponse) => {
      const thermostatData: ThermostatSetting[] = data.data.data.map(
        (each: Record<string, unknown>) => ({
          type: 'Tado-AirConditioning',
          ...each,
        }),
      );
      dispatch({
        type: 'INSERT_THERMOSTAT_SETTINGS_AIR_CONDITIONING',
        settings: thermostatData,
        propertyId,
      });
    })
    .catch((err: NotifiableError) => {
      OpertoLogger.Log(err);
    });
};

export const updateThermostatSettingsEcobee =
  (setting: ThermostatSetting, propertyId: number) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const state: ApplicationState = getState();
    api
      .updateTempSettingByPropertyIdEcobee(propertyId, setting)
      .then(() => {
        const settings = state.thermostat.byPropertyId[propertyId].map((each: ThermostatSetting) =>
          each.id === setting.id ? setting : each,
        );
        dispatch({
          type: 'UPDATE_THERMOSTAT_SETTINGS',
          settings,
          propertyId,
        });
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Thermostat values update was successful.',
            variant: SnackbarVariant.SUCCESS,
          }),
        );
      })
      .catch((err: Error) => {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Thermostat values update was not successful.',
            variant: SnackbarVariant.ERROR,
          }),
        );
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: `ERROR: ${err.message}`,
            variant: SnackbarVariant.ERROR,
          }),
        );
        OpertoLogger.Log(err);
      });
  };

export const updateThermostatSettingsTadoHeating =
  (setting: ThermostatSetting, propertyId: number) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const state = getState();

    api
      .updateTempSettingByPropertyIdTadoHeating(propertyId, setting)
      .then(() => {
        const settings = state.thermostat.byPropertyId[propertyId].map((each: ThermostatSetting) =>
          each.id === setting.id ? setting : each,
        );
        dispatch({
          type: 'UPDATE_THERMOSTAT_SETTINGS_HEATING',
          settings,
          propertyId,
        });
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Thermostat values update was successful.',
            variant: SnackbarVariant.SUCCESS,
          }),
        );
      })
      .catch((err: Error) => {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Thermostat values update was not successful.',
            variant: SnackbarVariant.ERROR,
          }),
        );
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: `ERROR: ${err.message}`,
            variant: SnackbarVariant.ERROR,
          }),
        );
        OpertoLogger.Log(err);
      });
  };

export const updateThermostatSettingsTadoAirConditioning =
  (setting: ThermostatSetting, propertyId: number) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const state: ApplicationState = getState();
    api
      .updateTempSettingByPropertyIdTadoAirConditioning(propertyId, setting)
      .then(() => {
        const settings = state.thermostat.byPropertyId[propertyId].map((each: ThermostatSetting) =>
          each.id === setting.id ? setting : each,
        );
        dispatch({
          type: 'UPDATE_THERMOSTAT_SETTINGS_AIR_CONDITIONING',
          settings,
          propertyId,
        });
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Thermostat values update was successful.',
            variant: SnackbarVariant.SUCCESS,
          }),
        );
      })
      .catch((err: Error) => {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Thermostat values update was not successful.',
            variant: SnackbarVariant.ERROR,
          }),
        );
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: `ERROR: ${err.message}`,
            variant: SnackbarVariant.ERROR,
          }),
        );
        OpertoLogger.Log(err);
      });
  };
