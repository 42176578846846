import getUnixTime from 'date-fns/getUnixTime';
import { UNCATEGORIZED_ID, isUncategorizedAvailable } from 'helper/helper';
import { logger } from 'lib/logger';
import uniqBy from 'lodash/uniqBy';
import { IMember } from 'member/memberType';
import { getSelf } from 'member/state/memberSelectors';
import { useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGuestPortal } from 'redux/guestportal';
import { useAppSelector } from 'redux/hooks';
import { ICategory, Order } from '../models';

export const newCategoryTemplate = (author: string): ICategory => {
  const now = getUnixTime(new Date());
  return {
    category: '',
    created_at: now,
    created_by: author,
    last_edit_at: now,
    last_edit_by: author,
    published: false,
    featured: false,
    scheduled: false,
    scheduled_start: now,
    scheduled_end: now,
    category_cover: '',
  };
};

export const useGuidesCategories = () => {
  const navigate = useNavigate();
  const { propertyId, guideId, categoryId, pageId } = useParams();
  const editingUser = useAppSelector(getSelf()) as IMember;
  const {
    featured,
    categories,
    categoriesList,
    saveCategory,
    savePropertyCategory,
    addCategory,
    removeSection,
    fetchGuideCategories,
    fetchPropertyGuideCategories,
    setGuideCategoriesOrder,
    setPropertyGuideCategoriesOrder,
  } = useGuestPortal();
  const saveCategoryInProgress = useRef<ICategory>();
  const createUncategorizedInProgress = useRef(false);

  const category = useMemo(() => {
    if (saveCategoryInProgress.current) {
      return saveCategoryInProgress.current;
    }

    // NOTE: determine what category to show based on the url params
    // dont return a category if a page is selected
    if (categoryId === undefined || pageId) {
      return undefined;
    }

    if (categoryId === 'add') {
      return newCategoryTemplate(editingUser?.name ?? 'Unknown');
    }

    const categoryItem = categories.byId[categoryId];
    if (categoryItem) {
      return categoryItem; // lucky us, we cached it already
    }

    const featuredItem = featured.byId[categoryId];
    if (featuredItem) {
      // NOTE: this part is a mess. IFeatured is not an actual ICategory type.
      // its a subset/partial of ICategory object, so we need to somehow construct it to ICategory
      return {
        ...featuredItem?.data,
        category_id: featuredItem?.category_id,
        section_id: featuredItem?.section_id,
      } as ICategory;
    }

    logger.error(`item not found but categoryId is ${categoryId}`);
    return undefined;
  }, [categories.byId, categoryId, editingUser?.name, featured.byId, pageId]);

  const save = async (category: ICategory) => {
    saveCategoryInProgress.current = categoryId ? category : null;
    let success = false;
    category.section_id = guideId;
    if (category?.category_id?.length > 0) {
      if (propertyId) {
        category.last_edit_by = editingUser?.name ?? 'Unknown';
        success = await savePropertyCategory(propertyId, category);
      } else {
        success = await saveCategory(category);
      }
    } else {
      success = await addCategory(category);
    }

    if (success) {
      saveCategoryInProgress.current = null;
      if (categoryId) navigate(-1);
    }
  };

  const remove = async ({ section_id, category_id }: ICategory) => {
    await removeSection({
      section_id,
      category_id,
      content_id: '',
    });
  };

  const sort = async (order: Order[]) => {
    const orderWithUncategorized: Order[] = uniqBy(
      [{ id: UNCATEGORIZED_ID, type: 'category' }, ...order],
      'id',
    );

    if (propertyId) {
      await setPropertyGuideCategoriesOrder({
        order: orderWithUncategorized,
        section_id: guideId,
        propertyId,
      });
    } else {
      await setGuideCategoriesOrder({ order: orderWithUncategorized, section_id: guideId });
    }
  };

  useEffect(() => {
    if (!guideId) {
      return;
    }

    if (propertyId) {
      fetchPropertyGuideCategories(Number(propertyId), guideId);
    } else {
      fetchGuideCategories(guideId);
    }
  }, [fetchGuideCategories, fetchPropertyGuideCategories, guideId, propertyId]);

  useEffect(() => {
    const createUncategoryAsync = async () => {
      // FIXME: this should be part of the POST section API
      // in the BE to create uncategorized item right away.
      // but for some reason we are hacking it here.
      createUncategorizedInProgress.current = true;

      const uncategorized = newCategoryTemplate(editingUser?.name ?? '');
      await addCategory({
        ...uncategorized,
        category: 'Uncategorized',
        category_id: UNCATEGORIZED_ID,
        section_id: guideId,
        published: true,
        created_at: getUnixTime(new Date()),
        created_by: editingUser?.name ?? 'Unknown',
      });

      createUncategorizedInProgress.current = false;
    };

    if (
      !guideId ||
      categoriesList.length === 0 ||
      isUncategorizedAvailable(categoriesList) ||
      propertyId ||
      createUncategorizedInProgress.current
    ) {
      return;
    }

    createUncategoryAsync();
  }, [addCategory, categoriesList, editingUser?.name, guideId, propertyId]);

  return {
    category,
    save,
    remove,
    sort,
  };
};
