export const MemberFilterType = {
  ALL_MEMBERS: 'all_members',
  STAFF: 'employee',
  CONTRACTOR: 'contractor',
  INACTIVE: 'inactive',
  EXCLUDE_GROUP: 'exclude_in_group',
  GROUP: 'group_id',
} as const;

export type MemberFilterType = (typeof MemberFilterType)[keyof typeof MemberFilterType];

export const MEMBERS_ROLES = {
  ACCOUNT_OWNER: 'account-owner',
  GLOBAL_MANAGER: 'global-manager',
  REGIONAL_MANAGER: 'regional-manager',
  BASIC_USER: 'basic-user',
} as const;

export type MembersRolesTypes = (typeof MEMBERS_ROLES)[keyof typeof MEMBERS_ROLES];

// NOTE: most minimal props for now required in UI. add new properties as needed
// server data representation
export type MemberJSON = {
  id: number;
  name: string;
  email: string;
  profile_picture_url: string;
  contact_number?: string;
  job_title: string;
  role: string;
};

// client data representation
export type Member = {
  id?: number;
  name: string;
  email: string;
  profilePictureUrl: string;
  contactNumber?: string;
  jobTitle: string;
  role: MembersRolesTypes;
};

export type MembersState = {
  members: Member[];
  pageNum: number;
  numPerPage: number;
  totalMembers: number;
};
