import TextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { Control, Controller, Message, ValidationRule } from 'react-hook-form';

// simple regex patterns probably not cover all cases but good enough for most cases
export const FormTextFieldRegexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/g;
export const FormTextFieldRegexPhone = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]{8,14}$/g;
export const FormTextFieldRegexURL =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;

type FormTextFieldRules = {
  required?: Message | ValidationRule<boolean>;
  pattern?: ValidationRule<RegExp>;
  validate?: (value: string) => boolean | string;

  // add other RHF rules if needed
};

export type FormTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  field: string;
  rules?: FormTextFieldRules;
};

export const FormTextField = ({
  field,
  rules,
  control,
  helperText,
  sx,
  ...props
}: FormTextFieldProps) => {
  return (
    <Controller
      name={field}
      rules={rules}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        return (
          <TextField
            fullWidth
            sx={{ mx: 0, ...sx }}
            onChange={onChange}
            onBlur={onBlur}
            value={value ?? ''}
            error={error !== undefined}
            helperText={error !== undefined ? error.message : helperText}
            InputLabelProps={styles.inputLabelProps}
            margin='normal'
            {...props}
          />
        );
      }}
    />
  );
};

const styles = {
  inputLabelProps: {
    shrink: true,
  },
};
