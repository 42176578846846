import { IDevice } from 'device/deviceType';
import { Sticker } from 'pintura';
import { IDevices } from 'types/properties';
export interface Property {
  is_celsius?: boolean;
  id: number;
  company_id?: number;
  name: string;
  occupied?: string;
  temperature?: number;
  lock_enabled: boolean;
  property_enabled: boolean;
  room_status: string;
  address: Address;
  external_id: string | null;
  forwards: string[];
  intercom_config: string | null;
  intercom_number: number | null;
  locks: number[];
  smart_app: boolean | null;
  service_status?: string;
  devices: IDevices[];
  city?: Sticker;
  common_property_devices: IDevice[];
  property_type: string;
  environment_enabled: boolean;
  cleaning_notes: string;
  isFavourite: boolean;
  timezone: string;
  type: PropertyFilterType;
  noiseLevel?: number;
  occupancyLevel?: number;
  checkin_offset: number;
  checkout_offset: number;
  thermostat_checkin_offset?: number;
  thermostat_checkout_offset?: number;
  in_public_access_group?: boolean;
  backup_code_enabled?: boolean;
  lock_alert_enabled?: boolean;
  battery_alert_enabled?: boolean;
  checkout_code_enabled?: boolean;
  member_code_enabled?: boolean;
  intercom_enabled?: boolean;
  thermostat_enabled?: boolean;
  blackout_notify_enabled?: boolean;
  guest_portal_status: 'active' | 'inactive';
  disable_lock_unlock: boolean;
  is_locked?: boolean;
  is_online?: boolean;
  group_type?: string;
  locationType?: string;
  guest_verification_enabled: boolean;
  room_type?: string;
  smw_enabled: boolean;
  display_name?: string;
  guest_portal_initialized?: boolean;
  groups?: { name: string; id: number }[];
}

export const PropertyQueryFilterType = {
  LOCK_ENABLED: 'lock_enabled',
  ROOM_STATUS: 'room_status',
  FAVOURITES: 'favourites',
  PROPERTY_TYPE: 'type',
  GROUP_TYPE: 'group_type',
  LOCATION_TYPE: 'locationType',
  PAGE_NUMBER: 'pageNum',
  NUMBER_PER_PAGE: 'numPerPage',
  KEYWORD: 'keyword',
  ACTIVE_TODAY_ONLY: 'active_today_only',
  GROUP: 'group_id',
  EXCLUDE_GROUP: 'exclude_in_group',
};

export enum PropertyFilterType {
  ALL_PROPERTIES = 'primary',
  ACTIVE_TODAY = 'active_today',
  UNACTIVATED = 'unactivated',
  VACANT = 'vacant',
  CLEANING = 'cleaning',
  FAVOURITES = 'favourites',
  COMMON = 'common',
  ALERT = 'alert',
  PUBLIC = 'public',
  ACCESS_GROUP = 'accessGroup',
  ROOM_TYPE = 'room',
}

export enum PropertyGroupType {
  PUBLIC = 'public',
  GUEST = 'guest',
}

export interface Address {
  alt_phone?: string | null;
  city?: string | null;
  country?: string | null;
  email?: string | null;
  fax?: string | null;
  id?: number | null;
  lat?: number | null;
  line1?: string | null;
  line2?: string | null;
  lng?: number | null;
  name?: string | null;
  phone?: string | null;
  postal_code?: string | null;
  state?: string | null;
  timezone: string;
}

export interface PropertyCounts {
  currentFilter: PropertyFilterType;
  isLoading: boolean;
  [PropertyFilterType.ALL_PROPERTIES]: RecordTrack;
  [PropertyFilterType.ACTIVE_TODAY]: RecordTrack;
  [PropertyFilterType.VACANT]: RecordTrack;
  [PropertyFilterType.UNACTIVATED]: RecordTrack;
  [PropertyFilterType.FAVOURITES]: RecordTrack;
  [PropertyFilterType.COMMON]: RecordTrack;
  [PropertyFilterType.ROOM_TYPE]: RecordTrack;
  [PropertyFilterType.PUBLIC]: RecordTrack;
  [PropertyFilterType.CLEANING]: RecordTrack;
  [PropertyFilterType.ACCESS_GROUP]: RecordTrack;
  [PropertyFilterType.ALERT]: RecordTrack;
}

export interface RecordTrack {
  ids: number[];
  pageNum: number;
  numPerPage: number;
  total_records: number;
}

export interface PropertyDict {
  [Key: number]: Property;
}

export interface PropertyState {
  byId: PropertyDict;
  meta: PropertyCounts;
}

export const emptyAddress: Address = {
  timezone: 'America/Vancouver',
};

export const emptyProperty: Property = {
  company_id: 0,
  name: '',
  address: emptyAddress,
  city: '',
  timezone: '',
  property_type: '',
  checkin_offset: 0,
  checkout_offset: 0,
  thermostat_checkin_offset: 0,
  thermostat_checkout_offset: 0,
  property_enabled: false,
  lock_enabled: false,
  environment_enabled: false,
  thermostat_enabled: false,
  intercom_enabled: false,
  backup_code_enabled: false,
  checkout_code_enabled: false,
  member_code_enabled: false,
  blackout_notify_enabled: false,
  battery_alert_enabled: false,
  lock_alert_enabled: false,
  in_public_access_group: false,
  group_type: '',
  locationType: '',
  id: 0,
  room_status: '',
  external_id: '',
  forwards: [],
  intercom_config: '',
  intercom_number: 0,
  locks: [],
  smart_app: false,
  devices: [],
  common_property_devices: [],
  cleaning_notes: '',
  isFavourite: false,
  type: PropertyFilterType.ALL_PROPERTIES,
  guest_portal_status: 'active',
  disable_lock_unlock: false,
  guest_verification_enabled: false,
  smw_enabled: false,
  groups: [],
};

export interface RoomTypesData {
  id: number;
  room_type: string;
  description: string;
}
