import { getCurrentCompany } from 'company/state/companySelectors';
import { getCurrentMember } from 'member/state/memberActions';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PageContentContainer } from 'ui-library/Styled/containers';
import GlobalGpPreivew from '../Common/GlobalGpPreview';
import AssignedUnitsTable from './AssignedUnitsTable/AssignedUnitsTable';

export const AssignedUnitsTabPage = () => {
  const { smwEnabled } = useAppSelector(getCurrentCompany());
  const [selectedPropertyId, setSelectedPropertyId] = useState<number>();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getCurrentMember());
  }, [dispatch]);

  return (
    <PageContentContainer isMobile={false} padding='8px 0 0 0' style={{ width: '100%' }}>
      <AssignedUnitsTable onGpPreview={setSelectedPropertyId} smwEnabled={smwEnabled} />
      {selectedPropertyId && (
        <GlobalGpPreivew
          propertyId={selectedPropertyId}
          onClose={() => setSelectedPropertyId(undefined)}
        />
      )}
    </PageContentContainer>
  );
};

export default AssignedUnitsTabPage;
